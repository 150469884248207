import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-client',
  templateUrl: './register-client.component.html',
  styleUrls: ['./register-client.component.scss'],
  providers: [],
})
export class RegisterClientComponent implements OnInit {
  show_password = false;
  data = {}
  currentStep = 1
  is_flujo_cotizador = false;



  constructor(
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private router: Router


  ) { }
 
  ngOnInit(): void {
    
   const info = JSON.parse(localStorage.getItem('dataIne'))
   const folio = localStorage.getItem('folio_solicitud') || ''
   const correo = localStorage.getItem('correo_solicitud')
   const flujo_cotizador = localStorage.getItem('flujo_cotizador') || false
   const cotizacion = JSON.parse(localStorage.getItem('datos_cotizacion')) || {}
  this.is_flujo_cotizador = flujo_cotizador ? JSON.parse(flujo_cotizador) : false
   this.data = {...info, folio, correo: correo != "undefined" ? correo : "", _flujo_cotizador:  this.is_flujo_cotizador, cotizacion}
   

  }
  getData(){
    

  }
  datosActualizados(nuevosDatos: any): void {
    this.data = nuevosDatos;

  }
 
 
  async Siguiente(){
    this.getData()
    try {
     
      
    } catch (err){
      this._snackBar.open('A ocurrido un error, por favor vuelve a intentarlo más tarde. ', 'Ok',
      {
      duration: 10000,
        horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['warning-snackbar']})
    }
  }
  onSiguiente(): void {
    this.currentStep++;
  }
  onAnterior(): void {
    this.currentStep= this.currentStep- 1;
  }
 
  iniciarSesion(){
    this.router.navigate(['/'])
  }
}

