<div class="row">
    <div class="col-4">
        <ul class="progress-cotizacion">
			<li class="active"><span>CLIENTE</span></li>
            <li><span>EQUIPO</span></li>
            <li><span>COTIZACIÓN</span></li>
            <li><span>RESIDUAL</span></li>
        </ul>
    </div>
</div>

<form [formGroup]="datosClienteForm">
<div class="row">
    <div class="col-4">
        <div class="input">
            <label>Nombre completo</label>
            <input 
			    [specialCharacters]="['space']"
                type="text" 
                [patterns]="customPattern"
                mask="S{100}"
                formControlName="nombreCliente"
                placeholder="Como aparece en el I.N.E.">
                <mat-error *ngIf="(submit) && !f.nombreCliente.value">Campo obligatorio</mat-error>
		</div>
		<div class="input">
            <label>Correo electrónico</label>
			<input 
				type="email"
                formControlName="correo"
                placeholder="correo electrónico">
                <mat-error *ngIf="(submit) && !f.correo.value">Campo obligatorio</mat-error>
                <mat-error *ngIf="(submit) && f.correo.value && f.correo.invalid">Correo no válido, vuelve a intentarlo.</mat-error>
        </div>

        <div style="margin-top: 16px;" *ngIf="(perfil == 9 || perfil == 6)">
            Asignar trámite a:
            <mat-error *ngIf="(perfil == 9 || perfil == 6) && submit && !id_responsable">Campo obligatorio</mat-error>
            <app-filtro
                [find_by_date]="false"
                [perfil]="perfil"
                [id_usuario]="id_usuario"
                [filtro]="filtro" 
                (onSelect)="ObtenerSubperfil($event.id_perfil, $event.id, $event.path)"
            ></app-filtro>
        </div>
        <mat-error *ngIf="(perfil == 9 || perfil == 6) && (submit) && fail_responsable">Elige un usuario responsable</mat-error>
    </div>
</div>
</form>
<div class="row">
    <div class="col-4">
        <button mat-button (click)="NextPage()" class="btn-next-page">
            Siguiente
            <svg style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><defs><style>.abc{fill:#707070;}</style></defs><path class="abc" d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0ZM22,38V18.7L13.448,27.25l-3.394-3.394L24,9.908,37.948,23.856,34.554,27.25,26,18.7V38Z" transform="translate(48) rotate(90)"/></svg>
        </button>
    </div>
</div>