import { trigger, state, style, transition, animate, animateChild, query } from '@angular/animations';


export const onSideNavChange = trigger('onSideNavChange', [
  state('close',
    style({
      'min-height': '64px'
    })
  ),
  state('open',
    style({
      'min-height': '220px'
    })
  ),
  transition('close => open', animate('1.5s ease-in-out')),
  transition('open => close', animate('1.5s ease-in-out')),
]);

export const onMainContentChange = trigger('onMainContentChange', [
    state('close',
      style({
        'margin-left': '64px'
      })
    ),
    state('open',
      style({
        'margin-left': '220px'
      })
    ),
    transition('close => open', animate('1s ease-in-out')),
    transition('open => close', animate('1s ease-in-out')),
  ]);

  export const animateText = trigger('animateText', [
    state('hide',
      style({
        'display': 'none',
        opacity: 0,
      })
    ),
    state('show',
      style({
        'display': 'block',
        opacity: 1,
      })
    ),
    transition('hide => show', animate('0.5s ease-in-out')),
    transition('show => hide', animate('0.5s ease-in-out')),
  ]);

  export const onDraweContentChange = trigger('onDraweContentChange', [
    state('close',
    style({
      'width': '0px'
    })
  ),
  state('open',
    style({
      'width': '156px'
    })
  ),
  transition('close => open', animate('1.5s ease-out')),
  transition('open => close', animate('1.5s ease-in')),
  ]);

  export const onContentChange = trigger('onContentChange', [
    state('close',
      style({
        'margin-left': '156px'
      })
    ),
    state('open',
      style({
        'margin-left': '156px'
      })
    ),
    transition('close => open', animate('1s ease-in-out')),
    transition('open => close', animate('1s ease-in-out')),
  ]);

 

  export const openCard = trigger('openCard', [
    state('close', style({
      height: '0',
      display: 'none',
      opacity: '0'
    })),
    state('open', style({
      height: '*',
      opacity: '1'
    })),
    transition('close => open', [
      animate('0.5s ease-in-out', style({
        height: '*',
        display: 'block',
        opacity: '1'
      })),
    ]),
    transition('open => close', [
      animate('0.5s ease-in-out', style({
        height: '0',
        opacity: '0'
      })),
    ])
  ]);

  export const flipCard = trigger('flipCard', [
    state('normal', style({
      transform: 'none'
    })),
    state('flipped', style({
      transform: 'rotateY(180deg)',
      height:'250px'
    })),
    transition('normal => flipped', animate('600ms ease-in')),
    transition('flipped => normal', animate('600ms ease-out'))
  ])


  export const fadeInOut =
  trigger('fadeInOut', [
    state('in', style({
      opacity: 1,
    })),
    transition('void => *', [
      style({ opacity: 0, display: 'none'
    }),
      animate(300)
    ]),
    transition('* => void', [
      animate(300, style({ opacity: 0 }))
    ])
  ])

  export const expandedSection = trigger('expandCollapse', [
    state('expanded', style({
      height: '*',
      opacity: 1,
    })),
    state('collapsed', style({
      height: '0px',
      opacity: 0,
    })),
    transition('expanded <=> collapsed', [
      animate('300ms ease-in-out')
    ]),
  ])