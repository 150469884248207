import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-tramite-proceso',
  templateUrl: './tramite-proceso.component.html',
  styleUrls: ['./tramite-proceso.component.scss']
})
export class TramiteProcesoComponent implements OnInit {
  @Output() onSelect = new EventEmitter;
  @Output() ImporteTotal = new EventEmitter
  @Input() tramites

  proceso_seleccionado = 0

  proceso = {
    solicitud: {
      cantidad: 0,
      importe: 0
    },
    expediente: {
      cantidad: 0,
      importe: 0
    },
    confirmacion: {
      cantidad: 0,
      importe: 0
    },
    comite: {
      cantidad: 0,
      importe: 0
    },
    aprobadas: {
      cantidad: 0,
      importe: 0
    },
    aprobadas_con: {
      cantidad: 0,
      importe: 0
    },
    info_adicional: {
      cantidad: 0,
      importe: 0
    },
  }
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if( !this.tramites ) return
    this.proceso.solicitud.importe = 0
    this.proceso.expediente.importe = 0
    this.proceso.confirmacion.importe = 0
    this.proceso.comite.importe = 0
    this.proceso.aprobadas.importe = 0
    this.proceso.aprobadas_con.importe = 0
    this.proceso.info_adicional.importe = 0

    this.proceso.solicitud.cantidad = this.tramites.solicitud.length
    this.proceso.expediente.cantidad = this.tramites.expediente.length
    this.proceso.confirmacion.cantidad = this.tramites.confirmacion.length
    this.proceso.comite.cantidad = this.tramites.comite.length
    this.proceso.aprobadas.cantidad = this.tramites.aprobadas.length
    this.proceso.aprobadas_con.cantidad = this.tramites.aprobadas_con.length
    this.proceso.info_adicional.cantidad = this.tramites.info_adicional.length
    
    this.tramites.solicitud.forEach( item => this.proceso.solicitud.importe += item.precio_con_iva)
    this.tramites.expediente.forEach( item => this.proceso.expediente.importe += item.precio_con_iva)
    this.tramites.confirmacion.forEach( item => this.proceso.confirmacion.importe += item.precio_con_iva)
    this.tramites.comite.forEach( item => this.proceso.comite.importe += item.precio_con_iva)
    this.tramites.aprobadas.forEach( item => this.proceso.aprobadas.importe += item.precio_con_iva)
    this.tramites.aprobadas_con.forEach( item => this.proceso.aprobadas_con.importe += item.precio_con_iva)
    this.tramites.info_adicional.forEach( item => this.proceso.info_adicional.importe += item.precio_con_iva)
    
  }

  ProcesoSeleccionado( value ){
    this.proceso_seleccionado = value
    console.log(this.proceso_seleccionado);
    
    this.onSelect.emit( this.proceso_seleccionado );
  }
}
