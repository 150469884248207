<div class="container-modal">
    <mat-dialog-content>
        <div class="titulo">
            <span>REFERIDO NO INTERESADO</span>
        </div>
        <div class="texto">
            <span>¿Desea continuar?</span>
        </div>

        <div class="container-btn">
            <button (click)="descartarReferido()">Si</button>
            <button (click)="cerrarModal()">No</button>
        </div>
    </mat-dialog-content>
</div>
