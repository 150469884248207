import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { customPatterns } from 'src/app/_models/maskPattern';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/_services';
import { MatDialog } from '@angular/material/dialog';
import { InfoComponent } from 'src/app/_components/info/info.component';

@Component({
  selector: 'app-residual',
  templateUrl: './residual.component.html',
  styleUrls: ['./../crear-cotizacion.component.scss']
})
export class ResidualComponent implements OnInit {
  @Output() onNextPage = new EventEmitter;
  @Input() tramite = false;
  @Input() loading;
  @Input() importe_total = 0;

  residualForm: FormGroup;
  customPattern = customPatterns;
  submit = false;
  perfil = null
  fail_residual12 = false;
  fail_residual18 = false;
  fail_residual24 = false;
  fail_residual36 = false;
  fail_residual48 = false;
  fail_residual60 = false;

  residual_60 = false

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog
  ) { }
// "arrendamientoPuro": {
	// 	"factor": 17,
	// 	"valorResidual12": 0,
	// 	"valorResidual18": 0,
	// 	"valorResidual24": 0,
	// 	"valorResidual36": 0,
	// 	"valorResidual48": 0
	// }
  ngOnInit(): void {
    const SUPER_VENDOR = 11
    const currentUser = JSON.parse(localStorage.getItem('currentUser'))
    this.perfil = currentUser.perfil_master == 11 ? 11 : Number(currentUser.perfil);
    this.residual_60 = this.importe_total > 1000000 || this.perfil === SUPER_VENDOR

    const ValidadorResidual60 = this.residual_60
      ? [Validators.required, Validators.minLength(1), Validators.max(16)]
      : []

    this.residualForm = this.formBuilder.group({
        valorResidual12: ['41', [Validators.required, Validators.minLength(1), Validators.max(41)]],
        valorResidual18: ['36', [Validators.required, Validators.minLength(1), Validators.max(36)]],
        valorResidual24: ['31', [Validators.required, Validators.minLength(1), Validators.max(31)]],
        valorResidual36: ['26', [Validators.required, Validators.minLength(1), Validators.max(26)]],
        valorResidual48: ['21', [Validators.required, Validators.minLength(1), Validators.max(21)]],
        valorResidual60: ['16', ValidadorResidual60],
    });
  }

  get f() {
    return this.residualForm.controls;
  }

  NextPage() {
    if ( !this.userService.alertService.isOnline() ) { return }

    this.submit = true;
    if ( this.f.valorResidual12.value > 41 ) { this.fail_residual12 = true }
    if ( this.f.valorResidual18.value > 36 ) { this.fail_residual18 = true }
    if ( this.f.valorResidual24.value > 31 ) { this.fail_residual24 = true }
    if ( this.f.valorResidual36.value > 26 ) { this.fail_residual36 = true }
    if ( this.f.valorResidual48.value > 21 ) { this.fail_residual48 = true }
    if ( this.f.valorResidual60.value > 16 ) { this.fail_residual60 = true }

    if (
      // this.fail_residual12 ||
      // this.fail_residual18 ||
      // this.fail_residual24 ||
      // this.fail_residual36 ||
      // this.fail_residual48 ||
      this.residualForm.invalid ) { return }
    this.onNextPage.emit({
      residual: {
        valorResidual12: Number(this.f.valorResidual12.value),
        valorResidual18: Number(this.f.valorResidual18.value),
        valorResidual24: Number(this.f.valorResidual24.value),
        valorResidual36: Number(this.f.valorResidual36.value),
        valorResidual48: Number(this.f.valorResidual48.value),
        valorResidual60: Number(this.f.valorResidual60.value),
      }
    });
  }

  Tooltip(valor_maximo) {
    this.dialog.open(InfoComponent, {
      width: '250px',
      data: {
        text: `Valor máximo: ${valor_maximo}`
      }
    });
  }
}
