export const Perfiles = [
    {id: 2, nombre: 'Vendor', creador: null},
    {id: 7, nombre: 'Vendedor', creador: null},
    {id: 3, nombre: 'Comercial', creador: null},
    {id: 4, nombre: 'Comité', creador: null},
    {id: 10, nombre: 'BackOffice', creador: null},
    {id: 8, nombre: 'Gerente Comercial', creador: null},
    {id: 13, nombre: 'Director de Operaciones', creador: null},
    {id: 14, nombre: 'Gerente de Operaciones', creador: null},
    {id: 15, nombre: 'Mesa de Control', creador: null},
    {id: 16, nombre: 'Gerente de Crédito', creador: null},
    {id: 12, nombre: 'Director Comercial', creador: null},
    // {id: 9, nombre: 'Super Comercial', creador: null},
    // {id: 11, nombre: 'Super Vendor', creador: null},
]

export const PerfilesBackoffice = [
    {id: 2, nombre: 'Vendor', creador: null},
]