import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChangeStatusComponent } from 'src/app/_dialogs/change-status/change-status.component';
import { Router } from '@angular/router';
import { LIST_STATUS } from '../../_helpers/status'

@Component({
  selector: 'app-tramites-activos',
  templateUrl: './tramites-activos.component.html',
  styleUrls: ['./tramites-activos.component.scss']
})
export class TramitesActivosComponent implements OnInit {
  @Input() tramites = []
  @Input() perfil = ''
  @Output() onChangeStatus = new EventEmitter()
  tramites_filtrados = []
  STATUS = LIST_STATUS // Clases segun el estatus del proceso
  filter_word = ''

  constructor(private dialog:MatDialog,
    private router:Router) { }

  ngOnInit(): void { }
  ngOnChanges(): void {
    // Se mantiene el filtro despues de haber un cambio en el estatus de los procesos
    if( this.tramites.length ) this.filtrar(this.filter_word)
  }

  // Filtrar Tramites por Folio o nombre del cliente
  filtrar(word) {
    this.filter_word = word
    this.tramites_filtrados = this.tramites.filter( 
      tramite => tramite.folio.toString().includes(word) || 
                 tramite.cliente.toLowerCase().includes(word.toLowerCase())
    )
  }

  // Guardamos en el localStorage al cliente y su estatus y cambiamos de pagina
  MostrarDetalles(folio) {
    const cliente = this.tramites.filter( tramite => tramite.folio == folio )
    localStorage.setItem("client_details", JSON.stringify( cliente[0] ) )
    this.router.navigateByUrl(`dashboard/${this.perfil}/${folio}`)
    
  }

  // mostramos el cuadro de dialogo para cambiar el proceso, 
  // indicamos el folio y el proceso del cual se va a cambiar el estatus
  CambiarStatus(folio, proceso) {
    if( this.perfil == 'comite' && proceso <= 3 ) return
    const dialogRef = this.dialog.open(ChangeStatusComponent, {
      width: "500px",
      height: "auto",
      data: {folio, proceso}
    })

    dialogRef.afterClosed()
      .subscribe( req => this.onChangeStatus.emit(true)) 
  }
}
