import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-vista-previa',
  templateUrl: './modal-vista-previa.component.html',
  styleUrls: ['./modal-vista-previa.component.scss']
})
export class ModalVistaPreviaComponent implements OnInit {
  correo_cliente = ""
  folio = ""
  isInputEmpty: boolean = true;
  isInputEmptyFolio: boolean = true;
  loading = false
  constructor(
    private dialogRef: MatDialogRef<ModalVistaPreviaComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {
  }

  async guardar(){
    if(this.data.aceptar){
      this.loading = true
      try {
        await this.data.aceptar(this.correo_cliente, this.folio);
      } catch (error) {
        console.error("Error al aceptar", error);
      } finally {
        this.loading = false;
      }
    }
  }
  cerrar(){
    if(this.data.cerrar){
      this.data.cerrar()
    }
    this.dialogRef.close();
  }

  checkInputValue(event: any, fieldName: string): void {
    const value = event.target.value;
  
    switch (fieldName) {
      case 'folio':
        if (/^\d*$/.test(value)) {
          this.folio = value;
          this.isInputEmptyFolio = this.folio === '';
        } else {
          event.target.value = this.folio;
        }
        break;
      case 'correo_cliente':
        this.correo_cliente = value;
        this.isInputEmpty = this.correo_cliente === '';
        if (!this.isInputEmpty && !this.validateEmail(this.correo_cliente)) {
          console.warn('Correo electrónico inválido');
        }
        break;
      default:
        console.warn(`Campo desconocido: ${fieldName}`);
    }
  }
  
  // Función de validación de correo electrónico
  validateEmail(email: string): boolean {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }
  
}
