<div class="container-form">
  <div class="form">
    <div>
      <div>
        <div class="input">
          <textarea
            maxlength="1000"
            [(ngModel)]="AnalisisPM.caracter.comentarios"
            placeholder="Antecedentes, actividad y perfil del socio"
          ></textarea>
        </div>
      </div>
      <h3>Evaluación de carácter</h3>
      <div class="title-section">
        <span class="circle">1</span><b>Perfil de la empresa</b>
      </div>
      <tbody class="formulario analisis solicitante">
        <tr>
          <td></td>
          <td>Calificar</td>
          <td></td>
          <td>Puntuación</td>
        </tr>
        <tr>
          <td>Tiempo en Operación</td>
          <td>
            <input
              matInput
              type="number"
              [(ngModel)]="AnalisisPM.perfil_empresa.tiempo_operaciones"
              (input)="CambiarPuntuacionEmpresa()"
            />
          </td>
          <td>
            <input
              matInput
              [(ngModel)]="AnalisisPM.perfil_empresa.tiempo_operaciones_descrip"
              readonly
            />
          </td>
          <td>
            <input
              matInput
              [(ngModel)]="AnalisisPM.perfil_empresa.tiempo_operaciones_puntos"
              (input)="ActualizarPuntuacion('perfil_empresa')"
              readonly
            />
          </td>
        </tr>
        <tr>
          <td>Tipo Instalaciones</td>
          <td>
            <input
              matInput
              [(ngModel)]="AnalisisPM.perfil_empresa.tipo_instalaciones"
              readonly
            />
          </td>
          <td>
            <mat-select [(ngModel)]="AnalisisPM.perfil_empresa.tipo_instalaciones_descrip" (ngModelChange)="puntoTipoInstalaciones()">
              <mat-option *ngFor="let tipo of TIPO_INSTALACIONES" [value]="tipo.id">{{ tipo.nombre }}</mat-option>
              </mat-select>
          </td>
          <td>
            <input
              matInput
              [(ngModel)]="AnalisisPM.perfil_empresa.tipo_instalaciones_puntos"
              (input)="ActualizarPuntuacion('perfil_empresa')"
              readonly
            />
          </td>
        </tr>
        <tr>
          <td>Arraigo</td>
          <td>
            <input
              matInput
              type="number"
              (input)="CambiarPuntuacionEmpresa()"
              [(ngModel)]="AnalisisPM.perfil_empresa.arraigo"
            />
          </td>
          <td>
            <input
              matInput
              [(ngModel)]="AnalisisPM.perfil_empresa.arraigo_descrip"
              readonly
            />
          </td>
          <td>
            <input
              matInput
              [(ngModel)]="AnalisisPM.perfil_empresa.arraigo_puntos"
              (input)="ActualizarPuntuacion('perfil_empresa')"
              readonly
            />
          </td>
        </tr>
        <tr>
          <td>Resultado Utilidad</td>
          <td>
            <input
              matInput
              #solicitanteBcScore
              [(ngModel)]="AnalisisPM.perfil_empresa.resultado_utilidad_descrip"
            />
            <!-- <mat-error *ngIf="submit && !(AnalisisPM.perfil_solicitante.bc_score > 0)">Campo obligatorio</mat-error></td> -->
          </td>

          <td>
            <mat-select [(ngModel)]="AnalisisPM.perfil_empresa.resultado_utilidad" (ngModelChange)="puntosResultadoUtilidad()">
              <mat-option *ngFor="let resultado of RESULTADO_UTILIDAD" [value]="resultado.id" >{{ resultado.nombre }}</mat-option>
              </mat-select>
          </td>
          <td>
            <input
              matInput
              [(ngModel)]="AnalisisPM.perfil_empresa.resultado_utilidad_puntos"
              (input)="ActualizarPuntuacion('perfil_empresa')"
              readonly
            />
          </td>
        </tr>
        <tr>
          <td>BC Score Representante Legal</td>
          <td>
            <input
              matInput
              #solicitanteBcScore
              type="number"
              (input)="CambiarPuntuacionEmpresa()"
              [(ngModel)]="AnalisisPM.perfil_empresa.bc_representante_legal"
            />
            <!-- <mat-error *ngIf="submit && !(AnalisisPM.perfil_solicitante.bc_score > 0)">Campo obligatorio</mat-error></td> -->
          </td>

          <td>
            <input
              matInput
              [(ngModel)]="
                AnalisisPM.perfil_empresa.bc_representante_legal_descrip
              "
              readonly
            />
          </td>
          <td>
            <input
              matInput
              [(ngModel)]="
                AnalisisPM.perfil_empresa.bc_representante_legal_puntos
              "
              (input)="ActualizarPuntuacion('perfil_empresa')"
              
            />
          </td>
        </tr>
        <tr>
          <td>BC Score Aval</td>
          <td>
            <input
              matInput
              #solicitanteBcScore
              type="number"
              (input)="CambiarPuntuacionEmpresa()"
              [(ngModel)]="AnalisisPM.perfil_empresa.bc_aval"
            />
          </td>

          <td>
            <input
              matInput
              [(ngModel)]="AnalisisPM.perfil_empresa.bc_aval_descrip"
              readonly
            />
          </td>
          <td>
            <input
              #solBc
              matInput
              [(ngModel)]="AnalisisPM.perfil_empresa.bc_aval_puntos"
              (input)="ActualizarPuntuacion('perfil_empresa')"
              
            />
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td colspan="2" style="text-align: right">
            <div class="puntuacion small">
              {{ AnalisisPM.perfil_empresa.total_puntos_empresa }} puntos
            </div>
          </td>
        </tr>
      </tbody>
    </div>
    <div>
      <div class="title-section">
        <span class="circle">2</span><b>Buró empresa</b>
      </div>
      <tbody class="formulario analisis">
        <tr>
          <td></td>
          <td>Calificar</td>
          <td>Param</td>
          <td>Puntuación</td>
        </tr>
        <tr>
          <td>Calificación en buró</td>
          <td>
            <input
              matInput
              #solicitanteBcScore
              [(ngModel)]="AnalisisPM.buro_empresa.calificacion"
              (ngModelChange)="CambiarPuntuacionBuroEmpresa()"
            />
            <!-- <mat-error *ngIf="submit && !(AnalisisPM.perfil_solicitante.bc_score > 0)">Campo obligatorio</mat-error></td> -->
          </td>

          <td>
            <input
              [(ngModel)]="AnalisisPM.buro_empresa.calificacion_descrip"
              readonly
            />
          </td>
          <td>
            <input
              #solBc
              matInput
              [(ngModel)]="AnalisisPM.buro_empresa.calificacion_puntos"
              (input)="ActualizarPuntuacion('buro_empresa')"
              readonly
            />
          </td>
        </tr>
        <tr>
          <td>Meses Mora Recientes</td>
          <td>
            <input
              matInput
              #solicitanteBcScore
              type="number"
              [(ngModel)]="AnalisisPM.buro_empresa.meses_mora"
              (ngModelChange)="CambiarPuntuacionBuroEmpresa()"
            />
            <!-- <mat-error *ngIf="submit && !(AnalisisPM.perfil_solicitante.bc_score > 0)">Campo obligatorio</mat-error></td> -->
          </td>

          <td>
            <input
              [(ngModel)]="AnalisisPM.buro_empresa.meses_mora_descrip"
              readonly
            />
          </td>
          <td>
            <input
              #solBc
              matInput
              [(ngModel)]="AnalisisPM.buro_empresa.meses_mora_puntos"
              (input)="ActualizarPuntuacion('buro_empresa')"
              readonly
            />
          </td>
        </tr>
        <tr>
          <td>% Cuentas Buenas</td>
          <td>
            <input
              matInput
              #solicitanteBcScore
              type="number"
              [(ngModel)]="AnalisisPM.buro_empresa.porcentaje_cuentas_buenas"
              (ngModelChange)="CambiarPuntuacionBuroEmpresa()"
             />
            <!-- <mat-error *ngIf="submit && !(AnalisisPM.perfil_solicitante.bc_score > 0)">Campo obligatorio</mat-error></td> -->
          </td>

          <td>
            <input
              [(ngModel)]="
                AnalisisPM.buro_empresa.porcentaje_cuentas_buenas_descrip
              "
              readonly
            />
          </td>
          <td>
            <input
              #solBc
              matInput
              [(ngModel)]="
                AnalisisPM.buro_empresa.porcentaje_cuentas_buenas_puntos
              "
              (input)="ActualizarPuntuacion('buro_empresa')"
              readonly
            />
          </td>
        </tr>
        <tr>
          <td>Peor MOP 12 meses</td>
          <td>
            <input
              matInput
              #solicitanteBcScore
              type="number"
              [(ngModel)]="AnalisisPM.buro_empresa.peor_mop_12_meses"
              (ngModelChange)="CambiarPuntuacionBuroEmpresa()"
            />
            <!-- <mat-error *ngIf="submit && !(AnalisisPM.perfil_solicitante.bc_score > 0)">Campo obligatorio</mat-error></td> -->
          </td>

          <td>
            <input
              [(ngModel)]="AnalisisPM.buro_empresa.peor_mop_12_meses_descrip"
              readonly
            />
          </td>
          <td>
            <input
              #solBc
              matInput
              [(ngModel)]="AnalisisPM.buro_empresa.peor_mop_12_meses_puntos"
              (ngModelChange)="CambiarPuntuacionBuroEmpresa()"
              readonly
            />
          </td>
        </tr>
        <tr>
          <td>Entrevista</td>
          <td>
            <input
              matInput
              #solicitanteBcScore
              [(ngModel)]="AnalisisPM.buro_empresa.entrevista"
              readonly
            />
            <!-- <mat-error *ngIf="submit && !(AnalisisPM.perfil_solicitante.bc_score > 0)">Campo obligatorio</mat-error></td> -->
          </td>

          <td>
            <mat-select [(ngModel)]="AnalisisPM.buro_empresa.entrevista_descrip" (ngModelChange)="CambiarPuntuacionBuroEmpresa()" >
              <mat-option value="OK">OK</mat-option>
              <mat-option value="NO">NO</mat-option>
              </mat-select>
          </td>
          <td>
            <input
              #solBc
              matInput
              [(ngModel)]="AnalisisPM.buro_empresa.entrevista_puntos"
              (input)="ActualizarPuntuacion('buro_empresa')"
              readonly
            />
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>

          <td colspan="2" style="text-align: right">
            <div class="puntuacion small">
              {{ AnalisisPM.buro_empresa.total_puntos_buro_empresa }} puntos
            </div>
          </td>
        </tr>
      </tbody>
    </div>

    <div style="width: 100% !important">
      <div class="title-section">
        <span class="circle">3</span><b>Resumen del Historial de Crédito</b>
      </div>
      <div></div>
      <div style="display: flex; flex-direction: column; width: 90% !important">
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
          "
        >
        <table>
          <thead><h2> Abiertos</h2></thead>
          <tbody class="formulario analisis">
            <tr>
              <th>Otorgante</th>
              <th>Credito Máximo</th>
              <th>Saldo Insoluto</th>
              <th>Mop Máximo</th>
              <th>Comp. de pago</th>
              <th>Puntos</th>
            </tr>
            <tr
              *ngFor="
                let credito of AnalisisPM.caracter.resumen_creditos_abiertos;
                let idx = index
              "
            >
              <td>
                <mat-select [(ngModel)]="credito.otorgante" placeholder="Seleccionar...">
                  <mat-option value="Hipotecario">Hipotecario</mat-option>
                  <mat-option value="Automotriz">Automotriz</mat-option>
                  <mat-option value="Crédito Simple">Crédito Simple</mat-option>
                  <mat-option value="Crédito de Nomina">Crédito de Nomina</mat-option>
                  <mat-option value="Credito Personal">Credito Personal</mat-option>
                  <mat-option value="Linea de Crédito">Linea de Crédito</mat-option>
                  <mat-option value="Tarjeta de Crédito">Tarjeta de Crédito</mat-option>
                  <mat-option value="Arrendamiento Puro">Arrendamiento Puro</mat-option>
              </mat-select>
              </td>
              <td>
                <input
                  matInput
                  mask="separator.2"
                  thousandSeparator=","
                  prefix="$ "
                  [(ngModel)]="credito.credito_maximo"
                />
              </td>
              <td>
                <input
                  matInput
                  mask="separator.2"
                  thousandSeparator=","
                  prefix="$ "
                  [(ngModel)]="credito.saldo_insoluto"
                />
              </td>
              <td>
                <input
                  matInput
                  mask="separator"
                  thousandSeparator=","
                  [(ngModel)]="credito.mop_maximo"
                />
              </td>
              <td>
                <mat-select
                  [(ngModel)]="credito.id_comportamiento_pago"
                >
                  <mat-option
                    *ngFor="let cp of COMPORTAMIENTO_PAGO_CREDITO"
                    [value]="cp.id"
                    >{{ cp.nombre }}</mat-option
                  >
                </mat-select>
              </td>
              <td class="centrar-texto">
                
                <input (ngModelChange)="ActualizarComportamientoCredito()" [(ngModel)]="credito.puntos"   type="number"/> puntos
              </td>
              <td>
                <button
                  class="button-del"
                  mat-icon-button
                  (click)="BorrarCuenta('activas', idx)"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
          <button
            class="button-add"
            *ngIf="AnalisisPM.caracter.resumen_creditos_abiertos.length < 10"
            mat-icon-button
            style="align-self: flex-end; margin-left: 12px"
            (click)="AgregarCuenta('activas')"
          >
            <mat-icon>add</mat-icon>
          </button>
        </table>
        </div>
        <span class="puntuacion small" style="align-self: flex-end"
          >{{ AnalisisPM.caracter.total_puntos_creditos_abiertos}} puntos</span
        >
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
          "
        >
        <table>
          <thead><h2>Cerradas</h2></thead>
          <tbody class="formulario analisis" style="margin-top: 20px">
            <tr>
              <th>Otorgante</th>
              <th>Credito Máximo</th>
              <th>Saldo Insoluto</th>
              <th>Mop Máximo</th>
              <th>Comp. de pago</th>
              <th>Puntos</th>
            </tr>
            <tr
              *ngFor="
                let creditoCerrado of AnalisisPM.caracter
                  .resumen_creditos_cerrados;
                let idx = index
              "
            >
              <td>
                <mat-select [(ngModel)]="creditoCerrado.otorgante" placeholder="Seleccionar...">
                  <mat-option value="Hipotecario">Hipotecario</mat-option>
                  <mat-option value="Automotriz">Automotriz</mat-option>
                  <mat-option value="Crédito Simple">Crédito Simple</mat-option>
                  <mat-option value="Crédito de Nomina">Crédito de Nomina</mat-option>
                  <mat-option value="Credito Personal">Credito Personal</mat-option>
                  <mat-option value="Linea de Crédito">Linea de Crédito</mat-option>
                  <mat-option value="Tarjeta de Crédito">Tarjeta de Crédito</mat-option>
                  <mat-option value="Arrendamiento Puro">Arrendamiento Puro</mat-option>
              </mat-select>
              </td>
              <td>
                <input
                  matInput
                  mask="separator.2"
                  thousandSeparator=","
                  prefix="$ "
                  [(ngModel)]="creditoCerrado.credito_maximo"
                />
              </td>
              <td>
                <input
                  matInput
                  mask="separator.2"
                  thousandSeparator=","
                  prefix="$ "
                  [(ngModel)]="creditoCerrado.saldo_insoluto"
                />
              </td>
              <td>
                <input
                  matInput
                  mask="separator"
                  thousandSeparator=","
                  [(ngModel)]="creditoCerrado.mop_maximo"
                />
              </td>
              <td>
                <mat-select
                  [(ngModel)]="creditoCerrado.comportamiento_pago"
                >
                  <mat-option
                    *ngFor="let cp of COMPORTAMIENTO_PAGO_CREDITO"
                    [value]="cp.id"
                    >{{ cp.nombre }}</mat-option
                  >
                </mat-select>
              </td>

              <td class="centrar-texto">
                <input (ngModelChange)="ActualizarComportamientoCredito()"  [(ngModel)]="creditoCerrado.puntos"  type="number" /> puntos

              </td>
              <td>
                <button
                  class="button-del"
                  mat-icon-button
                  (click)="BorrarCuenta('cerradas', idx)"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>

          </tbody>
          
          <button
            class="button-add"
            *ngIf="AnalisisPM.caracter.resumen_creditos_cerrados.length < 10"
            mat-icon-button
            style="align-self: flex-end; margin-left: 12px"
            (click)="AgregarCuenta('cerradas')"
          >
            <mat-icon>add</mat-icon>
          </button>
        </table>
        </div>
        <span class="puntuacion small" style="align-self: flex-end">
          {{ AnalisisPM.caracter.total_puntos_creditos_cerrados }} puntos</span
        >
      </div>
    </div>

    <div>
      <div class="title-section">
        <span class="circle">4</span><b>Puntuacion final</b>
      </div>
      <div></div>
      <div>
        <div class="puntuacion" style="margin-left: auto">
          {{ AnalisisPM.puntuacion_caracter }} / 34 puntos
        </div>
        <!-- <h2>Comentarios de crédito</h2>
        <textarea
          maxlength="500"
          placeholder="Comentarios"
          [(ngModel)]="AnalisisPM.comentarios_caracter"
          style="width: 100%"
          rows="5"
        ></textarea> -->
      </div>
    </div>
    <div>
      <div class="container-btn">
        <button class="btn-secondary-new" (click)="guardarYRetroceder()">Atras</button>
        <button class="btn-primary-new" (click)="guardarYAvanzar()">
          <mat-spinner *ngIf="loading" diameter="20" ></mat-spinner> <span *ngIf="!loading">Guardar</span>
        </button>
      </div>
    </div>
  </div>
</div>
