import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService, SolicitudService } from 'src/app/_services';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { AnalisisPM } from './AnalisisPM';
import { StepperNavigationService } from 'src/app/_services/stepper-navigation.service';
import { MatStepper } from '@angular/material/stepper';
import { saveAs } from 'file-saver';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-valuacion-pm',
  templateUrl: './valuacion-pm.component.html',
  styleUrls: ['./valuacion-pm.component.scss']
})

export class ValuacionPMComponent implements OnInit {
  @ViewChild('stepper') private stepper: MatStepper;
  analisisPm: any;
  folio = null;
  isLinear = false;
  frmResumen: FormGroup;
  frmCaracter: FormGroup;
  frmCapacidadPago: FormGroup;
  frmActivo: FormGroup;
  frmAnexo: FormGroup;
  frmMinuta: FormGroup;
  frmCartaResolucion: FormGroup;
  arrayAnalisisPM: any[] = [];
  AnalisisPM: any = {};
  total_participacion_accionistas = 0;
  id_cliente = null;
  loading = false;




  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private solicitudService: SolicitudService,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
    private stepperNavigationService: StepperNavigationService,
    private _snackBar: MatSnackBar


  ) { }
 
  ngAfterViewInit(): void {
    this.stepperNavigationService.setStepper(this.stepper);

  }

   async ngOnInit() {

    this.AnalisisPM = JSON.parse(JSON.stringify(AnalisisPM));
    this.analisisPm = this.AnalisisPM;
    const {
      folio,
    } = JSON.parse(localStorage.getItem("client_details"));
    
    this.folio = folio;
    console.log("FOLIO: ", this.folio);
   
    await this.getAnalisisPMData()
    //console.log("ANALISIS: ",  this.analisisPm);
   
  }

  
  
  actualizarAnalisisPm(nuevoValor:any){
    this.analisisPm = nuevoValor;
  }
  
  async getAnalisisPMData() {
    console.log("Entro a get analisis")
    const res: any = this.solicitudService
      .ObtenerAnalisisCredito(this.folio)
      .subscribe(
        (res: any) => {
          console.log("SI HAY INFO", res)
          if(res.data != null){
            this.AnalisisPM = {
              ...res.data,
            };
            this.analisisPm = {...this.AnalisisPM}

          }else{
            this.getInitData()
          }
         
          
          console.log("Entro a get analisis, OK", res)
          console.log("Entro a get analisis, OK", this.folio)

          console.log(this.AnalisisPM)
        
         console.log("NUEVO ANALISIS PM", this.analisisPm)
        },
        (error)=>{
          console.log("Entro a get analisis, ERROR")

          console.log("ERROR GET INIT DATA")
          this.getInitData()
          

        }

      )
  }

  async getInitData() {
    console.log("Entro a init data")
    const { id_cliente, folio, tipo_persona, comercial, vendor } = JSON.parse(
      localStorage.getItem("client_details")
    );
    console.log("tipo persona", tipo_persona)

    const guardado = { id_cliente, folio, parte: 1, tipo_persona };
    const obtenerTramite: any = await this.solicitudService.ObtenerTramite(
      folio
    );
    const _tramite =
      obtenerTramite.data.length > 0
        ? obtenerTramite.data.filter((tramite) => tramite.confirmada == true)
        : null;
    console.log("OBTENIENDO CONFIRM", _tramite);
    // this.confirmado = _tramite ? _tramite.confirmada : false

    const obtenerMensualidad: any =
      await this.solicitudService.ObtenerMensualidad(
        folio,
        obtenerTramite.data[0].version,
        obtenerTramite.data[0].plazo_confirmado
      );
    console.log("OBTENIENDO TRAMITE", obtenerTramite);
    console.log("OBTENIENDO TRAMITE", obtenerMensualidad);
    console.log("GUARDADO: ", guardado);
    const rawCot = JSON.parse(_tramite[0].raw_cot);
    console.log("OBTENIENDO", rawCot);
    const plazoConfirmado = this.extraerNumeroDeCadena(
      _tramite[0].plazo_confirmado
    );
    console.log("OBTENIENDO PLAZO CONFIRM", plazoConfirmado);
    const cotConfirm = rawCot.periods.find(
      (periods) => periods.period == plazoConfirmado
    );
    console.log("OBTENIENDO PERIODS", cotConfirm);
    this.folio = folio;
    this.id_cliente = id_cliente;
    this.AnalisisPM.cotizacion.comercial = comercial;
    this.AnalisisPM.cotizacion.vendor = vendor;
    this.AnalisisPM.cotizacion.plazo = _tramite[0].plazo_confirmado;
    this.AnalisisPM.cotizacion.mensualidad = this.extraerNumeroDeCadena(cotConfirm.monthly_payment);
    this.AnalisisPM.cotizacion.tiempo_entrega = rawCot.delay_funding;
    this.AnalisisPM.cotizacion.porcentaje_pago_inicial =
      obtenerMensualidad.renta_inicial * 100;
    this.AnalisisPM.cotizacion.factor = (((cotConfirm.active_rate)*12)/2).toFixed(1);
    this.AnalisisPM.cotizacion.monto_pago_inicial =
      cotConfirm.initial_payment_amount;
    this.AnalisisPM.cotizacion.valor_residual = cotConfirm.residual_amount;
    this.AnalisisPM.cotizacion.valor_pagare_final = Math.round((parseFloat( cotConfirm.monthly_payment.replace(/[^0-9.]/g, "") ) * cotConfirm.period) - parseFloat(cotConfirm.residual_amount.replace(/[^0-9.]/g, "") ));
    this.AnalisisPM.folio = this.folio
    this.analisisPm = {...this.AnalisisPM}
    console.log("OBTENIENDO PERIODS",  this.AnalisisPM.cotizacion.factor );

    this.solicitudService.getDatosGenerales({ guardado }).subscribe((res) => {
      console.log("Parte: ", res);
    
      this.AnalisisPM.razon_social = res.razon_social;
      this.AnalisisPM.caracteristica_activo.activo = res.activo_arrendar;
      this.AnalisisPM.caracteristica_activo.monto_activo = res.monto_linea;
      this.AnalisisPM.cotizacion.mar_total =
        parseFloat(this.AnalisisPM.caracteristica_activo.monto_activo) -
        parseFloat(
          this.AnalisisPM.cotizacion.monto_pago_inicial.replace(/[^0-9.]/g, "")
        );
        // this.actualizarAnalisisPm(this.AnalisisPM)
        this.analisisPm = {...this.AnalisisPM}


    });
    // guardado.parte = 2;
    // this.solicitudService.getDatosGenerales({ guardado }).subscribe((res) => {
    //   console.log("Parte 2: ", res);
    //   this.AnalisisPM.representante_legal = `${res.rl_nombres} ${res.rl_apellidos}`;
    //   this.AnalisisPM.direccion_pm= res.domicilio_empresa;
    //   // this.actualizarAnalisisPm(this.AnalisisPM)

    //   // this.DatosGeneralesDos = res;
    // });
    guardado.parte = 3;
    this.solicitudService.getDatosGenerales({ guardado }).subscribe((res) => {
      console.log("Parte 3: ", res);
      this.AnalisisPM.accionistas[0].nombre_accionista = res.accionista1 ?? "";
      this.AnalisisPM.accionistas[0].participacion = res.participacion1 ?? "";
      this.AnalisisPM.accionistas[1].nombre_accionista = res.accionista2 ?? "";
      this.AnalisisPM.accionistas[1].participacion = res.participacion2 ?? "";
      this.AnalisisPM.accionistas[2].nombre_accionista = res.accionista3 ?? "";
      this.AnalisisPM.accionistas[2].participacion = res.participacion3 ?? "";
      this.total_participacion_accionistas = this.sumarTotales(parseInt(res.participacion1),
      parseInt(res.participacion2),
      parseInt(res.participacion3));
      console.log("PARTICIPACIOn: ", this.total_participacion_accionistas);
      console.log("ANALISIS", AnalisisPM);
      this.analisisPm = {...this.AnalisisPM}


    });

    // Obtenemos datos del aval
    guardado.parte = 1;
    this.solicitudService.geteAval({ guardado }).subscribe(async ({ body }) => {
      console.log("AVAL", body);
      this.AnalisisPM.aval = body.aval_nombre_rs;
      this.AnalisisPM.direccion_aval = body.aval_domicilio
      this.AnalisisPM.nombre_contacto = body.aval_nombre_rs
      this.AnalisisPM.telefono_contacto = body.aval_telefono
      // this.actualizarAnalisisPm(this.AnalisisPM)
      this.analisisPm = {...this.AnalisisPM}

    });

    // guardado.parte = 2;
    // this.solicitudService
    //   .geteReferencias({ guardado })
    //   .subscribe(({ body: res }) => {
    //     console.log("REFERENCUAS", res);
        
    //   });
  }
  sumarTotales(...valores: number[]): number {
    return valores.reduce((total, valor) => total + (valor || 0), 0);
  }
  sumarPromedioAccionistas(){
    this.total_participacion_accionistas = this.sumarTotales(parseInt(this.AnalisisPM.accionistas[0].participacion), parseInt(this.AnalisisPM.accionistas[1].participacion), parseInt(this.AnalisisPM.accionistas[2].participacion))
  }
  puntosComportamiento() {
    if (this.AnalisisPM.comportamiento_pago == "BUENO") {
      this.AnalisisPM.puntos = 10;
    } else if (this.AnalisisPM.comportamiento_pago == "REGULAR") {
      this.AnalisisPM.puntos = 5;
    } else {
      this.AnalisisPM.puntos = 0;
    }
  }
 
  extraerNumeroDeCadena(cadena) {
    const match = cadena.match(/(?:\d{1,3}(?:,\d{3})*(?:\.\d+)?|\d+(?:\.\d+)?)/);
    return match ? parseFloat(match[0].replace(/,/g, '')) : null;
    }

    async DescargarPDFAnalisisCredito() {
      try {
        this.loading = true; 
        console.log('DescargarPDFAnalisisCredito');
    
        const pdf: any = await this.solicitudService.ObtenerPDFAnalisisCredito(this.folio);
        saveAs('data:application/octet-stream;base64,' + pdf.data, `${this.folio}_analisis_credito_pm.pdf`);
      } catch (error) {
        this._snackBar.open(
          "Ha ocurrido un error, por favor vuelve a intentarlo más tarde",
          "Ok",
          {
            duration: 10000,
            horizontalPosition: "center",
            verticalPosition: "top",
            panelClass: ["warning-snackbar"],
          }
        );
        console.error('Error al descargar el PDF del análisis de crédito:', error);
      } finally {
        this.loading = false;
      }
    }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
