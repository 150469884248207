import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-selector-fechas',
  templateUrl: './selector-fechas.component.html',
  styleUrls: ['./selector-fechas.component.scss']
})
export class SelectorFechasComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<SelectorFechasComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
    console.log("Dialog open:", this.data)
  }
  Cerrar() {
    this.dialogRef.close(null)
  }
  Descargar(){
    console.log("Descargando");
    
    this.dialogRef.close(this.data)
  }
}
