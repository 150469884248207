<div class="container">
    <div class="top">
        <div class="header">
            <img class="logo" mat-button height="60px" width="auto" [routerLink]="['/dashboard']" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/logo-header.svg" alt="">
            <div>
                <button mat-button routerLink="/changePassword">Cambiar Contraseña
                    <svg style="height: 20px; width: auto;" xmlns="http://www.w3.org/2000/svg" width="31.992" height="31.992" viewBox="0 0 31.992 31.992"><defs><style>.a{fill:#3ca2d6;}</style></defs><path class="a" d="M18,2a16,16,0,1,0,16,16A16.006,16.006,0,0,0,18,2Zm0,1.391A14.6,14.6,0,1,1,3.391,18,14.594,14.594,0,0,1,18,3.391Zm0,4.868a4.164,4.164,0,0,0-4.173,4.173v2.086h-.7A2.1,2.1,0,0,0,11.041,16.6V23.56a2.1,2.1,0,0,0,2.086,2.086h9.737a2.1,2.1,0,0,0,2.086-2.086V16.6a2.1,2.1,0,0,0-2.086-2.086h-.7V12.432A4.164,4.164,0,0,0,18,8.259ZM18,9.65a2.724,2.724,0,0,1,2.782,2.782v2.086H15.214V12.432A2.724,2.724,0,0,1,18,9.65Zm-4.868,6.259H14.4a.7.7,0,0,0,.227,0h6.728a.7.7,0,0,0,.227,0h1.281a.693.693,0,0,1,.7.7V23.56a.693.693,0,0,1-.7.7H13.128a.693.693,0,0,1-.7-.7V16.6A.693.693,0,0,1,13.128,15.909ZM18,18a1.39,1.39,0,0,0-.7,2.594v.883a.7.7,0,1,0,1.391,0v-.884A1.389,1.389,0,0,0,18,18Z" transform="translate(-2 -2)"/></svg>
                </button>
                <button mat-button (click)="logout()">Cerrar Sesión
                    <svg style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="32" height="31.992" viewBox="0 0 32 31.992"><defs><style>.a{fill:#3ca2d6;}</style></defs><path class="a" d="M18,2A16,16,0,1,0,30.9,27.449a.7.7,0,1,0-1.121-.823,14.6,14.6,0,1,1,0-17.259A.7.7,0,1,0,30.9,8.543,15.976,15.976,0,0,0,18,2Zm9.73,9.73a.7.7,0,0,0-.485,1.194L31.617,17.3H16.6a.7.7,0,1,0,0,1.391H31.617l-4.377,4.377a.7.7,0,1,0,.984.984l5.5-5.5a.7.7,0,0,0,0-1.107l-5.5-5.5A.7.7,0,0,0,27.726,11.73Z" transform="translate(-2 -2)"/></svg>
                </button>
            </div>
        </div>
        <h2>Confirmar Cotización</h2>
        <span>#{{data.folio}}</span>
    </div>
    <div class="body">
        <div>
            <span>Plazo elegido: {{plazo}} meses</span>
            <pdf-viewer  *ngIf="url"
                [src]="url"
                [autoresize]="true"
                [original-size]="false">
            </pdf-viewer>
        </div>
        <div class="validacion">
            <span>Validar cotización</span>
            <!-- <span style="align-self: flex-start;">Escoger opción</span>
            <mat-radio-group [(ngModel)]="data.plazo">
                <mat-radio-button [value]="12">12 Meses</mat-radio-button>
                <mat-radio-button [value]="36">36 Meses</mat-radio-button>
                <mat-radio-button [value]="18">18 Meses</mat-radio-button>
                <mat-radio-button [value]="48">48 Meses</mat-radio-button>
                <mat-radio-button [value]="24">24 Meses</mat-radio-button>
            </mat-radio-group> -->
            <!-- <div class="opciones">
                    <mat-checkbox (click)="data.plazo = 12"  [checked]="data.plazo == 12">12 MESES</mat-checkbox>
                    <mat-checkbox (click)="data.plazo = 36"  [checked]="data.plazo == 36">36 MESES</mat-checkbox>
                    <mat-checkbox (click)="data.plazo = 18"  [checked]="data.plazo == 18">18 MESES</mat-checkbox>
                    <mat-checkbox (click)="data.plazo = 46"  [checked]="data.plazo == 46">46 MESES</mat-checkbox>
                    <mat-checkbox (click)="data.plazo = 24"  [checked]="data.plazo == 24">24 MESES</mat-checkbox>
            </div> -->
            <div class="botones">
                <span class="btn-validacion" [ngClass]="{'rechazar': data.status === 2}" (click)="data.status = 2"><mat-icon>clear</mat-icon></span>
                <span class="btn-validacion" [ngClass]="{'aceptar': data.status === 3}" (click)="data.status = 3"><mat-icon>done</mat-icon></span>
                <!-- <button mat-button [ngClass]="{'active': data.status}" (click)="data.status = true">ACEPTAR COTIZACIÓN</button>
                <button mat-button [ngClass]="{'active': !data.status}" (click)="data.status = false">RECHAZAR COTIZACIÓN</button> -->
            </div>
            <mat-error *ngIf="submit && data.status == 1">Selecciona una opción</mat-error>
            <span class="comentarios-title">Comentarios sobre la cotización</span>
            <textarea [(ngModel)]="data.comentarios" placeholder="Comentarios"></textarea>
            <mat-error *ngIf="submit && !data.comentarios.length">Campo obligatorio</mat-error>

            <mat-spinner *ngIf="loading" diameter="30"></mat-spinner>
            <button *ngIf="!loading" class="btn-confirmar" mat-button [disabled]="data.status == null" (click)="CambiarStatusCotizacion()">Siguiente
                <svg style="height: 25px; width: 25px;" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><defs><style>.a{fill:#3ca2d6;}</style></defs><path class="a" d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0ZM22,38V18.7L13.448,27.25l-3.394-3.394L24,9.908,37.948,23.856,34.554,27.25,26,18.7V38Z" transform="translate(48) rotate(90)"/></svg>
            </button>
            <!-- <button class="btn-confirmar" mat-mini-fab (click)="CambiarStatusCotizacion()"><mat-icon>navigate_next</mat-icon></button> -->
        </div>
    </div>

    <app-socials-footer></app-socials-footer>
</div>







<!-- <div class="container">
    <img src="/assets/images/logo-header-dark.svg" alt="">
    <span>Cotizacion_V{{version}}</span>

    <app-socials-footer></app-socials-footer>
</div> -->