<div class="container-modal">
    <mat-dialog-content class="container-btn">
        <div *ngIf="data.icon_url" class="container-img">
            <img [src]="data.icon_url"/>
        </div>
        <h4>{{data.text1}}</h4>
        <p>{{data.text3}}</p>
    </mat-dialog-content>
    <mat-dialog-actions class="footer-btn">
        <button (click)="guardar()"><img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/check-green.svg" />Si</button>
        <button (click)="cerrar()" ><img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/uncheck-red.svg"/>Regresar</button>
    </mat-dialog-actions>
</div>