export const TipoCartas = ['',
    `<p>En los próximos días procederemos con el envío físico y/o digitalmente de su contrato con las instrucciones para firma, así como una guía prepagada de mensajería para poder enviarnos el documento firmado a nuestras oficinas.</p>`,
    `<p>Para {{carta}}concluir el proceso se agendará una visita física para validar la ubicación del equipo, cabe mencionar que esta visita es parte del proceso de acreditación de clientes por lo que es INDISPENSABLE realizarla. Solicitamos su apoyo con 2 opciones de fecha y horarios para poder acudir.</p>
    <p>Una vez recibido el resultado de la visita y la validación satisfactoría de esta, procederemos con el envío físico y/o digitalmente de su contrato.</p>`,
    `<p>Para proceder con la realización del contrato, solicitamos su apoyo con el envio de los siguientes documentos.</p>
    <div>
        <div><img style="vertical-align: middle;" src="https://static.api.leaseforu.com/assets/list_style.png" alt=""><span>Envio de estados de cuenta bancarios de cuenta de débito o cheques de los tres últimos meses con cadena del SAT (CFDI).</span></div>
        <div><img style="vertical-align: middle;" src="https://static.api.leaseforu.com/assets/list_style.png" alt=""><span>Fotografía de identificación oficial.</span></div>
        <div><img style="vertical-align: middle;" src="https://static.api.leaseforu.com/assets/list_style.png" alt=""><span>Fotografía de identificación oficial de aval.</span></div>
    </div>
    <p>Una vez recibida y validada la documentación, procederemos con el envío fisico y/o digitalmente de su contrato.</p>`
]