<!-- <mat-form-field appearance="outline" [ngClass]="{'valid-input': getFormControl(controlName)?.valid && getFormControl(controlName)?.dirty, 'invalid-input': getFormControl(controlName)?.invalid && getFormControl(controlName)?.dirty}">
    <mat-label [ngClass]="{'valid-label': getFormControl(controlName)?.valid && getFormControl(controlName)?.dirty, 'invalid-label': getFormControl(controlName)?.invalid && getFormControl(controlName)?.dirty}">{{ label }}</mat-label>
    <input [type]="type" matInput [formControl]="getFormControl(controlName)" [ngClass]="{'valid-label': getFormControl(controlName)?.valid && getFormControl(controlName)?.dirty, 'invalid-label': getFormControl(controlName)?.invalid && getFormControl(controlName)?.dirty}" >
    <mat-icon matSuffix *ngIf="!getFormControl(controlName)?.invalid && getFormControl(controlName)?.dirty"><img [src]="validIcon"></mat-icon>
    <mat-icon matSuffix *ngIf="getFormControl(controlName)?.invalid && getFormControl(controlName)?.dirty"><img [src]="errorIcon"></mat-icon>
    <mat-error *ngIf="getFormControl(controlName)?.invalid && getFormControl(controlName)?.dirty">{{ errorMessage }}</mat-error>
</mat-form-field> -->

<div class="custom-input" [ngClass]="{'valid-input': getFormControl(controlName)?.valid && getFormControl(controlName)?.dirty, 'invalid-input': getFormControl(controlName)?.invalid && getFormControl(controlName)?.dirty}">
    <label [ngClass]="{'valid-label': getFormControl(controlName)?.valid && getFormControl(controlName)?.dirty, 'invalid-label': getFormControl(controlName)?.invalid && getFormControl(controlName)?.dirty}">{{ label }}</label>
    <ng-container *ngIf="!isPassword; else passwordField">
        <input autocomplete="off" [mask]="mask" [type]="type" placeholder="{{ label }}" [formControl]="getFormControl(controlName)" [ngClass]="{'valid-label': getFormControl(controlName)?.valid && getFormControl(controlName)?.dirty, 'invalid-label': getFormControl(controlName)?.invalid && getFormControl(controlName)?.dirty}" [attr.maxlength]="maxlength ? maxlength : null">
        <span class="valid-icon" *ngIf="!getFormControl(controlName)?.invalid && getFormControl(controlName)?.dirty"></span>
        <span class="error-icon" *ngIf="getFormControl(controlName)?.invalid && getFormControl(controlName)?.dirty"></span>
        <div class="error-message" *ngIf="getFormControl(controlName)?.invalid && getFormControl(controlName)?.dirty">{{ errorMessage }}</div>

    </ng-container>
    <ng-template #passwordField>
        <input autocomplete="new-password" [type]="type" placeholder="{{ label }}" [formControl]="getFormControl(controlName)" [ngClass]="{'valid-label': getFormControl(controlName)?.valid && getFormControl(controlName)?.dirty, 'invalid-label': getFormControl(controlName)?.invalid && getFormControl(controlName)?.dirty}">
        <span class="password-toggle" (click)="togglePasswordVisibility()">
            <img *ngIf="showPassword" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/eye_pass_new.svg"/>
            <img *ngIf="!showPassword" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/eye_pass_new_inactive.svg"/>
        </span>
    </ng-template>
    
</div>
