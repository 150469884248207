import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-politicas',
  templateUrl: './modal-politicas.component.html',
  styleUrls: ['./modal-politicas.component.scss']
})
export class ModalPoliticasComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ModalPoliticasComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {
  }

}
