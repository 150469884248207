<div mat-dialog-content class="selector-fechas-container">
    <div class="header">
        <div></div>
        <h2>Selecciona un rango de fechas</h2>
        <div><mat-icon style="cursor: pointer;" (click)="Cerrar()">close</mat-icon></div>
    </div>
    <div class="container">
        <div class="filter-row">
            <div class="container-date">
                <div class="input">
                  <mat-label class="input-name">Fecha inicial:</mat-label>
                  <input
                    matInput
                    type="date"
                    [(ngModel)]="data.fecha_inicial"
                  />
                  <mat-error *ngIf="data.fecha_inicial_error">Fecha no válida</mat-error>
                </div>
                <div class="input">
                  <mat-label class="input-name">Fecha final:</mat-label>
                  <input
                    matInput
                    type="date"
                    [(ngModel)]="data.fecha_final"
                    [disabled]="!data.fecha_inicial"
                  />
                  <mat-error *ngIf="data.fecha_final && data.fecha_final_error"
                    >Fecha no válida</mat-error
                  >
                  <mat-error *ngIf="!data.fecha_final && data.fecha_inicial"
                    >Selecciona una fecha</mat-error
                  >
                </div>
            </div>
        </div>
    </div>
    <button class="btn-reporte" (click)="Descargar()">Descargar</button>
</div>