

<!-- <div class="progress" *ngIf="tipoPersona == 1">
	<div class="progress-left active" (click)="changePart(1)"></div>
	<div class="progress-right" (click)="changePart(2)"></div>
</div>

<div class="progress" *ngIf="tipoPersona == 2">
	<div class="progress-left active" (click)="changePart(1)"></div>
	<div class="progress-mid" (click)="changePart(2)" ></div>
	<div class="progress-right" (click)="changePart(3)"></div>
</div> -->


<form *ngIf="tipoPersona == 1" [formGroup]="dgFForm" >
	<div class="datos_generales_1">
		<div style="text-align: center; height: 50px;">
			<span><b>Referencias<br>bancarias (3)</b></span>
		</div>
		<div class="accionista-title">Referencia 1</div>
		<mat-form-field appearance="outline">
			<mat-label>Institución</mat-label>
			<input matInput
			[specialCharacters]="['space']"
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="institucion1" 
				[value]="datosRef.institucion1"
				[ngClass]="{ 'is-invalid': submitted && f.institucion1.errors }"
			>
			<mat-error *ngIf="submitted && f.institucion1.errors" class="invalid-feedback">
				<div *ngIf="f.institucion1.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<div class="input">
			<mat-label>Tipo de Cuenta</mat-label>
			<mat-radio-group 
				formControlName="tipo_credito1" 
				[value]="datosRef.tipo_credito1"
				[ngClass]="{ 'is-invalid': submitted && f.tipo_credito1.errors }">
				<mat-radio-button labelPosition="before" value="AHORRO">Ahorro</mat-radio-button>
				<mat-radio-button labelPosition="before" value="INVERSIÓN">Inversión</mat-radio-button>
				<mat-radio-button labelPosition="before" value="TARJETA DE CREDITO">Tarjeta de Crédito</mat-radio-button>
				<mat-radio-button labelPosition="before" value="CRÉDITO">Crédito</mat-radio-button>
			</mat-radio-group>

			<mat-error *ngIf="submitted && f.tipo_credito1.errors" class="invalid-feedback">
				<div *ngIf="f.tipo_credito1.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<mat-form-field appearance="outline">
			<mat-label>Monto</mat-label>
			<input matInput
				mask="separator.2"
				thousandSeparator=","
				prefix="$ "
				formControlName="monto1" 
				[value]="datosRef.monto1"
				[ngClass]="{ 'is-invalid': submitted && f.monto1.errors }"
			>
			<mat-error *ngIf="submitted && f.monto1.errors" class="invalid-feedback">
				<div *ngIf="f.monto1.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label>Antiguedad</mat-label>
			<input matInput
				[specialCharacters]="['space']"
				[patterns]="customPattern"
				mask="E{100}"
				formControlName="antiguedad1" 
				[value]="datosRef.antiguedad1"
				[ngClass]="{ 'is-invalid': submitted && f.antiguedad1.errors }"
			>
			<mat-error *ngIf="submitted && f.antiguedad1.errors" class="invalid-feedback">
				<div *ngIf="f.antiguedad1.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
	</div>

	<div class="datos_generales_1">
		<div style="text-align: center; height: 50px;">
			<span style="height: 30px;"></span>
		</div>
		<div class="accionista-title">Referencia 2</div>
		<mat-form-field appearance="outline">
			<mat-label>Institución</mat-label>
			<input matInput
			[specialCharacters]="['space']"
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="institucion2" 
				[value]="datosRef.institucion2"
				[ngClass]="{ 'is-invalid': submitted && f.institucion2.errors }"
			>
			<mat-error *ngIf="submitted && f.institucion2.errors" class="invalid-feedback">
				<div *ngIf="f.institucion2.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<div class="input">
			<mat-label>Tipo de Cuenta</mat-label>
			<mat-radio-group 
			formControlName="tipo_credito2" 
			[value]="datosRef.tipo_credito2"
			[ngClass]="{ 'is-invalid': submitted && f.tipo_credito2.errors }">
				<mat-radio-button labelPosition="before" value="AHORRO">Ahorro</mat-radio-button>
				<mat-radio-button labelPosition="before" value="INVERSIÓN">Inversión</mat-radio-button>
				<mat-radio-button labelPosition="before" value="TARJETA DE CREDITO">Tarjeta de Crédito</mat-radio-button>
				<mat-radio-button labelPosition="before" value="CRÉDITO">Crédito</mat-radio-button>
			</mat-radio-group>

			<mat-error *ngIf="submitted && f.tipo_credito2.errors" class="invalid-feedback">
				<div *ngIf="f.tipo_credito2.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<mat-form-field appearance="outline">
			<mat-label>Monto</mat-label>
			<input matInput
				mask="separator.2"
				thousandSeparator=","
				prefix="$ "

				formControlName="monto2" 
				[value]="datosRef.monto2"
				[ngClass]="{ 'is-invalid': submitted && f.monto2.errors }"
			>
			<mat-error *ngIf="submitted && f.monto2.errors" class="invalid-feedback">
				<div *ngIf="f.monto2.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label>Antiguedad</mat-label>
			<input matInput
				[specialCharacters]="['space']"
				[patterns]="customPattern"
				mask="E{100}"
				formControlName="antiguedad2" 
				[value]="datosRef.antiguedad2"
				[ngClass]="{ 'is-invalid': submitted && f.antiguedad2.errors }"
			>
			<mat-error *ngIf="submitted && f.antiguedad2.errors" class="invalid-feedback">
				<div *ngIf="f.antiguedad2.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
	</div>

	<div class="datos_generales_1">
		<div style="text-align: center; height: 50px;">
			<span style="height: 30px;"></span>
		</div>
		<div class="accionista-title">Referencia 3</div>

		<mat-form-field appearance="outline">
			<mat-label>Institución</mat-label>
			<input matInput 
			[specialCharacters]="['space']"
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="institucion3" 
				[value]="datosRef.institucion3"
				[ngClass]="{ 'is-invalid': submitted && f.institucion3.errors }"
			>
			<mat-error *ngIf="submitted && f.institucion3.errors" class="invalid-feedback">
				<div *ngIf="f.institucion3.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
		<div class="input">
			<mat-label>Tipo de Credito</mat-label>
			<mat-radio-group 
			formControlName="tipo_credito3" 
				[value]="datosRef.tipo_credito3"
				[ngClass]="{ 'is-invalid': submitted && f.tipo_credito3.errors }">
				<mat-radio-button labelPosition="before" value="AHORRO">Ahorro</mat-radio-button>
				<mat-radio-button labelPosition="before" value="INVERSIÓN">Inversión</mat-radio-button>
				<mat-radio-button labelPosition="before" value="TARJETA DE CREDITO">Tarjeta de Crédito</mat-radio-button>
				<mat-radio-button labelPosition="before" value="CRÉDITO">Crédito</mat-radio-button>
			</mat-radio-group>

			<mat-error *ngIf="submitted && f.tipo_credito3.errors" class="invalid-feedback">
				<div *ngIf="f.tipo_credito3.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>
		<mat-form-field appearance="outline">
			<mat-label>Monto</mat-label>
			<input matInput
				mask="separator.2"
				thousandSeparator=","
				prefix="$ "
				
				formControlName="monto3" 
				[value]="datosRef.monto3"
				[ngClass]="{ 'is-invalid': submitted && f.monto3.errors }"
			>
			<mat-error *ngIf="submitted && f.monto3.errors" class="invalid-feedback">
				<div *ngIf="f.monto3.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label>Antiguedad</mat-label>
			<input matInput
				[specialCharacters]="['space']"
				[patterns]="customPattern"
				mask="E{100}"
				formControlName="antiguedad3" 
				[value]="datosRef.antiguedad3"
				[ngClass]="{ 'is-invalid': submitted && f.antiguedad3.errors }"
			>
			<mat-error *ngIf="submitted && f.antiguedad3.errors" class="invalid-feedback">
				<div *ngIf="f.antiguedad3.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
	</div>

</form>

<!-- 
	------------------------------------------------------------------------------------------------------- 
	--------------------------------------- PERSONA MORAL -------------------------------------------------
	-------------------------------------------------------------------------------------------------------
-->

<form *ngIf="tipoPersona == 2" [formGroup]="dgMForm" >
	<div class="datos_generales_1">
		<div style="text-align: center; height: 50px;">
			<span><b>Referencias<br>bancarias (3)</b></span>
		</div>
		<div class="accionista-title">Referencia 1</div>
		<mat-form-field appearance="outline">
			<mat-label>Institución</mat-label>
			<input matInput  [specialCharacters]="['space']"
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="institucion1" 
				[value]="datosRef.institucion1"
				[ngClass]="{ 'is-invalid': submitted && m.institucion1.errors }"
			>
			<mat-error *ngIf="submitted && m.institucion1.errors" class="invalid-feedback">
				<div *ngIf="m.institucion1.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
		<div class="input">
			<mat-label>Tipo crédito</mat-label>
			<mat-radio-group 
			formControlName="tipo_credito1" 
			[value]="datosRef.tipo_credito1"
			[ngClass]="{ 'is-invalid': submitted && m.tipo_credito1.errors }">
				<mat-radio-button labelPosition="before" [value]="'AHORRO'">Ahorro</mat-radio-button>
				<mat-radio-button labelPosition="before" [value]="'INVERSIÓN'">Inversión</mat-radio-button>
				<mat-radio-button labelPosition="before" [value]="'TARJETA DE CREDITO'">Tarjeta de Crédito</mat-radio-button>
				<mat-radio-button labelPosition="before" [value]="'CRÉDITO'">Crédito</mat-radio-button>
			</mat-radio-group>
			<!-- <input  
				formControlName="tipo_credito1" 
				[value]="datosRef.tipo_credito1"
				[ngClass]="{ 'is-invalid': submitted && m.tipo_credito1.errors }"
			> -->
			<mat-error *ngIf="submitted && m.tipo_credito1.errors" class="invalid-feedback">
				<div *ngIf="m.tipo_credito1.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>
		<mat-form-field appearance="outline">
			<mat-label>Monto</mat-label>
			<input matInput
			mask="separator.2"
			thousandSeparator=","
			prefix="$ "
				formControlName="monto1" 
				[value]="datosRef.monto1"
				[ngClass]="{ 'is-invalid': submitted && m.monto1.errors }"
			>
			<mat-error *ngIf="submitted && m.monto1.errors" class="invalid-feedback">
				<div *ngIf="m.monto1.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label>Vigencia</mat-label>
			<input  matInput
				[specialCharacters]="['space']"
				[patterns]="customPattern"
				mask="E{100}"
				formControlName="vigencia1" 
				[value]="datosRef.vigencia1"
				[ngClass]="{ 'is-invalid': submitted && m.vigencia1.errors }"
			>
			<mat-error *ngIf="submitted && m.vigencia1.errors" class="invalid-feedback">
				<div *ngIf="m.vigencia1.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label>Tasa</mat-label>
			<input matInput
				mask="00.0"
				suffix=" %"
				formControlName="tasa1" 
				[value]="datosRef.tasa1"
				[ngClass]="{ 'is-invalid': submitted && m.tasa1.errors }"
			>
			<mat-error *ngIf="submitted && m.tasa1.errors" class="invalid-feedback">
				<div *ngIf="m.tasa1.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
	</div>

	<div class="datos_generales_1">
		<div style="text-align: center; height: 50px;">
			<span style="height: 30px;"></span>
		</div>
		<div class="accionista-title">Referencia 2</div>
		<mat-form-field appearance="outline">
			<mat-label>Institución</mat-label>
			<input  matInput
			[specialCharacters]="['space']"
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="institucion2" 
				[value]="datosRef.institucion2"
				[ngClass]="{ 'is-invalid': submitted && m.institucion2.errors }"
			>
			<mat-error *ngIf="submitted && m.institucion2.errors" class="invalid-feedback">
				<div *ngIf="m.institucion2.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
		<div class="input size-2">
			<mat-label>Tipo de Crédito</mat-label>
			<mat-radio-group 
			formControlName="tipo_credito2" 
				[value]="datosRef.tipo_credito2"
				[ngClass]="{ 'is-invalid': submitted && m.tipo_credito2.errors }">
				<mat-radio-button labelPosition="before" value="AHORRO">Ahorro</mat-radio-button>
				<mat-radio-button labelPosition="before" value="INVERSIÓN">Inversión</mat-radio-button>
				<mat-radio-button labelPosition="before" value="TARJETA DE CREDITO">Tarjeta de Crédito</mat-radio-button>
				<mat-radio-button labelPosition="before" value="CRÉDITO">Crédito</mat-radio-button>
			</mat-radio-group>
			<!-- <input  
				formControlName="tipo_credito2" 
				[value]="datosRef.tipo_credito2"
				[ngClass]="{ 'is-invalid': submitted && m.tipo_credito2.errors }"
			> -->
			<mat-error *ngIf="submitted && m.tipo_credito2.errors" class="invalid-feedback">
				<div *ngIf="m.tipo_credito2.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>
		<mat-form-field appearance="outline">
			<mat-label>Monto</mat-label>
			<input  matInput
			mask="separator.2"
			thousandSeparator=","
			prefix="$ "
				formControlName="monto2" 
				[value]="datosRef.monto2"
				[ngClass]="{ 'is-invalid': submitted && m.monto2.errors }"
			>
			<mat-error *ngIf="submitted && m.monto2.errors" class="invalid-feedback">
				<div *ngIf="m.monto2.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label>Vigencia</mat-label>
			<input matInput
				[specialCharacters]="['space']"
				[patterns]="customPattern"
				mask="E{100}"
				formControlName="vigencia2" 
				[value]="datosRef.vigencia2"
				[ngClass]="{ 'is-invalid': submitted && m.vigencia2.errors }"
			>
			<mat-error *ngIf="submitted && m.vigencia2.errors" class="invalid-feedback">
				<div *ngIf="m.vigencia2.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
		<mat-form-field appearance="outline">
			<mat-label>Tasa</mat-label>
			<input  matInput
				mask="00.0"
				suffix=" %"
				formControlName="tasa2" 
				[value]="datosRef.tasa2"
				[ngClass]="{ 'is-invalid': submitted && m.tasa2.errors }"
			>
			<mat-error *ngIf="submitted && m.tasa2.errors" class="invalid-feedback">
				<div *ngIf="m.tasa2.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
	</div>

	<div class="datos_generales_1">
		<div style="text-align: center; height: 50px;">
			<span style="height: 30px;"></span>
		</div>
		<div class="accionista-title">Referencia 3</div>
		<mat-form-field appearance="outline">
			<mat-label>Institución</mat-label>
			<input matInput [specialCharacters]="['space']"
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="institucion3" 
				[value]="datosRef.institucion3"
				[ngClass]="{ 'is-invalid': submitted && m.institucion3.errors }"
			>
			<mat-error *ngIf="submitted && m.institucion3.errors" class="invalid-feedback">
				<div *ngIf="m.institucion3.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
		<div class="input size-2">
			
			<mat-label>Tipo de Crédito</mat-label>
			<mat-radio-group 
			formControlName="tipo_credito3" 
				[value]="datosRef.tipo_credito3"
				[ngClass]="{ 'is-invalid': submitted && m.tipo_credito3.errors }">
				<mat-radio-button labelPosition="before" value="AHORRO">Ahorro</mat-radio-button>
				<mat-radio-button labelPosition="before" value="INVERSIÓN">Inversión</mat-radio-button>
				<mat-radio-button labelPosition="before" value="TARJETA DE CREDITO">Tarjeta de Crédito</mat-radio-button>
				<mat-radio-button labelPosition="before" value="CRÉDITO">Crédito</mat-radio-button>
			</mat-radio-group>
			<!-- <input  
				formControlName="tipo_credito3" 
				[value]="datosRef.tipo_credito3"
				[ngClass]="{ 'is-invalid': submitted && m.tipo_credito3.errors }"
			> -->
			<mat-error *ngIf="submitted && m.tipo_credito3.errors" class="invalid-feedback">
				<div *ngIf="m.tipo_credito3.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>
		<mat-form-field appearance="outline">
			
			<mat-label>Monto</mat-label>
			<input  matInput
			mask="separator.2"
			thousandSeparator=","
			prefix="$ "
				formControlName="monto3" 
				[value]="datosRef.monto3"
				[ngClass]="{ 'is-invalid': submitted && m.monto3.errors }"
			>
			<mat-error *ngIf="submitted && m.monto3.errors" class="invalid-feedback">
				<div *ngIf="m.monto3.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label>Vigencia</mat-label>
			<input  matInput
				[specialCharacters]="['space']"
				[patterns]="customPattern"
				mask="E{100}"
				formControlName="vigencia3" 
				[value]="datosRef.vigencia3"
				[ngClass]="{ 'is-invalid': submitted && m.vigencia3.errors }"
			>
			<mat-error *ngIf="submitted && m.vigencia3.errors" class="invalid-feedback">
				<div *ngIf="m.vigencia3.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label>Tasa</mat-label>
			<input  matInput
				mask="00.0"
				suffix=" %"
				formControlName="tasa3" 
				[value]="datosRef.tasa3"
				[ngClass]="{ 'is-invalid': submitted && m.tasa3.errors }"
			>
			<mat-error *ngIf="submitted && m.tasa3.errors" class="invalid-feedback">
				<div *ngIf="m.tasa3.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
	</div>

		<!-- <div class="input size-2">
			<mat-label>Tasa</mat-label>
			<input  
				formControlName="tasa1".0 
				suffix=" %"
				[value]="datosRef.tasa1"
				[ngClass]="{ 'is-invalid': submitted && m.tasa1.errors }"
			>
			<mat-error *ngIf="submitted && m.tasa1.errors" class="invalid-feedback">
				<div *ngIf="m.tasa1.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>
	
		<div class="input size-2">
			
			<input  
				formControlName="tasa2" 
				[value]="datosRef.tasa2"
				[ngClass]="{ 'is-invalid': submitted && m.tasa2.errors }"
			>
			<mat-error *ngIf="submitted && m.tasa2.errors" class="invalid-feedback">
				<div *ngIf="m.tasa2.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		

		<div class="input size-2">
			
			<input  
				formControlName="tasa3" 
				[value]="datosRef.tasa3"
				[ngClass]="{ 'is-invalid': submitted && m.tasa3.errors }"
			>
			<mat-error *ngIf="submitted && m.tasa3.errors" class="invalid-feedback">
				<div *ngIf="m.tasa3.errors.required">Campo obligatorio</div>
			</mat-error>
		</div> -->
	
</form>


<div class="row-end">
	<div></div>
	<mat-spinner *ngIf="loading" diameter="30"></mat-spinner>
	<!-- <button *ngIf="!loading" class="save-button" mat-button (click)="onSubmit(1)" style="height: 60px!important; width: 60px!important;">
		<svg style="height: 30px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="48.14" height="48.14" viewBox="0 0 48.14 48.14"><defs><style>.ab{fill:#fff;}</style></defs><path class="ab" d="M8.439,4A3.377,3.377,0,0,0,5,7.439V48.7A3.377,3.377,0,0,0,8.439,52.14H49.7A3.377,3.377,0,0,0,53.14,48.7V14.544a1.039,1.039,0,0,0-.345-.8l-9.4-9.4A1.039,1.039,0,0,0,42.6,4Zm5.731,2.292H41.678V20.047a2.3,2.3,0,0,1-2.292,2.292H16.462a2.3,2.3,0,0,1-2.292-2.292ZM32.508,9.731V18.9h4.585V9.731ZM16.462,30.362H41.678a2.3,2.3,0,0,1,2.292,2.292V49.847h-29.8V32.654A2.3,2.3,0,0,1,16.462,30.362ZM8.439,46.409h2.292V48.7H8.439Zm38.97,0H49.7V48.7H47.409Z" transform="translate(-5 -4)"/></svg>
	</button> -->
	<button *ngIf="!loading" mat-button (click)="onSubmit(2)">
		Siguiente
		<svg style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><defs><style>.abc{fill:#707070;}</style></defs><path class="abc" d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0ZM22,38V18.7L13.448,27.25l-3.394-3.394L24,9.908,37.948,23.856,34.554,27.25,26,18.7V38Z" transform="translate(48) rotate(90)"/></svg>
	</button>
</div>