import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pipelines-tramites-table',
  templateUrl: './pipelines-tramites-table.component.html',
  styleUrls: ['./pipelines-tramites-table.component.scss']
})
export class PipelinesTramitesTableComponent implements OnInit {
  @Input() headers
  @Input() summary = []
  @Input() data = []

  _headers = []
  showFiltroAvanzado = false

  constructor() { }

  ngOnInit(): void {
    this._headers = Object.keys(this.headers)
  }

}
