<div class="container-stepper-new">
    <div class="stepper">
      <ng-container *ngFor="let step of steps; let i = index">
        <div class="step" [ngClass]="{ 'active': currentStep >= i + 1 }">
          <div class="container-img-step">
            <img *ngIf="step.editMode && !step.complete" [src]="step.editSrc" />
            <img *ngIf="step.complete" [src]="step.completeSrc" />
            <img *ngIf="step.inactive" [src]="step.inactiveSrc" />
          </div>
          <div class="container-img-step-second">
            <img *ngIf="step.editMode && !step.complete" [src]="step.editSecondSrc" />
            <img *ngIf="step.complete" [src]="step.completeSecondSrc" />
            <img *ngIf="step.inactive" [src]="step.inactiveSecondSrc" />
          </div>
          <span [ngClass]="{ 'complete': steps[i].complete }">{{ step.name }}</span>
        </div>
        <div class="container-line" *ngIf="i < steps.length - 1">
          <div class="line-step" [ngClass]="{ 'next': step.complete && !steps[i + 1]?.complete, 'complete-step-line': steps[i + 1]?.complete }"></div>
        </div>
      </ng-container>
    </div>
  </div>
  