<div>
  <div class="auth-types">
    <div class="auth-type" [ngClass]="{'auth-type-active': type == 1}" (click)="type = 1"><img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/writing-sign.svg" alt="type"></div>
    <!-- <div class="auth-type" [ngClass]="{'auth-type-active': type == 2}" (click)="type = 2; sendPin()"><img src="/assets/images/asterisk-nip.svg" alt="type"></div> -->
  </div>
  <form *ngIf="type == 1" [formGroup]="signForm">

    <div class="container-aviso" style="width: 100%;">
      <div class="container-check">
        <input type="checkbox" formControlName="checkAcept"/>
      </div>
      <div class="aviso-privacidad">
        Hoy siendo <span>{{ fechaHoy | date:'dd/MM/yyyy' }}</span>, Autorizo a <a>LEASE FOR YOU SA DE CV.</a> consultar sus antecedentes
crediticios por única ocasión ante las Sociedades de Información Crediticia que estime conveniente,
declarando que conoce la naturaleza, alcance y uso que <a>LEASE FOR YOU SA DE CV.</a> hará de
tal información?
      </div>
    </div>
  </form>

  <form *ngIf="type == 2" [formGroup]="codeForm"  (ngSubmit)="onSubmit()" class="form-code">
    <div class="container-nip" style="height: 200px; margin-top: 20px">
      <div>
        Ingresa el token de validación que se envío al número del usuario que
        ingresaste.
      </div>
      <code-input
      [isCodeHidden]="false"
      [isNonDigitsCode]="false"
      [codeLength]="4"
      (codeChanged)="onFullFill($event)"
      (codeCompleted)="onFullFill($event)"
      >
      </code-input>
      <div class="counter-btn">
        <p class="btn-secondary"  [class.disabled]="disabledButton">{{ formattedCounter }} </p>
        <button class="btn-secondary" (click)="sendPin()" [disabled]="disabledButton"  [class.disabled]="disabledButton">Reenviar código</button>
      </div>
    </div>
    <div class="container-aviso">
      <div class="container-check">
        <input type="checkbox" formControlName="checkAcept" />
      </div>
      <div class="aviso-privacidad">
        Autorizo expresamente a <a>LEASE FOR YOU SA DE CV.</a>, para lleve a cabo
        Investigaciones, sobre mi <a>comportamiento Crediticio </a>
        en SIC que estime conveniente. Conozco la naturaleza y alcance de la
        información que se solicitará, del uso que se le dará y que se podrá
        realizar consultas periódicas de mi historial crediticio, consiento que
        esta autorización tenga vigencia de 3 años contados a partir de hoy, y en
        su caso mientras mantengamos relación jurídica.
      </div>
    </div>
  </form>

  <div class="row-end">
    <button mat-button (click)="back()">
      <svg
        style="height: 20px; width: auto; transform: rotate(180deg)"
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
      >
        <defs>
          <style>
            .cba {
              fill: #3fa4d7;
            }
          </style>
        </defs>
        <path
          class="cba"
          d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0ZM22,38V18.7L13.448,27.25l-3.394-3.394L24,9.908,37.948,23.856,34.554,27.25,26,18.7V38Z"
          transform="translate(48) rotate(90)"
        />
      </svg>
      Regresar
    </button>
    <div></div>
    <mat-spinner
      *ngIf="loading"
      diameter="30"
      style="margin-right: 30px"
    ></mat-spinner>
    <button *ngIf="!loading && type == 1" mat-button (click)="onSubmit()">
      Siguiente
      <svg [ngClass]="{'btnNext': signForm.valid}"  style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><defs><style>.abc{fill:#707070;}</style></defs><path class="abc" d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0ZM22,38V18.7L13.448,27.25l-3.394-3.394L24,9.908,37.948,23.856,34.554,27.25,26,18.7V38Z" transform="translate(48) rotate(90)"/></svg>
    </button>

    <button *ngIf="!loading && type == 2" mat-button (click)="onSubmit()">
      Siguiente
      <svg [ngClass]="{'btnNext': codeForm.valid}"  style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><defs><style>.abc{fill:#707070;}</style></defs><path class="abc" d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0ZM22,38V18.7L13.448,27.25l-3.394-3.394L24,9.908,37.948,23.856,34.554,27.25,26,18.7V38Z" transform="translate(48) rotate(90)"/></svg>
    </button>
  </div>
</div>
