import { Component, OnInit } from '@angular/core';
import { LIST_STATUS } from 'src/app/_helpers/status';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-consulta-buro',
  templateUrl: './consulta-buro.component.html',
  styleUrls: ['./consulta-buro.component.scss']
})
export class ConsultaBuroComponent implements OnInit {

  STATUS = LIST_STATUS;
  procesos = [
    {
      estado: "Actual",
      id_proceso: 1,
      nombre: "Nombre",
      status: 1
    },
    {
      estado: "Actual",
      id_proceso: 2,
      nombre: "Domicilio",
      status: 0
    },
    {
      estado: "Actual",
      id_proceso: 3,
      nombre: "Auth",
      status: 0
    },
    {
      estado: "Actual",
      id_proceso: 4,
      nombre: "Consulta",
      status: 0
    }
  ];
  folio;
  typeP;
  typePer = 1;
  buroResponse;
  idVendor;
  requestData: any = {
		ApellidoPaterno: '',
		ApellidoMaterno: '',
		PrimerNombre: '',
		SegundoNombre: '',
		FechaNacimiento: '',
		RFC: '',
		Nacionalidad: '',
		Sexo:'',
		correo: '',
		id_responsable: null,
		tipo_persona: null,
		Domicilio: {
			Direccion1: '',
			ColoniaPoblacion: '',
			DelegacionMunicipio: '',
			Ciudad: '',
			Estado: '',
			CP: null,
			CodPais: '',
			Numero: ''
		},
    Authorization: {
      type: 2,
      target: '',
      code: ''
    }

	};

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private rutaActiva: ActivatedRoute
  ) {
    let clientDetails = JSON.parse(localStorage.getItem('client_details'));
    this.folio = clientDetails !== null ? clientDetails.folio : '';
    this.typeP = Number(this.rutaActiva.snapshot.params.tipoPersona);
    this.idVendor = JSON.parse(localStorage.getItem('currentUser')).id;
    if (this.typeP > 2) {
      this.typePer = 1;
    }
    if (this.typeP === 1 || this.typeP === 2) {
      this.requestData.id_cliente = Number(JSON.parse(localStorage.getItem('client_details')).id_cliente);
     
      this.requestData.folio = JSON.parse(localStorage.getItem('client_details')).folio;
    }
  }

  ngOnInit(): void {
    this.requestData.id_responsable = Number(this.idVendor);
    this.requestData.tipo_persona = Number(this.typeP);
    const _client =  JSON.parse(localStorage.getItem('client_details'))
    if(_client){
      const {cliente_apellido_paterno,cliente_apellido_materno}  = JSON.parse(localStorage.getItem('client_details'))
      
          this.requestData.ApellidoPaterno = cliente_apellido_paterno
          this.requestData.ApellidoMaterno = cliente_apellido_materno

    }
  }

  changeActive(tipo: number) {
    this.typePer = tipo;
    this.requestData.tipo_persona = tipo
	}

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  getData($event) {
    const { PrimerNombre, ApellidoPaterno, RazonSocial, ApellidoMaterno } = $event
    console.log("SET: ", $event);
    if( $event.tipo_persona == 2 ){
      localStorage.setItem("nombre", `${RazonSocial}`)
    }else{
      localStorage.setItem("nombre", `${PrimerNombre} ${ApellidoPaterno}`)
    }
    this.requestData = $event;
  }

  getBuroResponse($event) {
    this.buroResponse = $event;
  }

  back() {
    console.log('go back');
    this.procesos = [
      {
        estado: "Actual",
        id_proceso: 1,
        nombre: "Nombre",
        status: 1
      },
      {
        estado: "Actual",
        id_proceso: 2,
        nombre: "Domicilio",
        status: 0
      },
      {
        estado: "Actual",
        id_proceso: 3,
        nombre: "NIP",
        status: 0
      },
      {
        estado: "Actual",
        id_proceso: 4,
        nombre: "Consulta",
        status: 0
      }
    ]
  }

}
