import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, AuthenticationService, UserService } from '../../../../_services';
import { SolicitudService } from 'src/app/_services/solicitud.service';


import { customPatterns } from '../../../../_models/maskPattern'
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
/* tslint:disable */

@Component({
	selector: 'app-solicitud-dg-p3',
	templateUrl: './solicitudDGp3.component.html',
	styleUrls: ['./../../solicitud.component.scss']
})

export class SolicitudDGp3Component implements OnInit {

	@Input() tipoPersona: number;
	@Input() solicitud: any;
	@Output() parte = new EventEmitter<number>();
	@Output() onBack = new EventEmitter<number>();
	@Output() stepCompletion3 =new EventEmitter<any>();

	folio = null
	client_details: any = {}
	es_medico = false
	customPattern = customPatterns
	dgFForm: FormGroup;
	dgMForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
	datosGen:any = {}
	perfil = null

	especialidades = [
		"ALGOLOGÍA",
		"ANESTESIOLOGÍA",
		"DERMATOLOGÍA",	
		"ENDOCRINOLOGÍA",
		"CARDIOLOGÍA",
		"CIRUGÍA GENERAL",
		"ESTOMATOLOGÍA",
		"ANGIOLOGÍA",
		"ESPECIALISTA EN DIABETES",
		"ECOGRAFISTA",
		"MEDICINA DE EMERGENCIA",
		"MEDICINA DEL DEPORTE",
		"MEDICINA DEL DOLOR",
		"FISIOTERAPIA",
		"NEUROLOGÍA",
		"GINECOLOGÍA",
		"MEDICINA GENERAL",
		"MEDICINA ESTÉTICA",
		"NEUMOLOGÍA",
		"NEFROLOGÍA",
		"NUTRICIÓN",
		"OFTALMOLOGÍA",
		"TÉCNICO RADIÓLOGO",
		"REUMATOLOGÍA",
		"ONCOLOGÍA",
		"REHABILITACIÓN",
		"ODONTOLOGÍA",
		"PEDIATRA",
		"RADIOLOGÍA E IMAGEN",
		"ORTOPEDIA",
		"VETERINARIA",
		"UROLOGÍA",
	]
    constructor(
        private formBuilder: FormBuilder,
        // private route: ActivatedRoute,
        private router: Router,
        // private authenticationService: AuthenticationService,
        private alertService: AlertService,
		private solicitudService: SolicitudService,
		private dialog:MatDialog,
		private userService:UserService
    ) {
        // redirect to home if already logged in

	}

	ngOnInit() {
		const currentUser = JSON.parse(localStorage.getItem('currentUser'))
		this.client_details = JSON.parse(localStorage.getItem('client_details'))
		this.folio = this.client_details.folio
		
		this.perfil = currentUser.perfil

		this.dgFForm = this.formBuilder.group({
			ocupacion_medica: ['', [Validators.required]],
			anio_grad: ['', Validators.required],
			lugar_recidencia: ['', [Validators.required]],
			pertenece: [''],
			lugares_trabajo: ['', [Validators.required]],
			tipo_consultorio: ['', Validators.required],
			total_gasto: ['', [Validators.required]],
			equipamiento: ['', Validators.required],
			equipo_solicitado: ['', [Validators.required]],
			ingreso_mensual_promedio: ['', [Validators.required]],
			numero_dependientes: [''],
			edad_dependiente1: [''],
			edad_dependiente2: ['', []],
			edad_dependiente3: ['', []],
			edad_dependiente4: ['', []],
			edad_dependiente5: ['', []],
			edad_dependiente6: ['', []],
		});


		this.dgMForm = this.formBuilder.group({
			accionista1: ['', [Validators.required]],
			participacion1: ['', [Validators.required]],
			participacion1_rfc: ['', [Validators.required,Validators.minLength(13)]],
			
			accionista2: ['', []],
			participacion2: ['', []],
			participacion_rfc2: ['', [Validators.minLength(13)]],
			
			accionista3: ['', []],
			participacion3: ['', []],
			participacion_rfc3: ['', [Validators.minLength(13)]],
			
			accionista4: ['', []],
			participacion4: ['', []],
			participacion_rfc4: ['', [Validators.minLength(13)]],
			
			participacion_rfc: ['', [Validators.minLength(13)]],
			
			nacionalidad_matriz: [''],
			grupo_em_opt: [false],
			grupo_emp: [''],
			filial_opt: [false],
			filial: ['']
		});

		// get return url from route parameters or default to '/'
		// this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/dashboard';
		const solicitud = this.solicitudService.getReferencia()
		const tipo_persona = this.tipoPersona
		const parte = 3

		const guardado = { ...solicitud, tipo_persona, parte}
		
		this.getInfoAdicional(guardado);


		const guardado2 = { ...solicitud, tipo_persona, parte: 1}
		console.log("Datos generales: ", guardado2);
		
		this.solicitudService.getDatosGenerales( {guardado: guardado2} ).subscribe( res => {
				console.log("GET: ", res);
				this.es_medico = res.es_medico == 'true'
			}
		)
		this.dgMForm.markAllAsTouched();
		this.dgFForm.markAllAsTouched();
		
	}

	// convenience getter for easy access to form fields
	get f() { return this.dgFForm.controls; }
	get m() { return this.dgMForm.controls; }

	async onSubmit(button: number) {
		this.dgMForm.markAllAsTouched();
		this.dgFForm.markAllAsTouched();
		
		if (!this.userService.alertService.isOnline()) return;
		
		this.loading = true;
		let data: any;
		this.submitted = true;
		const update = localStorage.getItem('update') || false;
		
		// Validación de formularios
		if (this.tipoPersona === 1) {
			if (this.dgFForm.invalid) {
				this.loading = false;
				console.log('Formulario inválido');
				this.stepCompletion3.emit(false);
				return;
			}
			data = this.dgFForm.value;
		} else {
			if (this.dgMForm.invalid) {
				this.loading = false;
				console.log('Formulario inválido:', this.dgMForm.value);
				this.stepCompletion3.emit(false);
				return;
			}
			data = this.dgMForm.value;
		}
	
		// Convertir checkbox a string
		data.pertenece = this.f.pertenece.value ? 'true' : 'false';
		console.log('Llamando a la API para guardar los datos generales...');
	
		// Llamada al servicio para guardar los datos
		(await this.solicitudService.saveDatosGenerales(data, 3, this.tipoPersona, button)).subscribe(
			async (res: any) => {
				console.log('Respuesta del servidor:', res);
				this.loading = false;
				
				// Verificar si es un cliente especial y necesita guardar referencias
				if (this.client_details.id_vendor === 7228 && this.tipoPersona === 1) {
					const dataRef = {
						rp_nombre1: " ", rp_nombre2: " ", rp_nombre3: " ",
						ocupacion1: " ", ocupacion2: " ", ocupacion3: " ",
						rp_direccion1: " ", rp_direccion2: " ", rp_direccion3: " ",
						rp_telefono1: " ", rp_telefono2: " ", rp_telefono3: " ",
						rp_parentesco1: " ", rp_parentesco2: " ", rp_parentesco3: " ",
						guardado: {
							parte: 1,
							tipo_persona: this.client_details.tipo_persona,
							tipo_guardado: 1,
							tipo_aval: 1,
							id_cliente: this.client_details.id_cliente,
							folio: this.client_details.folio,
							update: false
						}
					};
	
					// Llamada adicional al servicio para guardar referencias
					await this.solicitudService.saveReferencias(dataRef, 2, this.tipoPersona, this.tipoPersona === 1 ? 2 : 1).subscribe(
						async (resRef: any) => {
							console.log('Referencias guardadas:', resRef)
							const avalData = {
									aval_omitir: true,
									aval_email: " ",
									aval_nombre_rs: " ",
									aval_apellido_paterno: " ",
									aval_apellido_materno: " ",
									aval_fecha_nac_constitucion: " ",
									aval_rfc: " ",
									aval_domicilio: "  , , , , , C.P. ",
									avalF_estado_civil: "SOLTERO",
									avalF_regimen: " ",
									avalF_nombre_conyugue: " ",
									avalF_domicilio: "PROPIO",
									avalF_valor: 0,
									aval_parentesco: " ",
									aval_tiempo_conocerlo: " ",
									aval_telefono: " ",
									aval_ocupacion: " ",
									aval_genero: "Hombre",
									aval_calle: " ",
									aval_numExterior: " ",
									aval_numInterior: " ",
									aval_colonia: " ",
									aval_ciudad: " ",
									aval_municipio: " ",
									aval_estado: " ",
									aval_codigoPostal: " ",
									tipo_aval: 1,
								
								guardado: {
									parte: 1,
									tipo_persona: this.client_details.tipo_persona,
									tipo_guardado: 1,
									tipo_aval: 1,
									id_cliente: this.client_details.id_cliente,
									folio: this.client_details.folio,
									update: false
								}
							}
							const aval = {
								omitir: true,
							}
								// Llamada adicional al servicio para guardar aval
								await this.solicitudService.saveAval(avalData, 1, this.tipoPersona,  1).subscribe(
									async (resRef: any) => {
										console.log('Aval 1 guardadas:', resRef)
									 const avalp2 = {
										aval_funcion_public: "",
										aval_funcion_public_desc: "",
										aval_familiar_funcion_public: "",
										aval_familiar_funcion_public_desc: "",
										aval_nombre_solicitante: this.client_details.cliente,
										aval_autoriza: true,

									 }
									// Llamada adicional al servicio para guardar aval parte 2
									 await this.solicitudService.saveAval(avalp2, 2, this.tipoPersona,  2).subscribe(
										(resRef: any) => {
											console.log('Aval 2 guardadas:', resRef)
										},
										(err: any) => {
											this.loading = false;
											console.error('Error al guardar las Aval:', err);
										}
									);
									},
									(err: any) => {
										this.loading = false;
										console.error('Error al guardar las Aval:', err);
									}
								);
								await this.solicitudService.GuardarSolicitud(this.folio, {aval})

						},
						(err: any) => {
							this.loading = false;
							console.error('Error al guardar las referencias:', err);
						}
					);
				}
				if(this.client_details.id_vendor === 7228 && this.tipoPersona === 2){
					const refMoral1 = {
						institucion1: " ",
						institucion2: " ",
						institucion3: " ",
						tipo_credito1: " ",
						tipo_credito2: " ",
						tipo_credito3: " ",
						monto1: " ",
						monto2: " ",
						monto3: " ",
						tasa1: " ",
						tasa2: " ",
						tasa3: " ",
						vigencia1: " ",
						vigencia2: " ",
						vigencia3: " ",
						guardado: {
							parte: 1,
							tipo_persona: this.client_details.tipo_persona,
							tipo_guardado: 1,
							tipo_aval: 1,
							id_cliente: this.client_details.id_cliente,
							folio: this.client_details.folio,
							update: false
						}
					}
					await this.solicitudService.saveReferencias(refMoral1, 1, this.tipoPersona,  1).subscribe(
						async (resRef: any) => {
							console.log('Referencias 1 guardadas:', resRef)
							const refMoral2 = {
								proveedor_nombre1: " ",
								proveedor_nombre2: " ",
								proveedor_nombre3: " ",
								proveedor_contacto1: " ",
								proveedor_contacto2: " ",
								proveedor_contacto3: " ",
								proveedor_puesto1: " ",
								proveedor_puesto2: " ",
								proveedor_puesto3: " ",
								proveedor_promedio_compras1: " ",
								proveedor_promedio_compras2: " ",
								proveedor_promedio_compras3: " ",
								proveedor_total1: " ",
								proveedor_total2: " ",
								proveedor_total3: " ",
								guardado: {
									parte: 2,
									tipo_persona: this.client_details.tipo_persona,
									tipo_guardado: 1,
									tipo_aval: 1,
									id_cliente: this.client_details.id_cliente,
									folio: this.client_details.folio,
									update: false
								}
							}
							await this.solicitudService.saveReferencias(refMoral2, 2, this.tipoPersona,  1).subscribe(
								async (resRef: any) => {
									const refMoral3 = {
										cliente_nombre1: " ",
										cliente_nombre2: " ",
										cliente_nombre3: " ",
										cliente_contacto1: " ",
										cliente_contacto2: " ",
										cliente_contacto3: " ",
										cliente_puesto1: " ",
										cliente_puesto2: " ",
										cliente_puesto3: " ",
										cliente_promedio_compras1: " ",
										cliente_promedio_compras2: " ",
										cliente_promedio_compras3: " ",
										cliente_total1: " ",
										cliente_total2: " ",
										cliente_total3: " ",
									}
									console.log('Referencias 2 guardadas:', resRef)
									await this.solicitudService.saveReferencias(refMoral3, 3, this.tipoPersona,  2).subscribe(
										async (resRef: any) => {
											const avalData = {
												aval_omitir: true,
												aval_email: " ",
												aval_nombre_rs: " ",
												aval_apellido_paterno: " ",
												aval_apellido_materno: " ",
												aval_fecha_nac_constitucion: " ",
												aval_rfc: " ",
												aval_domicilio: "  , , , , , C.P. ",
												avalF_estado_civil: "SOLTERO",
												avalF_regimen: " ",
												avalF_nombre_conyugue: " ",
												avalF_domicilio: "PROPIO",
												avalF_valor: 0,
												aval_parentesco: " ",
												aval_tiempo_conocerlo: " ",
												aval_telefono: " ",
												aval_ocupacion: " ",
												aval_genero: "Hombre",
												aval_calle: " ",
												aval_numExterior: " ",
												aval_numInterior: " ",
												aval_colonia: " ",
												aval_ciudad: " ",
												aval_municipio: " ",
												aval_estado: " ",
												aval_codigoPostal: " ",
												tipo_aval: 1,
											
											guardado: {
												parte: 1,
												tipo_persona: this.client_details.tipo_persona,
												tipo_guardado: 1,
												tipo_aval: 1,
												id_cliente: this.client_details.id_cliente,
												folio: this.client_details.folio,
												update: false
											}
										}
										const aval = {
											omitir: true,
										}
											// Llamada adicional al servicio para guardar aval
											await this.solicitudService.saveAval(avalData, 1, this.tipoPersona,  1).subscribe(
												async (resRef: any) => {
													console.log('Aval 1 guardadas:', resRef)
												 const avalp2 = {
													aval_funcion_public: "",
													aval_funcion_public_desc: "",
													aval_familiar_funcion_public: "",
													aval_familiar_funcion_public_desc: "",
													aval_nombre_solicitante: this.client_details.cliente,
													aval_autoriza: true,
			
												 }
												// Llamada adicional al servicio para guardar aval parte 2
												 await this.solicitudService.saveAval(avalp2, 2, this.tipoPersona,  2).subscribe(
													(resRef: any) => {
														console.log('Aval 2 guardadas:', resRef)
													},
													(err: any) => {
														this.loading = false;
														console.error('Error al guardar las Aval:', err);
													}
												);
												},
												(err: any) => {
													this.loading = false;
													console.error('Error al guardar las Aval:', err);
												}
											);
											await this.solicitudService.GuardarSolicitud(this.folio, {aval})
											this.userService.goToDashboard();
										},
										(err: any) => {
											this.loading = false;
											console.error('Error al guardar las Aval:', err);
										}
									);
								},
								(err: any) => {
									this.loading = false;
									console.error('Error al guardar las Aval:', err);
								}
							);
						},
						(err: any) => {
							this.loading = false;
							console.error('Error al guardar las Aval:', err);
						}
					);
				}
	
				// Navegación según el botón presionado
				if (button === 1) {
					this.userService.goToDashboard(); // Ir al dashboard
				} else {
					const dialogref = this.dialog.open(MessageBoxComponent, {
						width: "500px",
						data: {
							text: "Éxito",
							text3: "Tu información se ha guardado",
							icon: "success",
							type: "success"
						}
					});
					dialogref.afterClosed().subscribe(() => {
						this.userService.goToDashboard(); // Ir al dashboard tras cerrar el diálogo
					});
					this.stepCompletion3.emit(true); // Emitir evento de finalización
				}
			},
			(err: any) => {
				this.loading = false;
				console.error('Error al guardar datos generales:', err);
				this.dialog.open(MessageBoxComponent, {
					width: "500px",
					data: {
						text2: "Tu información no se ha guardado",
						icon: "error",
						type: "error"
					}
				});
			}
		);
	
		// Procesar y guardar información adicional
		const profesion = {
			anio_grad: this.f.anio_grad.value,
			lugar_recidencia: this.f.lugar_recidencia.value,
			pertenece: this.f.pertenece.value,
			lugares_trabajo: this.f.lugares_trabajo.value,
			tipo_consultorio: this.f.tipo_consultorio.value,
			total_gasto: this.f.total_gasto.value,
			equipamiento: this.f.equipamiento.value,
			ingreso_mensual_promedio: this.f.ingreso_mensual_promedio.value,
			equipo_solicitado: this.f.equipo_solicitado.value,
			numero_dependientes: this.f.numero_dependientes.value,
			edad_dependiente1: this.f.edad_dependiente1.value,
			edad_dependiente2: this.f.edad_dependiente2.value,
			edad_dependiente3: this.f.edad_dependiente3.value,
			edad_dependiente4: this.f.edad_dependiente4.value,
			edad_dependiente5: this.f.edad_dependiente5.value,
			edad_dependiente6: this.f.edad_dependiente6.value
		};
	
		const corporativa = {
			accionista1: this.m.accionista1.value,
			participacion1: this.m.participacion1.value,
			participacion1_rfc: this.m.participacion1_rfc.value,
			accionista2: this.m.accionista2.value,
			participacion2: this.m.participacion2.value,
			participacion_rfc2: this.m.participacion_rfc2.value,
			accionista3: this.m.accionista3.value,
			participacion3: this.m.participacion3.value,
			participacion_rfc3: this.m.participacion_rfc3.value,
			accionista4: this.m.accionista4.value,
			participacion4: this.m.participacion4.value,
			participacion_rfc4: this.m.participacion_rfc4.value,
			nacionalidad_matriz: this.m.nacionalidad_matriz.value,
			grupo_em_opt: this.m.grupo_em_opt.value,
			grupo_emp: this.m.grupo_emp.value,
			filial_opt: this.m.filial_opt.value,
			filial: this.m.filial.value
		};
	
		const informacionAdicional = this.tipoPersona === 2 ? corporativa : profesion;
	
		console.log("Información adicional procesada:", informacionAdicional);
	
		// Guardar la solicitud final
		await this.solicitudService.GuardarSolicitud(this.folio, {
			esMedico: this.es_medico ? 'Sí' : 'No',
			ocupacionEspecialidad: this.f.ocupacion_medica.value,
			informacionAdicional
		});
	}
	
	setDataDynamo( res ){
		console.log("DYNaMO:",res);
		if( this.tipoPersona == 1 ){
			this.f.ocupacion_medica.setValue( res.informacionAdicional.ocupacion_medica )
			this.f.anio_grad.setValue( res.informacionAdicional.anio_grad )
			this.f.lugar_recidencia.setValue( res.informacionAdicional.lugar_recidencia )
			this.f.pertenece.setValue( res.informacionAdicional.pertenece == 'true' )
			this.f.lugares_trabajo.setValue( res.informacionAdicional.lugares_trabajo )
			this.f.tipo_consultorio.setValue( res.informacionAdicional.tipo_consultorio )
			this.f.total_gasto.setValue( res.informacionAdicional.total_gasto )
			this.f.equipamiento.setValue( res.informacionAdicional.equipamiento )
			this.f.ingreso_mensual_promedio.setValue( res.informacionAdicional.ingreso_mensual_promedio )
			this.f.equipo_solicitado.setValue( res.informacionAdicional.equipo_solicitado || this.client_details.activo_nombre )
			this.f.numero_dependientes.setValue( res.informacionAdicional.numero_dependientes )
			this.f.edad_dependiente1.setValue( res.informacionAdicional.edad_dependiente1 )
			this.f.edad_dependiente2.setValue( res.informacionAdicional.edad_dependiente2 )
			this.f.edad_dependiente3.setValue( res.informacionAdicional.edad_dependiente3 )
			this.f.edad_dependiente4.setValue( res.informacionAdicional.edad_dependiente4 )
			this.f.edad_dependiente5.setValue( res.informacionAdicional.edad_dependiente5 )
			this.f.edad_dependiente6.setValue( res.informacionAdicional.edad_dependiente6 )
		}else{
			this.m.accionista1.setValue( res.informacionAdicional.accionista1 )
			this.m.participacion1.setValue( res.informacionAdicional.participacion1 )
			this.m.participacion1_rfc.setValue( res.informacionAdicional.participacion1_rfc )
			this.m.accionista2.setValue( res.informacionAdicional.accionista2 )
			this.m.participacion2.setValue( res.informacionAdicional.participacion2 )
			this.m.participacion_rfc2.setValue( res.informacionAdicional.participacion_rfc2 )
			this.m.accionista3.setValue( res.informacionAdicional.accionista3 )
			this.m.participacion3.setValue( res.informacionAdicional.participacion3 )
			this.m.participacion_rfc3.setValue( res.informacionAdicional.participacion_rfc3 )
			this.m.accionista4.setValue( res.informacionAdicional.accionista4 )
			this.m.participacion4.setValue( res.informacionAdicional.participacion4 )
			this.m.participacion_rfc4.setValue( res.informacionAdicional.participacion_rfc4 )
			this.m.participacion_rfc.setValue( res.informacionAdicional.participacion_rfc )
			this.m.nacionalidad_matriz.setValue( res.informacionAdicional.nacionalidad_matriz )
			this.m.grupo_em_opt.setValue( res.informacionAdicional.grupo_em_opt )
			this.m.grupo_emp.setValue( res.informacionAdicional.grupo_emp )
			this.m.filial_opt.setValue( res.informacionAdicional.filial_opt )
			this.m.filial.setValue( res.informacionAdicional.filial )

		}
	}

	setData( res ){
		console.log(res);
		if( this.tipoPersona == 1 ){
			this.f.ocupacion_medica.setValue( res.ocupacion_medica )
			this.f.anio_grad.setValue( res.anio_grad )
			this.f.lugar_recidencia.setValue( res.lugar_recidencia )
			this.f.pertenece.setValue( res.pertenece == 'true' )
			this.f.lugares_trabajo.setValue( res.lugares_trabajo )
			this.f.tipo_consultorio.setValue( res.tipo_consultorio )
			this.f.total_gasto.setValue( res.total_gasto )
			this.f.equipamiento.setValue( res.equipamiento )
			this.f.ingreso_mensual_promedio.setValue( res.ingreso_mensual_promedio )
			this.f.equipo_solicitado.setValue( res.equipo_solicitado || this.client_details.activo_nombre )
			this.f.numero_dependientes.setValue( res.numero_dependientes )
			this.f.edad_dependiente1.setValue( res.edad_dependiente1 )
			this.f.edad_dependiente2.setValue( res.edad_dependiente2 )
			this.f.edad_dependiente3.setValue( res.edad_dependiente3 )
			this.f.edad_dependiente4.setValue( res.edad_dependiente4 )
			this.f.edad_dependiente5.setValue( res.edad_dependiente5 )
			this.f.edad_dependiente6.setValue( res.edad_dependiente6 )
		}else{
			this.m.accionista1.setValue( res.accionista1 )
			this.m.participacion1.setValue( res.participacion1 )
			this.m.participacion1_rfc.setValue( res.rfc1 )
			this.m.accionista2.setValue( res.accionista2 )
			this.m.participacion2.setValue( res.participacion2 )
			this.m.participacion_rfc2.setValue( res.participacion_rfc2 )
			this.m.accionista3.setValue( res.accionista3 )
			this.m.participacion3.setValue( res.participacion3 )
			this.m.participacion_rfc3.setValue( res.participacion_rfc3 )
			this.m.accionista4.setValue( res.accionista4 )
			this.m.participacion4.setValue( res.participacion4 )
			this.m.participacion_rfc4.setValue( res.participacion_rfc4 )
			this.m.participacion_rfc.setValue( res.participacion_rfc )
			this.m.nacionalidad_matriz.setValue( res.nacionalidad_matriz )
			this.m.grupo_em_opt.setValue( res.grupo_em_opt )
			this.m.grupo_emp.setValue( res.grupo_emp )
			this.m.filial_opt.setValue( res.filial_opt )
			this.m.filial.setValue( res.filial )

		}
	}

	changePart(part: number){
		this.parte.emit(part);
	}

	back(step) {
		localStorage.setItem('update', 'true')
		this.onBack.emit(step)
	}
	async getInfoAdicional(guardado){
		const infoAdicional = await this.solicitudService.ObtenerSolicitud(this.folio);
		if(infoAdicional.informacionAdicional?.accionista1?.trim().length>0){
			this.setDataDynamo(infoAdicional);
		}else{
			this.solicitudService.getDatosGenerales({guardado}).subscribe( res => {
				console.log("GET: ", res);
				this.datosGen = res
				this.setData( res )
			})
		}
		
	}
}
