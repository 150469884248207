<form [formGroup]="frmRef">
  <div class="container-form" *ngFor="let index of numRefArray">
    <div class="container-title-ref">
      <div class="num-ref">Referencia {{ index + 1 }}</div>
    </div>
    <div style="display: flex; flex-direction: column">
      <app-input-validation
        type="text"
        [formGroup]="frmRef"
        [controlName]="'nombres_ref_' + (index + 1)"
        [errorMessage]=""
        label="Nombre(s)"
        mask=""
      ></app-input-validation>
    </div>
    <div class="container-inputs">
      <div class="column">
        <app-input-validation
          type="text"
          [errorMessage]=""
          [formGroup]="frmRef"
          [controlName]="'apellido_paterno_ref_' + (index + 1)"
          label="Apellido Paterno"
          mask=""
        ></app-input-validation>
        <app-input-validation
          type="text"
          [formGroup]="frmRef"
          [controlName]="'apellido_materno_ref_' + (index + 1)"
          [errorMessage]=""
          label="Apellido Materno"
          mask=""
        ></app-input-validation>
      </div>
      <div class="column" style="margin-left: 15px">
        <app-input-validation
          type="text"
          [formGroup]="frmRef"
          [controlName]="'ocupacion_ref_' + (index + 1)"
          [errorMessage]=""
          label="Ocupación"
          mask=""
        ></app-input-validation>
        <app-input-validation
          type="text"
          [formGroup]="frmRef"
          [controlName]="'tel_ref_' + (index + 1)"
          [errorMessage]=""
          label="Teléfono"
          mask="00-0000-0000"
        ></app-input-validation>
      </div>
    </div>
  </div>
</form>
