<div class="container-principal">
  <div class="container-panel">
    <div class="panel">
      <div class="panel-header" (click)="expandedDocumentosPersonales = !expandedDocumentosPersonales">
        <div class="panel-title" [ngClass]="{ complete: expandedDocumentosPersonales }">
          <div style="display: flex">
            <img
              style="width: 15px; margin-right: 10px"
              src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-enproceso-p.svg"
            />
          </div>
          <div>Documentos personales</div>
        </div>
        <div class="panel-icon" (click)="toggleExpand('documentosPersonales')" style="cursor: pointer;">
          <span (click)="toggleExpand('documentosPersonales')"  >{{ expandedDocumentosPersonales ? "Adjunta tus documentos en PDF" : "" }}</span>
          <img  (click)="toggleExpand('documentosPersonales')"  *ngIf="expandedDocumentosPersonales" style="width: 15px; margin-left: 10px;" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_arriba.svg"/>
          <img  (click)="toggleExpand('documentosPersonales')"  *ngIf="!expandedDocumentosPersonales" style="width: 15px;" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_abajo.svg"/>
        </div>
      </div>
      <div 
        [@expandCollapse]="expandedDocumentosPersonales ? 'expanded' : 'collapsed'"
        class="panel-content"
      >
        <app-documentacion-personal [data]="data"></app-documentacion-personal>
      </div>
    </div>
  </div>

  <div class="container-panel">
    <div class="panel">
      <div class="panel-header" (click)="expandedConyugue = !expandedConyugue">
        <div class="panel-title" [ngClass]="{ complete: expandedConyugue }">
          <div style="display: flex">
            <img
              style="width: 15px; margin-right: 10px"
              src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-enproceso-p.svg"
            />
          </div>
          <div>Cónyugue</div>
        </div>
        <div class="panel-icon" (click)="toggleExpand('conyugue')" style="cursor: pointer;">
          <span (click)="toggleExpand('conyugue')" >{{ expandedConyugue ? "En caso de no tener omite" : "" }}</span>
          <img (click)="toggleExpand('conyugue')" *ngIf="expandedConyugue" style="width: 15px; margin-left: 10px;" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_arriba.svg"/>
          <img (click)="toggleExpand('conyugue')" *ngIf="!expandedConyugue" style="width: 15px;" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_abajo.svg"/>
        </div>
      </div>
      <div 
        [@expandCollapse]="expandedConyugue ? 'expanded' : 'collapsed'"
        class="panel-content"
      >
        <app-conyugue-nueva-solicitud [data]="data" (datosActualizados)="datosActualizadosDos($event)"></app-conyugue-nueva-solicitud>
      </div>
    </div>
  </div>

  <div class="container-panel">
    <div class="panel">
      <div class="panel-header" (click)="expandedAval = !expandedAval">
        <div class="panel-title" [ngClass]="{ complete: expandedAval }">
          <div style="display: flex">
            <img
              style="width: 15px; margin-right: 10px"
              src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-enproceso-p.svg"
            />
          </div>
          <div>Aval</div>
        </div>
        <div class="panel-icon" (click)="toggleExpand('aval')" style="cursor: pointer;">
          <span (click)="toggleExpand('aval')" >{{ expandedAval ? "Ingresa los datos de tu aval" : "" }}</span>
          <img  (click)="toggleExpand('aval')" *ngIf="expandedAval" style="width: 15px; margin-left: 10px;" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_arriba.svg"/>
          <img  (click)="toggleExpand('aval')" *ngIf="!expandedAval" style="width: 15px;" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_abajo.svg"/>
        </div>
      </div>
      <div 
        [@expandCollapse]="expandedAval ? 'expanded' : 'collapsed'"
        class="panel-content"
      >
        <app-aval-nueva-solicitud [data]="data" (datosActualizados)="datosActualizadosDos($event)"></app-aval-nueva-solicitud>
      </div>
    </div>
  </div>

  <div class="container-panel">
    <div class="panel">
      <div class="panel-header" (click)="expandedReferencias = !expandedReferencias">
        <div class="panel-title" [ngClass]="{ complete: expandedReferencias }">
          <div style="display: flex">
            <img
              style="width: 15px; margin-right: 10px"
              src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/step-enproceso-p.svg"
            />
          </div>
          <div>Referencias</div>
        </div>
        <div class="panel-icon" (click)="toggleExpand('referencias')" style="cursor: pointer;">
          <span (click)="toggleExpand('referencias')" >{{ expandedReferencias ? "Ingresa los datos de tus referencias" : "" }}</span>
          <img  (click)="toggleExpand('referencias')" *ngIf="expandedReferencias" style="width: 15px; margin-left: 10px;" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_arriba.svg"/>
          <img  (click)="toggleExpand('referencias')" *ngIf="!expandedReferencias" style="width: 15px;" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_abajo.svg"/>
        </div>
      </div>
      <div 
        [@expandCollapse]="expandedReferencias ? 'expanded' : 'collapsed'"
        class="panel-content"
      >
        <app-referencias-nueva-solicitud [data]="data" (datosActualizados)="datosActualizadosDos($event)"></app-referencias-nueva-solicitud>
      </div>
    </div>
  </div>
</div>

<div class="container-btn-new">
  <button class="btn-secondary-new-format" (click)="atras()">Regresar</button>
  <button class="btn-new-format" (click)="enviarDatos()">Continuar</button>
</div>
