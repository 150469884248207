import { Component, OnInit } from '@angular/core';
import { UserService, AuthenticationService } from 'src/app/_services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-comercial-confirmar-cotizacion',
  templateUrl: './comercial-confirmar-cotizacion.component.html',
  styleUrls: ['./comercial-confirmar-cotizacion.component.scss']
})
export class ComercialConfirmarCotizacionComponent implements OnInit {
  folio = null
  cotizaciones = []
  constructor(
    private authenticationService:AuthenticationService,
    private router:Router,
    private userService:UserService
  ) { }

  ngOnInit(): void {
    const { folio } = JSON.parse(localStorage.getItem('client_details'))
    this.folio = folio
    this.userService.ObtenerCotizacionesPreConfirmadas( this.folio )
    .subscribe(res => {
      console.log("Cotizaciones: ", res);
      this.cotizaciones = res.cotizaciones
    })
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  } 
}
