import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentosService {
  documentos = []

  constructor(private http: HttpClient, private router: Router) {
    this.ObtenerDocumentos()
  }

  async ObtenerDocumentos() {
    const res = await this.http.post<any>(`${environment.config.apiUrl}documentos/documentos_disponibles`, {}).toPromise();
    this.documentos = [...res.data]
  }

  async ActualizarDocumentos(data) {
    return this.http.post<any>(`${environment.config.apiUrl}documentos/actualizar_documentos`, data).toPromise();
  }
}
