<div class="container-pwd">
  <div class="header">
    <img
      class="logo"
      mat-button
      height="50px"
      width="auto"
      [routerLink]="['/dashboard']"
      src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/NewLogo-L4U.png"
      alt="leaseforu"
    />
  </div>
  <div class="container">
    <form [formGroup]="resetForm" #reset="ngForm" (ngSubmit)="onSubmit()">
      <div class="card-pwd">
        <h2>Bienvenido,</h2>
        <h1>Restablecer contraseña</h1>
        <div *ngIf="!fase1">
          <div class="newInput">
            <mat-form-field appearance="outline" class="input-email">
              <mat-label>Correo Electrónico</mat-label>

              <input
                matInput
                type="email"
                formControlName="email"
                [ngClass]="{
                  'invalid-input':
                    (f.email.touched && !f.email.valid) || user_not_register
                }"
                placeholder="Correo Electrónico"
              />

              <mat-error
                *ngIf="!f.email.value && (f.email.touched || submitted)"
                class="invalid-input"
                ><img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/warning.svg" />Ingresar correo
                electrónico</mat-error
              >
              <mat-error
                *ngIf="
                  f.email.value &&
                  !f.email.valid &&
                  (submitted || f.email.touched)
                "
                class="invalid-input"
              >
                <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/warning.svg" />Correo no
                válido, vuelve a intentarlo.</mat-error
              >
              <mat-error *ngIf="user_not_register" class="invalid-input"
                ><img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/warning.svg" />Correo no
                registrado.</mat-error
              >
            </mat-form-field>
          </div>
          <div class="info-pwd">
            <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/info-leaseforu.svg" /> Ingrese el
            correo electrónico a recuperar
          </div>

          <!-- <mat-progress-spinner
            *ngIf="loading"
            mode="indeterminate"
            diameter="25"
          ></mat-progress-spinner>
          <button
            *ngIf="!loading"
            class="button-next"
            mat-mini-fab
            (click)="reset.ngSubmit.emit()"
          >
            <mat-icon>navigate_next</mat-icon>
          </button> -->
          <div class="buttons">
            <button
              mat-raised-button
              color="primary"
              [disabled]="loading"
              class="btn-primary-new"
              (click)="reset.ngSubmit.emit()"
            >
              <span>
                <mat-progress-spinner
                  *ngIf="loading"
                  mode="indeterminate"
                  diameter="30"
                ></mat-progress-spinner
              ></span>
              <span *ngIf="!loading">Restablecer</span>
            </button>
          </div>
        </div>

        <div *ngIf="fase1">
          <div class="newInput">
            <mat-form-field appearance="outline">
              <mat-label>Contraseña Actual</mat-label>
              <input
                matInput
                [type]="passwordHide ? 'text' : 'password'"
                formControlName="password"
                [ngClass]="{
                  'invalid-input':
                    (f.password.touched && !f.password.valid) ||
                    user_not_register
                }"
                placeholder="Contraseña actual"
                maxlength="20"
              />
              <mat-icon
                matSuffix
                (click)="passwordHide = !passwordHide"
                [ngClass]="{
                  'invalid-input': f.password.touched && !f.password.valid
                }"
                >{{ !passwordHide ? "visibility" : "visibility_off" }}</mat-icon
              >
              <!-- <mat-icon
                *ngIf="
                  (!f.password.valid && (submitted || f.password.touched)) ||
                  user_not_register
                "
                [ngClass]="{
                  'invalid-input':
                    (!f.password.valid && f.password.touched) ||
                    user_not_register ||
                    submitted
                }"
                >clear</mat-icon
              > -->
              <mat-error
                *ngIf="!f.password.value && (submitted || f.password.touched)"
                class="invalid-input"
              >
                <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/warning.svg" /> Campo
                obligatorio</mat-error
              >
              <mat-error
                *ngIf="
                  f.password.value &&
                  !f.password.valid &&
                  (submitted || f.password.touched)
                "
                class="invalid-input"
                ><img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/warning.svg" />Contraseña no
                válida, vuelve a intentarlo.</mat-error
              >
            </mat-form-field>
          </div>

          <!-- <mat-error *ngIf="user_not_register" [ngClass]="{'invalid-input': user_not_register}">Contraseña no válida, vuelve a intentarlo.</mat-error> -->

          <div class="tip" *ngIf="false">
            <svg
              style="
                height: 20px;
                width: 30px;
                border: 1px solid white;
                background-color: white;
                border-radius: 180px;
                margin-right: 10px;
              "
              xmlns="http://www.w3.org/2000/svg"
              width="16.138"
              height="16.137"
              viewBox="0 0 16.138 16.137"
            >
              <defs>
                <style>
                  .a {
                    fill: #3f4a57;
                  }
                </style>
              </defs>
              <path
                class="a"
                d="M10.069,2a8.069,8.069,0,1,0,8.069,8.069A8.068,8.068,0,0,0,10.069,2Zm.807,12.1H9.262V12.489h1.614Zm0-3.227H9.262V6.034h1.614Z"
                transform="translate(-2 -2)"
              />
            </svg>
            <!-- <p>Debe de contener de 8 a 20 caracteres</p> -->
          </div>
          <div class="new-pwd">
            <mat-form-field appearance="outline">
              <mat-label>Nueva Contraseña</mat-label>

              <input
                matInput
                [type]="newPasswordHide ? 'text' : 'password'"
                formControlName="newPassword"
                [ngClass]="{
                  'invalid-input':
                    (f.newPassword.touched && !f.newPassword.valid) ||
                    user_not_register
                }"
                placeholder="De 8 a 20 caracteres"
                maxlength="20"
              />
              <!-- <mat-icon
                *ngIf="
                  (!f.newPassword.valid &&
                    (submitted || f.newPassword.touched)) ||
                  user_not_register
                "
                [ngClass]="{
                  'invalid-input':
                    (!f.newPassword.valid && f.newPassword.touched) ||
                    user_not_register ||
                    submitted
                }"
                >clear</mat-icon
              > -->
              <mat-icon
                matSuffix
                (click)="newPasswordHide = !newPasswordHide"
                [ngClass]="{
                  'invalid-input':
                    (f.newPassword.touched && !f.newPassword.valid) ||
                    user_not_register
                }"
                >{{
                  !newPasswordHide ? "visibility" : "visibility_off"
                }}</mat-icon
              >

              <mat-error
                *ngIf="
                  !f.newPassword.value && (submitted || f.newPassword.touched)
                "
                class="invalid-input"
              >
                <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/warning.svg" />Campo
                obligatorio</mat-error
              >
              <mat-error
                *ngIf="
                  f.newPassword.value &&
                  !f.newPassword.valid &&
                  (submitted || f.newPassword.touched)
                "
                class="invalid-input"
              >
                <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/warning.svg" />Contraseña no
                válida, vuelve a intentarlo.</mat-error
              >
            </mat-form-field>
          </div>
          <div class="pwd-validation">
            <p>
              <img
                class="key"
                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/key-leaseforu.svg"
              />Cree una nueva contraseña
            </p>
            <p [ngClass]="resetForm.controls['newPassword'].hasError('required') || resetForm.controls['newPassword'].hasError('minlength')  ? 'text-static' : 'text-success'">
              <img
                class="key-leaseforu"
                [src]="resetForm.controls['newPassword'].hasError('required') || resetForm.controls['newPassword'].hasError('minlength') ? 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/circle-leaseforu.svg' : 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/check.svg'"
              />Mínimo 8 carácteres
            </p>
            <p [ngClass]="resetForm.controls['newPassword'].hasError('required') || resetForm.controls['newPassword'].hasError('hasCapitalCase')  ? 'text-static' : 'text-success'">
              <img
                class="key-leaseforu"
                [src]="resetForm.controls['newPassword'].hasError('required') || resetForm.controls['newPassword'].hasError('hasCapitalCase') ? 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/circle-leaseforu.svg' : 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/check.svg'"
              />Una mayúscula
            </p>
            <p [ngClass]="resetForm.controls['newPassword'].hasError('required') || resetForm.controls['newPassword'].hasError('hasSmallCase')  ? 'text-static' : 'text-success'">
              <img
                class="key-leaseforu"
                [src]="resetForm.controls['newPassword'].hasError('required') || resetForm.controls['newPassword'].hasError('hasSmallCase') ? 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/circle-leaseforu.svg' : 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/check.svg'"
              />Una minúscula
            </p>
            <p [ngClass]="resetForm.controls['newPassword'].hasError('required') || resetForm.controls['newPassword'].hasError('hasNumber')  ? 'text-static' : 'text-success'">
              <img
                class="key-leaseforu"
                [src]="resetForm.controls['newPassword'].hasError('required') || resetForm.controls['newPassword'].hasError('hasNumber') ? 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/circle-leaseforu.svg' : 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/check.svg'"
              />
              Un número
            </p>
          </div>

          <div class="newInput">
            <mat-form-field appearance="outline">
              <mat-label>Confirmar Contraseña</mat-label>

              <input
                matInput
                [type]="confirmPasswordHide ? 'text' : 'password'"
                formControlName="confirmPassword"
                [ngClass]="{
                  'invalid-input':
                    (f.confirmPassword.touched && !f.confirmPassword.valid) ||
                    user_not_register
                }"
                placeholder="Repetir contraseña"
                maxlength="20"
              />
              <!-- <mat-icon
                *ngIf="
                  (!f.confirmPassword.valid &&
                    (submitted || f.confirmPassword.touched)) ||
                  user_not_register
                "
                [ngClass]="{
                  'invalid-input':
                    (!f.confirmPassword.valid && f.confirmPassword.touched) ||
                    user_not_register ||
                    submitted
                }"
                >clear</mat-icon
              > -->
              <mat-icon
                matSuffix
                (click)="confirmPasswordHide = !confirmPasswordHide"
                [ngClass]="{
                  'invalid-input':
                    (f.confirmPassword.touched && !f.confirmPassword.valid) ||
                    user_not_register
                }"
                >{{
                  !confirmPasswordHide ? "visibility" : "visibility_off"
                }}</mat-icon
              >
              <mat-error
                *ngIf="
                  !f.confirmPassword.valid &&
                  (submitted || f.confirmPassword.touched)
                "
                [ngClass]="{
                  'invalid-input':
                    !f.confirmPassword.valid &&
                    (submitted || f.confirmPassword.touched)
                }"
                ><img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/warning.svg" />Contraseñas no
                coinciden, vuelve a intentarlo.</mat-error
              >
            </mat-form-field>
          </div>
          <div class="buttons">
            <button
              mat-raised-button
              color="primary"
              [disabled]="loading"
              class="btn-primary-new"
              (click)="reset.ngSubmit.emit()"
            >
              <span>
                <mat-progress-spinner
                  *ngIf="loading"
                  mode="indeterminate"
                  diameter="30"
                ></mat-progress-spinner
              ></span>
              <span *ngIf="!loading">Crear contraseña</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="!fase1" class="container-footer">
    <div class="text-footer">
      <p>© 2020, Lease For U S.A. de C.V. Todos los derechos reservados.</p>
    </div>
  </div>
  <div *ngIf="fase1" class="container-footer">
    <div class="text-footer">
      <p>© 2020, Lease For U S.A. de C.V. Todos los derechos reservados.</p>
    </div>
  </div>
</div>
