<div class="container">
    <div class="top">
        <div class="header">
            <img class="logo" mat-button height="60px" width="auto" [routerLink]="['/dashboard']" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/logo-header.svg" alt="" style="padding-left: 50px;">
            <div>
                <button mat-button routerLink="/changePassword">Cambiar Contraseña
                    <svg style="height: 20px; width: auto;" xmlns="http://www.w3.org/2000/svg" width="31.992" height="31.992" viewBox="0 0 31.992 31.992"><defs><style>.a{fill:#3ca2d6;}</style></defs><path class="a" d="M18,2a16,16,0,1,0,16,16A16.006,16.006,0,0,0,18,2Zm0,1.391A14.6,14.6,0,1,1,3.391,18,14.594,14.594,0,0,1,18,3.391Zm0,4.868a4.164,4.164,0,0,0-4.173,4.173v2.086h-.7A2.1,2.1,0,0,0,11.041,16.6V23.56a2.1,2.1,0,0,0,2.086,2.086h9.737a2.1,2.1,0,0,0,2.086-2.086V16.6a2.1,2.1,0,0,0-2.086-2.086h-.7V12.432A4.164,4.164,0,0,0,18,8.259ZM18,9.65a2.724,2.724,0,0,1,2.782,2.782v2.086H15.214V12.432A2.724,2.724,0,0,1,18,9.65Zm-4.868,6.259H14.4a.7.7,0,0,0,.227,0h6.728a.7.7,0,0,0,.227,0h1.281a.693.693,0,0,1,.7.7V23.56a.693.693,0,0,1-.7.7H13.128a.693.693,0,0,1-.7-.7V16.6A.693.693,0,0,1,13.128,15.909ZM18,18a1.39,1.39,0,0,0-.7,2.594v.883a.7.7,0,1,0,1.391,0v-.884A1.389,1.389,0,0,0,18,18Z" transform="translate(-2 -2)"/></svg>
                </button>
                <button mat-button (click)="logout()">Cerrar Sesión
                    <svg style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="32" height="31.992" viewBox="0 0 32 31.992"><defs><style>.a{fill:#3ca2d6;}</style></defs><path class="a" d="M18,2A16,16,0,1,0,30.9,27.449a.7.7,0,1,0-1.121-.823,14.6,14.6,0,1,1,0-17.259A.7.7,0,1,0,30.9,8.543,15.976,15.976,0,0,0,18,2Zm9.73,9.73a.7.7,0,0,0-.485,1.194L31.617,17.3H16.6a.7.7,0,1,0,0,1.391H31.617l-4.377,4.377a.7.7,0,1,0,.984.984l5.5-5.5a.7.7,0,0,0,0-1.107l-5.5-5.5A.7.7,0,0,0,27.726,11.73Z" transform="translate(-2 -2)"/></svg>
                </button>
            </div>
        </div>
        <div class="secciones">
            <div class="title">Detalle de la solicitud</div>
        </div>
    </div>
    <div class="body">
        <div class="input-folio">
            <label>Folio: </label>
            <input mask="000000" [(ngModel)]="folio" placeholder="123456" maxlength="6" (input)="BuscarTramite()">
            <mat-progress-spinner *ngIf="loading" mode="indeterminate" diameter="25"></mat-progress-spinner>
        </div>

        <div *ngIf="!data && search">No se encontró información sobre el folio: {{folio}}</div>
        <div *ngIf="data">
            <div class="input">
                <mat-label>Selecciona una versión</mat-label>
                <mat-select [(ngModel)]="versionSelected" (selectionChange)="MostrarInfo()">
                    <mat-option *ngFor="let d of data; let i = index" [value]="d.version">Versión {{d.version}}</mat-option>
                </mat-select>
            </div>

            <div *ngIf="versionSelected > 0 && !tramite">No hay información sobre esta cotización</div>
            <div *ngIf="tramite">
                <div>
                    <table class="client-details">
                        <tr><th>País: </th><td>{{tramite.country}}</td></tr>
                        <tr><th>Moneda: </th><td>{{tramite.currency}}</td></tr>
                        <tr><th>Cliente: </th><td>{{tramite.customer}}</td></tr>
                        <tr><th>Costo del equipo con IVA: </th><td>{{tramite.discount_amount}}</td></tr>
                        <tr><th>Tiempo de entrega: </th><td>{{tramite.delay_funding}}</td></tr>
                        <tr><th>Pago a vendor: </th><td>{{tramite.periods[0].vendor_payment}} mes(es)</td></tr>
                        <!-- <tr><th>Comisión de vendor (recibida): </th><td><input mat-input /></td></tr>
                        <tr><th>Comisión de vendor (pagada): </th><td><input mat-input /></td></tr> -->
                        <tr *ngIf="tramite.periods[0].type != 'meses + MSI'"><th>Pago inicial: </th><td>{{tramite.periods[0].initial_payment}}</td></tr>
                        <tr><th>Comisión por apertura con IVA: </th><td>{{tramite.periods[0].comission | currency:'MXN'}}</td></tr>
                        <tr *ngIf="comission_details">
                            <th>Comisión por apertura: </th>
                                <td *ngIf="comission_details.opening_commission_type == 'p'">
                                    {{comission_details.opening_comission_value}}%
                                </td>
                                <td *ngIf="comission_details.opening_commission_type == 'a'">
                                    {{comission_details.opening_comission_value | currency:'MXN'}}
                                </td>
                        </tr>
                        <tr *ngIf="tramite.monthly_insurance_amount_included">
                            <th>Póliza de mantenimiento mensual: </th>
                            <td>{{tramite.monthly_insurance_amount}}</td>
                        </tr>
                        <tr *ngIf="!isAuto"><th>Seguro con IVA: </th><td>{{(amount *0.01) *1.16 | currency:'MXN'}}</td></tr>

                        <tr *ngIf="!isAuto"><th>Seguro mensual con IVA: </th><td>{{tramite.periods[0].seguro | currency:'MXN'}}</td></tr>
                        <!-- <tr><th>Diferencia: </th><td>{{(((amount * 0.01) / 12)-(((amount/1.16)*0.01)*(1+0)/12)) | currency:'MXN'}}</td></tr> -->

                        <tr *ngIf="isAuto"><th>Cotización: </th><td>Auto</td></tr>
                        <tr *ngIf="isAuto"><th>Descripción: </th><td>{{tramite.product_description}}</td></tr>
                        <tr *ngIf="isAuto"><th>Estado: </th><td>{{tramite.periods[0].estado}}</td></tr>
                        <tr *ngIf="isAuto"><th>Nuevo: </th><td>{{tramite.periods[0].auto_nuevo ? 'SÍ' : 'NO'}}</td></tr>
                        <tr *ngIf="isAuto"><th>Año: </th><td>{{tramite.product_year}}</td></tr>
                        <tr *ngIf="isAuto"><th>GPS pago inicial: </th><td>{{tramite.periods[0].gps_inicial | currency:'MXN'}}</td></tr>
                        <tr *ngIf="isAuto"><th>GPS pago mensual: </th><td>{{tramite.periods[0].gps_mensualidad | currency:'MXN'}}</td></tr>
                        <tr *ngIf="isAuto"><th>Tenencia: </th><td>{{tramite.periods[0].tenencia | currency:'MXN'}}</td></tr>
                        <tr *ngIf="isAuto"><th>Placas: </th><td>{{tramite.periods[0].placas | currency:'MXN'}}</td></tr>
                        <tr *ngIf="isAuto"><th>Tipo de Seguro: </th><td>{{tramite.periods[0].seguro_financiado ? 'FINANCIADO' : 'CONTADO'}}</td></tr>
                        <tr *ngIf="isAuto"><th>Monto seguro: </th><td>{{tramite.periods[0].seguro_auto | currency:'MXN'}}</td></tr>
                        <tr *ngIf="isAuto"><th>Monto seguro inicial: </th><td>{{tramite.periods[0].seguro_auto_inicial | currency:'MXN'}}</td></tr>
                        <tr *ngIf="isAuto"><th>Monto seguro mensual: </th><td>{{tramite.periods[0].seguro_auto_mensualidad | currency:'MXN'}}</td></tr>
                        <tr *ngIf="isAuto && asignar_seguro"><th></th><td>
                            <mat-progress-spinner *ngIf="loading" diameter="26" mode="indeterminate"></mat-progress-spinner>
                            <button *ngIf="!loading" class="principal_button" (click)="AsignarSeguro()">Asignar seguro</button>
                        </td></tr>
                    </table>
                </div>
                <div style="display: flex; justify-content: space-between; align-items: center;">
                    <button *ngIf="confirmada" class="principal_button" (click)="DescargarArchivoCsv()">Descargar CSV</button>
                    <button *ngIf="confirmada" class="principal_button" (click)="DescargarInvoices()">Descargar Invoices</button>
                    <div *ngIf="CREANDO_ANEXO" style="display: flex; justify-content: center; width: 120px;">
                        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                    </div>
                    <button *ngIf="(confirmada && PUEDE_CREAR_ANEXO) && !CREANDO_ANEXO" class="principal_button" (click)="CrearAnexo()">Crear anexo</button>
                </div>
                <app-payment-details *ngFor="let period of tramite.periods" [periodo]="period" [confirmada]="(!cot_msi && (period.period == plazo_confirmado || (period.period +1) == plazo_confirmado)) || (cot_msi && (period.period == plazo_confirmado && period.initial_payment.includes(enganche_confirmado)))"></app-payment-details>
            </div>
        </div>
    </div>
    <app-socials-footer style="width:100%;"></app-socials-footer>
</div>