import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { DocumentosService } from 'src/app/_services/documentos.service';

@Component({
  selector: 'app-documentos-vendor',
  templateUrl: './documentos-vendor.component.html',
  styleUrls: ['./documentos-vendor.component.scss']
})
export class DocumentosVendorComponent implements OnInit {
  @Input() id_vendor = 0
  @Input() documentos = {
    documentosSolicitantePF: [],
    documentosSolicitantePM: [],
    documentosAvalPF: [],
    documentosAvalPM: [],
  }

  loading = false

  constructor(
    private documentosService: DocumentosService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  ActualizarDocumentos(documentos, tipo) {
    console.log({documentos, tipo});
    this.documentos[tipo] = [...documentos]
  }

  async GuardarDocumentos() {
    this.loading = true
    const data = {
      id_vendor: this.id_vendor,
      documentos: this.documentos
    }

    await this.documentosService.ActualizarDocumentos(data)
    this.dialog.open(MessageBoxComponent, {
      width: '500px',
      data: {
        type: 'success',
        icon: 'success',
        text: "Documentos actualizados",
				text2: "Se han actualizado los documentos.",
      }
    })
    this.loading = false
  }
}
