import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BuroService, SolicitudService } from 'src/app/_services';
import { MessageBoxComponent } from '../message-box/message-box.component';

@Component({
  selector: 'app-indicar-direccion',
  templateUrl: './indicar-direccion.component.html',
  styleUrls: ['./indicar-direccion.component.scss']
})
export class IndicarDireccionComponent implements OnInit {
  folio: ''
  direccion = {
    direccionCompleta: '',
    calle: '',
    numExt: '',
    numInt: '',
    cp: '',
    estado: '',
    municipio: '',
    colonia: '',
    ciudad: '',
    pais: ''
  }

  colonias = []

  constructor(
    private dialogRef: MatDialogRef<IndicarDireccionComponent>,
    private dialog: MatDialog,
    private buroService: BuroService,
    private solicitudService: SolicitudService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

 async ngOnInit() {
    
    console.log({data: this.data});
    this.folio = this.data.folio
    this.ObtenerDireccionActivo()
  }

  async BuscarCP(cp) {
    console.log(cp.toString().length);
    
    if (cp.toString().length == 5) {
      console.log('Buscar CP');
      const resp: any = await this.buroService.buscarEstado(cp.toString())
      console.log({resp});

      const direccion = resp[0].response
      this.direccion.ciudad = direccion.ciudad
      this.direccion.estado = direccion.estado
      this.direccion.municipio = direccion.municipio
      this.direccion.pais = direccion.pais

      this.colonias = []
      resp.forEach( direccion => {
        this.colonias.push(direccion.response.asentamiento)
      })
    }
  }

  async GuardarDireccionActivo() {
    console.log('GuardarDireccionActivo');
    this.direccion.direccionCompleta = `${this.direccion.calle} ${this.direccion.numExt} ${this.direccion.numInt}, ${this.direccion.colonia}, ${this.direccion.municipio}, ${this.direccion.estado}, C.P.: ${this.direccion.cp}`.trim()
    await this.solicitudService.GuardarSolicitud(this.folio, {direccionActivo: this.direccion})
    this.dialogRef.close({direccionCompleta: this.direccion.direccionCompleta})
    this.dialog.open(MessageBoxComponent, {
      width: '500px',
      data: {
        text2: 'Éxito',
        text3: 'Se ha guardado la dirección con éxito',
        type: 'success',
        icon: 'success'
      }
    });
  }

  async ObtenerDireccionActivo() {
    const solicitud = await this.solicitudService.ObtenerSolicitud(this.folio)
    if (solicitud) {
      const { direccionActivo} = solicitud
      console.log({direccionActivo});
      
      if(direccionActivo) {
        const {
          direccionCompleta,
          calle,
          numExt,
          numInt,
          cp,
          estado,
          municipio,
          colonia,
          ciudad,
          pais,
        } = direccionActivo

        this.direccion = {
          direccionCompleta,
          calle,
          numExt,
          numInt,
          cp,
          estado,
          municipio,
          colonia,
          ciudad,
          pais,
        }
      }
      this.asignarColonia(direccionActivo?.cp);
    }
    
  }
 async asignarColonia(cp){
  if(cp){
    await this.BuscarCP(cp);
  }else{
    return
  }
    
  }
}


