export const referenciasM ={
	referencias:{
		referenciasBancarias:{
			institucion1: "",
			institucion2:"",
			institucion3: "",
			tipo_credito1: "",
			tipo_credito2: "",
			tipo_credito3: "",
			monto1: "",
			monto2: "",
			monto3: "",
			antiguedad1: "",
			antiguedad2: "",
			antiguedad3: ""
		},
		referenciasProveedor:{
			proveedor_nombre1:"",
			proveedor_nombre2:"",
			proveedor_nombre3:"",
			proveedor_contacto1:"",
			proveedor_contacto2:"",
			proveedor_contacto3:"",
			proveedor_puesto1:"",
			proveedor_puesto2:"",
			proveedor_puesto3:"",
			proveedor_promedio_compras1:"",
			proveedor_promedio_compras2:"",
			proveedor_promedio_compras3:"",
			proveedor_total1:"",
			proveedor_total2:"",
			proveedor_total3:"",
		},
		referenciasCliente:{
			cliente_nombre1:"",
			cliente_nombre2:"",
			cliente_nombre3:"",
			cliente_contacto1:"",
			cliente_contacto2:"",
			cliente_contacto3:"",
			cliente_puesto1:"",
			cliente_puesto2:"",
			cliente_puesto3:"",
			cliente_promedio_compras1:"",
			cliente_promedio_compras2:"",
			cliente_promedio_compras3:"",
			cliente_total1:"",
			cliente_total2:"",
			cliente_total3:"",
		}
	}
}