import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-importe',
  templateUrl: './importe.component.html',
  styleUrls: ['./importe.component.scss']
})
export class ImporteComponent implements OnInit {
  @Input() importe = 0
  constructor() { }

  ngOnInit(): void {
  }

}
