import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-actualizar-informacion',
  templateUrl: './actualizar-informacion.component.html',
  styleUrls: ['./actualizar-informacion.component.scss']
})
export class ActualizarInformacionComponent implements OnInit {
  capacidad_pago = true
  folio = null

  constructor(
    private authenticationService:AuthenticationService,
    private router:Router
  ) { }

  ngOnInit(): void {
    const analisis = JSON.parse(localStorage.getItem('actualizar_informacion')) || null
    console.log("Menu: ", analisis);
    const {folio}= JSON.parse(localStorage.getItem('client_details'));
    this.folio = folio
    
    if( analisis ){
      this.capacidad_pago = analisis.parte > 3
    }
  }

  ActualizarAval(){
    console.log("Actualizar aval")
    this.router.navigate(['/actualizar_informacion/actualizar_aval']);
  }

  ActualizarCapacidadPago(){
    console.log("Actualizar Capacidad de Pago")
    this.router.navigate(['/actualizar_informacion/capacidad_pago']);
  }
  ActualizarReferencias(){
    console.log("Actualizar Referencias")
    this.router.navigate(['/actualizar_informacion/actualizar_referencias']);
  }

  ActualizarDocumentacion(){
    console.log("Actualizar Documentacion")
    this.router.navigate([`/dashboard/cliente/${this.folio}/carga_documentos`]);
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
