import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BuroService } from 'src/app/_services';
import { MessageBoxComponent } from '../message-box/message-box.component';

@Component({
  selector: 'app-precalificar-contacto',
  templateUrl: './precalificar-contacto.component.html',
  styleUrls: ['./precalificar-contacto.component.scss']
})
export class PrecalificarContactoComponent implements OnInit {
  loading = false

  constructor(
    private buroService: BuroService,
    private dialogRef: MatDialogRef<PrecalificarContactoComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data) { }
  contacto = null
  currentUser = null

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
    const { contacto } = this.data
    this.contacto = contacto
    console.log('Precalificar: ',{contacto});
    
  }

  ObtenerNombreCompleto() {
    return `${this.contacto.nombreCompleto} ${this.contacto.apellidoPaterno} ${this.contacto.apellidoMaterno}`.trim().toUpperCase()
  }

  async Precalificar() {
    this.loading = true
    const estado = this.buroService.obtenerCodigoEstado(this.contacto.direccion.estado.trim())
    const body = {
      "folio": this.data.folio,
      "ApellidoPaterno": this.contacto.apellidoPaterno.trim(),
      "ApellidoMaterno": this.contacto.apellidoMaterno.trim(),
      "PrimerNombre": this.contacto.nombreCompleto.trim(),
      "SegundoNombre": this.contacto.segundoNombre || ''.trim(),
      "FechaNacimiento": this.contacto.fechaNacimiento.trim(),
      "RFC": this.contacto.rfc,
      "Nacionalidad": "MX",
      "correo": this.contacto.email.trim(),
      "email": this.contacto.email.trim(),
      "id_responsable": this.currentUser.id,
      "tipo_persona": 1,
      "Domicilio": {
        "Direccion1": this.contacto.direccion.calle.trim(),
        "ColoniaPoblacion": this.contacto.direccion.colonia.trim(),
        "DelegacionMunicipio": this.contacto.direccion.municipio.trim(),
        "Ciudad": this.contacto.direccion.ciudad.trim(),
        "Estado": estado.id,
        "CP": this.contacto.direccion.cp.trim(),
        "CodPais": "MX",
        "Numero": `${this.contacto.direccion.numeroExterior} ${this.contacto.direccion.numeroInterior}`.trim()
      }
    };

    console.log({body});
    

    const resp: any = await (await this.buroService.consultaBuro(body)).toPromise()
    console.log({resp});
    this.loading = false
    if (resp && resp.score) {
      this.dialogRef.close({score: resp.score})
      this.dialog.open(MessageBoxComponent,{
        width: "500px",
          data: {
          text: "Precalificar contacto",
          text2: "El contacto se ha precalificado con éxito.",
          icon: "success",
          type: "success",
          },
      })
    } else {
      this.dialog.open(MessageBoxComponent,{
        width: "500px",
          data: {
          text: "Precalificar contacto",
          text2: "Ha ocurrido un error al precalificar el contacto.",
          icon: "error",
          type: "error",
          },
      })
    }
  }

  Close() {
    this.dialogRef.close()
  }

}
