import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { Activo } from "src/app/_models/activo";
import { ActivosService } from "src/app/_services/activos.service";
import { MessageBoxComponent } from "../message-box/message-box.component";
import { Location } from "@angular/common";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-modal-crear-activo",
  templateUrl: "./modal-crear-activo.component.html",
  styleUrls: ["./modal-crear-activo.component.scss"],
})
export class ModalCrearActivoComponent implements OnInit {
  @ViewChild("activoForm") form: NgForm;

  selectedFileName: string;
  selectedFileNameDocRelacionado: string;
  isInputEmpty: boolean = false;
  error_msg = false;
  formData: FormData;
  numero_guia = "";
  isValid = true;
  error_msg_validar = "";
  loading = false;
  Activo: Activo;
  is_loading = false;
  error = false;
  isEditing = false;

  constructor(
    private dialogRef: MatDialogRef<ModalCrearActivoComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private activosService: ActivosService,
    private dialog: MatDialog,
    private location: Location
  ) {
    this.Activo = new Activo();
  }

  ngOnInit(): void {
    if (this.data && this.data.activo) {
      this.Activo = new Activo();
    Object.assign(this.Activo, this.data.activo);
    this.isEditing = true;
    } else {
      this.Activo = new Activo();

      const client_details = JSON.parse(localStorage.getItem('client_details'));
      this.Activo.empresa = "LEASE FOR U";
      this.Activo.proveedor = client_details.vendor;
      this.Activo.name = client_details.activo_descripcion;
      this.Activo.description = client_details.activo_descripcion;
      this.Activo.quantity = 1;
      this.isEditing = false; 
    }
  }
  public chooseOpt(opt: number): void {
    try {
      this.selectedFileName = "";
    } catch (e) {
      console.error("e :", e);
    }

    // this.dialogRef.close(true);
  }

  selectFile(): void {
    console.log("SELECCIONAR FILE");
    const fileInput = document.getElementById("fileInput");
    console.log(fileInput);
    console.log(document.getElementById("fileInput"));
    if (fileInput) {
      fileInput.click();
      console.log(fileInput);
    }
  }

  onFileSelected(event: any): void {
    this.formData = new FormData();
    const selectedFile = event.target.files[0];
    this.selectedFileName = selectedFile.name;
    if (selectedFile) {
      console.log(selectedFile);
      this.formData.append("factura_relacionada", selectedFile);
    }

    console.log("Archivo seleccionado:", selectedFile);
  }
  selectFileDocRelacionado(): void {
    console.log("SELECCIONAR FILE");
    const fileInput = document.getElementById("fileInputDocRelacionado");
    console.log(fileInput);
    console.log(document.getElementById("fileInputDocRelacionado"));
    if (fileInput) {
      fileInput.click();
      console.log(fileInput);
    }
  }

  onFileSelectedDocRelacionado(event: any): void {
    this.formData = new FormData();
    const selectedFile = event.target.files[0];
    this.selectedFileNameDocRelacionado = selectedFile.name;
    if (selectedFile) {
      console.log(selectedFile);
      this.formData.append("documento_relacionado", selectedFile);
    }

    console.log("Archivo seleccionado:", selectedFile);
  }

  checkInputValue(event: any): void {
    this.numero_guia = event.target.value;
    this.isInputEmpty = this.numero_guia === "";
  }
  async guardar() {
    this.formData = new FormData();
    this.formData.append("folio", this.data.folio);

    try {
      this.loading = true;
      // const res = await this.contratoService.ActualizarContrato(this.formData).toPromise();
      // console.log(res);
      // if (res.message == "Contrato actualizado con éxito") {
      //     console.log("Actualizo estado");
      //     this.userService.changeStatus(this.data.folio, 6, 3).subscribe(res => {
      //         console.log(res);
      //         this.userService.changeStatus(this.data.folio, 7, 0).subscribe((res) => {
      //             this.loading = false;
      //             this.dialogRef.close();
      //             window.location.reload();
      //         });
      //     });
      // }
    } catch (e) {
      console.log("ERR", e);
      this.loading = false;
    }
  }

  cerrar() {
    this.form.reset();

    this.selectedFileName = "";
    this.selectedFileNameDocRelacionado = "";
    this.error_msg = false;
    this.error_msg_validar = "";

    this.dialogRef.close();
  }
  checkInput(nameInput: any) {
    this.isInputEmpty = !nameInput;
  }
  async GuardarActivo() {
    this.Activo.name = this.Activo.description
    if (!this.DatosValidos()) {
      this.error = true;
      return;
    }

    this.loading = true;

    this.formData = new FormData();
    this.Activo.name = this.Activo.description
    console.log("ACTIVOS", this.Activo);
    

    for (const key of Object.keys(this.Activo)) {
      if (this.Activo[key]) {
        this.formData.append(key, this.Activo[key]);
      }
    }
    console.log(this.formData);
    
    try {
      let res;
      if (this.isEditing) {
        // Actualizar activo existente
        res = await this.activosService
          .ActualizarActivo(this.Activo.id, this.formData)
          .toPromise();
      } else {
        // Crear nuevo activo
        this.formData.append("folio", this.data.folio);

        res = await this.activosService
          .CrearActivo(this.formData)
          .toPromise();
      }
      
      this.loading = false;
      if (res) {
        console.log(res)
        this.form.reset();

        this.selectedFileName = "";
        this.selectedFileNameDocRelacionado = "";
        this.error_msg = false;
        this.error_msg_validar = "";
        this.data.getActivos();
        this.dialogRef.close();
      }
    } catch (e) {
      console.error(e);
      this.ShowDialog("error", "Hubo un error al guardar el producto");
      this.loading = false;
    }
  }
  DatosValidos(): Boolean {
    console.log(this.Activo.isValid());

    return this.Activo.isValid ? this.Activo.isValid() : false;
  }

  ShowDialog(type, text, text2 = "", home = false) {
    this.dialog
      .open(MessageBoxComponent, {
        width: "500px",
        data: {
          text,
          text2,
          type,
          icon: type,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (home) {
          this.location.back();
        }
      });
  }
}
