import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-consulta-solicitud',
  templateUrl: './consulta-solicitud.component.html',
  styleUrls: ['./consulta-solicitud.component.scss']
})
export class ConsultaSolicitudComponent implements OnInit {

  constructor(
    private authenticationService:AuthenticationService,
    private router:Router
  ) { }

  ngOnInit(): void {
    history.pushState({page: 1}, "Lease for U - Web", "/#/dashboard/solicitudes")
    history.pushState({page: 2}, "Lease for U - Web", "/#/consulta_solicitud")
    localStorage.setItem('update', 'true')
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
