<div class="container-modal">
    <div>
        <img *ngIf="estado" style="margin-top: 20px;" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/correctReferidos.svg">
        <img *ngIf="!estado" style="margin-top: 20px;" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/incorrectReferidos.svg">
    </div>
        <span>{{texto}} {{folio}}</span>

    <div>

    </div>
</div>