<div class="custom-radio">
  <label>{{ label }}</label>
  <div class="options-container">
    <div
      *ngFor="let option of options; let i = index"
      class="radio-option"
      [class.selected]="selectedOption === option.value"
      (click)="updateSelectedOption(option.value)"
    >
      <div class="radio-button"></div>
      <img
      [src]="getOptionImageUrl(option)"
        alt="{{ option.label }}"
        class="radio-img"
      />
      <span>{{ option.label }}</span>
    </div>
  </div>
  <div class="error-message" *ngIf="errorMessage">{{ errorMessage }}</div>
</div>
