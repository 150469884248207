import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SolicitudService } from 'src/app/_services';
import { AnalisisPMService } from 'src/app/_services/analisis.service';
import { StepperNavigationService } from 'src/app/_services/stepper-navigation.service';
import { AnalisisPM } from '../AnalisisPM';

@Component({
  selector: 'app-activo',
  templateUrl: './activo.component.html',
  styleUrls: ['./activo.component.scss']
})
export class ActivoComponent implements OnInit {
  @Input() analisisPm: any;  
  @Output() analisisPmChange = new EventEmitter<any>();
  AnalisisPM: any = {};
  folio = null;
  opcionesResolucion = [
    { id: 1, nombre: "Aprobada" },
    { id: 2, nombre: "Información Adicional" },
    { id: 3, nombre: "Aprobada Condicionalmente" },
    { id: 4, nombre: "Rechazada" }
  ];
  loading = false;
  ngOnChanges(changes: SimpleChanges) {
    if (changes.analisisPm && changes.analisisPm.currentValue !== null && changes.analisisPm.currentValue !== undefined && changes.analisisPm.currentValue !== 0) {
      console.log("Nuevo valor de analisisPm ACTIVO:", this.analisisPm);
      this.AnalisisPM = {...this.analisisPm}
      this.sumarPuntuacionTotalActivo()
      this.sumarPuntuacionCaracteristicaActivo()
    }
  }

  constructor(
    private solicitudService: SolicitudService,
    private stepperNavigationService: StepperNavigationService,
    private analisisPM: AnalisisPMService

  ) { 

  }

  async ngOnInit() {
    const { folio } = JSON.parse(localStorage.getItem("client_details"));
    this.AnalisisPM = JSON.parse(JSON.stringify(AnalisisPM));
    this.folio = folio;
    // this.AnalisisPM.folio = this.folio
    // this.analisisPM.getAnalisisPM()
    // this.analisisPm = {
    //   ...this.AnalisisPM
    // }

    // this.analisisPm = this.AnalisisPM
    // await this.getAnalisisPMData();


  }

  
  sumarTotales(...valores: number[]): number {
    return valores.reduce((total, valor) => total + (valor || 0), 0);
  }
  sumarPuntuacionCaracteristicaActivo(){
    this.AnalisisPM.puntuacion_total_activo = this.sumarTotales(
      parseInt(this.AnalisisPM.caracteristica_activo.puntos_vida_util),
      parseInt(this.AnalisisPM.caracteristica_activo.puntos_mercado_secundario),
      parseInt(this.AnalisisPM.caracteristica_activo.puntos_estimado_colocacion),
      parseInt(this.AnalisisPM.caracteristica_activo.puntos_ubicacion),
      parseInt(this.AnalisisPM.caracteristica_activo.puntos_tiempo_financiamiento),
    )
  }
  sumarPuntuacionTotalActivo(){
    this.AnalisisPM.puntuacion_final_activo = this.sumarTotales(
      parseInt(this.AnalisisPM.puntuacion_total_activo),
      parseInt(this.AnalisisPM.capacidad_pago.total_puntos_razones_financieras),
      parseInt(this.AnalisisPM.puntuacion_caracter)
    )

  }
  obtenerResolucionSeleccionada(id) {
    console.log("OBTENER RESOL",id)
    const opcionSeleccionada = this.opcionesResolucion.find(opcion => opcion.id === id);
    return opcionSeleccionada ? opcionSeleccionada.nombre : '';
  }

  async guardarYRetroceder() {
   
    console.log("Guardando analisis", this.AnalisisPM);
    this.AnalisisPM.resolucion_comite = this.obtenerResolucionSeleccionada(this.AnalisisPM.id_resolucion_comite)
    console.log("RESOLUCIONID", this.AnalisisPM.resolucion_comite)
    const res:any = await this.solicitudService.GuardarAnalisisCredito(
      this.AnalisisPM
    );
    console.log(res);
    if(res.statusCode == 200){
      this.analisisPM.setAnalisisPM(this.AnalisisPM)
      this.stepperNavigationService.previousStep();
    }
  }

  async guardarYAvanzar() {
    this.loading = true;
    this.AnalisisPM.resolucion_comite = this.obtenerResolucionSeleccionada(this.AnalisisPM.id_resolucion_comite)
    console.log("RESOLUCIONID", this.AnalisisPM.resolucion_comite)

    console.log("Guardando analisis", this.AnalisisPM);
    const res:any = await this.solicitudService.GuardarAnalisisCredito(
      this.AnalisisPM
    );
    console.log(res);
    if(res.statusCode == 200){
      this.loading = false;
      this.analisisPmChange.emit(this.AnalisisPM)

      this.stepperNavigationService.nextStep();
    }else{
      this.loading = false;
    }
  }


}
