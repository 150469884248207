import { Component, OnInit } from '@angular/core';
import { AuthenticationService, UserService } from 'src/app/_services';
import { Router } from '@angular/router';
import { MESES } from '../../pages/valuacion/analisis/Analisis'

@Component({
  selector: 'app-historial-cotizaciones',
  templateUrl: './historial-cotizaciones.component.html',
  styleUrls: ['./historial-cotizaciones.component.scss']
})
export class HistorialCotizacionesComponent implements OnInit {

  folio = null
  perfil = null
  cotizaciones = []
  cotizaciones_filtradas = []
  meses = MESES
  ver_detalle = false

  constructor(
    private authenticationService:AuthenticationService,
    private userService:UserService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.folio = JSON.parse(localStorage.getItem('client_details')).folio
    if( !this.userService.alertService.isOnline() ) {
      this.router.navigate(['dashboard','comercial', this.folio])
    }
    this.perfil = Number(JSON.parse(localStorage.getItem('currentUser')).perfil)

    this.ver_detalle = [3,6,12,10,14,15,16,17,18,19,20,18].includes(this.perfil)

    this.userService.VerCotizaciones(this.folio)
    .subscribe( res => {
      console.log("Cotizaciones", res);
      if( res == null ) return
      this.cotizaciones = res.cotizaciones.map( (cot,i ) => {
        return {
          ...cot,
          nombre: `Cotizacion ${res.cotizaciones.length - i}`
        }
      })
      console.log(this.cotizaciones);
      
      this.filtrarCotizacion('')
    }, err => {
      console.log("Error: ", err);
      
    })
  }
  filtrarCotizacion(filtro){
    this.cotizaciones_filtradas = this.cotizaciones.filter( cot => {
      const mes = Number(cot.fecha_creacion.split('T')[0].split('-')[1]) -1
      console.log(mes);
      
      return cot.nombre.includes(filtro) || cot.fecha_creacion.split('T')[0].includes(filtro) || this.meses[mes].toLowerCase().includes(filtro.toLowerCase()) ? true : false 
    })
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  } 
}
