import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MejorarCotizacionJson } from 'src/app/_models/mejorarCotizacion';

@Component({
  selector: 'app-pre-aprobado',
  templateUrl: './pre-aprobado.component.html',
  styleUrls: ['./pre-aprobado.component.scss']
})
export class PreAprobadoComponent implements OnInit {
  mensualidad = 1;
  mejorarCotizacionJson = MejorarCotizacionJson;
  mensualidad_mejorada: any = "";
  charging = true;
  noAprobado = null;
  nombrePros = ""
  nombreProsCom = ""
  apellidoPros = ""
  apellidoProsCom = ""
  constructor(private router: Router) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.charging = false;
    }, 5000);
    this.mejorarCotizacionJson = JSON.parse(
      localStorage.getItem("mejorar_cotizacion")
    );
    this.noAprobado = JSON.parse(localStorage.getItem("noAprobado"));
    if (this.mejorarCotizacionJson) {
      console.log(this.mejorarCotizacionJson);
      this.mensualidad_mejorada = localStorage.getItem("mensualidad_mejorada");
      console.log(this.mensualidad_mejorada);
    } else {
      console.log(this.noAprobado);
      this.nombrePros = localStorage.getItem("nombreProspecto")
      this.apellidoPros = localStorage.getItem("apellidoPros")
      this.nombreProsCom = this.nombrePros.slice(1, -1)
     
      console.log(this.apellidoPros)

    }
  }
}


