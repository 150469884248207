<app-sidenav-v2 [shortMenu]="true">
    <div class="container">
        <div class="top">
            <div class="back">
                <app-go-bak></app-go-bak> 
                <div class="title">Usuario</div>
            </div>
            <span>{{ tipo_evento }} usuario</span>
        </div>

        <div class="box">
            <div class="vendedor_form">
                <form (ngSubmit)="GuardarVendedor()">
                    <div id="vendedor_header">
                        <div *ngIf="Vendedor.id">
                            <div style="display: flex; flex-direction: column; align-items: center; margin-right: 20px; border: 5px;">
                                <div class="container-img">
                                    <div class="container-img">
                                        <div class="container-img-photo">
                                            <img id="vendedor_no_image" [src]="image_preview" alt="Agregar imagen" *ngIf="image_preview == 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/no-photo.png'">
                                            <img id="vendedor_image" [src]="image_preview" alt="Agregar imagen"  *ngIf="image_preview != 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/no-photo.png'">
                                        </div>   
                                    </div>
                                </div>
                                <div style="font-weight: 300; font-size: 12px; width: 65%; text-align: center;">Recuerda que la imagen debe estar en formato .jpg de 800 * 800px y con un peso máximo de 10 MB.
                                </div>
                                <div class="container-input">
                                    <input type="file" 
                                    accept=".jpg, .jpeg" 
                                    name="vendedor_image" 
                                    id="vendedor_image" 
                                    class="inputfile inputfile-1" 
                                    data-multiple-caption="{count} archivos seleccionados" 
                                    (change)="ImagePreview()" />
                                    <label for="vendedor_image">
                                        <img src="../../../../assets/icons/upload-degradated.png" 
                                        style="margin-right: 3px;">
                                    <span class="iborrainputfile">Cargar la imagen</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div id="vendedor_name" style="min-width: 300px;">
                                <div class="custom-form-field">
                                    <label for="name">Nombre</label>
                                    <input [specialCharacters]="['space']" type="text" [patterns]="customPattern"
                                        mask="S{100}" [(ngModel)]="Vendedor.nombres" type="text" name="name" id="name"
                                        (input)="error = false" />
                                    <mat-error *ngIf="error && !Vendedor.nombres">Ingresa un nombre válido</mat-error>
                                </div>

                                <div class="custom-form-field">
                                    <label for="apellido_paterno">Apellido paterno</label>
                                    <input type="text" [patterns]="customPattern" mask="S{100}"
                                        [(ngModel)]="Vendedor.apellido_parterno" type="text" name="apellido_paterno"
                                        id="apellido_paterno" (input)="error = false" />
                                    <mat-error *ngIf="error && !Vendedor.apellido_parterno">Ingresa un apellido
                                        válido</mat-error>
                                </div>

                                <div class="custom-form-field">
                                    <label for="apellido_materno">Apellido materno</label>
                                    <input type="text" [patterns]="customPattern" mask="S{100}"
                                        [(ngModel)]="Vendedor.apellido_materno" type="text" name="apellido_materno"
                                        id="apellido_materno" />
                                </div>

                                <div class="custom-form-field">
                                    <label for="email">Correo electrónico</label>
                                    <input [(ngModel)]="Vendedor.email" type="text" name="email" id="email"
                                        (input)="email_error = false" />
                                    <mat-error *ngIf="email_error">Ingresa un correo válido</mat-error>
                                </div>

                                <div class="custom-form-field">
                                    <label for="phone">Teléfono</label>
                                    <input type="text" mask="(00) 0000 0000" [(ngModel)]="Vendedor.phone" type="text"
                                        name="phone" id="phone" />
                                </div>

                                <div class="custom-form-field">
                                    <label *ngIf="Vendedor.id" for="name">Activo</label>
                                    <input *ngIf="Vendedor.id" class="input-readonly" [disabled]="true" type="text"
                                        name="status" id="status" [value]="Vendedor.status ? 'No' : 'Sí'" />
                                </div>

                                <div class="btn_actions" style="margin-top: 16px;">
                                    <button *ngIf="!is_loading" type="submit" id="btn_guardar_vendedor">
                                        <mat-icon>save</mat-icon><span>Guardar</span>
                                    </button>
                                    <!-- <button *ngIf="Vendedor.id > 0 && !is_loading" class="btn-secondary-main"
                                        style="margin-left: 12px; padding: 0 8px;" (click)="ResetPassword()">Restablecer
                                        contraseña</button> -->
                                    <mat-spinner *ngIf="is_loading" diameter="30"
                                        style="margin-right: 50px;"></mat-spinner>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </div>




        </div>
        <app-socials-footer style="width: 100%;"></app-socials-footer>
    </div>
</app-sidenav-v2>