import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from 'src/app/_services';
import { ContratoService } from 'src/app/_services/contrato.service';

@Component({
  selector: 'app-modal-contrato',
  templateUrl: './modal-contrato.component.html',
  styleUrls: ['./modal-contrato.component.scss']
})
export class ModalContratoComponent implements OnInit {
  optionSelected = null;
  selectedFileName: string;
  isInputEmpty: boolean = true;
  error_msg = false;
  formData: FormData;
  numero_guia= ''
  isValid=true;
  error_msg_validar= '';
  loading = false;


  constructor(private dialogRef: MatDialogRef<ModalContratoComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private contratoService: ContratoService,
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    console.log(this.data)
  }

  public chooseOpt(opt: number): void {
    try {
      this.data.confirmopt(opt);
      this.optionSelected = opt
      this.selectedFileName = ''
    } catch (e) {
      console.error('e :', e);
    }

    // this.dialogRef.close(true);
  }

  selectFile(): void {
    console.log("SELECCIONAR FILE")
    const fileInput = document.getElementById('fileInput');
    console.log(fileInput)
    console.log(document.getElementById('fileInput'))
    if (fileInput) {
      fileInput.click();
      console.log(fileInput)
    }
  }

  onFileSelected(event: any): void {
    this.formData = new FormData();
    const selectedFile = event.target.files[0];
    this.selectedFileName = selectedFile.name;
    if(selectedFile){
      console.log(selectedFile)
      this.formData.append("contrato", selectedFile)
    }

    console.log('Archivo seleccionado:', selectedFile);
  }

  checkInputValue(event: any): void {
    this.numero_guia = event.target.value;
    this.isInputEmpty = this.numero_guia === '';
    
  }

  async guardar() {
    console.log(this.optionSelected);
    if (this.optionSelected == 1) {
        if (!this.numero_guia || !this.selectedFileName) {
            this.isValid = false;
            this.error_msg = true;
            this.error_msg_validar = "Por favor, complete el número de guía y cargue un archivo.";
            return;
        }else{
          this.isValid = true;
          this.error_msg = false;
        }

        this.formData.append("anexo", this.data.anexo);
        this.formData.append("tipo_contrato", this.optionSelected);
        this.formData.append("referencia_envio", this.numero_guia);
        this.formData.append("folio", this.data.folio);
        this.formData.append("status", "6");
    } else {
        this.formData = new FormData();
        this.formData.append("tipo_contrato", this.optionSelected);
        this.formData.append("folio", this.data.folio);
        this.formData.append("status", "6");
    }

    try {
      this.loading = true;
        const res = await this.contratoService.ActualizarContrato(this.formData).toPromise();
        console.log(res);
        if (res.message == "Contrato actualizado con éxito") {
            console.log("Actualizo estado");
            this.userService.changeStatus(this.data.folio, 6, 3).subscribe(res => {
                console.log(res);
                this.userService.changeStatus(this.data.folio, 7, 0).subscribe((res) => {
                    this.loading = false;
                    this.dialogRef.close();
                    window.location.reload();
                });
            });
        }
    } catch (e) {
        console.log("ERR", e);
        this.loading= false;
    }
}


  cerrar(){
    this.dialogRef.close();
  }

}
