<div class="container-documentacion-personal">
  <div class="container-doc">
    <input
      type="file"
      id="document"
      (click)="resset($event)"
      accept="application/pdf"
      (change)="SubirDocumento($event)"
      hidden
    />
    <app-documents-new
      *ngFor="let documento of Documentos.DocumentosSolicitante; let i = index"
      [documento]="documento"
      [index]="i"
      [solicitante]="true"
      style="margin: 10px 0"
      [show]="
        (documento && documento.tipo_documento != 21) ||
        (documento.tipo_documento == 21 && documento.url)
      "
      (Upload)="SeleccionarDocumento(documento, true)"
      (VerDocumento)="VerDocumento(documento, true)"
    >
    </app-documents-new>
    <input
      type="file"
      id="document_est_cuenta"
      (click)="resset($event)"
      accept="application/pdf"
      (change)="SubirDocumento($event)"
      hidden
    />
    <ng-container *ngIf="documentos_estados_cuenta">
        <app-documents-estado-cuenta-new
          [documentos]="documentos_estados_cuenta"
          [solicitante]="true"
          style="margin: 10px 0"
          (Upload)="SeleccionarDocumento($event, true)"
          (VerDocumento)="VerDocumento($event, true)"
        ></app-documents-estado-cuenta-new>
      </ng-container>
  </div>
</div>
