import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { AuthenticationService } from 'src/app/_services';
import { UserService } from 'src/app/_services';
import { saveAs } from 'file-saver';
import { customPatterns } from '../../_models/maskPattern';

@Component({
  selector: 'app-actualizar-aval',
  templateUrl: './actualizar-aval.component.html',
  styleUrls: ['./actualizar-aval.component.scss']
})
export class ActualizarAvalComponent implements OnInit {
  customPattern = customPatterns;
  submit = false;
  loading = false;

  DatosAval: any = {
    id: 0,
    id_perfil: 0,
    nombre: '',
    ocupacion: '',
    tiempo_conocerlo: '',
    recomendacion: true,
    parentesco: '',
    telefonoCasa: '',
    domicilio: '',
    calificacion1_BCscore: 0,
    descripcion_BCscore: '>= 630',
    calificacion1_ICC: 0,
    descripcion_ICC: '>=6',
    calificacion1_Docs: '',
    descripcion_Docs: 'OK',
    calificacion1_parentesco: '',
    descripcion_parentesco: 'Vendor',
    calificacion1_ocupacion: '',
    descripcion_ocupacion: 'Rep Legal',
    puntosBCscore: 0,
    puntosICC: 0,
    puntosDocs: 0,
    puntosParentesco: 0,
    puntosOcupacion: 0,
    sumaPuntos: 0
  };
  comentarios_caracter = '';
  Actualizar = false;
  mostrarComentarios = false;
  mostrarPerfilAval = false;
  datosAvalSeccion = null;
  email_user = null;
  datos_cliente = null;
  requestUpdateAval = null;
  actualizar_informacion = null;
  constructor(private authenticationService: AuthenticationService, private dialog: MatDialog,
              private router: Router, private userService: UserService) { }

  ngOnInit(): void {

    this.datosAvalSeccion = localStorage.getItem('actualizar_informacion') ? JSON.parse(localStorage.getItem('actualizar_informacion')).parte : null;
    this.email_user = JSON.parse(localStorage.getItem('currentUser')).email;
    this.datos_cliente = JSON.parse(localStorage.getItem('client_details'));
    switch (this.datosAvalSeccion) {
      case 2:
        this.mostrarComentarios = true;
        this.mostrarPerfilAval = false;
        break;
      case 3:
        this.mostrarComentarios = true;
        this.mostrarPerfilAval = true;
        break;
      case 4:
        this.mostrarComentarios = true;
        this.mostrarPerfilAval = true;
        break;
      default:
        this.mostrarComentarios = true;
        this.mostrarPerfilAval = true;
        break;
    }
    this.ObtenerInfoAval();
  }

  ObtenerInfoAval() {
    const folio = JSON.parse(localStorage.getItem('client_details')).folio;
    this.actualizar_informacion = JSON.parse(localStorage.getItem('actualizar_informacion')) || null;
    this.userService.ObtenerInfoAval(folio).subscribe((res) => {
      console.log('INFO AVAL -> ', res);
      this.DatosAval.id = res.id_aval;
      this.DatosAval.id_perfil = res.id_perfil_aval;
      this.DatosAval.nombre = res.aval_nombre;
      this.DatosAval.domicilio = res.aval_domicilio;
      this.DatosAval.ocupacion = res.aval_ocupacion;
      this.DatosAval.parentesco = res.aval_parentezco;
      this.DatosAval.tiempo_conocerlo = res.aval_tiempo_conocerlo;
      this.DatosAval.telefonoCasa = res.aval_telefono;
      this.DatosAval.recomendacion = res.lo_recomienda;

      this.DatosAval.calificacion1_BCscore = res.bc_score,
      this.DatosAval.puntosBCscore = res.bc_score_punt;
      this.DatosAval.calificacion1_ICC = res.icc;
      this.DatosAval.puntosICC = res.icc_punt;
      this.DatosAval.calificacion1_Docs = res.documentacion;
      this.DatosAval.puntosDocs = res.documentacion_punt;
      this.DatosAval.puntosParentesco = res.parentezco_punt;
      this.DatosAval.puntosOcupacion = res.ocupacion_punt;
      this.DatosAval.sumaPuntos = res.total;
      this.comentarios_caracter = res.comentarios;

      // this.DatosAval.calificacion1_parentesco = res.aval_parentezco;
      // this.DatosAval.calificacion1_ocupacion = res.aval_ocupacion;

      if ((this.datosAvalSeccion == 3 || this.datosAvalSeccion == 4) && !localStorage.getItem('comentarios')) {
        this.DatosAval.nombre = this.actualizar_informacion.DatosAnalisis.aval_nombre;
        this.DatosAval.parentesco =  this.actualizar_informacion.DatosAnalisis.datos_generales.aval_parentezco;
        this.DatosAval.ocupacion = this.actualizar_informacion.DatosAnalisis.datos_generales.aval_ocupacion;

        this.DatosAval.calificacion1_BCscore = this.actualizar_informacion.Analisis.perfil_aval.bc_score;
        this.DatosAval.puntosBCscore = this.actualizar_informacion.Analisis.perfil_aval.bc_score_punt;
        this.DatosAval.calificacion1_ICC = this.actualizar_informacion.Analisis.perfil_aval.icc;
        this.DatosAval.puntosICC = this.actualizar_informacion.Analisis.perfil_aval.icc_punt;
        this.DatosAval.calificacion1_Docs = this.actualizar_informacion.Analisis.perfil_aval.documentacion;
        this.DatosAval.puntosDocs = this.actualizar_informacion.Analisis.perfil_aval.documentacion_punt;

        // this.DatosAval.calificacion1_parentesco = this.actualizar_informacion.DatosAnalisis.datos_generales.aval_parentezco;
        this.DatosAval.puntosParentesco = this.actualizar_informacion.Analisis.perfil_aval.parentezco_punt;

        // this.DatosAval.calificacion1_ocupacion = this.actualizar_informacion.DatosAnalisis.datos_generales.aval_ocupacion;
        this.DatosAval.puntosOcupacion = this.actualizar_informacion.Analisis.perfil_aval.ocupacion_punt;
        this.DatosAval.sumaPuntos = this.actualizar_informacion.Analisis.perfil_aval.total;
        this.comentarios_caracter = this.actualizar_informacion.Analisis.comentarios_caracter;
      }
    },
    (error) => {
      console.log('Error: ', error);
      this.dialog.open(MessageBoxComponent, {
        width: '500px',
        data: {
          text: 'El servicio no está disponible por el momento.',
          text3: 'Vuelve a intentarlo',
          type: 'error',
          icon: 'error'
        }
      });
    });
  }

  ActualizarAval() {
    this.submit = true;

    if ( !this.isValid() ) {
      return;
    }

    this.requestUpdateAval = {
      tipo: 1,
      email: this.email_user,
      nombreSolicitante: this.datos_cliente.cliente,
      activoArrendar: this.datos_cliente.activo_nombre,
      folio: this.datos_cliente.folio,
      perfil_aval: {
        id: this.DatosAval.id_perfil,
        nombre: this.DatosAval.nombre,
        bc_score: this.DatosAval.calificacion1_BCscore || 0,
        bc_score_descrip: this.DatosAval.descripcion_BCscore,
        bc_score_punt: this.DatosAval.puntosBCscore,
        icc: this.DatosAval.calificacion1_ICC || 0,
        icc_descrip: this.DatosAval.descripcion_ICC,
        icc_punt: this.DatosAval.puntosICC,
        documentacion: this.DatosAval.calificacion1_Docs,
        documentacion_descrip: this.DatosAval.descripcion_Docs,
        documentacion_punt: this.DatosAval.puntosDocs,
        parentezco: this.DatosAval.parentesco,
        parentezco_descrip: this.DatosAval.descripcion_parentesco,
        parentezco_punt: this.DatosAval.puntosParentesco,
        ocupacion: this.DatosAval.ocupacion,
        ocupacion_descrip: this.DatosAval.descripcion_ocupacion,
        ocupacion_punt: this.DatosAval.puntosOcupacion,
        total: this.DatosAval.sumaPuntos
      },
      datos_aval: {
        id: this.DatosAval.id,
        nombre: this.DatosAval.nombre,
        parentezco: this.DatosAval.parentesco,
        domicilio: this.DatosAval.domicilio,
        ocupacion: this.DatosAval.ocupacion,
        telefono: this.DatosAval.telefonoCasa,
        tiempo_conocerlo: this.DatosAval.tiempo_conocerlo,
        lo_recomienda: this.DatosAval.recomendacion
      },
      comentarios: this.comentarios_caracter
    };

    this.loading = true;

    if ((this.datosAvalSeccion == 2 || this.datosAvalSeccion == 3 || this.datosAvalSeccion == 4) && (!localStorage.getItem('comentarios') && !localStorage.getItem('cartas'))) {
      this.requestUpdateAval.tipo = 1;
      this.GuardarInfoAval();
    } else if (localStorage.getItem('comentarios')) {
      this.requestUpdateAval.tipo = 2;
      this.GuardarInfoAval();
    } else if (localStorage.getItem('cartas')) {
      this.requestUpdateAval.tipo = 4;
      this.GuardarInfoAval();
    } else {
      this.requestUpdateAval.tipo = 3;
      this.GuardarInfoAval();
    }
  }

  GuardarInfoAval() {
    this.userService.ActualizarInfoAval(this.requestUpdateAval).subscribe((res) => {
      console.log('Actualizar info aval: ', res);
      const dialogref = this.dialog.open(MessageBoxComponent, {
        width: '500px',
        data: {
          text2: 'Éxito',
          text3: 'Se ha actualizado el Aval',
          type: 'success',
          icon: 'success'
        }
      });
      this.SavePDF( res );
      dialogref.afterClosed().subscribe(() => {
        if ((this.datosAvalSeccion == 2 || this.datosAvalSeccion == 3 || this.datosAvalSeccion == 4) && (!localStorage.getItem('comentarios') && !localStorage.getItem('cartas'))) {
          this.ActualizarLocalStorage(this.requestUpdateAval);
        }
        window.history.go(-2);
      });
    },
    (error) => {
      this.loading = false;
      console.log('Error: ', error);
      this.dialog.open(MessageBoxComponent, {
        width: '500px',
        data: {
          text: 'El servicio no está disponible por el momento.',
          text3: 'Vuelve a intentarlo',
          type: 'error',
          icon: 'error'
        }
      });
    });
  }

  ActualizarLocalStorage(requestAval) {
    this.actualizar_informacion = JSON.parse(localStorage.getItem('actualizar_informacion'));
    // Respaldo de info
    this.actualizar_informacion.DatosAnalisis.aval_nombre = requestAval.datos_aval.nombre;
    this.actualizar_informacion.DatosAnalisis.aval_parentezco = requestAval.datos_aval.parentezco;
    this.actualizar_informacion.DatosAnalisis.aval_ocupacion = requestAval.datos_aval.ocupacion;
    // Info de variables
    this.actualizar_informacion.Analisis.perfil_aval.nombre  = requestAval.datos_aval.nombre;

    this.actualizar_informacion.Analisis.perfil_aval.bc_score = requestAval.perfil_aval.bc_score;
    this.actualizar_informacion.Analisis.perfil_aval.bc_score_punt = requestAval.perfil_aval.bc_score_punt;
    this.actualizar_informacion.Analisis.perfil_aval.icc = requestAval.perfil_aval.icc;
    this.actualizar_informacion.Analisis.perfil_aval.icc_punt = requestAval.perfil_aval.icc_punt;
    this.actualizar_informacion.Analisis.perfil_aval.documentacion = requestAval.perfil_aval.documentacion;
    this.actualizar_informacion.Analisis.perfil_aval.documentacion_punt = requestAval.perfil_aval.documentacion_punt;
    this.actualizar_informacion.DatosAnalisis.datos_generales.aval_parentezco = requestAval.datos_aval.parentezco;
    this.actualizar_informacion.Analisis.perfil_aval.parentezco_punt = requestAval.perfil_aval.parentezco_punt;
    this.actualizar_informacion.DatosAnalisis.datos_generales.aval_ocupacion = requestAval.datos_aval.ocupacion;
    this.actualizar_informacion.Analisis.perfil_aval.ocupacion_punt = requestAval.perfil_aval.ocupacion_punt;

    this.actualizar_informacion.Analisis.perfil_aval.total = requestAval.perfil_aval.total;
    this.actualizar_informacion.Analisis.comentarios_caracter = requestAval.comentarios;

    localStorage.setItem('actualizar_informacion', JSON.stringify(this.actualizar_informacion));
  }
  ActualizarPuntuacion() {
    this.DatosAval.sumaPuntos = (
      Number(this.DatosAval.puntosBCscore) +
      Number(this.DatosAval.puntosICC) +
      Number(this.DatosAval.puntosDocs) +
      Number(this.DatosAval.puntosParentesco) +
      Number(this.DatosAval.puntosOcupacion)
    );
  }
  CambiarPuntuacionAval() {
    this.DatosAval.puntosBCscore =
      (this.DatosAval.calificacion1_BCscore >= 630) ? 3
      : (this.DatosAval.calificacion1_BCscore >= 615 ) ? 2
      : (this.DatosAval.calificacion1_BCscore >= 600 ) ? 1 : 0;

    this.DatosAval.puntosDocs =
      (this.DatosAval.calificacion1_Docs) ? 1 : 0;

    this.ActualizarPuntuacion();
  }

  SavePDF( data ) {
    console.log('PDFs', data);

    if ( data.analisis ) {
      saveAs('data:application/octet-stream;base64,' + data.analisis, 'analisis.pdf');
    }

    if ( data.comentario ) {
      saveAs('data:application/octet-stream;base64,' + data.comentario, 'comentarios.pdf');
    }

    if (data.minuta) {
      saveAs('data:application/octet-stream;base64,' + data.minuta, 'minuta.pdf');
    }
  }

  isValid() {
    if (this.datosAvalSeccion == 2) {
      return (this.DatosAval.nombre &&
        this.DatosAval.ocupacion &&
        this.DatosAval.tiempo_conocerlo &&
        this.DatosAval.recomendacion &&
        this.DatosAval.parentesco &&
        this.DatosAval.telefonoCasa &&
        this.comentarios_caracter &&
        this.DatosAval.domicilio);
    } else {
      return (this.DatosAval.nombre &&
      this.DatosAval.ocupacion &&
      this.DatosAval.tiempo_conocerlo &&
      this.DatosAval.recomendacion &&
      this.DatosAval.parentesco &&
      this.DatosAval.telefonoCasa &&
      this.DatosAval.domicilio &&
      this.comentarios_caracter &&
      this.DatosAval.calificacion1_BCscore >= 0 &&
      this.DatosAval.calificacion1_ICC >= 0);
    }
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

}
