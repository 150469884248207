import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { perfilUser } from 'src/app/_models/firmaDigital';
import { FirmaDigitalService } from 'src/app/_services/firmaDigita.service';

@Component({
  selector: 'app-firma-digital',
  templateUrl: './firma-digital.component.html',
  styleUrls: ['./firma-digital.component.scss']
})
export class FirmaDigitalComponent implements OnInit {

   id='0'
   perfil: perfilUser = null

  constructor(private firmaDigital: FirmaDigitalService, private activeRoute: ActivatedRoute, private router: Router) { }
  
  ngOnInit(): void {
    
    if ( Number(this.activeRoute.snapshot.params.id) == 17 ) {
      window.location.href = 'https://www.leaseforu.com/promociones'
      return
    }

    this.id =  this.activeRoute.snapshot.params.id

    console.log(this.id)
    this.firmaDigital.getById(this.id)
    .subscribe(
      {next: (data:any) => {
       
        const {user} = data
        this.perfil = user
        console.log(user)
        
      },
      error: (e) =>{
        console.log(e)
        this.id =  'perfilnotfound'
        window.location.href = 'https://www.leaseforu.com/'
       // this.router.navigate(['https://www.leaseforu.com/'])
      }}
    )

  }


}
