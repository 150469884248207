import { formatDate, Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterLink } from '@angular/router';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { Promocion } from 'src/app/_models/promocion';
import { AuthenticationService } from 'src/app/_services';
import { PromocionesService } from 'src/app/_services/promociones.service';
import { VendorsService } from 'src/app/_services/vendors.service';

@Component({
  selector: 'app-crear-promocion',
  templateUrl: './crear-promocion.component.html',
  styleUrls: ['./crear-promocion.component.scss']
})
export class CrearPromocionComponent implements OnInit {
  promocion = new Promocion()
  formData: FormData;

  hoy = formatDate(new Date().toISOString(), 'yyyy-MM-ddT00:00', 'es-MX') 
  error_name = false
  error_description = false
  error_dates = false
  error_rate = false

  agregar_vendor = 0
  lista_vendors = []

  loading = false
  image_preview: any = 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/no_image.png'
 

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private vendorService: VendorsService,
    private promocionService: PromocionesService,
    private dialog: MatDialog,
    public location: Location
  ) { }

  async ngOnInit() {
    await this.ObtenerVendors()
    const _promocion = localStorage.getItem('promocion')
    if (_promocion) {
      this.promocion = JSON.parse(_promocion)
      this.image_preview = this.promocion.url_image
    }
  }

  async CrearPromocion() {
    this.loading = true
    if (this.promocion.name == "") this.error_name = true
    if (this.promocion.description == "") this.error_description = true
    if ( !(Number(this.promocion.condiciones.monthly_rate) > 0)) this.error_rate = true
    if ( !this.ValidarFechas() ) this.error_dates = true

    if (
      this.error_name ||
      this.error_description ||
      this.error_dates ||
      this.error_rate ||
      this.promocion.vendors.list.length == 0
    ) return

    this.formData = new FormData();

    const producto:any = document.querySelector("input[name='product_image']")
    const file = producto.files[0]

    if (file) {
      console.log(file);
      this.formData.append("image_promotion", file);
    }

    for (const key of Object.keys(this.promocion)) {
      if(this.promocion[key]) {
        if (['vendors', 'condiciones'].includes(key)){
          this.formData.append(key, JSON.stringify(this.promocion[key]))
        } else {
          this.formData.append(key, this.promocion[key])
        }
      }
    }

    console.log("Guardar Promoción: ", this.promocion);
    try {
      await this.promocionService.CrearPromocion(this.formData)
      this.ShowDialog('success', `Se ha guardado con éxito la promoción`, '')
      this.dialog.afterAllClosed.subscribe(()=>this.location.back())
      
    } catch(e) {
      console.log(e);
      
    }
    
    this.loading = false
  }

  async ObtenerVendors() {
    this.vendorService.ObtenerVendors({id_usuario: 0, perfil: 0}).subscribe(resp => {
      if (resp && resp.data && resp.data.length) {
        for (const vendor of resp.data) {
          this.lista_vendors.push({
            id: vendor.user_id,
            name: vendor.fullname
          })
        }
      }
    })
  }

  AgregarVendor() {
    if (this.agregar_vendor == 0) return
    const vendor = this.lista_vendors[this.agregar_vendor -1]

    if (this.promocion.vendors.ids.includes(vendor.id)) return

    this.promocion.vendors.ids.push(vendor.id)
    this.promocion.vendors.list.push(vendor)
    
    this.agregar_vendor = 0
  }

  EliminarVendor(index) {

    console.log(`indexOf: ${index}`);
    
    this.promocion.vendors.ids.splice(index, 1)
    this.promocion.vendors.list.splice(index, 1)
  }

  ValidarFechas() {
    const date_start = new Date(this.promocion.date_start)
    const date_end = new Date(this.promocion.date_end)

    return date_start < date_end
  }

  ShowDialog(type, text, text2 = '') {
    this.dialog.open(MessageBoxComponent, {
      width: "500px",
      data: {
        text,
        text2,
        type,
        icon: type,
      },
    })
  }

  ImagePreview() {
    const producto:any = document.querySelector("input[name='product_image']")
    const file = producto.files[0]

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.image_preview = reader.result
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }
  ResetCommission() {
    this.promocion.condiciones.commission_for_opening = 0
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
