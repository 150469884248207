<div class="uploading">
    <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/loading.jpg" height="50" alt="Loading">
</div>
<div class="container">

    <div class="top">
        <div class="header">
            <img class="logo" mat-button height="60px" width="auto" [routerLink]="['/dashboard']" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/logo-header.svg" alt="">
            <div>
                <button mat-button routerLink="/changePassword">Cambiar Contraseña
                    <svg style="height: 20px; width: auto;" xmlns="http://www.w3.org/2000/svg" width="31.992" height="31.992" viewBox="0 0 31.992 31.992"><defs><style>.a{fill:#3ca2d6;}</style></defs><path class="a" d="M18,2a16,16,0,1,0,16,16A16.006,16.006,0,0,0,18,2Zm0,1.391A14.6,14.6,0,1,1,3.391,18,14.594,14.594,0,0,1,18,3.391Zm0,4.868a4.164,4.164,0,0,0-4.173,4.173v2.086h-.7A2.1,2.1,0,0,0,11.041,16.6V23.56a2.1,2.1,0,0,0,2.086,2.086h9.737a2.1,2.1,0,0,0,2.086-2.086V16.6a2.1,2.1,0,0,0-2.086-2.086h-.7V12.432A4.164,4.164,0,0,0,18,8.259ZM18,9.65a2.724,2.724,0,0,1,2.782,2.782v2.086H15.214V12.432A2.724,2.724,0,0,1,18,9.65Zm-4.868,6.259H14.4a.7.7,0,0,0,.227,0h6.728a.7.7,0,0,0,.227,0h1.281a.693.693,0,0,1,.7.7V23.56a.693.693,0,0,1-.7.7H13.128a.693.693,0,0,1-.7-.7V16.6A.693.693,0,0,1,13.128,15.909ZM18,18a1.39,1.39,0,0,0-.7,2.594v.883a.7.7,0,1,0,1.391,0v-.884A1.389,1.389,0,0,0,18,18Z" transform="translate(-2 -2)"/></svg>
                </button>
                <button mat-button (click)="logout()">Cerrar Sesión
                    <svg style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="32" height="31.992" viewBox="0 0 32 31.992"><defs><style>.a{fill:#3ca2d6;}</style></defs><path class="a" d="M18,2A16,16,0,1,0,30.9,27.449a.7.7,0,1,0-1.121-.823,14.6,14.6,0,1,1,0-17.259A.7.7,0,1,0,30.9,8.543,15.976,15.976,0,0,0,18,2Zm9.73,9.73a.7.7,0,0,0-.485,1.194L31.617,17.3H16.6a.7.7,0,1,0,0,1.391H31.617l-4.377,4.377a.7.7,0,1,0,.984.984l5.5-5.5a.7.7,0,0,0,0-1.107l-5.5-5.5A.7.7,0,0,0,27.726,11.73Z" transform="translate(-2 -2)"/></svg>
                </button>
            </div>
        </div>
        <div class="title">Documentos del Solicitante</div>
        <span>#{{folio}}</span>
        <span>{{tipo_persona}}</span>
    </div>


    <input type="file" id="document" (click)="resset($event)" (change)="SubirDocumento($event)" hidden>
    <div class="box">

    <div class="mostrar_documentos">
        <button mat-button [ngClass]="{'active': documentos_solicitante}" (click)="documentos_solicitante = true; documentos_aval = false">{{tipo_persona | uppercase}}</button>
        <button mat-button [ngClass]="{'active': documentos_aval}" (click)="documentos_aval = true; documentos_solicitante = false">AVAL</button>
    </div>

    <!-- <button *ngIf="[4].includes(perfil) && !validandoDocumentos" class="principal_button done_all" style="width: 200px;" (click)="ValidarDocumentos()">Valirdar Documentos <mat-icon>done_all</mat-icon></button>
    <div *ngIf="validandoDocumentos" style="display: flex; justify-content: center; align-items: center; align-self: flex-end; gap: 8px;"><mat-spinner diameter="18"></mat-spinner>Validando...</div>
    <span *ngIf="faltanDocumentos" style="align-self: flex-end; color: red;">* Faltan documentos por cargar</span> -->
    <!-- <div>
        <button (click)="downloadAllDocuments()" >Descargar PDF's</button>
    </div> -->
    <div class="table-container" *ngIf="documentos_solicitante">
        <button mat-button *ngIf="data.tipo_persona == 1" style="text-align: left;" (click)="documentos_pf = !documentos_pf">
            Información Persona Física
            <svg style="height: 25px; width:auto;" *ngIf="documentos_pf" xmlns="http://www.w3.org/2000/svg" width="43.411" height="43.411" viewBox="0 0 43.411 43.411"><defs><style>.ab{fill:#303943;}</style></defs><path class="ab" d="M24.706,3A21.706,21.706,0,1,0,46.411,24.706,21.707,21.707,0,0,0,24.706,3ZM35.558,26.514H13.853a1.809,1.809,0,1,1,0-3.618H35.558a1.809,1.809,0,0,1,0,3.618Z" transform="translate(-3 -3)"/></svg>
            <svg style="height: 25px; width:auto;" *ngIf="!documentos_pf" xmlns="http://www.w3.org/2000/svg" width="43.411" height="43.411" viewBox="0 0 43.411 43.411"><defs><style>.a{fill:#3ca2d6;}</style></defs><path class="a" d="M24.706,3A21.706,21.706,0,1,0,46.411,24.706,21.707,21.707,0,0,0,24.706,3ZM35.559,26.514H26.514v9.044a1.809,1.809,0,0,1-3.618,0V26.514H13.853a1.809,1.809,0,1,1,0-3.618H22.9V13.853a1.809,1.809,0,1,1,3.618,0V22.9h9.044a1.809,1.809,0,0,1,0,3.618Z" transform="translate(-3 -3)"/></svg>
        </button>
        <button mat-button *ngIf="data.tipo_persona == 2" style="text-align: left;" (click)="documentos_pm = !documentos_pm">
            Información Financiera y Cualitativa de la Empresa
            <svg style="height: 25px; width:auto;" *ngIf="documentos_pm" xmlns="http://www.w3.org/2000/svg" width="43.411" height="43.411" viewBox="0 0 43.411 43.411"><defs><style>.ab{fill:#303943;}</style></defs><path class="ab" d="M24.706,3A21.706,21.706,0,1,0,46.411,24.706,21.707,21.707,0,0,0,24.706,3ZM35.558,26.514H13.853a1.809,1.809,0,1,1,0-3.618H35.558a1.809,1.809,0,0,1,0,3.618Z" transform="translate(-3 -3)"/></svg>
            <svg style="height: 25px; width:auto;" *ngIf="!documentos_pm" xmlns="http://www.w3.org/2000/svg" width="43.411" height="43.411" viewBox="0 0 43.411 43.411"><defs><style>.abc{fill:#3ca2d6;}</style></defs><path class="abc" d="M24.706,3A21.706,21.706,0,1,0,46.411,24.706,21.707,21.707,0,0,0,24.706,3ZM35.559,26.514H26.514v9.044a1.809,1.809,0,0,1-3.618,0V26.514H13.853a1.809,1.809,0,1,1,0-3.618H22.9V13.853a1.809,1.809,0,1,1,3.618,0V22.9h9.044a1.809,1.809,0,0,1,0,3.618Z" transform="translate(-3 -3)"/></svg>
        </button>

        
        <div *ngIf="documentos_pf || documentos_pm" class="documentos">
            <app-documento 
                *ngFor="let documento of Documentos.DocumentosSolicitante; let i=index" 
                [documento]="documento" 
                [index]="i" 
                [solicitante]="true"
                style="margin: 10px 0;"
                [show]="documento && documento.tipo_documento != 21 || (documento.tipo_documento == 21 && documento.url)"
                (VerInfo)="VerDescripcion($event)"
                (Upload)="SeleccionarDocumento(documento, true)"
                (VerDocumento)="VerDocumento(documento, true)"
                (VerComentarios)="VerComentario(documento, true)"
                (Aceptar)="AceptarDocumento(documento, true)"
                (Rechazar)="RechazarDocumento(documento, true)">
            </app-documento>
        </div>
    </div>



    <div class="table-container" *ngIf="documentos_aval">
        <button mat-button style="text-align: left;" (click)="documentos_af = !documentos_af">
            {{ data.tipo_aval == 2 ? 'Información Financiera y Cualitativa de la Empresa' : 'Información Aval y/o Obligado Solidarios' }}
            <svg style="height: 25px; width:auto;" *ngIf="documentos_af" xmlns="http://www.w3.org/2000/svg" width="43.411" height="43.411" viewBox="0 0 43.411 43.411"><defs><style>.ab{fill:#303943;}</style></defs><path class="ab" d="M24.706,3A21.706,21.706,0,1,0,46.411,24.706,21.707,21.707,0,0,0,24.706,3ZM35.558,26.514H13.853a1.809,1.809,0,1,1,0-3.618H35.558a1.809,1.809,0,0,1,0,3.618Z" transform="translate(-3 -3)"/></svg>
            <svg style="height: 25px; width:auto;" *ngIf="!documentos_af" xmlns="http://www.w3.org/2000/svg" width="43.411" height="43.411" viewBox="0 0 43.411 43.411"><defs><style>.abc{fill:#3ca2d6;}</style></defs><path class="abc" d="M24.706,3A21.706,21.706,0,1,0,46.411,24.706,21.707,21.707,0,0,0,24.706,3ZM35.559,26.514H26.514v9.044a1.809,1.809,0,0,1-3.618,0V26.514H13.853a1.809,1.809,0,1,1,0-3.618H22.9V13.853a1.809,1.809,0,1,1,3.618,0V22.9h9.044a1.809,1.809,0,0,1,0,3.618Z" transform="translate(-3 -3)"/></svg>
        </button>

        <div *ngIf="documentos_af" class="documentos">
            <app-documento 
                *ngFor="let documento of Documentos.DocumentosAval; let i=index" 
                [documento]="documento" 
                [index]="i" 
                [solicitante]="false"
                style="margin: 10px 0;"
                (VerInfo)="VerDescripcion($event)"
                (Upload)="SeleccionarDocumento(documento, false)"
                (VerDocumento)="VerDocumento(documento, false)"
                (VerComentarios)="VerComentario(documento, false)"
                (Aceptar)="AceptarDocumento(documento, false)"
                (Rechazar)="RechazarDocumento(documento, false)">
            </app-documento>
        </div>
    </div>
    <div *ngIf="perfil != 4" style="align-self: flex-start; font-size: .8rem; margin: 10px 0;">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 17.328 17.328"><defs><style>.info_icon_2{ fill: white; stroke: #3F4A57; stroke-width: 2px;}</style></defs><path class="info_icon_2" d="M10.664,2a8.664,8.664,0,1,0,8.664,8.664A8.664,8.664,0,0,0,10.664,2Zm.866,13H9.8V13.263H11.53Zm0-3.466H9.8v-5.2H11.53Z" transform="translate(-2 -2)"/></svg>
        Por favor revisa que tus documentos no se encuentren protegidos para poder realizar la validación.
    </div>
    <div *ngIf="false" style="align-self: flex-start; font-size: .8rem; margin: 10px 0;">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 17.328 17.328"><defs><style>.info_icon_2{ fill: white; stroke: #3F4A57; stroke-width: 2px;}</style></defs><path class="info_icon_2" d="M10.664,2a8.664,8.664,0,1,0,8.664,8.664A8.664,8.664,0,0,0,10.664,2Zm.866,13H9.8V13.263H11.53Zm0-3.466H9.8v-5.2H11.53Z" transform="translate(-2 -2)"/></svg>
          Es requerido cancelar un documento para continuar.
    </div>
    <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
    <button *ngIf="[4,15].includes(perfil) && !loading" mat-button class="confirmar-documentos" [disabled]="!completado" (click)="CompletarExpediente()">
		Siguiente
		<svg style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><defs><style>.next{fill:#3CA2D6;}</style></defs><path class="next" d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0ZM22,38V18.7L13.448,27.25l-3.394-3.394L24,9.908,37.948,23.856,34.554,27.25,26,18.7V38Z" transform="translate(48) rotate(90)"/></svg>
    </button>
    <button *ngIf="false && !loading" mat-button class="confirmar-documentos" [disabled]="!cancelDoc" (click)="NextCancelarDocumento()">
		Siguiente
		<svg style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><defs><style>.next{fill:#3CA2D6;}</style></defs><path class="next" d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0ZM22,38V18.7L13.448,27.25l-3.394-3.394L24,9.908,37.948,23.856,34.554,27.25,26,18.7V38Z" transform="translate(48) rotate(90)"/></svg>
	</button>
    </div>
    <app-socials-footer style="width: 100%;"></app-socials-footer>
</div>