import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { customPatterns } from 'src/app/_models/maskPattern';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-info-cotizacion',
  templateUrl: './info-cotizacion.component.html',
  styleUrls: ['./../crear-cotizacion.component.scss']
})
export class InfoCotizacionComponent implements OnInit {
  @Output() onNextPage = new EventEmitter;
  @Input() precio_equipo = 0;
  @Input() data: any = null;
  @Input() tramite: boolean;

  DEPOSITOS_GARANTIA = 6

  Garantia = Array(this.DEPOSITOS_GARANTIA +1)

  precio_equipo_sin_iva = 0;
  submit = false;
  calculated = false;
  customPattern = customPatterns;
  cotizacionForm: FormGroup;
  fail_factor = false;
  fail_enganche = false;
  fail_seguro = false;
  user;
  perfil;
  vendor = false;

  perfiles_permitidos = [6,9]
  puede_editar = false
  
  engancheMax = 60
  engancheMin = 0
  seguroMin = 0
  seguroMax = 20
  factorMin = 12

  poliza_mantenimiento = false

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
  const SUPER_VENDOR = 11
	this.user = JSON.parse(localStorage.getItem('currentUser'));
  this.perfil = this.user.perfil_master == 11 ? 11 : Number(this.user.perfil);
  this.engancheMax = this.perfil == 9 ? 60 : this.engancheMax
  this.poliza_mantenimiento = this.perfil == SUPER_VENDOR

  console.log(this.user);
  this.factorMin = this.user.factor;
  this.puede_editar = this.perfiles_permitidos.indexOf(Number(this.perfil)) >= 0

	if ([2,3,7,9,11].includes(Number(this.perfil))) {
		console.log('vendor o vendedor');
		this.data.factor = this.user.factor ?? '16.6';
		this.vendor = true;
	}

 this.cotizacionForm = this.formBuilder.group({
      factor: [ this.data.factor || 16, [Validators.required, Validators.min(this.factorMin), Validators.max(20)]],
      enganche: [this.data.enganche || 10, [Validators.min(this.engancheMin), Validators.max(this.engancheMax)]],
      porcentajeSeguro: [1, [Validators.required, Validators.min(this.seguroMin), Validators.max(this.seguroMax)]],
      engancheIVA: [0, [Validators.required]],
      precioSeguroMensualFinanciado: [0, [Validators.required]],
      precioSeguroAnual: [0, [Validators.required]],
      deposito_garantia: [0],
      polizaMantenimientoAnual: [0],
      porcentajePolizaMantenimiento: [0],
      polizaMantenimientoMensual: [0]
    });
  }

  get f() {
    return this.cotizacionForm.controls;
  }

  NextPage() {
    const cotizacion = this.cotizacionForm.value;
    // cotizacion.engancheIVA = this.f.enganche_sin_iva.value * 1.16
    if( this.cotizacionForm.invalid ) return this.calculated = false
    this.onNextPage.emit({
      cotizacion: {
        factor: Number(this.f.factor.value),
        enganche: Number(this.f.enganche.value),
        porcentajeSeguro: Number(this.f.porcentajeSeguro.value),
        engancheIVA: this.f.engancheIVA.value,
        precioSeguroMensualFinanciado: this.f.precioSeguroMensualFinanciado.value,
        precioSeguroAnual: this.f.precioSeguroAnual.value,
        depositoGarantia: this.f.deposito_garantia.value,
        polizaMantenimiento: Math.round(Number(this.f.polizaMantenimientoMensual.value))
      }
    });
  }

  Calcular() {
    this.submit = true;
    if ( Number(this.f.factor.value) < 12 || Number(this.f.factor.value) > 100 ) { this.fail_factor = true; }
    if ( this.f.enganche.invalid ) { this.fail_enganche = true; }
    if ( Number(this.f.porcentajeSeguro.value > 100) ) { this.fail_seguro = true; }

    if ( this.fail_factor || this.fail_enganche || this.fail_seguro || this.cotizacionForm.invalid ) { return; }

    this.calculated = true;

    this.precio_equipo_sin_iva = Number(this.precio_equipo / 1.16);

    this.f.engancheIVA.setValue( Math.round( this.precio_equipo * (Number(this.f.enganche.value) / 100)) );
    this.f.precioSeguroAnual.setValue( Math.round( this.precio_equipo_sin_iva * (Number(this.f.porcentajeSeguro.value) / 100)) );
    this.f.precioSeguroMensualFinanciado.setValue( Math.round( (Number(this.f.precioSeguroAnual.value) * (1 + Number(this.f.factor.value) / 100)) / 12 ));

    const porcentajePolizaMantenimiento = Number(this.f.polizaMantenimientoAnual.value) / Number( this.precio_equipo_sin_iva)
    
    this.f.porcentajePolizaMantenimiento.setValue( Math.round(porcentajePolizaMantenimiento *100) )
    this.f.polizaMantenimientoMensual.setValue( (Number(this.f.polizaMantenimientoAnual.value) * (1+ Number(this.f.factor.value) /100) / 12 ).toFixed(1))
  }
}
