import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-analisis-crediticio',
  templateUrl: './analisis-crediticio.component.html',
  styleUrls: ['./analisis-crediticio.component.scss']
})
export class AnalisisCrediticioComponent implements OnInit {
  @Input() scorePerson;
  @Input() datos;

  nombre: String;
  message: String = '';
  folio;
  score;

  constructor(
    private router: Router
  ) { 
    const client_details = JSON.parse(localStorage.getItem('client_details'))
    const nombre = localStorage.getItem('nombre')
    this.nombre =  client_details ? client_details.cliente : `${nombre}`;
    this.folio =   client_details ? client_details.folio : null;
  }

  ngOnInit(): void {
    this.score = this.scorePerson.score;
    if (this.score == '') {
      this.message = 'No se encontró información';
    } else if (this.score == 'menos 90 dias') {
      this.message = 'La consulta que deseas realizar es menor a tres meses, te recomendamos avanzar al siguiente paso';
    } else if (this.score == 'A1' || this.score == 'A2' || this.score == 'A3') {
      this.message = 'Pre - Aprobado';
    } else if (this.score == 'B1' || this.score == 'B2') {
      this.message = 'Aprobado sujeto a revisión';
    } else if (this.score == 'NC' || this.score == 'OTRO') {
      this.message = 'Sujeto a revisión';
    } else {
      this.score = parseInt(this.scorePerson.score);
      if (this.score >= 630) {
        this.message = 'Pre - Aprobado';
      } else if (629 >= this.score && this.score >= 600){
        this.message = 'Aprobado sujeto a revisión';
      } else if (this.score < 600) {
        this.message = 'Sujeto a revisión';
      }
    }
  }

  async onSubmit() {
    if( this.folio ){
      this.router.navigate([`/dashboard/comite/${this.folio}`]);
    }else{
      this.router.navigate([`/dashboard/solicitudes`]);
    }
  }

}
