import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MejorarCotizacionJson } from 'src/app/_models/mejorarCotizacion';
import { NuevaSolicitudService } from 'src/app/_services/nueva_solicitud.service';

@Component({
  selector: 'app-nueva-solicitud',
  templateUrl: './nueva-solicitud.component.html',
  styleUrls: ['./nueva-solicitud.component.scss']
})
export class NuevaSolicitudComponent implements OnInit {
  parte= 1;
  mejorarCotizacionJson = MejorarCotizacionJson
  folio = null
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private nuevaSolicitudService: NuevaSolicitudService
  ) { }

  async ngOnInit() {

    this.route.queryParams.subscribe( params => {
      // console.log(params);
      const {folio, correo, flujo_cotizador} = params
      this.folio = folio
      if(params.folio){

        localStorage.setItem('folio_solicitud', folio)
        localStorage.setItem('correo_solicitud', correo)
        localStorage.setItem('flujo_cotizador', JSON.stringify(flujo_cotizador));
      }

      if(flujo_cotizador == 'false'){
        localStorage.setItem('folio_solicitud', folio)
        localStorage.setItem('flujo_cotizador', JSON.stringify(flujo_cotizador));

      }else{
        this.getIdUser()

      }

  })
  }

  iniciarSesion(){
    this.router.navigate(['/'])
  }

  async getIdUser(){
    const data = {
      folio: this.folio
    }
    const res: any = await this.nuevaSolicitudService.getIdUser(data)
    const id_cliente = res.data[0].id_cliente
    localStorage.setItem('current_id_cliente', id_cliente)
  }

}
