import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import CryptoJS from 'crypto-js';
import sha256 from 'crypto-js/sha256';

import { User } from '../_models';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CreateUser } from '../_models/createUser';
import { AlertService } from './alert.service';

@Injectable({ providedIn: 'root' })


export class UserService {
    latitude = '0';
    longitude = '0';

    Perfil = {
        CLIENTE: 1,
        VENDOR: 2,
        COMERCIAL: 3,
        COMITE: 4,
        ADMINISTRADOR: 5,
        DIRECTOR_GENERAL: 6,
        DIRECTOR_COMERCIAL: 12
    };
    procesos_iniciales = [
        {id_proceso: 1, nombre: 'Solicitud', status: 0, dias: 0},
        {id_proceso: 2, nombre: 'Expediente', status: 0, dias: 0},
        {id_proceso: 3, nombre: 'Confirmacion', status: 0, dias: 0},
        {id_proceso: 4, nombre: 'Comite', status: 0, dias: 0},
      ];

    solicitud = false
    folio = null
    perfil = null

    constructor(private http: HttpClient, private router: Router, public alertService: AlertService) {
        console.log('entro');

        window.addEventListener('offline',  (e) => this.alertService.isOnline() );
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                this.latitude = position.coords.latitude.toString();
                this.longitude = position.coords.longitude.toString();
            });
          }
    }

    getAll() {
        return this.http.get<User[]>(`${environment.config}/users`);
    }

    getById(id: number) {
        return this.http.get(`${environment.config}/users/${id}`);
    }

    register(user: User) {
        // let hasPassword = sha256(user.password).toString(CryptoJS.enc.Hex);
        // user.password = hasPassword;
        user.typeUser = 1; // Change dinamic

        user.latitude = this.latitude;
        user.longitude = this.longitude;

        return this.http.post<any>(`${environment.config.apiUrl}authentication/signUp`,  user ,
        {observe: 'response' as 'body'})
        .pipe(map(user => {
            console.log(user);
            return user;
        }));
    }

    changePassword(user: User) {
        // let hasPassword = sha256(user.password).toString(CryptoJS.enc.Hex);
        // user.password = hasPassword;
        // let hasNewPassword = sha256(user.newPassword).toString(CryptoJS.enc.Hex);
        // user.newPassword = hasNewPassword;
        // let hasConfirmPassword = sha256(user.confirmPassword).toString(CryptoJS.enc.Hex);
        // user.confirmPassword = hasConfirmPassword;
         console.log('User: ', user);
         const { token } = JSON.parse( localStorage.getItem('currentUser'));
         return this.http.post<any>(`${environment.config.apiUrl}authentication/changePassword`,  user ,
        {observe: 'response' as 'body',
        headers: (new HttpHeaders).append('Authorization', token)})
        .pipe(map(user => {
             return user;
        }));
    }

    // checkProcess(user: User) {
    //     return this.http.post<any>(`${environment.config['apiUrl']}verificationProcessCode`, { 'mail': user.id },
    //     {observe: 'response' as 'body'})
    //     .pipe(map(user => {  return user;
    //     }));
    // }
    checkProcess(): any {
        const {id, perfil, quatation, primera_vez} = JSON.parse(localStorage.getItem('currentUser'));
        if ( perfil ==  this.Perfil.CLIENTE && !quatation.Status ) { this.router.navigate(['codeActivation']); } else if ( perfil == this.Perfil.CLIENTE && quatation.Status ) { return this.getStatus((id), String(quatation.Code)); } else if ( perfil != this.Perfil.CLIENTE && primera_vez ) { this.router.navigate(['changePassword']); } else if ( perfil != this.Perfil.CLIENTE ) { this.router.navigate(['dashboard', 'principal']); }
        // else if ( perfil == this.Perfil.COMERCIAL ) { this.router.navigate(['/dashboard/comercial']); }
        // else if ( perfil == this.Perfil.COMITE ) { this.router.navigate(['/dashboard/comite']); }
        // else if ( perfil == this.Perfil.ADMINISTRADOR ) { this.router.navigate(['/dashboard/administrador']); }
    }

    activateCode(id_cotizacion, code: String, nueva) {
        console.log('id cliente: ', id_cotizacion, 'Code: ', code);
        const { token } = JSON.parse( localStorage.getItem('currentUser'));
        const data = { id_cotizacion, code, nueva};

        console.log('Activar tramite: ', data);

        return this.http.post<any>(`${environment.config.apiUrl}validationCode`, data,
        {observe: 'response' as 'body',
        headers: (new HttpHeaders()).append('Authorization', token)})
        .pipe(map(data => {
            // update quatation status after code validation
            console.log('Data: ', data);

            if ( data.status === 200 ) {
                const currentUser = JSON.parse(localStorage.getItem('currentUser'));
                currentUser.quatation.Code = code;
                currentUser.quatation.Status = true;
                localStorage.setItem('currentUser', JSON.stringify(currentUser));
                console.log('Nuevo log: ', currentUser);

            }
            return data;
        }));
    }

    hasCode() {
        const { token } = JSON.parse( localStorage.getItem('currentUser'));

        return this.http.post<any>(`${environment.config.apiUrl}hasCode`, {},
        {observe: 'response' as 'body',
        headers: (new HttpHeaders()).append('Authorization', token)})
        .pipe(map(data => {
            return data;
        }));
    }

    sendCode(emailUser) {
        console.log('id cliente: ', emailUser);
        const { token } = JSON.parse( localStorage.getItem('currentUser'));
        const data: any = {
            email:  emailUser
        };

        console.log('Enviar código tramite: ', data);

        return this.http.post<any>(`${environment.config.apiUrl}resendCode`, data,
        {observe: 'response' as 'body',
        headers: (new HttpHeaders()).append('Authorization', token)})
        .pipe(map(data => {
            // update quatation status after code validation
            console.log('Data: ', data);
            return data;
        }));
    }

    forgotPassword(email) {
        return this.http.post<any>(`${environment.config.apiUrl}authentication/forgotPassword`, { email },
        {observe: 'response' as 'body'})
        .pipe(map(data => {
             return data;
        }));
    }

    resetPassword(parameters) {
        return this.http.post<any>(`${environment.config.apiUrl}authentication/resetPassword`, { password: parameters.password, newPassword: parameters.newPassword, confirmPassword: parameters.confirmPassword, email: parameters.email },
        {observe: 'response' as 'body'})
        .pipe(map(data => {
             return data;
        }));
    }

    getStatusProceso(idUsuario, folio: String) {
        return this.http.post<any>(`${environment.config.apiUrl}getStatus`, { idUsuario, folio}).toPromise();
    }

    getStatus(idUsuario, folio: String) {
        console.log(idUsuario, folio.toString());
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        currentUser.quatation.Code = folio;
        localStorage.setItem('currentUser', JSON.stringify(currentUser));

        return this.http.post<any>(`${environment.config.apiUrl}getStatus`, { idUsuario, folio})
        .subscribe( client => {
            console.log('Cambiando proceso...', client);

            localStorage.removeItem('client_details');
            const folio = JSON.parse( localStorage.getItem('currentUser'));
            const client_status = {
              cliente: client.nombre,
            //   folio: folio.quatation.Code,
              folio: client.folio,
              id_cliente: client.id_cliente,
              tipo_persona: client.tipo_persona,
              tipo_aval : client.tipo_aval,
              equipo: client.equipo,
              procesos: {}
            };
            console.log('getStatus: ', client, 'folio: ', folio);

            client_status.procesos = client.estauts ? client.estauts.map(
              ({id_proceso, nombre, estatus: status}) => ({id_proceso, nombre, status})
            ) : this.procesos_iniciales;

            localStorage.setItem('client_details', JSON.stringify(client_status));
            // this.router.navigateByUrl(`/dashboard/cliente/${folio.quatation.Code}`)
            this.router.navigateByUrl(`/dashboard/cliente/${client.folio}`);
            // console.log("Con doble refesh funcionara?????");
            // this.router.navigateByUrl(`/dashboard/cliente/${folio.quatation.Code}`)
            if ( folio.perfil == 4 ) { return this.router.navigateByUrl(`/dashboard/comite/${client.folio}`); }

        }, err => console.log('No vi estos errores: ', err)
        );
        // {observe: 'response' as 'body'})
        // .pipe(map( ({body}) => body, err => {
        //     console.log('Error getStatus: ', err);
        // }));
    }

    update(user: User) {
        return this.http.put(`${environment.config}/users/${user.id}`, user);
    }

    delete(id: number) {
        return this.http.delete(`${environment.config}/users/${id}`);
    }

    // Change Status
    changeStatus( folio, id_proceso, id_status ) {
        return this.http.post<any>(`${environment.config.apiUrl}changeStatus`, {folio, id_proceso, id_status},
        {observe: 'response' as 'body'})
        .pipe( map(data => {
            const client_details = JSON.parse(localStorage.getItem('client_details'));
            console.log("client_details", client_details)
            const procesos = client_details.procesos.map( proceso => {
                proceso.status = proceso.id_proceso == id_proceso
                    ? id_status
                    : proceso.status;
                return proceso;
            });

            client_details.procesos = procesos;
            localStorage.setItem('client_details', JSON.stringify(client_details));
            console.log('Procesos actualizados: ', procesos);

            return true;
        }));
    }
    // get List of Status
    getListStatus( dashboard ) {
        const {id} = JSON.parse( localStorage.getItem('currentUser'));
        return this.http.post<any>(`${environment.config.apiUrl}getListStatus`, {idUsuario: id, ...dashboard},
        {observe: 'response' as 'body'})
        .pipe( map( ({body: tramites}) => {
            tramites.forEach( tramite => {
                if ( tramite.procesos ) {  tramite.procesos.sort( (a, b) => a.id_proceso - b.id_proceso); }
                tramite.procesos = (!tramite.procesos) ? this.procesos_iniciales : tramite.procesos;
                for ( let i = 0; i < this.procesos_iniciales.length; i++) {
                    tramite.procesos[i] = ( tramite.procesos[i]) ? tramite.procesos[i] : this.procesos_iniciales[i];
                }
            });
            console.log('Tramites listos: ', tramites);
            return tramites;

            // body.map( (tramite: any) => {
            //     if ( !tramite.procesos ) { return false; }
            //     if ( tramite.procesos.length === 3 ) { tramite.procesos.push({id_proceso: 4, status: 0}); }
            //     tramite.procesos.sort( (a, b) => a.id_proceso - b.id_proceso);
            //   });
            // return body;
        } ));
    }

    CreateUser( User: CreateUser ) {
        console.log('Crear User: ', User);

        return this.http.post<any>(`${environment.config.apiUrl}createUser`, User,
        {observe: 'response' as 'body'})
        .pipe( map( data => {
            return data;
        } ));
    }


    goToDashboard() {
        if( this.perfil == 1 ){
            return this.router.navigate(['/dashboard'])
        }else {
            if( this.perfil != 1 && this.solicitud )
                return this.router.navigate(['dashboard','comercial', this.folio])
            else
                return this.router.navigate(['consulta_solicitud'])
        }
    }

    getDatosAnalisis( folio ) {
        return this.http.post(`${environment.config.apiUrl}comite/DatosAnalisis`, { folio } );
    }

    GuardarAnalisis( analisis ) {
        console.log('Guardando Analisis: ', analisis);

        return this.http.post<any>(`${environment.config.apiUrl}comite/GuardarAnalisis`, analisis );
    }
    GuardarEntrevista( Entrevista ) {
        console.log('Guardando Entrevista: ', Entrevista );
        return this.http.post<any>(`${environment.config.apiUrl}comite/GuardarEntrevista`, Entrevista);

    }

    // getEntrevista(folio) {
    //     return this.http.post<any>(`${environment.config.apiUrl}dashboard/getEntrevista`, {folio});
    // }

    ObtenerComentarios(folio) {
        return this.http.post<any>(`${environment.config.apiUrl}comite/ObtenerComentarios`, {folio});
    }

    GuardarResolucion( resolucion ) {
        return this.http.post<any>(`${environment.config.apiUrl}comite/GuardarResolucion`, resolucion);
    }

    ObtenerDatosCaratula( folio ) {
        return this.http.post<any>(`${environment.config.apiUrl}comite/ObtenerCaratula`, {folio});
    }

    GuardarCaratula( caratula ) {
        return this.http.post<any>(`${environment.config.apiUrl}comite/GuardarCaratula`, caratula);
    }

    ObtenerMinuta( folio ) {
        return this.http.post<any>(`${environment.config.apiUrl}comite/ObtenerMinuta`, {folio});
    }

    GuardarMinuta(participantes) {
        return this.http.post<any>(`${environment.config.apiUrl}comite/GuardarMinuta`, participantes);
    }

    ObtenerDetalleCarta( folio ) {
        return this.http.post<any>(`${environment.config.apiUrl}comite/DetalleCarta`, {folio});
    }

    ObtenerStatusComite(folio) {
        return this.http.post<any>(`${environment.config.apiUrl}comite/getStatus`, {folio});
    }

    ObtenerStatusDocuments(data) {
        return this.http.post<any>(`${environment.config.apiUrl}documents/getStatusDocument`, data);
    }

    SubirDocumento( formData ) {
        return this.http.post<any>(`${environment.config.apiUrl}documents/uploadDocument`, formData);
    }

    CambiarStatusDocumento(data) {
        return this.http.post<any>(`${environment.config.apiUrl}documents/uploadStatusDocument`, data);
    }

    ObtenerPDF(params) {
            return this.http.post(`${environment.config.apiUrl}documents/getLetterComments`, params,
            {responseType: 'arraybuffer' as 'json'})
    }

    ObtenerEstatusSolicitud(data) {
        return this.http.post<any>(`${environment.config.apiUrl}dashboard/getStepStatus`, data);
    }

    VerCotizaciones(folio) {
        return this.http.post<any>(`${environment.config.apiUrl}documents/getList`, {folio});
    }

    CambiarStatusCotizacion(data) {
        return this.http.post<any>(`${environment.config.apiUrl}status/cotizacion`, data);
    }

    ObtenerTramites(id_cliente) {
        return this.http.post<any>(`${environment.config.apiUrl}getListStatusClient`, {id_cliente});
    }

    GuardarCarta(data) {
        return this.http.post<any>(`${environment.config.apiUrl}comite/Cartas`, data);
    }

    ObtenerListaCotizacionesPorConfirmar(folio) {
        return this.http.post<any>(`${environment.config.apiUrl}quatation/getListConfirm`, {folio});
    }

    CotizacionVista( idCotizacion ) {
        return this.http.post<any>(`${environment.config.apiUrl}quatation/setViewed`, {idCotizacion});
    }

    PreConfirmarCotizacion( folio, id_cliente, plazo, id_cotizacion, perfil ) {
        return this.http.post<any>(`${environment.config.apiUrl}quatation/confirmQuote`, {folio, id_cliente, plazo, id_cotizacion, perfil});
    }

    ObtenerCotizacionesPreConfirmadas( folio ) {
        return this.http.post<any>(`${environment.config.apiUrl}quatation/preConfirmQuote`, {folio});
    }

    DatosValidacion(id_cliente, folio) {
        return this.http.post<any>(`${environment.config.apiUrl}documents/datosValidacion`, {id_cliente, folio});
    }

    ValidacionComercial(folio, tipo_documento, validacion, comentarios) {
        return this.http.post<any>(`${environment.config.apiUrl}documents/validacionComercial`, {folio, tipo_documento, validacion, comentarios});
    }

    ValidacionComite(folio, validacion, comentarios) {
        return this.http.post<any>(`${environment.config.apiUrl}documents/validacionComite`, {folio, validacion, comentarios});
    }

    ObtenerParametrosSistema() {
        return this.http.post<any>(`${environment.config.apiUrl}getConfigs`, {});
    }
    GuardarParametrosSistema(parametros) {
        return this.http.post<any>(`${environment.config.apiUrl}setConfigs`, parametros);
    }
    CrearCotizacion(data) {
        return this.http.post<any>(`${environment.config.apiUrl}documents/arrenadmientoPuro`, data);
    }

    CrearCotizacionTasaPreferencial(data) {
        return this.http.post<any>(`${environment.config.apiUrl}documents/tasaPreferencial`, data);
    }

    ActualizarCapacidadPago(CapacidadPago) {
        return this.http.post<any>(`${environment.config.apiUrl}comite/actualizarCapacidadPago`, CapacidadPago);
    }

    ObtenerCapacidadPago(folio) {
        return this.http.post<any>(`${environment.config.apiUrl}comite/ObtenerCapacidadPago`, { folio });
    }

    ObtenerInfoAval(folio) {
        return this.http.post<any>(`${environment.config.apiUrl}comite/ObtenerInformacionAval`, { folio });
    }
    ActualizarInfoAval(datosAval) {
        return this.http.post<any>(`${environment.config.apiUrl}comite/ActualizarAval`, datosAval);
	}

	CancelarTramite( folio: string, idUsuario: number ) {
		return this.http.post<any>(`${environment.config.apiUrl}tramite/cancelar`, {folio, idUsuario});
    }
    public DeleteProcessState(request: any): any {
        return this.http.post<any>(`${environment.config.apiUrl}documents/deleteProcessState`, request);
    }
    public DeleteProcessComite(request: any): any {
        return this.http.post<any>(`${environment.config.apiUrl}comite/DeleteProcessComite`, request);
    }
    public ObtenerIdCotizacion(request: any): any {
        return this.http.post<any>(`${environment.config.apiUrl}comite/ObtenerIdCotizacion`, request);
    }
    public ActualizaResolucionStatus(request: any): any {
        return this.http.post<any>(`${environment.config.apiUrl}comite/ActualizaResolucionStatus`, request);
    }
    public informacionAdicionalCarta(request: any): any {
        return this.http.post<any>(`${environment.config.apiUrl}documents/informacionAdicional`, request);
    }
    
    public PlazosValidos(idCotizacion){
        return this.http.post<any>(`${environment.config.apiUrl}documents/getPlazosValidos`, { idCotizacion }).toPromise();
    }
    nuevoRegistro(data) {
        return this.http.post<any>(`${environment.config.apiUrl}authentication/signUp`, data).toPromise();
    }
    CambiarStatusSolicitud( folio, id_proceso, id_status ) {
        return this.http.post<any>(`${environment.config.apiUrl}changeStatus`, {folio, id_proceso, id_status}).toPromise()
        }

    
}

