import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { Product } from 'src/app/_models/product';
import { AuthenticationService, ProductsService } from 'src/app/_services';
import { Perfil } from './../../../_models/perfiles'
import { Location } from '@angular/common';
@Component({
  selector: 'app-crud-producto',
  templateUrl: './crud-producto.component.html',
  styleUrls: ['./crud-producto.component.scss']
})
export class CrudProductoComponent implements OnInit {
  PERFILES_VENDOR = [2,11]
  perfil
  id_usuario
  IDvendor = 0
  isVendor = false
  tipo_evento = 'Crear'
  Product: Product
  formData: FormData;
  error_plazo = false
  image_preview: any
  show_msi = false
  error = false
  vendor: any = {
    country: 'MX',
    name: '',
  }
  costo_financiero: any = {
    disminuir_valor_factura: false,
    plazos_seleccionados: []
  }
  is_loading = false

  Residuales = {
    ap12: 0,
    ap18: 0,
    ap24: 0,
    ap36: 0,
    ap48: 0,
    ap60: 0
  }
  TazaMensual = {
    ap12: 0,
    ap18: 0,
    ap24: 0,
    ap36: 0,
    ap48: 0,
    ap60: 0,
    msi12: 0,
    msi18: 0,
    msi24: 0,
    msi36: 0,
    msi48: 0,
    msi60: 0
  }

  Plazos = {
    ap12: false,
    ap18: false,
    ap24: false,
    ap36: false,
    ap48: false,
    ap60: false,
    msi12: false,
    msi18: false,
    msi24: false,
    msi36: false,
    msi48: false,
    msi60: false
  }
  comments_unread = []
  show_notifications = false
  total_unread_comments = 0
  isInputEmpty: boolean = false;


  constructor(
    private authenticationService: AuthenticationService,
    private productosService: ProductsService,
    private router: Router,
    private dialog: MatDialog,
    private location: Location,
  ) { }

  ngOnInit(): void {
    const { id, perfil, perfil_master, id_master } = JSON.parse(localStorage.getItem('currentUser'))
    const _vendor = localStorage.getItem('vendor')
    const vendor = JSON.parse(_vendor ? _vendor : '{"country":"MX","name":""}')
    this.vendor = vendor

    this.perfil = Number(perfil)
    this.id_usuario = Number(id)
    this.IDvendor = this.PERFILES_VENDOR.includes(Number(perfil)) ? Number(id) : this.PERFILES_VENDOR.includes(Number(perfil_master)) ? Number(id_master) : 0
    this.isVendor = this.PERFILES_VENDOR.includes(Number(perfil)) ? true : false
    
    const _producto = JSON.parse(localStorage.getItem('producto'))
    this.Product = new Product
    if (_producto) {
      this.Product.fromJson(_producto)
    }
    if (!this.Product) {
      this.Product = new Product
    }
    this.Product.vendor_id = this.IDvendor
    console.log(this.Product, "Aqui");

    if(this.vendor.country == 'PE') {
      this.Product.currency = 'USD'
    }
    
    this.image_preview = this.Product.url_image
    this.SetPlazos(this.Product.plazos)
    if (this.Product && this.Product.id > 0) {
      this.tipo_evento = 'Editar'
    }

    try {
      this.Residuales = JSON.parse(this.Product.residuales) || {ap12: 0,ap18: 0,ap24: 0,ap36: 0,ap48: 0,ap60: 0}
    } catch(e) {
      this.Residuales = {ap12: 0,ap18: 0,ap24: 0,ap36: 0,ap48: 0,ap60: 0}
    }
    try {
      this.TazaMensual = JSON.parse(this.Product.taza_mensuales) || {ap12: 0,ap18: 0,ap24: 0,ap36: 0,ap48: 0,ap60: 0, msi12: 0,msi18: 0,msi24: 0,msi36: 0,msi48: 0,msi60: 0}
    } catch(e) {
      this.TazaMensual = {ap12: 0,ap18: 0,ap24: 0,ap36: 0,ap48: 0,ap60: 0, msi12: 0,msi18: 0,msi24: 0,msi36: 0,msi48: 0,msi60: 0}
    }

    try {
      this.costo_financiero = JSON.parse(this.Product.costo_financiero) || {plazos_seleccionados: []}
      this.costo_financiero['plazos_seleccionados'] = this.costo_financiero.plazos_seleccionados
        ? this.costo_financiero.plazos_seleccionados
        : []
      console.log('nuevo log: ', this.costo_financiero);
    } catch(e) {
      this.costo_financiero = {plazos_seleccionados: []}
    }
    this.show_msi = this.costo_financiero.show_msi || false

    if (this.IDvendor == 0) {
      if ([2,11].includes(this.perfil)) {
        this.ShowDialog("error", "Vendor inválido")
        this.logout()
      }
    }
  }

  async GuardarProducto() {
    if (!this.isVendor) return
    this.error = false

    if (!this.DatosValidos()) {
      this.error = true
      return
    }

    this.is_loading = true
    this.Product.plazos = this.ObtenerPlazos()
    this.Product.residuales = JSON.stringify(this.Residuales)
    this.Product.taza_mensuales = JSON.stringify(this.TazaMensual)
    this.Product.costo_financiero = JSON.stringify({...this.costo_financiero, show_msi: this.show_msi})

    this.formData = new FormData();
    console.log(this.Product);
    const producto:any = document.querySelector("input[name='product_image']")
    const file = producto.files[0]

    if (file) {
      console.log(file);
      this.formData.append("image_product", file);
    }
    for (const key of Object.keys(this.Product)) {
      if(this.Product[key]) {
        this.formData.append(key, this.Product[key])
      }
    }
    console.log(this.formData);
    try {
      if (this.Product.id > 0) {
        await this.productosService.ActualizarProducto(this.formData).toPromise()
        this.ShowDialog("success", "Se ha actualizado correctamente", null, true)
        this.is_loading = false
      } else {
        await this.productosService.CrearProducto(this.formData).toPromise()
        this.ShowDialog("success", "Se ha creado correctamente")
        this.is_loading = false
      }
    } catch(e) {
      console.error(e)
      this.ShowDialog("error", "Hubo un error al guardar el producto")
      this.is_loading = false
    }
  }

  ActivarDesactivarPlazo(plazo) {
    if (!this.isVendor) return

    this.error_plazo = false
    this.Plazos[plazo] = !this.Plazos[plazo]

    const type = plazo.match(/[a-z]*/gi)[0] || null
    const value = plazo.match(/[0-9]+/gi)[0] || null

    if (type === 'ap') {
      this.Plazos[`msi${value}`] = this.Plazos[plazo] ? false : this.Plazos[`msi${value}`]
    } else {
      this.Plazos[`ap${value}`] = this.Plazos[plazo] ? false : this.Plazos[`ap${value}`]
    }
  }

  ObtenerPlazos() {
    const plazos = []
    for (const plazo of Object.keys(this.Plazos)) {
      if (this.Plazos[plazo]) {
        plazos.push(plazo)
      }
    }

    if (!plazos.length) {
      this.error_plazo = true
      throw "Necesitas minimo un plazo"
    }
    return plazos.join(',')
  }

  SetPlazos(plazos: string) {
    for (const plazo of plazos.split(',')) {
      this.Plazos[plazo] = true
    }
  }

  ShowDialog(type, text, text2 = '', home = false) {
    this.dialog.open(MessageBoxComponent, {
      width: "500px",
      data: {
        text,
        text2,
        type,
        icon: type,
      },
    }).afterClosed().subscribe( res => {
      if (home) {
        this.location.back()
      }
    });
  }

  ImagePreview() {
    const producto:any = document.querySelector("input[name='product_image']")
    const file = producto.files[0]

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.image_preview = reader.result
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  ClickFile() {
    const producto:any = document.querySelector("input[name='product_image']")
    producto.click()
  }

  GetCostoFinanciero(costo_financiero, update = false) {
    this.costo_financiero = costo_financiero
    console.log(costo_financiero);
    
    this.Product.suggested_customer_price = costo_financiero.aumentar_valor_factura
      ? (Number(this.Product.value) / (1-costo_financiero.porcentaje_pago_inicial/100))
      : Number(this.Product.value)

      if (update) {
        this.Product.value = costo_financiero.disminuir_valor_factura
          ? (Number(this.Product.value) - (Number(this.Product.value) * (costo_financiero.porcentaje_pago_inicial/100))).toFixed(2)
          : Number(this.Product.value).toFixed(2)
      }

    this.AjustarPrecioLista()
  }

  AjustarPrecioLista() {
    this.Product.customer_price = this.Product.suggested_customer_price > this.Product.customer_price
      ? this.Product.suggested_customer_price
      : this.Product.customer_price
  }

  ValidarPrecioMinimo() {
    if (Number(this.Product.suggested_customer_price) <= Number(this.Product.value)) {
      this.Product.suggested_customer_price = Number(this.Product.value)
    }

    if (Number(this.Product.suggested_customer_price) >= Number(this.Product.customer_price)) {
      this.Product.suggested_customer_price = Number(this.Product.customer_price)
    }
  }

  DatosValidos(): Boolean {
    console.log(this.Product.isValid());
    
    return this.Product.isValid()
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
  openNotifications(){
    console.log(this.show_notifications)
    this.show_notifications = !this.show_notifications
  }
  checkInput(nameInput: any) {
    this.isInputEmpty = !nameInput;
  }
}
