import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-fin-proceso-message',
  templateUrl: './fin-proceso-message.component.html',
  styleUrls: ['./fin-proceso-message.component.scss']
})
export class FinProcesoMessageComponent implements OnInit {
  
  constructor(
    public dialogref:MatDialogRef<FinProcesoMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) { }

  ngOnInit(): void {
  }

}
