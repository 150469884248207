import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ContratoService {
    constructor(private http: HttpClient, private router: Router) { }

    ActualizarContrato( formData ) {
        return this.http.post<any>(`${environment.config.apiUrl}anexo/actualizar_contrato`, formData);
      }
    
}
