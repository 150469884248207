import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-validation',
  templateUrl: './input-validation.component.html',
  styleUrls: ['./input-validation.component.scss'],
})
export class InputValidationComponent implements OnInit {
  @Input() formControl: FormControl;
  @Input() formGroup: FormGroup;
  @Input() controlName: string;
  @Input() type: any 
  @Input() label: any 
  @Input() isError: boolean = false
  @Input() errorMessage: string = '' 
  @Input() mask: string
  @Input() isPassword: boolean = false;
  @Input() maxlength: number; 


  validIcon: string = '../../../../assets/icons/new-icons/check-input.svg';
  errorIcon: string = '../../../../assets/icons/new-icons/invalid-input.svg';
  showPassword: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }
  getFormControl(controlName: string): FormControl | null {
    if (this.formControl) {
      return this.formControl;
    } else if (this.formGroup && controlName) {
      return this.formGroup.get(controlName) as FormControl;
    } else {
      return null;
    }
  }
  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
    this.type = this.showPassword ? 'text' : 'password';
    console.log(this.showPassword)
  }
  
  
  
}
