<div class="documentos-rechazados">
    <h2>Documentos rechazados</h2>
    <table>
        <thead>
            <tr>
                <th>N</th>
                <th>Documento</th>
                <th>Comentario</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let documento of documentos; let i = index">
                <td>{{i +1}}</td>
                <td>{{documento.documento}}</td>
                <td>{{documento.comentario}}</td>
            </tr>
        </tbody>
    </table>
    <Button class="btn-primary-main" (click)="Cerrar()">Aceptar</Button>
</div>