<div class="container">
    <svg *ngIf="closeBtn" (click)="Close()" style="height: 25px; width:auto;cursor:pointer;" xmlns="http://www.w3.org/2000/svg" width="48.552" height="48.552" viewBox="0 0 48.552 48.552"><defs><style>.close{fill:#fff;}</style></defs><path class="close" d="M8.146,6.281,6.281,8.146,28.693,30.557,6.281,52.969l1.864,1.864L30.557,32.421,52.969,54.833l1.864-1.864L32.421,30.557,54.833,8.146,52.969,6.281,30.557,28.693Z" transform="translate(-6.281 -6.281)"/></svg>
    <img height="60px" width="auto" style="margin-left: -50px;" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/logo-header-u.svg" alt="">
    
    <span>Comentario</span>
    <textarea [(ngModel)]="comentarios"></textarea>
    <mat-error *ngIf="messageRequiredError">
        El comentario es requerido
    </mat-error>
    <button mat-button (click)="Rechazar()" style="margin-top: 12px;">
        <span *ngIf="data != undefined">{{data.textBtn}}</span>
        <span *ngIf="data == undefined">ENVIAR</span>
    </button>
</div>