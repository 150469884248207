<div class="section" *ngIf="id != '0'">
    <div class="container">
      <div class="columns">
        <div class="column">
          <div class="card">
            <div class="header">
              <div class="logo-lfu">
                <img src="https://static.api.leaseforu.com/assets/Logo-white.svg"/>
              </div>
              <div class="avatar">
                <img src={{perfil?.url_image}} alt="">
              </div>
            </div>
            <div class="card-body">
              <div class="user-data">
                <h3 class="username">{{perfil?.name}} <span>{{perfil?.first_lastname}}</span></h3>
                <h5 class="position">{{perfil?.jobtitle}}</h5>
              </div>
              <div class="subtitle">
                <h4>Contacta con nosotros</h4>
              </div>
              <div class="user-contact">
                
                <div class="button-container">
                <a href="tel:{{perfil?.phone}}" class="button-contact"><img class="img-contact" src="https://static.api.leaseforu.com/assets/contact.svg"/><span>Contacto</span></a>
                </div>
                <div class="button-container">
                  <a href="https://wa.me/{{perfil?.phonewhatsapp}}" class="button-contact"><img class="img-contact" src="https://static.api.leaseforu.com/assets/WhatsApp.svg"/><span>WhatsApp</span></a>
                  </div>
                  <div class="button-container">
                    <a href="mailto:{{perfil?.email}}" class="button-contact"><img class="img-contact" src="https://static.api.leaseforu.com/assets/correo-logo.svg"/><span>Correo</span></a>
                    </div>
              </div>
              <div class="subtitle">
                <h4>Encuéntranos en nuestras redes</h4>
              </div>
              <div class="user-contact">
                
                <div class="button-container">
                <a href="https://www.facebook.com/profile.php?id=100064230634432" class="button-contact"><img class="img-contact" src="https://static.api.leaseforu.com/assets/Facebook.svg"/><span>Facebook</span></a>
                </div>
                <div class="button-container">
                  <a href="https://mobile.twitter.com/lease_for_u" class="button-contact"><img class="img-contact" src="https://static.api.leaseforu.com/assets/Twitter-logo.svg"/><span>Twitter</span></a>
                  </div>
                  <div class="button-container">
                    <a href="https://www.linkedin.com/company/lease-for-u/mycompany/" class="button-contact"><img class="img-contact" src="https://static.api.leaseforu.com/assets/LinkedIn.svg"/><span>LinkedIn</span></a>
                    </div>
              </div>
              
            </div>
            <div class="footer">
              <div class="button-cotiza">
                <a href="https://www.leaseforu.com/#cotizador">¡Cotiza ahora con nosotros!</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
