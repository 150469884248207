import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { first } from "rxjs/operators";

import { MustMatch } from "../../_helpers/must-match.validator";

import {
  AlertService,
  UserService,
  AuthenticationService,
} from "../../_services";
import { MatDialog } from "@angular/material/dialog";
import { MessageBoxComponent } from "src/app/_dialogs/message-box/message-box.component";
import { CustomValidators } from "./custom-validators";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  changeForm: FormGroup;
  loading = false;
  submitted = false;

  invalid_password = false;
  actualPasswordHide = false;
  newPasswordHide = false;
  confirmPasswordHide = false;
  user_not_register = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private alertService: AlertService,
    private dialog: MatDialog
  ) {}

  // convenience getter for easy access to form fields
  get f() {
    return this.changeForm.controls;
  }

  ngOnInit(): void {
    this.changeForm = this.formBuilder.group(
      {
        password: [
          "",
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(20),
          ],
        ],
        //   password: ['', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&#.])[A-Za-z0-9$@$!%*?&#.].{7,19}$')]],
        //newPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]],
        
        newPassword: [
          "",
          [
            Validators.compose([
              Validators.required,
              Validators.minLength(8),
              // Validators.pattern(/\d/, { hasNumber: true }),
              CustomValidators.patternValidator(/\d/, { hasNumber: true }),
              CustomValidators.patternValidator(/[A-Z]/, {
                hasCapitalCase: true,
              }),
              CustomValidators.patternValidator(/[a-z]/, {
                hasSmallCase: true,
              }),
              CustomValidators.patternValidator(
                /[ +$@$!%*?&#.]/,
                {
                  hasSpecialCharacter: true,
                }
              ),
            ]),
          ],
        ],
        confirmPassword: ["", [Validators.required]],
      },
      {
        validator: MustMatch("newPassword", "confirmPassword"),
      }
    );
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.changeForm.invalid) {
      return;
    }

    // let objUser = Object.assign({}, this.changeForm.value);
    // delete objUser['confirmPassword'];

    // console.log(objUser);

    this.loading = true;
    this.userService
      .changePassword(this.changeForm.value)
      .pipe(first())
      .subscribe(
        (res) => {
          const { status } = res;
          this.loading = false;

          if (status == 220) {
            return (this.invalid_password = true);
          } else if (status == 200) {
            this.loading = false;
            this.router.navigate(["/login"]);
            this.dialog.open(MessageBoxComponent, {
              width: "500px",
              data: {
                text: "Éxito",
                text3: "Se ha generado tu nueva contraseña",
                type: "success",
                icon: "success",
              },
            });
            this.authenticationService.logout();
          }
        },
        (error) => {
          this.loading = false;
          this.dialog.open(MessageBoxComponent, {
            width: "500px",
            data: {
              text: "UPS",
              text3: "Fallo de conexión",
              type: "error",
              icon: "error",
            },
          });
        }
      );
  }
  onKeyup({ keyCode }) {
    if (keyCode === 13) {
      this.onSubmit();
    }
  }
}
