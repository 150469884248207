import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NuevaSolicitudService } from 'src/app/_services/nueva_solicitud.service';

@Component({
  selector: 'app-carga-ine',
  templateUrl: './carga-ine.component.html',
  styleUrls: ['./carga-ine.component.scss']
})
export class CargaIneComponent implements OnInit {
  pasaporte = false;
  parteFrontal = false;
  partePosterior = false;
  frontImagePath = '';
  backImagePath = '';
  messageError = false;
  loading = false;

  primerNombre = '';
  segundoNombre = '';
  primerApellido = '';
  segundoApellido = '';
  fechaNacimiento = '';
  curp = '';
  cp='';

  constructor(
    private router: Router,
    private nuevaSolicitudService: NuevaSolicitudService,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    localStorage.removeItem('dataIne')
  }
  togglePasaporte() {
    this.pasaporte = !this.pasaporte;

  }
  async selectFile() {
    const fileInput = document.getElementById('fileIneFront') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
      fileInput.addEventListener('change', async (event) => {
        const files = (event.target as HTMLInputElement).files;
        if (files && files.length > 0) {
          const file = files[0];
          const reader = new FileReader();
          reader.onload = () => {
              this.frontImagePath = reader.result as string;
              this.parteFrontal = true;
          };
          reader.readAsDataURL(file);
        }
      });
    }
  }

  async selectFileBack() {
    const fileInput = document.getElementById('fileIneBack') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
      fileInput.addEventListener('change', async (event) => {
        const files = (event.target as HTMLInputElement).files;
        if (files && files.length > 0) {
          const file = files[0];
          const reader = new FileReader();
          reader.onload = () => {
            this.backImagePath = reader.result as string;
            this.partePosterior = true;          
          };
          reader.readAsDataURL(file);
        }
      });
    }
  }

  onFileSelected(event: any): void {
    const selectedFile = event.target.files[0];
  }

  async continuar() {
    this.loading = true;   

    if (this.parteFrontal && this.partePosterior && !this.pasaporte || this.parteFrontal && this.pasaporte) {
      this.loading = true;   
      localStorage.setItem("sin_ine", "false")

      try {
        const res = await this.nuevaSolicitudService.validarIdentificacion(this.frontImagePath, this.backImagePath, null);
       
  
        if (res && res.uuid) {
          this.nuevaSolicitudService.checkUUIDstatus(res.uuid).subscribe(
            (response) => {
              const first_name = response?.given_names.value.split(' ')[0] ?? '';
              const second_name = response?.given_names.value.split(' ')[1] ?? '';
              const first_lastname = response?.father_surname?.value ?? '';
              const second_lastname = response?.mother_surname?.value ?? '';
              const birthday = response?.date_of_birth.value ?? '';
              const curp = response?.personal_number.value ?? '';
              const zip = response.cp.value == '00000' ? '' : response.cp.value ?? '';

              const dataIne = {
                first_name,
                second_name,
                first_lastname,
                second_lastname,
                birthday,
                "direccion": {
                  zip
                },
                curp,
                frontImagePath: this.frontImagePath, 
                backImagePath: this.backImagePath
                
              }
              localStorage.setItem('dataIne', JSON.stringify(dataIne))
              this.loading = false;
              
              this.ngZone.run(() => {
                this.router.navigate(['/nueva_solicitud/registro']);
              });
            },
            (error) => {
              console.error('Error en la solicitud de validación:', error);
              this.loading = false;
            }
          );
  
        } else {
          console.error('No se recibió un UUID en la respuesta.');
          this.loading = false;
        }
      } catch (err) {
        console.error('Error:', err);
        this.loading = false;
      }
    } else {
      this.messageError = true;
      this.loading = false;
    }
  }
  
  
  continuarSinIne(){
    localStorage.setItem("sin_ine", "true")
    this.router.navigate(['/nueva_solicitud/registro'])
  }



}
