<form [formGroup]="domicilioForm" class="frmDatosDomicilio">
	<div class="datos_generales_1">
		<div>
			<div><div class="labelDiv"><mat-label>Código del país</mat-label></div><div (click)="showTooltip('codigoPais')">
				<p>
					<svg xmlns="http://www.w3.org/2000/svg" style="border: 2px solid #3F4A57; border-radius: 45px;" width="19" height="19" viewBox="0 0 17.328 17.328"><defs><style>.info_icon{fill:#fff; stroke: #3F4A57;stroke-width: 1;}</style></defs><path class="info_icon" d="M10.664,2a8.664,8.664,0,1,0,8.664,8.664A8.664,8.664,0,0,0,10.664,2Zm.866,13H9.8V13.263H11.53Zm0-3.466H9.8v-5.2H11.53Z" transform="translate(-2 -2)"/></svg>
				</p></div></div>
			<div class="noDisplay" id="codigoPais">
				<p>Para poder solicitar el arrendamiento, el solicitante debe de tener residencia mexicana.</p>
			</div>
			<input
				[readonly]="true"
				formControlName="codigo_pais" 
				[value]="'MX'"
			>
		</div>

		<div>
			<mat-label>Calle</mat-label>
			<input  
			maxlength="40"
			[specialCharacters]="['space']"
			[patterns]="customPattern"
			mask="E{100}"
				formControlName="calle" 
				[value]="datosGen.calle"
				[ngClass]="{ 'is-invalid': submitted && f.calle.errors }"
			>
			<mat-error *ngIf="submitted && f.calle.errors" class="invalid-feedback">
				<div *ngIf="f.calle.errors.required">Campo obligatorio</div>
			</mat-error>
        </div>
        
        <div class="mainCont">
            <div class="numberCont">
                <div><div class="labelDiv"><mat-label>Número</mat-label></div><div (click)="showTooltip('numero')">
					<p>
						<svg xmlns="http://www.w3.org/2000/svg" style="border: 2px solid #3F4A57; border-radius: 45px;" width="19" height="19" viewBox="0 0 17.328 17.328"><defs><style>.info_icon{fill:#fff; stroke: #3F4A57;stroke-width: 1;}</style></defs><path class="info_icon" d="M10.664,2a8.664,8.664,0,1,0,8.664,8.664A8.664,8.664,0,0,0,10.664,2Zm.866,13H9.8V13.263H11.53Zm0-3.466H9.8v-5.2H11.53Z" transform="translate(-2 -2)"/></svg>
					</p></div></div>
				<div class="noDisplay" id="numero">
					<p>Si el domicilio no tiene número, especificar "SN".</p>
				</div>
				<input  
                [specialCharacters]="['space']"
                [patterns]="customPattern"
                mask="E{100}"
                    formControlName="numero" 
                    [value]="datosGen.numero"
                    [ngClass]="{ 'is-invalid': submitted && f.numero.errors }"
                >
                <mat-error *ngIf="submitted && f.numero.errors" class="invalid-feedback">
                    <div *ngIf="f.numero.errors.required">Campo obligatorio</div>
				</mat-error>
            </div>
    
            <div class="cpCont">
				<div><div class="labelDiv" style="padding: 13px 0;"><mat-label>C.P.:</mat-label></div></div>
				<br>
				<input  
					#input_cp
					mask="00000"
                    formControlName="cp" 
                    [value]="datosGen.cp"
                    [ngClass]="{ 'is-invalid': submitted && f.cp.errors }"
					(input)="SearchState(input_cp.value)"
                >
                <mat-error *ngIf="submitted && f.cp.errors" class="invalid-feedback">
                    <div *ngIf="!f.cp.value && f.cp.errors.required">Campo obligatorio</div>
                    <div *ngIf="f.cp.value && f.cp.value.length < 5">Campo no válido</div>
                </mat-error>
            </div>
        </div>

		<div>
			<!-- <mat-label>Colonia / Población</mat-label>
			<input
				[specialCharacters]="['space']"
				[patterns]="customPattern"
				mask="S{40}"
				formControlName="colonia_poblacion" 
				[value]="datosGen.colonia_poblacion"
				[ngClass]="{ 'is-invalid': submitted && !f.colonia_poblacion.valid }"
			>
			<mat-error *ngIf="submitted && !f.colonia_poblacion.valid" class="invalid-feedback">
				<div *ngIf="!f.colonia_poblacion.valid">Campo obligatorio</div>
			</mat-error> -->
		</div>
	</div>

	<div class="datos_generales_2">
		<div>
			<div><div class="labelDiv"><mat-label>Delegación / Municipio</mat-label></div></div>
			<input
				[specialCharacters]="['space']"
				[patterns]="customPattern"
				mask="S{40}"
				formControlName="delegacion_municipio" 
				[value]="datosGen.delegacion_municipio"
				[ngClass]="{ 'is-invalid': submitted && !f.delegacion_municipio.valid }"
			>
			<mat-error *ngIf="submitted && !f.delegacion_municipio.valid" class="invalid-feedback">
				<div *ngIf="!f.delegacion_municipio.valid">Campo obligatorio</div>
			</mat-error>
		</div>

        <div>
			<mat-label>Ciudad</mat-label>
			<input
				[specialCharacters]="['space']"
				[patterns]="customPattern"
				mask="S{40}"
				formControlName="ciudad" 
				[value]="datosGen.ciudad"
				[ngClass]="{ 'is-invalid': submitted && !f.ciudad.valid }"
			>
			<mat-error *ngIf="submitted && !f.ciudad.valid" class="invalid-feedback">
				<div *ngIf="!f.ciudad.valid">Campo obligatorio</div>
			</mat-error>
		</div>

		<div>
			<mat-label>Estado</mat-label>
			<mat-select formControlName="estado" [value]="'CDMX'">
				<mat-option *ngFor="let estado of estados" value="{{estado.id}}">{{estado.id}}		{{estado.valor}}</mat-option>
			</mat-select>
			<mat-error *ngIf="submitted && f.estado.errors" class="invalid-feedback">
				<div *ngIf="f.estado.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>
	</div>
</form>
<div class="row-end">
	<button mat-button (click)="back()">
		<svg style="height: 20px; width:auto; transform: rotate(180deg)" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><defs><style>.cba{fill:#3fa4d7;}</style></defs><path class="cba" d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0ZM22,38V18.7L13.448,27.25l-3.394-3.394L24,9.908,37.948,23.856,34.554,27.25,26,18.7V38Z" transform="translate(48) rotate(90)"/></svg>
		Regresar
	</button>
	<div></div>
	<mat-spinner *ngIf="loading" diameter="30" style="margin-right: 30px;"></mat-spinner>
	<button *ngIf="!loading" mat-button (click)="onSubmit()">
		Siguiente
		<svg [ngClass]="{'btnNext': domicilioForm.valid}"  style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><defs><style>.abc{fill:#707070;}</style></defs><path class="abc" d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0ZM22,38V18.7L13.448,27.25l-3.394-3.394L24,9.908,37.948,23.856,34.554,27.25,26,18.7V38Z" transform="translate(48) rotate(90)"/></svg>
	</button>
</div>