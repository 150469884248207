<div mat-dialog-content class="container" [ngClass]="{'success': data.type == 'success' || data.type == 'cotizacion' || data.type == 'confirm' || data.type == 'confirm-cot', 'error': data.type == 'error', 'confirm-cot': data.type == 'confirm-cot'}">
    <div class="text1" [ngClass]="{'confirm': data.type == 'confirm', 'confirm-cot': data.type == 'confirm-cot'}" [innerHTML]="data.text"></div>
    <div class="text2">{{ data.text2 }}</div>
	<div class="text3" [ngClass]="{'confirm': data.type == 'confirm'}">{{ data.text3 }}</div>
	
	<div class="row perfiles">
		<div class="col" *ngIf="[6].includes(data.perfil)">
			<div  >
				<div class="control">
					<mat-select [(ngModel)]="perfil_director_comercial" (selectionChange)="BuscarGerentesComercial()">
						<mat-option [value]="0">Director Comercial</mat-option>
						<mat-option *ngFor="let item of directores_comercial" [value]="item.id">{{ item.nombre_cliente }}</mat-option>
					</mat-select>
				</div>
			</div>
		</div>
		<div class="col" *ngIf="[6,12].includes(data.perfil)">
			<div >
				<div class="control">
					<mat-select [(ngModel)]="perfil_gerente_comercial" (selectionChange)="BuscarComerciales()">
						<mat-option [value]="0">Gerente Comercial</mat-option>
						<mat-option *ngFor="let item of gerentes_comercial" [value]="item.id">{{ item.nombre_cliente }}</mat-option>
					</mat-select>
				</div>
			</div>
		</div>
		<div class="col" *ngIf="[6,12,8,10].includes(data.perfil)">
			<div >
				<div class="control">
					<mat-select [(ngModel)]="perfil_comercial" (selectionChange)="BuscarVendors()">
						<mat-option [value]="0">Comercial</mat-option>
						<mat-option *ngFor="let item of comerciales" [value]="item.id">{{ item.nombre_cliente }}</mat-option>
					</mat-select>
				</div>
			</div>
		</div>
		<div class="col" *ngIf="perfil_comercial != 0">
			<div >
				<div class="control">
					<mat-select [(ngModel)]="perfil_vendor" (selectionChange)="BuscarVendedores()">
						<mat-option [value]="0">Vendor</mat-option>
						<mat-option *ngFor="let item of vendors" [value]="item.id">{{ item.nombre_cliente }}</mat-option>
					</mat-select>
				</div>
			</div>
		</div>
		<div class="col" *ngIf="perfil_vendor != 0">
			<div >
				<div class="control">
					<mat-select [(ngModel)]="perfil_vendedor">
						<mat-option [value]="0">Vendedor</mat-option>
						<mat-option *ngFor="let item of vendedores" [value]="item.id">{{ item.nombre_cliente }}</mat-option>
					</mat-select>
				</div>
			</div>
		</div>
	</div>
	<div class="row" style="margin:20px">
		<span *ngIf="error">Selecciona un comercial, vendor o vendedor</span>
	</div>
    <div *ngIf="data.type == 'confirm' || data.type == 'confirm-cot'">
        <div class="options" *ngIf="data.options != 'options'" [ngClass]="{'confirm-cot': data.type == 'confirm-cot'}">
			<button mat-icon-button class="confirm-button" (click)="Confirm(false)"><mat-icon>clear</mat-icon></button>
			<button mat-icon-button class="confirm-button" (click)="Confirm(true)"><mat-icon>done</mat-icon></button>
        </div>
    </div>
</div>