<div class="container-nueva-solicitud">
  <div class="header">
    <img
      class="logo"
      mat-button
      height="50px"
      width="auto"
      [routerLink]="['/dashboard']"
      src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/NewLogo-L4U.png"
      alt="leaseforu"
    />
    <div class="container-btn-login">
      <p>¿Ya tienes cuenta?</p>
      <a class="btn-login" (click)="iniciarSesion()">Iniciar sesión</a>
    </div>
  </div>
  <div style="max-height: 90vh">
    <app-carga-ine *ngIf="parte == 1"></app-carga-ine>
    <app-register-client *ngIf="parte == 2"></app-register-client>
  </div>
</div>
