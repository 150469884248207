<div class="container-modal">
  <mat-dialog-content class="container-btn">
    <h4>{{ data.text }}</h4>
    <div  *ngIf="!data.folio" class="custom-form-field" [class.non-empty]="!isInputEmptyFolio">
        <label>Ingresa Folio de Vista Previa</label>
        <input
          type="text"
          placeholder=" "
          (input)="checkInputValue($event, 'folio')"
          required
          [value]="folio"
        />
      </div>
    <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
      <label>Correo del cliente</label>
      <input
        type="text"
        placeholder=" "
        (input)="checkInputValue($event, 'correo_cliente')"
        required
        [value]="correo_cliente"
      />
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="footer-btn" *ngIf="!loading">
    <button (click)="guardar()">
      <img
        src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/check-green.svg"
      />Si
    </button>
    <button (click)="cerrar()">
      <img
        src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/uncheck-red.svg"
      />Regresar
    </button>
  </mat-dialog-actions>
  <mat-spinner *ngIf="loading" diameter="30"></mat-spinner>
</div>
