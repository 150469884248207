<div class="container">
    <div class="header" style="box-shadow: 0 2px 12px rgb(0 71 128 / 16%)">
      <div class="menu-header">
        <a href="https://www.leaseforu.com/">
          <img
            class="logo"
            mat-button
            height="40px"
            width="auto"
            src="https://static.api.leaseforu.com/assets/New-logo-LFU.svg"
            alt="leaseforu"
          />
        </a>
      </div>
    </div>
    <div class="container-title">
      <h1>Mejorar tu cotizacion</h1>
    </div>
    <div class="container-charging" *ngIf="charging">
      <div class="text">
          <p>Estamos analizando tu información. Un momento, por favor.</p>
      </div>
      <div class="container-progress">
          <img src="https://static.api.leaseforu.com/assets/letter.svg"/>
          <!-- <mat-progress-bar mode="indeterminate"></mat-progress-bar> -->
          <div class="progress">
              <div class="progress-value"></div>
            </div>
      </div>
    </div>
    <div class="container-page" *ngIf="!charging">
      <div class="container-box">
        <div class="container-preaprobado" *ngIf="mejorarCotizacionJson">
        <div class="container-name">
          <h3 class="title-name" >
            ¡{{ mejorarCotizacionJson?.prospecto.primer_nombre }}{{ " "
            }}<span>{{ mejorarCotizacionJson?.prospecto.apellido_paterno }}</span
            >{{ " " }}tu solicitud ha sido preaprobada!
          </h3>
          
        </div>
        <p >Tu mensualidad queda en:</p>
        <h2 class="title-mensualidad" >
          {{ mensualidad_mejorada | currency : "USD" : "symbol-narrow" }}
        </h2>
        <p class="text-sujeto">
          El monto esta sujeto a la evaluación de tu perfil.
        </p>
        <p class="text-instructions" >
          Hemos mandamos las instrucciones de acceso para los siguientes pasos al
          correo: <span>{{ mejorarCotizacionJson?.email }}</span>
        </p>
        
        </div>
        <div class="container-noaprobado" *ngIf="!mejorarCotizacionJson" >
          <div class="contianer-name">
            <h3 class="title-name" *ngIf="!mejorarCotizacionJson">
              ¡Lo sentimos, <br/>
              {{nombreProsCom}} <span>{{apellidoProsCom}}</span>!
            </h3>
          </div>
          <p class="text-noAprobado">
            Por el momento no podemos aprobar tu solicitud,<br/> puedes intentarlo nuevamente en 3 meses
          </p>
  
        </div>
        <div class="container-letter"></div>
        <div class="container-animation"></div>
  
      </div>
      <div class="container-title"  >
        <a class="link-sf" href="https://www.leaseforu.com/"><button  class="btn-gradient">Regresar</button></a>
      
      </div>
    </div>
  </div>
  