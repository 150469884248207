const cur_date = (new Date()).toISOString().split('T')[0]

export const MESES = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
]

export const COMPORTAMIENTO_PAGO = [
    {id: 1, nombre: "Bueno", puntuacion: 10},
    {id: 2, nombre: "Regular", puntuacion: 5},
    {id: 3, nombre: "Malo", puntuacion: 0},
]

export const COMPORTAMIENTO_PAGO_CREDITO = [
    {id: 1, nombre: "Bueno", puntuacion: 4},
    {id: 2, nombre: "Regular", puntuacion: 2},
    {id: 3, nombre: "Malo", puntuacion: 0},
]

export const TIEMPO_ENTREGA = [
    {id:0, tiempo:'Inmediato'},
    {id:1, tiempo:'10 a 30 días'},
    {id:2, tiempo:'31 a 60 días'},
    {id:3, tiempo:'61 a 90 días'}
]


export const Analisis = {
    numero_comite:<number> 0,
    tipo_comite: '',
    fecha_creacion: cur_date,
    inicio_operaciones: '',
    cliente_l4u: false,
    id_comportamiento_pago: 3,
    domicilio: '',
    
    nombre_escuela_egresado: '',
    residencia_escuela: '',
    especialidad: '',
    actividad_economica: '',
    
    ingresos_nomina: 0,
    ingresos_nominaCopy: '',
    ingresos_efectivo: 0,
    ingresos_efectivoCopy: '',
    ingresos_extra: 0,
    ingresos_extraCopy: '',
    factor_renta: 0,
    
    maximo_factor: 60,
    comprobantes_ingresos: 0,
    comprobantes_gastos: 0,
    comprobantes_factor: 0,
    efectivo_ingresos: 0,
    efectivo_gastos: 0,
    efectivo_factor: 0,
    extra_nuevo_equipo_ingresos: 0,
    extra_nuevo_equipo_gastos: 0,
    extra_nuevo_equipo_factor: 0,

    comprobantes_ingresosCopy: '',
    comprobantes_gastosCopy: '',
    efectivo_ingresosCopy: '',
    efectivo_gastosCopy: '',
    extra_nuevo_equipo_ingresosCopy: '',
    extra_nuevo_equipo_gastosCopy: '',

    tiempo_entrega: 0,
    tipo_vendor: 'Interno',
    vendor: '',
    comercial: '',
    marca: '',
    garantia: '',
    valor_residual: '',
    valor_residualCopy: '',
    mar_total: 0,
    mar_totalCopy: '',
    monto_arrendar: 0,
    monto_arrendarCopy:'',
    descripcion_activo: '',
    tipo_activo_arrendar: '',
    factor_porcentaje: 0,
    plazo: 0,
    mensualidad: 0,
    mensualidadCopy:'',

    rentas_anticipadas: '0',
    rentas_anticipadasCopy: '0',

    saldo_inicial: 0,

    vida_util: '',
    vida_util_pts: <number>0,
    mercado_secundario: '',
    mercado_secundario_pts: <number>0,
    estimado_de_colocacion: '',
    estimado_de_colocacion_pts: <number>0,
    ubicacion: '',
    ubicacion_pts: <number>0,
    tipo_financiamient: '',
    tipo_financiamiento_pts:<number> 0,
    
    promedio_depositos: 0,
    promedio_gastos: 0,
    promedio_saldo_final: 0,
    promedio_depositosCopy: '',
    promedio_gastosCopy: '',
    promedio_saldo_finalCopy: '',

    comentarios_caracter: '',
    comentarios_capacidad_pago: '',
    comentarios_colateral: '',

    puntos_credito_abierto:  0,
    puntos_credito_cerradas: 0,

    puntuacion_comportamiento_pago: 0,
    puntuacion_caracter: 0,
    puntuacion_capacidad_pago: 0,
    puntuacion_colateral: 0,

    perfil_solicitante: {
        bc_score: 0,
        bc_score_descrip: '>= 630',
        bc_score_punt: 0,
        icc: 0,
        icc_descrip: '>= 6',
        icc_punt: 0,
        arraigo_domicilio: 0,
        arraigo_domicilio_descrip: '> 2 AÑOS',
        arraigo_domicilio_punt: 0,
        arraigo_empleo: 0,
        arraigo_empleo_descrip: '> 2 AÑOS',
        arraigo_empleo_punt: 0,
        consejo_especialidad: '',
        consejo_especialidad_descrip: 'REGISTRO',
        consejo_especialidad_punt: 0,
        total: 0,
    },

    perfil_aval: {
        nombre: '',
        bc_score: 0,
        bc_score_descrip: '>= 630',
        bc_score_punt: 0,
        icc: 0,
        icc_descrip: '>= 6',
        icc_punt: 0,
        documentacion: '',
        documentacion_descrip: 'ok',
        documentacion_punt: 0,
        parentezco: '',
        parentezco_descrip: '',
        parentezco_punt: 0,
        ocupacion: '',
        ocupacion_descrip: '',
        ocupacion_punt: 0,
        total: 0,
    },

    historial_credito: {
        activas: [
        ],
        activasCopy:[],
        cerradas: [
        ],
        cerradasCopy: []
    },
    historial_movimientos: [
        {mes: '', depositos: 0, gastos: 0, saldo_final: 0}
    ],
    historial_movimientos_copy: []
}

export const tiposContrato: any = {
	'AF': 'Aparatos /Muebles',
	'AG': 'Agropecuario (PFAE)',
	'AL': 'Arrendamiento Automotriz',
	'AP': 'Aviación',
	'AU': 'Compra de Automóvil',
	'BD': 'Fianza',
	'BT': 'Bote / Lancha',
	'CC': 'Tarjeta de Crédito',
	'CE': 'Cartas de Crédito (PFAE)',
	'CF': 'Crédito fiscal',
	'CL': 'Línea de Crédito',
	'CO': 'Consolidación',
	'CS': 'Crédito Simple (PFAE)',
	'CT': 'Con Colateral (PFAE)',
	'DE': 'Descuentos (PFAE)',
	'EQ': 'Equipo',
	'FI': 'Fideicomiso (PFAE)',
	'FT': 'Factoraje',
	'HA': 'Habilitación o Avío (PFAE)',
	'HE': 'Préstamo tipo “Home Equity”',
	'HI': 'Mejoras a la casa',
	'LR': 'Línea de Crédito Reinstalable',
	'LS': 'Arrendamiento',
	'OA': 'Otros adeudos vencidos (PFAE)',
	'PA': 'Préstamo para Personas Físicas con Actividad Empresarial (PFAE)',
	'PB': 'Editorial',
	'PG': 'PGUE - Préstamo como garantía de unidades industriales para PFAE',
	'PL': 'Préstamo personal',
	'PN': 'Préstamo de nómina',
	'PR': 'Prendario (PFAE)',
	'PS': 'Pago de Servicios',
	'PQ': 'Quirografario (PFAE)',
	'RC': 'Reestructurado (PFAE)',
	'RD': 'Redescuento (PFAE)',
	'RE': 'Bienes Raíces',
	'RF': 'Refaccionario (PFAE)',
	'RN': 'Renovado (PFAE)',
	'RV': 'Vehículo Recreativo',
	'SC': 'Tarjeta garantizada',
	'SE': 'Préstamo garantizado',
	'SG': 'Seguros',
	'SM': 'Segunda hipoteca',
	'ST': 'Préstamo para estudiante',
	'TE': 'Tarjeta de Crédito Empresarial',
	'US': 'Préstamo no garantizado',
}