import { Component, OnInit } from '@angular/core';
import { AuthenticationService, UserService } from 'src/app/_services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ver-cotizaciones',
  templateUrl: './ver-cotizaciones.component.html',
  styleUrls: ['./ver-cotizaciones.component.scss']
})
export class VerCotizacionesComponent implements OnInit {
  folio = null
  cotizaciones = []

  constructor(private authenticationService:AuthenticationService,
    private router:Router,
    private userService:UserService) { }

  ngOnInit(): void {
    this.folio = JSON.parse(localStorage.getItem('client_details')).folio
    this.VerCotizaciones()
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
}

  VerCotizaciones(){
    this.userService.VerCotizaciones(this.folio)
      .subscribe( cotizaciones => {
        console.log("cotizaciones: ", cotizaciones);
        
        this.cotizaciones = cotizaciones
      } )
  }
}
