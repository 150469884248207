<div class="container">
    <pdf-viewer  *ngIf="url"
        [src]="url"
        [autoresize]="true"
        [original-size]="false">
    </pdf-viewer>
    <button mat-button routerLink="/dashboard">
        Siguiente
        <svg style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><defs><style>.abc{fill:#3CA2D6;}</style></defs><path class="abc" d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0ZM22,38V18.7L13.448,27.25l-3.394-3.394L24,9.908,37.948,23.856,34.554,27.25,26,18.7V38Z" transform="translate(48) rotate(90)"/></svg>
    </button>
</div>