import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent implements OnInit {

  @Input() initialTime: number;
  @Input() emailValidation: boolean = false;
  @Input() phoneValidation: boolean = false;
  @Output() solicitarPin = new EventEmitter<void>();

  timeLeft: number;
  timerInterval: any;
  begin_count= false;

  constructor() { }

  ngOnInit(): void {
    this.timeLeft = this.initialTime;
  }

  startCountdown() {
    console.log(this.timeLeft)
    if(this.emailValidation){
      this.solicitarPin.emit()
    }else if(this.solicitarPin){
      this.solicitarPin.emit()
    }
    this.begin_count = true;
    this.timerInterval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.timerInterval);
        this.begin_count = false;
        this.resetCountdown()
      }
    }, 1000);
  }
  formatTime(time: number): string {
    const minutes: number = Math.floor(time / 60);
    const seconds: number = time % 60;
    const minutesString: string = minutes < 10 ? '0' + minutes : '' + minutes;
    const secondsString: string = seconds < 10 ? '0' + seconds : '' + seconds;
    return `${minutesString}:${secondsString}`;
  }

  resetCountdown() {
    clearInterval(this.timerInterval);
    this.timeLeft = this.initialTime;
    this.begin_count = false;
  }



}
