import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MejorarCotizacionService {

  constructor(private http: HttpClient) {
  }

  Register(Form) {
    return this.http.post<any>(`${environment.config.apiUrl}create_account`, Form).toPromise()
  }

  Mejorar(Form) {
    return this.http.post<any>(`${environment.config.apiUrl}mejorar_cotizacion`, Form).toPromise()
  }

  EnviarPinEmail(email){
    return this.http.post<any>(`${environment.config.apiUrl}sendPinEmail`, email).toPromise()
  }
  VerificarEmail(email: string, code: number){
    return this.http.post<any>(`${environment.config.apiUrl}validate_email`, {email, code}).toPromise()
  }
  EnviarPinPhone(phone){
    return this.http.post<any>(`${environment.config.apiUrl}sendPINcode`, phone).toPromise()

  }


}
