<div class="container">
    <div class="proceso" (click)="ProcesoSeleccionado(0)" [ngClass]="{'active': proceso_seleccionado == 0}">
        <span class="label">Total</span>
        <span class="count"><br></span>
        <span class="importe"><br></span>
    </div>
    <div class="proceso" (click)="ProcesoSeleccionado(1)" [ngClass]="{'active': proceso_seleccionado == 1}">
        <span class="label">Cotización</span>
        <span class="count">{{ proceso.solicitud.cantidad }} trámites activo</span>
        <!-- <span class="importe">{{ proceso.solicitud.importe | currency:'$'}}</span> -->
    </div>
    <div class="proceso" (click)="ProcesoSeleccionado(2)" [ngClass]="{'active': proceso_seleccionado == 2}">
        <span class="label">Expediente</span>
        <span class="count">{{ proceso.expediente.cantidad }} trámites activo</span>
        <span class="importe">{{ proceso.expediente.importe | currency:'$'}}</span>
    </div>
    <div class="proceso" (click)="ProcesoSeleccionado(3)" [ngClass]="{'active': proceso_seleccionado == 3}">
        <span class="label">Confirmación</span>
        <span class="count">{{ proceso.confirmacion.cantidad }} trámites activo</span>
        <span class="importe">{{ proceso.confirmacion.importe | currency:'$'}}</span>
    </div>
    <div class="proceso" (click)="ProcesoSeleccionado(4)" [ngClass]="{'active': proceso_seleccionado == 4}">
        <span class="label">Comité</span>
        <span class="count">{{ proceso.comite.cantidad }} trámites activo</span>
        <span class="importe">{{ proceso.comite.importe | currency:'$'}}</span>
    </div>

    

    <div class="proceso" (click)="ProcesoSeleccionado(6)" [ngClass]="{'active': proceso_seleccionado == 6}">
        <span class="label">Condicionada</span>
        <span class="count">{{ proceso.aprobadas_con.cantidad }} trámites activo</span>
        <span class="importe">{{ proceso.aprobadas_con.importe | currency:'$'}}</span>
    </div>

    <div class="proceso" (click)="ProcesoSeleccionado(7)" [ngClass]="{'active': proceso_seleccionado == 7}">
        <span class="label">Info. Adicional</span>
        <span class="count">{{ proceso.info_adicional.cantidad }} trámites activo</span>
        <span class="importe">{{ proceso.info_adicional.importe | currency:'$'}}</span>
    </div>
    <div class="proceso" (click)="ProcesoSeleccionado(5)" [ngClass]="{'active': proceso_seleccionado == 5}">
        <span class="label">Aprobados</span>
        <span class="count">{{ proceso.aprobadas.cantidad }} trámites activo</span>
        <span class="importe">{{ proceso.aprobadas.importe | currency:'$'}}</span>
    </div>
</div>