import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BuroService } from "src/app/_services";
import { NuevaSolicitudService } from "src/app/_services/nueva_solicitud.service";

@Component({
  selector: "app-domicilio",
  templateUrl: "./domicilio.component.html",
  styleUrls: ["./domicilio.component.scss"],
})
export class DomicilioComponent implements OnInit {
  @Input() data: any;
  @Output() datosActualizados = new EventEmitter();

  
  frmDatosPersonales: FormGroup;
  domicilioForm: FormGroup;
  COLONIAS = [];
  estados = [
    { id: "CDMX", valor: "Ciudad de México", estado: "Ciudad de México" },
    { id: "AGS", valor: "Aguascalientes", estado: "Aguascalientes" },
    { id: "BCN", valor: "Baja California", estado: "Baja California" },
    { id: "BCS", valor: "Baja California Sur", estado: "Baja California Sur" },
    { id: "CAM", valor: "Campeche", estado: "Campeche" },
    { id: "CHS", valor: "Chiapas", estado: "Chiapas" },
    { id: "CHI", valor: "Chihuahua", estado: "Chihuahua" },
    { id: "COA", valor: "Coahuila", estado: "Coahuila de Zaragoza" },
    { id: "COL", valor: "Colima", estado: "Colima" },
    { id: "DGO", valor: "Durango", estado: "Durango" },
    { id: "EM", valor: "Estado de México", estado: "México" },
    { id: "GTO", valor: "Guanajuato", estado: "Guanajuato" },
    { id: "GRO", valor: "Guerrero", estado: "Guerrero" },
    { id: "HGO", valor: "Hidalgo", estado: "Hidalgo" },
    { id: "JAL", valor: "Jalisco", estado: "Jalisco" },
    { id: "MICH", valor: "Michoacán", estado: "Michoacán de Ocampo" },
    { id: "MOR", valor: "Morelos", estado: "Morelos" },
    { id: "NAY", valor: "Nayarit", estado: "Nayarit" },
    { id: "NL", valor: "Nuevo León", estado: "Nuevo León" },
    { id: "OAX", valor: "Oaxaca", estado: "Oaxaca" },
    { id: "PUE", valor: "Puebla", estado: "Puebla" },
    { id: "QRO", valor: "Querétaro", estado: "Querétaro" },
    { id: "QR", valor: "Quintana Roo", estado: "Quintana Roo" },
    { id: "SLP", valor: "San Luis Potosí", estado: "San Luis Potosí" },
    { id: "SIN", valor: "Sinaloa", estado: "Sinaloa" },
    { id: "SON", valor: "Sonora", estado: "Sonora" },
    { id: "TAB", valor: "Tabasco", estado: "Tabasco" },
    { id: "TAM", valor: "Tamaulipas", estado: "Tamaulipas" },
    { id: "TLAX", valor: "Tlaxcala", estado: "Tlaxcala" },
    { id: "VER", valor: "Veracruz", estado: "Veracruz de Ignacio de la Llave" },
    { id: "YUC", valor: "Yucatán", estado: "Yucatán" },
    { id: "ZAC", valor: "Zacatecas", estado: "Zacatecas" },
  ];
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue) {
      console.log("Nuevo valor deDATA en domicilio:", this.data);
      
    }
  }

  constructor(private formBuilder: FormBuilder,     private buroService: BuroService,
    private nuevaSolicitudService: NuevaSolicitudService,

    ) {}

  ngOnInit(): void {
    this.domicilioForm = this.formBuilder.group({
      calle: ["", Validators.required],
      cp: ["", Validators.required],
      ciudad: ["", Validators.required],
      estado: ["", Validators.required],
      delegacion_municipio: ["", Validators.required],
      colonia: ["", Validators.required],
      estado_nombre: [""],
      num_ext: ["", Validators.required],
    });
    if(this.data.cp || this.data.cp != "" || this.data.cp != "0000"){
      this.domicilioForm.patchValue({
        cp: this.data.cp
      })
      this.SearchState(this.data.cp)
    }
  }
  get fd() {
    return this.domicilioForm.controls;
  }


  async SearchState(cp) {
    if (cp.length == 5) {
      const estados: any = await this?.buroService.buscarEstado(cp);
      const colonia = estados.map((d) => d.response.asentamiento);
      this.COLONIAS = colonia;
      for (const cur of estados) {
        if (!cur.error) {
          const { municipio, estado, ciudad, asentamiento } = cur.response;
          this.fd.delegacion_municipio.setValue(municipio);
          this.fd.ciudad.setValue(ciudad);
          this.fd.estado.setValue(this.getEstado(estado));
          this.fd.estado_nombre.setValue(estado);

          break;
        }
      }
    }
  }

  getEstado(estado) {
    const result: any = this.estados.filter((e) => e.estado === estado);
    return result.length ? result[0].id : "CDMX";
  }
  async enviarDatos()  {
    this.data = {...this.data, ...this.domicilioForm.value}
    await this.nuevaSolicitudService.guardarDatosSolicitud(this.data, null, null)
    this.datosActualizados.emit(this.data);

  }
}
