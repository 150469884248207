

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, AuthenticationService, UserService } from '../../../../_services';
import { SolicitudService } from 'src/app/_services/solicitud.service';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';


import { customPatterns } from '../../../../_models/maskPattern'
/* tslint:disable */

@Component({
	selector: 'app-solicitud-a-p2',
	templateUrl: './solicitudAvalp2.component.html',
	styleUrls: ['./../../solicitudAval.component.scss']
})

export class SolicitudAvalp2Component implements OnInit {

	@Input() tipoPersona: number;
	@Input() tipoAval:number;
	@Output() parte = new EventEmitter<number>();
	@Output() onBack = new EventEmitter<number>();

	customPattern = customPatterns

	dgFForm: FormGroup;
	dgMForm: FormGroup;
	loading = false;
	submitted = false;
	returnUrl: string;
	datosAval:any = {}
	perfil = null
	constructor(
		private formBuilder: FormBuilder,
		// private route: ActivatedRoute,
		private router: Router,
		// private authenticationService: AuthenticationService,
		private alertService: AlertService,
		private solicitudService: SolicitudService,
		private userService:UserService,
		private dialog:MatDialog
	) {
		// redirect to home if already logged in

	}
	ngOnInit() {
		const currentUser = JSON.parse(localStorage.getItem('currentUser'))
		this.perfil = currentUser.perfil

		this.dgFForm = this.formBuilder.group({
			aval_funcion_public: [''],
			aval_funcion_public_desc: [''],
			aval_familiar_funcion_public: [''],
			aval_familiar_funcion_public_desc: [''],
			aval_nombre_solicitante: ['', [Validators.required]],
			aval_autoriza: ['', [Validators.required]],
		});

		this.dgMForm = this.formBuilder.group({
			aval_funcion_public: [''],
			aval_funcion_public_desc: [''],
			aval_familiar_funcion_public: [''],
			aval_familiar_funcion_public_desc: [''],
			aval_empresa: ['', Validators.required],
			aval_autoriza_empresa: ['', [Validators.required]],
			aval_nombre_representante: ['', [Validators.required]],
			aval_autorizacion_representante: ['', Validators.required],
			aval_nombre: ['', [Validators.required]],
			aval_autoriza: ['', [Validators.required]]
		});

		// get return url from route parameters or default to '/'
		// this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/dashboard';
		const solicitud = this.solicitudService.getReferencia()
		const tipo_persona = this.tipoAval
		const parte = 2

		const guardado = { ...solicitud, tipo_persona, parte}
		console.log(this.tipoAval, "TIPO");
		
		this.solicitudService.geteAval( {guardado} ).subscribe( res => {
			console.log("GET parte 2: ", res);
			this.datosAval = res.body
			this.setData( res.body )
		})
	}

	// convenience getter for easy access to form fields
	get f() { return this.dgFForm.controls; }
	get m() { return this.dgMForm.controls; }	

	async onSubmit(button: number) {
		//this.parte.emit(2);
		this.loading = true;
		var data: any;
		this.submitted = true;

		// if( (this.datosAval.aval_nombre || this.datosAval.aval_nombre_solicitante) && button === 2 ) return this.router.navigate(['/dashboard'])
		// stop here if form is invalid
		if (this.tipoAval == 1) {
			if (this.dgFForm.invalid || !this.f.aval_autoriza.value) {
				this.loading = false;
				return;
			}
			data = this.dgFForm.value;
		}else {
			if( !this.m.aval_autoriza_empresa.value )
			{ this.m.aval_autoriza_empresa.setErrors({required: true})}

			if( !this.m.aval_autorizacion_representante.value )
			{ this.m.aval_autorizacion_representante.setErrors({required: true}) }
			
			if( !this.m.aval_autoriza.value )
			{ this.m.aval_autoriza.setErrors({required: true}) }

			if (this.dgMForm.invalid) {
				this.loading = false;
				console.log(this.dgMForm.value);
				return;
			}
			
			data = this.dgMForm.value;
		}

		this.loading = true;
		console.log('before calling api');
		(await this.solicitudService.saveAval(data, 2, this.tipoPersona, button)).subscribe(
			async (res: any) => {
				console.log(res);
				// if (data.status <= 300) {
					this.loading = false;
					// this.alertService.success()
					if ( button === 1 ) { // go to dashboard
						// this.alertService.success('Se guardó correctamente.')
						this.userService.goToDashboard()
					} else { // next form
						// this.alertService.success('Se guardó correctamente.')
						const dialogref = this.dialog.open(MessageBoxComponent, {
							width: "500px",
							data: {
								text: "Éxito",
								text3: "tu información se ha guardado",
								icon: "success",
								type: "success"
							}
						})
						dialogref.afterClosed().subscribe( _=> {
							this.userService.goToDashboard()
						})
					}
				// }
				// this.router.navigateByUrl('/login', { replaceUrl: true } );
			},
			(err: any) => {
				this.loading = false;
				// this.alertService.error(err.error.message);
				console.log('Error 2 :', err)
				this.dialog.open(MessageBoxComponent, {
					width: "500px",
					data: {
						text: "Éxito",
						text3: "tu información no se ha guardado",
						icon: "error",
						type: "error"
					}
				})
				//let er = JSON.parse(err);
			}
		);				
	}

	setData( res ){
		if( this.tipoAval == 1 ){
			this.f.aval_funcion_public.setValue( res.aval_funcion_public )
			this.f.aval_funcion_public_desc.setValue( res.aval_funcion_public_desc )
			this.f.aval_familiar_funcion_public.setValue( res.aval_familiar_funcion_public )
			this.f.aval_familiar_funcion_public_desc.setValue( res.aval_familiar_funcion_public_desc )
			this.f.aval_nombre_solicitante.setValue( res.aval_nombre_solicitante )
			this.f.aval_autoriza.setValue( res.aval_autoriza )
		}else{
			this.m.aval_funcion_public.setValue( res.aval_funcion_public )
			this.m.aval_funcion_public_desc.setValue( res.aval_funcion_public_desc )
			this.m.aval_familiar_funcion_public.setValue( res.aval_familiar_funcion_public )
			this.m.aval_familiar_funcion_public_desc.setValue( res.aval_familiar_funcion_public_desc )
			this.m.aval_empresa.setValue( res.aval_empresa )
			this.m.aval_autoriza_empresa.setValue( res.aval_autoriza_empresa )
			this.m.aval_nombre_representante.setValue( res.aval_nombre_representante )
			this.m.aval_autorizacion_representante.setValue( res.aval_autorizacion_representante )
			this.m.aval_nombre.setValue( res.aval_nombre )
			this.m.aval_autoriza.setValue( res.aval_autoriza )
		}
	}
	
	changePart(part: number){
		console.log(part);
		this.parte.emit(part);
	}

	back(step) {
		localStorage.setItem('update', 'true')
		this.onBack.emit(step)
	}
}
