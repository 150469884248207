<div class="container">
    <div class="top">
        <div class="header">
            <img class="logo" mat-button height="60px" width="auto" [routerLink]="['/dashboard']" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/logo-header.svg" alt="">
            <div>
                <button mat-button routerLink="/changePassword">Cambiar Contraseña
                    <svg style="height: 20px; width: auto;" xmlns="http://www.w3.org/2000/svg" width="31.992" height="31.992" viewBox="0 0 31.992 31.992"><defs><style>.a{fill:#3ca2d6;}</style></defs><path class="a" d="M18,2a16,16,0,1,0,16,16A16.006,16.006,0,0,0,18,2Zm0,1.391A14.6,14.6,0,1,1,3.391,18,14.594,14.594,0,0,1,18,3.391Zm0,4.868a4.164,4.164,0,0,0-4.173,4.173v2.086h-.7A2.1,2.1,0,0,0,11.041,16.6V23.56a2.1,2.1,0,0,0,2.086,2.086h9.737a2.1,2.1,0,0,0,2.086-2.086V16.6a2.1,2.1,0,0,0-2.086-2.086h-.7V12.432A4.164,4.164,0,0,0,18,8.259ZM18,9.65a2.724,2.724,0,0,1,2.782,2.782v2.086H15.214V12.432A2.724,2.724,0,0,1,18,9.65Zm-4.868,6.259H14.4a.7.7,0,0,0,.227,0h6.728a.7.7,0,0,0,.227,0h1.281a.693.693,0,0,1,.7.7V23.56a.693.693,0,0,1-.7.7H13.128a.693.693,0,0,1-.7-.7V16.6A.693.693,0,0,1,13.128,15.909ZM18,18a1.39,1.39,0,0,0-.7,2.594v.883a.7.7,0,1,0,1.391,0v-.884A1.389,1.389,0,0,0,18,18Z" transform="translate(-2 -2)"/></svg>
                </button>
                <button mat-button (click)="logout()">Cerrar Sesión
                    <svg style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="32" height="31.992" viewBox="0 0 32 31.992"><defs><style>.a{fill:#3ca2d6;}</style></defs><path class="a" d="M18,2A16,16,0,1,0,30.9,27.449a.7.7,0,1,0-1.121-.823,14.6,14.6,0,1,1,0-17.259A.7.7,0,1,0,30.9,8.543,15.976,15.976,0,0,0,18,2Zm9.73,9.73a.7.7,0,0,0-.485,1.194L31.617,17.3H16.6a.7.7,0,1,0,0,1.391H31.617l-4.377,4.377a.7.7,0,1,0,.984.984l5.5-5.5a.7.7,0,0,0,0-1.107l-5.5-5.5A.7.7,0,0,0,27.726,11.73Z" transform="translate(-2 -2)"/></svg>
                </button>
            </div>
        </div>
        <div class="secciones">
            <div>
                <div class="title">{{ perfil == 3 ? 'Expediente' : 'Validación de Identidad' }}</div>
                <span>#{{folio}}</span>
                <span>Resumen de resultados</span>
            </div>
        </div>
    </div>
    <span *ngIf="!id_mobile" style="align-self: center; width: 85%; margin-bottom: 16px;">Autenticidad del documento</span>
    <div class="body documento" *ngIf="!id_mobile">
        <pdf-viewer *ngIf="url"
            [src]="url"
            [autoresize]="true"
            [original-size]="false"
        ></pdf-viewer>
    </div>
    <!-- <div class="body" *ngIf="validacion.ine && id_mobile">
        <span class="texto-resultados">Resultados de <br> autenticidad del documento</span>
        <br>
        <div class="resultados">
            <div class="images">
                <app-image [rotate]="false" [url]="url_rostro.url" [text]="'Foto de rostro'" [width]="140" [height]="168"></app-image>
                <app-image [rotate]="true" [url]="url_ine_front.url" [text]="'INE frontal'" [width]="290" [height]="168"></app-image>
                <app-image [rotate]="true" [url]="url_ine_back.url" [text]="'INE trasera'" [width]="290" [height]="168"></app-image>
            </div> -->
            <!-- <div>
                <img 
                    [ngClass]="
                    {'rotate_90': img_rostro == 1,
                        'rotate_180': img_rostro == 2,
                        'rotate_270': img_rostro == 3
                    }" [src]="url_rostro.url" alt="" width="140" height="168">
                <span><span (click)="img_rostro = img_rostro -1">a</span>Foto de rostro<span (click)="img_rostro = img_rostro +1">b</span></span>
                {{ img_rostro }}
            </div>
            <div style="margin-right: 7px;">
                <img [src]="url_ine_front.url" alt="" width="290" height="168">
                <span>INE frontal</span>
            </div>
            <div>
                <img [src]="url_ine_back.url" alt="" width="290" height="168">
                <span>INE trasera</span>
            </div> -->
            <!-- <div class="porcentajeAut">
                <p class="tituloPorcentaje">Porcentaje de <br>autenticidad</p>
                <p class="porcentajeCifra" [ngClass]="{'match': validacion.ine.match_face >= 0.90}" >{{ match_face > 0 ? ((match_face * 100) | number:'1.2-2') +'%' : 'NO MATCH'}}</p> 
                <p class="porcentajeFecha" *ngIf="validacion.ine.fecha_creacion"> <b>Validación generada el: <br> {{ validacion.ine.fecha_creacion | date:'dd/MMMM/yyyy HH:mm'}} hrs</b></p> 
                <p class="porcentajeFecha" *ngIf="!validacion.ine.fecha_creacion">Validación no exitosa</p>
            </div>
        </div>
        <div class="form">
            <div>
                <div class="input">
                    <label>Apellido Paterno</label>
                    <div>
                        <input matInput readonly placeholder="Apellido paterno" [(ngModel)]="validacion.ine.apellido_paterno"> -->
                        <!-- <input type="image" id="image" alt="Editar" src="/assets/images/editar.svg" class="button-editar"> -->
                    <!-- </div>
                </div>

                <div class="input">
                    <label>Apellido Materno</label>
                    <div>
                        <input matInput readonly placeholder="Apellido materno" [(ngModel)]="validacion.ine.apellido_materno"> -->
                        <!-- <input type="image" id="image" alt="Editar" src="/assets/images/editar.svg" class="button-editar"> -->
                    <!-- </div>
                </div>

                <div class="input">
                    <label>Nombre</label>
                    <div>
                        <input matInput readonly placeholder="Nombre" [(ngModel)]="validacion.ine.nombre"> -->
                        <!-- <input type="image" id="image" alt="Editar" src="/assets/images/editar.svg" class="button-editar"> -->
                    <!-- </div>
                </div>
                <br>
            </div>
            <div>
                <div class="input">
                    <label>Expiración</label>
                    <div>
                        <input matInput type="date" readonly [(ngModel)]="validacion.ine.expiracion"> -->
                        <!-- <input type="image" id="image" alt="Editar" src="/assets/images/editar.svg" class="button-editar"> -->
                    <!-- </div>
                </div>

                <div class="input">
                    <label>Clave de elector</label>
                    <div>
                        <input matInput readonly placeholder="Clave de elector" [(ngModel)]="validacion.ine.clave_elector"> -->
                        <!-- <input type="image" id="image" alt="Editar" src="/assets/images/editar.svg" class="button-editar"> -->
                    <!-- </div>
                </div>

                <div class="input">
                    <label>OCR</label>
                    <div>
                        <input matInput readonly placeholder="CIC" [(ngModel)]="validacion.ine.cic"> -->
                        <!-- <input type="image" id="image" alt="Editar" src="/assets/images/editar.svg" class="button-editar"> -->
                    <!-- </div>
                </div>
                <br>
            </div>
        </div>
    </div> -->
    <div class="validaciones">
        <div class="contenedor">
            <div>
                <span class="val-comercial">Validación Comercial</span>
                <br>
                <span style="text-align: center;">¿Coincide la foto del rostro con la de la INE?</span>
                <div class="botones">
                    <span class="btn-validacion" [ngClass]="{'rechazar': comercial_validacion === false}" (click)="ValidarComercial(false)"><mat-icon>clear</mat-icon></span>
                    <span class="btn-validacion" [ngClass]="{'aceptar': comercial_validacion}" (click)="ValidarComercial(true)"><mat-icon>done</mat-icon></span>
                </div>
                <mat-error *ngIf="submit && comercial_validacion == null">Debes seleccionar una opción</mat-error>
                <!-- <img src="/assets/images/aceptar.svg" alt="" width="150" height="50" style="
                    margin-top: 10px;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    width: 50%;
                "> Sustituir por iconos para cada botón, pendientes de separar por diseño -->
                <div class="input">
                    <label>Comentarios sobre la validación </label>
                    <textarea [(ngModel)]="comentarios_comercial" matInput maxlength="500" rows="5" style="width: 100%; resize: none;" placeholder="Comentarios"></textarea>
                    <mat-error *ngIf="submit && !comentarios_comercial">Campo obligatorio</mat-error>
                </div>
            </div>
            <div *ngIf="perfil == 4">
                <span class="val-comite">Validación Comité</span>
                <br>
                <span style="text-align: center;">¿Coincide la foto del rostro con la de la INE?</span>

                <div class="botones">
                    <span class="btn-validacion" [ngClass]="{'rechazar': comite_validacion === false}" (click)="ValidarComite(false)"><mat-icon>clear</mat-icon></span>
                    <span class="btn-validacion" [ngClass]="{'aceptar': comite_validacion}" (click)="ValidarComite(true)"><mat-icon>done</mat-icon></span>
                </div>
                <mat-error *ngIf="submit && comite_validacion == null">Debes seleccionar una opción</mat-error>
                <!-- <img src="/assets/images/aceptar.svg" alt="" width="150" height="50" style="
                    margin-top: 10px;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    width: 50%;
                "> Sustituir por iconos para cada botón, pendientes de separar por diseño -->

                <div class="input">
                    <label>Comentarios sobre la validación</label>
                    <textarea [(ngModel)]="comentarios_comite" matInput maxlength="500" rows="5" style="width: 100%; resize: none;" placeholder="Comentarios"></textarea>
                    <mat-error *ngIf="submit && !comentarios_comite">Campo obligatorio</mat-error>
                </div>
            </div>
        </div>
        <!-- <div class="contenedor" *ngIf="perfil == 4">
           
        </div> -->
    </div>
    <button class="next-button" mat-button (click)="NextPage()">
        Siguiente
        <svg style="height: 20px; width:auto;"  xmlns="http://www.w3.org/2000/svg" width="31.25" height="31.25" viewBox="0 0 31.25 31.25"><defs><style>.next{fill:#00a5da;}</style></defs><path class="next" d="M15.625,0A15.625,15.625,0,1,0,31.25,15.625,15.626,15.626,0,0,0,15.625,0Zm-1.3,24.74V12.173L8.755,17.741l-2.21-2.21,9.079-9.081,9.081,9.081-2.21,2.21-5.569-5.568V24.74Z" transform="translate(31.25) rotate(90)"/></svg>
    </button>
    <app-socials-footer style="width: 100%;"></app-socials-footer>
</div>