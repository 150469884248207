import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-lista-tramites',
  templateUrl: './lista-tramites.component.html',
  styleUrls: ['./lista-tramites.component.scss']
})
export class ListaTramitesComponent implements OnInit {

  @Input() tramites = [];
  @Output() CambiarTramite = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  Cambiar(folio, activa) {
	if (activa) {
		console.log('Cambiar tramite: ', folio);
		this.CambiarTramite.emit(folio);
	}

  }
}
