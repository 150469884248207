import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogDetaleCarteraAnexoComponent } from 'src/app/_dialogs/dialog-detale-cartera-anexo/dialog-detale-cartera-anexo.component';
import { CarteraService } from 'src/app/_services/cartera.service';

@Component({
  selector: 'app-cartera',
  templateUrl: './cartera.component.html',
  styleUrls: ['./cartera.component.scss']
})
export class CarteraComponent implements OnInit {
  anexos: any = []
  resumen: any = []

  constructor(
    private carteraService: CarteraService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.ObtenerCartera()
  }

  async ObtenerCartera() {
    const res = await this.carteraService.ObtenerCartera()
    if (res.statusCode == 200) {
      this.anexos = [...res.data.anexos]
      this._cargarDetalle([...res.data.anexos])
    }
  }

  MostrarDetalle(anexo) {
    console.log(anexo);
    
    this.dialog.open(DialogDetaleCarteraAnexoComponent, {
      width: "620px",
      minHeight: "450px",
      data: anexo
    })
  }

  _cargarDetalle(anexos) {
    let _resumen = {}
    for (const anexo of anexos) {
      if (!anexo.desglose || !anexo.desglose.length) {
        console.error(anexo);
        continue 
      }
      const fechaResidual = new Date(anexo.fechaResidual)
      fechaResidual.setDate(1)

      if (_resumen[fechaResidual.toUTCString()]) {
        _resumen[fechaResidual.toUTCString()].residual += anexo.valorResidual || 0
      } else {
        _resumen[fechaResidual.toUTCString()] = {
          capital: 0,
          interes: 0,
          insoluto: 0,
          pago: 0,
          monto: 0,
          residual: anexo.valorResidual,
        }
      }

      
      for (const renta of anexo.desglose) {
        console.log({renta});
        const periodo = new Date(renta.fecha)
        periodo.setDate(1)
       
        if (_resumen[periodo.toUTCString()]) {
           _resumen[periodo.toUTCString()].capital += renta.capital || 0
           _resumen[periodo.toUTCString()].interes += renta.interes || 0
           _resumen[periodo.toUTCString()].insoluto += renta.insoluto || 0
           _resumen[periodo.toUTCString()].pago += renta.pago || 0
           _resumen[periodo.toUTCString()].monto += renta.capital || 0
        } else {
          _resumen[periodo.toUTCString()] = {
            capital: renta.capital || 0,
            interes: renta.interes || 0,
            insoluto: renta.insoluto || 0,
            pago: renta.pago || 0,
            monto: renta.capital || 0,
            residual: 0
          }
        }
      }
    }


    for (const key in _resumen) {
      if (Object.prototype.hasOwnProperty.call(_resumen, key)) {
        this.resumen.push({fecha: key, ..._resumen[key]});
      }
    }
  }

}
