<div>
  <ng-template matStepLabel>General</ng-template>
  <div class="container-form">
    <div class="container-title"><h3>Resumen</h3></div>
    <div class="container-date">
      <mat-form-field appearance="outline">
        <mat-label>Fecha comité</mat-label>
        <input matInput type="date" [(ngModel)]="AnalisisPM.fecha_creacion"  />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Número comité</mat-label>
        <input matInput type="text" [(ngModel)]="AnalisisPM.numero_comite" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Cliente LFU</mat-label>
        <input matInput type="text" [(ngModel)]="AnalisisPM.solicitante" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Comportamiento de pago</mat-label>
        <mat-select
          [(ngModel)]="AnalisisPM.comportamiento_pago"
          (selectionChange)="puntosComportamiento()"
        >
          <mat-option value="Selecciona una opción"
            >Selecciona una opción</mat-option
          >
          <mat-option value="BUENO">BUENO</mat-option>
          <mat-option value="REGULAR">REGULAR</mat-option>
          <mat-option value="MALO">MALO</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Puntos</mat-label>
        <input matInput type="text" [(ngModel)]="AnalisisPM.puntos" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Razón Social</mat-label>
        <input matInput type="text" [(ngModel)]="AnalisisPM.razon_social" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Objeto Social</mat-label>
        <input matInput [(ngModel)]="AnalisisPM.objeto_social" type="text" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Representante Legal</mat-label>
        <input
          matInput
          [(ngModel)]="AnalisisPM.representante_legal"
          type="text"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Aval</mat-label>
        <input matInput type="text" [(ngModel)]="AnalisisPM.aval" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Activo Solicitado</mat-label>
        <input
          matInput
          type="text"
          [(ngModel)]="AnalisisPM.caracteristica_activo.activo"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Monto Activo</mat-label>
        <input
          prefix="$"
          mask="separator.2"
          thousandSeparator=","
          matInput
          [(ngModel)]="AnalisisPM.caracteristica_activo.monto_activo"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Estado</mat-label>
        <input
          matInput
          type="text"
          [(ngModel)]="AnalisisPM.caracteristica_activo.estado_activo"
        />
      </mat-form-field>

      <div>
        <h3>Estructura Accionaria</h3>
      </div>
      <div></div>
      <ng-container *ngFor="let accionista of AnalisisPM.accionistas; let i = index">
        <mat-form-field appearance="outline">
          <mat-label>Accionista {{ i + 1 }}</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="accionista.nombre_accionista"
          />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Participacion</mat-label>
          <input
            suffix="%"
            mask="separator.2"
            matInput
            [(ngModel)]="accionista.participacion"
            (ngModelChange)="sumarPromedioAccionistas()"
          />
        </mat-form-field>
      </ng-container>
      
      <div></div>
      <mat-form-field appearance="outline">
        <mat-label>Total</mat-label>
        <input
          suffix="%"
          mask="separator.2"
          matInput
          [(ngModel)]="total_participacion_accionistas"
          readonly
        />
      </mat-form-field>
      <div>
        <h3>Colateral</h3>
      </div>
      <div></div>
      <mat-form-field appearance="outline">
        <mat-label>Vendor</mat-label>
        <input
          matInput
          type="text"
          [(ngModel)]="AnalisisPM.cotizacion.vendor"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Comercial</mat-label>
        <input
          matInput
          type="text"
          [(ngModel)]="AnalisisPM.cotizacion.comercial"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Plazo</mat-label>
        <input matInput type="text" [(ngModel)]="AnalisisPM.cotizacion.plazo" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Factor</mat-label>
        <input
          matInput
          type="text"
          name="factor"
          [(ngModel)]="AnalisisPM.cotizacion.factor"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Tiempo de entrega</mat-label>
        <input
          matInput
          type="text"
          name="tiempo_entrega"
          [(ngModel)]="AnalisisPM.cotizacion.tiempo_entrega"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Garantia</mat-label>
        <input
          matInput
          type="text"
          name="garantia"
          [(ngModel)]="AnalisisPM.cotizacion.garantia"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Porcentaje Pago Inicial</mat-label>
        <input
          suffix="%"
          mask="separator.2"
          matInput
          name="porcentaje_pago_inicial"
          [(ngModel)]="AnalisisPM.cotizacion.porcentaje_pago_inicial"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Pago Inicial</mat-label>
        <input
          prefix="$"
          mask="separator.2"
          thousandSeparator=","
          matInput
          name="pago_inicial"
          [(ngModel)]="AnalisisPM.cotizacion.monto_pago_inicial"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Valor Residual</mat-label>
        <input
          matInput
          type="text"
          name="valor_residual"
          [(ngModel)]="AnalisisPM.cotizacion.valor_residual"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Mensualidad</mat-label>
        <input
          prefix="$" mask="separator.2" thousandSeparator=","
          matInput
          name="mensualidad"
          [(ngModel)]="AnalisisPM.cotizacion.mensualidad"
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Valor Pagare Final</mat-label>
        <input
          prefix="$"
          mask="separator.2"
          thousandSeparator=","
          matInput
          name="valor_pagare_final"
          [(ngModel)]="AnalisisPM.cotizacion.valor_pagare_final"
        />
      </mat-form-field>
      <div></div>
      <mat-form-field appearance="outline">
        <mat-label>Mar Total</mat-label>
        <input
          prefix="$"
          mask="separator.2"
          thousandSeparator=","
          matInput
          type="text"
          name="mar_total"
          [(ngModel)]="AnalisisPM.cotizacion.mar_total"
        />
      </mat-form-field>
      <div></div>

     
    </div>
    <div>
      <div>
        <div class="input">
          <textarea
            
            placeholder="Recomendación"
            [(ngModel)]="AnalisisPM.recomendacionResumen"
          ></textarea>
        </div>
      </div>
      <div style="height: 30px"></div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Dirección PM</mat-label>
          <input matInput type="text" [(ngModel)]="AnalisisPM.direccion_pm" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Dirección Activo</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="AnalisisPM.direccion_activo"
          />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Dirección RL</mat-label>
          <input matInput type="text" [(ngModel)]="AnalisisPM.direccion_rl" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Dirección aval</mat-label>
          <input matInput type="text" [(ngModel)]="AnalisisPM.direccion_aval" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Nombre de contacto</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="AnalisisPM.nombre_contacto"
          />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Teléfono</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="AnalisisPM.telefono_contacto"
          />
        </mat-form-field>
      </div>
    </div>
    <div style="margin-top: 80px; margin-bottom: 100px; display: flex; text-align: center; justify-content: center;">
      <tr>
        <td><b>PUNTUACIÓN FINAL</b></td>
        <td>
          <input style="width: 65px"  type="number" name="puntuacion_final" readonly [(ngModel)]="this.puntuacion_final_resumen" />
        </td>
      </tr>
    </div>
    <div class="container-btn">
      <button class="btn-primary-new" (click)="guardarYAvanzar()">
        <mat-spinner *ngIf="loading" diameter="20"></mat-spinner> <span *ngIf="!loading">Guardar</span>
      </button>
    </div>
  </div>
</div>
