<form [formGroup]="personaForm" class="frmDatosPersonalesM">
	<div class="datos_generales_1">
		<div>
			<mat-label>RFC</mat-label>
			<input  
				mask="SSS000000 AAA"
				formControlName="rfc" 
				[value]="datosGen.rfc"
                [ngClass]="{ 'is-invalid': submitted && !f.rfc.valid }"
                placeholder="Con Homoclave"
			>
			<mat-error *ngIf="submitted && !f.rfc.valid" class="invalid-feedback">
				<div *ngIf="!f.rfc.valid">Campo obligatorio</div>
			</mat-error>
        </div>
        
        <div class="input size-2">
			<mat-label>Nombre / Razón social</mat-label>
			<input 
			[specialCharacters]="['space']"
			[patterns]="customPattern"
			mask="E{100}"
				formControlName="razon_social" 
				[value]="datosGen.razon_social"
				[ngClass]="{ 'is-invalid': submitted && f.razon_social.errors }"
			>
			<mat-error *ngIf="submitted && f.razon_social.errors" class="invalid-feedback">
				<div *ngIf="f.razon_social.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>
	</div>

	<div class="datos_generales_2">
		<div>
			<mat-label>Correo electrónico</mat-label>
			<input  [readonly]="isClient"
			    placeholder="@"
				type="email"
				formControlName="correo" 
				[value]="datosGen.correo"
				[ngClass]="{ 'is-invalid': submitted && !f.correo.valid }"
			>
			<mat-error *ngIf="submitted && !f.correo.valid" class="invalid-feedback">
				<div *ngIf="!f.correo.valid">Campo obligatorio</div>
			</mat-error>
		</div>
	</div>
</form>

<div class="row-end">
	<div></div>
	<button mat-button (click)="onSubmit()">
		Siguiente
		<svg [ngClass]="{'btnNext': personaForm.valid}" style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><defs><style>.abc{fill:#707070;}</style></defs><path class="abc" d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0ZM22,38V18.7L13.448,27.25l-3.394-3.394L24,9.908,37.948,23.856,34.554,27.25,26,18.7V38Z" transform="translate(48) rotate(90)"/></svg>
	</button>
</div>