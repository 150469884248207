import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { customPatterns } from 'src/app/_models/maskPattern';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
@Component({
  selector: 'app-equipo',
  templateUrl: './equipo.component.html',
  styleUrls: ['./../crear-cotizacion.component.scss']
})
export class EquipoComponent implements OnInit {
  @Input() data: any = null;
  @Input() tramite: boolean;
  @Output() onNextPage = new EventEmitter;
  submit = false;
  calculated = false;
  customPattern = customPatterns;
  equipoForm: FormGroup;
  perfil = null
  separatorLimit = '100000000'
  precio_minimo = 50000
  descuento_ciego = false

  constructor(
    private formBuilder: FormBuilder,
    private dialog:MatDialog
  ) { }

  ngOnInit(): void {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'))
    this.perfil = currentUser.perfil_master == 11 ? 11 : currentUser.perfil;
    this.descuento_ciego = JSON.parse(localStorage.getItem('currentUser')).descuento_ciego
    this.equipoForm = this.formBuilder.group({
      activoArrendar: [this.data.activoArrendar || 'EQUIPO MÉDICO', [Validators.required]],
      descripcion: [this.data.descripcion, [Validators.required]],
      precio_equipo_sin_iva: [this.data.precio_equipo_sin_iva || '', [Validators.required, Validators.min(2000), Validators.max(100000000)]],
      precio_equipo_sin_iva_mxn: [''],
      tipo_cambio: [this.data.tipoCambio || 1, [Validators.required, Validators.min(1)]],
      iva: [0, [Validators.required]],
      tiempoEntrega: [ this.data.tiempoEntrega || 0, [Validators.required]],
      precioEquipoIva: [0, [Validators.required]],
      descuento_ciego: [0,[Validators.min(0), Validators.max(60.0)]],
    });
  }

  get f() {
    return this.equipoForm.controls;
  }

  NextPage() {
    if( !(Number(this.f.precio_equipo_sin_iva.value) > 0) ){
      this.f.precio_equipo_sin_iva.invalid
      return
    }
    if ( this.equipoForm.invalid ) return this.calculated = false;
    if((this.f.precio_equipo_sin_iva.value * this.f.tipo_cambio.value) < this.precio_minimo) { 
      this.f.precio_equipo_sin_iva.setErrors({invalid: true})
      return this.calculated = false; 
    }
    
    if( this.f.descuento_ciego.value > 0 ){
      this.dialog.open(MessageBoxComponent, {
        width: "500px",
        data: {
          text: `Esta cotización incluye <br>un descuento ciego de ${parseFloat(this.f.descuento_ciego.value).toFixed(1)}%, <br>lo cual disminuirá <br>el precio que Lease for U <br>pagará por el equipo.`,
          type: "confirm-cot",
          reject: ()=>{},
          confirm: ()=>this.EmitEquipo()
        }
      })
    }else{
      this.EmitEquipo()
    }
  }

  EmitEquipo() {
    this.onNextPage.emit({
      equipo: {
        activoArrendar: this.f.activoArrendar.value,
        descripcion: this.f.descripcion.value,
        precioEquipoIva: this.f.precioEquipoIva.value,
        tiempoEntrega: this.f.tiempoEntrega.value,
        tipoCambio: Number(this.f.tipo_cambio.value),
        precio_equipo_sin_iva: this.f.precio_equipo_sin_iva.value,
        descuentoCiego: parseFloat(this.f.descuento_ciego.value),
      }
    });
  }

  Calcular() {
    this.submit = true;
    if ( this.equipoForm.invalid ) { return; }
    if( (this.f.precio_equipo_sin_iva.value * this.f.tipo_cambio.value) >= this.precio_minimo ){
      this.calculated = true;
      this.f.precio_equipo_sin_iva_mxn.setValue( Math.round(this.f.precio_equipo_sin_iva.value * this.f.tipo_cambio.value));
      this.f.iva.setValue( Math.round( (this.f.precio_equipo_sin_iva.value * Number(this.f.tipo_cambio.value)) * 0.16 ) );
      this.f.precioEquipoIva.setValue( Math.round((this.f.precio_equipo_sin_iva.value * 1.16) * this.f.tipo_cambio.value) );
      return
    }
    this.calculated = false
    this.f.precio_equipo_sin_iva.setErrors({invalid: true})
  }
}
