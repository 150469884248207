import { ChangeDetectorRef,Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, AuthenticationService, UserService } from '../../../../_services';
import { SolicitudService } from 'src/app/_services/solicitud.service';

import {referenciasM} from '../../modalSolicitudR';

import { customPatterns } from '../../../../_models/maskPattern'
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
/* tslint:disable */

@Component({
	selector: 'app-solicitud-r-p3',
	templateUrl: './solicitudRp3.component.html',
	styleUrls: ['./../../solicitudR.component.scss']
})

export class SolicitudRp3Component implements OnInit {

	@Input() tipoPersona: number;
	@Output() parte = new EventEmitter<number>();
	@Output() onBack = new EventEmitter<number>();
	// @Input() referneciasCliente :any;
	
	

	

	customPattern = customPatterns
	//dgFForm: FormGroup;
	dgMForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
	datosRef:any = {}
	perfil = null
	solicitudMoral={};
    constructor(
        private formBuilder: FormBuilder,
        // private route: ActivatedRoute,
        private router: Router,
        // private authenticationService: AuthenticationService,
        private alertService: AlertService,
		private solicitudService: SolicitudService,
		private userService:UserService,
		private dialog:MatDialog
    ) {
        // redirect to home if already logged in

	}
    ngOnInit() {
		const currentUser = JSON.parse(localStorage.getItem('currentUser'))
		this.perfil = currentUser.perfil

		this.dgMForm = this.formBuilder.group({
			cliente_nombre1: ['', [Validators.required]],
			cliente_nombre2: ['', []],
			cliente_nombre3: ['', []],
			cliente_contacto1: ['', Validators.required],
			cliente_contacto2: ['', []],
			cliente_contacto3: ['', []],
			cliente_puesto1: ['', Validators.required],
			cliente_puesto2: ['', []],
			cliente_puesto3: ['', []],
			cliente_promedio_compras1: ['', Validators.required],
			cliente_promedio_compras2: ['', []],
			cliente_promedio_compras3: ['', []],
			cliente_total1: ['', Validators.required],
			cliente_total2: ['', []],
			cliente_total3: ['', []]
		});

		const solicitud = this.solicitudService.getReferencia()
		const tipo_persona = this.tipoPersona
		const parte = 3

		const guardado = { ...solicitud, tipo_persona, parte}
		console.log("Guardado: ", guardado);
		
		this.getRefencias(guardado);
        // get return url from route parameters or default to '/'
        // this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/dashboard';
    }

    // convenience getter for easy access to form fields
	//get f() { return this.dgFForm.controls; }
	get m() { return this.dgMForm.controls; }	

    async onSubmit(button: number) {
		this.dgMForm.markAllAsTouched();
		//this.parte.emit(2);
		this.loading = true;
		var data: any;
		this.submitted = true;
		const update = localStorage.getItem('update') == 'true' || false

		// if( this.datosRef.cliente_nombre1 && button === 2 && !update ) return this.router.navigate(['/dashboard'])
		// stop here if form is invalid
		
			if (this.dgMForm.invalid) {
				this.loading = false;
				console.log(this.dgMForm.value);
				return;
			}
			data = this.dgMForm.value;
		

		this.loading = true;
		console.log('before calling api', data);
		(await this.solicitudService.saveReferencias(data, 3, this.tipoPersona, button)).subscribe(
			async (res: any) => {
				console.log(res);
				// if (data.status <= 300) {
					this.loading = false;
					// this.alertService.success()
					// this.alertService.success('Se guardó correctamente.')
					const dialogref = this.dialog.open(MessageBoxComponent, {
						width: "500px",
						data: {
							text: "Éxito",
							text3: "tu información se ha guardado",
							icon: "success",
							type: "success"
						}
					})
					dialogref.afterClosed().subscribe( _=> {
						this.userService.goToDashboard()
					})
				// }
				// this.router.navigateByUrl('/login', { replaceUrl: true } );
			},
			(err: any) => {
				this.loading = false;
				// this.alertService.error(err.error.message);
				console.log('Error 2 :', err)
				this.dialog.open(MessageBoxComponent, {
					width: "500px",
					data: {
						text: "Éxito",
						text3: "tu información no se ha guardado",
						icon: "error",
						type: "error"
					}
				})
				//let er = JSON.parse(err);
			}
		);		
		const referencias = {
			
			cliente_nombre1:this.m.cliente_nombre1.value,
			cliente_nombre2:this.m.cliente_nombre2.value,
			cliente_nombre3:this.m.cliente_nombre3.value,
			cliente_contacto1:this.m.cliente_contacto1.value,
			cliente_contacto2:this.m.cliente_contacto2.value,
			cliente_contacto3:this.m.cliente_contacto3.value,
			cliente_puesto1:this.m.cliente_puesto1.value,
			cliente_puesto2:this.m.cliente_puesto2.value,
			cliente_puesto3:this.m.cliente_puesto3.value,
			cliente_promedio_compras1:this.m.cliente_promedio_compras1.value,
			cliente_promedio_compras2:this.m.cliente_promedio_compras2.value,
			cliente_promedio_compras3:this.m.cliente_promedio_compras3.value,
			cliente_total1:this.m.cliente_total1.value,
			cliente_total2:this.m.cliente_total2.value,
			cliente_total3:this.m.cliente_total3.value,
			
		}	
		this.SendValues(referencias);
		
		
		
		//await this.solicitudService.GuardarSolicitud(JSON.parse(localStorage.getItem('client_details')).folio, {referencias});	
	}
	setDataDynamo( res ){
		this.m.cliente_nombre1.setValue( res.referencias.referneciasCliente.cliente_nombre1 )
		this.m.cliente_nombre2.setValue( res.referencias.referneciasCliente.cliente_nombre2 )
		this.m.cliente_nombre3.setValue( res.referencias.referneciasCliente.cliente_nombre3 )
		this.m.cliente_contacto1.setValue( res.referencias.referneciasCliente.cliente_contacto1 )
		this.m.cliente_contacto2.setValue( res.referencias.referneciasCliente.cliente_contacto2 )
		this.m.cliente_contacto3.setValue( res.referencias.referneciasCliente.cliente_contacto3 )
		this.m.cliente_puesto1.setValue( res.referencias.referneciasCliente.cliente_puesto1 )
		this.m.cliente_puesto2.setValue( res.referencias.referneciasCliente.cliente_puesto2 )
		this.m.cliente_puesto3.setValue( res.referencias.referneciasCliente.cliente_puesto3 )
		this.m.cliente_promedio_compras1.setValue( res.referencias.referneciasCliente.cliente_promedio_compras1 )
		this.m.cliente_promedio_compras2.setValue( res.referencias.referneciasCliente.cliente_promedio_compras2 )
		this.m.cliente_promedio_compras3.setValue( res.referencias.referneciasCliente.cliente_promedio_compras3 )
		this.m.cliente_total1.setValue( res.referencias.referneciasCliente.cliente_total1 )
		this.m.cliente_total2.setValue( res.referencias.referneciasCliente.cliente_total2 )
		this.m.cliente_total3.setValue( res.referencias.referneciasCliente.cliente_total3 )
	}
	setData( res ){
		this.m.cliente_nombre1.setValue( res.cliente_nombre1 )
		this.m.cliente_nombre2.setValue( res.cliente_nombre2 )
		this.m.cliente_nombre3.setValue( res.cliente_nombre3 )
		this.m.cliente_contacto1.setValue( res.cliente_contacto1 )
		this.m.cliente_contacto2.setValue( res.cliente_contacto2 )
		this.m.cliente_contacto3.setValue( res.cliente_contacto3 )
		this.m.cliente_puesto1.setValue( res.cliente_puesto1 )
		this.m.cliente_puesto2.setValue( res.cliente_puesto2 )
		this.m.cliente_puesto3.setValue( res.cliente_puesto3 )
		this.m.cliente_promedio_compras1.setValue( res.cliente_promedio_compras1 )
		this.m.cliente_promedio_compras2.setValue( res.cliente_promedio_compras2 )
		this.m.cliente_promedio_compras3.setValue( res.cliente_promedio_compras3 )
		this.m.cliente_total1.setValue( res.cliente_total1 )
		this.m.cliente_total2.setValue( res.cliente_total2 )
		this.m.cliente_total3.setValue( res.cliente_total3 )
	}

	changePart(part: number){
		this.parte.emit(part);
	}

	back(step) {
		localStorage.setItem('update', 'true')
		this.onBack.emit(step)
	}
	async getRefencias(guardado){
		const ref = await this.solicitudService.ObtenerSolicitud(JSON.parse(localStorage.getItem("client_details")).folio);
		if(ref.referencias?.referneciasCliente?.cliente_nombre1?.trim().length>0){
			this.setDataDynamo(ref);
		}else{
			this.solicitudService.geteReferencias( {guardado} ).subscribe( res => {
				console.log("GET: ", res);
				this.datosRef = res.body
				this.setData( res.body )
			})
		}
	}
	SendValues(valores) {
		referenciasM.referencias.referenciasCliente={...valores};
		console.log("Valor :", referenciasM);
	}
}