const cur_date = new Date().toISOString().split("T")[0];

export const COMPORTAMIENTO_PAGO = [
  { id: 1, nombre: "Bueno", puntuacion: 10 },
  { id: 2, nombre: "Regular", puntuacion: 5 },
  { id: 3, nombre: "Malo", puntuacion: 0 },
];

export const COMPORTAMIENTO_PAGO_CREDITO = [
  { id: 1, nombre: "Bueno", puntuacion: 4 },
  { id: 2, nombre: "Regular", puntuacion: 2 },
  { id: 3, nombre: "Malo", puntuacion: 0 },
];

export const TIPO_INSTALACIONES = [
  { id: 1, nombre: "PROPIA", puntuacion: 2 },
  { id: 2, nombre: "RENTADA", puntuacion: 0 },
];
export const RESULTADO_UTILIDAD = [
  { id: 1, nombre: "POSITIVO", puntuacion: 2 },
  { id: 2, nombre: "NEGATIVO", puntuacion: 0 },
];

export const MESES = [
  { value: 0, id: 0,  mes: "Parcial 2024" },
  { value: 1, id: 1,  mes: "Enero 2024" },
  { value: 2, id: 2,  mes: "Febrero 2024" },
  { value: 3, id: 3,  mes: "Marzo 2024" },
  { value: 4, id: 4,  mes: "Abril 2024" },
  { value: 5, id: 5,  mes: "Mayo 2024" },
  { value: 6, id: 6,  mes: "Junio 2024" },
  { value: 7, id: 7,  mes: "Julio 2024" },
  { value: 8, id: 8,  mes: "Agosto 2024" },
  { value: 9, id: 9,  mes: "Septiembre 2024" },
  { value: 10, id: 10,  mes: "Octubre 2024" },
  { value: 11, id: 11,  mes: "Noviembre 2024" },
  { value: 12, id: 12,  mes: "Diciembre 2024" },
  { value: 11, id: 13,  mes: "Noviembre 2023" },
  { value: 12, id: 14,  mes: "Diciembre 2023" },
];

export const AnalisisPM = {
  tipo_comite: "",
  fecha_creacion: cur_date,
  inicio_operaciones: "",
  cliente_l4u: false,
  id_comportamiento_pago: 3,
  id_resolucion_comite: 0,
  resolucion_comite: "",
  folio: "",
  email: "",
  tipo_persona: 2,
  solicitante: "",
  razon_social: "",
  rfc: "",
  id_cliente: <number>0,
  puntos: <number>0,
  comportamiento_pago: "",
  objeto_social: "",
  total_puntos: <number>0,
  comentarios_generales: "",
  representante_legal: "",
  aval: "",
  numero_comite: <number>0,
  fecha_comite: cur_date,
  numero_cotizacion: "",
  fecha_ingreso: "",
  recomendacionResumen: "",
  puntuacion_total_activo: 0,
  comentarios_activo: "",
  puntuacion_final_activo: 0,
  comentarios_resolucion_comite: "",
  direccion_pm: "",
  direccion_activo: "",
  direccion_rl: "",
  direccion_aval: "",
  nombre_contacto: "",
  telefono_contacto: "",
  accionistas: [
    {
      nombre_accionista: "",
      participacion: "",
    },
    {
      nombre_accionista: "",
      participacion: "",
    },
    {
      nombre_accionista: "",
      participacion: "",
    },
    {
      nombre_accionista: "",
      participacion: "",
    },
    {
      nombre_accionista: "",
      participacion: "",
    },
  ],
  cotizacion: {
    vendor: "",
    comercial: "",
    plazo: "",
    factor: "",
    tiempo_entrega: "",
    garantia: "",
    porcentaje_pago_inicial: "",
    monto_pago_inicial: "",
    valor_residual: "",
    mensualidad: "",
    valor_pagare_final: "",
    mar_total: "",
    comision: 0,
    iva: 1.16,
    iva2: 1.17,
    seguro: 0,
    opcion_compra_iva: 0,
  },
  caracteristica_activo: {
    activo: "",
    monto_activo: "",
    estado_activo: "",
    vida_util: "",
    mercado_secundario: "",
    estimado_colocacion: "",
    ubicacion: "",
    tiempo_financiamiento: "",
    puntos_vida_util: <number>0,
    puntos_mercado_secundario: <number>0,
    puntos_estimado_colocacion: <number>0,
    puntos_ubicacion: <number>0,
    puntos_tiempo_financiamiento: <number>0,
    total_puntos: <number>0,
    comentarios: "",
  },
  perfil_empresa: {
    tiempo_operaciones: <number>0,
    tiempo_operaciones_puntos: <number>0,
    tiempo_operaciones_descrip: ">=2 AÑOS",
    tipo_instalaciones: "",
    tipo_instalaciones_puntos: <number>0,
    tipo_instalaciones_descrip: "",
    arraigo: "",
    arraigo_puntos: <number>0,
    arraigo_descrip: ">=2 AÑOS",
    resultado_utilidad: "",
    resultado_utilidad_puntos: <number>0,
    resultado_utilidad_descrip: "",
    bc_representante_legal: <number>0,
    bc_representante_legal_puntos: <number>0,
    bc_representante_legal_descrip: ">=630",
    bc_aval: <number>0,
    bc_aval_puntos: <number>0,
    bc_aval_descrip: ">=630",
    total_puntos_empresa: <number>0,
  },
  buro_empresa: {
    calificacion: "",
    calificacion_puntos: <number>0,
    calificacion_descrip: "<=B2",
    meses_mora: <number>0,
    meses_mora_descrip: "<90 DÍAS",
    meses_mora_puntos: <number>0,
    porcentaje_cuentas_buenas: <number>0,
    porcentaje_cuentas_buenas_descrip: ">80%",
    porcentaje_cuentas_buenas_puntos: <number>0,
    peor_mop_12_meses: <number>0,
    peor_mop_12_meses_descrip: "<MOP 3",
    peor_mop_12_meses_puntos: <number>0,
    entrevista: "",
    entrevista_descrip: "",
    entrevista_puntos: <number>0,
    experiencia_arrendamiento: "",
    total_puntos_buro_empresa: <number>0,
  },
  caracter: {
    comentarios: "",
    total_puntos_creditos_abiertos: <number>0,
    total_puntos_creditos_cerrados: <number>0,
    total_puntos: "",
    resumen_creditos_abiertos: [
      {
        otorgante: "",
        credito_maximo: <number>0,
        saldo_insoluto: <number>0,
        mop_maximo: <number>0,
        comportamiento: "",
        puntos: <number>0,
      },
    ],
    resumen_creditos_cerrados: [
      {
        otorgante: "",
        credito_maximo: <number>0,
        saldo_insoluto: <number>0,
        mop_maximo: <number>0,
        comportamiento: "",
        puntos: <number>0,
      },
    ],
  },
  capacidad_pago: {
    ingresos:{
      comprobables: {
        ingresos: <number>0,
        gastos: <number>0,
        ratio: <number>0,
      },
      efectivo: {
        ingresos: <number>0,
        gastos: <number>0,
        ratio: <number>0,
      },
      extra_nuevo_equipo: {
        ingresos: <number>0,
        gastos: <number>0,
        ratio: <number>0,
      }
    },
    renta_adicional_disponible: "",
    ingresos_comprobables_prom: "",
    ingresos_efectivo: "",
    ingresos_extas: "",
    mensualidad: "",
    factor_renta: "",
    ratio: "",
    dictamen: "",
    total_puntos: <number>0,
    total_puntos_periodos: <number>0,
    total_puntos_razones_financieras: <number>0,
    comentarios_adicionales: "",
    puntos_liquidez: <number>0,
    puntos_capital_trabajo: <number>0,
    puntos_endeudamiento: <number>0,
    puntos_apalancamiento_ext: <number>0,
    puntos_quiebra_tecnica: <number>0,
    puntos_rendimiento_roa: <number>0,
    puntos_capital_roe: <number>0,
    puntos_margen_operativo: <number>0,
    puntos_margen_neta: <number>0,
    total_puntos_parametros: <number>0,
    total_puntos_gastos: <number>0,
    depositos_promedio: <number>0,
    retiros_promedio: <number>0,
    saldo_final_promedio: <number>0,
    periodos: [
      {
        mes: "",
        depositos: "",
        retiros: "",
        saldo_final: "",
      },
      {
        mes: "",
        depositos: "",
        retiros: "",
        saldo_final: "",
      },
      {
        mes: "",
        depositos: "",
        retiros: "",
        saldo_final: "",
      },
    ],
    margenes: {
      bruto: {
        _2022: 0,
        _2023: 0,
        _2024: 0,
        variacion_22_23: <number>0,
        variacion_23_24: <number>0
      },
      operativo: {
        _2022: 0,
        _2023: 0,
        _2024: 0,
        variacion_22_23: <number>0,
        variacion_23_24: <number>0
      },
      neto: {
        _2022: 0,
        _2023: 0,
        _2024: 0,
        variacion_22_23: <number>0,
        variacion_23_24: <number>0
      },
    },
    liquidez: {
      capital_trabajo: {
        _2022: 0,
        _2023: 0,
        _2024: 0,
        variacion_22_23: <number>0,
        variacion_23_24: <number>0,
        promedio: <number>0,
      },
      corto_plazo: {
        _2022: 0,
        _2023: 0,
        _2024: 0,
        variacion_22_23: <number>0,
        variacion_23_24: <number>0,
        promedio: <number>0,
      },
      acida: {
        _2022: 0,
        _2023: 0,
        _2024: 0,
        variacion_22_23: <number>0,
        variacion_23_24: <number>0,
        promedio: <number>0,
      },
    },
    rentabilidad: {
      ventas: {
        _2022: 0,
        _2023: 0,
        _2024: 0,
        variacion_22_23: <number>0,
        variacion_23_24: <number>0,
        promedio: <number>0,
      },
      activos_roa: {
        _2022: 0,
        _2023: 0,
        _2024: 0,
        variacion_22_23: <number>0,
        variacion_23_24: <number>0,
        promedio: <number>0,
      },
      capital_roe: {
        _2022: 0,
        _2023: 0,
        _2024: 0,
        variacion_22_23: <number>0,
        variacion_23_24: <number>0,
        promedio: <number>0,
      },
    },
    deuda: {
      endeudamiento: {
        _2022: 0,
        _2023: 0,
        _2024: 0,
        variacion_22_23: <number>0,
        variacion_23_24: <number>0,
        promedio: <number>0,
        
      },
      apalancamiento_ext: {
        _2022: 0,
        _2023: 0,
        _2024: 0,
        variacion_22_23: <number>0,
        variacion_23_24: <number>0,
        promedio: <number>0,
      },
      cobertura_intereses: {
        _2022: 0,
        _2023: 0,
        _2024: 0,
      },
      cobertura_efectivo: {
        _2022: 0,
        _2023: 0,
        _2024: 0,
      },
    },
    rendimiento: {
      quiebra_tecnica: {
        _2022: 0,
        _2023: 0,
        _2024: 0,
        variacion_22_23: <number>0,
        variacion_23_24: <number>0,
        promedio: <number>0,
      }
    },
    rotacion: {
      rotacion_cxc: {
        _2022: 0,
        _2023: 0,
        _2024: 0,
      },
      rotacion_inventarios: {
        _2022: 0,
        _2023: 0,
        _2024: 0,
      },
      rotacion_proveedores: {
        _2022: 0,
        _2023: 0,
        _2024: 0,
      },
      ciclo_financiero: {
        _2022: 0,
        _2023: 0,
        _2024: 0,
      },
    },
  },
  anexo: {
    gastos_financieros: 0,
    mesSelected:{
      id: <number>0,
      value: <number>0,
      nombre: 'PARCIAL 2024'
    },
    activo_circulante: {
      caja: {
        _2022: <number>0,
        porcentaje_2022: <number>0,
        _2023: <number>0,
        porcentaje_2023: <number>0,
        _2024: <number>0,
        porcentaje_2024: <number>0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      bancos: {
        _2022: <number>0,
        porcentaje_2022: <number>0,
        _2023: <number>0,
        porcentaje_2023: <number>0,
        _2024: <number>0,
        porcentaje_2024: <number>0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      clientes: {
        _2022: <number>0,
        porcentaje_2022: <number>0,
        _2023: <number>0,
        porcentaje_2023: <number>0,
        _2024: <number>0,
        porcentaje_2024: <number>0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      inventarios: {
        _2022: <number>0,
        porcentaje_2022: <number>0,
        _2023: <number>0,
        porcentaje_2023: <number>0,
        _2024: <number>0,
        porcentaje_2024: <number>0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      cuentas_por_cobrar: {
        _2022: <number>0,
        porcentaje_2022: <number>0,
        _2023: <number>0,
        porcentaje_2023: <number>0,
        _2024: <number>0,
        porcentaje_2024: <number>0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      impuestos_a_favor: {
        _2022: <number>0,
        porcentaje_2022: <number>0,
        _2023: <number>0,
        porcentaje_2023: <number>0,
        _2024: <number>0,
        porcentaje_2024: <number>0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      iva_pendiente: {
        _2022: <number>0,
        porcentaje_2022: <number>0,
        _2023: <number>0,
        porcentaje_2023: <number>0,
        _2024: <number>0,
        porcentaje_2024: <number>0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      deudores_diversos: {
        _2022: <number>0,
        porcentaje_2022: <number>0,
        _2023: <number>0,
        porcentaje_2023: <number>0,
        _2024: <number>0,
        porcentaje_2024: <number>0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      pagos_anticipados: {
        _2022: <number>0,
        porcentaje_2022: <number>0,
        _2023: <number>0,
        porcentaje_2023: <number>0,
        _2024: <number>0,
        porcentaje_2024: <number>0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      inversiones: {
        _2022: <number>0,
        porcentaje_2022: <number>0,
        _2023: <number>0,
        porcentaje_2023: <number>0,
        _2024: <number>0,
        porcentaje_2024: <number>0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      otras_cuentas: {
        _2022: <number>0,
        porcentaje_2022: <number>0,
        _2023: <number>0,
        porcentaje_2023: <number>0,
        _2024: <number>0,
        porcentaje_2024: <number>0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      total_activo_circulante: {
        _2022: <number>0,
        porcentaje_2022: <number>0,
        _2023: <number>0,
        porcentaje_2023: <number>0,
        _2024: <number>0,
        porcentaje_2024: <number>0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
    },

    activo_fijo: {
      terrenos_edificios: {
        _2022: <number>0,
        porcentaje_2022: <number>0,
        _2023: <number>0,
        _porcentaje_2023: <number>0,
        _2024: <number>0,
        porcentaje_2024: <number>0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      depreciacion_terrenos: {
        _2022: <number>0,
        porcentaje_2022: <number>0,
        _2023: <number>0,
        porcentaje_2023: <number>0,
        _2024: <number>0,
        porcentaje_2024: <number>0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      maquinaria_equipo: {
        _2022: <number>0,
        porcentaje_2022: <number>0,
        _2023: <number>0,
        porcentaje_2023: <number>0,
        _2024: <number>0,
        porcentaje_2024: <number>0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      depreciacion_maquinaria_equipo: {
        _2022: <number>0,
        porcentaje_2022: <number>0,
        _2023: <number>0,
        porcentaje_2023: <number>0,
        _2024: <number>0,
        porcentaje_2024: <number>0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      mobiliario_oficina_equipo_comp: {
        _2022: <number>0,
        porcentaje_2022: <number>0,
        _2023: <number>0,
        porcentaje_2023: <number>0,
        _2024: <number>0,
        porcentaje_2024: <number>0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      depreciacion_mob_oficina_equipo: {
        _2022: <number>0,
        porcentaje_2022: <number>0,
        _2023: <number>0,
        porcentaje_2023: <number>0,
        _2024: <number>0,
        porcentaje_2024: <number>0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      equipo_transporte: {
        _2022: <number>0,
        porcentaje_2022: <number>0,
        _2023: <number>0,
        porcentaje_2023: <number>0,
        _2024: <number>0,
        porcentaje_2024: <number>0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      depreciacion_equipo_transp: {
        _2022: <number>0,
        porcentaje_2022: <number>0,
        _2023: <number>0,
        porcentaje_2023: <number>0,
        _2024: <number>0,
        porcentaje_2024: <number>0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      otras_cuentas: {
        _2022: <number>0,
        porcentaje_2022: <number>0,
        _2023: <number>0,
        porcentaje_2023: <number>0,
        _2024: <number>0,
        porcentaje_2024: <number>0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      activo_fijo_total: {
        _2022: <number>0,
        porcentaje_2022: <number>0,
        _2023: <number>0,
        porcentaje_2023: <number>0,
        _2024: <number>0,
        porcentaje_2024: <number>0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
    },
    total_activos_circulante_fijo_22: <number>0,
    total_activos_circulante_fijo_23: <number>0,
    total_activos_circulante_fijo_24: <number>0,
    total_activos_circulante_fijo_absoluto_22_23: <number>0,
    total_activos_circulante_fijo_absoluto_23_24: <number>0,
    total_activos_circulante_fijo_porcentual_22_23: <number>0,
    total_activos_circulante_fijo_porcentual_23_24: <number>0,
    otros_activos_22: <number>0,
    otros_activos_23: <number>0,
    otros_activos_24: <number>0,
    otras_cuentas_fijo_var_absoluta_22_23: <number>0,
    otras_cuentas_fijo_var_absoluta_23_24: <number>0,
    otras_cuentas_fijo_var_porcentual_22_23: <number>0,
    otras_cuentas_fijo_var_porcentual_23_24: <number>0,

    pasivo: {
      proveedores: {
        _2022: 0,
        porcentaje_2022: 0,
        _2023: 0,
        porcentaje_2023: 0,
        _2024: 0,
        porcentaje_2024: 0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      acreedores: {
        _2022: 0,
        porcentaje_2022: 0,
        _2023: 0,
        porcentaje_2023: 0,
        _2024: 0,
        porcentaje_2024: 0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      impuestos_pagar: {
        _2022: 0,
        porcentaje_2022: 0,
        _2023: 0,
        porcentaje_2023: 0,
        _2024: 0,
        porcentaje_2024: 0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      documentos_pagar: {
        _2022: 0,
        porcentaje_2022: 0,
        _2023: 0,
        porcentaje_2023: 0,
        _2024: 0,
        porcentaje_2024: 0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      iva_trasladar: {
        _2022: 0,
        porcentaje_2022: 0,
        _2023: 0,
        porcentaje_2023: 0,
        _2024: 0,
        porcentaje_2024: 0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      provisiones: {
        _2022: 0,
        porcentaje_2022: 0,
        _2023: 0,
        porcentaje_2023: 0,
        _2024: 0,
        porcentaje_2024: 0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      intereses: {
        _2022: 0,
        porcentaje_2022: 0,
        _2023: 0,
        porcentaje_2023: 0,
        _2024: 0,
        porcentaje_2024: 0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      otras_cuentas: {
        _2022: 0,
        porcentaje_2022: 0,
        _2023: 0,
        porcentaje_2023: 0,
        _2024: 0,
        porcentaje_2024: 0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      total_pasivo_circulante: {
        _2022: 0,
        porcentaje_2022: 0,
        _2023: 0,
        porcentaje_2023: 0,
        _2024: 0,
        porcentaje_2024: 0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      otros_pasivos_lg: {
        _2022: 0,
        _2023: 0,
        _2024: 0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      total_pasivos_cir_lg: {
        _2022: 0,
        _2023: 0,
        _2024: 0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
    },

    capital: {
      capital_social: {
        _2022: 0,
        porcentaje_2022: 0,
        _2023: 0,
        porcentaje_2023: 0,
        _2024: 0,
        porcentaje_2024: 0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      apor_futuros_aum_cap: {
        _2022: 0,
        porcentaje_2022: 0,
        _2023: 0,
        porcentaje_2023: 0,
        _2024: 0,
        porcentaje_2024: 0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      reserva_legal: {
        _2022: 0,
        porcentaje_2022: 0,
        _2023: 0,
        porcentaje_2023: 0,
        _2024: 0,
        porcentaje_2024: 0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      resultados_acumulados: {
        _2022: 0,
        porcentaje_2022: 0,
        _2023: 0,
        porcentaje_2023: 0,
        _2024: 0,
        porcentaje_2024: 0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      resultado_del_ejercicio: {
        _2022: 0,
        porcentaje_2022: 0,
        _2023: 0,
        porcentaje_2023: 0,
        _2024: 0,
        porcentaje_2024: 0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      otras_cuentas: {
        _2022: 0,
        porcentaje_2022: 0,
        _2023: 0,
        porcentaje_2023: 0,
        _2024: 0,
        porcentaje_2024: 0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      total_capital: {
        _2022: 0,
        porcentaje_2022: 0,
        _2023: 0,
        porcentaje_2023: 0,
        _2024: 0,
        porcentaje_2024: 0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
    },
    total_pasivo_capital: {
      _2022: 0,
      porcentaje_2022: 0,
      _2023: 0,
      porcentaje_2023: 0,
      _2024: 0,
      porcentaje_2024: 0,
      var_absoluta_22_23: <number>0,
      var_absoluta_23_24: <number>0,
      var_porcentual_22_23: <number>0,
      var_porcentual_23_24: <number>0,
    },
    estado_resultados: {
      mes: 0,

      ventas: {
        _2022: 0,
        _2023: 0,
        _2024: 0,
      },
      costo_ventas: {
        _2022: 0,
        porcentaje_2022: 0,
        _2023: 0,
        porcentaje_2023: 0,
        _2024: 0,
        porcentaje_2024: 0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      gastos_operacion: {
        _2022: 0,
        porcentaje_2022: 0,
        _2023: 0,
        porcentaje_2023: 0,
        _2024: 0,
        porcentaje_2024: 0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      gastos_financieros_total: 0,
      gastos_financieros: {
        _2022: 0,
        porcentaje_2022: 0,
        _2023: 0,
        porcentaje_2023: 0,
        _2024: 0,
        porcentaje_2024: 0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      productos_financieros: {
        _2022: 0,
        porcentaje_2022: 0,
        _2023: 0,
        porcentaje_2023: 0,
        _2024: 0,
        porcentaje_2024: 0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      impuestos: {
        _2022: 0,
        porcentaje_2022: 0,
        _2023: 0,
        porcentaje_2023: 0,
        _2024: 0,
        porcentaje_2024: 0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      utilidad_neta: {
        _2022: 0,
        porcentaje_2022: 0,
        _2023: 0,
        porcentaje_2023: 0,
        _2024: 0,
        porcentaje_2024: 0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      utilidad_bruta: {
        _2022: 0,
        porcentaje_2022: 0,
        _2023: 0,
        porcentaje_2023: 0,
        _2024: 0,
        porcentaje_2024: 0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      utilidad_operativa: {
        _2022: 0,
        porcentaje_2022: 0,
        _2023: 0,
        porcentaje_2023: 0,
        _2024: 0,
        porcentaje_2024: 0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
      utilidad_antes_impuestos: {
        _2022: 0,
        porcentaje_2022: 0,
        _2023: 0,
        porcentaje_2023: 0,
        _2024: 0,
        porcentaje_2024: 0,
        var_absoluta_22_23: <number>0,
        var_absoluta_23_24: <number>0,
        var_porcentual_22_23: <number>0,
        var_porcentual_23_24: <number>0,
      },
    },
  },
  minuta: {
    numero_comite: 0,
    fecha_comite: "",
    hora_comite: "",
    ubicacion:
      "Carr. México- Toluca #5420 El Yaqui, Cuajimalpa. CDMX. C.P 05320",
    puntos_principales_revisados: "",
    comentarios_adicionales: "",
  },
};
