<div class="container" [ngClass]="{'rotate': rotate}">
    <div class="functions" *ngIf="rotate">
        <span (click)="RotateLeft()"><mat-icon>undo</mat-icon> </span>
        <span (click)="RotateRight()"> <mat-icon>redo</mat-icon> </span>
    </div>
    <div class="image" [ngStyle]="{'width': img.width +'px','height': img.height +'px', 'padding': 0, 'box-sizing': 'border-box'}"> 
        <img 
            [ngClass]="
            {'rotate_90': orientacion == 1,
                'rotate_180': orientacion == 2,
                'rotate_270': orientacion == 3
            }" [src]="url" alt="" [height]="height" [width]="width">
    
    </div>
    <div class="title">{{ text }}</div>
    <div class="splash" *ngIf="rotate" (click)="preview = true"></div>
</div>

<div class="preview" (click)="preview = false" *ngIf="preview">
    <img [src]="url" style="height: 80%; width: auto;">
</div>