import { Component, OnInit } from '@angular/core';
import { UserService, SolicitudService, AuthenticationService } from 'src/app/_services';
import { Router } from '@angular/router';

import { saveAs } from 'file-saver'
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';

import { DatosAnalisis } from '../../_models/Comite'
import { customPatterns } from '../../_models/maskPattern'
import {
  COMPORTAMIENTO_PAGO,
  COMPORTAMIENTO_PAGO_CREDITO,
  MESES,
  Analisis
} from '../valuacion/analisis/Analisis';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-actualizar-capacidad-pago',
  templateUrl: './actualizar-capacidad-pago.component.html',
  styleUrls: ['./actualizar-capacidad-pago.component.scss']
})
export class ActualizarCapacidadPagoComponent implements OnInit {
  customPattern = customPatterns
  MESES = MESES
  COMPORTAMIENTO_PAGO = COMPORTAMIENTO_PAGO
  COMPORTAMIENTO_PAGO_CREDITO = COMPORTAMIENTO_PAGO_CREDITO

  Analisis = Analisis
  DatosAnalisis = DatosAnalisis
  folio = null
  submit = false
  loading = false
  localStorage = false
  comentarios = false

  cliente = null
  activo = null
  cartas = false

  saldo_inicial = 0;

  constructor(
    private authenticationService:AuthenticationService,
    private router:Router,
    private userService:UserService,
    private solicitudService: SolicitudService,
    private dialog:MatDialog
  ) { }

  ngOnInit(): void {
    this.comentarios = localStorage.getItem("comentarios") == 'true'
    this.cartas = localStorage.getItem("cartas") == 'true'
    
    const {folio, id_cliente, tipo_persona, cliente, activo_descripcion, activo_nombre} = JSON.parse( localStorage.getItem("client_details"))
    this.folio = folio
    this.cliente = cliente
    this.activo = activo_nombre
    const actualizar_informacion = JSON.parse(localStorage.getItem('actualizar_informacion')) || null

    if( actualizar_informacion != null ){
      this.localStorage = true
      this.DatosAnalisis = actualizar_informacion.DatosAnalisis || {
        capacidad_pago: {
          ingresos_efectivo: 0,
          ingresos_extra: 0,
        }
      }
      this.Analisis = actualizar_informacion.Analisis
      return
    }

    this.userService.getDatosAnalisis( Number(this.folio) ).subscribe( async (DatosAnalisis:any) => {    
      console.log("Analisis: ", DatosAnalisis);
      
      this.DatosAnalisis = DatosAnalisis
      this.DatosAnalisis.datos_generales.puesto = DatosAnalisis.puesto
      this.DatosAnalisis.datos_generales.fecha_cotizacion = DatosAnalisis.fecha_cotizacion.split('T')[0]
      this.DatosAnalisis.datos_generales.aval_parentezco = DatosAnalisis.aval_parentezco
      this.DatosAnalisis.datos_generales.aval_ocupacion = DatosAnalisis.aval_ocupacion
      this.DatosAnalisis.capacidad_pago.ingresos_efectivo = DatosAnalisis.ingresos_efectivo
      this.DatosAnalisis.capacidad_pago.ingresos_extra = DatosAnalisis.ingresos_extras_equipo
      this.DatosAnalisis.colateral.pago_inicial = DatosAnalisis.pago_inicial
      
      const {mensualidad, renta_inicial = 0} = await <any> this.solicitudService.ObtenerMensualidad(DatosAnalisis.folio, DatosAnalisis.version, DatosAnalisis.plazo).then( mensualidad => mensualidad)
      this.Analisis.mensualidad = mensualidad;
      const {residual} = await <any> this.solicitudService.ObtenerResidual(DatosAnalisis.folio, DatosAnalisis.version, DatosAnalisis.plazo).then( residual => residual)
      this.Analisis.valor_residual = residual

      this.Analisis.monto_arrendar = DatosAnalisis.monto_arrendar
      this.Analisis.descripcion_activo = DatosAnalisis.activo_arrendar
      this.Analisis.tipo_activo_arrendar = DatosAnalisis.tipo_activo_arrendar
      this.Analisis.perfil_solicitante.bc_score = DatosAnalisis.bc_score
      this.Analisis.perfil_solicitante.arraigo_empleo = DatosAnalisis.empleo_antiguedad
      this.Analisis.perfil_solicitante.arraigo_empleo_punt = DatosAnalisis.empleo_antiguedad >= 2 ? 2 :0
      this.Analisis.perfil_aval.nombre = DatosAnalisis.aval_nombre
      this.Analisis.perfil_aval.parentezco = DatosAnalisis.aval_parentezco;
      this.Analisis.perfil_aval.ocupacion = DatosAnalisis.aval_ocupacion;
      this.Analisis.comprobantes_gastos = DatosAnalisis.gastos_comprobables
      this.Analisis.efectivo_gastos = DatosAnalisis.monto_renta_consultorio + DatosAnalisis.gastos_comprobables
      this.Analisis.extra_nuevo_equipo_gastos = this.Analisis.efectivo_gastos + this.Analisis.mensualidad
      this.Analisis.mar_total = DatosAnalisis.monto_arrendar - DatosAnalisis.pago_inicial
      this.Analisis.factor_porcentaje = DatosAnalisis.factor_porcentaje
      this.Analisis.plazo = DatosAnalisis.plazo
      this.Analisis.tiempo_entrega = DatosAnalisis.tiempo_entrega
      // this.Analisis.rentas_anticipadas = ((DatosAnalisis.monto_arrendar * (DatosAnalisis.factor_porcentaje /100)) / 1.16).toFixed(2);
      this.Analisis.rentas_anticipadas = (renta_inicial * 1.16).toFixed(2);
      this.DatosAnalisis.colateral.factor = this.Analisis.plazo * this.Analisis.mensualidad
      this.DatosAnalisis.colateral.factorCopy = this.DatosAnalisis.colateral.factor;
      console.log("Facot,", this.DatosAnalisis.colateral.factor, DatosAnalisis);
      this.Analisis.vendor = this.DatosAnalisis.perfil_responsable == 2 ? this.DatosAnalisis.vendor : '';
      this.Analisis.comercial = this.DatosAnalisis.perfil_responsable == 3 ? this.DatosAnalisis.vendor : '';
      this.Analisis.tipo_vendor = 'Interno'

      this.userService.ObtenerCapacidadPago( this.folio )
      .subscribe( res => {
        console.log("Capacidad de Pago: ", res)
        this.Analisis.historial_movimientos = res.movimientos
        this.Analisis.comentarios_capacidad_pago = res.comentarios
        this.Analisis.ingresos_nomina = res.ingresos_nomina
        this.ActualizarPromedios()
      })
    })
  }

  Actualizar() {
    if( !this.userService.alertService.isOnline() ) return

    if( !this.isValid() ) return

    if( this.localStorage ){
      const persistence:any = JSON.parse(localStorage.getItem('actualizar_informacion'))
      persistence.Analisis = this.Analisis
      persistence.DatosAnalisis = this.DatosAnalisis

      localStorage.setItem('actualizar_informacion', JSON.stringify(persistence))

      this.dialog.open(MessageBoxComponent, {
        width: "500px",
        data: {
          text2: 'Éxito',
          text3: 'Se ha actualizado la capacidad de pago',
          type: "success",
          icon: "success"
        }
      }).afterClosed().subscribe(() => history.go(-2) )
      return
    }
    const request:any = {
      tipo: this.comentarios ? 2 : this.cartas ? 4 : 3,
      folio: this.folio,
      nombre_solicitante: this.cliente,
      activo_arrendar: this.activo,
      historial_movimientos: {
        promedio_depositos: this.Analisis.promedio_depositos.toFixed(2),
        promedio_gastos: this.Analisis.promedio_gastos.toFixed(2),
        promedio_saldo_final: this.Analisis.promedio_saldo_final.toFixed(2),
      },
      movimientos: this.Analisis.historial_movimientos,
      capacidad_pago: {
        ingresos_nomina: this.Analisis.ingresos_nomina,
        efectivo_ingresos: this.DatosAnalisis.capacidad_pago.ingresos_efectivo,
        extra_nuevo_equipo_ingresos: this.DatosAnalisis.capacidad_pago.ingresos_extra,
        factor_renta: this.Analisis.factor_renta,
        puntuacion_capacidad_pago: this.Analisis.puntuacion_capacidad_pago,
        comentarios: this.Analisis.comentarios_capacidad_pago
      },
      matriz_capacidad_pago: {
        comprobantes: {
          comprobantes_ingresos: this.Analisis.comprobantes_ingresos.toFixed(2),
          comprobantes_gastos: this.Analisis.comprobantes_gastos.toFixed(2),
          comprobantes_factor: this.Analisis.comprobantes_factor,
        },
        efectivo: {
          efectivo_ingresos: this.Analisis.efectivo_ingresos.toFixed(2),
          efectivo_gastos: this.Analisis.efectivo_gastos.toFixed(2),
          efectivo_factor: this.Analisis.efectivo_factor,
        },
        extra: {
          extra_nuevo_equipo_ingresos: this.Analisis.extra_nuevo_equipo_ingresos.toFixed(2),
          extra_nuevo_equipo_gastos: this.Analisis.extra_nuevo_equipo_gastos.toFixed(2),
          extra_nuevo_equipo_factor: this.Analisis.extra_nuevo_equipo_factor,
        }
      }
    }

    
    this.loading = true
    console.log("Actualizando", request);

    this.userService.ActualizarCapacidadPago(request)
    .subscribe( res => {
      this.loading = false
      console.log(res)
      this.SavePDF( res )
      const dialogref = this.dialog.open(MessageBoxComponent, {
        width: "500px",
        data: {
          text2: 'Éxito',
          text3: 'Se ha actualizado la capacidad de pago',
          type: "success",
          icon: "success"
        }
      })

      dialogref.afterClosed().subscribe( () => history.go(-2))
      
    })
  }

  AgregarMovimiento(){
    this.Analisis.historial_movimientos
    .push({mes: '', depositos: 0, gastos: 0, saldo_final: 0})
    this.ActualizarPromedios()
  }

  BorrarMovimiento(idx){
    this.Analisis.historial_movimientos.splice(idx, 1)
    this.ActualizarPromedios()
  }
  
  ActualizarPromedios() {
    this.Analisis.promedio_depositos = 0
    this.Analisis.promedio_gastos = 0
    this.Analisis.promedio_saldo_final = 0
    
    const saldo_inicial = this.Analisis.saldo_inicial
    this.Analisis.historial_movimientos.forEach( (mov, i) => {
      const saldo_anterior = (i > 0) ? Number(this.Analisis.historial_movimientos[i -1].saldo_final) : Number(saldo_inicial)
      console.log("Saldo final: ", saldo_anterior, this.saldo_inicial);
      mov.saldo_final = Number(mov.depositos) - Number(mov.gastos) + saldo_anterior
      this.Analisis.promedio_depositos += (Number(mov.depositos) / this.Analisis.historial_movimientos.length);
      this.Analisis.promedio_gastos += (Number(mov.gastos) / this.Analisis.historial_movimientos.length);
      this.Analisis.promedio_saldo_final += (Number(mov.saldo_final) / this.Analisis.historial_movimientos.length);
    });

    this.Analisis.comprobantes_ingresos = (this.Analisis.promedio_depositos *1) + (this.Analisis.ingresos_nomina *1)
    this.Analisis.efectivo_ingresos = (this.DatosAnalisis.capacidad_pago.ingresos_efectivo * 0.6) + this.Analisis.comprobantes_ingresos
    this.Analisis.extra_nuevo_equipo_ingresos = (this.DatosAnalisis.capacidad_pago.ingresos_extra * 0.5) + this.Analisis.efectivo_ingresos

    
    this.Analisis.comprobantes_factor = Math.trunc(100 * (this.Analisis.comprobantes_gastos / this.Analisis.comprobantes_ingresos))
    this.Analisis.efectivo_factor = Math.trunc(100 * (this.Analisis.efectivo_gastos / this.Analisis.efectivo_ingresos))
    this.Analisis.extra_nuevo_equipo_factor = Math.trunc(100 * (this.Analisis.extra_nuevo_equipo_gastos / this.Analisis.extra_nuevo_equipo_ingresos))

    this.Analisis.factor_renta = Number(((this.Analisis.extra_nuevo_equipo_ingresos - this.Analisis.extra_nuevo_equipo_gastos) / this.Analisis.mensualidad).toFixed(2))

    // const PromedioFactor = (this.Analisis.comprobantes_factor + this.Analisis.efectivo_factor + this.Analisis.extra_nuevo_equipo_factor) / this.Analisis.historial_movimientos.length
    const PromedioFactor = this.Analisis.extra_nuevo_equipo_factor
    this.Analisis.puntuacion_capacidad_pago = (PromedioFactor > 81) ? 0 
      : (PromedioFactor > 75) ? 25
      : (PromedioFactor > 70) ? 27
      : (PromedioFactor > 66) ? 30
      : (PromedioFactor > 60) ? 33 : 0
    this.Analisis.puntuacion_capacidad_pago = (this.Analisis.factor_renta > 2)
      ? this.Analisis.puntuacion_capacidad_pago += 3
      : this.Analisis.puntuacion_capacidad_pago
  }

  SavePDF( data ){
    console.log("PDFs", data);

    if( data.analisis ){
      saveAs('data:application/octet-stream;base64,' + data.analisis, 'analisis.pdf')
    }

    if( data.comentario ){
      saveAs('data:application/octet-stream;base64,' + data.comentario, 'comentarios.pdf')
    }
    if( data.minuta ){
      saveAs('data:application/octet-stream;base64,' + data.minuta, 'minuta.pdf')
    }
  }

  isValid() {
    this.submit = true
    this.Analisis.comentarios_capacidad_pago = this.Analisis.comentarios_capacidad_pago.trim()
    return (
      this.Analisis.comentarios_capacidad_pago.length &&
      this.Analisis.historial_movimientos[0].mes &&
      this.Analisis.historial_movimientos[0].depositos > 0
    )
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
