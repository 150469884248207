import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ArcusService {
  httpHeaders: any;
  ocupaciones = []
  sectoresEconomicos = []

  constructor(
    private http: HttpClient
  ) { 
    const currentUser = localStorage.getItem('currentUser')
        const { token } = currentUser ? JSON.parse( currentUser ) : { token: "test"}

		this.httpHeaders = {
            headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Authorization': token,
            })
        };
  }

  async obtenerOcupaciones() {

    const res = await this.http.post(`${environment.config.apiUrl}arcus/obtener_ocupaciones`, null, this.httpHeaders)
        .toPromise()
        console.log('Response', res);
        this.ocupaciones = res['data']
        this.sectoresEconomicos = Array.from(new Set(this.ocupaciones.map(item => item.sectorEconomico)));
        console.log(this.sectoresEconomicos);
        
        return res;
  }

  async CrearAnexo(folio) {
    return await this.http.post(`${environment.config.apiUrl}arcus/crear_contratos`, {folio}, this.httpHeaders).toPromise()
  }

  CargarActividadesPrincipales(sectorEconomico) {
    console.log(`Filtro: ${sectorEconomico}`);
    
    return this.ocupaciones.filter(ocupacion => ocupacion.sectorEconomico == sectorEconomico)
  }
}
