<div class="main">
    <h2>Detalle de cartera</h2>
    <div class="info">
        <span>Cliente: </span><span>{{cliente}}</span>
    </div>
    <div class="info">
        <span>Anexo: </span><span>{{anexo}}</span>
    </div>
    <div class="table-rentas">
        <table class="table-rentas" *ngIf="rentas.length">
            <thead>
                <tr>
                    <th>Renta</th>
                    <th>Fecha</th>
                    <th>Pago</th>
                    <th>Capital</th>
                    <th>Intereses</th>
                    <th>Insoluto</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let renta of rentas">
                    <td>{{renta.renta}}</td>
                    <td>{{renta.fecha | date:'dd/MM/yyyy'}}</td>
                    <td>{{renta.pago | currency:'MXN'}}</td>
                    <td>{{renta.capital | currency:'MXN'}}</td>
                    <td>{{renta.interes | currency:'MXN'}}</td>
                    <td>{{renta.insoluto | currency:'MXN'}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>