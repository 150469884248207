import { Component, OnInit } from '@angular/core';
import { AuthenticationService, UserService } from 'src/app/_services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-comercial',
  templateUrl: './dashboard-comercial.component.html',
  styleUrls: ['./dashboard-comercial.component.scss']
})
export class DashboardComercialComponent implements OnInit {
  tramites:any = []
  tramites_filtrados:any []

  constructor(private authenticationService:AuthenticationService,
    private router:Router,
    private userService:UserService) { }

  ngOnInit(): void {
    this.CargarStatus()
  }

  ngOnChanges(): void {
    // Se mantiene el filtro despues de haber un cambio en el estatus de los procesos
    if( this.tramites.length ) this.Filtrar('')
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  CargarStatus(){
    this.userService.getListStatus({comercial: true})
      .subscribe( tramites => {
        this.tramites = tramites
        this.Filtrar('')
      } )
  }

  VerDetalles(folio) {
    if( !this.userService.alertService.isOnline() ) return 
    console.log(folio);
    
    const cliente = this.tramites.filter( tramite => tramite.folio == folio )
    localStorage.setItem("client_details", JSON.stringify( cliente[0] ) )
    this.router.navigateByUrl(`dashboard/comercial/${folio}`)
    
  }

  VerExpediente(folio) {
    if( !this.userService.alertService.isOnline() ) return 
    const cliente = this.tramites.filter( tramite => tramite.folio == folio )
    localStorage.setItem("client_details", JSON.stringify( cliente[0] ) )
    this.router.navigateByUrl(`dashboard/cliente/${folio}/carga_documentos`)
  }

  Confirmacion(folio) {
    if( !this.userService.alertService.isOnline() ) return 
    const cliente = this.tramites.filter( tramite => tramite.folio == folio )
    localStorage.setItem("client_details", JSON.stringify( cliente[0] ) )
    this.router.navigateByUrl(`dashboard/historial_cotizaciones`)

  }

  Filtrar(word) {
    console.log("Filtrando: ", word);
    
    this.tramites_filtrados = this.tramites.filter( tramite => {
      return (
        tramite.folio.toString().includes(word) || 
        tramite.cliente.toLowerCase().includes(word.toLowerCase()) || 
        tramite.activo_nombre.toLowerCase().includes(word.toLowerCase()))
    })
    console.log("Filtro: ", this.tramites_filtrados);
    
  }
}
