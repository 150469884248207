import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-documentos-contrato-rechazados',
  templateUrl: './documentos-contrato-rechazados.component.html',
  styleUrls: ['./documentos-contrato-rechazados.component.scss']
})
export class DocumentosContratoRechazadosComponent implements OnInit {

  documentos: any = []
  constructor(
    private dialogRef: MatDialogRef<DocumentosContratoRechazadosComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {
    this.documentos = this.data.documentos
  }


  Cerrar() {
    this.dialogRef.close()
  }
}
