import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, AuthenticationService, UserService } from '../../_services';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';

@Component({
    selector: 'app-solicitud',
    templateUrl: './solicitud.component.html',
    styleUrls: ['./solicitud.component.scss']
})

export class SolicitudComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    show_password = false;
    submitted = false;
    returnUrl: string;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private userService: UserService,
        private dialog: MatDialog
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/dashboard']);
        }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', [Validators.required, Validators.pattern(/^([a-zA-Z]|[0-9]+[a-zA-Z]+)([a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~])*@[a-zA-Z0-9\-]+\.[a-zA-Z]{2,}/)]],
            password: ['', [Validators.required, Validators.minLength(8)]]
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/dashboard';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.username.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                ({status, body, body: {errorMessage}}) => {
                    console.log(body);

                    this.loading = false;
                    if (status === 220) {
                        this.dialog.open(MessageBoxComponent, {
                            data: {
                                width: '300px',
                                text: errorMessage,
                                type: 'error',
                                icon: 'error'
                            }
                        });
                    } else if ( status === 201 ) {
                        this.dialog.open(MessageBoxComponent, {
                            data: {
                                width: '300px',
                                text: 'La cuenta no ha sido activada.',
                                type: 'error',
                                icon: 'error'
                            }
                        });
                    } else if ( status === 200 ) {
                        const {perfil, quatation} = this.authenticationService.currentUserValue;

                        if ( perfil == 1 && quatation.Status ) { this.router.navigate(['/dashboard']); } else if ( perfil == 1) { this.router.navigate(['codeActivation']); } else { this.router.navigate(['/dashboard']); }

                        this.loading = false;

                        // this.checkProcess();
                        // this.router.navigate([this.returnUrl]);
                    } else {
                        this.dialog.open(MessageBoxComponent, {
                            data: {
                                width: '300px',
                                text: 'Ocurrió un error inesperado, por favor vuelva a intentarlo.',
                                type: 'error',
                                icon: 'error'
                            }
                        });
                    }
                },
                error => {
                    this.loading = false;
                    this.dialog.open(MessageBoxComponent, {
                        data: {
                            width: '300px',
                            text: 'Ocurrió un error inesperado, por favor vuelva a intentarlo.',
                            type: 'error',
                            icon: 'error'
                        }
                    });
                });
    }

    onKeyup({keyCode}) {
        if ( keyCode === 13) { this.onSubmit(); }
    }
    checkProcess() {
        // this.userService.checkProcess(this.authenticationService.currentUserValue)
        //     .pipe(first())
        //     .subscribe(
        //         data => {

        //         },
        //         error => {
        //             this.router.navigate(['codeActivation']);
        //         });
    }
}
