import { Component, OnInit } from '@angular/core';
import { SolicitudService, UserService } from 'src/app/_services';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';

@Component({
  selector: 'app-seleccionar-plazo',
  templateUrl: './seleccionar-plazo.component.html',
  styleUrls: ['./seleccionar-plazo.component.scss']
})
export class SeleccionarPlazoComponent implements OnInit {
  perfil = null
  loading = false
  version = null
  folio_lease = null
  id_cliente = null
  folio = null
  url = null
  id_cotizacion = null
  zoom = 1.0
  plazo:any = 0

  plazos_validos = []
  plazos_validos_msi = []

  tipoDomiciliacion = 'Sin domiciliación'
  numeroCuenta = ''
  banco = ''
  bancos = [
    {
        nombre: "Banca Afirme",
        id: "1590080924889x680908762151023500"
    },
    {
        nombre: "Banca Mifel",
        id: "1590080925499x920141593569574000"
    },
    {
        nombre: "Banco Azteca",
        id: "1590080926962x384734523128436000"
    },
    {
        nombre: "Banco Compartamos",
        id: "1590080928080x713075572389140600"
    },
    {
        nombre: "Banco Credit Suisse (México)",
        id: "1590080928415x993279456251461300"
    },
    {
        nombre: "Banco de Inversión Afirme",
        id: "1590080929055x610609549542966000"
    },
    {
        nombre: "Banco del Bajío",
        id: "1590080929547x302928739148445440"
    },
    {
        nombre: "Banco Inbursa",
        id: "1590080931473x858255099944489300"
    },
    {
        nombre: "Banco Inmobiliario Mexicano",
        id: "1590080931807x283087046803891520"
    },
    {
        nombre: "Banco Invex",
        id: "1590080932132x500367643877437800"
    },
    {
        nombre: "Banco JP Morgan",
        id: "1590080932472x133430279737367650"
    },
    {
        nombre: "Banco Monex",
        id: "1590080933297x302346844337262800"
    },
    {
        nombre: "Banco Multiva",
        id: "1590080933661x469419652656629250"
    },
    {
        nombre: "Citi Banamex",
        id: "1590080934162x242996502629421200"
    },
    {
        nombre: "Banco Regional de Monterrey",
        id: "1590080934817x735871571579748100"
    },
    {
        nombre: "Banco Santander",
        id: "1590080935911x369406876653727940"
    },
    {
        nombre: "Banco Ve por Más",
        id: "1590080936715x827055496242654600"
    },
    {
        nombre: "BanCoppel",
        id: "1590080937039x385899996037598140"
    },
    {
        nombre: "Bank of America Mexico",
        id: "1590080937550x168672245903041630"
    },
    {
        nombre: "Banorte",
        id: "1590080938756x959274916483753700"
    },
    {
        nombre: "Bansí",
        id: "1590080939083x848576890857233200"
    },
    {
        nombre: "Barclays Bank México",
        id: "1590080939430x763479192756340200"
    },
    {
        nombre: "BBVA",
        id: "1590080939790x633608693318391900"
    },
    {
        nombre: "CIBanco",
        id: "1590080940167x263929057440903700"
    },
    {
        nombre: "Deutsche Bank México",
        id: "1590080941040x224993030062962780"
    },
    {
        nombre: "HSBC México",
        id: "1590080942031x610495598557596400"
    },
    {
        nombre: "Intercam Banco",
        id: "1590080942843x671009854408065200"
    },
    {
        nombre: "Scotiabank",
        id: "1590080943913x708953820289524700"
    },
    {
        nombre: "Banjercito",
        id: "1596721485031x250205095944545660"
    },
    {
        nombre: "Banregio",
        id: "1613501701387x898499942510279000"
    },
    {
        nombre: "Sistema de Transferencias y Pagos STP",
        id: "1716410116713x732439180131215700"
    }
]

  
  constructor(
    private userService:UserService,
    private router:Router,
    private dialog:MatDialog,
    private solicitudService:SolicitudService
  ) { }

  async ngOnInit() {
    // folio, id_cliente, plazo, id_cotizacion
    const { id, id_cliente, version, folio_leas, url } = JSON.parse(localStorage.getItem('cotizacion'))
    this.perfil = Number( JSON.parse(localStorage.getItem('currentUser')).perfil )

    const {plazos_validos, plazos_validos_msi} = await this.userService.PlazosValidos( id );
    console.log('plazos_validos: ', plazos_validos);
    
    this.plazos_validos_msi = JSON.parse(plazos_validos_msi || '[]')
    this.plazos_validos = plazos_validos.split(",")
    this.plazo = Number(this.plazos_validos[0])
    
    this.folio = JSON.parse(localStorage.getItem('client_details')).folio
    this.id_cotizacion = id
    this.id_cliente = id_cliente
    this.version = version
    this.folio_lease = folio_leas
    this.url = url
    this.userService.CotizacionVista( id ).subscribe( res => console.log(res))
  }

  SetZoom(v){
    this.zoom += v
    this.zoom = this.zoom < 1.0 ? 1.0 : this.zoom
    this.zoom = this.zoom > 2.0 ? 2.0 : this.zoom
  }

  ConfirmarCotizacion(){
    if( !this.userService.alertService.isOnline() ) return 
    this.dialog.open(MessageBoxComponent, {
      width: "450px",
      data: {
        text: `¿Seguro que quieres confirmar<br>la cotización #${this.version} con<br>plazo de ${this.plazo}?`,
        text3: "Sólo podrás escoger una vez",
        type: "confirm-cot",
        confirm: ()=>{
          if( !this.userService.alertService.isOnline() ) return
          console.log("Aceptada");
          console.log(this.folio, this.id_cliente, this.plazo, this.id_cotizacion, this.perfil);
          
          this.userService.changeStatus(this.folio, 3, 1).subscribe(res => {
            console.log(res);
            this.userService.PreConfirmarCotizacion(this.folio, this.id_cliente, this.plazo, this.id_cotizacion, this.perfil)
            .subscribe( async (res) => {
              console.log(res);
              const domiciliacion = {
                tipoDomiciliacion: this.tipoDomiciliacion,
                numeroCuenta: this.numeroCuenta,
                banco: this.banco
              }
              await this.solicitudService.GuardarSolicitud(this.folio, {domiciliacion})
              const confirmdialog = this.dialog.open(MessageBoxComponent, {
                width: "500px",
                data: {
                  text: "Éxito",
                  text2: "Has confirmado la cotización",
                  text3: this.perfil == 1 ? "la evaluaremos y pronto tendrás los resultados" : '',
                  type: "success",
                  icon: "success"
                }
              }).afterClosed().subscribe( () => this.router.navigate(['dashboard']) )
            })
          })
        },
        reject: () => console.log("Rechazada")
      }
    })
  }

  PlazoValido = ( meses ) => this.plazos_validos.indexOf(meses) != -1;
}
