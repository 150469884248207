import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { AuthenticationService } from 'src/app/_services';
import { CotizadorAutoService } from 'src/app/_services/cotizador-auto.service';

@Component({
  selector: 'app-cotizador-auto',
  templateUrl: './cotizador-auto.component.html',
  styleUrls: ['./cotizador-auto.component.scss']
})
export class CotizadorAutoComponent implements OnInit {

  config = []
  loading = false

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private cotizadorAutoService: CotizadorAutoService,
    private dialog: MatDialog
  ) { }

  async ngOnInit() {
    try {
      this.config = await this.cotizadorAutoService.GetConfig()
      console.log(this.config);
    } catch(e) {

    }
    
  }

  AgregarEstado() {
    this.config.push({
      estado: '',
      monto_tenencia: 0,
      monto_placas: 0,
      gps_inicial: 0,
      gps_mensualidad: 0,
    })
  }

  async GuardarEstados() {
    this.loading = true
    try {
      await this.cotizadorAutoService.GuardarConfig(this.config)
      this.dialog.open(MessageBoxComponent, {
        width: "500px",
        data: {
          text2: 'Éxito',
          text3: 'Se ha actualizado con éxito',
          type: "success",
          icon: "success"
        }
      })
      this.loading = false
    } catch(e) {
      this.dialog.open(MessageBoxComponent, {
        width: "500px",
        data: {
          text2: 'Error',
          text3: 'Ha ocurrido un error al actualizar los datos',
          type: "error",
          icon: "error"
        }
      })
      this.loading = false
    }
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
