
<!-- <div class="progress">
	<div class="progress-left active" (click)="changePart(1)"></div>
	<div class="progress-mid" (click)="changePart(2)"></div>
</div> -->

<form [formGroup]="dgFForm" *ngIf="tipoAval > 0">
	<!-- <h3>Referencias Personales (Dos referencias no familiares y una familiar)</h3> -->
	<div class="datos_generales_1">
		<input type="checkbox" name="" id="" formControlName="aval_omitir" >Omitir aval
		<div class="size-2">
		</div>
		<mat-form-field appearance="outline" *ngIf="!f.aval_omitir.value">
			<mat-label>RFC</mat-label>
			<input matInput *ngIf="tipoAval == 1"
			mask="SSSS000000 AAA"
			placeholder="Con homoclave"
				formControlName="aval_rfc" 
				[value]="datosAval.aval_rfc"
				[ngClass]="{ 'is-invalid': submitted && f.aval_rfc.errors }"
			>
			<input matInput *ngIf="tipoAval == 2"
				mask="SSS000000 AAA"
				formControlName="aval_rfc" 
				[value]="datosAval.aval_rfc"
				[ngClass]="{ 'is-invalid': submitted && f.aval_rfc.errors }"
			>
			<mat-error *ngIf="submitted && f.aval_rfc.errors" class="invalid-feedback">
				<div *ngIf="f.aval_rfc.errors">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline" *ngIf="!f.aval_omitir.value">
			<mat-label>Correo electrónico</mat-label>
			<input matInput
				formControlName="aval_email" 
				[value]="datosAval.aval_email"
				[ngClass]="{ 'is-invalid': submitted && f.aval_email.errors }"
			>
			<mat-error *ngIf="submitted && f.aval_email.errors" class="invalid-feedback">
				<div *ngIf="f.aval_email.errors">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
		<mat-form-field appearance="outline" *ngIf="!f.aval_omitir.value">
			<mat-label *ngIf="tipoAval == 1">Nombres</mat-label>
			<mat-label *ngIf="tipoAval == 2">Razón Social</mat-label>
			<input  matInput
			[specialCharacters]="['space']"
				[patterns]="customPattern"
				mask="S{100}"
				formControlName="aval_nombre_rs" 
				[value]="datosAval.aval_nombre_rs"
				[ngClass]="{ 'is-invalid': submitted && f.aval_nombre_rs.errors }"
				>
			<mat-error *ngIf="submitted && f.aval_nombre_rs.errors" class="invalid-feedback">
				<div *ngIf="f.aval_nombre_rs.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<div *ngIf="tipoAval == 1">
			<mat-form-field appearance="outline" *ngIf="!f.aval_omitir.value">
				<mat-label >Apellido paterno</mat-label>
				<input  matInput
				[specialCharacters]="['space']"
					[patterns]="customPattern"
					mask="S{100}"
					formControlName="aval_apellido_paterno" 
					[value]="datosAval.aval_apellido_paterno"
					[ngClass]="{ 'is-invalid': submitted && f.aval_apellido_paterno.errors }"
					>
				<mat-error *ngIf="submitted && f.aval_apellido_paterno.errors" class="invalid-feedback">
					<div *ngIf="f.aval_apellido_paterno.errors.required">Campo obligatorio</div>
				</mat-error>
			</mat-form-field>
	
			<mat-form-field appearance="outline" *ngIf="!f.aval_omitir.value">
				<mat-label>Apellido materno</mat-label>
				<input  matInput
				[specialCharacters]="['space']"
					[patterns]="customPattern"
					mask="S{100}"
					formControlName="aval_apellido_materno" 
					[value]="datosAval.aval_apellido_materno"
					[ngClass]="{ 'is-invalid': submitted && f.aval_apellido_materno.errors }"
					>
				<mat-error *ngIf="submitted && f.aval_apellido_materno.errors" class="invalid-feedback">
					<div *ngIf="f.aval_apellido_materno.errors.required">Campo obligatorio</div>
				</mat-error>
			</mat-form-field>
		</div>



		<mat-form-field appearance="outline" *ngIf="!f.aval_omitir.value">
			<mat-label *ngIf="tipoAval == 1">Genero</mat-label>
			<mat-select
			formControlName="aval_genero" >
				<mat-option value="Hombre">Hombre</mat-option>
				<mat-option value="Mujer">Mujer</mat-option>
			</mat-select>
			<mat-error *ngIf="submitted && f.aval_genero.errors" class="invalid-feedback">
				<div *ngIf="f.aval_genero.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline" *ngIf="!f.aval_omitir.value">
			<mat-label *ngIf="tipoAval == 1">Fecha de Nacimiento</mat-label>
			<mat-label *ngIf="tipoAval == 2">Constitución</mat-label>
			<input  matInput
			*ngIf="tipoAval == 1"
			type="date"
				min="1900-01-01"
				max="2100-12-31"
				formControlName="aval_fecha_nac_constitucion"

				[value]="datosAval.aval_fecha_nac_constitucion"
				[ngClass]="{ 'is-invalid': submitted && f.aval_fecha_nac_constitucion.errors }"
				max="{{minDate | date: 'yyyy-MM-dd'}}"
			>
			<input  matInput
			*ngIf="tipoAval == 2"
			type="date"
				min="1900-01-01"
				max="2100-12-31"
				formControlName="aval_fecha_nac_constitucion"
				
				[value]="datosAval.aval_fecha_nac_constitucion"
				[ngClass]="{ 'is-invalid': submitted && f.aval_fecha_nac_constitucion.errors }"
				
			>
			<mat-error *ngIf="submitted && f.aval_fecha_nac_constitucion.errors" class="invalid-feedback">
				<div *ngIf="f.aval_fecha_nac_constitucion.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<!-- <div class="input size-2" *ngIf="tipoAval == 1 && !f.aval_omitir.value">
			<mat-label>Estado Civil</mat-label>
			<mat-radio-group 
				formControlName="avalF_estado_civil" 
				[value]="datosAval.avalF_estado_civil"
				[ngClass]="{ 'is-invalid': submitted && f.avalF_estado_civil.errors }">
				<mat-radio-button labelPosition="before" value="SOLTERO">Soltero</mat-radio-button>
				<mat-radio-button labelPosition="before" value="CASADO">Casado</mat-radio-button>
			</mat-radio-group> -->
			<!-- <input  
				formControlName="avalF_estado_civil" 
				[value]="datosAval.avalF_estado_civil"
				[ngClass]="{ 'is-invalid': submitted && f.avalF_estado_civil.errors }"
			> -->
			<!-- <mat-error *ngIf="submitted && f.avalF_estado_civil.errors" class="invalid-feedback">
				<div *ngIf="f.avalF_estado_civil.errors.required">Campo obligatorio</div>
			</mat-error>
		</div> -->

		<!-- <div *ngIf="tipoAval == 1 && !f.aval_omitir.value">
			<div style="color: #3F4A57; font-size: .7rem;">SOLO PARA CASADOS</div>
			<mat-form-field appearance="outline">
				<mat-label>Régimen</mat-label>
				<input matInput 
				[readonly]="f.avalF_estado_civil.value == 'SOLTERO'"
				formControlName="avalF_regimen" 
				[value]="datosAval.avalF_regimen"
				[ngClass]="{ 'is-invalid': submitted && f.avalF_regimen.errors }"
				>
			</mat-form-field>
		</div> -->

		<!-- <div *ngIf="f.avalF_estado_civil.value == 'CASADO' and !f.aval_omitir.value">
			<div style="color: #3F4A57; font-size: .7rem;">SOLO PARA CASADOS</div>
			<mat-form-field appearance="outline">
			<mat-label>Régimen</mat-label>
			<input matInput
				[readonly]="f.avalF_estado_civil.value == 'SOLTERO'"
				formControlName="avalF_regimen" 
				[value]="datosAval.avalF_regimen"
				[ngClass]="{ 'is-invalid': submitted && f.avalF_regimen.errors }"
			>
		</mat-form-field>
			<mat-error *ngIf="submitted && f.avalF_regimen.errors" class="invalid-feedback">
				<div *ngIf="f.avalF_regimen.errors.required">Campo obligatorio</div>
			</mat-error>
		</div> -->
		<!-- <div *ngIf="tipoAval == 1 && !f.aval_omitir.value">
		<mat-form-field appearance="outline" *ngIf="f.avalF_estado_civil.value == 'CASADO' and !f.aval_omitir.value">
			<mat-label>Nombre del cónyuge</mat-label>
			<input  matInput
			placeholder="Como aparece en el I.N.E."
				[readonly]="f.avalF_estado_civil.value == 'SOLTERO'"
				[patterns]="customPattern"
				mask="S{100}"
				formControlName="avalF_nombre_conyugue" 
				[value]="datosAval.avalF_nombre_conyugue"
				[ngClass]="{ 'is-invalid': submitted && f.avalF_nombre_conyugue.errors }"
			>
			<mat-error *ngIf="submitted && f.avalF_nombre_conyugue.errors" class="invalid-feedback">
				<div *ngIf="f.avalF_nombre_conyugue.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
	</div> -->

		<!-- <div *ngIf="tipoAval == 1">
			<mat-form-field appearance="outline" *ngIf="!f.aval_omitir.value">
				<mat-label>Prentesco con solicitante</mat-label>
				<input matInput
				[specialCharacters]="['space']"
					[patterns]="customPattern"
					mask="S{100}"
					formControlName="aval_parentesco" 
					[value]="datosAval.aval_parentesco"
					[ngClass]="{ 'is-invalid': submitted && f.aval_parentesco.errors }"
					>
			</mat-form-field>
			<mat-form-field appearance="outline" *ngIf="!f.aval_omitir.value" >
				<mat-label>Ocupación</mat-label>
				<input matInput
				[specialCharacters]="['space']"
					[patterns]="customPattern"
					mask="S{100}"
					formControlName="aval_ocupacion" 
					[value]="datosAval.aval_ocupacion"
					[ngClass]="{ 'is-invalid': submitted && f.aval_ocupacion.errors }"
					>
			</mat-form-field>
			<mat-form-field appearance="outline" *ngIf="!f.aval_omitir.value">
				<mat-label>Tiempo de conocerlo</mat-label>
				<input matInput
				[specialCharacters]="['space']"
					[patterns]="customPattern"
					mask="E{100}"
					formControlName="aval_tiempo_conocerlo" 
					[value]="datosAval.aval_tiempo_conocerlo"
					[ngClass]="{ 'is-invalid': submitted && f.aval_tiempo_conocerlo.errors }"
					>
			</mat-form-field>
		</div> -->
		

		

		
	</div>


	<div>
		
		<!-- <div *ngIf="!f.aval_omitir.value" class="input">
			<mat-label>Domicilio</mat-label>
			<textarea  
				formControlName="aval_domicilio" 
				[value]="datosAval.aval_domicilio"
				[ngClass]="{ 'is-invalid': submitted && f.aval_domicilio.errors }"
			></textarea>
			<mat-error *ngIf="submitted && f.aval_domicilio.errors" class="invalid-feedback">
				<div *ngIf="f.aval_domicilio.errors.required">Campo obligatorio</div>
			</mat-error>
		</div> -->
		<div class="input" *ngIf="tipoAval == 1 && !f.aval_omitir.value">
			<mat-label>Datos del domicilio</mat-label>
			<mat-radio-group 
				formControlName="avalF_domicilio" 
				[value]="datosAval.avalF_domicilio"
				[ngClass]="{ 'is-invalid': submitted && f.avalF_domicilio.errors }">
				<mat-radio-button labelPosition="before" [value]="'PROPIO'">Propio</mat-radio-button>
				<mat-radio-button labelPosition="before" [value]="'RENTADO'">Rentado</mat-radio-button>
			</mat-radio-group>
			<!-- <input  
				formControlName="avalF_domicilio" 
				[value]="datosAval.avalF_domicilio"
				[ngClass]="{ 'is-invalid': submitted && f.avalF_domicilio.errors }"
			> -->
			<mat-error *ngIf="submitted && f.avalF_domicilio.errors" class="invalid-feedback">
				<div *ngIf="f.avalF_domicilio.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<!-- <mat-form-field appearance="outline" *ngIf="tipoAval == 1 && f.avalF_domicilio.value == 'PROPIO' && !f.aval_omitir.value">
			<mat-label>Valor</mat-label>
			<input matInput
			mask="separator.2"
			thousandSeparator=","
			prefix="$ "
			placeholder="Solo para privado"
			[readonly]="f.avalF_domicilio.value == 'RENTADO'"
				formControlName="avalF_valor" 
				[value]="datosAval.avalF_valor"
				[ngClass]="{ 'is-invalid': submitted && f.avalF_valor.errors }"
			>
			<mat-error *ngIf="submitted && f.avalF_valor.errors" class="invalid-feedback">
				<div *ngIf="f.avalF_valor.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field> -->

		<div *ngIf="tipoAval == 1">
			<mat-form-field appearance="outline" *ngIf="!f.aval_omitir.value">
				<mat-label >Teléfono</mat-label>
				<input matInput
					mask="00 0000 0000"
					formControlName="aval_telefono" 
					[value]="datosAval.aval_telefono"
					[ngClass]="{ 'is-invalid': submitted && f.aval_telefono.errors }"
					>
				
			</mat-form-field>
		</div>
		
		<mat-form-field appearance="outline" *ngIf="!f.aval_omitir.value">
			<mat-label>Calle</mat-label>
			<input matInput
				formControlName="aval_calle" 
				[value]="datosAval.aval_calle"
				[ngClass]="{ 'is-invalid': submitted && f.aval_calle.errors }"
				>
			<mat-error *ngIf="submitted && f.aval_calle.errors" class="invalid-feedback">
				<div *ngIf="f.aval_calle.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
		<mat-form-field appearance="outline" *ngIf="!f.aval_omitir.value">
			<mat-label>Numero exterior</mat-label>
			<input matInput
				formControlName="aval_numExterior" 
				[value]="datosAval.aval_numExterior"
				[ngClass]="{ 'is-invalid': submitted && f.aval_numExterior.errors }"
				>
			<mat-error *ngIf="submitted && f.aval_numExterior.errors" class="invalid-feedback">
				<div *ngIf="f.aval_numExterior.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
		<mat-form-field appearance="outline" *ngIf="!f.aval_omitir.value">
			<mat-label>Numero interior</mat-label>
			<input matInput
				formControlName="aval_numInterior" 
				[value]="datosAval.aval_numInterior"
				[ngClass]="{ 'is-invalid': submitted && f.aval_numInterior.errors }"
				>
			<mat-error *ngIf="submitted && f.aval_numInterior.errors" class="invalid-feedback">
				<div *ngIf="f.aval_numInterior.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
		<mat-form-field appearance="outline" *ngIf="!f.aval_omitir.value">
			<mat-label>Codigo postal</mat-label>
			<input matInput
				(input)="BuscarDireccion()"
				formControlName="aval_codigoPostal" 
				[value]="datosAval.aval_codigoPostal"
				[ngClass]="{ 'is-invalid': submitted && f.aval_codigoPostal.errors }"
				>
			<mat-error *ngIf="submitted && f.aval_codigoPostal.errors" class="invalid-feedback">
				<div *ngIf="f.aval_codigoPostal.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
		<div *ngIf="!f.aval_omitir.value">
			<mat-label>Colonia</mat-label>
			<app-select-colonias *ngIf="coloniasDisponibles.length" [colonia]="f.aval_colonia.value" [colonias]="coloniasDisponibles" (onSelected)="setColonia($event)"></app-select-colonias>
			<input *ngIf="!coloniasDisponibles.length"
				formControlName="aval_colonia" 
				[value]="datosAval.aval_colonia"
				[ngClass]="{ 'is-invalid': submitted && f.aval_colonia.errors }"
				>
			<mat-error *ngIf="submitted && f.aval_colonia.errors" class="invalid-feedback">
				<div *ngIf="f.aval_colonia.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>
		<mat-form-field appearance="outline" *ngIf="!f.aval_omitir.value">
			<mat-label>Municipio</mat-label>
			<input matInput
				formControlName="aval_municipio" 
				[value]="datosAval.aval_municipio"
				[ngClass]="{ 'is-invalid': submitted && f.aval_municipio.errors }"
				>
			<mat-error *ngIf="submitted && f.aval_municipio.errors" class="invalid-feedback">
				<div *ngIf="f.aval_municipio.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
		<mat-form-field appearance="outline" *ngIf="!f.aval_omitir.value">
			<mat-label>Ciudad</mat-label>
			<input matInput
				formControlName="aval_ciudad" 
				[value]="datosAval.aval_ciudad"
				[ngClass]="{ 'is-invalid': submitted && f.aval_ciudad.errors }"
				>
			<mat-error *ngIf="submitted && f.aval_ciudad.errors" class="invalid-feedback">
				<div *ngIf="f.aval_ciudad.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
		<mat-form-field appearance="outline" *ngIf="!f.aval_omitir.value">
			<mat-label>Estado</mat-label>
			<input matInput
				formControlName="aval_estado" 
				[value]="datosAval.aval_estado"
				[ngClass]="{ 'is-invalid': submitted && f.aval_estado.errors }"
				>
			<mat-error *ngIf="submitted && f.aval_estado.errors" class="invalid-feedback">
				<div *ngIf="f.aval_estado.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
		
	</div>
</form>

<div class="row-end">
	<div></div>
	<mat-spinner *ngIf="loading" diameter="30"></mat-spinner>
	<!-- <button *ngIf="!loading"  class="save-button" mat-button (click)="onSubmit(1)" style="height: 60px!important; width: 60px!important;">
		<svg style="height: 30px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="48.14" height="48.14" viewBox="0 0 48.14 48.14"><defs><style>.ab{fill:#fff;}</style></defs><path class="ab" d="M8.439,4A3.377,3.377,0,0,0,5,7.439V48.7A3.377,3.377,0,0,0,8.439,52.14H49.7A3.377,3.377,0,0,0,53.14,48.7V14.544a1.039,1.039,0,0,0-.345-.8l-9.4-9.4A1.039,1.039,0,0,0,42.6,4Zm5.731,2.292H41.678V20.047a2.3,2.3,0,0,1-2.292,2.292H16.462a2.3,2.3,0,0,1-2.292-2.292ZM32.508,9.731V18.9h4.585V9.731ZM16.462,30.362H41.678a2.3,2.3,0,0,1,2.292,2.292V49.847h-29.8V32.654A2.3,2.3,0,0,1,16.462,30.362ZM8.439,46.409h2.292V48.7H8.439Zm38.97,0H49.7V48.7H47.409Z" transform="translate(-5 -4)"/></svg>
	</button> -->
	<button *ngIf="!loading"  mat-button (click)="onSubmit(2)">
		Siguiente
		<svg style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><defs><style>.abc{fill:#707070;}</style></defs><path class="abc" d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0ZM22,38V18.7L13.448,27.25l-3.394-3.394L24,9.908,37.948,23.856,34.554,27.25,26,18.7V38Z" transform="translate(48) rotate(90)"/></svg>
	</button>
</div>
