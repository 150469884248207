import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<MessageBoxComponent>,
              @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    console.log("DATA",this.data);
  }

  VerCotizacion() {
    this.dialogRef.close(true);
  }

  Confirm(value) {
    try {
      if (value) { this.data.confirm(); } else { this.data.reject(); }
    } catch (e) { }

    this.dialogRef.close(value);
  }

  public chooseOpt(opt: number): void {
    try {
      this.data.confirmopt(opt);
    } catch (e) {
      console.error('e :', e);
    }

    this.dialogRef.close(true);
  }
}
