<div class="container-pwd">
  <div class="header">
    <img
      class="logo"
      mat-button
      height="50px"
      width="auto"
      [routerLink]="['/dashboard']"
      src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/NewLogo-L4U.png"
      alt="leaseforu"
    />
  </div>
  <div class="container">
    <form
      [formGroup]="changeForm"
      #change="ngForm"
      (ngSubmit)="onSubmit()"
      (keyup)="onKeyup($event)"
    >
      <div class="card-pwd">
        <div>
          <h1>Cambiar contraseña</h1>

          <div class="newInput">
            <mat-form-field appearance="outline">
              <mat-label>Contraseña Actual</mat-label>

              <input
                matInput
                [type]="actualPasswordHide ? 'text' : 'password'"
                formControlName="password"
                [ngClass]="{
                  'invalid-input':
                    (f.password.touched && !f.password.valid) ||
                    user_not_register
                }"
                placeholder="Contraseña actual"
                maxlength="20"
              />
              <!-- <mat-icon *ngIf="(!f.password.valid && (submitted || f.password.touched)) || user_not_register" [ngClass]="{'invalid-input': (!f.password.valid && f.password.touched) || user_not_register || submitted}">clear</mat-icon> -->
              <mat-icon
                matSuffix
                (click)="actualPasswordHide = !actualPasswordHide"
                >{{
                  !actualPasswordHide ? "visibility" : "visibility_off"
                }}</mat-icon
              >

              <mat-error
                *ngIf="!f.password.valid && (submitted || f.password.touched)"
                [ngClass]="{
                  'invalid-input':
                    !f.password.valid && (submitted || f.password.touched)
                }"
                ><img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/warning.svg" /> Contraseña no
                válida, vuelve a intentarlo.</mat-error
              >
              <mat-error
                *ngIf="invalid_password"
                [ngClass]="{ 'invalid-input': invalid_password }"
                ><img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/warning.svg" /> Contraseña no
                válida, vuelve a intentarlo.</mat-error
              >

              <div class="tip" *ngIf="false">
                <svg
                  style="
                    height: 20px;
                    width: 30px;
                    border: 1px solid white;
                    background-color: white;
                    border-radius: 180px;
                    margin-right: 10px;
                  "
                  xmlns="http://www.w3.org/2000/svg"
                  width="16.138"
                  height="16.137"
                  viewBox="0 0 16.138 16.137"
                >
                  <defs>
                    <style>
                      .a {
                        fill: #3f4a57;
                      }
                    </style>
                  </defs>
                  <path
                    class="a"
                    d="M10.069,2a8.069,8.069,0,1,0,8.069,8.069A8.068,8.068,0,0,0,10.069,2Zm.807,12.1H9.262V12.489h1.614Zm0-3.227H9.262V6.034h1.614Z"
                    transform="translate(-2 -2)"
                  />
                </svg>
                <!-- <p>Debe de contener de 8 a 20 caracteres</p> -->
              </div>
            </mat-form-field>
          </div>
          <div class="newInput">
            <mat-form-field appearance="outline">
              <mat-label>Nueva Contraseña</mat-label>

              <input
                matInput
                [type]="newPasswordHide ? 'text' : 'password'"
                formControlName="newPassword"
                [ngClass]="{
                  'invalid-input':
                    (f.newPassword.touched && !f.newPassword.valid) ||
                    user_not_register
                }"
                placeholder="De 8 a 20 caracteres"
                maxlength="20"
              />
              <!-- <mat-icon *ngIf="(!f.newPassword.valid && (submitted || f.newPassword.touched)) || user_not_register" [ngClass]="{'invalid-input': (!f.newPassword.valid && f.newPassword.touched) || user_not_register || submitted}">clear</mat-icon> -->
              <mat-icon
                matSuffix
                (click)="newPasswordHide = !newPasswordHide"
                >{{
                  !newPasswordHide ? "visibility" : "visibility_off"
                }}</mat-icon
              >

              <mat-error
                *ngIf="
                  !f.newPassword.valid && (submitted || f.newPassword.touched)
                "
                [ngClass]="{
                  'invalid-input':
                    !f.newPassword.valid && (submitted || f.newPassword.touched)
                }"
              >
                <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/warning.svg" /> Contraseña no
                válida, vuelve a intentarlo.</mat-error
              >
            </mat-form-field>
          </div>
          <div class="pwd-validation">
            <p >
              <img
                class="key"
                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/key-leaseforu.svg"
              />Cree una nueva contraseña
            </p>
            <p [ngClass]="changeForm.controls['newPassword'].hasError('required') || changeForm.controls['newPassword'].hasError('minlength')  ? 'text-static' : 'text-success'">
              <img
                class="key-leaseforu"
                [src]="changeForm.controls['newPassword'].hasError('required') || changeForm.controls['newPassword'].hasError('minlength') ? 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/circle-leaseforu.svg' : 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/check.svg'"
                
              />Mínimo 8 carácteres
            </p>
            <p [ngClass]="changeForm.controls['newPassword'].hasError('required') || changeForm.controls['newPassword'].hasError('hasCapitalCase')  ? 'text-static' : 'text-success'">
              <img
                class="key-leaseforu"
                [src]="changeForm.controls['newPassword'].hasError('required') || changeForm.controls['newPassword'].hasError('hasCapitalCase') ? 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/circle-leaseforu.svg' : 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/check.svg'"
              />Una mayúscula
            </p>
            <p [ngClass]="changeForm.controls['newPassword'].hasError('required') || changeForm.controls['newPassword'].hasError('hasSmallCase')  ? 'text-static' : 'text-success'">
              <img
                class="key-leaseforu"
                [src]="changeForm.controls['newPassword'].hasError('required') || changeForm.controls['newPassword'].hasError('hasSmallCase') ? 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/circle-leaseforu.svg' : 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/check.svg'"
              />Una minúscula
            </p>
            <p [ngClass]="changeForm.controls['newPassword'].hasError('required') || changeForm.controls['newPassword'].hasError('hasNumber')  ? 'text-static' : 'text-success'">
              <img
                class="key-leaseforu"
                [src]="changeForm.controls['newPassword'].hasError('required') || changeForm.controls['newPassword'].hasError('hasNumber') ? 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/circle-leaseforu.svg' : 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/check.svg'"
              />Un número
            </p>
            <p [ngClass]="changeForm.controls['newPassword'].hasError('required') || changeForm.controls['newPassword'].hasError('hasSpecialCharacter')  ? 'text-static' : 'text-success'">
              <img
                class="key-leaseforu"
                [src]="changeForm.controls['newPassword'].hasError('required') || changeForm.controls['newPassword'].hasError('hasSpecialCharacter') ? 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/circle-leaseforu.svg' : 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/check.svg'"
              />Un carácter especial
            </p>
          </div>
          <div class="newInput">
            <mat-form-field appearance="outline">
              <mat-label>Confirmar Contraseña</mat-label>

              <input
                matInput
                [type]="confirmPasswordHide ? 'text' : 'password'"
                formControlName="confirmPassword"
                [ngClass]="{
                  'invalid-input':
                    (f.confirmPassword.touched && !f.confirmPassword.valid) ||
                    user_not_register
                }"
                placeholder="Repetir contraseña"
                maxlength="20"
              />
              <!-- <mat-icon *ngIf="(!f.confirmPassword.valid && (submitted || f.confirmPassword.touched)) || user_not_register" [ngClass]="{'invalid-input': (!f.confirmPassword.valid && f.confirmPassword.touched) || user_not_register || submitted}">clear</mat-icon> -->
              <mat-icon
                matSuffix
                (click)="confirmPasswordHide = !confirmPasswordHide"
                >{{
                  !confirmPasswordHide ? "visibility" : "visibility_off"
                }}</mat-icon
              >

              <mat-error
                *ngIf="
                  !f.confirmPassword.valid &&
                  (submitted || f.confirmPassword.touched)
                "
                [ngClass]="{
                  'invalid-input':
                    !f.confirmPassword.valid &&
                    (submitted || f.confirmPassword.touched)
                }"
              >
                <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/warning.svg" /> Contraseñas no
                coinciden, vuelve a intentarlo.</mat-error
              >
            </mat-form-field>
          </div>
          <div class="buttons">
            <button
              mat-raised-button
              color="primary"
              class="btn-primary-new"
              [disabled]="!change.form.valid"
              (click)="change.ngSubmit.emit()"
            >
              <span
                ><mat-progress-spinner
                  *ngIf="loading"
                  mode="indeterminate"
                  diameter="25"
                ></mat-progress-spinner
              ></span>
              <span *ngIf="!loading">Crear Contraseña </span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="container-footer">
    <div class="text-footer">
    <app-socials-footer style="width: 100%;"></app-socials-footer>
    </div>
  </div>
</div>
