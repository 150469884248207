import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-advanced-filters',
  templateUrl: './advanced-filters.component.html',
  styleUrls: ['./advanced-filters.component.scss']
})
export class AdvancedFiltersComponent implements OnInit {
  @Output() close = new EventEmitter

  filter_fields = [
    {
      type: 'yesno',
      title: 'Información completa (IC)',
      name: 'informacion_completa',
    },
    {
      type: 'date',
      name: 'ic_fecha_modificacion',
    },
    {
      type: 'input',
      placeholder: 'Modificado por',
      name: 'ic_usuario_modifico',
    },
    {
      type: 'yesno',
      title: 'Comité de crédito (CC)',
      name: 'comite_credito',
    },
    {
      type: 'date',
      name: 'cc_fecha_modificacion',
    },
    {
      type: 'input',
      placeholder: 'Modificado por',
      name: 'cc_usuario_modifico',
    },
    {
      type: 'yesno',
      title: 'Condicionada (CN)',
      name: 'condicionado'},
    {
      type: 'date',
      name: 'cn_fecha_modificacion'},
    {
      type: 'input',
      placeholder: 'Modificado por',
      name: 'cn_usuario_modifico'},
    {
      title: 'Información adicional (IA)',
      type: 'yesno',
      name: 'informacion_adicional'},
    {
      type: 'date',
      name: 'ia_fecha_modificacion'},
    {
      type: 'input',
      placeholder: 'Modificado por',
      name: 'ia_usuario_modifico'},
    {
      title: 'Operación liberada (OL)',
      type: 'yesno',
      name: 'operacion_liberada'},
    {
      type: 'date',
      name: 'ol_fecha_modificacion'},
    {
      type: 'input',
      placeholder: 'Modificado por',
      name: 'ol_usuario_modifico'},
    {
      title: 'Rechazada (RC)',
      type: 'yesno',
      name: 'rechazada'},
    {
      type: 'date',
      name: 'rc_fecha_modificacion'},
    {
      type: 'input',
      placeholder: 'Modificado por',
      name: 'rc_usuario_modifico'},

      {
        title: 'Factura recibida',
        type: 'yesno',
        name:'factura_recibida'
      },
      {
        title: 'Contrato realizado',
        type: 'yesno',
        name:'contrato_realizado'
      },
      {
        title: 'Control cambios',
        type: 'yesno',
        name:'control_cambios'
      },
      {
        title: 'Contrato enviado',
        type: 'yesno',
        name:'contrato_enviado'
      },
      {
        title: 'Contrato regresado',
        type: 'yesno',
        name:'contrato_regresado'
      },
      {
        title: 're-firma contratos',
        type: 'yesno',
        name:'re_firma_contratos'
      },
      {
        title: 'Contrato activado',
        type: 'yesno',
        name:'contrato_activado'
      },
      {
        title: 'Pago inicial recibido',
        type: 'yesno',
        name:'pago_inicial_recibido'
      },
      {
        title: 'Proceso comercial finalizado',
        type: 'yesno',
        name:'proceso_comercial_finalizado'
      },
      {
        title: 'Cancelado (CA)',
        type: 'yesno',
        name:'cancelado'
      },
      {
        type: 'date',
        name:'ca_fecha_modificacion'
      },
      {
        type: 'input',
        placeholder: 'Modificado por',
        name:'ca_usuario_modifico'
      },
      {
        title: 'Pago realizado (PR)',
        type: 'yesno',
        name:'pago_realizado'
      },
      {
        type: 'date',
        name:'pr_fecha_modificacion'
      },
      {
        type: 'input',
        placeholder: 'Modificado por',
        name:'pr_usuario_modifico'
      },
      {
        title: 'Proyeccion pago',
        type: 'yesno',
        name:'proyeccion_pago'
      },
  ]

  constructor() { }

  ngOnInit(): void {
  }

  Close() {
    this.close.emit(true)
  }

}
