<div class="centro-mensajeria">
    <div class="messages">
        <div *ngFor="let mensaje of mensajes; let i = index">
            <span *ngIf="i == 0 || mensaje.date_comment != mensajes[i -1].date_comment"
            class="date-comment">
                {{ mensaje.date_comment}}
            </span>
            <!-- {{date_comment}} -->
            <app-centro-mensajeria-item [message]="mensaje" (onNuevoMensaje)="ScrollDown()"></app-centro-mensajeria-item>
        </div>
    </div>
    <div class="send-message">
        <textarea maxlength="145" cols="30" [rows]="nuevo_mensaje.length < 35 ? 1 : 2" [(ngModel)]="nuevo_mensaje"></textarea>
        <div class="send-button" [ngClass]="{'ready': nuevo_mensaje.length > 0}"
            (click)="CrearComentario()"
        >
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.66671 9.33333L14 2M6.66671 9.33333L9.00005 14C9.0293 14.0638 9.07626 14.1179 9.13535 14.1558C9.19444 14.1938 9.26317 14.2139 9.33338 14.2139C9.40359 14.2139 9.47233 14.1938 9.53141 14.1558C9.5905 14.1179 9.63746 14.0638 9.66671 14L14 2M6.66671 9.33333L2.00005 7C1.93622 6.97075 1.88213 6.92379 1.84421 6.8647C1.80629 6.80561 1.78613 6.73688 1.78613 6.66667C1.78613 6.59646 1.80629 6.52772 1.84421 6.46863C1.88213 6.40954 1.93622 6.36258 2.00005 6.33333L14 2" stroke="#C0C8CE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
    </div>
    <audio id="new_comment_sound" src="/assets/sounds/new_comment.mp3"></audio>
</div>