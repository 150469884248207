import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { MustMatch } from '../../_helpers/must-match.validator';

import { AlertService, UserService, AuthenticationService } from '../../_services';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';

@Component({
    selector: 'register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})


export class RegisterComponent implements OnInit {
    registerForm: FormGroup;
    loading = false;
    submitted = false;
    show_password = false;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private alertService: AlertService,
        private dialog: MatDialog
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.pattern(/^([a-zA-Z]|[0-9]+[a-zA-Z]+)([a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~])*@[a-zA-Z0-9\-]+\.[a-zA-Z]{2,}/)]],
            password: ['', [Validators.required, Validators.minLength(8),  Validators.maxLength(20)]],
            // password: ['', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&#.])[A-Za-z0-9$@$!%*?&#.].{7,19}$')]],
            place: ['', [Validators.required, ]]
        });

        this.registerForm.get('place').setValue('consultorio');
    }

    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }

    onSubmit() {
        this.submitted = true;
        console.log('submitting');

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }

        const objUser = Object.assign({}, this.registerForm.value);
        delete objUser.confirmPassword;

        this.loading = true;
        this.userService.register(objUser)
            .pipe(first())
            .subscribe(
                ({status}) => {
                    this.loading = false;
                    if ( status === 203 ) {
                        this.dialog.open(MessageBoxComponent, {
                            width: '500px',
                            data: {
                                text: 'Este correo ya',
                                text2: 'ha sido registrado',
                                type: 'error',
                                icon: 'error'
                            }
                        });
                    } else if ( status === 220 ) {
                        this.dialog.open(MessageBoxComponent, {
                            width: '500px',
                            data: {
                                text: 'Acción',
                                text2: 'requiere cotización',
                                text3: 'Aún no has activado tu cuenta, revisa tu correo electrónico para realizar la activación',
                                type: 'error',
                                icon: 'error'
                            }
                        });
                    } else if ( status === 200 ) {
                        const register = this.dialog.open(MessageBoxComponent, {
                            width: '500px',
                            data: {
                                text: 'Éxito',
                                text2: 'Se ha registrado tu usuario',
                                type: 'success',
                                icon: 'success'
                            }
                        });
                        register.afterClosed().subscribe( () => this.router.navigate(['/login']) );
                    }
                },
                error => {
                    this.dialog.open(MessageBoxComponent, {
                        width: '500px',
                        data: {
                            text: 'Error de conexiòn',
                            type: 'error',
                            icon: 'error'
                        }
                    });
                    this.loading = false;
                });
    }
    onKeyup({keyCode}) {
        if ( keyCode === 13 ) { this.onSubmit() }

    }
}
