import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, UserService } from 'src/app/_services';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';

@Component({
  selector: 'app-validacion-identidad',
  templateUrl: './validacion-identidad.component.html',
  styleUrls: ['./validacion-identidad.component.scss']
})
export class ValidacionIdentidadComponent implements OnInit {
  folio = null
  perfil = null
  submit = false

  id_cliente = null
  id_mobile = true
  url = null

  comercial_validacion = null
  comite_validacion = null

  comentarios_comercial = ''
  comentarios_comite = ''

  disabled_comercial = false

  tipo_documento = null
  validacion:any = {}

  url_rostro:any = {}
  url_ine_front:any = {}
  url_ine_back:any = {}

  match_face = 0
  img_rostro = 0
  // {
  //   ine: {
  //     nombre: "Carlos",
  //     apellido_paterno: "Dominguez",
  //     apellido_materno: "Rojas",
  //     cic: "1231231231231",
  //     expiracion: "2025-12-31",
  //     fecha_creacion: "1998-11-06",
  //     clave_elector: "jk2l34j32lk42",
  //     match: "0.99"
  //   },
  //   docs: [
  //     {url: 'https://static.api.leaseforu.com/documentos/4ea5c508a6566e76240543f8feb06fd457777be39549c4016436afda65d2330e/folio_261825/undefined_1592865349054.pdf'},
  //     {url: 'https://static.api.leaseforu.com/documentos/4ea5c508a6566e76240543f8feb06fd457777be39549c4016436afda65d2330e/folio_261825/undefined_1592865349061.pdf'},
  //     {url: 'https://static.api.leaseforu.com/documentos/4ea5c508a6566e76240543f8feb06fd457777be39549c4016436afda65d2330e/folio_261825/undefined_1592865349112.pdf'}
  //   ]
  // }

  constructor(
    private router:Router,
    private authenticationService:AuthenticationService,
    private userService:UserService,
    private dialog:MatDialog) { }

  ngOnInit(): void {
    let usuario = JSON.parse(localStorage.getItem('currentUser'));
    const { id_cliente, folio } = JSON.parse(localStorage.getItem('client_details'))
    if( usuario.perfil == 3 || usuario.perfil == 4 || usuario.perfil == 10 || usuario.perfil == 15) this.url = JSON.parse(localStorage.getItem('documento')).url
    console.log(this.url)
    if( this.url ) this.id_mobile = false

    this.folio = folio
    this.perfil = usuario.perfil;
    this.id_cliente = id_cliente
    this.tipo_documento = Number(localStorage.getItem('tipo_documento'))
console.log(this.id_mobile);

    if( this.id_mobile )
    this.userService.DatosValidacion(id_cliente, folio).subscribe( res => {
      console.log("Validacion: ", res);
      this.validacion = res.ine ? res : {ine:{}, docs: [{url: null},{url: null},{url: null}]}
      this.match_face = this.validacion.ine.match_face || 0
      
      const doc_identidad = res.docs.filter( doc => doc.tipo == 3 )[0] || {url: ''}
      
      this.url_rostro = res.docs.filter( doc => doc.tipo == 15 )[0] || {url: ''}
      this.url_ine_front = res.docs.filter( doc => doc.tipo == 13 )[0] || {url: ''}
      this.url_ine_back = res.docs.filter( doc => doc.tipo == 14 )[0] || {url: ''}

      console.log("Doc: ", this.url_rostro);
      
      if( doc_identidad.url ) {
        this.id_mobile = false
        this.url = doc_identidad.url
      }

      if( res.validacion ){
        this.disabled_comercial = true
        this.comentarios_comercial = res.validacion.comercial_comentarios
        this.comercial_validacion = res.validacion.comercial_validacion
      }

      // if( !res.ine && !doc_identidad.url ){
      //   this.dialog.open(MessageBoxComponent, {
      //     width: "500px",
      //     data:{
      //       text: "Validación no fue exitosa",
      //       text3: "Favor de validar la identidad del cliente manualmente",
      //       type: "error",
      //       icon: "error"
      //     }
      //   })
      // }
    })
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  ValidarComercial(value){
    if( this.perfil == 4 || this.perfil == 15 ) this.comercial_validacion = value
  }

  ValidarComite(value){
    if( this.perfil == 4 ) this.comite_validacion = value
  }

  NextPage(){
    if( !this.userService.alertService.isOnline() ) return 
    this.comentarios_comercial = this.comentarios_comercial.trim()
    this.comentarios_comite = this.comentarios_comite.trim()

    this.submit = true

    if( this.perfil == 4 && this.comercial_validacion != null && this.comentarios_comercial.length || this.perfil == 15 && this.comercial_validacion != null && this.comentarios_comercial.length){
      this.userService.ValidacionComercial(this.folio, this.tipo_documento, this.comercial_validacion, this.comentarios_comercial)
      .subscribe( res => {
        const dialogref = this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text: "Éxito",
            text2: "Se ha validado la identidad",
            type: "success",
            icon: "success"
          }
        })
        dialogref.afterClosed().subscribe(_ => this.router.navigate(['dashboard','cliente',this.folio,'carga_documentos']))
      })
    }

    if( this.perfil == 4 && this.comite_validacion != null && this.comentarios_comite.length ){
      this.userService.ValidacionComite(this.folio, this.comite_validacion, this.comentarios_comite)
      .subscribe( async res => {
        this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text: "Éxito",
            text2: "Se ha validado la identidad",
            type: "success",
            icon: "success"
          }
        }).afterClosed().subscribe(async ()=>{
          // await this.userService.changeStatus(this.folio, 4, 1).toPromise()
          this.router.navigate(['dashboard','comite',this.folio])
        })
        // await this.userService.getStatus(this.id_cliente, this.folio)
      })
    }
  }
}
