import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService, SolicitudService } from './_services';
import { User } from './_models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
    title = 'lease for u';
    currentUser: User;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private solicitudService: SolicitudService,
    ) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);

        const _tramites = localStorage.getItem('tramites')
        const _tramitesOrigen = localStorage.getItem('tramitesOrigen')

        if (_tramites && _tramitesOrigen) {
            this.solicitudService.GuardarTramitesOrigen(JSON.parse(_tramitesOrigen), JSON.parse(_tramites))
        }
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }
}