<div class="container">
  <h3>Folio {{ folio }}</h3>

    <!-- <app-btn-actualizar-info style="float: right;"></app-btn-actualizar-info> -->
    <div class="row">
      <div class="col">
        <span class="circle left">1</span>
      </div>
    </div>
    <div class="row">
      <div class="col-2">
        <div class="input">
          <label>Asunto</label>
          <input matInput [(ngModel)]="AnalisisPM.minuta.numero_comite" />
        </div>
      </div>

      <div class="col">
        <div class="input">
          <label>Fecha</label>
          <input type="date" matInput [(ngModel)]="AnalisisPM.minuta.fecha_comite" />
        </div>
      </div>

      <div class="col">
        <div class="input">
          <label>Hora</label>
          <input type="time" matInput [(ngModel)]="AnalisisPM.minuta.hora_comite" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <div class="input">
          <label>Ubicación</label>
          <input matInput [(ngModel)]="AnalisisPM.minuta.ubicacion" readonly />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b>Participante</b>
        <mat-error *ngIf="submit && !guardar">Campo obligatorio</mat-error>
      </div>
    </div>

    <div class="row">
      <div class="col-2">
        <span class="text-blue">Participante 1</span>
        <div class="row" style="margin-bottom: 0px !important">
          <div class="col-3">
            <div class="input">
              <label>Nombre del participante</label>
              <app-selector-participantes
                (onSelect)="ParticipanteSeleccionado($event, 0)"
              ></app-selector-participantes>
              <!-- <input readonly type="text" [value]="Participantes[0].nombre"> -->
            </div>
          </div>
          <div class="col">
            <div class="input">
              <label>Iniciales</label>
              <input
                readonly
                type="text"
                [value]="Participantes[0].iniciales"
              />
            </div>
          </div>
        </div>
        <div class="input">
          <label>Puesto</label>
          <input readonly type="text" [value]="Participantes[0].puesto" />
        </div>
        <mat-checkbox
          [(ngModel)]="Participantes[0].asistencia"
          [checked]="Participantes[0].asistencia"
          >Asistencia del Participante</mat-checkbox
        >
      </div>

      <div class="col-2">
        <span class="text-blue">Participante 2</span>
        <div class="row" style="margin-bottom: 0px !important">
          <div class="col-3">
            <div class="input">
              <label>Nombre del participante</label>
              <app-selector-participantes
                (onSelect)="ParticipanteSeleccionado($event, 1)"
              ></app-selector-participantes>
              <!-- <input readonly type="text" [value]="Participantes[1].nombre"> -->
            </div>
          </div>
          <div class="col">
            <div class="input">
              <label>Iniciales</label>
              <input
                readonly
                type="text"
                [value]="Participantes[1].iniciales"
              />
            </div>
          </div>
        </div>
        <div class="input">
          <label>Puesto</label>
          <input readonly type="text" [value]="Participantes[1].puesto" />
        </div>
        <mat-checkbox
          [(ngModel)]="Participantes[1].asistencia"
          [checked]="Participantes[1].asistencia"
          >Asistencia del Participante</mat-checkbox
        >
      </div>
    </div>

    <div class="row">
      <div class="col-2">
        <span class="text-blue">Participante 3</span>
        <div class="row" style="margin-bottom: 0px !important">
          <div class="col-3">
            <div class="input">
              <label>Nombre del participante</label>
              <app-selector-participantes
                (onSelect)="ParticipanteSeleccionado($event, 2)"
              ></app-selector-participantes>
              <!-- <input readonly type="text" [value]="Participantes[2].nombre"> -->
            </div>
          </div>
          <div class="col">
            <div class="input">
              <label>Iniciales</label>
              <input
                readonly
                type="text"
                [value]="Participantes[2].iniciales"
              />
            </div>
          </div>
        </div>
        <div class="input">
          <label>Puesto</label>
          <input readonly type="text" [value]="Participantes[2].puesto" />
        </div>
        <mat-checkbox
          [(ngModel)]="Participantes[2].asistencia"
          [checked]="Participantes[2].asistencia"
          >Asistencia del Participante</mat-checkbox
        >
      </div>

      <div class="col-2">
        <span class="text-blue">Participante 4</span>
        <div class="row" style="margin-bottom: 0px !important">
          <div class="col-3">
            <div class="input">
              <label>Nombre del participante</label>
              <app-selector-participantes
                (onSelect)="ParticipanteSeleccionado($event, 3)"
              ></app-selector-participantes>
              <!-- <input readonly type="text" [value]="Participantes[3].nombre"> -->
            </div>
          </div>
          <div class="col">
            <div class="input">
              <label>Iniciales</label>
              <input
                readonly
                type="text"
                [value]="Participantes[3].iniciales"
              />
            </div>
          </div>
        </div>
        <div class="input">
          <label>Puesto</label>
          <input readonly type="text" [value]="Participantes[3].puesto" />
        </div>
        <mat-checkbox
          [(ngModel)]="Participantes[3].asistencia"
          [checked]="Participantes[3].asistencia"
          >Asistencia del Participante</mat-checkbox
        >
      </div>
    </div>

    <div class="row">
      <mat-checkbox [(ngModel)]="adicional" [checked]="adicional">
        Agregar participante adicional</mat-checkbox
      >
    </div>

    <div class="row" *ngIf="adicional">
      <div class="col-2">
        <span class="text-blue">Participante adicional</span>
        <div class="row" style="margin-bottom: 0px !important">
          <div class="col-3">
            <div class="input">
              <label>Nombre del participante</label>
              <app-selector-participantes
                (onSelect)="ParticipanteSeleccionado($event, 4)"
              ></app-selector-participantes>
              <!-- <input readonly type="text" [value]="Participantes[3].nombre"> -->
            </div>
          </div>
          <div class="col">
            <div class="input">
              <label>Iniciales</label>
              <input
                readonly
                type="text"
                [value]="Participantes[4].iniciales"
              />
            </div>
          </div>
        </div>
        <div class="input">
          <label>Puesto</label>
          <input readonly type="text" [value]="Participantes[4].puesto" />
        </div>
        <mat-checkbox
          [(ngModel)]="Participantes[4].asistencia"
          [checked]="Participantes[4].asistencia"
          >Asistencia del Participante</mat-checkbox
        >
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <p style="text-align: center">
          Orden del día<br />
          Se presenta a Comité de Crédito<br />
          la siguiente solicitud para su revisión
        </p>
      </div>
    </div>

    <div class="row">
      <hr />
    </div>

    <div class="row">
      <div class="col-2 col-row">
        <span class="circle left">2</span>
      </div>
      <div class="col-2 col-row">
        <span class="circle left">2</span>
        <p>
          <b>Puntos principales<br />revisados</b>
        </p>
      </div>
    </div>

    <div style="display: grid; grid-template-columns: 48% 48%; gap: 2%;">
      <div>
        <div class="input">
          <label>Solicitante</label>
          <input readonly matInput [(ngModel)]="AnalisisPM.solicitante" />
        </div>

        <div class="input">
          <label>Cotización</label>
          <input readonly matInput [(ngModel)]="AnalisisPM.folio" />
        </div>

        <div class="input">
          <label>Activo</label>
          <input
            readonly
            matInput
            [(ngModel)]="AnalisisPM.caracteristica_activo.activo"
          />
        </div>

        <div class="input">
          <label>Valor del activo</label>
          <input
            readonly
            matInput
            mask="separator.2"
            thousandSeparator=","
            prefix="$ "
            [(ngModel)]="AnalisisPM.caracteristica_activo.monto_activo"
          />
        </div>

        <div class="input">
          <label>Tipo de financiamiento</label>
          <input
            matInput
            [(ngModel)]="AnalisisPM.caracteristica_activo.tipo_financiamiento"
          />
        </div>

        <div class="input">
          <label for="">Plazo</label>
          <input
            readonly
            matInput
            mask="000"
            suffix=" meses"
            [(ngModel)]="AnalisisPM.cotizacion.plazo"
          />
        </div>
      </div>

      <div>
        <div class="input">
          <label>Puntos principales revisados</label>
          <textarea
            
            [(ngModel)]="AnalisisPM.minuta.puntos_principales_revisados"
          ></textarea>
        </div>
      </div>
    </div>

      <div>
        <div>
          <span class="circle">3</span>
          <p><b>Estatus</b></p>
        </div>
        <div></div>
      </div>
      <div></div>
      <div>
        <div>
          <div class="input">
            <label><br /></label>
            <mat-select
              [(ngModel)]="AnalisisPM.id_resolucion_comite"
            >
              <mat-option [value]="1">Aprobada</mat-option>
              <mat-option [value]="2">Aprobada Condicionalmente</mat-option>
              <mat-option [value]="3">Información Adicional</mat-option>
              <mat-option [value]="4">Rechazada</mat-option>
            </mat-select>
          </div>
        </div>

        <div>
          <div class="input">
            <label>Comentarios adicionales</label>
            <textarea
              
              placeholder="Comentarios"
              [(ngModel)]="AnalisisPM.minuta.comentarios_adicionales"
            ></textarea>
          </div>
        </div>
      </div>
    
     

      
    
    <div class="row">
        <div class="col-row">
          <span class="circle">4</span>
          <p>
            <b>Firman la presente los participantes</b>
          </p>
        </div>
    </div>
    <div
      style="text-align: center; justify-content: center; display: flex; gap: 2%;"
    >
      <div class="col">
        <div class="input" style="text-align: center">
          <svg
            style="margin: 0 auto; height: 20px; width: auto"
            xmlns="http://www.w3.org/2000/svg"
            width="43"
            height="43"
            viewBox="0 0 43 43"
          >
            <defs>
              <style>
                .icon {
                  fill: #919191;
                }
              </style>
            </defs>
            <path
              class="icon"
              d="M23.5,2.008A21.5,21.5,0,1,0,45,23.508,21.514,21.514,0,0,0,23.5,2.008Zm0,1.87A19.628,19.628,0,0,1,37.511,37.254,20.987,20.987,0,0,0,31.95,34.74a15.012,15.012,0,0,1-2.541-1.1,2.571,2.571,0,0,1-1.156-1.2c-.086-1.072-.069-1.935-.062-2.954.113-.113.259-.192.374-.329a8.923,8.923,0,0,0,.816-1.154,8.271,8.271,0,0,0,.971-2.614c.283-.144.586-.176.816-.469a4.651,4.651,0,0,0,.84-2.368,2.665,2.665,0,0,0-.579-2.08,16.147,16.147,0,0,0,.761-5.8,6.841,6.841,0,0,0-1.159-3.526,5.132,5.132,0,0,0-3.368-1.961A5.466,5.466,0,0,0,23.42,7.609H23.4c-4.022.074-6.617,1.739-7.728,4.278-1.057,2.414-.829,5.42-.071,8.55a2.654,2.654,0,0,0-.612,2.118,4.659,4.659,0,0,0,.84,2.368c.23.294.533.325.816.469A8.269,8.269,0,0,0,17.619,28a8.907,8.907,0,0,0,.816,1.154c.116.137.261.218.374.331.006,1.018.023,1.881-.062,2.954a2.583,2.583,0,0,1-1.154,1.2,15,15,0,0,1-2.538,1.1,21.549,21.549,0,0,0-5.563,2.512A19.628,19.628,0,0,1,23.5,3.877Zm-.073,5.6c1.724,0,2.808.512,3.106,1.033l.228.4.455.062a3.113,3.113,0,0,1,2.3,1.269,5.1,5.1,0,0,1,.811,2.576,15.967,15.967,0,0,1-.794,5.558l-.235.707.637.387a.954.954,0,0,1,.217.86,2.957,2.957,0,0,1-.455,1.437c-.142.181-.2.158-.188.157l-.77.064-.082.769a6.3,6.3,0,0,1-.876,2.282,7.237,7.237,0,0,1-.643.911c-.194.229-.4.371-.323.33l-.5.261v.566a35.939,35.939,0,0,0,.08,3.692l.011.124.044.117a4.441,4.441,0,0,0,2,2.206A16.112,16.112,0,0,0,31.323,36.5,23.379,23.379,0,0,1,36.1,38.561a19.631,19.631,0,0,1-25.2,0,24.182,24.182,0,0,1,4.783-2.059,16.091,16.091,0,0,0,2.87-1.26,4.454,4.454,0,0,0,1.994-2.2l.044-.117.011-.124a36.249,36.249,0,0,0,.08-3.692v-.566l-.5-.263c.078.041-.129-.1-.323-.33a7.2,7.2,0,0,1-.643-.909,6.311,6.311,0,0,1-.876-2.282l-.082-.769-.77-.064c.014,0-.046.024-.188-.157a2.962,2.962,0,0,1-.455-1.437.969.969,0,0,1,.217-.86l.593-.36-.173-.674c-.8-3.1-.926-5.912-.1-7.8S19.87,9.548,23.427,9.481Z"
              transform="translate(-2 -2.008)"
            />
          </svg>
          <h4 style="font-size: 0.8rem">Ariel Finsod Leff</h4>
          <span style="font-size: 0.6rem">Director General</span>
        </div>
      </div>
      <div class="col">
        <div class="input" style="text-align: center">
          <svg
            style="margin: 0 auto; height: 20px; width: auto"
            xmlns="http://www.w3.org/2000/svg"
            width="43"
            height="43"
            viewBox="0 0 43 43"
          >
            <defs>
              <style>
                .icon {
                  fill: #919191;
                }
              </style>
            </defs>
            <path
              class="icon"
              d="M23.5,2.008A21.5,21.5,0,1,0,45,23.508,21.514,21.514,0,0,0,23.5,2.008Zm0,1.87A19.628,19.628,0,0,1,37.511,37.254,20.987,20.987,0,0,0,31.95,34.74a15.012,15.012,0,0,1-2.541-1.1,2.571,2.571,0,0,1-1.156-1.2c-.086-1.072-.069-1.935-.062-2.954.113-.113.259-.192.374-.329a8.923,8.923,0,0,0,.816-1.154,8.271,8.271,0,0,0,.971-2.614c.283-.144.586-.176.816-.469a4.651,4.651,0,0,0,.84-2.368,2.665,2.665,0,0,0-.579-2.08,16.147,16.147,0,0,0,.761-5.8,6.841,6.841,0,0,0-1.159-3.526,5.132,5.132,0,0,0-3.368-1.961A5.466,5.466,0,0,0,23.42,7.609H23.4c-4.022.074-6.617,1.739-7.728,4.278-1.057,2.414-.829,5.42-.071,8.55a2.654,2.654,0,0,0-.612,2.118,4.659,4.659,0,0,0,.84,2.368c.23.294.533.325.816.469A8.269,8.269,0,0,0,17.619,28a8.907,8.907,0,0,0,.816,1.154c.116.137.261.218.374.331.006,1.018.023,1.881-.062,2.954a2.583,2.583,0,0,1-1.154,1.2,15,15,0,0,1-2.538,1.1,21.549,21.549,0,0,0-5.563,2.512A19.628,19.628,0,0,1,23.5,3.877Zm-.073,5.6c1.724,0,2.808.512,3.106,1.033l.228.4.455.062a3.113,3.113,0,0,1,2.3,1.269,5.1,5.1,0,0,1,.811,2.576,15.967,15.967,0,0,1-.794,5.558l-.235.707.637.387a.954.954,0,0,1,.217.86,2.957,2.957,0,0,1-.455,1.437c-.142.181-.2.158-.188.157l-.77.064-.082.769a6.3,6.3,0,0,1-.876,2.282,7.237,7.237,0,0,1-.643.911c-.194.229-.4.371-.323.33l-.5.261v.566a35.939,35.939,0,0,0,.08,3.692l.011.124.044.117a4.441,4.441,0,0,0,2,2.206A16.112,16.112,0,0,0,31.323,36.5,23.379,23.379,0,0,1,36.1,38.561a19.631,19.631,0,0,1-25.2,0,24.182,24.182,0,0,1,4.783-2.059,16.091,16.091,0,0,0,2.87-1.26,4.454,4.454,0,0,0,1.994-2.2l.044-.117.011-.124a36.249,36.249,0,0,0,.08-3.692v-.566l-.5-.263c.078.041-.129-.1-.323-.33a7.2,7.2,0,0,1-.643-.909,6.311,6.311,0,0,1-.876-2.282l-.082-.769-.77-.064c.014,0-.046.024-.188-.157a2.962,2.962,0,0,1-.455-1.437.969.969,0,0,1,.217-.86l.593-.36-.173-.674c-.8-3.1-.926-5.912-.1-7.8S19.87,9.548,23.427,9.481Z"
              transform="translate(-2 -2.008)"
            />
          </svg>
          <h4 style="font-size: 0.8rem">Sebastian Ochoteco</h4>
          <span style="font-size: 0.6rem">Director de Finanzas</span>
        </div>
      </div>
      <div class="col">
        <div class="input" style="text-align: center">
          <svg
            style="margin: 0 auto; height: 20px; width: auto"
            xmlns="http://www.w3.org/2000/svg"
            width="43"
            height="43"
            viewBox="0 0 43 43"
          >
            <defs>
              <style>
                .icon {
                  fill: #919191;
                }
              </style>
            </defs>
            <path
              class="icon"
              d="M23.5,2.008A21.5,21.5,0,1,0,45,23.508,21.514,21.514,0,0,0,23.5,2.008Zm0,1.87A19.628,19.628,0,0,1,37.511,37.254,20.987,20.987,0,0,0,31.95,34.74a15.012,15.012,0,0,1-2.541-1.1,2.571,2.571,0,0,1-1.156-1.2c-.086-1.072-.069-1.935-.062-2.954.113-.113.259-.192.374-.329a8.923,8.923,0,0,0,.816-1.154,8.271,8.271,0,0,0,.971-2.614c.283-.144.586-.176.816-.469a4.651,4.651,0,0,0,.84-2.368,2.665,2.665,0,0,0-.579-2.08,16.147,16.147,0,0,0,.761-5.8,6.841,6.841,0,0,0-1.159-3.526,5.132,5.132,0,0,0-3.368-1.961A5.466,5.466,0,0,0,23.42,7.609H23.4c-4.022.074-6.617,1.739-7.728,4.278-1.057,2.414-.829,5.42-.071,8.55a2.654,2.654,0,0,0-.612,2.118,4.659,4.659,0,0,0,.84,2.368c.23.294.533.325.816.469A8.269,8.269,0,0,0,17.619,28a8.907,8.907,0,0,0,.816,1.154c.116.137.261.218.374.331.006,1.018.023,1.881-.062,2.954a2.583,2.583,0,0,1-1.154,1.2,15,15,0,0,1-2.538,1.1,21.549,21.549,0,0,0-5.563,2.512A19.628,19.628,0,0,1,23.5,3.877Zm-.073,5.6c1.724,0,2.808.512,3.106,1.033l.228.4.455.062a3.113,3.113,0,0,1,2.3,1.269,5.1,5.1,0,0,1,.811,2.576,15.967,15.967,0,0,1-.794,5.558l-.235.707.637.387a.954.954,0,0,1,.217.86,2.957,2.957,0,0,1-.455,1.437c-.142.181-.2.158-.188.157l-.77.064-.082.769a6.3,6.3,0,0,1-.876,2.282,7.237,7.237,0,0,1-.643.911c-.194.229-.4.371-.323.33l-.5.261v.566a35.939,35.939,0,0,0,.08,3.692l.011.124.044.117a4.441,4.441,0,0,0,2,2.206A16.112,16.112,0,0,0,31.323,36.5,23.379,23.379,0,0,1,36.1,38.561a19.631,19.631,0,0,1-25.2,0,24.182,24.182,0,0,1,4.783-2.059,16.091,16.091,0,0,0,2.87-1.26,4.454,4.454,0,0,0,1.994-2.2l.044-.117.011-.124a36.249,36.249,0,0,0,.08-3.692v-.566l-.5-.263c.078.041-.129-.1-.323-.33a7.2,7.2,0,0,1-.643-.909,6.311,6.311,0,0,1-.876-2.282l-.082-.769-.77-.064c.014,0-.046.024-.188-.157a2.962,2.962,0,0,1-.455-1.437.969.969,0,0,1,.217-.86l.593-.36-.173-.674c-.8-3.1-.926-5.912-.1-7.8S19.87,9.548,23.427,9.481Z"
              transform="translate(-2 -2.008)"
            />
          </svg>
          <h4 style="font-size: 0.8rem">Alberto Gatica</h4>
          <span style="font-size: 0.6rem"
            >Especialista de Créditos y Riesgos</span
          >
        </div>
      </div>
      <div class="col">
        <div class="input" style="text-align: center">
          <svg
            style="margin: 0 auto; height: 20px; width: auto"
            xmlns="http://www.w3.org/2000/svg"
            width="43"
            height="43"
            viewBox="0 0 43 43"
          >
            <defs>
              <style>
                .icon {
                  fill: #919191;
                }
              </style>
            </defs>
            <path
              class="icon"
              d="M23.5,2.008A21.5,21.5,0,1,0,45,23.508,21.514,21.514,0,0,0,23.5,2.008Zm0,1.87A19.628,19.628,0,0,1,37.511,37.254,20.987,20.987,0,0,0,31.95,34.74a15.012,15.012,0,0,1-2.541-1.1,2.571,2.571,0,0,1-1.156-1.2c-.086-1.072-.069-1.935-.062-2.954.113-.113.259-.192.374-.329a8.923,8.923,0,0,0,.816-1.154,8.271,8.271,0,0,0,.971-2.614c.283-.144.586-.176.816-.469a4.651,4.651,0,0,0,.84-2.368,2.665,2.665,0,0,0-.579-2.08,16.147,16.147,0,0,0,.761-5.8,6.841,6.841,0,0,0-1.159-3.526,5.132,5.132,0,0,0-3.368-1.961A5.466,5.466,0,0,0,23.42,7.609H23.4c-4.022.074-6.617,1.739-7.728,4.278-1.057,2.414-.829,5.42-.071,8.55a2.654,2.654,0,0,0-.612,2.118,4.659,4.659,0,0,0,.84,2.368c.23.294.533.325.816.469A8.269,8.269,0,0,0,17.619,28a8.907,8.907,0,0,0,.816,1.154c.116.137.261.218.374.331.006,1.018.023,1.881-.062,2.954a2.583,2.583,0,0,1-1.154,1.2,15,15,0,0,1-2.538,1.1,21.549,21.549,0,0,0-5.563,2.512A19.628,19.628,0,0,1,23.5,3.877Zm-.073,5.6c1.724,0,2.808.512,3.106,1.033l.228.4.455.062a3.113,3.113,0,0,1,2.3,1.269,5.1,5.1,0,0,1,.811,2.576,15.967,15.967,0,0,1-.794,5.558l-.235.707.637.387a.954.954,0,0,1,.217.86,2.957,2.957,0,0,1-.455,1.437c-.142.181-.2.158-.188.157l-.77.064-.082.769a6.3,6.3,0,0,1-.876,2.282,7.237,7.237,0,0,1-.643.911c-.194.229-.4.371-.323.33l-.5.261v.566a35.939,35.939,0,0,0,.08,3.692l.011.124.044.117a4.441,4.441,0,0,0,2,2.206A16.112,16.112,0,0,0,31.323,36.5,23.379,23.379,0,0,1,36.1,38.561a19.631,19.631,0,0,1-25.2,0,24.182,24.182,0,0,1,4.783-2.059,16.091,16.091,0,0,0,2.87-1.26,4.454,4.454,0,0,0,1.994-2.2l.044-.117.011-.124a36.249,36.249,0,0,0,.08-3.692v-.566l-.5-.263c.078.041-.129-.1-.323-.33a7.2,7.2,0,0,1-.643-.909,6.311,6.311,0,0,1-.876-2.282l-.082-.769-.77-.064c.014,0-.046.024-.188-.157a2.962,2.962,0,0,1-.455-1.437.969.969,0,0,1,.217-.86l.593-.36-.173-.674c-.8-3.1-.926-5.912-.1-7.8S19.87,9.548,23.427,9.481Z"
              transform="translate(-2 -2.008)"
            />
          </svg>
          <h4 style="font-size: 0.8rem">Lucero Martínez</h4>
          <span style="font-size: 0.6rem">Subdirectora de Operaciones</span>
        </div>
      </div>
    </div>
    <div style="text-align: center; justify-content: center; height: 100px; margin-top: 20px;">
      <button mat-icon-button (click)="GuardarMinuta()" [disabled]="pdf">
        <svg
          style="height: 60px; width: auto"
          xmlns="http://www.w3.org/2000/svg"
          width="116.322"
          height="116.322"
          viewBox="0 0 116.322 116.322"
          *ngIf="!loadingpdf"
        >
          <defs>
            <style>
              .a {
                fill: #00a5da;
              }
              .b {
                fill: #fff;
              }
            </style>
          </defs>
          <g transform="translate(-902 -3389)">
            <g transform="translate(739 1848)">
              <path
                class="a"
                d="M58.161,0A58.161,58.161,0,1,1,0,58.161,58.161,58.161,0,0,1,58.161,0Z"
                transform="translate(163 1541)"
              />
            </g>
            <g transform="translate(896.596 3947.768)">
              <path
                class="b"
                d="M41.4-500.669q0-10.725,0-21.45a5.963,5.963,0,0,1,4.633-6.142,6.565,6.565,0,0,1,1.48-.165q10.753-.015,21.506-.009a1.767,1.767,0,0,1,1.333.539q7.188,7.155,14.4,14.284a2.217,2.217,0,0,1,.688,1.678q0,11.805,0,23.611c0,3.1.011,6.208-.006,9.312a5.921,5.921,0,0,1-4.568,5.912,7.2,7.2,0,0,1-1.645.175q-15.825.016-31.649.008a5.957,5.957,0,0,1-6.169-6.137c-.014-6.374,0-12.748,0-19.122v-2.494Zm25.685-24.262c-.148-.019-.22-.036-.292-.036q-9.671,0-19.342,0A2.322,2.322,0,0,0,45-522.431q0,21.753,0,43.507a2.643,2.643,0,0,0,.1.761,2.374,2.374,0,0,0,2.567,1.667q12.553,0,25.106,0c2.18,0,4.36.008,6.54,0a2.2,2.2,0,0,0,2.425-2.341q0-15.435-.006-30.87c0-.179-.017-.357-.028-.579h-.917c-2.586,0-5.173.011-7.759,0a5.9,5.9,0,0,1-5.841-4.915,11.06,11.06,0,0,1-.1-1.819C67.083-519.649,67.089-522.271,67.089-524.931Zm3.577,2.755.11-.152c-.006.122-.016.243-.016.365,0,1.845-.008,3.69,0,5.535a2.4,2.4,0,0,0,2.662,2.675c1.79.01,3.579,0,5.369,0,.141,0,.282-.022.6-.049Z"
              />
              <path
                class="b"
                d="M112.862-402.662a20.413,20.413,0,0,1,3.467.541,5.619,5.619,0,0,1,3.76,6.088,5.838,5.838,0,0,1-4.969,5.135,34.168,34.168,0,0,1-4.2.1,1.094,1.094,0,0,1-1.112-1.2q-.012-4.652,0-9.3a1.129,1.129,0,0,1,1.259-1.22c.591-.008,1.182,0,1.772,0Zm-.617,2.613v6.977c.7,0,1.359.009,2.02,0a3.353,3.353,0,0,0,3.414-2.793,3.427,3.427,0,0,0-2.6-4A17.845,17.845,0,0,0,112.245-400.049Z"
                transform="translate(-51.359 -94.439)"
              />
              <path
                class="b"
                d="M73.008-401.922a31.835,31.835,0,0,1,4.288.037,3.9,3.9,0,0,1,3.213,4.357,3.981,3.981,0,0,1-3.85,3.59c-.419.019-.839,0-1.311,0v3.741h-2.34Zm2.355,5.573c.459,0,.863.011,1.266,0a1.572,1.572,0,0,0,1.533-1.394A1.558,1.558,0,0,0,77-399.417a9.164,9.164,0,0,0-1.641-.019Z"
                transform="translate(-23.73 -94.95)"
              />
              <path
                class="b"
                d="M164.64-402.1v2.4h-4.906v2.322h4.444V-395h-4.466v4.724h-2.38v-1.6q0-4.348,0-8.7c0-1.141.384-1.529,1.516-1.528C160.764-402.1,162.68-402.1,164.64-402.1Z"
                transform="translate(-87.046 -94.862)"
              />
            </g>
          </g>
        </svg>
        <mat-spinner *ngIf="loadingpdf" diameter="20" class="spinner_pdf"></mat-spinner>
      </button>
    </div>
    <div class="container-btn">
      <button class="btn-secondary-new" (click)="guardarYRetroceder()">
        Atras
      </button>
      <button class="btn-primary-new" (click)="guardarYAvanzar()">
        <mat-spinner *ngIf="loading" diameter="20" class="spinner_next" ></mat-spinner> <span *ngIf="!loading">Guardar</span>
      </button>
    </div>
</div>
