import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, AuthenticationService, SolicitudService, UserService } from '../../_services';

@Component({
    selector: 'app-solicitud-aval',
    templateUrl: './solicitudAval.component.html',
    styleUrls: ['./solicitudAval.component.scss']
})

export class SolicitudAvalComponent implements OnInit {
    folio = null
    email = null
	typeP: number;
    loading = false;
    submitted = false;
	returnUrl: string;
	parte = 1;
    tipoAval = 0

    aval: any = {}

    ready = false
    constructor(
        private authenticationService:AuthenticationService,
        private router:Router,
        private solicitudService: SolicitudService
    ) {
        // redirect to home if already logged in
	}

	changeForm(newPart) {
		console.log(newPart);
		this.parte = newPart;
	}
	changeActive(tipo: number) {
        this.tipoAval = this.typeP == 1 ? 1 : tipo;
        console.log(this.tipoAval);
        localStorage.setItem('tipoAval', this.tipoAval.toString())
	}

    async ngOnInit() {
        this.ready = false
        this.folio = JSON.parse(localStorage.getItem('client_details')).folio
        this.email = JSON.parse(localStorage.getItem('client_details')).email
		this.parte = Number(JSON.parse(localStorage.getItem('steps')).aval) +1
        this.typeP = Number(localStorage.getItem('tipoPersona'));
        this.tipoAval = JSON.parse(localStorage.getItem('currentUser')).typeAval || Number(localStorage.getItem('tipoAval'))
        console.log("Aval", this.tipoAval);
        localStorage.setItem('tipoAval', this.tipoAval.toString())

        const datosCliente = await this.solicitudService.ObtenerDatosCliente(this.email)
		console.log({datosCliente});
		const solicitud = await this.solicitudService.ObtenerSolicitud(this.folio)
		console.log({solicitud});
		this.llenarSolicitud({datosCliente, solicitud})
		this.ready = true
    }

    llenarSolicitud({datosCliente, solicitud}) {
        this.aval = {...solicitud.aval}
        this.aval.primerNombre = solicitud && solicitud.aval ? solicitud.aval.nombreCompleto : datosCliente && datosCliente.aval ? datosCliente.aval.primerNombre : ''
        this.aval.segundoNombre = solicitud && solicitud.aval ? solicitud.aval.segundoNombre : datosCliente && datosCliente.aval ? datosCliente.aval.segundoNombre : ''
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }
}
