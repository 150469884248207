import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-module-instructions',
  templateUrl: './module-instructions.component.html',
  styleUrls: ['./module-instructions.component.scss']
})
export class ModuleInstructionsComponent implements OnInit {
  @Input() instructions: any 
  constructor() { }

  ngOnInit(): void {
  }

}
