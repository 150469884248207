import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { AuthenticationService, UserService } from 'src/app/_services';
import { InfoComponent } from 'src/app/_components/info/info.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-parametros-sistema',
  templateUrl: './parametros-sistema.component.html',
  styleUrls: ['./parametros-sistema.component.scss']
})
export class ParametrosSistemaComponent implements OnInit {

  horasPattern: String = ''
  parametros: any = {
    km:0,
    tamanoArchivo:0,
    formato:'PDF',
    horasSolicitud:0,
    horasCotizacion:0,
    horasDocVendor: 0,
    horasDocCliente: 0,
    horasComite: 0,
    usuarioBuro: '',
    fechaModificacion: '',
    contrasenaBuro: '',
    diasRestantes: '',
    diferenciaDias: 0,
    usuarioBuroPM: '',
    fechaModificacionPM: '',
    contrasenaBuroPM: '',
    diasRestantesPM: '',
    diferenciaDiasPM: 0
  }
  show_password = false;
  show_passwordPM = false;
  parametrosEnvio: any = []
  parametrosCopy: any = {}
  perfil = 6

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private datePipe: DatePipe) {}

  ngOnInit(): void {
    const currentUser = JSON.parse( localStorage.getItem("currentUser") );
    this.perfil = Number(currentUser.perfil);
    this.horasPattern = "^[0-9]{1,3}$"
    this.obtenerParametros();
  }

  obtenerParametros(){
    let t = this;
    this.userService.ObtenerParametrosSistema().subscribe(
      res => {
        console.log(res);
        t.setValores(res);
       
      }, 
      err => {
        console.log(err)
      }
    )
  }

  setValores(res){
    this.parametros.km = this.obtenerValor('max_kilometros', res);
    this.parametros.tamanoArchivo = this.obtenerValor('max_size_gb', res);
    this.parametros.horasSolicitud = this.obtenerValor('notificaciones_horas_pausa_solicitud', res);
    this.parametros.horasCotizacion = this.obtenerValor('notificaciones_horas_pausa_cotizacion', res);
    this.parametros.horasDocVendor = this.obtenerValor('notificaciones_horas_documentos_vendor', res);
    this.parametros.horasDocCliente = this.obtenerValor('notificaciones_horas_documentos_cliente', res);
    this.parametros.horasComite = this.obtenerValor('notificaciones_horas_pausa_comite', res);
    this.parametros.usuarioBuro = this.obtenerValorVariable('user_buro', 'valor', res);
    this.parametros.contrasenaBuro = this.obtenerValorVariable('pass_buro', 'valor', res);
    this.parametros.usuarioBuroPM = this.obtenerValorVariable('user_buro_pm', 'valor', res);
    this.parametros.contrasenaBuroPM = this.obtenerValorVariable('pass_buro_pm', 'valor', res);
    this.parametrosCopy = Object.assign({}, this.parametros);
    this.calcularFechaDias(res);
  }

  transformDate(date, format) {
    return this.datePipe.transform(date, format); //format you need. 
  }

  calcularFechaDias(array){
    let fechaModUser = new Date(this.obtenerValorVariable('user_buro', 'fecha', array));
    let fechaModUserPM = new Date(this.obtenerValorVariable('user_buro_pm', 'fecha', array));
    this.parametros.fechaModificacion = this.transformDate(fechaModUser, 'dd/MM/yyyy');
    this.parametros.fechaModificacionPM = this.transformDate(fechaModUserPM, 'dd/MM/yyyy');
    let fechaActual = new Date();

    let diferenciaTiempo = fechaActual.getTime() - fechaModUser.getTime();
    let diferenciaDias = Math.round(30 - (diferenciaTiempo / (1000 * 3600 * 24)));
    let diferenciaTiempoPM = fechaActual.getTime() - fechaModUserPM.getTime();
    let diferenciaDiasPM = Math.round(30 - (diferenciaTiempoPM / (1000 * 3600 * 24)));

    this.parametros.diferenciaDias = diferenciaDias;
    this.parametros.diasRestantes = ' '+ diferenciaDias + ' días';
    this.parametros.diferenciaDiasPM = diferenciaDiasPM;
    this.parametros.diasRestantesPM = ' '+ diferenciaDiasPM + ' días';
  }

  obtenerValorVariable(nombreVariable, valor, array){
    var value;
    array.forEach(element => {
      if(element.nombre_variable == nombreVariable){
        value = valor == 'valor' ? element.valor_variable: element.fecha_modificacion;
        return;
      }
    });
    return value;
  }

  obtenerValor(valor, array){
    var value = 0;
    array.forEach(element => {
      if(element.nombre_variable == valor){
        value = parseInt(element.valor_variable)
        return;
      }
    });
    return value;
  }
  verificarExpresiones(){
    var regex = /^[0-9]{1,3}$/
    if (regex.test(this.parametros.horasSolicitud) && regex.test(this.parametros.horasCotizacion) &&
        regex.test(this.parametros.horasDocCliente) && regex.test(this.parametros.horasDocVendor) &&
        regex.test(this.parametros.horasComite)){
          return true;
      }
    else{
      return false;
    }
  }

  guardarCambios(){
    if(this.verificarCambios()){
      if(!this.verificarExpresiones()) {
        const dialogref = this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text: 'Favor de verificar los datos ingresados.',
            type: 'error',
            icon: 'error'
          }
        });
        return
      }
      this.verificarCambioUserBuro();
      this.construirModelo();
      this.userService.GuardarParametrosSistema(this.parametrosEnvio).subscribe(
        res => {
          console.log(res);
          const dialogref = this.dialog.open(MessageBoxComponent, {
            width: "500px",
            data: {
              text: 'Éxito',
              text2: 'Se han guardado los cambios',
              type: 'success',
              icon: 'success'
            }
          })
          this.parametrosEnvio = []
          this.parametrosCopy = {}
          this.obtenerParametros();
        }, 
        err => {
          console.log(err)
          const dialogref = this.dialog.open(MessageBoxComponent, {
            width: "500px",
            data: {
              text: "Ha ocurrido un error al guardar la información",
              text2: err,
              type: "error",
              icon: "error"
            }
          })
        }
      );
    }
    else{
      this.dialog.open(MessageBoxComponent, {
        width: "500px",
        data: {
          text: 'Vuelve a intentarlo',
          text2: 'Aún no has realizado cambios en los parámetros del sistema.',
          type: 'error',
          icon: 'error'
        }
      });
    }
  }

  verificarCambios(){
    if( (this.parametrosCopy.km != this.parametros.km) || 
      (this.parametrosCopy.tamanoArchivo != this.parametros.tamanoArchivo) ||
      (this.parametrosCopy.horasSolicitud != this.parametros.horasSolicitud) ||
      (this.parametrosCopy.horasCotizacion != this.parametros.horasCotizacion) ||
      (this.parametrosCopy.horasDocVendor != this.parametros.horasDocVendor) ||
      (this.parametrosCopy.horasComite != this.parametros.horasComite) ||
      (this.parametrosCopy.horasDocCliente != this.parametros.horasDocCliente) ||
      (this.parametrosCopy.usuarioBuro != this.parametros.usuarioBuro) ||
      (this.parametrosCopy.usuarioBuroPM != this.parametros.usuarioBuroPM) ||
      (this.parametrosCopy.contrasenaBuroPM != this.parametros.contrasenaBuroPM) ||
      (this.parametrosCopy.contrasenaBuro != this.parametros.contrasenaBuro)){
        return true
      }
      else{
        return false
      }
  }

  verificarCambioUserBuro(){
    if((this.parametrosCopy.usuarioBuro != this.parametros.usuarioBuro) || (this.parametrosCopy.contrasenaBuro != this.parametros.contrasenaBuro)){
      let paramUserBuro = {
        "nombre_variable": 'user_buro',
        "valor_variable": this.parametros.usuarioBuro.toString()
      }
      let paramPassBuro = {
        "nombre_variable": 'pass_buro',
        "valor_variable": this.parametros.contrasenaBuro.toString()
      }
      this.parametrosEnvio.push(paramUserBuro);
      this.parametrosEnvio.push(paramPassBuro);
    }

    if((this.parametrosCopy.usuarioBuroPM != this.parametros.usuarioBuroPM) || (this.parametrosCopy.contrasenaBuroPM != this.parametros.contrasenaBuroPM)){
      let paramUserBuroPM = {
        "nombre_variable": 'user_buro_pm',
        "valor_variable": this.parametros.usuarioBuroPM.toString()
      }
      let paramPassBuroPM = {
        "nombre_variable": 'pass_buro_pm',
        "valor_variable": this.parametros.contrasenaBuroPM.toString()
      }
      this.parametrosEnvio.push(paramUserBuroPM);
      this.parametrosEnvio.push(paramPassBuroPM);
    } 
  }

  construirModelo(){
    this.parametrosEnvio.push(
      {
        "nombre_variable": 'max_kilometros',
        "valor_variable": this.parametros.km.toString()
      },
      {
        "nombre_variable": 'max_size_gb',
        "valor_variable": this.parametros.tamanoArchivo.toString()
      },
      {
        "nombre_variable": 'notificaciones_horas_pausa_solicitud',
        "valor_variable": this.parametros.horasSolicitud.toString()
      },
      {
        "nombre_variable": 'notificaciones_horas_pausa_cotizacion',
        "valor_variable": this.parametros.horasCotizacion.toString()
      },
      {
        "nombre_variable": 'notificaciones_horas_documentos_vendor',
        "valor_variable": this.parametros.horasDocVendor.toString()
      },
      {
        "nombre_variable": 'notificaciones_horas_documentos_cliente',
        "valor_variable": this.parametros.horasDocCliente.toString()
      },
      {
        "nombre_variable": 'notificaciones_horas_pausa_comite',
        "valor_variable": this.parametros.horasComite.toString()
      }
    )
    console.log("Modelo de salida: ",this.parametrosEnvio)
  }

  info(){
    this.dialog.open(InfoComponent,{
      width: "500px",
      data: {
        text: "Se sugiere notificar primero al VENDEDOR y posteriormente al CLIENTE"
      }
    })
  }

  ValidarHoras( {target: {value}} ){
    return value.length < 3
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

}
