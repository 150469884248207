import { formatCurrency } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { AuthenticationService, SolicitudService, UserService } from 'src/app/_services';
import { saveAs } from 'file-saver';
import { AnalisisPM } from '../AnalisisPM';
import { StepperNavigationService } from 'src/app/_services/stepper-navigation.service';


@Component({
  selector: 'app-minuta-pm',
  templateUrl: './minuta-pm.component.html',
  styleUrls: ['./minuta-pm.component.scss']
})
export class MinutaPmComponent implements OnInit {
  @Input() analisisPm: any;  
  @Output() analisisPmChange = new EventEmitter<any>();
  AnalisisPM: any = {};
  ngOnChanges(changes: SimpleChanges) {
    if (changes.analisisPm && changes.analisisPm.currentValue) {
      console.log("Nuevo valor de analisisPm capacidad pago:", this.analisisPm);
      this.AnalisisPM = {...this.analisisPm}
    }
  }

  Participantes = [
    {nombre: '', iniciales: '', puesto: '', asistencia: false},
    {nombre: '', iniciales: '', puesto: '', asistencia: false},
    {nombre: '', iniciales: '', puesto: '', asistencia: false},
    {nombre: '', iniciales: '', puesto: '', asistencia: false},
    {nombre: '', iniciales: '', puesto: '', asistencia: true},
  ];
  folio = null;
  MinutaDatos: any = {};
  Minuta: any = {};
  Actualizado = false;
  submit = false;
  guardar = false;
  pdf = false
  adicional = false
  loading = false;
  loadingpdf = false;
  constructor(private dialog: MatDialog,
              private router: Router,
              private userService: UserService,
              private solicitudService: SolicitudService,
              private authenticationService: AuthenticationService,
              private stepperNavigationService: StepperNavigationService,

              ) { }

  async ngOnInit() {
    const {folio, cliente} = JSON.parse( localStorage.getItem('client_details'));
    this.folio = folio;
    this.AnalisisPM = JSON.parse(JSON.stringify(AnalisisPM));
    

    // await this.getAnalisisPMData();

  }



  Save() {
    // this.userService.changeStatus(this.folio, 4, 1).subscribe(() => {
    //   this.dialog.open(MessageBoxComponent, {
    //     data: {
    //       text: 'Éxito',
    //       text2: 'Se ha completado la Minuta',
    //       // text3: "Revisa tu correo para descargar el documento",
    //       type: 'success',
    //       icon: 'success'
    //     }
    //   });
    //   //this.router.navigate(['/dashboard', 'comite', this.folio]);
    // });
  }

  GuardarMinuta() {
    if ( !this.userService.alertService.isOnline() ) { return; }
    this.pdf = true

    this.Minuta.participante_uno = this.Participantes[0].asistencia,
    this.Minuta.participante_dos = this.Participantes[1].asistencia,
    this.Minuta.participante_tres = this.Participantes[2].asistencia,
    this.Minuta.participante_cuatro = this.Participantes[3].asistencia;
    this.Minuta.fecha = this.AnalisisPM.minuta.fecha_comite;
    this.Minuta.hora = this.AnalisisPM.minuta.hora_comite;

    this.submit = true;

    this.guardar = ( this.Participantes[0].asistencia || this.Participantes[1].asistencia || this.Participantes[2].asistencia || this.Participantes[3].asistencia);
    if ( !this.guardar ) {
      this.pdf = false
      return this.dialog.open(MessageBoxComponent, {
        width: '500px',
        data: {
          text: 'No se ha completado la Minuta',
          text3: 'Se requiere autorizar Asistencia del Participante',
          icon: 'error',
          type: 'error'
        }
      });
    }

    const mail = JSON.parse(localStorage.getItem('currentUser')).email;
    const cliente = JSON.parse(localStorage.getItem('client_details'));

    const resolucionFinal = {
      1: 'Aprobada',
      2: 'Aprobada Condicionalmente',
      3: 'Información Adicional',
      4: 'Rechazada',
    };
    
    if (!this.adicional) {
      this.Participantes.pop()
    }

    console.log(this.Participantes)

    const request = {
      email: mail,
      opcion: 28,
      filename: 'minuta.pdf',
      subjec: 'Minuta',
      type: 33,
      params: {
        inputAsunto: this.AnalisisPM.minuta.numero_comite,
        inputFecha: this.AnalisisPM.minuta.fecha_comite,
        inputHora: this.AnalisisPM.minuta.hora_comite,
        asistencia1: this.Participantes[0].asistencia ? 'OK' : '-',
        asistencia2: this.Participantes[1].asistencia ? 'OK' : '-',
        asistencia3: this.Participantes[2].asistencia ? 'OK' : '-',
        asistencia4: this.Participantes[3].asistencia ? 'OK' : '-',
        inputSolicitante: this.AnalisisPM.solicitante,
        inputCotizacion: this.AnalisisPM.folio,
        inputActivo: this.AnalisisPM.caracteristica_activo.activo,
        inputValorActivo: formatCurrency( this.AnalisisPM.caracteristica_activo.monto_activo, 'es-MX', '$' ),
        inputArr: this.AnalisisPM.caracteristica_activo.tipo_financiamiento,
        inputPlazo: this.AnalisisPM.cotizacion.plazo,
        puntosPrincipalesRevisados: this.AnalisisPM.minuta.puntos_principales_revisados,
        comentariosAdicionales: this.AnalisisPM.minuta.comentarios_adicionales,
        resolucion: resolucionFinal[this.AnalisisPM.id_resolucion_comite],
        nombreSolicitante: this.AnalisisPM.solicitante,
        activoArrendar: this.AnalisisPM.caracteristica_activo.activo,
        folio: this.AnalisisPM.folio,
        participantes: {...this.Participantes}
      }
    };

    console.log('request: ', request);
    this.enviaEmail(request);
    // this.userService.GuardarMinuta( this.Minuta )
    // .subscribe( res => {
    //   console.log(res);
      
    // }, err => {
    //   this.pdf = false
    //   this.dialog.open(MessageBoxComponent, {
    //     width: '500px',
    //     data: {
    //       text: 'Hubo un error al actualizar la minuta.',
    //       type: 'error',
    //       icon: 'error'
    //     }
    //   });
    // });
  }
  enviaEmail(request) {
    this.loadingpdf = true;
    this.solicitudService.sendMailCommentaries(request)
    .subscribe( (res: any) => {
      console.log('res: ', res);
      this.loadingpdf = false;
      this.Actualizado = true;

      const file = new Blob([res], {type: 'application/pdf'});
      /*var fileURL = URL.createObjectURL(file);
      window.open(fileURL);*/
      saveAs(file, request.filename);

      const dialogref = this.dialog.open(MessageBoxComponent, {
        width: '500px',
        data: {
          text: 'Éxito',
          text2: 'Se ha guardado la información',
          text3: 'Revisa tu correo para descargar el documento',
          type: 'success',
          icon: 'success'
        }
      });
    }, (err) => {
      this.loadingpdf = false;
      console.log('error: ', err);
      this.dialog.open(MessageBoxComponent, {
        data: {
          text: 'El servicio no está disponible.',
          text3: 'Vuelve a intentarlo',
          type: 'error',
          icon: 'error'
        }
      });
    });
  }

  ParticipanteSeleccionado(participante, index) {
    console.log(participante);
    
    this.Participantes[index].nombre = participante.nombre
    this.Participantes[index].puesto = participante.puesto
    this.Participantes[index].iniciales = participante.iniciales
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  // GUARDAR MINUTA SUBSCRIBE
  /*this.Actualizado = true
  this.userService.ObtenerPDF({
    id: 7,
    params :{
      ...this.Minuta,
      ...this.MinutaDatos
    }
  }).subscribe(res => {
    const blob = new Blob([res], {type: 'application/pdf'})
    saveAs(blob, 'Minuta.pdf')
  },
  err=>console.log(err))

  this.dialog.open(MessageBoxComponent, {
    width: "500px",
    data: {
      text: "Se ha completado la Minuta exitosamente.",
      type: "success",
      icon: "success"
    }
  })*/

  async guardarYRetroceder() {
   
    console.log("Guardando analisis", this.AnalisisPM);
    const res:any = await this.solicitudService.GuardarAnalisisCredito(
      this.AnalisisPM
    );
    console.log(res);
    if(res.statusCode == 200){
      this.analisisPmChange.emit(this.AnalisisPM)

      this.stepperNavigationService.previousStep();
    }
  }

  async guardarYAvanzar() {
    this.loading = true
    console.log("Guardando analisis", this.AnalisisPM);
    const res:any = await this.solicitudService.GuardarAnalisisCredito(
      this.AnalisisPM
    );
    console.log(res);
    if(res.statusCode == 200){
      this.loading = false
      this.analisisPmChange.emit(this.AnalisisPM)
      this.stepperNavigationService.nextStep();
    }else{
      this.loading = false
    }
  }
}
