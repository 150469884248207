export class Product {
    id: number = 0
	vendor_id: number
	name: string = 'Producto a cotizar'
	description: string = ''
	model: string
	year: string
	warranty: string
	value: any = '100000'
	customer_price: number = 100000
	suggested_customer_price: number = 100000
	currency: string = 'MXN'
	accessories: string
	url_image: string = 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/no-photo.svg'
    plazos: string = "ap12,ap18,ap24,ap36,ap48,ap60"
	residuales: string = "{\"ap12\":\"42\",\"ap18\":\"37\",\"ap24\":\"32\",\"ap36\":\"27\",\"ap48\":\"22\",\"ap60\":\"17\"}"
	taza_mensuales: string = ''
	costo_financiero: string = ''
	edit: boolean = false
	caracteristicas: any
	nombres: any

	fromJson(json) {
		console.log(`Constructor:`, json);
		this.id = json.id
		this.vendor_id = json.vendor_id
		this.name = json.name
		this.description = json.description
		this.model = json.model
		this.year = json.year
		this.warranty = json.warranty
		this.value = json.value
		this.customer_price = json.customer_price
		this.suggested_customer_price = json.suggested_customer_price
		this.currency = json.currency
		this.accessories = json.accessories
		this.url_image = json.url_image
		this.plazos = json.plazos
		this.residuales = json.residuales
		this.taza_mensuales = json.taza_mensuales
		this.costo_financiero = json.costo_financiero
		this.edit = json.edit
		this.caracteristicas = json.caracteristicas
		this.nombres = json.nombres
	}

	isValid(): Boolean {
		if (this.name == '' ||
			this.description == '' ||
			this.model == '' ||
			this.year == '' ||
			this.warranty == '' ||
			!(this.value > 0) || 
			!(this.customer_price > 0) ||
			!(this.suggested_customer_price > 0) ||
			this.accessories == ''
		) {
			return false
		}
		return true
	}
}

export class Auto extends Product {
	name: string = "Auto a cotizar"
	caracteristicas: CaracteristicasAuto = {
		nuevo: '1',
		year: '',
		month: '',
		estado: 'CDMX',
		seguro_financiado: 0,
		tenencia: 0,
		placas: 0,
		seguro_auto: 0,
		gps_inicial: 0,
		gps_mensualidad: 0,
	}
}

class CaracteristicasAuto {
	nuevo: string
	year: string
	month: string
	estado: string
	seguro_financiado: number
	tenencia: number
	placas: number
	seguro_auto: number
	gps_inicial: number
	gps_mensualidad: number
}