import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-conyugue-nueva-solicitud',
  templateUrl: './conyugue-nueva-solicitud.component.html',
  styleUrls: ['./conyugue-nueva-solicitud.component.scss']
})
export class ConyugueNuevaSolicitudComponent implements OnInit {
  @Input() data: any;
  @Output() datosActualizados = new EventEmitter();
  frmConyugue: FormGroup;

  constructor(
    private formBuilder: FormBuilder,

  ) { }
  
  ngOnInit(): void {
    this.frmConyugue = this.formBuilder.group({
      nombres: ['', Validators.required],
      apellido_paterno: ['', Validators.required],
      apellido_materno: ['', Validators.required],
      ocupacion: ['', Validators.required],
      tel: ['', [Validators.required, Validators.maxLength(10),Validators.minLength(10)]],
    })
    this.frmConyugue.valueChanges.subscribe(() => {
      if (this.frmConyugue.valid) {
        this.data = { ...this.data, conyugue: this.frmConyugue.value };
        this.datosActualizados.emit(this.data);
      }
    });
    if(this.data.conyugue){
      this.frmConyugue.patchValue({
        nombres: this.data.conyugue.nombres || '',
        apellido_paterno: this.data.conyugue.apellido_paterno || '',
        apellido_materno: this.data.conyugue.apellido_materno || '',
        ocupacion: this.data.conyugue.ocupacion || '',
        tel: this.data.conyugue.tel || ''
      });
    }
  }

}
