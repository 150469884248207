import { Component, OnInit } from '@angular/core';
import { UserService, AuthenticationService } from 'src/app/_services';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { Observable, Observer, fromEvent, merge } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-ver-tramites',
  templateUrl: './ver-tramites.component.html',
  styleUrls: ['./ver-tramites.component.scss']
})
export class VerTramitesComponent implements OnInit {
  tramites = []
  id_cliente = null
  meses : any = [];
  arrayFecha: any = [];
  tramitesInput : any = [];
  loading : boolean = true;
  
  constructor(
    private userService:UserService,
    private authenticationService:AuthenticationService,
    private router:Router,
    private dialog: MatDialog) {
      
      this.meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

    }

  ngOnInit(): void {
    const { id_cliente } = JSON.parse(localStorage.getItem('client_details'))
    this.id_cliente = id_cliente

    this.loading = true;

    if( id_cliente ) {
      let t = this;
      this.userService.ObtenerTramites(id_cliente)
      .subscribe( res => {
        console.log("Tramites: ", res);
        t.tramites = res
        t.tramitesInput = res
  
        console.log(t.tramitesInput);
        t.tramitesInput.forEach(tramite => {
          //Convert date: 16/06/2020 into 16 Jun 200 format
          t.arrayFecha = tramite.ultimo_cambio.split("/");
          tramite.ultimo_cambio_fecha = t.arrayFecha[0] + " " +
          t.formatMesDate(t.arrayFecha[1]) + " " + t.arrayFecha[2];
        });
        console.log(t.tramitesInput);
      },
      error => {
        this.loading = false;
        this.dialog.open(MessageBoxComponent,{
            width: "500px",
            data: {
                text: "Error de conexión",
                type: "error",
                icon: "error"
            }
        })
      })
    }
  }

  formatMesDate(numeroMes: string): string {
    let numMes = parseInt(numeroMes);
    let mes = this.meses[numMes - 1];
    return mes;
  }

  filtrarTramites(word) {
    console.log("Filtrando: ", word);
    if(word){
      let tramitesTemp = this.tramites;
      this.tramitesInput = tramitesTemp.filter(tramite => {
        return (
          tramite.folio.toString().includes(word) || 
          tramite.nombre_equipo.toLowerCase().includes(word.toLowerCase()) || 
          tramite.nombre_status.toLowerCase().includes(word.toLowerCase()) ||
          tramite.ultimo_cambio_fecha.toLowerCase().includes(word.toLowerCase()) 
          )
      })
      console.log("Filtro: ", this.tramitesInput);
    }
    else{
      this.tramitesInput = this.tramites;
    }
  }

  CambiarTramite(folio){
    console.log(folio);
    this.userService.getStatus(this.id_cliente, folio)
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
