import { Component, OnInit } from '@angular/core';
import { AuthenticationService, UserService } from 'src/app/_services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirmar-cotizacion',
  templateUrl: './confirmar-cotizacion.component.html',
  styleUrls: ['./confirmar-cotizacion.component.scss']
})
export class ConfirmarCotizacionComponent implements OnInit {
  folio = null
  data:any = {}
  
  constructor(
    private authenticationService:AuthenticationService,
    private router:Router,
    private userService:UserService
  ) { }

  ngOnInit(): void {
    const { folio } = JSON.parse(localStorage.getItem('client_details'))
    this.folio = folio

    this.userService.ObtenerListaCotizacionesPorConfirmar(this.folio)
    .subscribe( res => {
      console.log("Cliente cotizaciones: ", res)
      this.data = res
    })
  }

  SeleccionarCotizacion(cot){
    if( !this.userService.alertService.isOnline() ) return
    console.log("Seleccionada: ", cot);
    localStorage.setItem('cotizacion', JSON.stringify(cot))
    this.router.navigate(['dashboard', 'confirmar_cotizacion'])
    
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
