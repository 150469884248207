import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { expandedSection } from "src/app/_animations/animations";
import { NuevaSolicitudService } from "src/app/_services/nueva_solicitud.service";

@Component({
  selector: "app-carga-documentacion",
  templateUrl: "./carga-documentacion.component.html",
  styleUrls: ["./carga-documentacion.component.scss"],
  animations: [expandedSection],
})
export class CargaDocumentacionComponent implements OnInit {
  @Input() data: any;
  @Output() siguiente = new EventEmitter();
  @Output() anterior = new EventEmitter();
  @Output() datosActualizados = new EventEmitter();

  expandedDocumentosPersonales = false;
  expandedConyugue = false;
  expandedAval = false;
  expandedReferencias = false;
  constructor(private nuevaSolicitudService: NuevaSolicitudService) {}

  ngOnInit(): void {}

  atras() {
    this.anterior.emit();
  }
  async enviarDatos() {
    this.datosActualizados.emit(this.data);
    const emailCliente = this.data.correo || this.data.email;
    const rfcCliente = this.data.rfc;
    const reqGuardadoDatos = {
      aval: this.data.aval,
      conyugue: this.data.conyugue,
      referencias: this.data?.referencias
    };
    const resGuardarDatosCliente =
      await this.nuevaSolicitudService.guardarTodosDatosSolicitud(
        emailCliente,
        rfcCliente,
        reqGuardadoDatos
      );
    if (resGuardarDatosCliente) {
      this.siguiente.emit();
    }
  }
  datosActualizadosDos(nuevosDatos: any): void {
    this.data = nuevosDatos;
    this.datosActualizados.emit(this.data);
    console.log("GUARDADR", nuevosDatos);
  }
  toggleExpand(section: string) {
    switch (section) {
      case "conyugue":
        this.expandedConyugue = !this.expandedConyugue;
        if (this.expandedConyugue) {
          this.expandedDocumentosPersonales = false;
          this.expandedAval = false;
          this.expandedReferencias = false;
        }
        break;
      case "documentosPersonales":
        this.expandedDocumentosPersonales = !this.expandedDocumentosPersonales;
        if (this.expandedDocumentosPersonales) {
          this.expandedConyugue = false;
          this.expandedAval = false;
          this.expandedReferencias = false;
        }
        break;
      case "aval":
        this.expandedAval = !this.expandedAval;
        if (this.expandedAval) {
          this.expandedConyugue = false;
          this.expandedDocumentosPersonales = false;
          this.expandedReferencias = false;
        }
        break;
      case "referencias":
        this.expandedReferencias = !this.expandedReferencias;
        if (this.expandedReferencias) {
          this.expandedConyugue = false;
          this.expandedDocumentosPersonales = false;
          this.expandedAval = false;
        }
        break;
      default:
        break;
    }
  }
}
