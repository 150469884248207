<app-sidenav-v2
[shortMenu]="true"
>
<div class="container">
  <div style="width: 100%; text-align: center;"> 
    <h4>Folio: #{{folio}}</h4>
  </div>
    <div class="body">
      <mat-horizontal-stepper [linear]="isLinear" #stepper>
        <mat-step>
          <div>
            <ng-template matStepLabel>Entrevista</ng-template>
            <app-entrevista 
            [solicitud]="solicitud"
            [analisisPf]="analisisPf" (analisisPfChange)="actualizarAnalisisPf($event)"
            (onUpdate)="ObtenerSolicitudPrecalificacion()"
            ></app-entrevista>
            <!-- <app-resumen [analisisPf]="analisisPf" (analisisPfChange)="actualizarAnalisisPm($event)" #resumen></app-resumen> -->
          </div>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Analisis</ng-template>
          <app-analisis
            [solicitud]="solicitud"
            [analisisPf]="analisisPf"
            (onUpdate)="ObtenerSolicitudPrecalificacion()"
            (analisisPfChange)="actualizarAnalisisPf($event)"
            (actualizarPromedios)="actualizarPromediosAnalisis()"
            (cambiarPuntuacion)="cambiarPuntuacionAnalisis()"   
            (puntuacionCaracter)="puntuacionCaracterAnalisis()">
          </app-analisis>
          <!-- <app-caracter [analisisPf]="analisisPf" (analisisPfChange)="actualizarAnalisisPm($event)" #caracter></app-caracter> -->
        </mat-step>
        <!-- <mat-step>
          <ng-template matStepLabel>Resolución</ng-template>
            
          <div>
            <app-comentarios [analisisPf]="analisisPf" (analisisPfChange)="actualizarAnalisisPf($event)"></app-comentarios> -->
            <!-- <app-capacidad-pago [analisisPf]="analisisPf" (analisisPfChange)="actualizarAnalisisPm($event)"></app-capacidad-pago> -->
          <!-- </div>
        </mat-step> -->
        <mat-step>
          <ng-template matStepLabel>Minuta</ng-template>
            <app-minuta [analisisPf]="analisisPf" (analisisPfChange)="actualizarAnalisisPf($event)"></app-minuta>
          <!-- <app-minuta-pm [analisisPf]="analisisPf" (analisisPfChange)="actualizarAnalisisPm($event)"></app-minuta-pm> -->
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Carta a cliente</ng-template>
            <app-cartas-cliente [analisisPf]="analisisPf" (analisisPfChange)="actualizarAnalisisPf($event)"></app-cartas-cliente>
          <!-- <app-cartas-cliente-pm [analisisPf]="analisisPf" (analisisPfChange)="actualizarAnalisisPm($event)"></app-cartas-cliente-pm> -->
        </mat-step>
        <!-- <mat-step>
                <ng-template matStepLabel></ng-template>
                <p>You are now done.</p>
                <div>
                  <button mat-button matStepperPrevious>Back</button>
                  <button mat-button (click)="stepper.reset()">Reset</button>
                </div>
              </mat-step> -->
      </mat-horizontal-stepper>
    </div>
  </div>
</app-sidenav-v2>
