<div class="container">
    <div class="input-search">
        <img src="/assets/icons/search.png" alt="">
        <input  type="text"
        placeholder="Buscar"
        aria-label="String"
        matInput
        [formControl]="myControl"
        [matAutocomplete]="auto"/>


        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="BuscarDetalles()">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.name">
                {{ option.name }}
            </mat-option>
        </mat-autocomplete>
    </div>
    <div class="contenido-form">
        <div>
            <div class="container-img">
                <div class="container-img-photo">
                    <img id="product_no_image" [src]="image_preview" alt="Agregar imagen" *ngIf="image_preview == 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/no-photo.svg'">
                    <img id="product_image" [src]="image_preview" alt="Agregar imagen"  *ngIf="image_preview != 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/no-photo.svg'">
                </div>
            </div>
            
        </div>
        <div style="width: 100%;">
            <div class="contenido" style="margin-bottom: 20px;">
                <label>Nombre producto</label>
                <input
                type="text"
                name="nombre_producto"
                id="nombre_producto"
                [ngModel]="name_product"
                readonly
                > 
                <!-- [ngModel]="CotizarProducto.name | uppercase" -->
            </div>
            <div style="display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: flex-start;">
                <div class="activo-radiobutton" >
                    <span>Tipo de activo</span>
                    <mat-radio-group aria-label="Select an option" [(ngModel)]="remanufactured">
                        <mat-radio-button value="false">Nuevo</mat-radio-button>
                        <mat-radio-button value="true">Remanufacturado</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="contenido"  style="width: 40%;">
                    <label>Precio con IVA</label>
                    <input
                    type="text"
                    name="tc"
                    id="tc"
                    [ngModel]="
                      precio_iva * tipo_cambio -
                        descuento * tipo_cambio || 0 | currency : 'MXN'
                    "
                    >
                </div>
            </div>
            <div class="enganche">
                <div class="contenido" style="width: 40%;">
                    <label>Enganche $</label>
                    <input
                    type="text"
                    name="enganche_a"
                    id="enganche_a"
                    mask="separator.1"
                    prefix="$"
                    type="text"
                    [(ngModel)]="initial_payment_amount"
                    (input)="ChangeInitialPayment()"
                    maxlength="15"
                    >
                </div>
                <div class="contenido" style="width: 40%; margin-left: 20px;">
                    <label>Enganche %</label>
                    <input
                    type="text"
                    name="enganche_p"
                    id="enganche_p"
                    mask="separator.1"
                    type="text"
                    [value]="initial_payment"
                    [(ngModel)]="initial_payment"
                    (input)="ChangeInitialPaymentPercent()"
                    >
                </div>
            </div>
            <div *ngIf="!msi_disponibles.length" class="contenido" >
                <label >Tiempo de entrega</label>
                <select 
                name="delivery_time"
                id="delivery_time"
                [(ngModel)]="delivery_time"
                >
                    <option value="0">Inmediato</option>
                    <option value="1">1 mes</option>
                    <option value="2">2 meses</option>
                    <option value="3">3 meses</option>
                </select>
            </div>
            <div class="contenido" style="margin-top: 20px;">
                <label>Correo del cliente</label>
                <input
                type="text"
                name="client_email"
                id="client_email"
                [(ngModel)]="client_email"
                placeholder="correo@dominio.com"
                >
                <mat-error *ngIf="error_form && !client_email" style="margin-left: 20px;">Campo obligatorio</mat-error>
            </div>
            <div class="contenido" style="margin-top: 40px;">
                <label>Teléfono del cliente</label>
                <input
                matInput
                mask="00 0000 0000"
                type="text"
                name="client_phone"
                id="client_phone"
                [(ngModel)]="client_phone"
                >
                <mat-error *ngIf="error_form && !client_phone" style="margin-left: 20px;">Campo obligatorio</mat-error>
                <mat-error *ngIf="error_form && client_phone.length > 0 && client_phone.length < 10" style="margin-left: 20px;">Teléfono inválido</mat-error>
            </div>
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
            <div *ngIf="!is_loading">
                <button  *ngIf="(validarCrearSolicitud || tipo_cotizacion == 3)" 
                class="botton-crear-solicitud"
                (click)="Cotizar(false, false, false, true)"
                [disabled]="HayError()"
                >CREAR SOLICITUD</button>
            </div>
            <div  *ngIf="is_loading" style="display: flex; justify-content: center; align-items: center;">
            <mat-spinner diameter="30"></mat-spinner>
            </div>
            <div>
                <button class="boton-cierre" (click)="modalClose(false)">Cerrar</button>
            </div>
            </div>

        </div>
    </div>
</div>