<div class="panel">
    <div class="header">
        <svg style="height: 40px; width:auto; margin-bottom:16px;" xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 75 75">
            <path id="icons8-male_user" d="M40.5,3A37.5,37.5,0,1,0,78,40.5,37.543,37.543,0,0,0,40.5,3Zm0,5.769A31.731,31.731,0,1,1,8.769,40.5,31.691,31.691,0,0,1,40.5,8.769Zm0,8.654a14.335,14.335,0,0,0-9.285,25.331A20.206,20.206,0,0,0,20.308,60.692h5.769a14.423,14.423,0,0,1,28.846,0h5.769A20.206,20.206,0,0,0,49.785,42.754,14.335,14.335,0,0,0,40.5,17.423Zm0,5.769a8.654,8.654,0,1,1-8.654,8.654A8.613,8.613,0,0,1,40.5,23.192Z" transform="translate(-3 -3)" fill="#fff"/>
          </svg>
        <span>Dashboard</span>
        <span>{{ tipo_dashboard }}</span>
    </div>
    <div class="content">
        <button mat-button class="item" [disabled]="tir" (click)="To('detalle_solicitud')">Detalle Solicitudes</button>
        <button mat-button class="item" [disabled]="admin_vendors" (click)="To('vendors')">Admin Vendors</button>
        <button mat-button class="item" [disabled]="crear_perfil" (click)="To('dashboard/administrador/crearperfil')">Registrar<br>usuario</button>
        <button mat-button class="item" (click)="To('referidos')">Refaridos</button>
        <!-- <button mat-button class="item" [disabled]="reasignar_tramite">Reasignar<br>trámite</button> -->
        <button mat-button class="item" [disabled]="reasignar_usuario" (click)="To('dashboard/administrador/reasignar')">Reasignar<br>personal</button>
        <button mat-button class="item" [disabled]="consulta_buro" (click)="To('dashboard/comite/valuacion/consultaBuro/3')">Consulta<br>a buró</button>
        <button mat-button class="item" [disabled]="crear_tramite" (click)="To('crear_cotizacion')">Iniciar<br>trámite</button>
        <button mat-button class="item" [disabled]="ver_promociones" (click)="To('promociones')">Promociones</button>
        <button mat-button class="item" [disabled]="reporte_buro" (click)="To('dashboard/administrador/reporte_buro')">Reporte Buró</button>
        <!-- <button mat-button class="item" [disabled]="false" (click)="To('dashboard/metricas/tablas')">Tablas</button> -->
        <button mat-button class="item submenu" [disabled]="configuraciones" (click)="ShowHideCatalogo()">Catálogos</button>
        <div class="sub-items" id="catalogo-menu">
            <button mat-button class="item" (click)="To('dashboard/catalogos/cotizador')">Cotización autos</button>
        </div>
        <button mat-button class="item" [disabled]="configuraciones" (click)="To('dashboard/administrador/parametros')">Configuraciones</button>
    </div>
    <div class="footer"></div>
</div>