import { ChangeDetectorRef,Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SolicitudService } from 'src/app/_services/solicitud.service';
import { first } from 'rxjs/operators';
import {referenciasM} from './modalSolicitudR';

import { AlertService, AuthenticationService, UserService } from '../../_services';

@Component({
    selector: 'app-solicitud-r',
    templateUrl: './solicitudR.component.html',
    styleUrls: ['./solicitudR.component.scss']
})

export class SolicitudRComponent implements OnInit {
	typeP: number;
    loading = false;
    submitted = false;
	returnUrl: string;
	parte = 2;
	folio = null
	solicitudService: SolicitudService
	refSolicitud:any;

	solicitudF={
		referenciasBancarias:{
			institucion1: "",
			institucion2:"",
			institucion3: "",
			tipo_credito1: "",
			tipo_credito2: "",
			tipo_credito3: "",
			monto1: "",
			monto2: "",
			monto3: "",
			antiguedad1: "",
			antiguedad2: "",
			antiguedad3: ""
		}
	}
	solicitudM={
		referencias:{
			referenciasBancarias:{
				institucion1: "",
				institucion2:"",
				institucion3: "",
				tipo_credito1: "",
				tipo_credito2: "",
				tipo_credito3: "",
				monto1: "",
				monto2: "",
				monto3: "",
				antiguedad1: "",
				antiguedad2: "",
				antiguedad3: ""
			},
			referenciasProveedor:{
				proveedor_nombre1:"",
				proveedor_nombre2:"",
				proveedor_nombre3:"",
				proveedor_contacto1:"",
				proveedor_contacto2:"",
				proveedor_contacto3:"",
				proveedor_puesto1:"",
				proveedor_puesto2:"",
				proveedor_puesto3:"",
				proveedor_promedio_compras1:"",
				proveedor_promedio_compras2:"",
				proveedor_promedio_compras3:"",
				proveedor_total1:"",
				proveedor_total2:"",
				proveedor_total3:"",
			},
			referenciasCliente:{
				cliente_nombre1:"",
				cliente_nombre2:"",
				cliente_nombre3:"",
				cliente_contacto1:"",
				cliente_contacto2:"",
				cliente_contacto3:"",
				cliente_puesto1:"",
				cliente_puesto2:"",
				cliente_puesto3:"",
				cliente_promedio_compras1:"",
				cliente_promedio_compras2:"",
				cliente_promedio_compras3:"",
				cliente_total1:"",
				cliente_total2:"",
				cliente_total3:"",
			}
		}
	}

    constructor(
		private authenticationService: AuthenticationService,
		private router: Router,
		private cdr : ChangeDetectorRef
    ) {
        // redirect to home if already logged in
	}
	ngAfterViewInit(){
		this.actualizarReferencias(referenciasM);
	}
	actualizarReferencias(valor){
		this.refSolicitud = valor;
		this.cdr.detectChanges();

	}


	changeForm(newPart) {
		console.log(newPart);
		this.parte = newPart;
	}
	changeActive(tipo: number) {
		this.typeP = tipo;
	}

    ngOnInit() {
		this.folio = JSON.parse(localStorage.getItem('client_details')).folio
		this.typeP = Number(localStorage.getItem('tipoPersona'));
		this.parte = Number(JSON.parse(localStorage.getItem('steps')).referencias) + (this.typeP == 1 ? 2 : 1)
		this.refSolicitud=referenciasM;
    }

	logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }
	
}
