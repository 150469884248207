<div class="container">
    <div class="top">
        <div class="header">
            <img class="logo" mat-button height="60px" width="auto" [routerLink]="['/dashboard']" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/logo-header.svg" alt="">
            <div>
                <button mat-button routerLink="/changePassword">Cambiar Contraseña
                    <svg style="height: 20px; width: auto;" xmlns="http://www.w3.org/2000/svg" width="31.992" height="31.992" viewBox="0 0 31.992 31.992"><defs><style>.a{fill:#3ca2d6;}</style></defs><path class="a" d="M18,2a16,16,0,1,0,16,16A16.006,16.006,0,0,0,18,2Zm0,1.391A14.6,14.6,0,1,1,3.391,18,14.594,14.594,0,0,1,18,3.391Zm0,4.868a4.164,4.164,0,0,0-4.173,4.173v2.086h-.7A2.1,2.1,0,0,0,11.041,16.6V23.56a2.1,2.1,0,0,0,2.086,2.086h9.737a2.1,2.1,0,0,0,2.086-2.086V16.6a2.1,2.1,0,0,0-2.086-2.086h-.7V12.432A4.164,4.164,0,0,0,18,8.259ZM18,9.65a2.724,2.724,0,0,1,2.782,2.782v2.086H15.214V12.432A2.724,2.724,0,0,1,18,9.65Zm-4.868,6.259H14.4a.7.7,0,0,0,.227,0h6.728a.7.7,0,0,0,.227,0h1.281a.693.693,0,0,1,.7.7V23.56a.693.693,0,0,1-.7.7H13.128a.693.693,0,0,1-.7-.7V16.6A.693.693,0,0,1,13.128,15.909ZM18,18a1.39,1.39,0,0,0-.7,2.594v.883a.7.7,0,1,0,1.391,0v-.884A1.389,1.389,0,0,0,18,18Z" transform="translate(-2 -2)"/></svg>
                </button>
                <button mat-button (click)="logout()">Cerrar Sesión
                    <svg style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="32" height="31.992" viewBox="0 0 32 31.992"><defs><style>.a{fill:#3ca2d6;}</style></defs><path class="a" d="M18,2A16,16,0,1,0,30.9,27.449a.7.7,0,1,0-1.121-.823,14.6,14.6,0,1,1,0-17.259A.7.7,0,1,0,30.9,8.543,15.976,15.976,0,0,0,18,2Zm9.73,9.73a.7.7,0,0,0-.485,1.194L31.617,17.3H16.6a.7.7,0,1,0,0,1.391H31.617l-4.377,4.377a.7.7,0,1,0,.984.984l5.5-5.5a.7.7,0,0,0,0-1.107l-5.5-5.5A.7.7,0,0,0,27.726,11.73Z" transform="translate(-2 -2)"/></svg>
                </button>
            </div>
        </div>
        <div class="secciones">
            <div>
                <div class="title">Actualizar Análisis</div>
                <span>Capacidad de Pago</span>
            </div>
        </div>
    </div>

    <div class="body">

        <div class="form">
            <div><span class="circle">1</span>
                <mat-error *ngIf="submit && !(Analisis.promedio_depositos > 0)">Campos obligatorios</mat-error>
            </div><div></div>

            <div style="width: 100%; display: flex; flex-direction: row; justify-content: flex-start;">
                <tbody class="formulario analisis" style="width: 80%;">
                    <tr>
                        <td>Ajuste de saldo</td>
                        <td><input [(ngModel)]="saldo_inicial" matInput mask="separator.2" thousandSeparator="," prefix="$ " (input)="ActualizarPromedios()"></td>
                        <td colspan="2"></td>
                    </tr>
                    <tr>
                        <th style="width: 150px;">Mes</th>
                        <th>Depositos</th>
                        <th>Retiros</th>
                        <th>Saldo Final</th>
                    </tr>
                    <tr *ngFor="let movimiento of Analisis.historial_movimientos; let idx = index">
                        <td>
                            <mat-select [(ngModel)]="movimiento.mes">
                                <mat-option *ngFor="let mes of MESES" [value]="mes">{{ mes }}</mat-option>
                            </mat-select>
                        </td>
                        <td><input matInput mask="separator.2" thousandSeparator="," prefix="$ " (input)="ActualizarPromedios()" [(ngModel)]="movimiento.depositos"></td>
                        <td><input matInput mask="separator.2" thousandSeparator="," prefix="$ " (input)="ActualizarPromedios()" [(ngModel)]="movimiento.gastos"></td>
                        <td><input  mask="separator.2" thousandSeparator="," prefix="$ " [allowNegativeNumbers]="true" readonly matInput (input)="ActualizarPromedios()" [(ngModel)]="movimiento.saldo_final"></td>
                        <td><button *ngIf="Analisis.historial_movimientos.length > 1" mat-icon-button class="button-del" (click)="BorrarMovimiento(idx)"><mat-icon>delete</mat-icon></button></td>
                    </tr>
                    <tr>
                        <td style="text-align: right;padding-right: 8px; box-sizing: border-box;">Promedio </td>
                        <td><input mask="separator.2" thousandSeparator="," prefix="$ " matInput readonly [ngModel]="Analisis.promedio_depositos | number:'1.2-2'" (ngModelChange)="Analisis.promedio_depositos=$event"  style="background-color: #C4C4C4; font-size: 1.3;"></td>
                        <td><input mask="separator.2" thousandSeparator="," prefix="$ " matInput readonly [ngModel]="Analisis.promedio_gastos | number:'1.2-2'" (ngModelChange)="Analisis.promedio_gastos=$event" style="background-color: #C4C4C4; font-size: 1.3;"></td>
                        <td><input mask="separator.2" thousandSeparator="," prefix="$ " [allowNegativeNumbers]="true" matInput readonly [ngModel]="Analisis.promedio_saldo_final | number:'1.2-2'" (ngModelChange)="Analisis.promedio_saldo_final=$event" style="background-color: #C4C4C4; font-size: 1.3;"></td>
                    </tr>
                </tbody>
                <button (click)="AgregarMovimiento()" mat-icon-button class="button-add" style="align-self: flex-end; margin-bottom: 40px;"><mat-icon>add</mat-icon></button>
            </div>

            <div><span class="circle">2</span></div><div></div>
            <div>
                <div class="input">
                    <label>Ingresos en Nómina</label>
                    <input matInput mask="separator.2" thousandSeparator="," prefix="$ " [(ngModel)]="Analisis.ingresos_nomina" (input)="ActualizarPromedios()">
                </div>
                <div class="input">
                    <label>Ingresos en Efectivo</label>
                    <input matInput mask="separator.2" thousandSeparator="," prefix="$ " [(ngModel)]="DatosAnalisis.capacidad_pago.ingresos_efectivo" readonly>
                </div>
            </div>

            <div>
                <div class="input">
                    <label>Ingresos Extras</label>
                    <input matInput mask="separator.2" thousandSeparator="," prefix="$ " [(ngModel)]="DatosAnalisis.capacidad_pago.ingresos_extra" readonly>
                </div>
            </div>

            <div class="title-section"><span class="circle">3</span><b>Matriz de cálculo <br>Capacidad de Pago</b></div>

            <div style="width: 100%!important; display: flex; flex-direction: row;">
                <tbody class="formulario analisis">
                    <tr>
                        <th></th>
                        <th>Ingresos</th>
                        <th>Gastos</th>
                        <th>Factor %</th>
                    </tr>
                    <tr>
                        <td>Comprobables (A)</td>
                        <td><input matInput [ngModel]="Analisis.comprobantes_ingresos | number:'.2-2'" readonly (ngModelChanege)="Analisis.comprobantes_ingresos= $event" readonly></td>
                        <td><input matInput [ngModel]="Analisis.comprobantes_gastos | number:'.2-2'" (ngModelChanege)="Analisis.comprobantes_gastos= $event" readonly></td>
                        <td><input matInput [ngModel]="Analisis.comprobantes_factor" (ngModelChanege)="Analisis.comprobantes_factor= $event" readonly></td>
                    </tr>
                    <tr>
                        <td>En Efectivo (B)</td>
                        <td><input matInput [ngModel]="Analisis.efectivo_ingresos | number:'.2-2'" readonly (ngModelChanege)="Analisis.efectivo_ingresos= $event" readonly></td>
                        <td><input matInput [ngModel]="Analisis.efectivo_gastos | number:'.2-2'" (ngModelChanege)="Analisis.efectivo_gastos= $event" readonly></td>
                        <td><input matInput [ngModel]="Analisis.efectivo_factor" (ngModelChanege)="Analisis.efectivo_factor= $event" readonly></td>
                    </tr>
                    <tr>
                        <td>Extra Nuevo Equipo (C)</td>
                        <td><input matInput #extraEquipoNvoIngreso [ngModel]="Analisis.extra_nuevo_equipo_ingresos | number:'.2-2'" readonly (ngModelChanege)="Analisis.extra_nuevo_equipo_ingresos= $event"></td>
                        <td><input matInput #extraEquipoNvoGasto [ngModel]="(Analisis.extra_nuevo_equipo_gastos) | number:'.2-2'" (ngModelChanege)="Analisis.extra_nuevo_equipo_gastos= $event" readonly></td>
                        <td><input matInput [ngModel]="Analisis.extra_nuevo_equipo_factor" (ngModelChange)="Analisis.extra_nuevo_equipo_factor=$event" readonly></td>
                    </tr>
                    <!-- <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><h2><span>MAX {{ Analisis.maximo_factor}}%</span></h2></td>
                    </tr> -->
                    <tr>
                        <td>Factor de Renta</td>
                        <td colspan="2"><input matInput type="number" [(ngModel)]="Analisis.factor_renta" readonly></td>
                        <td></td>
                    </tr>
                </tbody>
                <span class="puntuacion">Valor Óptimo<br>{{ Analisis.maximo_factor}}%</span>
            </div>

            <div class="title-section"><span class="circle">4</span><b>Puntuacion<br>Final</b></div> <div></div>
            <div style="width: 20%!important;">
                <input style="text-align: center; outline: none;" class="puntuacion small" #puntuacionCapacidadPago type="number" [(ngModel)]="Analisis.puntuacion_capacidad_pago" readonly>
            </div>
            <div style="width: 70%!important;">
                <div class="input">
                    <label for="Comentarios de capacidad de pago"></label>
                    <textarea maxlength="500" placeholder="comentarios" [(ngModel)]="Analisis.comentarios_capacidad_pago" rows="4"></textarea>
                    <mat-error *ngIf="submit && !Analisis.comentarios_capacidad_pago">Campo obligatorio</mat-error>
                </div>
            </div>
        </div>

       <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
        <button *ngIf="!loading" class="next-button" mat-button (click)="Actualizar()">
            Actualizar
            <!-- <mat-icon>navigate_next</mat-icon> -->
            <svg style="height: 20px; width:auto;"  xmlns="http://www.w3.org/2000/svg" width="31.25" height="31.25" viewBox="0 0 31.25 31.25"><defs><style>.next{fill:#00a5da;}</style></defs><path class="next" d="M15.625,0A15.625,15.625,0,1,0,31.25,15.625,15.626,15.626,0,0,0,15.625,0Zm-1.3,24.74V12.173L8.755,17.741l-2.21-2.21,9.079-9.081,9.081,9.081-2.21,2.21-5.569-5.568V24.74Z" transform="translate(31.25) rotate(90)"/></svg>
        </button>
    </div>

    <app-socials-footer style="width: 100%;"></app-socials-footer>
</div>