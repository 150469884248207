import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-btn-actualizar-info',
  templateUrl: './btn-actualizar-info.component.html',
  styleUrls: ['./btn-actualizar-info.component.scss']
})
export class BtnActualizarInfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
