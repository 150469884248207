import { Component, OnInit } from '@angular/core';
import { ClientStatus } from 'src/app/_models/client';
import { AuthenticationService, UserService } from 'src/app/_services';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { HostListener } from '@angular/core';
import { ComentariosService } from 'src/app/_services/comentarios.service';

@Component({
  selector: 'app-estatus-tramite',
  templateUrl: './estatus-tramite.component.html',
  styleUrls: ['./estatus-tramite.component.scss']
})


export class EstatusTramiteComponent implements OnInit {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private userService: UserService,
    private dialog: MatDialog,
    private commentsService: ComentariosService
  ) { }
  Perfil = ['CLIENTE', 'VENDOR', 'Comercial', 'Comité'];
  CurPerfil = '';
  client: ClientStatus = null;
  esSolicitud = false;
  mostrarComentarios = false
  unread_comments = 0
  id_user = 0

  _timer = null

  @HostListener('window:popstate', ['$event'])
  onpopstate(event) {
    console.log('Back button');

    this.router.navigate(['dashboard']);
  }


  ngOnInit(): void {
    const {perfil, id} = JSON.parse( localStorage.getItem('currentUser'));
    this.mostrarComentarios = localStorage.getItem('ver_comentarios') == 'true';
    this.id_user = id
    this.CurPerfil = this.Perfil[ perfil - 1];
    this.client = JSON.parse(localStorage.getItem('client_details'));
    this.esSolicitud = JSON.parse(localStorage.getItem('client_details')).procesos[0].status != 3;
    console.log('Es solicitud: ', this.esSolicitud);

    this._timer = setInterval(()=>{
      this.ObtenerMensajesSinLeer()
    }, 5000)

    if ( !this.client ) { return; }
  }

  ngOnDestroy() {
    clearInterval(this._timer)
  }

  VerTramites() {
    if ( this.userService.alertService.isOnline() ) {
      this.Navigate(['dashboard', 'ver_tramites']);
    }
  }
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  Navigate(r) {
    if ( !this.userService.alertService.isOnline() ) { return; }
    if ( r[0] == '/codeActivation' ) {
      return this.userService.hasCode().subscribe( res => this.router.navigate(r), err => {
        this.dialog.open(MessageBoxComponent, {
          width: '500px',
          data: {
            text: err.error.message,
            text3: 'Vuelve a intentarlo',
            type: 'error',
            icon: 'error'
          }
        });
      } );
    } else { this.router.navigate(r); }
  }

	cancelar(event, folio) {
      this.dialog.open(MessageBoxComponent, {
        width: '450px',
        data: {
          text: `¿Seguro que deseas cancelar<br>el trámite?`,
          type: 'confirm-cot',
          confirm: () => {
            if ( !this.userService.alertService.isOnline() ) { return; }
            const user = JSON.parse(localStorage.getItem('currentUser'));
            console.log(user.id);
            this.userService.CancelarTramite(folio, user.id)
            .subscribe( res => {
			  if (res.mas) {
				this.VerTramites();
			  } else {
				  this.logout();
			  }
            //   const confirmdialog = this.dialog.open(MessageBoxComponent, {
            //     width: '350px',
            //     data: {
            //       text: 'Éxito',
            //       text2: 'Has cancelado el trámite',
            //       type: 'success',
            //       icon: 'success'
            //     }
            //   }).afterClosed().subscribe( () => this.router.navigate(['dashboard']) );
            });
          },
          reject: () => console.log('Nel')
        }
      });
  }

  MostrarComentarios() {
    this.mostrarComentarios = true
  }

  async ObtenerMensajesSinLeer() {
    const res = await this.commentsService.ObtenerComentariosSinLeer(this.client.folio, this.id_user)
    if (res && res.unread_comments) {
      console.log(`unread: ${res.unread_comments}`);
      this.unread_comments = res.unread_comments || 0
    }
  }
  RegresarDashboard() {
    this.router.navigate(['/dashboard/solicitudes']);
  }
}
