import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AnalisisCreditoService {

  constructor() { }

     calcularG52(G53, gastosEfectivo, ingresosNuevoEquipo) {
      return (gastosEfectivo + G53) / ingresosNuevoEquipo;
     }
  
  // Función de búsqueda de objetivo para encontrar el valor de G53
       buscarObjetivoG53(valorObjetivo, G53Inicial, tolerancia, gastosEfectivo, ingresosNuevoEquipo) {
      let porcentajeRenta = G53Inicial;
      let valorDisponible = this.calcularG52(porcentajeRenta,gastosEfectivo, ingresosNuevoEquipo);
      
      while (Math.abs(valorDisponible - valorObjetivo) > tolerancia) {
          // Ajustar el valor de G53
          porcentajeRenta = porcentajeRenta + (valorObjetivo - valorDisponible); // Método de ajuste simple
          
          // Volver a calcular el valor de G52 con el nuevo valor de G53
          valorDisponible = this.calcularG52(porcentajeRenta, gastosEfectivo, ingresosNuevoEquipo);
      }
      
        return porcentajeRenta;
      }


}
