import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from 'src/app/_services';

@Component({
  selector: 'app-filtro',
  templateUrl: './filtro.component.html',
  styleUrls: ['./filtro.component.scss']
})
export class FiltroComponent implements OnInit {
  @Input() filtro:any = {}
  @Input() perfil
  @Input() id_usuario
  @Input() find_by_date = true

  @Output() onSelect = new EventEmitter
  @Output() onDateChange = new EventEmitter
  @Output() onFiltro = new EventEmitter
  @Output() onChangeCountry = new EventEmitter
  @Output() onChangeSemana = new EventEmitter
  @Input() busqueda: any; 

  empresa: any = ''
  semana_compromiso: any = ''

  custom = 0
  director_comercial = 0
  gerente_comercial = 0
  comercial = 0
  vendor = 0
  vendedor = 0

  fecha_hoy = new Date()
  fecha_hace_un_mes = new Date()
  fecha_inicial = null
  fecha_final = null
  word = ""

  fecha_inicial_error = false
  fecha_final_error = false

  constructor(
    private userService: UserService
  ) { }

  ngOnInit(): void {
    console.log("Perfil: ", this.perfil, " ID: ", this.id_usuario);
    let path = ''

    this.fecha_hace_un_mes.setDate(this.fecha_hoy.getDate() -45)

    this.fecha_inicial = `${this.fecha_hace_un_mes.getFullYear()}-${(this.fecha_hace_un_mes.getMonth() +1).toString().padStart(2,'0')}-${this.fecha_hace_un_mes.getDate().toString().padStart(2,'0')}`
    this.fecha_final = `${this.fecha_hoy.getFullYear()}-${(this.fecha_hoy.getMonth() +1).toString().padStart(2,'0')}-${this.fecha_hoy.getDate()}`
    this.DateChange()

    switch( this.perfil ){
      case 2:
        this.vendor = this.id_usuario
        path = 'hijos_vendedor'
        break
      case 3:
        this.comercial = this.id_usuario
        path = 'hijos_vendor'
        break;
      case 8:
        this.gerente_comercial = this.id_usuario
        path = 'hijos_comercial'
        break
      case 9:
      case 10:
      case 13:
        this.gerente_comercial = this.id_usuario
        path = 'hijos_comercial'
        break
      case 12:
        this.director_comercial = this.id_usuario
        path = 'hijos_gerente'
        break
      case 7:
        break
      default:
        this.custom = this.id_usuario
        path = 'hijos_director_comercial'
    }

    this.Seleccionar( this.id_usuario, this.perfil, path)
  }
  

  Seleccionar( id, id_perfil, path ){
    this.Limpiar( path )
    this.onSelect.emit({
      id, id_perfil, path
    })
  }
  limpiarFiltros(){
    this.director_comercial= 0
    this.gerente_comercial = 0
    this.comercial = 0
    this.vendor = 0
    this.vendedor = 0
    this.fecha_inicial= null;
    this.fecha_final = null;
    this.DateChange()
    this.semana_compromiso = null
  }

  Limpiar( path ){
    switch( path ){
      case 'hijos_vendedor':
        this.vendedor = 0
        break;
      case 'hijos_vendor':
        this.vendor = 0
        this.vendedor = 0
        break;
      case 'hijos_comercial':
        this.comercial = 0
        this.vendor = 0
        this.vendedor = 0
        break;
      case 'hijos_gerente':
        this.gerente_comercial = 0
        this.comercial = 0
        this.vendor = 0
        this.vendedor = 0
    }
  }


  DateChange(){
    if( !this.userService.alertService.isOnline() ) return
    this.fecha_inicial_error = false
    this.fecha_final_error = false

    const hoy = new Date()
    const fecha_inicial = new Date(this.fecha_inicial)
    const fecha_final = new Date( this.fecha_final || null)

    if( fecha_inicial < hoy ){
      if( fecha_inicial <= fecha_final && fecha_final <= hoy ){
        this.onDateChange.emit({
          fecha_inicial: this.fecha_inicial,
          fecha_final: this.fecha_final
        })
      }else{ this.fecha_final_error = true
      }
    }else{ this.fecha_inicial_error = true
    }
  }
  SemanaCompromisoChange(){
    if( !this.userService.alertService.isOnline() ) return
    this.fecha_inicial_error = false
    this.fecha_final_error = false

    const hoy = new Date()
    const fecha_inicial = new Date(this.fecha_inicial)
    const fecha_final = new Date( this.fecha_final || null)

    if( fecha_inicial < hoy ){
      if( fecha_inicial <= fecha_final && fecha_final <= hoy ){
        this.onDateChange.emit({
          fecha_inicial: this.fecha_inicial,
          fecha_final: this.fecha_final
        })
      }else{ this.fecha_final_error = true
      }
    }else{ this.fecha_inicial_error = true
    }
  }

  Filtrar(){
    this.onFiltro.emit( this.word )
  }

  ChangeCountry() {
    this.onChangeCountry.emit( this.empresa )
  }
  ChangeSemanaCompromiso(semana_compromiso) {
    this.onChangeSemana.emit( semana_compromiso )
  }
}
