import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from "@angular/material/dialog";
import { ModalCotizacionReferidosComponent } from '../../_dialogs/modal-cotizacion-referidos/modal-cotizacion-referidos.component';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ReferidosService } from 'src/app/_services/referidos.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ModalReferidosRechazoComponent } from 'src/app/_dialogs/modal-referidos-rechazo/modal-referidos-rechazo.component';
import {ScrollingModule} from '@angular/cdk/scrolling';

interface Referido {
    id: number;
    id_cliente: number;
    id_usuario: number;
    id_cotizacion: number;
    id_proceso_solicitud_referidos: number;
    names: string;
    father_last_name: string;
    mother_last_name: string;
    email: string;
    phone: string;
    folio: string | null; // valores de relaciones externas a la tabla
    estatus: string | null; //  valores de relaciones externas a la tabla
    invitation_sent: boolean;
    invitation_red: boolean;
    gift_card_sent: boolean;
    id_cat_rejected: number | null;
    token: string;
    created_at: string;
    updated_at: string; 
    deleted_at: string | null;
}

interface Cliente {
    id: number;
    id_campaign: number;
    names: string;
    father_last_name: string;
    mother_last_name: string;
    email: string;
    phone: string;
    created_at: string; 
    updated_at: string; 
    deleted_at: string | null; 
    referido: Referido[];
}
interface Contacto {
    id: number;
    email: string;
    phone: string;
  }

@Component({
    selector: 'app-referidos',
    templateUrl: './referidos.component.html',
    styleUrls: ['./referidos.component.scss'],

})
export class ReferidosComponent implements OnInit, AfterViewInit {

    constructor(
        
        private dialog: MatDialog,
        private fb: FormBuilder,
        private referridosService: ReferidosService
    ) {
    }


    @ViewChild(MatPaginator) paginator: MatPaginator;
    ELEMENT_DATA: any;
    fechaActual: string = null;
    clientes: Cliente[];
    referidos: Referido [];
    contactos: Contacto[]=[];
    campaigns=[];
    selectedClientIds: number[] = [];
    expantionDown: number | null = null;
    expandedIndex: number | null = null;
    campaign:number=0;
    loading : Boolean=true;

    collapse = true;
    form: FormGroup;


    ngAfterViewInit() {

    }
    async ngOnInit() {
        this.fechaActual = this.getFechaFormateada();
        this.setValoresClient();
        console.log("Clientes", this.clientes);
        this.campaigns= await this.referridosService.ConsultaCampañas().toPromise();

        console.log("Valores de campaña:",this.campaigns);
        
    }

    toggleReferidos(index: number) {
        this.expantionDown = this.expantionDown == index ? null : index;
        this.expandedIndex = this.expandedIndex === index ? null : index;
    }

    cotizacionReferido(referido) {
        console.log("Valor de Referido", referido)
        if(referido.id_cotizacion!=null) return; 
        const dialogRef = this.dialog.open(ModalCotizacionReferidosComponent, {
            width: '700px',
            data:{
                name: referido.names,
                email: referido.email,
                phone: referido.phone,
                id: referido.id
            }
        });
        
        dialogRef.componentInstance.updateEvent.subscribe((updated: boolean) => {
            if (updated) {
              this.setValoresClient(); 
            }
          });
        
    }

    getFechaFormateada(): string {
        const fecha = new Date();

        const diasSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
        const meses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];

        const diaSemana = diasSemana[fecha.getDay()];
        const dia = fecha.getDate();
        const mes = meses[fecha.getMonth()];
        const anio = fecha.getFullYear();

        console.log(diaSemana, dia, mes, anio);

        return `${diaSemana}, ${dia} de ${mes} de ${anio}`;
    }

    get checkboxes(): FormArray {
        return this.form.get('checkboxes') as FormArray;
    }


    selectCampaign(id){
        console.log("id campaña",id);
    }

    isChecked(cliente: any): boolean {
        return this.contactos.some(c => c.id === cliente.id);
      }

    onCheckboxChange(event: any, cliente: any) {
        let data: Contacto = {
            id: cliente.id,
            email: cliente.email,
            phone: cliente.phone
        };
        if (event.checked) {
            this.contactos.push(data);
        } else {
            this.contactos = this.contactos.filter(c => c.id !== cliente.id);
        }
        console.log('Checkbox changed:', event.checked);
        console.log('isCheck', this.isChecked);
        console.log('valor de cliente:::', cliente);
        console.log("valor del arreglo:", this.contactos);
    }

    enviarCampanas() {
        if(this.campaign>0){
            console.log("Datos a enviar",this.contactos);
            console.log("campaña",this.campaign)
        }else{

        }
    }
    selectCampaigns(campaign){
        console.log("valor de campaña::",campaign)
    }

    rejected(referido){
        console.log("rejected", referido);
        this.dialog.open(ModalReferidosRechazoComponent, {
            width: "300px",
            height: "300px",
            data: {
                id: referido.id,
                nombre: (referido.names + ' ' + referido.father_last_name),
            }
        }).componentInstance.referidoDescartado.subscribe((id: number) => {
            this.actualizarClientes(id);
        });
        
    }
    actualizarClientes(idReferido: number) {
        this.clientes = this.clientes.map(cliente => {
            cliente.referido = cliente.referido.filter(ref => ref.id !== idReferido);
            return cliente;
        });
    }
    async setValoresClient() {

        this.clientes = await this.referridosService.ConsultarReferidos().toPromise();
        console.log("Valores",this.clientes)
        this.clientes.length>0?this.loading=false:this.loading=true;
    }
}
