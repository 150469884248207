import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {
  @Input() width = 140;
  @Input() height = 168;
  @Input() url = null;
  @Input() text = '';
  @Input() rotate = false;

  img = {
    width: 0,
    height: 0
  }
  orientacion = 0
  preview = false
  constructor() { }

  ngOnInit(): void {
    this.img.width = this.width
    this.img.height = this.height
    return ( this.rotate ) ? this.RotateLeft() : null;
  }

  RotateLeft(){
    console.log(this.orientacion);
    
    this.orientacion = this.orientacion == 0 ? 3 : this.orientacion -1
    this.Ajustar()
  }

  RotateRight() {
    this.orientacion = this.orientacion == 3 ? 0 : this.orientacion +1
    this.Ajustar()
  }

  Ajustar(){
    if( this.orientacion == 1 || this.orientacion == 3 ){
      this.width = this.img.height
      this.height = this.img.width
    }else{
      this.width = this.img.width
      this.height = this.img.height
    }
  }
}
