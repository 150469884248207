import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-asignar-seguro',
  templateUrl: './asignar-seguro.component.html',
  styleUrls: ['./asignar-seguro.component.scss']
})
export class AsignarSeguroComponent implements OnInit {

  seguro_financiado = 0
  seguro_auto = 0

  constructor(
    private dialogRef: MatDialogRef<AsignarSeguroComponent>,
        @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
  }

  Guardar() {
    this.dialogRef.close({
      seguro_financiado: this.seguro_financiado,
      seguro_auto: this.seguro_auto,
    })
  }

}
