import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CotizadorAutoService {
  valores = []

  private PorcentajesPorMes = {	
    enero     : 1,
    febrero   : 0.92,
    marzo     : 0.83,
    abril     : 0.75,
    mayo      : 0.67,
    junio     : 0.58,
    julio     : 0.50,
    agosto    : 0.42,
    septiembre: 0.33,
    octubre   : 0.25,
    noviembre : 0.17,
    diciembre : 0.08,
  }

  constructor(private http: HttpClient) { 
    this.GetConfig().then(res => console.log(res))
  }

  async GetConfig() {
    const resp = await this.http.get<any>(`${environment.config.apiUrl}cotizador/config/auto`).toPromise();
    console.log(resp);
    for (const valor of resp) {
      this.valores[valor.estado] = valor
    }
    return resp
  }

  GuardarConfig(cotizador_auto) {
    return this.http.post<any>(`${environment.config.apiUrl}cotizador/config/auto`, {cotizador_auto}).toPromise();
  }

  CalcularTenencia({tipo_persona, monto, mes, estado, nuevo}) {
    console.log('CalcularTenencia: ', {tipo_persona, monto, mes, estado});
    console.log("Config: ", this.valores);
    
    const valor_estado = this.valores[estado]
    console.log(valor_estado, valor_estado.monto_tenencia);
    

    switch (estado) {
      case 'CDMX':
        return this._CalcularTenencia(monto, Number(valor_estado.monto_tenencia), tipo_persona, this.PorcentajesPorMes[mes], true)
      case 'EDOMEX':
        return this._CalcularTenencia(monto, Number(valor_estado.monto_tenencia), tipo_persona, this.PorcentajesPorMes[mes])
      case 'MORELOS' :
        return nuevo == 1 ? 0 : this._CalcularTenencia(monto, Number(valor_estado.monto_tenencia), tipo_persona, this.PorcentajesPorMes[mes])
      case 'QUERETARO':
        return 850;
    }
  }

  CalcularPlacas({estado}) {
    console.log('CalcularPlacas: ', estado);
    
    let valor_placas = 0
    switch (estado) {
      case 'CDMX':
        valor_placas = 2668 * 1.4
        break;
      case 'EDOMEX':
        valor_placas = 5500
        break;
      case 'MORELOS' :
        valor_placas = 3500 + 1058
        break
      case 'QUERETARO' :
        valor_placas = 6300
        break
    }

    return Number((valor_placas * 1.16).toFixed(0))
  }

  CalcularGPS(estado) {
    const valor_estado = this.valores[estado]
    const {gps_inicial, gps_mensualidad} = valor_estado
    return {
      gps_inicial,
      gps_mensualidad
    }
  }

  private _CalcularTenencia(monto, derechos, tipo_persona, porcentaje, isCDMX = false) {
    const monto_sin_iva = Number((monto / 1.16).toFixed(2))
    const tenencia_anual = (monto_sin_iva * 0.031) + (isCDMX ? 1500 : 0) // ver si el: 0.031 y los 1500, serán dinamicos

    const subtotal = tipo_persona == 1 && monto_sin_iva <= (isCDMX ? 250000 : 350000) // el monto de 250000 siempre será fijo
                      ? 0
                      : tenencia_anual * porcentaje

    const total = (subtotal + derechos + (isCDMX ? 1500 : 0)) * 1.16 // los 1500 serán fijos
    return Number(total.toFixed(0))

  }
}
