import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundedNumber'
})
export class RoundedNumberPipe implements PipeTransform {

  transform(value: number): string {
    return value.toFixed(2); 
  }
}