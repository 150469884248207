<h4>{{ data.text }}</h4>
<div class="container-modal">
  <mat-dialog-content class="container-btn">
    <button [ngClass]="{'active': optionSelected == 1}" (click)="chooseOpt(1)" class="btn-option">{{ data.opt1 }}</button>
    <button [ngClass]="{'active': optionSelected == 2}" (click)="chooseOpt(2)" class="btn-option">{{ data.opt2 }}</button>
    <div *ngIf="optionSelected == 1">
        <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
            <label>Número de guía</label>
            <input type="text" placeholder=" " (input)="checkInputValue($event)"  required [value]="numero_guia">
          </div>
      <div>
        <div style="position: relative; overflow: hidden; width: 0; height: 0">
          <input
            type="file"
            id="fileInput"
            accept="image/*,.pdf"
            (change)="onFileSelected($event)"
          />
        </div>
        <div style="display: flex; justify-content: center;">
        <button (click)="selectFile()" style="position: relative; z-index: 1" class="btn-carga-document">
            <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/upload-doc.svg"/>
          Cargar formato de guía
        </button>
        </div>
        <div *ngIf="selectedFileName" class="file-name">
            <p style="margin-bottom: 2px;">Archivo seleccionado: </p>
            <p>{{ selectedFileName }}</p>
        </div>
      </div>
      <div *ngIf="error_msg" class="error-msg">
        {{error_msg_validar}}
      </div>
    </div>
  </mat-dialog-content>

</div>
<mat-dialog-actions class="footer-btn">
    <div class="error-msg" *ngIf="error_msg"></div>
    <button (click)="guardar()" ><img *ngIf="!loading" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/check-green.svg" />
      <mat-progress-spinner
      *ngIf="loading"
      mode="indeterminate"
      diameter="30"
    ></mat-progress-spinner>
    <span *ngIf="!loading">Continuar</span>
    </button>
    <button (click)="cerrar()"><img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/uncheck-red.svg" />Regresar</button>
</mat-dialog-actions>
