<div class="container" *ngIf="documento">
    <div class="body">
        <!-- <h2 class="title">{{ documento.nombre_documento }}</h2> -->
        <pdf-viewer
        [src]="url"
        [autoresize]="true"
        [original-size]="false"
        >
        </pdf-viewer>
        <!-- <button mat-button [routerLink]="['../carga_documentos']">REGRESAR A DOCUMENTOS</button> -->
    </div>
    <app-socials-footer style="width: 100%;"></app-socials-footer>
</div>