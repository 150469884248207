import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageItem } from 'src/app/_models/message';

@Component({
  selector: 'app-centro-mensajeria-item',
  templateUrl: './centro-mensajeria-item.component.html',
  styleUrls: ['./centro-mensajeria-item.component.scss']
})
export class CentroMensajeriaItemComponent implements OnInit {
  @Input() message: any = {}
  @Output() onNuevoMensaje = new EventEmitter

  constructor() { }

  ngOnInit(): void {
    this.onNuevoMensaje.emit(true)
  }

}
