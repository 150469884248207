import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Directive, ElementRef, Inject, OnInit, Self } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appCdkVirtualScrollViewportResizeAware]'
})
export class CdkVirtualScrollViewportResizeAwareDirective implements OnInit {

  protected readonly destroy$: Subject<void> = new Subject();
  private visibilityObserver!: IntersectionObserver;

  constructor(
    @Self() @Inject(CdkVirtualScrollViewport) private readonly viewportComponent: CdkVirtualScrollViewport,
    private elementRef: ElementRef
  ) {}

  ngOnInit() {
    fromEvent(window, 'resize')
      .pipe(
        debounceTime(50),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.viewportComponent.checkViewportSize();
      });

    setTimeout(() => {
      this.visibilityObserver = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.viewportComponent.checkViewportSize();
          }
        });
      });

      console.log('Observar el element:', this.elementRef.nativeElement);
      this.visibilityObserver.observe(this.elementRef.nativeElement);
    }, 0);
  }

  ngOnDestroy() {
    this.visibilityObserver.disconnect();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
