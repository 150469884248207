<app-sidenav-v2
  [shortMenu]="true"
  (openNotifications)="openNotifications()"
  [commentsUnreadLength]="comments_unread.length"
  [placeholderSearch]="placeholderSearch"
>
<div class="box">
<div style="width: 100%; display: flex; justify-content: space-between;">
    <div></div>
    <button  id="btnNuevoActivo" (click)="CrearActivo()">Nuevo Activo</button>
</div>
  <div class="container-table-activos">

    <table
      class="table table-admin-activos"
      [cellSpacing]="0"
      [cellPadding]="0"
      
    >
      <thead>
        <th>Proveedor</th>
        <!-- <th>Activo</th> -->
        <th>Descripción</th>
        <th>Número de serie</th>
        <th>Cantidad</th>
        <th>Precio del activo</th>
        <th></th>
      </thead>
      <p *ngIf="!loading && Activos.length == 0">No hay activos que mostrar</p>

      <tbody *ngIf="!loading && Activos.length > 0">

      <tr *ngFor="let activo of Activos"  >
        <td>{{ activo.proveedor }}</td>
        <!-- <td>{{ activo.name }}</td> -->
        <td>{{ activo.description }}</td>
        <td>{{ activo.serial_number }}</td>
        <td>{{ activo.quantity }}</td>
        <td>{{ activo.value | currency : "MXN" }}</td>
        <td><button mat-icon-button (click)="EditarActivo(activo)"><mat-icon>edit</mat-icon></button></td>

      </tr>
     
      </tbody>
    </table>
  </div>
</div>
</app-sidenav-v2>
