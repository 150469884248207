import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-generate-csv',
  templateUrl: './generate-csv.component.html',
  styleUrls: ['./generate-csv.component.scss']
})
export class GenerateCsvComponent implements OnInit {

  tipo_activo = [
    'Vehículo de transporte',
    'Equipo de cómputo',
    'Equipo médico',
    'Otro',
  ]

  tipo_periodo = [
    'unico',
    'bianual',
    'bimestral',
    'trimestral',
    'semestral',
    'mensual',
    'anual',
  ]

  constructor(
        private dialogRef: MatDialogRef<GenerateCsvComponent>,
        @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
    console.log("Dialog open: ", this.data);
    
  }

  Cerrar() {
    this.dialogRef.close(null)
  }

  Descargar(){
    console.log("Descargando");
    
    this.dialogRef.close(this.data)
  }

}
