<div class="containerSection">
    <div>
        <div class="title">Análisis de comportamiento</div>
        <div class="title">crediticio</div>
    </div>
    <br>
    <br>
    <div>
        <div><span>Nombre</span></div>
        <div><span>{{nombre}}</span></div>
    </div>
    <div class="wrap">
        <div [ngClass]="(score == '' || score == 'menos 90 dias') ? 'resultCard1' : 'resultCard2'">
            <p [ngClass]="score === 'menos 90 dias' ? 'p2' : 'p1'">{{message}}</p>
        </div>
    </div>
</div>

<div class="row-end">
	<div></div>
	<button mat-button (click)="onSubmit()">
		Siguiente
		<svg style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><defs><style>.abc{fill:#3CA2D6;}</style></defs><path class="abc" d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0ZM22,38V18.7L13.448,27.25l-3.394-3.394L24,9.908,37.948,23.856,34.554,27.25,26,18.7V38Z" transform="translate(48) rotate(90)"/></svg>
	</button>
</div>