<div style="margin-top: 15px; width: 100%; overflow-x: auto; overflow-y: hidden;">
    <label for="select-periodo-gracia">Periodo de Gracia</label>
    <select name="select-periodo-gracia" id="select-periodo-gracia" [(ngModel)]="periodo_gracia" (change)="CargarTabla()">
        <option [value]="0">Sin periodo de gracia</option>
        <option [value]="1">1 mes de gracia</option>
        <option [value]="2">2 meses de gracia</option>
    </select>

    <table [cellSpacing]="0" [cellPadding]="0" >
        <thead>
            <tr>
                <th colspan="12" style="background-color: #AAD1F2; border-radius: 15px 15px 0 0; padding: 5px;">% Pago Inicial</th>
            </tr>
            <tr style="background-color: #AAD1F2; padding: 5px;">
    <th>Plazo</th>
    <th *ngFor="let porcentaje of porcentajes"
        [ngClass]="{
            'hide': ([27.8, 28].includes(porcentaje) && !vendors_special_msi.includes(id_vendor)) ||
                    ([10, 15, 20, 25, 27.8, 28, 30, 35, 40, 45, 50].includes(porcentaje) && vendors_special_36msi.includes(id_vendor)) ||
                    ([0, 27.8, 28].includes(porcentaje) && !vendors_special_36msi.includes(id_vendor))
        }">
        {{ porcentaje }}%
    </th>
</tr>
        </thead>
        <tbody>
            <tr *ngIf="!vendors_special_36msi.includes(id_vendor)">
                <th>6</th>
                <td class="opt_pagos_iniciales" *ngFor="let item of tabla_pago_inicial.plazo6; let i=index" (click)="Agregar(i, 6, item)" [ngClass]="{'plazo_seleccionado': pagos_iniciales_seleccionados['plazo'+6+'_'+i] && pagos_iniciales_seleccionados['plazo'+6+'_'+i].value > 0, 'hide': hide_column_indices.includes(i) && !vendors_special_msi.includes(id_vendor)}">{{item}}%</td>
            </tr>
            <tr *ngIf="!vendors_special_36msi.includes(id_vendor)">
                <th>9</th>
                <td class="opt_pagos_iniciales" *ngFor="let item of tabla_pago_inicial.plazo9; let i=index" (click)="Agregar(i, 9, item)" [ngClass]="{'plazo_seleccionado': pagos_iniciales_seleccionados['plazo'+9+'_'+i] && pagos_iniciales_seleccionados['plazo'+9+'_'+i].value > 0, 'hide': hide_column_indices.includes(i) && !vendors_special_msi.includes(id_vendor)}">{{item}}%</td>
            </tr>
            <tr *ngIf="!vendors_special_36msi.includes(id_vendor)">
                <th>12</th>
                <td class="opt_pagos_iniciales" *ngFor="let item of tabla_pago_inicial.plazo12; let i=index" (click)="Agregar(i, 12, item)" [ngClass]="{'plazo_seleccionado': pagos_iniciales_seleccionados['plazo'+12+'_'+i] && pagos_iniciales_seleccionados['plazo'+12+'_'+i].value > 0, 'hide': hide_column_indices.includes(i) && !vendors_special_msi.includes(id_vendor)}">{{item}}%</td>
            </tr>
            <tr *ngIf="!vendors_special_36msi.includes(id_vendor)">
                <th>15</th>
                <td class="opt_pagos_iniciales" *ngFor="let item of tabla_pago_inicial.plazo15; let i=index" (click)="Agregar(i, 15, item)" [ngClass]="{'plazo_seleccionado': pagos_iniciales_seleccionados['plazo'+15+'_'+i] && pagos_iniciales_seleccionados['plazo'+15+'_'+i].value > 0, 'hide': hide_column_indices.includes(i) && !vendors_special_msi.includes(id_vendor)}">{{item}}%</td>
            </tr>
            <tr *ngIf="!vendors_special_36msi.includes(id_vendor)">
                <th>18</th>
                <td class="opt_pagos_iniciales" *ngFor="let item of tabla_pago_inicial.plazo18; let i=index" (click)="Agregar(i, 18, item)" [ngClass]="{'plazo_seleccionado': pagos_iniciales_seleccionados['plazo'+18+'_'+i] && pagos_iniciales_seleccionados['plazo'+18+'_'+i].value > 0, 'hide': hide_column_indices.includes(i) && !vendors_special_msi.includes(id_vendor)}">{{item}}%</td>
            </tr>
            <tr *ngIf="!vendors_special_36msi.includes(id_vendor)">
                <th>21</th>
                <td class="opt_pagos_iniciales" *ngFor="let item of tabla_pago_inicial.plazo21; let i=index" (click)="Agregar(i, 21, item)" [ngClass]="{'plazo_seleccionado': pagos_iniciales_seleccionados['plazo'+21+'_'+i] && pagos_iniciales_seleccionados['plazo'+21+'_'+i].value > 0, 'hide': hide_column_indices.includes(i) && !vendors_special_msi.includes(id_vendor)}">{{item}}%</td>
            </tr>
            <tr *ngIf="!vendors_special_36msi.includes(id_vendor)">
                <th>24</th>
                <td class="opt_pagos_iniciales" *ngFor="let item of tabla_pago_inicial.plazo24; let i=index" (click)="Agregar(i, 24, item)" [ngClass]="{'plazo_seleccionado': pagos_iniciales_seleccionados['plazo'+24+'_'+i] && pagos_iniciales_seleccionados['plazo'+24+'_'+i].value > 0, 'hide': hide_column_indices.includes(i) && !vendors_special_msi.includes(id_vendor)}">{{item}}%</td>
            </tr>
            <tr *ngIf="vendors_special_30msi.includes(id_vendor)">
                <th>30</th>
                <td class="opt_pagos_iniciales" *ngFor="let item of tabla_pago_inicial.plazo30; let i=index" (click)="Agregar(i, 30, item)" [ngClass]="{'plazo_seleccionado': pagos_iniciales_seleccionados['plazo'+30+'_'+i] && pagos_iniciales_seleccionados['plazo'+30+'_'+i].value > 0, 'hide': hide_column_indices.includes(i) && !vendors_special_msi.includes(id_vendor)}">{{item}}%</td>
            </tr>
            <tr *ngIf="vendors_special_36msi.includes(id_vendor)">
                <th>36</th>
                <td class="opt_pagos_iniciales" *ngFor="let item of tabla_pago_inicial.plazo36; let i=index" (click)="Agregar(i, 36, item)" [ngClass]="{'plazo_seleccionado': pagos_iniciales_seleccionados['plazo'+36+'_'+i] && pagos_iniciales_seleccionados['plazo'+36+'_'+i].value > 0, 'hide': hide_column_indices.includes(i) && !vendors_special_msi.includes(id_vendor)}">{{item}}%</td>
            </tr>
        </tbody>
    </table>
    <div>
        Costo Financiero: {{this.porcentaje_pago_inicial || 0}}%
    </div>

    <div>
        <input type="checkbox" name="aum_valor_factura" id="aum_valor_factura" [(ngModel)]="aumentar_valor_factura" (change)="EmitSeleccion('plus')"> ¿Aumentar al valor Factura? <br>
        <input type="checkbox" name="dis_valor_factura" id="dis_valor_factura" [(ngModel)]="disminuir_valor_factura" (change)="EmitSeleccion('minus')"> ¿Disminuir al valor Factura?
    </div>
</div>