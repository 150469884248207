import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-estado-formulario',
  templateUrl: './estado-formulario.component.html',
  styleUrls: ['./estado-formulario.component.scss']
})
export class EstadoFormularioComponent implements OnInit {
  @Input() Paginas = []
  @Input() Actual = 1
  
  constructor() { }

  ngOnInit(): void {

  }

}
