import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { RechazarDocumentoComponent } from 'src/app/_dialogs/rechazar-documento/rechazar-documento.component';
import { ComentariosService } from 'src/app/_services/comentarios.service';
import { PerfilService } from 'src/app/_services/perfil.service';

@Component({
  selector: 'app-update-status-list',
  templateUrl: './update-status-list.component.html',
  styleUrls: ['./update-status-list.component.scss']
})
export class UpdateStatusListComponent implements OnInit {
  @Input() tramites = {
    comite: [],
    aprobadas: [],
    aprobadas_con: [],
    info_adicional: [],
    rechazadas: [],
    cerrados: [],
  }
  id_usuario = null
  @Output() onChange = new EventEmitter
  constructor(
    private dialog: MatDialog,
    private perfilService: PerfilService,
    private comentarioService: ComentariosService
  ) { }

  ngOnInit(): void {
    const { id } = JSON.parse(localStorage.getItem('currentUser'))
    this.id_usuario = id
  }

  ActualizarStatus(tramite, status) {
    console.log('Actualizar', tramite, status, tramite.folio, tramite.id_solicitud);
    let status_nuevo = ''
    let comentario = ''
    
    switch (status) {
      case 1: status_nuevo = 'Aprobada'; break;
      case 2: status_nuevo = 'Condicionada'; break;
      case 3: status_nuevo = 'Información Adicional'; break;
      case 4: status_nuevo = 'Rechazada'; break;
      case 99: status_nuevo = 'Cerrada'; break;
    }

    this.dialog.open(MessageBoxComponent, {
      width: '500px',
      data: {
        text: `
          Folio: ${tramite.folio}
        `,
        // text2: `Status actual:\n${status_actual}`,
        text2: `¿Estas seguro que deseas cambiar la resolución a: ${status_nuevo}?`,
        type: 'confirm',
        icon: 'info',
        confirm: async ()=>{
          console.log('Cambiar');
          try {
            if ([2,3].includes(status)) {
              comentario = await this.SolicitarComentario()
              await this.comentarioService.CrearComentario(tramite.folio, this.id_usuario, comentario)
            }
            await this.perfilService.CambiarResolucionComite( tramite.id_resolucion_comite || 0, status, tramite.folio, tramite.id_solicitud, tramite.nombre_cliente )
            await this.ChangeSuccess()
            this.onChange.emit();
          } catch(e) {
            console.log('[Error]: ', e);
            this.ChangeError()
          }
        },
        reject: () => {
          console.log('Canceling');
          
        }
      }
    });
  }

  async SolicitarComentario() {
    const comentario = await this.dialog.open(RechazarDocumentoComponent, {
      disableClose: true,
      width: '500px',
      data: {
        required: true,
        closeBtn: false,
        textBtn: 'Enviar'
      }
    }).afterClosed().toPromise()
    return comentario
  }

  ChangeError() {
    this.dialog.open(MessageBoxComponent, {
      width: '500px',
      data: {
        text: `
          Cambio de Resolución
        `,
        text3: `Hubo un error al realizar el cambio de resolución`,
        type: 'error',
        icon: 'error'
      }
    })
  }

  async ChangeSuccess() {
    await this.dialog.open(MessageBoxComponent, {
      width: '500px',
      data: {
        text: `
          Cambio de Resolución
        `,
        // text2: `Status actual:\n${status_actual}`,
        text3: `Se ha cambiado correctamente`,
        type: 'success',
        icon: 'success'
      }
    }).afterClosed().toPromise()
  }
}
