import { Component, OnInit } from '@angular/core';
import { perfilDirectorGeneral, perfilDirectorComercial, perfilGerenteComercial } from '../reasignacion/opciones/opciones';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PerfilService } from 'src/app/_services/perfil.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/_services';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { MatDialog } from '@angular/material/dialog';

/* tslint:disable */
@Component({
  selector: 'app-reasignacion-select',
  templateUrl: './reasignacion-select.component.html',
  styleUrls: ['./reasignacion-select.component.scss']
})
export class ReasignacionSelectComponent implements OnInit {
  REASIGNADO = "Usuario re-asignado correctamente"
  perfil;
  perfilNombre;
  user;
  opciones;
  primerSelect = 0;
  segundoSelect = 0;
  usrReassign = [];
  usrCargo = [];
  submited: boolean;
  loading: boolean;

  constructor(
	private userService: UserService,
    private authenticationService: AuthenticationService,
    private router: Router,
	private perfilService: PerfilService,
	private ar: ActivatedRoute,
	private formBuilder: FormBuilder,
	private dialog:MatDialog
  ) { }

  async ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.perfil = Number(this.user.perfil);
	console.log(this.ar.snapshot.params.tipo);
	this.submited = false;
	this.loading = false;
    // console.log(this.user);
    let users = await this.perfilService.getUsuarios(Number(this.perfil), this.user.id);
	// console.log(users);x
	users = users['children'];
    // tslint:disable-next-line: no-string-literal
	// this.addUsers(users['hijos_comercial']);
	// this.addUsers(users['hijos_director_comercial']);
	// this.addUsers(users['hijos_gerente']);
	// this.addUsers(users['hijos_vendedor']);
	// this.addUsers(users['hijos_vendor']);
	this.selectUsers(this.ar.snapshot.params.tipo);
	console.log(this.usrReassign);
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  async selectUsers(type:string){
	let users = await this.perfilService.getUsuarios(Number(this.perfil), this.user.id);
	users = users['children'];
	let reassign = 'bo';
	let cargo = 'bo';
	  switch(type){
		case 'dc':
			reassign = 'hijos_director_comercial'; 
			cargo = 'hijos_director_comercial'; 
			break;
		case 'gc':
			reassign = 'hijos_gerente'; 
			cargo = 'hijos_director_comercial'; 
			break;
		case 'co':
			reassign = 'hijos_comercial'; 
			cargo = 'hijos_gerente'; 
			break;
		case 've':
			reassign = 'hijos_vendor'; 
			cargo = 'hijos_comercial'; 
			break;
		case 'ven':
			reassign = 'hijos_vendedor';
			cargo = 'hijos_vendor'; 
			break;
		default:
			reassign = 'hijos_director_comercial';
			cargo = 'hijos_director_comercial'; 
	  }
console.log('reassign: ', reassign)
console.log('cargo: ', cargo)
	  for(let i = 0; i < users[reassign].length; i++){
		this.usrReassign.push(users[reassign][i]);
	  }
	  for(let i = 0; i < users[cargo].length; i++){
		this.usrCargo.push(users[cargo][i]);
	  }
	  console.log('reassign: ', this.usrReassign)
console.log('cargo: ', this.usrCargo)
  }

  async onSubmit(){
	if( !this.userService.alertService.isOnline() ) return
	  this.submited = true;
	  this.loading = true;
	if(this.primerSelect == 0 || this.segundoSelect == 0) { this.submited = false; this.loading = false; return;}

	console.log('cargo: ', this.primerSelect)
	console.log('reas: ', this.segundoSelect)

	let res:any = await this.perfilService.staffReassign(this.segundoSelect, this.primerSelect);
	this.submited = false;
	this.loading = false;
	console.log(res);
	if( res.message == this.REASIGNADO){
		return this.dialog.open(MessageBoxComponent, {
			width: "500px",
			data: {
				text: "Éxito",
				text2: res.message,
				type: "success",
				icon: "success"
			}
		}).afterClosed().subscribe( ()=>{
			this.router.navigate(['/dashboard'])
		})
	}else{
		return this.dialog.open(MessageBoxComponent, {
			width: "500px",
			data: {
				text: "Error",
				text2: res.message,
				type: "error",
				icon: "error"
			}
		})
	}

  }
}
