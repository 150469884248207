<div class="container">
    <div class="header">
        <img class="logo" mat-button height="60px" width="auto" [routerLink]="['/dashboard']" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/logo-header.svg" alt="">
        <button mat-button routerLink="/login">Iniciar sesión</button>
    </div>
    <section class="section-1">
        <div>
            <h2>Solicitud de Arrendamiento</h2>
            <p>Muéstranos hacia dónde vas y<br>
            déjanos recorrer el camino contigo.</p>
        </div>
        <div class="descarga">
            <span>Descarga nuestra App</span>
            <span>para seguir con tu proceso desde cualquier parte</span>
        </div>
    </section>
    <section class="section-2">
        <div class="registrate">
            <button *ngIf="false" mat-button routerLink="/register">Regístrate</button>
        </div>
        <div class="disponible">
            <div>
                <span>Disponible para</span>
                <button mat-button class="app_android"></button>
                <button mat-button class="app_ios"></button>
            </div>
            <img height="450px" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/phones.png" alt="phones">
        </div>
    </section>
    <app-socials-footer></app-socials-footer>
</div>