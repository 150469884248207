import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-select-invoice-date-init',
  templateUrl: './select-invoice-date-init.component.html',
  styleUrls: ['./select-invoice-date-init.component.scss']
})
export class SelectInvoiceDateInitComponent implements OnInit {
  date: String = ''
  constructor(
    private dialogRef: MatDialogRef<SelectInvoiceDateInitComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
  }

  FechaSeleccionada() {
    this.dialogRef.close(this.date)
  }

}
