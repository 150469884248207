<div class="vista-previa-card">
    <div class="header-card">
        <div style="width: 30%; font-weight:500">Folio: {{ vista_previa.folio}}</div>
        <div style="color: #0277D9; display: flex;"><img style="margin-right: 5px;" [src]="!vista_previa.data.request.auto ? 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/equipo_medico_icon.svg' : 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/auto_icon.svg'"/>{{!vista_previa.data.request.auto ? 'Equipo médico' : 'Auto'}}</div>
    </div>
    <div class="line"></div>
    <div class="body-card">
        <div>
            <div class="title">Producto</div>
            <div class="name">{{vista_previa.datos_equipo_arrendar.activoArrendar}}</div>
        </div>
        <div>
            <div class="title">Vendor</div>
            <div class="name">{{vista_previa.data.vendor.fullname}}</div>
        </div>
        <div>
            <div class="title">Precio</div>
            <div class="name">{{vista_previa.datos_equipo_arrendar.precioEquipoIva | currency:'MXN' }}</div>
        </div>
        <div>
            <div class="title">Fecha</div>
            <div class="name">{{ vista_previa.create_at | customDate }}</div>
        </div>
        <!-- <div>
            <div class="title">Solicitud</div>
            <div class="name">312086</div>
        </div> -->
    </div>
    <div class="container-buttons">
        <div class="button-sec">
            <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/cotizacion-vp.svg" />
            <div (click)="VerVista()">Ver cotización</div>
        </div>
        <div class="button-sec-gr">
            <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/asignar-sol.svg" />
            <div (click)="AsignarVistaPreviaFolio()">Crear solicitud</div>
        </div>
    </div>
</div>
