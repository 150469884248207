import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService, ProductsService } from 'src/app/_services';
import { Product } from './../../../_models/product'
import { Perfil } from './../../../_models/perfiles'
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { ComentariosService } from 'src/app/_services/comentarios.service';
import {CdkVirtualScrollViewport, ScrollingModule} from '@angular/cdk/scrolling';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit,AfterViewInit {
  empresa = ''
  PERFILES_VENDOR = [2,11]
  perfil
  id_usuario
  tipo_dashboard
  Productos: Product[] = []
  ProductosFiltro: Product[] = []
  Filtro = ''
  IDvendor = 0
  isVendor = false
  View = {
    view_list: false,
    view_module: true
  }
  comments_unread = []
  show_notifications = false
  total_unread_comments = 0
  placeholderSearch="Buscar en productos"
  loading= true;
  product_with_msi = false
  show_msi = false;
  constructor(
    private productosService: ProductsService,
    private authenticationService: AuthenticationService,
    private comentariosServicios: ComentariosService,
    private router: Router,
    private dialog:MatDialog,
  ) { }
  ngAfterViewInit() {
    if (this.viewport) {
        this.viewport.checkViewportSize();
      }
}

  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;

  ngOnInit(): void {
    this.loading = true

    localStorage.removeItem('producto')
    const { id, perfil, perfil_master, id_master } = JSON.parse(localStorage.getItem('currentUser'))

    this.IDvendor = this.PERFILES_VENDOR.includes(Number(perfil)) ? Number(id) : this.PERFILES_VENDOR.includes(Number(perfil_master)) ? Number(id_master) : 0
    this.isVendor = this.PERFILES_VENDOR.includes(Number(perfil)) ? true : false

    console.log('Vendor: ', this.IDvendor);
    

    this.perfil = Number(perfil)
    this.id_usuario = Number(id)
    this.tipo_dashboard = Perfil[this.perfil]
    this.ObtenerProductos()
    this.comentariosServicios.ObtenerComentariosSinLeer('', this.id_usuario).then( value => {
      console.log(`Comentarios: `, value);
      this.comments_unread = value
      this.comments_unread.forEach(comment => this.total_unread_comments += Number(comment.unread_comments))
    })
    this.Filtrar('')

  }

  async ObtenerProductos() {
    console.log("entro a obtener productos");
    this.loading = true;
    console.log("LOADING", this.loading);
    try {
      const data = {
        vendor_id: this.IDvendor || 0,
        user_id: this.id_usuario || 0,
        perfil_id: this.perfil,
        country: this.empresa,
      };
  
      const res = await this.productosService.ObtenerProductosVendor(data).toPromise();
      this.Productos = res.data.map(product => {
        try {
          const costoF = JSON.parse(product.costo_financiero);
          return {
            ...product,
            show_msi: costoF.show_msi
          };
        } catch (error) {
          console.error(`Error parsing costo_financiero for product ID: ${product.id}`, error);
          return {
            ...product,
            show_msi: false
          };
        }
      });
      console.log(this.Productos);
      this.loading = false;
      this.Filtrar(this.Filtro);
    } catch (error) {
      console.error('Error al obtener productos:', error);
      this.loading = false;
    }
  }
  
  Filtrar(filtro: string) {
    this.ProductosFiltro = this.Productos.filter(producto => 
      producto.name.toLowerCase().includes(filtro.toLowerCase()) ||
      producto.model.toLowerCase().includes(filtro.toLowerCase()) ||
      producto.year.toLowerCase().includes(filtro.toLowerCase()) ||
      producto.warranty.toLowerCase().includes(filtro.toLowerCase()) ||
      producto.customer_price.toString().includes(filtro.toLowerCase()) ||
      producto.suggested_customer_price.toString().includes(filtro.toLowerCase()) ||
      producto.nombres.toLowerCase().includes(filtro.toLowerCase()) 
    );
  }
  

  Cotizar(Producto: Product) {
    console.log('Cotizar', Producto);
    localStorage.setItem('cotizar_producto', JSON.stringify(Producto))
    this.router.navigate(['/crear_cotizacion']);
  }

  Crear() {
    this.router.navigate(['/productos/crear']);
  }

  Editar(Producto: Product) {
    console.log('Editar', Producto);
    localStorage.setItem('producto', JSON.stringify(Producto))
    this.router.navigate(['/productos/editar']);
  }

  async Copiar(Producto: Product) {
    console.log('Copiar', Producto);
    try {
      await this.productosService.CrearProducto(Producto).toPromise()
      this.ShowDialog("success", "Se ha copiado el producto con exito")
      this.ObtenerProductos()
    } catch(e) {
      console.log(e);
      this.ShowDialog("error", "Hubo un error al copiar el producto.", "Vuelve a intentarlo")
    }
    
  }

  async Eliminar(Producto: Product) {
    try {
      console.log('Eliminar', Producto);
      this.ShowDialog("confirm", "¿Estas seguro que deseas eliminar el producto?", Producto.name, async () => {
        await this.productosService.EliminarProducto(Producto.id).toPromise()
        this.ShowDialog("success", "Se ha eliminado el producto con exito")
        this.ObtenerProductos()
      }, () => {
        console.log("Cancelado")
      })

    } catch(e) {
      console.log(e);
      this.ShowDialog("error", "Hubo un error al eliminar el producto.", "Vuelve a intentarlo")
    }
  }

  ShowDialog(type, text, text2 = '', confirm = null, reject = null) {
    this.dialog.open(MessageBoxComponent, {
      width: "500px",
      data: {
        text,
        text2,
        type,
        icon: type,
        confirm,
        reject
      },
    });
  }

  ChangeView(view) {
    this.View.view_list = false
    this.View.view_module = false

    this.View[view] = true
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
  openNotifications(){
    console.log(this.show_notifications)
    this.show_notifications = !this.show_notifications
  }
  setEmpresa(empresa: string) {
    this.empresa = empresa;
    this.ObtenerProductos();
  }
}
