import { Component, OnInit } from '@angular/core';
import { MessageItem } from 'src/app/_models/message';
import { ComentariosService } from 'src/app/_services/comentarios.service';

@Component({
  selector: 'app-centro-mensajeria',
  templateUrl: './centro-mensajeria.component.html',
  styleUrls: ['./centro-mensajeria.component.scss']
})
export class CentroMensajeriaComponent implements OnInit {
  nuevo_mensaje = ''
  id_user = 0
  folio = ''
  date_comment = null

  mensajes = []

  _timer = null
  notification = null
  constructor(
    private comentariosService: ComentariosService
  ) { }

  ngOnInit(): void {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'))
    const client_details = JSON.parse(localStorage.getItem('client_details'))

    this.id_user = currentUser.id
    this.folio = client_details.folio
    this.ObtenerComentarios()

    this.notification = document.querySelector("#new_comment_sound")

    this._timer = setInterval(async ()=>{
      const res = await this.comentariosService.ObtenerComentariosSinLeer(this.folio, this.id_user)
    if (res && res.unread_comments && res.unread_comments > 0) {
      console.log(`unread: ${res.unread_comments}`);
      this.notification.play()
      this.ObtenerComentarios()
    }
    }, 5000)
  }

  ngOnDestroy(){
    clearInterval(this._timer)
  }

  async ObtenerComentarios() {
    const res = await this.comentariosService.ObtenerComentarios(this.folio, this.id_user)
    if (res.comments && res.comments.length) {
      this.mensajes = res.comments
    }
  }

  async CrearComentario() {
    if (this.nuevo_mensaje.length == 0) return
    await this.comentariosService.CrearComentario(this.folio, this.id_user, this.nuevo_mensaje)
    this.nuevo_mensaje = ''
    await this.ObtenerComentarios()
  }

  ScrollDown() {
    var element = document.querySelector(".messages");
    element.scrollTop = element.scrollHeight
  }
}
