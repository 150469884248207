import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cotizaciones',
  templateUrl: './cotizaciones.component.html',
  styleUrls: ['./cotizaciones.component.scss']
})
export class CotizacionesComponent implements OnInit {
  @Input() documentos = []

  documentos_filtrados = []
  filter_word = ''
  folio = null
  cliente = null
  constructor(private router:Router) { }

  ngOnInit(): void {
    const { folio, cliente } = JSON.parse(localStorage.getItem('client_details'))
    this.folio = folio
    this.cliente = cliente
    console.log("Documentos");
  }
  ngOnChanges(): void {
    // Se mantiene el filtro despues de haber un cambio en el estatus de los procesos
    
    if( this.documentos.length ) this.filtrar(this.filter_word)
    console.log("aqui", this.documentos_filtrados);
  }

 filtrar(word) {
    this.filter_word = word
    this.documentos_filtrados = this.documentos.filter( 
      documento => documento.id.toString().includes(word) || 
                 documento.fecha.toLowerCase().includes(word.toLowerCase())
    )
  }

  DetalleCotizacion(version, url, id_cotizacion, approved){
    if( this.CotizacionAprobada() ) return
    console.log(version, url);
    localStorage.setItem('cotizacion', JSON.stringify({
      version, url, id_cotizacion
    }))
    this.router.navigateByUrl('/dashboard/detalle_cotizacion')
  }

  CotizacionAprobada(){
    const cot = this.documentos.filter( doc=> doc.approved)
    console.log(cot);
    
    return cot.length
  }
}
