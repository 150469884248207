import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/_services';

@Component({
  selector: 'app-dashboard-administrador',
  templateUrl: './dashboard-administrador.component.html',
  styleUrls: ['./dashboard-administrador.component.scss']
})
export class DashboardAdministradorComponent implements OnInit {
  constructor(private router: Router,
    private authenticationService:AuthenticationService,) { }

  ngOnInit(): void {
  }
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  redirectPerfil(){
    this.router.navigate(['/login']);
  }

  redirectParametros(){
    this.router.navigate(['/login']);
  }
}
