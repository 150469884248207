<div class="container">
    <div class="top">
        <div class="header">
            <img class="logo" mat-button height="60px" width="auto" [routerLink]="['/dashboard']" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/logo-header.svg" alt="">
            <div>
                <button mat-button routerLink="/changePassword">Cambiar Contraseña
                    <svg style="height: 20px; width: auto;" xmlns="http://www.w3.org/2000/svg" width="31.992" height="31.992" viewBox="0 0 31.992 31.992"><defs><style>.a{fill:#3ca2d6;}</style></defs><path class="a" d="M18,2a16,16,0,1,0,16,16A16.006,16.006,0,0,0,18,2Zm0,1.391A14.6,14.6,0,1,1,3.391,18,14.594,14.594,0,0,1,18,3.391Zm0,4.868a4.164,4.164,0,0,0-4.173,4.173v2.086h-.7A2.1,2.1,0,0,0,11.041,16.6V23.56a2.1,2.1,0,0,0,2.086,2.086h9.737a2.1,2.1,0,0,0,2.086-2.086V16.6a2.1,2.1,0,0,0-2.086-2.086h-.7V12.432A4.164,4.164,0,0,0,18,8.259ZM18,9.65a2.724,2.724,0,0,1,2.782,2.782v2.086H15.214V12.432A2.724,2.724,0,0,1,18,9.65Zm-4.868,6.259H14.4a.7.7,0,0,0,.227,0h6.728a.7.7,0,0,0,.227,0h1.281a.693.693,0,0,1,.7.7V23.56a.693.693,0,0,1-.7.7H13.128a.693.693,0,0,1-.7-.7V16.6A.693.693,0,0,1,13.128,15.909ZM18,18a1.39,1.39,0,0,0-.7,2.594v.883a.7.7,0,1,0,1.391,0v-.884A1.389,1.389,0,0,0,18,18Z" transform="translate(-2 -2)"/></svg>
                </button>
                <button mat-button (click)="logout()">Cerrar Sesión
                    <svg style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="32" height="31.992" viewBox="0 0 32 31.992"><defs><style>.a{fill:#3ca2d6;}</style></defs><path class="a" d="M18,2A16,16,0,1,0,30.9,27.449a.7.7,0,1,0-1.121-.823,14.6,14.6,0,1,1,0-17.259A.7.7,0,1,0,30.9,8.543,15.976,15.976,0,0,0,18,2Zm9.73,9.73a.7.7,0,0,0-.485,1.194L31.617,17.3H16.6a.7.7,0,1,0,0,1.391H31.617l-4.377,4.377a.7.7,0,1,0,.984.984l5.5-5.5a.7.7,0,0,0,0-1.107l-5.5-5.5A.7.7,0,0,0,27.726,11.73Z" transform="translate(-2 -2)"/></svg>
                </button>
            </div>
        </div>
        <div class="secciones">
            <div class="title">Actualizar <br>Aval</div>
        </div>

    </div>
    <div class="body">
        <div style="text-align: start; display: flex;"><app-go-bak></app-go-bak>Regresar</div>

        <div class="formularioColumnas">
            <div class="form">
                <div>
                    <div class="input">
                        <label>Nombre</label>
                        <input matInput 
                        [specialCharacters]="['space']"
                        [patterns]="customPattern"
                        mask="S{100}"
                        [(ngModel)]="DatosAval.nombre">
                        <mat-error *ngIf="submit && !DatosAval.nombre">Campo obligatorio</mat-error>
                    </div>
                    <div class="input">
                        <label>Ocupación</label>
                        <input matInput 
                        [specialCharacters]="['space']"
                        [patterns]="customPattern"
                        mask="S{100}"
                        [(ngModel)]="DatosAval.ocupacion">
                        <mat-error *ngIf="submit && !DatosAval.ocupacion">Campo obligatorio</mat-error>
                    </div>
                    <div class="input">
                        <label>Tiempo de conocerlo</label>
                        <input [specialCharacters]="['space']" [patterns]="customPattern" mask="E{100}" matInput [(ngModel)]="DatosAval.tiempo_conocerlo">
                        <mat-error *ngIf="submit && !DatosAval.tiempo_conocerlo">Campo obligatorio</mat-error>
                    </div>
                    <div class="input">
                        <label>¿Lo recomienda?  <mat-slide-toggle style="padding-left: 100px;" [(ngModel)]="DatosAval.recomendacion"></mat-slide-toggle></label>
                        <mat-error *ngIf="submit && !DatosAval.recomendacion">Campo obligatorio</mat-error>
                    </div>
                </div>
                <div>
                    <div class="input">
                        <label>Parentesco</label>
                        <input matInput 
                        [specialCharacters]="['space']"
                        [patterns]="customPattern"
                        mask="S{100}"
                        [(ngModel)]="DatosAval.parentesco">
                        <mat-error *ngIf="submit && !DatosAval.parentesco">Campo obligatorio</mat-error>
                    </div>
                    <div class="input">
                        <label>Teléfono de casa</label>
                        <input mask="00 0000 0000" placeholder="( _ _ )" matInput [(ngModel)]="DatosAval.telefonoCasa">
                        <mat-error *ngIf="submit && !(DatosAval.telefonoCasa.length == 10)">Campo obligatorio</mat-error>
                    </div>
                    <div class="">
                        <label>Domicilio</label>
                        <textarea rows="4" cols="50" maxlength="100" placeholder="Calle, número exterior, interior, Colonia, Delegación o Municipio, Ciudad, o Población, Estado, C.P." 
                        [(ngModel)]="DatosAval.domicilio"></textarea><br>
                        <mat-error *ngIf="submit && !DatosAval.domicilio">Campo obligatorio</mat-error>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="comentarios" *ngIf="mostrarComentarios">
            <label style="display: flex;">Comentarios</label>
            <textarea style= "width: 88%;" rows="6" maxlength="500" placeholder="Comentarios" [(ngModel)]="comentarios_caracter"></textarea>
            <mat-error *ngIf="submit && !comentarios_caracter">Campo obligatorio</mat-error>
            <!-- <mat-error *ngIf="submit && !Caratula.comentarios">Campo obligatorio</mat-error> -->
        </div>
        <div class="tituloPerfilAval" *ngIf="mostrarPerfilAval">
            Perfil <br>del Aval
        </div>
        <div class="containerPerfilAval" style="width: inherit;" *ngIf="mostrarPerfilAval">
            <div class="divPerfilAval rubros" style="width: 60%;">
                <div class="rubro">BC Score</div>
                <div class="rubro">ICC</div>
                <div class="rubro">Documentación</div>
                <div class="rubro">Parentesco</div>
                <div class="rubro">Ocupación</div>
            </div>
            <div class="divPerfilAval" style="width: 80%;">
                <div style="padding-bottom: 10px;">Calificar</div>
                <div class="input" style="margin-top: 0;">
                    <input type="number" class="center" matInput [(ngModel)]="DatosAval.calificacion1_BCscore"  (input)="CambiarPuntuacionAval()" max="3" maxlength="3">
                </div>
                <div class="input">
                    <input type="number" class="center" matInput [(ngModel)]="DatosAval.calificacion1_ICC" (input)="CambiarPuntuacionAval()" maxlength="4">
                </div>
                <div class="input">
                    <mat-select class="center"
                        [(ngModel)]="DatosAval.calificacion1_Docs"
                        (selectionChange)="CambiarPuntuacionAval()">
                        <mat-option></mat-option>
                        <mat-option value="OK">O.K.</mat-option>
                    </mat-select>
                    <!-- <input type="number" class="center" matInput [(ngModel)]="DatosAval.calificacion1_Docs" (input)="CambiarPuntuacionAval()"> -->
                </div>
                <div class="input">
                    <input class="center" matInput [(ngModel)]="DatosAval.parentesco" (input)="CambiarPuntuacionAval()" readonly>
                </div>
                <div class="input">
                    <input class="center" matInput [(ngModel)]="DatosAval.ocupacion"  (input)="CambiarPuntuacionAval()" readonly>
                </div>
            </div>
            <div class="divPerfilAval reglas">
                <div class="input">
                    <input class="center" matInput [(ngModel)]="DatosAval.descripcion_BCscore" readonly>
                </div>
                <div class="input">
                    <input class="center" matInput [(ngModel)]="DatosAval.descripcion_ICC" readonly>
                </div>
                <div class="input">
                    <input class="center" matInput [(ngModel)]="DatosAval.descripcion_Docs" readonly>
                </div>
                <div class="input">
                    <input class="center" matInput readonly>
                </div>
                <div class="input">
                    <input class="center" matInput readonly>
                </div>
            </div>
            <div class="divPerfilAval puntuacion">
                <div style="padding-bottom: 10px;">Puntuación</div>
                <div style="margin-top: 0;" class="input">
                    <input type="number" class="center puntos" matInput [(ngModel)]="DatosAval.puntosBCscore" (input)="ActualizarPuntuacion()">
                    <!-- {{DatosAval.puntosBCscore}} puntos -->
                </div>
                <div class="input">
                    <input type="number" class="center puntos" matInput [(ngModel)]="DatosAval.puntosICC" (input)="ActualizarPuntuacion()">
                    <!-- {{DatosAval.puntosICC}} puntos -->
                </div>
                <div class="input">
                    <input type="number" class="center puntos" matInput [(ngModel)]="DatosAval.puntosDocs" (input)="ActualizarPuntuacion()">
                    <!-- {{DatosAval.puntosDocs}} puntos -->
                </div>
                <div class="input">
                    <input type="number" class="center puntos" matInput [(ngModel)]="DatosAval.puntosParentesco" (input)="ActualizarPuntuacion()">
                    <!-- {{DatosAval.puntosParentesco}} puntos -->
                </div>
                <div class="input">
                    <input type="number" class="center puntos" matInput [(ngModel)]="DatosAval.puntosOcupacion" (input)="ActualizarPuntuacion()">
                    <!-- {{DatosAval.puntosOcupacion}} puntos -->
                </div>
            </div>
        </div>
        <div class="sumaPuntos" *ngIf="mostrarPerfilAval">
            <p class="textoSumaPuntos">{{DatosAval.sumaPuntos}} puntos</p>
        </div>
        <div [ngClass]="{'next-button': true, 'nomargin' : !mostrarPerfilAval}">
            <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
            <button mat-button (click)="ActualizarAval()" *ngIf="!loading">
                Actualizar
                <!-- <mat-icon>navigate_next</mat-icon> -->
                <svg style="height: 20px; width:auto;"  xmlns="http://www.w3.org/2000/svg" width="31.25" height="31.25" viewBox="0 0 31.25 31.25"><defs><style>.next{fill:#00a5da;}</style></defs><path class="next" d="M15.625,0A15.625,15.625,0,1,0,31.25,15.625,15.626,15.626,0,0,0,15.625,0Zm-1.3,24.74V12.173L8.755,17.741l-2.21-2.21,9.079-9.081,9.081,9.081-2.21,2.21-5.569-5.568V24.74Z" transform="translate(31.25) rotate(90)"/></svg>
            </button>
        </div>
    </div>

    <app-socials-footer style="width: 100%;"></app-socials-footer>
</div>
