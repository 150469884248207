import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReferidosService {
    constructor(private http: HttpClient, private router: Router) { }

    ConsultarReferidos() {
        return this.http.get<any>(`${environment.config.apiUrl}referred/info_client_referred`).pipe(map(response => response.data));
    }
    ConsultaCampañas(){
        return this.http.get<any>(`${environment.config.apiUrl}referred/all_campaign`).pipe(map(response => response.data));
    }
    rechazoReferido(id: number) {
        return this.http.put<any>(`${environment.config.apiUrl}referred/rejected`, { id }).pipe(map((response: any) => response.data)
        );
    }
    insertProcess(folio: string, email: string, id: number) {
        return this.http.post<any>(`${environment.config.apiUrl}referred/insert_process`, { folio, email, id}).pipe(map((response: any) => response.data)
        );
    }
    updateEmailAndPhone(email:string,phone:string,id:number){
        console.log("------3----",{email,phone,id})
        return this.http.put<any>(`${environment.config.apiUrl}referred/email_phone`, { id,email,phone}).pipe(map((response: any) => response.data));
    }
 
}