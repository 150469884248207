import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { UserService, SolicitudService, AuthenticationService } from 'src/app/_services';
import { saveAs } from 'file-saver';
import { formatCurrency } from '@angular/common';

@Component({
  selector: 'app-minuta',
  templateUrl: './minuta.component.html',
  styleUrls: ['./minuta.component.scss']
})
export class MinutaComponent implements OnInit {
  @Input() analisisPf: any;  
  @Output() analisisPfChange = new EventEmitter<any>();
  ngOnChanges(changes: SimpleChanges) {
    if (changes.analisisPf && changes.analisisPf.currentValue) {
      console.log("Nuevo valor de analisisPf MINUTA:", this.analisisPf);
      this.AnalisisPF = { ...this.analisisPf };
      console.log("Valor de renta adicional en minuta",this.AnalisisPF.capacidad_pago.renta_adicional_disponible)
      this.MinutaDatos.ubicacion = this.AnalisisPF.minuta.ubicacion
       this.AnalisisPF.comentarios_resolucion_comite 
       this.AnalisisPF.id_resolucion_comite 
      //  this.AnalisisPF.participante[0].participante = this.Participantes[0].
      //  this.AnalisisPF.participante[0].puesto 
      //  this.AnalisisPF.participante[0].asistencia 
      //  this.AnalisisPF.participante[1].participante 
      //  this.AnalisisPF.participante[1].puesto 
      //  this.AnalisisPF.participante[1].asistencia 
      //  this.AnalisisPF.participante[2].participante 
      //  this.AnalisisPF.participante[2].puesto 
      //  this.AnalisisPF.participante[2].asistencia 
      //  this.AnalisisPF.participante[3].participante 
      //  this.AnalisisPF.participante[3].puesto 
      //  this.AnalisisPF.participante[3].asistencia 
      this.MinutaDatos.cliente = this.AnalisisPF.solicitante 
      this.MinutaDatos.cotizacion = this.AnalisisPF.folio 
      this.MinutaDatos.descripcion_activo = this.AnalisisPF.caracteristica_activo.descripcion_activo 
      this.MinutaDatos.monto_arrendar = this.AnalisisPF.cotizacion.monto_arrendar 
      this.MinutaDatos.tipo_financiamiento = this.AnalisisPF.caracteristica_activo.tipo_financiamient 
      this.MinutaDatos.plazo = this.AnalisisPF.cotizacion.plazo 
      this.MinutaDatos.id_resolucion = this.AnalisisPF.id_resolucion_comite
      this.MinutaDatos.fecha = this.AnalisisPF.fecha_creacion
      this.MinutaDatos.numero_comite = this.AnalisisPF.numero_comite
      // console.log("Nuevo valor de analisisPf ENTREVISTA:", this.AnalisisPF);
      this.Participantes = [...this.AnalisisPF.participantes]
      this.Referencias = this.AnalisisPF.referencias
      this.validarReferencias()
      this.cdr.detectChanges();

      
    }
  }
  AnalisisPF:any = {};

  Participantes = [
    {nombre: '', iniciales: '', puesto: '', asistencia: false},
    {nombre: '', iniciales: '', puesto: '', asistencia: false},
    {nombre: '', iniciales: '', puesto: '', asistencia: false},
    {nombre: '', iniciales: '', puesto: '', asistencia: false},
    {nombre: '', iniciales: '', puesto: '', asistencia: true},
  ];
  folio = null;
  MinutaDatos: any = {};
  Minuta: any = {};
  Actualizado = false;
  submit = false;
  guardar = false;
  pdf = false
  adicional = false
  dataDocumentos = {
    folio: '', tipo_persona: 0, tipo_aval: 0
  }

  Documentos = null
  documentosSolicitanteFaltantes = [];
  documentosAvalFaltantes = [];
  id_solicitud = null;
  sent = false;
  Aprobado = false;
  AprobadoCondicional = false;
  NoAprobado = false;
  documentosCompletos = true;
  code = '';
  codeValido = '';
  idx = 3;
  isValid = false;
  invalidCode = false;
  submitCode = false;
  loading = false;
  Referencias = null

  
  constructor(private dialog: MatDialog,
              private router: Router,
              private userService: UserService,
              private solicitudService: SolicitudService,
              private authenticationService: AuthenticationService,
              private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    const {folio, cliente, tipo_aval = 1, tipo_persona, id_cliente} = JSON.parse( localStorage.getItem('client_details'));
    this.folio = folio;
    this.dataDocumentos = { folio, tipo_persona, tipo_aval };
    const id_solicitud = localStorage.getItem('id_solicitud')
    this.id_solicitud = id_solicitud
    this.userService.ObtenerMinuta( this.folio) 
    .subscribe( res => {
      const hora = (new Date()).toLocaleTimeString();
      const arrHora = hora.split(':');
      this.MinutaDatos = res;
      this.MinutaDatos.numero_comite = '';
      this.MinutaDatos.fecha = res.fecha.split('T')[0];
      this.MinutaDatos.hora = `${arrHora[0]}:${arrHora[1]}`;
      this.Minuta.id_solicitud = res.id_solicitud;
      this.MinutaDatos.cliente = cliente;
      this.MinutaDatos.ubicacion = 'Carr. México- Toluca #5420 El Yaqui, Cuajimalpa. CDMX. C.P 05320';
    }, err => console.log(err));

        this.validarReferencias()
        this.ObtenerStatusDocumentos()
       

  }

  async Save() {
    this.loading = true;
    this.AnalisisPF.participantes = this.Participantes
    this.AnalisisPF.numero_comite = this.MinutaDatos.numero_comite
    this.AnalisisPF.minuta.fecha_comite = this.MinutaDatos.fecha
    this.AnalisisPF.minuta.hora_comite = this.MinutaDatos.hora
    const resGuardado: any = await this.solicitudService.GuardarAnalisisCredito(this.AnalisisPF);


    if(resGuardado.statusCode === 200){
      this.loading = false
        this.dialog.open(MessageBoxComponent, {
          data: {
            text: 'Éxito',
            text2: 'Se ha completado la Minuta',
            // text3: "Revisa tu correo para descargar el documento",
            type: 'success',
            icon: 'success'
          }
        });
    //   this.userService.changeStatus(this.folio, 4, 1).subscribe(() => {
    //   this.router.navigate(['/dashboard', 'comite', this.folio]);
    // });
  }
  }

  GuardarMinuta() {
    this.loading = true
    if ( !this.userService.alertService.isOnline() ) { return; }
    this.pdf = true

    this.Minuta.participante_uno = this.Participantes[0].asistencia,
    this.Minuta.participante_dos = this.Participantes[1].asistencia,
    this.Minuta.participante_tres = this.Participantes[2].asistencia,
    this.Minuta.participante_cuatro = this.Participantes[3].asistencia;
    this.Minuta.fecha = this.MinutaDatos.fecha;
    this.Minuta.hora = this.MinutaDatos.hora;
    this.Minuta.id_solicitud = Number(this.id_solicitud)
    this.Minuta.participante_uno_nombre = this.Participantes[0].iniciales,
    this.Minuta.participante_dos_nombre = this.Participantes[1].iniciales,
    this.Minuta.participante_tres_nombre = this.Participantes[2].iniciales,
    this.Minuta.participante_cuatro_nombre = this.Participantes[3].iniciales;

    this.submit = true;
    this.guardar = true;

    this.guardar = ( this.Participantes[0].asistencia || this.Participantes[1].asistencia );
    if ( !this.guardar ) {
      this.pdf = false
      return this.dialog.open(MessageBoxComponent, {
        width: '500px',
        data: {
          text: 'No se ha completado la Minuta',
          text3: 'Se requiere autorizar Asistencia del Participante',
          icon: 'error',
          type: 'error'
        }
      });
    }

    const mail = JSON.parse(localStorage.getItem('currentUser')).email;
    const cliente = JSON.parse(localStorage.getItem('client_details'));

    const resolucionFinal = {
      1: 'Aprobada',
      2: 'Aprobada Condicionalmente',
      3: 'Información Adicional',
      4: 'Rechazada',
    };
    
    // if (!this.adicional) {
    //   this.Participantes.pop()
    // }}
    if (this.MinutaDatos.monto_arrendar > 2500000 && this.code.length < 6) {
      this.Actualizado = false
      return this.dialog.open(MessageBoxComponent, {
        data: {
          text: 'Por favor ingrese el código de resolución.',
          type: 'error',
          icon: 'error'
        }
      });
    }

    if (this.code !== this.codeValido ) {
      this.Actualizado = false
      return this.dialog.open(MessageBoxComponent, {
        data: {
          text: 'El código de resolución es incorrecto.',
          type: 'error',
          icon: 'error'
        }
      });
    }

    
    this.AnalisisPF.resolucion_comite = resolucionFinal[this.MinutaDatos.id_resolucion],
    this.AnalisisPF.id_resolucion_comite = this.MinutaDatos.id_resolucion
    this.cdr.detectChanges();

    const request = {
      email: mail,
      opcion: 4,
      filename: 'minuta.pdf',
      subjec: 'Minuta',
      type: 15,
      params: {
        inputAsunto: this.MinutaDatos.numero_comite,
        inputFecha: this.MinutaDatos.fecha,
        inputHora: this.MinutaDatos.hora,
        asistencia1: this.Participantes[0].asistencia ? 'OK' : '-',
        asistencia2: this.Participantes[1].asistencia ? 'OK' : '-',
        asistencia3: this.Participantes[2].asistencia ? 'OK' : '-',
        asistencia4: this.Participantes[3].asistencia ? 'OK' : '-',
        inputSolicitante: this.MinutaDatos.cliente,
        inputCotizacion: this.MinutaDatos.cotizacion,
        inputActivo: this.MinutaDatos.descripcion_activo,
        inputValorActivo: formatCurrency( this.MinutaDatos.monto_arrendar, 'es-MX', '$' ),
        inputArr: this.MinutaDatos.tipo_financiamiento,
        inputPlazo: this.MinutaDatos.plazo,
        comentariosCaracter: this.MinutaDatos.comentarios_caracter,
        comentariosColateral: this.MinutaDatos.comentarios_colateral,
        comentariosCapacidad: this.MinutaDatos.comentarios_capacidad_pago,
        comentariosAdicionales: this.MinutaDatos.comentarios_adicionales,
        puntosPrincipalesRevisados: this.AnalisisPF.minuta.puntos_principales_revisados,
        resolucion: resolucionFinal[this.MinutaDatos.id_resolucion],
        nombreSolicitante: cliente.cliente,
        activoArrendar: cliente.activo_nombre,
        folio: cliente.folio,
        comentarios : this.AnalisisPF.comentarios_resolucion_comite,
        renta_adicional_disponible: this.AnalisisPF.capacidad_pago.renta_adicional_disponible,
        participantes: {...this.Participantes}
        
      }
    };

    console.log("MINUTA", request)

    this.userService.GuardarMinuta( this.Minuta )
    .subscribe( async res => {
      console.log("GUARDAR MINUTA", res);
      this.enviaEmail(request);
      const data = {
        folio : this.folio,
        nombreCliente : this.AnalisisPF.solicitante,
        id_resolucion : this.MinutaDatos.id_resolucion,
        id_solicitud : Number(this.id_solicitud),
        participante_uno : this.Participantes[0].asistencia,
        participante_dos : this.Participantes[1].asistencia,
        comentarios : this.AnalisisPF.comentarios_resolucion_comite,
        participante_uno_puesto : this.Participantes[0].puesto,
        participante_dos_puesto :  this.Participantes[1].puesto,
        participante_tres_puesto :  this.Participantes[2].puesto,
        participante_cuatro_puesto :  this.Participantes[3].puesto,
        participante_uno_nombre :  this.Participantes[0].nombre,
        participante_dos_nombre :  this.Participantes[1].nombre,
        participante_tres_nombre :  this.Participantes[2].nombre,
        participante_cuatro_nombre :  this.Participantes[3].nombre,
        participante_tres : this.Participantes[2].asistencia,
        participante_cuatro : this.Participantes[3].asistencia,
        puntuacion_final :  this.AnalisisPF.puntuacion_total
    }
      if(this.MinutaDatos.monto_arrendar < 2500000){
        this.solicitudService.CodeResolucion(data)
      .subscribe((res: any) => {
        this.loading = false;
        // console.log(res);

        this.getResolucion(this.id_solicitud);
      })
      }
      const response = await this.userService.ActualizaResolucionStatus({
        folio: this.folio,
        id_solicitud: this.Minuta.id_solicitud,
        id_resolucion: this.MinutaDatos.id_resolucion
      }).toPromise();
      console.log("RESPONSE ACTUALIZAR RESOLUCION", response)
      await this.userService.changeStatus(this.folio, 4, 3).toPromise();
      
    }, err => {
      // this.pdf = false
      this.dialog.open(MessageBoxComponent, {
        width: '500px',
        data: {
          text: 'Hubo un error al actualizar la minuta.',
          type: 'error',
          icon: 'error'
        }
      });
    });
  }
  enviaEmail(request) {
    this.solicitudService.sendMailCommentaries(request)
    .subscribe( (res: any) => {
      this.Actualizado = true;

      const file = new Blob([res], {type: 'application/pdf'});
      /*var fileURL = URL.createObjectURL(file);
      window.open(fileURL);*/
      saveAs(file, request.filename);
      this.loading = false
      const dialogref = this.dialog.open(MessageBoxComponent, {
        width: '500px',
        data: {
          text: 'Éxito',
          text2: 'Se ha guardado la información',
          text3: 'Revisa tu correo para descargar el documento',
          type: 'success',
          icon: 'success'
        }
      });
    }, (err) => {
      this.dialog.open(MessageBoxComponent, {
        data: {
          text: 'El servicio no está disponible.',
          text3: 'Vuelve a intentarlo',
          type: 'error',
          icon: 'error'
        }
      });
    });
  }
   
  validarReferencias(){
    const rec = this.Referencias;
    let count = 0;
  
    for(let i = 0; i < rec.length; i++ ){
      if(rec[i][`lo_recomienda${i + 1}`] === true){
        ++count
      }
    }
    count = 1
    this.Aprobado = true; 
  
    switch(count){
      case 0:
        console.log("ENTRO NO APROBADA");

        this.NoAprobado = true;
        this.Minuta.id_resolucion = 3;
        this.cdr.detectChanges();

        break;
      case 1:
        console.log("ENTRO APROBADA");
        this.Aprobado = true;
        this.Minuta.id_resolucion = 1;
        this.cdr.detectChanges();

        break;
    }
  }
  

  ParticipanteSeleccionado(participante, index) {
    console.log(participante, index);
    if (index >= 0 && index < this.Participantes.length) {
      this.Participantes[index].nombre = participante.nombre;
      this.Participantes[index].puesto = participante.puesto;
      this.Participantes[index].iniciales = participante.iniciales;
  } else {
      console.error('Índice fuera de rango:', index);
  }
    console.log("PARTICIPANTES", this.Participantes)
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
  ObtenerStatusDocumentos() {
    if (!this.userService.alertService.isOnline()) return;
    this.userService.ObtenerStatusDocuments(this.dataDocumentos).subscribe(
      (res) => {
         this.Documentos = res
        this.Documentos.DocumentosSolicitante.forEach(
          (documento) => {
            if (documento.tipo_documento != 21 && documento.status != 3 && documento.requerido) {
              console.log("Entro a documentos solicitante")
              this.documentosCompletos = true
              this.documentosSolicitanteFaltantes.push(documento);

            }
          }
        );
        this.Documentos.DocumentosAval.forEach(
          (documento) => {
            // (status += documento.status)
            if (documento.status != 3  && documento.requerido) {
              console.log("Entro a documentos aval")
              this.documentosAvalFaltantes.push(documento);

            }
          }
        );
    
        console.log("DOCUMENTOS", this.Documentos)
      },
      (err) => {
        this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text: "El servicio no está disponible por el momento",
            text3: "Vuelve a intentarlo.",
            type: "error",
            icon: "error",
          },
        });
      }
    );
  }
  generateCode() {

    if (this.userService.alertService.isOnline()){
      this.submit = true;
  
      if (!this.Minuta.id_resolucion ||
        !this.AnalisisPF.minuta.puntos_principales_revisados ||
        !this.Participantes[0].asistencia ||
        !this.Participantes[1].asistencia ) {
          this.Actualizado = false
          return;
      }
  
      this.loading = true;
      const data = {
          folio : this.folio,
          nombreCliente : this.AnalisisPF.solicitante,
          id_resolucion : this.Minuta.id_resolucion,
          id_solicitud : Number(this.id_solicitud),
          participante_uno : this.Participantes[0].asistencia,
          participante_dos : this.Participantes[1].asistencia,
          comentarios : this.AnalisisPF.comentarios_resolucion_comite,
          participante_uno_puesto : this.Participantes[0].puesto,
          participante_dos_puesto :  this.Participantes[1].puesto,
          participante_tres_puesto :  this.Participantes[2].puesto,
          participante_cuatro_puesto :  this.Participantes[3].puesto,
          participante_uno_nombre :  this.Participantes[0].nombre,
          participante_dos_nombre :  this.Participantes[1].nombre,
          participante_tres_nombre :  this.Participantes[2].nombre,
          participante_cuatro_nombre :  this.Participantes[3].nombre,
          participante_tres : this.Participantes[2].asistencia,
          participante_cuatro : this.Participantes[3].asistencia,
          puntuacion_final :  this.AnalisisPF.puntuacion_total
      }
      this.solicitudService.CodeResolucion(data)
      .subscribe((res: any) => {
        this.loading = false;
        // console.log(res);

        this.getResolucion(this.id_solicitud);
      },
      (err: any) => {
        console.log(err);
        this.loading = false;
        this.dialog.open(MessageBoxComponent, {
          data: {
            text: 'Hubo un error al guardar la información',
            text2: err,
            type: 'error',
            icon: 'error'
          }
        });
      });
    }

  }

  public getResolucion(idSolicitud: any) {

    this.solicitudService.getResolucion(Number(idSolicitud))
        .subscribe((resp: any) => {
          console.log(resp);
          if (resp.status && resp.status === 200) {
            this.sent = true;
            // this.Resolucion.id_resolucion = resp.body.id_resolucion || false;
            // this.Resolucion.participante_uno = resp.body.participante_uno || false;
            // this.Resolucion.participante_dos = resp.body.participante_dos || false;
            // this.Resolucion.participante_tres = resp.body.participante_tres || false;
            // this.Resolucion.participante_cuatro = resp.body.participante_cuatro || false;
            // this.Resolucion.comentarios = resp.body.comentarios;
            this.codeValido = resp.body.codigo;
            // console.log('res.codigo :', resp.body.codigo);
            // console.log('this.codeValido :', this.codeValido);
          } else {
            //this.Resolucion.id_resolucion = 1;
          }
        }, (err: any) => {
          this.sent = false;
          console.log(err);
          //this.Resolucion.id_resolucion = 1;
        });
  }

   cancelCode() {
    if (this.userService.alertService.isOnline()){
      this.loading = true;
      this.solicitudService.cancelResolucion(this.id_solicitud)
      .subscribe((res: any) => {
        this.loading = false;
        console.log(res);
        this.submitCode = false;
        this.getResolucion(this.id_solicitud);
      },
      (err: any) => {
        console.log(err);
        this.loading = false;
        this.dialog.open(MessageBoxComponent, {
          data: {
            text: 'Hubo un error al guardar la información',
            text2: err,
            type: 'error',
            icon: 'error'
          }
        });
      });
    }
  }

  public onFullFill(data: any) {
    if (data.length === 6) {
       this.code = data;
       this.isValid = true;
       this.invalidCode = false;
     } else if (isNaN(Number(data.key)) ) {
       this.code = '';
       this.isValid = false;
       this.invalidCode = true;
       return false;
     }
   }

  // GUARDAR MINUTA SUBSCRIBE
  /*this.Actualizado = true
  this.userService.ObtenerPDF({
    id: 7,
    params :{
      ...this.Minuta,
      ...this.MinutaDatos
    }
  }).subscribe(res => {
    const blob = new Blob([res], {type: 'application/pdf'})
    saveAs(blob, 'Minuta.pdf')
  },
  err=>console.log(err))

  this.dialog.open(MessageBoxComponent, {
    width: "500px",
    data: {
      text: "Se ha completado la Minuta exitosamente.",
      type: "success",
      icon: "success"
    }
  })*/
}
