<div class="container" *ngIf="Cotizacion" [ngClass]="{'visto': Cotizacion.visto}" (click)="Click()">
    <div class="header">
        <div>
            <svg *ngIf="Cotizacion.creado_leas" style="height: 30px; width: auto;" xmlns="http://www.w3.org/2000/svg" width="35.791" height="52.529" viewBox="0 0 35.791 52.529">
                <g id="Group_6496" data-name="Group 6496" transform="translate(-537.909 -861.5)">
                  <path id="Path_8823" data-name="Path 8823" d="M52.6,4218v51l18.186-13.046L87.391,4269v-51Z" transform="translate(485.809 -3356.001)" fill="#00a5da" stroke="#00a5da" stroke-width="1"/>
                  <g id="Grupo_5296" data-name="Grupo 5296" transform="translate(546.545 870.864)">
                    <g id="Grupo_5295" data-name="Grupo 5295">
                      <path id="Trazado_7555" data-name="Trazado 7555" d="M99.451,29.035a9.512,9.512,0,0,1-6.46,2.034,9.479,9.479,0,0,1-6.475-2.019q-2.292-2.019-2.292-6.229v-12.8h4.528v12.8a9.3,9.3,0,0,0,.144,1.644A3.2,3.2,0,0,0,90.785,26.9a5.215,5.215,0,0,0,2.206.389,4.239,4.239,0,0,0,3.345-1.081,5.151,5.151,0,0,0,.922-3.389v-12.8h4.527v12.8Q101.788,27,99.451,29.035Z" transform="translate(-84.225 -10.016)" fill="#fff"/>
                    </g>
                  </g>
                </g>
              </svg>
        </div>
        <div class="version">#<span>{{ Cotizacion.version }}</span></div>
    </div>
    <div class="nombre">{{ Cotizacion.folio_leas }}</div>
    <div class="fecha">{{ Cotizacion.fecha_creacion | date:'dd MMM yyyy' }}</div>
</div>