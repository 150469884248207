import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AnalisisPM } from '../AnalisisPM';
import { SolicitudService } from 'src/app/_services';
import { StepperNavigationService } from 'src/app/_services/stepper-navigation.service';
import { AnalisisPMService } from 'src/app/_services/analisis.service';
import { AnalisisCreditoService } from 'src/app/_services/analisis-credito.service';

@Component({
  selector: 'app-capacidad-pago',
  templateUrl: './capacidad-pago.component.html',
  styleUrls: ['./capacidad-pago.component.scss']
})
export class CapacidadPagoComponent implements OnInit {
[x: string]: any;
  @Input() analisisPm: any;  
  @Output() analisisPmChange = new EventEmitter<any>();

  ngOnChanges(changes: SimpleChanges) {
    if (changes.analisisPm && changes.analisisPm.currentValue !== null && changes.analisisPm.currentValue !== undefined && changes.analisisPm.currentValue !== 0) {
      console.log("Nuevo valor de analisisPm capacidad pago:", this.analisisPm);
      this.AnalisisPM = {...this.analisisPm}
      this.calcularCapacidadPago();
      this.AnalisisPM.capacidad_pago.mensualidad = Number(this.AnalisisPM.cotizacion.mensualidad)
      this.AnalisisPM.capacidad_pago.ingresos.comprobables.gastos = Number(this.AnalisisPM.anexo.gastos_financieros);
      this.AnalisisPM.capacidad_pago.puntos_rendimiento_roe 
      this.calcularRazonesFinancieras();
      this.calcularRatio();
      this.recalcularTotalPuntosRF()
      this.calcularFactorRenta();
      this.calcularRentaAdicionalDisponible();
    }
  }

  AnalisisPM: any = {};
  folio = null
  loading = false;

  puntuacionRazonesFinancieras = 0; 



  liqu_corto_puntos = 0;

  capital_trabajo_variacion_22_23 = 0;
  capital_trabajo_variacion_23_24 = 0;

  capital_trabajo_promedio = 0;
  capital_trabajo_puntos = 0;

 
  endeudamiento_promedio = 0;
  endeudamiento_puntos = 0;

  
  apalancamiento_puntos = 0;

  quiebra_tecnica_variacion_22_23 = 0;
  quiebra_tecnica_variacion_23_24 = 0;

  quiebra_tecnica_puntos = 0;



  activos_roa_puntos = 0;

  capital_roe_puntos = 0;

  operativo_promedio = 0;
  operativo_puntos = 0;


  neto_promedio = 0;
  neto_puntos = 0;

  capacidad_pago_depositos_promedio = 0;
  capacidad_pago_retiros_promedio = 0;
  capacidad_pago_saldo_final_promedio = 0;


  efectivo_ingresos = 0;


  puntuacionTotalGastos = 0;

  



  constructor(
    private solicitudService: SolicitudService,
    private stepperNavigationService: StepperNavigationService,
    private analisisCredito: AnalisisCreditoService    

  ) { }

  async ngOnInit() {
    this.AnalisisPM = JSON.parse(JSON.stringify(AnalisisPM));
    const { folio } = JSON.parse(localStorage.getItem("client_details"));
    this.folio = folio;
    //await this.getAnalisisPMData();

  }

   extraerNumeroDeCadena(cadena) {
    const match = cadena.match(/(?:\d{1,3}(?:,\d{3})*(?:\.\d+)?|\d+(?:\.\d+)?)/);
    return match ? parseFloat(match[0].replace(/,/g, '')) : null;
    }


  calcularRazonesFinancieras(){
    this.AnalisisPM.capacidad_pago.margenes.bruto._2022 = Math.round((this.AnalisisPM.anexo.estado_resultados.utilidad_bruta._2022 / this.AnalisisPM.anexo.estado_resultados.ventas._2022) * 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.margenes.bruto._2023 = Math.round((this.AnalisisPM.anexo.estado_resultados.utilidad_bruta._2023 / this.AnalisisPM.anexo.estado_resultados.ventas._2023) * 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.margenes.bruto._2024 = Math.round((this.AnalisisPM.anexo.estado_resultados.utilidad_bruta._2024 / this.AnalisisPM.anexo.estado_resultados.ventas._2024) * 100).toFixed(2)

    this.AnalisisPM.capacidad_pago.margenes.operativo._2022 = Number((this.AnalisisPM.anexo.estado_resultados.utilidad_operativa._2022 / this.AnalisisPM.anexo.estado_resultados.ventas._2022) * 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.margenes.operativo._2023 = Number((this.AnalisisPM.anexo.estado_resultados.utilidad_operativa._2023 / this.AnalisisPM.anexo.estado_resultados.ventas._2023) * 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.margenes.operativo._2024 = Number((this.AnalisisPM.anexo.estado_resultados.utilidad_operativa._2024 / this.AnalisisPM.anexo.estado_resultados.ventas._2024) * 100).toFixed(2)

    this.AnalisisPM.capacidad_pago.margenes.neto._2022 = Number((this.AnalisisPM.anexo.estado_resultados.utilidad_neta._2022 / this.AnalisisPM.anexo.estado_resultados.ventas._2022) * 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.margenes.neto._2023 = Number((this.AnalisisPM.anexo.estado_resultados.utilidad_neta._2023 / this.AnalisisPM.anexo.estado_resultados.ventas._2023) * 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.margenes.neto._2024 = Number((this.AnalisisPM.anexo.estado_resultados.utilidad_neta._2024 / this.AnalisisPM.anexo.estado_resultados.ventas._2024) * 100).toFixed(2)

    this.AnalisisPM.capacidad_pago.liquidez.capital_trabajo._2022 = this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2022 - this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2022
    this.AnalisisPM.capacidad_pago.liquidez.capital_trabajo._2023 = this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2023 - this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2023
    this.AnalisisPM.capacidad_pago.liquidez.capital_trabajo._2024 = this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2024 - this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2024

    this.AnalisisPM.capacidad_pago.liquidez.corto_plazo._2022 = parseFloat((this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2022 / this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2022).toFixed(2))
    this.AnalisisPM.capacidad_pago.liquidez.corto_plazo._2023 = parseFloat((this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2023 / this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2023).toFixed(2))
    this.AnalisisPM.capacidad_pago.liquidez.corto_plazo._2024 = parseFloat((this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2024 / this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2024).toFixed(2))

    this.AnalisisPM.capacidad_pago.liquidez.acida._2022 = parseFloat(((this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2022 - this.AnalisisPM.anexo.activo_circulante.inventarios._2022 )/ this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2022).toFixed(2))
    this.AnalisisPM.capacidad_pago.liquidez.acida._2023 = parseFloat(((this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2023 - this.AnalisisPM.anexo.activo_circulante.inventarios._2023 )/ this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2023).toFixed(2))
    this.AnalisisPM.capacidad_pago.liquidez.acida._2024 = parseFloat(((this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2024 - this.AnalisisPM.anexo.activo_circulante.inventarios._2024 )/ this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2024).toFixed(2))

    this.AnalisisPM.capacidad_pago.rentabilidad.ventas._2022 = Number(((this.AnalisisPM.anexo.estado_resultados.ventas._2022 / this.AnalisisPM.anexo.estado_resultados.costo_ventas._2022) - 1) * 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.rentabilidad.ventas._2023 = Number(((this.AnalisisPM.anexo.estado_resultados.ventas._2023 / this.AnalisisPM.anexo.estado_resultados.costo_ventas._2023) - 1) * 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.rentabilidad.ventas._2024 = Number(((this.AnalisisPM.anexo.estado_resultados.ventas._2024 / this.AnalisisPM.anexo.estado_resultados.costo_ventas._2024) - 1) * 100).toFixed(2)

    this.AnalisisPM.capacidad_pago.rentabilidad.activos_roa._2022 = Math.floor((this.AnalisisPM.anexo.estado_resultados.utilidad_neta._2022 / this.AnalisisPM.anexo.total_activos_circulante_fijo_22) * 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.rentabilidad.activos_roa._2023 = Math.floor((this.AnalisisPM.anexo.estado_resultados.utilidad_neta._2023 / this.AnalisisPM.anexo.total_activos_circulante_fijo_23) * 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.rentabilidad.activos_roa._2024 = Math.floor((this.AnalisisPM.anexo.estado_resultados.utilidad_neta._2024 / this.AnalisisPM.anexo.total_activos_circulante_fijo_24) * 100).toFixed(2)

    this.AnalisisPM.capacidad_pago.rentabilidad.capital_roe._2022 =  Math.floor((this.AnalisisPM.anexo.estado_resultados.utilidad_neta._2022 / this.AnalisisPM.anexo.capital.total_capital._2022) * 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.rentabilidad.capital_roe._2023 =  Math.floor((this.AnalisisPM.anexo.estado_resultados.utilidad_neta._2023 / this.AnalisisPM.anexo.capital.total_capital._2023) * 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.rentabilidad.capital_roe._2024 =  Math.floor((this.AnalisisPM.anexo.estado_resultados.utilidad_neta._2024 / this.AnalisisPM.anexo.capital.total_capital._2024) * 100).toFixed(2)
    
    this.AnalisisPM.capacidad_pago.deuda.endeudamiento._2022 = Number((this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2022 / this.AnalisisPM.anexo.total_activos_circulante_fijo_22) * 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.deuda.endeudamiento._2023 = Number((this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2023 / this.AnalisisPM.anexo.total_activos_circulante_fijo_23) * 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.deuda.endeudamiento._2024 = Number((this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2024 / this.AnalisisPM.anexo.total_activos_circulante_fijo_24) * 100).toFixed(2)

    this.AnalisisPM.capacidad_pago.deuda.apalancamiento_ext._2022 = Number((this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2022 / this.AnalisisPM.anexo.capital.total_capital._2022) * 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.deuda.apalancamiento_ext._2023 = Number((this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2023 / this.AnalisisPM.anexo.capital.total_capital._2023) * 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.deuda.apalancamiento_ext._2024 = Number((this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2024 / this.AnalisisPM.anexo.capital.total_capital._2024) * 100).toFixed(2)

    this.AnalisisPM.capacidad_pago.rendimiento.quiebra_tecnica._2022 = Number((this.AnalisisPM.anexo.capital.capital_social._2022 / (this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2022 + this.AnalisisPM.anexo.capital.total_capital._2022) ) * 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.rendimiento.quiebra_tecnica._2023 = Number((this.AnalisisPM.anexo.capital.capital_social._2023 / (this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2023 + this.AnalisisPM.anexo.capital.total_capital._2023) ) * 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.rendimiento.quiebra_tecnica._2024 = Number((this.AnalisisPM.anexo.capital.capital_social._2024 / (this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2024 + this.AnalisisPM.anexo.capital.total_capital._2024) ) * 100).toFixed(2)
    

    this.AnalisisPM.capacidad_pago.deuda.cobertura_intereses._2022 = Number((this.AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos._2022 / this.AnalisisPM.anexo.estado_resultados.gastos_financieros._2022) * 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.deuda.cobertura_intereses._2023 = Number((this.AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos._2023 / this.AnalisisPM.anexo.estado_resultados.gastos_financieros._2023) * 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.deuda.cobertura_intereses._2024 = Number((this.AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos._2024 / this.AnalisisPM.anexo.estado_resultados.gastos_financieros._2024) * 100).toFixed(2)

    this.AnalisisPM.capacidad_pago.deuda.cobertura_efectivo._2022 = Number((this.AnalisisPM.anexo.estado_resultados.utilidad_operativa._2022 / (Number(this.AnalisisPM.anexo.estado_resultados.gastos_financieros._2022) + this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2022) ) * 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.deuda.cobertura_efectivo._2023 = Number((this.AnalisisPM.anexo.estado_resultados.utilidad_operativa._2023 / (Number(this.AnalisisPM.anexo.estado_resultados.gastos_financieros._2023) + this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2023) ) * 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.deuda.cobertura_efectivo._2024 = Number((this.AnalisisPM.anexo.estado_resultados.utilidad_operativa._2024 / (Number(this.AnalisisPM.anexo.estado_resultados.gastos_financieros._2024) + this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2024) ) * 100).toFixed(2)

    this.AnalisisPM.capacidad_pago.rotacion.rotacion_cxc._2022 = parseFloat((365/ (this.AnalisisPM.anexo.estado_resultados.ventas._2022 / this.AnalisisPM.anexo.activo_circulante.cuentas_por_cobrar._2022)).toFixed(2))
    this.AnalisisPM.capacidad_pago.rotacion.rotacion_cxc._2023 = parseFloat((365/ (this.AnalisisPM.anexo.estado_resultados.ventas._2023 / this.AnalisisPM.anexo.activo_circulante.cuentas_por_cobrar._2023)).toFixed(2))
    this.AnalisisPM.capacidad_pago.rotacion.rotacion_cxc._2024 = parseFloat((365/ (this.AnalisisPM.anexo.estado_resultados.ventas._2024 / this.AnalisisPM.anexo.activo_circulante.cuentas_por_cobrar._2024)).toFixed(2))

    this.AnalisisPM.capacidad_pago.rotacion.rotacion_inventarios._2022 = parseFloat((365/ (this.AnalisisPM.anexo.estado_resultados.costo_ventas._2022 / this.AnalisisPM.anexo.activo_circulante.inventarios._2022)).toFixed(2))
    this.AnalisisPM.capacidad_pago.rotacion.rotacion_inventarios._2023 = parseFloat((365/ (this.AnalisisPM.anexo.estado_resultados.costo_ventas._2023 / this.AnalisisPM.anexo.activo_circulante.inventarios._2023)).toFixed(2))
    this.AnalisisPM.capacidad_pago.rotacion.rotacion_inventarios._2024 = parseFloat((365/ (this.AnalisisPM.anexo.estado_resultados.costo_ventas._2024 / this.AnalisisPM.anexo.activo_circulante.inventarios._2024)).toFixed(2))
    
    this.AnalisisPM.capacidad_pago.rotacion.rotacion_proveedores._2022 = parseFloat((365/ (this.AnalisisPM.anexo.estado_resultados.costo_ventas._2022 / this.AnalisisPM.anexo.pasivo.proveedores._2022)).toFixed(2))
    this.AnalisisPM.capacidad_pago.rotacion.rotacion_proveedores._2023 = parseFloat((365/ (this.AnalisisPM.anexo.estado_resultados.costo_ventas._2023 / this.AnalisisPM.anexo.pasivo.proveedores._2023)).toFixed(2))
    this.AnalisisPM.capacidad_pago.rotacion.rotacion_proveedores._2024 = parseFloat(( (Number(this.AnalisisPM.anexo.pasivo.proveedores._2024) *360 )/ Number(this.AnalisisPM.anexo.estado_resultados.ventas._2024)).toFixed(2))

    this.AnalisisPM.capacidad_pago.rotacion.ciclo_financiero._2022 = (this.AnalisisPM.capacidad_pago.rotacion.rotacion_cxc._2022  + this.AnalisisPM.capacidad_pago.rotacion.rotacion_inventarios._2022 - this.AnalisisPM.capacidad_pago.rotacion.rotacion_proveedores._2022).toFixed(2)
    this.AnalisisPM.capacidad_pago.rotacion.ciclo_financiero._2023 = (this.AnalisisPM.capacidad_pago.rotacion.rotacion_cxc._2023  + this.AnalisisPM.capacidad_pago.rotacion.rotacion_inventarios._2023 - this.AnalisisPM.capacidad_pago.rotacion.rotacion_proveedores._2023).toFixed(2)
    this.AnalisisPM.capacidad_pago.rotacion.ciclo_financiero._2024 = (this.AnalisisPM.capacidad_pago.rotacion.rotacion_cxc._2024  + this.AnalisisPM.capacidad_pago.rotacion.rotacion_inventarios._2024 - this.AnalisisPM.capacidad_pago.rotacion.rotacion_proveedores._2024).toFixed(2)
    
    this.AnalisisPM.capacidad_pago.liquidez.corto_plazo.variacion_22_23 = Number(((this.AnalisisPM.capacidad_pago.liquidez.corto_plazo._2023 / this.AnalisisPM.capacidad_pago.liquidez.corto_plazo._2022) - 1) * 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.liquidez.corto_plazo.variacion_23_24 = Number(((this.AnalisisPM.capacidad_pago.liquidez.corto_plazo._2023 / this.AnalisisPM.capacidad_pago.liquidez.corto_plazo._2024) - 1)* 100).toFixed(2)

    this.AnalisisPM.capacidad_pago.liquidez.corto_plazo.promedio = this.calcularPromedio(Number(this.AnalisisPM.capacidad_pago.liquidez.corto_plazo._2022), Number(this.AnalisisPM.capacidad_pago.liquidez.corto_plazo._2023), Number(this.AnalisisPM.capacidad_pago.liquidez.corto_plazo._2024))

    this.AnalisisPM.capacidad_pago.liquidez.capital_trabajo.variacion_22_23 = Number(((this.AnalisisPM.capacidad_pago.liquidez.capital_trabajo._2023 / this.AnalisisPM.capacidad_pago.liquidez.capital_trabajo._2022) - 1 )* 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.liquidez.capital_trabajo.variacion_23_24 = Number(((this.AnalisisPM.capacidad_pago.liquidez.capital_trabajo._2024 / this.AnalisisPM.capacidad_pago.liquidez.capital_trabajo._2023) - 1 )* 100).toFixed(2)

    this.AnalisisPM.capacidad_pago.liquidez.capital_trabajo.promedio = this.calcularPromedio(Number(this.AnalisisPM.capacidad_pago.liquidez.capital_trabajo._2022), Number(this.AnalisisPM.capacidad_pago.liquidez.capital_trabajo._2023), Number(this.AnalisisPM.capacidad_pago.liquidez.capital_trabajo._2024))

    this.AnalisisPM.capacidad_pago.deuda.endeudamiento.variacion_22_23 = Number(((this.AnalisisPM.capacidad_pago.deuda.endeudamiento._2023 / this.AnalisisPM.capacidad_pago.deuda.endeudamiento._2022) -1) * 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.deuda.endeudamiento.variacion_23_24 = Number(((this.AnalisisPM.capacidad_pago.deuda.endeudamiento._2024 / this.AnalisisPM.capacidad_pago.deuda.endeudamiento._2023) -1) * 100).toFixed(2)

    this.AnalisisPM.capacidad_pago.deuda.endeudamiento.promedio  = this.calcularPromedio(Number(this.AnalisisPM.capacidad_pago.deuda.endeudamiento._2022), Number(this.AnalisisPM.capacidad_pago.deuda.endeudamiento._2023), Number(this.AnalisisPM.capacidad_pago.deuda.endeudamiento._2024))


    this.AnalisisPM.capacidad_pago.deuda.apalancamiento_ext.variacion_22_23 = Number(((this.AnalisisPM.capacidad_pago.deuda.apalancamiento_ext._2023 / this.AnalisisPM.capacidad_pago.deuda.apalancamiento_ext._2022)-1) * 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.deuda.apalancamiento_ext.variacion_23_24 = Number(((this.AnalisisPM.capacidad_pago.deuda.apalancamiento_ext._2024 / this.AnalisisPM.capacidad_pago.deuda.apalancamiento_ext._2023)-1) * 100).toFixed(2)

    this.AnalisisPM.capacidad_pago.deuda.apalancamiento_ext.promedio = this.calcularPromedio(Number(this.AnalisisPM.capacidad_pago.deuda.apalancamiento_ext._2022), Number(this.AnalisisPM.capacidad_pago.deuda.apalancamiento_ext._2023), Number(this.AnalisisPM.capacidad_pago.deuda.apalancamiento_ext._2024))

    this.AnalisisPM.capacidad_pago.rendimiento.quiebra_tecnica.variacion_22_23 = Number(((this.AnalisisPM.capacidad_pago.rendimiento.quiebra_tecnica._2023 / this.AnalisisPM.capacidad_pago.rendimiento.quiebra_tecnica._2022)-1) * 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.rendimiento.quiebra_tecnica.variacion_23_24 = Number(((this.AnalisisPM.capacidad_pago.rendimiento.quiebra_tecnica._2024 / this.AnalisisPM.capacidad_pago.rendimiento.quiebra_tecnica._2023)-1) * 100).toFixed(2)

    this.AnalisisPM.capacidad_pago.rendimiento.quiebra_tecnica.promedio = this.calcularPromedio(Number(this.AnalisisPM.capacidad_pago.rendimiento.quiebra_tecnica._2022), Number(this.AnalisisPM.capacidad_pago.rendimiento.quiebra_tecnica._2023), Number(this.AnalisisPM.capacidad_pago.rendimiento.quiebra_tecnica._2024))


    this.AnalisisPM.capacidad_pago.rentabilidad.activos_roa.variacion_22_23 = Number(((this.AnalisisPM.capacidad_pago.rentabilidad.activos_roa._2023 / this.AnalisisPM.capacidad_pago.rentabilidad.activos_roa._2022) - 1 ) * 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.rentabilidad.activos_roa.variacion_23_24 = Number(((this.AnalisisPM.capacidad_pago.rentabilidad.activos_roa._2024 / this.AnalisisPM.capacidad_pago.rentabilidad.activos_roa._2023) - 1) * 100).toFixed(2)

    this.AnalisisPM.capacidad_pago.rentabilidad.activos_roa.promedio = this.calcularPromedio(Number(this.AnalisisPM.capacidad_pago.rentabilidad.activos_roa._2022), Number(this.AnalisisPM.capacidad_pago.rentabilidad.activos_roa._2023), Number(this.AnalisisPM.capacidad_pago.rentabilidad.activos_roa._2024))

    this.AnalisisPM.capacidad_pago.rentabilidad.capital_roe.variacion_22_23 = Number(((this.AnalisisPM.capacidad_pago.rentabilidad.capital_roe._2023 / this.AnalisisPM.capacidad_pago.rentabilidad.capital_roe._2022)-1)* 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.rentabilidad.capital_roe.variacion_23_24 = Number(((this.AnalisisPM.capacidad_pago.rentabilidad.capital_roe._2024 / this.AnalisisPM.capacidad_pago.rentabilidad.capital_roe._2023)-1) * 100).toFixed(2)

    this.AnalisisPM.capacidad_pago.rentabilidad.capital_roe.promedio = this.calcularPromedio(Number(this.AnalisisPM.capacidad_pago.rentabilidad.capital_roe._2022), Number(this.AnalisisPM.capacidad_pago.rentabilidad.capital_roe._2023), Number(this.AnalisisPM.capacidad_pago.rentabilidad.capital_roe._2024))


    this.AnalisisPM.capacidad_pago.margenes.operativo.variacion_22_23 = Number(((this.AnalisisPM.capacidad_pago.margenes.operativo._2023 / this.AnalisisPM.capacidad_pago.margenes.operativo._2022)-1) * 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.margenes.operativo.variacion_23_24 = Number(((this.AnalisisPM.capacidad_pago.margenes.operativo._2024 / this.AnalisisPM.capacidad_pago.margenes.operativo._2023)-1) * 100).toFixed(2)

    this.AnalisisPM.capacidad_pago.margenes.operativo.promedio  = this.calcularPromedio(Number(this.AnalisisPM.capacidad_pago.margenes.operativo._2022), Number(this.AnalisisPM.capacidad_pago.margenes.operativo._2023), Number(this.AnalisisPM.capacidad_pago.margenes.operativo._2024))

    this.AnalisisPM.capacidad_pago.margenes.neto.variacion_22_23 = Number(((this.AnalisisPM.capacidad_pago.margenes.neto._2023 / this.AnalisisPM.capacidad_pago.margenes.neto._2022) -1 )* 100).toFixed(2)
    this.AnalisisPM.capacidad_pago.margenes.neto.variacion_23_24 = Number(((this.AnalisisPM.capacidad_pago.margenes.neto._2024 / this.AnalisisPM.capacidad_pago.margenes.neto._2023) -1 )* 100).toFixed(2)

    this.AnalisisPM.capacidad_pago.margenes.neto.promedio = this.calcularPromedio(Number(this.AnalisisPM.capacidad_pago.margenes.neto._2022), Number(this.AnalisisPM.capacidad_pago.margenes.neto._2023), Number(this.AnalisisPM.capacidad_pago.margenes.neto._2024))



  }


  calcularPromedio(valor1, valor2, valor3) {
    const valoresNoNulos = [valor1, valor2, valor3].filter(valor => valor !== 0);
    
    if (valoresNoNulos.length > 0) {
      const suma = valoresNoNulos.reduce((total, valor) => total + valor, 0);
      const promedio = suma / valoresNoNulos.length;
      return +promedio.toFixed(2);
    } else {
      return 0; 
    }
  }
  

  calcularCapacidadPago(){
    this.AnalisisPM.capacidad_pago.depositos_promedio = this.calcularPromedio(Number(this.AnalisisPM.capacidad_pago.periodos[0].depositos), Number(this.AnalisisPM.capacidad_pago.periodos[1].depositos), Number(this.AnalisisPM.capacidad_pago.periodos[2].depositos))
    this.AnalisisPM.capacidad_pago.retiros_promedio = this.calcularPromedio(Number(this.AnalisisPM.capacidad_pago.periodos[0].retiros), Number(this.AnalisisPM.capacidad_pago.periodos[1].retiros), Number(this.AnalisisPM.capacidad_pago.periodos[2].retiros))
    this.AnalisisPM.capacidad_pago.saldo_final_promedio = this.calcularPromedio(Number(this.AnalisisPM.capacidad_pago.periodos[0].saldo_final), Number(this.AnalisisPM.capacidad_pago.periodos[1].saldo_final), Number(this.AnalisisPM.capacidad_pago.periodos[2].saldo_final))
    this.capacidad_pago_depositos_promedio = this.AnalisisPM.capacidad_pago.depositos_promedio
    this.AnalisisPM.capacidad_pago.ingresos.comprobables.ingresos =  this.AnalisisPM.capacidad_pago.depositos_promedio
    this.AnalisisPM.capacidad_pago.ingresos.comprobables.gastos =  this.AnalisisPM.anexo.gastos_financieros
    this.AnalisisPM.capacidad_pago.ingresos.efectivo.gastos = this.AnalisisPM.capacidad_pago.ingresos.comprobables.gastos
    this.AnalisisPM.capacidad_pago.ingresos.extra_nuevo_equipo.gastos = (parseFloat(this.AnalisisPM.capacidad_pago.ingresos.efectivo.gastos) + parseFloat(this.AnalisisPM.cotizacion.mensualidad)).toFixed(2)


    this.AnalisisPM.capacidad_pago.ingresos.efectivo.ingresos = (Number(this.AnalisisPM.capacidad_pago.ingresos_efectivo) * 0.6) + this.AnalisisPM.capacidad_pago.ingresos.comprobables.ingresos;
    this.AnalisisPM.capacidad_pago.ingresos.efectivo.gastos = this.AnalisisPM.anexo.gastos_financieros
    
    const ingresosExtras = this.AnalisisPM.capacidad_pago.ingresos_extras == "" || this.AnalisisPM.capacidad_pago.ingresos_extras == null ? 0 : Number(this.AnalisisPM.capacidad_pago.ingresos_extras);

    this.AnalisisPM.capacidad_pago.ingresos.extra_nuevo_equipo.ingresos = (ingresosExtras * 0.5) + Number(this.AnalisisPM.capacidad_pago.ingresos.efectivo.ingresos)
    
    this.calcularRatio()
    this.calcularFactorRenta()
  }

  calcularRatio(){
    this.AnalisisPM.capacidad_pago.ingresos.comprobables.ratio = Math.round((this.AnalisisPM.capacidad_pago.ingresos.comprobables.gastos / this.AnalisisPM.capacidad_pago.ingresos.comprobables.ingresos) * 100);
    this.AnalisisPM.capacidad_pago.ingresos.efectivo.ratio =  Math.round((this.AnalisisPM.capacidad_pago.ingresos.efectivo.gastos / this.AnalisisPM.capacidad_pago.ingresos.efectivo.ingresos) * 100)
    this.AnalisisPM.capacidad_pago.ingresos.extra_nuevo_equipo.ratio =  Math.round((this.AnalisisPM.capacidad_pago.ingresos.extra_nuevo_equipo.gastos / this.AnalisisPM.capacidad_pago.ingresos.extra_nuevo_equipo.ingresos) * 100)
  }

  calcularFactorRenta(){
    const extnuevoequipogastos = Number(this.AnalisisPM.capacidad_pago.ingresos.efectivo.gastos) + this.AnalisisPM.cotizacion.mensualidad 
    this.AnalisisPM.capacidad_pago.factor_renta = parseFloat(((this.AnalisisPM.capacidad_pago.ingresos.extra_nuevo_equipo.ingresos - Number(this.AnalisisPM.capacidad_pago.ingresos.extra_nuevo_equipo.gastos) )/ this.AnalisisPM.capacidad_pago.mensualidad ).toFixed(2))
  }
  sumarTotales(...valores: number[]): number {
    return valores.reduce((total, valor) => total + (valor || 0), 0);
  }
  recalcularTotalPuntosRF(){
    this.calcularTotalPuntosRazonesFinancieras()
    this.calcularTotalPuntosFinal()
  }
  calcularTotalPuntosRazonesFinancieras(){
    this.AnalisisPM.capacidad_pago.total_puntos_parametros = this.sumarTotales(
      Number(this.AnalisisPM.capacidad_pago.puntos_liquidez),
      Number(this.AnalisisPM.capacidad_pago.puntos_capital_trabajo),
      Number(this.AnalisisPM.capacidad_pago.puntos_endeudamiento),
      Number(this.AnalisisPM.capacidad_pago.puntos_apalancamiento_ext),
      Number(this.AnalisisPM.capacidad_pago.puntos_quiebra_tecnica),
      Number(this.AnalisisPM.capacidad_pago.puntos_rendimiento_roa),
      Number(this.AnalisisPM.capacidad_pago.puntos_capital_roe),
      Number(this.AnalisisPM.capacidad_pago.puntos_margen_operativo),
      Number(this.AnalisisPM.capacidad_pago.puntos_margen_neta),
    )
  }
  calcularTotalPuntosFinal(){
    this.AnalisisPM.capacidad_pago.total_puntos_razones_financieras = this.sumarTotales(
        Number(this.AnalisisPM.capacidad_pago.total_puntos_parametros),
        Number(this.AnalisisPM.capacidad_pago.total_puntos_gastos),
    )
  }

  calcularRentaAdicionalDisponible(){
    if(this.AnalisisPM.capacidad_pago.ingresos.extra_nuevo_equipo.ingresos != 0){
      const buscarObjetivo = this.analisisCredito.buscarObjetivoG53(0.65, 0, 0.00001,this.AnalisisPM.capacidad_pago.ingresos.efectivo.gastos, this.AnalisisPM.capacidad_pago.ingresos.extra_nuevo_equipo.ingresos)
      this.AnalisisPM.capacidad_pago.renta_adicional_disponible = (buscarObjetivo - this.AnalisisPM.capacidad_pago.mensualidad).toFixed(2)
    }
  }
    
  async guardarYRetroceder() {
   
     console.log("Guardando analisis", this.AnalisisPM);
     const res:any = await this.solicitudService.GuardarAnalisisCredito(
       this.AnalisisPM
     );
     console.log(res);
     if(res.statusCode == 200){
      this.analisisPM.setAnalisisPM(this.AnalisisPM)
       this.stepperNavigationService.previousStep();
     }
   }
 
   async guardarYAvanzar() {
    
    this.loading = true;
     console.log("Guardando analisis", this.AnalisisPM);
     this.AnalisisPM.capacidad_pago.total_puntos_parametros = this.AnalisisPM.capacidad_pago.total_puntos_parametros 
     const res:any = await this.solicitudService.GuardarAnalisisCredito(
       this.AnalisisPM
     );
     console.log(res);
     if(res.statusCode == 200){
      this.loading = false;
      this.analisisPmChange.emit(this.AnalisisPM)

       this.stepperNavigationService.nextStep();
     }else{
      this.loading = false;
     }
   }
 


  

}
