import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/_services';

@Component({
  selector: 'app-change-status',
  templateUrl: './change-status.component.html',
  styleUrls: ['./change-status.component.scss']
})
export class ChangeStatusComponent implements OnInit {
  loading = false
  constructor( private dialogRef: MatDialogRef<ChangeStatusComponent>,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    // console.log(`Cambiar proceso: ${this.data.proceso} del folio: ${this.data.folio}`);
  }

  CambiarStatus(new_status) {
    // console.log(`Proceso: ${this.data.proceso} cambia estatusa: ${new_status}`);
    this.loading = true
    this.userService.changeStatus(this.data.folio, this.data.proceso, new_status)
    .subscribe( data => {
      this.loading = false
      this.dialogRef.close()
    }, err => {
      console.error("Ocurrió un error inesperado: ", err)
      this.loading = false
    })
    
  }

}
