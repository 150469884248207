import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { ModalCrearActivoComponent } from 'src/app/_dialogs/modal-crear-activo/modal-crear-activo.component';
import { Activo } from 'src/app/_models/activo';
import { ActivosService } from 'src/app/_services/activos.service';

@Component({
  selector: 'app-admin-activos',
  templateUrl: './admin-activos.component.html',
  styleUrls: ['./admin-activos.component.scss']
})
export class AdminActivosComponent implements OnInit {
  folio = null
  comments_unread = []
  show_notifications = false
  total_unread_comments = 0
  placeholderSearch="Buscar activos"
  Activos: Activo[] = []
  ActivosFiltro: Activo[] = []
  loading = false


  constructor(
    private route: ActivatedRoute,
    private dialog:MatDialog,
    private activosService: ActivosService,



  ) { }

  ngOnInit(): void {
    this.folio = this.route.snapshot.paramMap.get('folio');
    console.log(this.folio)
    if(this.folio){
      this.getActivos()
    }
  }


  async getActivos(){
   console.log("GetActivos")
   
   try{
    const data = {
      folio: this.folio
    };
    const res = await this.activosService.ObtenerActivos(data).toPromise();
    console.log(res)
    this.Activos = res.data.map(activo => {
      try {
        return {
          ...activo,
        };
      } catch (error) {
        return {
          ...activo,
        };
      }
    });
   }catch(e){
    console.error('Error al obtener activos:', e);
      this.loading = false;
   }
  }
  openNotifications(){
    console.log(this.show_notifications)
    this.show_notifications = !this.show_notifications
  }
  CrearActivo() {
    this.dialog.open(ModalCrearActivoComponent, {
      width: "580px",
      height: "400px",
      data: {
        folio: this.folio,
        getActivos: () => this.getActivos() 
      },
    });
  }
  EditarActivo(activo:Activo) {
    this.dialog.open(ModalCrearActivoComponent, {
      width: "580px",
      height: "400px",
      data: {
        activo: activo,
        folio: this.folio,
        getActivos: () => this.getActivos() 
      },
    });
  }
}
