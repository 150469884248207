import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import CryptoJS from 'crypto-js';
import sha256 from 'crypto-js/sha256';

import { User } from '../_models';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable, from } from 'rxjs';

@Injectable({ providedIn: 'root' })

/* tslint:disable */
export class SolicitudService {
	tramitesOrigen: any = null
	tramites: any = null

	latitude = '0';
    longitude = '0';
	httpHeaders: any;
	user: any;
	perfil: any;
	solicitud: any;
	hasSolicitud= false;
	update = false

    constructor(private http: HttpClient, private router: Router) {
		console.log("Solicitud service");
		const currentUser = JSON.parse( localStorage.getItem('currentUser') )

		if (currentUser) {
			const { token, perfil } = currentUser
			this.perfil = perfil
			this.httpHeaders = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': token
				})
			};
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(position => {
					this.latitude = position.coords.latitude.toString();
					this.longitude = position.coords.longitude.toString();
				});
			}
	
			this.user = JSON.parse(localStorage.getItem('currentUser'));
			this.solicitud = JSON.parse(localStorage.getItem('solicitud'));
			if(this.solicitud){
				this.hasSolicitud = true;
				console.log("Solicitud", this.solicitud);
			}else{
				// this.solicitud = {}
				console.log('vacio');
			}
		}
	}

	ObtenerTramitesOrigen() {
		return {tramitesOrigen: this.tramitesOrigen, tramites: this.tramites}
	}

	GuardarTramitesOrigen(tramitesOrigen, tramites) {
		this.tramitesOrigen = tramitesOrigen
		this.tramites = tramites

		localStorage.setItem('tramites', JSON.stringify(tramites))
		localStorage.setItem('tramitesOrigen', JSON.stringify(tramitesOrigen))
	}

	Clear() {
		this.tramitesOrigen = null
		this.tramites = null

		localStorage.removeItem('tramites')
		localStorage.removeItem('tramitesOrigen')
	}

	getReferencia() {
		this.user = JSON.parse(localStorage.getItem('client_details'));
		const solicitud = JSON.parse(localStorage.getItem('solicitud'));
		// if(solicitud){
		// 	this.hasSolicitud = true;
		// 	this.solicitud.id = this.solicitud.IDSolicitud,
		// 	this.solicitud.referencia_dolicitud_datos = this.solicitud.ReferenciaSolicitudDatos
		// 	console.log("Solicitud", this.solicitud);
		// }else{
			const { id_cliente, folio} = this.user

			this.solicitud = {
				id_cliente,
				folio
			}
		// }
		return this.solicitud
	}

	public getResolucion(id_solicitud: any): any {
        const folio = (JSON.parse(localStorage.getItem('client_details')).folio).toString();

        const request = {
            folio,
            id_solicitud
        };

        const { token } = JSON.parse(localStorage.getItem('currentUser'));

        return this.http.post<any>(`${environment.config.apiUrl}comite/ObtenerResolucion`, request,
            {observe: 'response' as 'body',
            headers: (new HttpHeaders).append('Authorization', token)})
            .pipe(map(response => {
                 return response;
            }));
	}
	
	public cancelResolucion(id_solicitud: any): any {
        const folio = (JSON.parse(localStorage.getItem('client_details')).folio).toString();

        const request = {
            folio,
            id_solicitud
        };

        const { token } = JSON.parse(localStorage.getItem('currentUser'));

        return this.http.post<any>(`${environment.config.apiUrl}comite/CancelarResolucion`, request,
            {observe: 'response' as 'body',
            headers: (new HttpHeaders).append('Authorization', token)})
            .pipe(map(response => {
                 return response;
            }));
    }

	public CodeResolucion(resolucion: any): any {
        const folio = (JSON.parse(localStorage.getItem('client_details')).folio).toString();
        const nombreCliente = JSON.parse(localStorage.getItem('client_details')).cliente;

        const request = {
            folio,
            nombreCliente,
            id_resolucion: resolucion.id_resolucion,
            id_solicitud: resolucion.id_solicitud,
            puntuacion_final: resolucion.puntuacion_final,
            participante_uno: resolucion.participante_uno || false,
            participante_dos: resolucion.participante_dos || false,
            comentarios: resolucion.comentarios || '',
			...resolucion,
        };

        const { token } = JSON.parse(localStorage.getItem('currentUser'));

        return this.http.post<any>(`${environment.config.apiUrl}comite/GuardarResolucion`, request,
            {observe: 'response' as 'body',
            headers: (new HttpHeaders).append('Authorization', token)})
            .pipe(map(response => {
                 return response;
            }));
    }

	sendMailCommentaries(body: any) {
		//return this.http.post<any>(`${environment.config.apiUrl}documents/getLetterComments, body)
		return this.http.post(`${environment.config.apiUrl}documents/getLetterComments`, body,
		{responseType: 'arraybuffer' as 'json'})
		//{observe: 'response', responseType: 'arraybuffer'}
	}

    getDatosGenerales(body: any) {
		return this.http.post<any>(`${environment.config.apiUrl}getDatosGenerales`, body)
		// , {
		// 	observe: 'response' as 'body',
		// 	headers: (new HttpHeaders).append('Content-Type', 'application/json')
		// })
		// .pipe(map(form => {
		// 	console.log(form);
		// 	return form;
		// }));
    }

    geteReferencias(body: any) {

        return this.http.post<any>(`${environment.config.apiUrl}getReferencias`, body, {
			observe: 'response' as 'body',
			headers: (new HttpHeaders).append('Content-Type', 'application/json')
		})
		.pipe(map(form => {
			return form;
		}));
	}

	geteAval(body: any) {

        return this.http.post<any>(`${environment.config.apiUrl}getAval`, body, {
			observe: 'response' as 'body',
			headers: (new HttpHeaders).append('Content-Type', 'application/json')
		})
		.pipe(map(form => {
			return form;
		}));
    }


    async saveDatosGenerales(data: any, parte: number, tipo_persona: number, tipo_guardado, id_cliente = 0, folio = '' ) {
		const solicitud = this.getReferencia()
		let body: any;

		let mongo = data;
		console.log("DATA:", data);
		console.log("Aqui empezamos");
		console.log("Code: ", this.user.folio);
		
		
		const update = localStorage.getItem('update') == 'true'
		console.log(update);
		
		if (parte == 1) {
			body =	{
				guardado: {
					parte,
					tipo_persona,
					tipo_guardado,
					folio: this.solicitud.folio,
					update
				},
				folio: this.perfil == 1 ? this.user.folio : this.solicitud.folio,
				id_cliente: this.user.id_cliente,
				latitud: this.latitude,
				longitud: this.longitude,
				mongo
			};
		} else {
			body = data;
			body['guardado'] = {
				parte,
				tipo_persona,
				tipo_guardado,
				// id: this.solicitud.IDSolicitud,
				// referencia_dolicitud_datos: this.solicitud.ReferenciaSolicitudDatos
				...solicitud,
				// folio: this.solicitud.folio,
				update
			};
		}
		console.log("SAVE Datos Generales: ", body);

		return from(
			this.http.post(`${environment.config.apiUrl}saveDatosGenerales`, body, this.httpHeaders)
			.toPromise().then((res: any) => {
				localStorage.setItem('solicitud', JSON.stringify(res));
				console.log('Response', res);
					return res;
				}
			).catch((e) => {
				console.log('Error: ----- ', e);
				throw e;
			})
		);
	}


	saveReferencias(data: any, parte: number, tipo_persona: number, tipo_guardado, id_cliente = 0, folio = '' ) {
		let body: any;
		console.log("Data: ", data);
		const update = localStorage.getItem('update') == 'true'
		console.log(update);
		const solicitud = this.getReferencia()
		if (this.hasSolicitud) {
			body = data;
			body['guardado'] = {
				parte,
				tipo_persona,
				tipo_guardado,
				// id: this.solicitud.IDSolicitud,
				// referencia_dolicitud_datos: this.solicitud.ReferenciaSolicitudDatos
				...solicitud,
				update
			};
		} else {
			body = data;
			body['guardado'] ={
				parte,
				tipo_persona,
				tipo_guardado,
				...solicitud,
				update
			};
		}
		console.log("Body: ",body);
		return from(
			this.http.post(`${environment.config.apiUrl}saveReferencias`, body, this.httpHeaders)
			.toPromise().then((res: any) => {
				localStorage.setItem('solicitud', JSON.stringify(res));
				console.log('Response', res);
					return res;
				}
			).catch((e) => {
				console.log('Error: ----- ', e);
				throw e;
			})
		);
	}

	saveAval(data: any, parte: number, tipo_persona: number,tipo_guardado, id_cliente = 0, folio = '' ) {
		let body: any;
		const tipo_aval = Number(localStorage.getItem('tipoAval'))
		const update = localStorage.getItem('update') == 'true'
		console.log(update);
		data['tipo_aval'] = tipo_aval
		const solicitud = this.getReferencia()
		if (this.hasSolicitud) {
			body = data;
			body['guardado'] = {
				parte,
				tipo_persona,
				tipo_guardado,
				tipo_aval,
				// id: this.solicitud.IDSolicitud,
				// referencia_dolicitud_datos: this.solicitud.ReferenciaSolicitudDatos
				...solicitud,
				update
			};
		} else {
			body = data;
			body['guardado'] ={
				parte,
				tipo_persona,
				tipo_guardado,
				tipo_aval,
				// folio: this.user.folio,
				// id_cliente: this.user.id,
				...solicitud,
				update
			};
		}
		console.log(body);
		return from(
			this.http.post(`${environment.config.apiUrl}saveAval`, body, this.httpHeaders)
			.toPromise().then((res: any) => {
				localStorage.setItem('solicitud', JSON.stringify(res));
				console.log('Response', res);
					return res;
				}
			).catch((e) => {
				console.log('Error: ----- ', e);
				throw e;
			})
		);
	}

	BuscarAval( id_cliente: Number ){
		return this.http.post(`${environment.config.apiUrl}comite/ObtenerAval`, { id_cliente }).toPromise()
	}

	ObtenerMensualidad( folio, version, plazo ){
		return this.http.post(`${environment.config.apiUrl}documents/mensualidad`, { folio, plazo, version }).toPromise()
	}

	ObtenerResidual( folio, version, plazo ){
		return this.http.post(`${environment.config.apiUrl}documents/residual`, { folio, plazo, version }).toPromise()
	}

	ObtenerTramite( folio ){
		return this.http.post(`${environment.config.apiUrl}obtener_tramite`, { folio }).toPromise()
	}

	GuardarContactoCoFi( data ){
		return this.http.post(`${environment.config.apiUrl}arcus/crear_contacto`, data).toPromise()
	}

	ObtenerDatosSolicitudes( data ){
		return this.http.post(`${environment.config.apiUrl}dynamodb/obtener`, data).toPromise()
	}

	ActualizarReferencias(body:any){
		return this.http.post<any>(`${environment.config.apiUrl}comite/ActualizarReferencias`, {...body, isKey: true}, {
			observe: 'response' as 'body',
			headers: (new HttpHeaders).append('Content-Type', 'application/json')
		})
		.pipe(map(form => {
			return form;
		}));
	}
	GuardarAnalisisCredito(data: any){
		return this.http.post(`${environment.config.apiUrl}analisis_credito/guardar`, data).toPromise()
	}
	ObtenerAnalisisCredito(folio){
		return this.http.post(`${environment.config.apiUrl}analisis_credito/obtener`, {folio})
	}
	ObtenerPDFAnalisisCredito(folio){
		return this.http.post(`${environment.config.apiUrl}analisis_credito/obtener_pdf`, {folio}).toPromise()
	}
	ObtenerReporteSolicitudes(data){
		return this.http.post(`${environment.config.apiUrl}reporte_solicitudes/obtener`, data).toPromise()
	}

	GuardarSolicitud(folio, data) {
		return this.http.post(`${environment.config.apiUrl}dynamodb/guardar`, {
			data,
			tableName: 'solicitudes',
			keys: {
				folio
			}
		}).toPromise()
	}
	GuardarCliente(email,rfc, data) {
		return this.http.post(`${environment.config.apiUrl}dynamodb/guardar`, {
			data,
			tableName: 'clientes',
			keys: {
				email,
				rfc
			}
		}).toPromise()
	}

	async ObtenerSolicitud(folio) {
		const resp: any = await this.http.post(`${environment.config.apiUrl}dynamodb/obtener`, {
			tableName: 'solicitudes',
			filters: {
				folio
			},
			isKey: true
		}).toPromise()

		const {data: {items}} = resp
		return items[0] || null;
	}

	async ObtenerDatosCliente(email) {
		const resp: any = await this.http.post(`${environment.config.apiUrl}dynamodb/obtener`, {
			tableName: 'clientes',
			filters: {
				email
			}
		}).toPromise()

		const {data: {items}} = resp
		return items[0] || null;
	}

}

