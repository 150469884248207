<div class="login-container">
  <img class="logo" mat-button height="60px" width="auto" [routerLink]="['/dashboard']" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/logo-header.svg" alt="">
  <h2>Inicio sesión</h2>

  <form [formGroup]="loginForm" #login="ngForm" (ngSubmit)="onSubmit()" (keyup)="onKeyup($event)">
	<!--
	<mat-card-content class="login-form">
      
      <mat-form-field class="input form-group" >
        <mat-label color="white">Correo electrónico</mat-label>
        <input matInput formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
        <mat-icon matSuffix>email</mat-icon>
          <mat-error *ngIf="f.username.errors" class="invalid-feedback">
              Correo no valido, vuelve a intentarlo.
              
          </mat-error>
      </mat-form-field>

      <mat-form-field class="input form-group" >
        <mat-label>Contraseña</mat-label>  
        <input matInput [type]="show_password ? 'text' : 'password'" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
        <mat-icon matSuffix (click)="show_password = !show_password">{{ show_password ? 'visibility_off' : 'visibility' }}</mat-icon> 
        <mat-error *ngIf="f.password.errors" class="invalid-feedback">
              Contraseña debe contener minimo 8 caracteres.
          </mat-error>
      </mat-form-field>

      <div>
        <p class="login-register">¿Eres nuevo?&nbsp;&nbsp;<a routerLink="/register" href="#" style="color: rgba(63, 164, 215, 1);">Registrate</a></p>
        <a href="#" routerLink="/reset" class="login-lost-psw">Olvidé mi contraseña</a>
      </div>
      
	</mat-card-content>
	-->
	<img *ngIf="loading" class="pl-3" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
	
  </form>
  
  <button mat-mini-fab [disabled]="!login.form.valid" (click)="login.ngSubmit.emit()"><mat-icon>navigate_next</mat-icon></button>
  <app-socials-footer style="width:100%;"></app-socials-footer>
</div>
