<form [formGroup]="personaForm" class="frmDatosPersonales" >
	<div class="datos_generales_1">
		<div>
			<mat-label>Apellido Paterno</mat-label>
			<input  (input)="campoValido('ap_paterno',$event)"
			[specialCharacters]="['space']"
			[patterns]="customPattern"
			mask="S{26}"
				formControlName="ap_paterno" 
				[value]="datosGen.ap_paterno"
				[ngClass]="{ 'is-invalid': submitted && f.ap_paterno.errors }"
				placeholder="Como aparece en el INE"
			>
			
				<mat-error *ngIf="submitted && !f.ap_paterno.value">Campo obligatorio</mat-error>
				<mat-error *ngIf="submitted && f.ap_paterno.value && f.ap_paterno.invalid">Campo inválido</mat-error>
			
		</div>

		<div>
			<div><div class="labelDiv"><mat-label>Apellido Materno</mat-label></div><div (click)="showTooltip('tooltipMaterno')">
				<p>
					<svg xmlns="http://www.w3.org/2000/svg" style="border: 2px solid #3F4A57; border-radius: 45px;" width="19" height="19" viewBox="0 0 17.328 17.328"><defs><style>.info_icon{fill:#fff; stroke: #3F4A57;stroke-width: 1;}</style></defs><path class="info_icon" d="M10.664,2a8.664,8.664,0,1,0,8.664,8.664A8.664,8.664,0,0,0,10.664,2Zm.866,13H9.8V13.263H11.53Zm0-3.466H9.8v-5.2H11.53Z" transform="translate(-2 -2)"/></svg>
        		</p></div></div>
			<div class="noDisplay" id="tooltipMaterno">
				<p>Si no existe el apellido materno por ser extranjero, deberá colocarse el único apellido en "Apellido paterno" y en "Apellido materno" debe ingresarsela frase "NO PROPORCIONADO"</p>
			</div>
			<input  (input)="campoValido('ap_materno',$event)"
				[specialCharacters]="['space']"
				[patterns]="customPattern"
				mask="S{26}"
				formControlName="ap_materno" 
				[value]="datosGen.ap_materno"
				[ngClass]="{ 'is-invalid': submitted && f.ap_materno.errors }"
				placeholder="Como aparece en el INE"
			>
			<!-- <mat-error *ngIf="submitted && f.ap_materno.errors" class="invalid-feedback"> -->
				<mat-error *ngIf="submitted && !f.ap_materno.value">Campo obligatorio</mat-error>
				<mat-error *ngIf="submitted && f.ap_materno.value && f.ap_materno.invalid">Campo inválido</mat-error>
			<!-- </mat-error> -->
		</div>

		<div>
			<mat-label>Primer Nombre</mat-label>
			<input  (input)="campoValido('primer_nombre',$event)"
			[specialCharacters]="['space']"
			[patterns]="customPattern"
			mask="S{26}"
				formControlName="primer_nombre" 
				[value]="datosGen.primer_nombre"
				[ngClass]="{ 'is-invalid': submitted && f.primer_nombre.errors }"
				placeholder="Como aparece en el INE"
			>
			<!-- <mat-error *ngIf="submitted && f.primer_nombre.errors" class="invalid-feedback"> -->
				<mat-error *ngIf="submitted && !f.primer_nombre.value">Campo obligatorio</mat-error>
				<mat-error *ngIf="submitted && f.primer_nombre.value && f.primer_nombre.invalid">Campo inválido</mat-error>
			<!-- </mat-error> -->
		</div>

		<div>
			<mat-label>Segundo Nombre</mat-label>
			<input  (input)="campoValido('segundo_nombre',$event)"
			[specialCharacters]="['space']"
			[patterns]="customPattern"
			mask="S{26}"
				formControlName="segundo_nombre" 
				[value]="datosGen.segundo_nombre"
				[ngClass]="{ 'is-invalid': submitted && f.segundo_nombre.errors }"
				placeholder="Como aparece en el INE"
			>
			<!-- <mat-error *ngIf="submitted && f.segundo_nombre.errors" class="invalid-feedback"> -->
				<mat-error *ngIf="submitted && !f.segundo_nombre.value">Campo obligatorio</mat-error>
				<mat-error *ngIf="submitted && f.segundo_nombre.value && f.segundo_nombre.invalid">Campo inválido</mat-error>
			<!-- </mat-error> -->
		</div>
	</div>

	<div class="datos_generales_2">
		<div>
			<mat-label>Fecha de nacimiento</mat-label>
			<input
				id="myDate"
				formControlName="fecha_nacimiento" 
				[value]="datosGen.fecha_nacimiento"
				[ngClass]="{ 'is-invalid': submitted && f.fecha_nacimiento.errors }"
				type="date"
				max="{{minDate | date: 'yyyy-MM-dd'}}"
			>
			<mat-error *ngIf="submitted && f.fecha_nacimiento.errors" class="invalid-feedback">
				<div *ngIf="f.fecha_nacimiento.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>
		<div>
			<mat-label>Teléfono móvil</mat-label>
			<input  
				mask="00 0000 0000"
				placeholder="( _ _ )"
				type="tel"
				formControlName="target" 
				[value]="datosGen.target"
				[ngClass]="{ 'is-invalid': submitted && !f.target.valid }"
			>
			<mat-error *ngIf="submitted && !f.targetvalid" class="invalid-feedback">
				<div *ngIf="!f.target.value">Campo obligatorio</div>
				<div *ngIf="f.targetvalue && !f.targetvalid">Teléfono móvil no válido</div>
			</mat-error>
		</div>

		<div>
			<mat-label>Correo electrónico</mat-label>
			<input  [readonly]="isClient"
			placeholder="@"
				type="email"
				formControlName="correo" 
				[value]="datosGen.correo"
				[ngClass]="{ 'is-invalid': submitted && !f.correo.valid }"
			>
			<mat-error *ngIf="submitted && !f.correo.valid" class="invalid-feedback">
				<div *ngIf="!f.correo.value">Campo obligatorio</div>
				<div *ngIf="f.correo.value && !f.correo.valid">Correo no válido</div>
			</mat-error>
		</div>

		<div>
			<mat-label>RFC</mat-label>
			<input  
				mask="SSSS000000AAA"
				formControlName="rfc" 
				[value]="datosGen.rfc"
				[ngClass]="{ 'is-invalid': submitted && !f.rfc.valid }"
			>
			<mat-error *ngIf="submitted && !f.rfc.valid" class="invalid-feedback">
				<div *ngIf="!f.rfc.value">Campo obligatorio</div>
				<div *ngIf="f.rfc.value && !f.rfc.valid">RFC no válido</div>
			</mat-error>
		</div>

		<div>
			<mat-label>Sexo</mat-label>
			<mat-radio-group formControlName="sexo" [value]="datosGen.sexo" name="select">
				<mat-radio-button labelPosition="before" value="M">Masculino</mat-radio-button>
				<mat-radio-button style="margin-left: 20px;" labelPosition="before" value="F">Femenino</mat-radio-button>
			</mat-radio-group>
			<mat-error *ngIf="submitted && f.sexo.errors" class="invalid-feedback">
				<div *ngIf="f.sexo.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<div>
			<mat-label>Nacionalidad</mat-label>
			<mat-select formControlName="nacionalidad" [value]="datosGen.nacionalidad">
				<mat-option *ngFor="let nacionalidad of nacionalidades" value="{{nacionalidad.id}}">{{nacionalidad.id}}		{{nacionalidad.valor}}</mat-option>
			</mat-select>
			<mat-error *ngIf="submitted && f.nacionalidad.errors" class="invalid-feedback">
				<div *ngIf="f.nacionalidad.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>
	</div>
</form>

<div class="row-end">
	<div></div>
	<button mat-button (click)="onSubmit()">
		Siguiente
		<svg [ngClass]="{'btnNext': personaForm.valid}" style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><defs><style>.abc{fill:#707070;}</style></defs><path class="abc" d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0ZM22,38V18.7L13.448,27.25l-3.394-3.394L24,9.908,37.948,23.856,34.554,27.25,26,18.7V38Z" transform="translate(48) rotate(90)"/></svg>
	</button>
</div>