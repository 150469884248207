<div mat-dialog-content class="generator-csv-container">
    <div class="header">
        <div></div>
        <h2>Detalle de archivo CSV</h2>
        <div><mat-icon style="cursor: pointer;" (click)="Cerrar()">close</mat-icon></div>
    </div>
    <div class="container">
        <table>
            <thead>
                <tr>
                    <th>Descripción</th>
                    <th>Monto s/IVA</th>
                    <th>IVA</th>
                    <th>Periodicidad</th>
                    <th>Plazo</th>
                    <th>Tipo Activo</th>
                    <th>Tipo Linea</th>
                    <th>Comisión Apertura</th>
                    <th>Deposito Garantía</th>
                    <th>Comercial</th>
                    <th>Vendor</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of data; let i = index;">
                    <td><input size="60" [(ngModel)]="row.descripcion" /></td>
                    <td><input mask="separator.2" thousandSeparator="," prefix="$ " size="15" style="text-align: right;" [(ngModel)]="row.montoSIva" /></td>
                    <td><input mask="separator.2" thousandSeparator="," prefix="$ " size="15" style="text-align: right;" [(ngModel)]="row.iva" /></td>
                    <td>
                        <mat-select [(ngModel)]="row.periodicidad" style="width: 120px;">
                            <mat-option *ngFor="let periodicidad of tipo_periodo" [value]="periodicidad">{{periodicidad}}</mat-option>
                        </mat-select>
                    </td>
                    <td><input size="3" [(ngModel)]="row.plazo" /></td>
                    <td>
                        <mat-select [(ngModel)]="row.tipoActivo" style="width: 180px;">
                            <mat-option *ngFor="let activo of tipo_activo" [value]="activo">{{activo}}</mat-option>
                        </mat-select>
                    </td>
                    <td><input size="50" [(ngModel)]="row.tipoLinea" /></td>
                    <td><input *ngIf="i == 0" size="5" [(ngModel)]="row.comisionApertura" /></td>
                    <td><input *ngIf="i == 0" size="5" [(ngModel)]="row.depositoGarantia" /></td>
                    <td><input *ngIf="i == 0" size="30" [(ngModel)]="row.comercial" /></td>
                    <td><input *ngIf="i == 0" size="50" [(ngModel)]="row.vendor" /></td>
                </tr>
            </tbody>
        </table>
    </div>
    <button class="principal_button" (click)="Descargar()">Descargar</button>
</div>