import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, AuthenticationService, UserService } from '../../_services';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { User } from 'src/app/_models';

/* tslint:disable */
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    user_not_register = false;
    show_password = false;
    submitted = false;
    returnUrl: string;
    new_register: boolean = false
    email = null
    token = null

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private userService: UserService,
        private dialog: MatDialog
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/dashboard']);
        }
    }

    ngOnInit() {
  this.loginForm = this.formBuilder.group({
            username: ['', [Validators.required, Validators.pattern(/^([a-zA-Z]|[0-9]+[a-zA-Z]+)([a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~])*@[a-zA-Z0-9\-]+\.[a-zA-Z]{2,}/)]],
            password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]]
            // password: ['', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&#.])[A-Za-z0-9$@$!%*?&#.].{7,19}$')]]
        });

        // get return url from route parameters or default to '/'
        this.route.queryParams.subscribe( params => {
            console.log(params);
            if ( (params.email && params.email !== '') && (params.token && params.token !== '') ) {
                this.new_register = true
                this.email = params.email
                this.token = params.token
                this.f.username.setValue(this.email)
            }
        })
  this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/dashboard';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    async onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;

        if (this.new_register) {
            console.log('Creando cobtraseña');
            const user = new User
            user.email = this.email
            user.password = this.f.password.value
            user.token = this.token

            const response: any = await this.userService.register(user)
            console.log(response);
            this.loading = false
            if (response.id_usuario > 0) {
                console.log('Mejorar cotizacion', response);
                localStorage.setItem('mejorar_cotizacion', JSON.stringify({id_usuario: response.id_usuario, cotizacion: response.options}))
                localStorage.setItem('currentUser', JSON.stringify({token: this.token}))

                this.router.navigate(['mejorar_cotizacion']);
            }
            return
        }
        this.authenticationService.login(this.f.username.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                ({status, body, body: {errorMessage}}) => {
                    console.log(body);

                    this.loading = false;
                    if (status == 220) {
                        // this.user_not_register = true
                        this.dialog.open(MessageBoxComponent, {
                            width: '500px',
                            data: {
                                text: errorMessage,
                                type: 'error',
                                icon: 'error'
                            }
                        });
                    } else if ( status === 201 ) {
                        this.dialog.open(MessageBoxComponent, {
                            width: '500px',
                            data: {
                                text: 'La cuenta no ha sido activada.',
                                type: 'error',
                                icon: 'error'
                            }
						});
                    } else if ( status === 200 ) {
					if ( this.isVendor(body) ) {
						this.router.navigate(['crear_cotizacion'], {queryParams: {tramite: true}});
						return;
					}

      const {perfil, quatation} = this.authenticationService.currentUserValue;

      if ( perfil == 1 && quatation?.Status ) { this.router.navigate(['/dashboard']); } else if ( perfil == 1) { this.router.navigate(['codeActivation']); } else { this.router.navigate(['/dashboard']); }

      this.loading = false;

                        // this.checkProcess();
                        // this.router.navigate([this.returnUrl]);
                    } else {
                        this.dialog.open(MessageBoxComponent, {
                            width: '500px',
                            data: {
                                text: 'El servicio no está disponible por el momento.',
                                text3: 'Vuelve a intentarlo.',
                                type: 'error',
                                icon: 'error'
                            }
                        });
                    }
                },
                error => {
                    this.loading = false;
                    this.dialog.open(MessageBoxComponent, {
                        width: '500px',
                        data: {
                            text: 'Error de conexión',
                            type: 'error',
                            icon: 'error'
                        }
                    });
                });
    }

    onKeyup({keyCode}) {
        if ( keyCode === 13) { this.onSubmit(); }
    }
    checkProcess() {
        // this.userService.checkProcess(this.authenticationService.currentUserValue)
        //     .pipe(first())
        //     .subscribe(
        //         data => {

        //         },
        //         error => {
        //             this.router.navigate(['codeActivation']);
        //         });
    }

    isVendor(body) {
		return [2,7, 11].includes(body.perfil);
		// if ( [2,7, 11].includes(body.perfil)) {
            // const dialogref = this.dialog.open(MessageBoxComponent, {
            //     width: "500px",
            //     data: {
            //         text: "Vendor",
            //         text2: "Te invitamos a descargar nuestra app para poder iniciar sesión.",
            //         text3: "Inicio de sesión no permitido",
            //         type: "error",
            //         icon: "error"
            //     }
            // }).afterClosed().subscribe(()=>{
            //     this.authenticationService.logout()
            //     localStorage.clear()
            //     this.router.navigateByUrl('/')
            // })
//             return true;
//         }
//   return false;
    }
}
