<div class="table-documentos">
    <h2>Selecciona los documentos</h2>
    <table class="table-documentos documentos-list" [cellSpacing]="0" [cellPadding]="0">
        <thead>
            <tr>
                <th>N.</th>
                <th>Documento</th>
                <th>Requerido</th>
            </tr>
        </thead>
        <tbody>
            <tr [ngClass]="{seleccionado: DocumentoSeleccionado(documento.id)}" *ngFor="let documento of documentoService.documentos; let idx = index" (click)="AgregarQuitar(documento.id)">
                <td>{{ idx +1 }}</td>
                <td>{{ documento.tipo }}</td>
                <td>{{ documento.requerido ? 'Sí' : 'No'}}</td>
            </tr>
        </tbody>
    </table>
</div>
<div style="display: flex; justify-content: flex-end; align-items: center; margin-top: 24px;">
    <mat-progress-spinner
        *ngIf="guardando"
        mode="indeterminate"
        diameter="30"
        ></mat-progress-spinner>
    <button *ngIf="!guardando" class="btn-primary-new" (click)="GuardarDocumentos()">Guardar</button>
</div>