import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tramite',
  templateUrl: './tramite.component.html',
  styleUrls: ['./tramite.component.scss']
})
export class TramiteComponent implements OnInit {
  @Output() onVerExpediente = new EventEmitter
  @Output() onVerDetalles = new EventEmitter
  @Output() onConfirmacion = new EventEmitter

  @Input() tramite
  dias = 0
  perfil = 0
  constructor() { }

  ngOnInit(): void {
    this.perfil = JSON.parse(localStorage.getItem('currentUser')).perfil
    this.ObtenerDias()
    this.ProcesoActual()
  }

  ObtenerDias(){
    this.tramite.procesos.forEach( proc => this.dias += proc.dias)
    this.dias += this.tramite.registro > 0 ? this.tramite.registro : 0
    this.dias -= ( this.perfil == 3 ) ? this.tramite.procesos[3].dias : 0
  }

  ProcesoActual() {
    this.tramite.procesos[0].estado = this.tramite.procesos[0].status == 3 ? 'Duró' :  this.tramite.procesos[0].status > 0 && this.tramite.procesos[1].status == 0 ? 'Actual' : ''
    this.tramite.procesos[1].estado = this.tramite.procesos[1].status == 3 ? 'Duró' : this.tramite.procesos[1].status > 0 && this.tramite.procesos[2].status == 0 ? 'Actual' : ''
    this.tramite.procesos[2].estado = this.tramite.procesos[2].status == 3 ? 'Duró' : this.tramite.procesos[2].status > 0 && this.tramite.procesos[3].status == 0 ? 'Actual' : ''
    this.tramite.procesos[3].estado = this.tramite.procesos[3].status == 3 ? 'Duró' : this.tramite.procesos[3].status > 0 ? 'Actual' : ''
  }

  VerExpediente() {
    console.log("Ver expediente...", this.tramite.procesos[1].estado);
    
    if( this.tramite.procesos[1].estado == 'Actual'){
      this.onVerExpediente.emit()
    }
  }

  VerDetalles(){
    this.onVerDetalles.emit()
  }

  Confirmacion(){
    if(this.tramite.procesos[2].estado == 'Actual'){
      this.onConfirmacion.emit()
    }
  }
}
