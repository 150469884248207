import { Component, OnInit } from '@angular/core';
import { tableFields } from './util';
import { SolicitudService } from 'src/app/_services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tabla-pipelines',
  templateUrl: './tabla-pipelines.component.html',
  styleUrls: ['./tabla-pipelines.component.scss']
})
export class TablaPipelinesComponent implements OnInit {
  headers
  data = []
  summary = []

  constructor(private solicitud: SolicitudService) {
    this.headers = {...tableFields}
  }

  async ngOnInit() {
    const res = await this.solicitud.ObtenerDatosSolicitudes({
      "tableName": "solicitudes",
      "filters": {
          "is_demo": false // environment.config.ENV == '' ? false : true
      }
    })

    this.data = res['data']['items']
    this.summary = res['data']['summary']
    console.log({res});
    
  }

}
