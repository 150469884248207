<div class="activation-container">
    <header>
        <img class="logo" mat-button height="60px" width="auto" [routerLink]="['/dashboard']" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/logo-header.svg" alt="">
    </header>
    <div class="body">
      <h2 class="subtitle">¿{{nuevo ? 'Nuevo' : 'Primer' }}<br>registro?</h2>
      <div class="box">
        <p>Ingresa tu código de activación el cual</p>
        <p>fue enviado a tu correo previamente</p>
        <form class="form-code" [formGroup]="codeForm" #activation="ngForm" (ngSubmit)="onSubmit()">
          <div class="input-code">
              <!-- <angular7-pincode (currentIndex)="idx" class="verification-code" [ngClass]="{'error': invalid_code}" size="6" (keydown)="onFullFill($event)" (onFullFill)="onFullFill($event)">
              </angular7-pincode> -->

              <code-input class="verification-code2" [ngClass]="{'error': invalid_code}"
                [isCodeHidden]="false"
                [isNonDigitsCode]="false"
                [codeLength]="6"
                (codeChanged)="onFullFill($event)"
                (codeCompleted)="onFullFill($event)">
            </code-input>
            </div>
            <div class="required" *ngIf="invalid_code">Código invalido, vuelve a intentarlo.</div>
        </form>
        <p style="font-size: .9rem;">Si no has recibido el correo,</p>
        <p style="font-size: .9rem;">Solicitalo nuevamente <a class="cursor-link" (click)="this.sendCode()">aquí</a></p>
      </div>
    </div>
    <button mat-mini-fab class="button-next" [disabled]="!isValid" form="this.codeForm" (click)="activation.ngSubmit.emit()"><mat-icon>navigate_next</mat-icon></button>
    
    <app-socials-footer style="width: 100%;"></app-socials-footer>
  </div>
<!-- <div class="activation">
    <div class="activation-form">
        <div class="container-form">
            <h1 class="title">¿Primer tramite?</h1>
            <form class="form-code" action="" [formGroup]="codeForm" (ngSubmit)="onSubmit()">
                <p class="description">Ingresa tú código de activación</p>
                <div class="input-code">
                    <angular7-pincode class="verification-code" size="6" (keydown)="onFullFill($event)" (onFullFill)="onFullFill($event)">
                    </angular7-pincode>
                    <div class="required" *ngIf="!isValid">Campo requerido</div>
                </div>
                <div class="form-actions">
                    <button mat-raised-button color="primary" class="btn-primary">Entrar</button>
                </div>
            </form>
        </div>
        <div class="bg-activation"></div>
    </div>

</div> -->
