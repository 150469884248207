import { Injectable } from '@angular/core';
import { AnalisisPM } from '../pages/valuacion_PM/AnalisisPM';

@Injectable({
  providedIn: 'root',
})
export class AnalisisPMService {
  public analisisPM = AnalisisPM;  

  getAnalisisPM(): any {
    return this.analisisPM;
  }

  setAnalisisPM(nuevoAnalisisPM: any): void {
    this.analisisPM = nuevoAnalisisPM;
  }
}
