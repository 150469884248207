<div class="container-login">
  <div class="header">
    <img
      class="logo"
      mat-button
      height="50px"
      width="auto"
      [routerLink]="['/dashboard']"
      src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/NewLogo-L4U.png"
      alt="leaseforu"
    />
  </div>
  <div class="container">
    <form
      class="frmLogin"
      [formGroup]="loginForm"
      #login="ngForm"
      (ngSubmit)="onSubmit()"
      (keyup)="onKeyup($event)"
    >
      <div class="card-login">
        <div>
          <h2>Bienvenido,</h2>
          <h1>{{ new_register ? 'Crear contraseña' : 'Inicia Sesión'}}</h1>
          <div class="newInput">
            <mat-form-field appearance="outline" class="input-email">
              <mat-label>Correo electrónico</mat-label>
              <input
                matInput
                type="email"
                formControlName="username"
                [readonly]="new_register"
                [ngClass]="{
                  'invalid-input':
                    (f.username.touched && !f.username.valid) ||
                    user_not_register
                }"
              />
              <mat-error
                *ngIf="!f.username.valid && f.username.touched"
                [ngClass]="{
                  'invalid-input': !f.username.valid && f.username.touched
                }"
              >
                <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/warning.svg" />
                Correo no válido, vuelve a intentarlo.</mat-error
              >
              <mat-error *ngIf="user_not_register" class="invalid-input">
                <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/warning.svg" />
                Correo no registrado.</mat-error
              >
            </mat-form-field>
          </div>
          <div class="newInput">
            <mat-form-field appearance="outline">
              <mat-label>Constraseña</mat-label>
              <input
                matInput
                [type]="show_password ? 'text' : 'password'"
                formControlName="password"
                [ngClass]="{
                  'invalid-input': f.password.touched && !f.password.valid
                }"
                placeholder="Introduce contraseña"
                maxlength="20"
              />
              <!-- <mat-icon *ngIf="!f.password.valid && f.password.touched" [ngClass]="{'invalid-input': !f.password.valid && f.password.touched}">clear</mat-icon> -->
              <mat-icon matSuffix (click)="show_password = !show_password" [ngClass]="{
                'invalid-input': f.password.touched && !f.password.valid
              }">{{
                show_password ? "visibility_off" : "visibility"
              }}</mat-icon>
              <mat-error
                *ngIf="!f.password.valid && f.password.touched"
                [ngClass]="{
                  'invalid-input': !f.password.valid && f.password.touched
                }"
              >
                <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/warning.svg" /> Contraseña no
                válida, vuelve a intentarlo.</mat-error
              >
            </mat-form-field>
          </div>
          <div class="registrar">
            <!-- <p *ngIf="true" class="login-register">
              ¿Eres nuevo?&nbsp;&nbsp;<a
                routerLink="/register"
                href="#"
                style="color: rgba(63, 164, 215, 1)"
                >Registrate</a
              >
            </p> -->
            
            <a *ngIf="!new_register" href="#" routerLink="/reset" class="login-lost-psw"
              >¿Olvidaste tu contraseña?</a
            >
          </div>
          <div class="buttons">
            <button
              mat-raised-button
              color="primary"
              [disabled]="loading"
              class="btn-primary-new"
            >
            
            <mat-progress-spinner
            *ngIf="loading"
            mode="indeterminate"
            diameter="30"
          ></mat-progress-spinner>
        
        <span *ngIf="!loading && !new_register">  Iniciar Sesión</span>
        <span *ngIf="!loading && new_register ">  Crear contraseña</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="container-footer">
    <div class="text-footer">
      <p>© 2020, Lease For U S.A. de C.V. Todos los derechos reservados.</p>
      <!-- <p>Backoffice versión 1.0. Powered by Lease For U</p> -->
    </div>
  </div>
</div>
