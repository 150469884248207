import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-radio-validation',
  templateUrl: './radio-validation.component.html',
  styleUrls: ['./radio-validation.component.scss']
})
export class RadioValidationComponent implements OnInit {
  @Input() options: { label: string, imageUrl: string, active: boolean, value: any }[] = [];
  @Input() formControl: FormControl;
  @Input() formGroup: FormGroup;
  @Input() controlName: string;
  @Input() label: any 
  @Input() isError: boolean = false
  @Input() errorMessage: string = '' 
  radioGroup = 'radioGroup'
  selectedOption: any;

  constructor() { }

  ngOnInit(): void {
    console.log(this.formGroup)
  }

  updateSelectedOption(value: any) {
    this.options.forEach(option => {
      option.active = option.value === value;
    });
    console.log(this.formGroup.get(this.controlName))
    console.log(this.controlName)
    this.formGroup.get(this.controlName)?.setValue(value); 

    console.log('Opción seleccionada:', value);
  }
  getOptionImageUrl(option: any): string {
    return option.active ? option.activeImageUrl : option.imageUrl;
  }

}
