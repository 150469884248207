import { formatDate } from "@angular/common"

export class Promocion {
    id: number = 0
    name: string = ''
    description: string = ''
    date_start: string = formatDate(new Date().toISOString(), 'yyyy-MM-ddTHH:mm', 'es-MX') 
    date_end: string = formatDate(new Date(new Date().setDate(new Date().getDate() +1)).toISOString(), 'yyyy-MM-ddT23:59', 'es-MX') 
    active: boolean = true
    url_image: string = null
    condiciones = {
        monthly_rate: 0.0,
        monthly_rate_msi: 0.0,
        commission_for_opening_type: 'a',
        commission_for_opening_type_msi: 'a',
        commission_for_opening: 0.0,
        commission_for_opening_msi: 0.0,
        descuento_ciego_type: 'a',
        descuento_ciego_type_msi: 'a',
        descuento_ciego: 0.0,
        descuento_ciego_msi: 0.0
    }
    vendors = {
        ids: [],
        list: []
    }

    Promocion() {

    }
}