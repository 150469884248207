<div>
  <div style="display: grid; width: 100%; grid-template-columns: 70% 30%; gap: 2%;overflow: scroll;  ">
    <div style="overflow: scroll;">
    <table>
      <thead>

        <tr> 
          <th colspan="5" style="text-align: start;">BALANCE GENERAL</th>         
        </tr>
        <tr>
          <th colspan="5" style="text-align: start;">Activo</th>
        </tr>
        <tr>
          <th  >Activo Circulante</th>
          <th  colspan="2">2022</th>
          <th  colspan="2">2023</th>
          <th >
            {{obtenerNombreMes(AnalisisPM.anexo.mesSelected.id)}}
          </th>
          
          <th >Análisis Vertical</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Caja</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_circulante.caja['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," (input)="recalcularTotales()" [(ngModel)]="AnalisisPM.anexo.activo_circulante.caja['_' + year]" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_circulante.caja['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" readonly [ngModel]="AnalisisPM.anexo.activo_circulante.caja['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        
        <tr>
          <td>Bancos</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_circulante.bancos['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="bancos_{{year}}" [(ngModel)]="AnalisisPM.anexo.activo_circulante.bancos['_' +year]" (input)="recalcularTotales()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_circulante.bancos['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" name="bancos_porcentaje_{{year}}" readonly [(ngModel)]="AnalisisPM.anexo.activo_circulante.bancos['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        
        <tr>
          <td>Clientes</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_circulante.clientes['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="clientes_{{year}}" [(ngModel)]="AnalisisPM.anexo.activo_circulante.clientes['_' +year]" (input)="recalcularTotales()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_circulante.clientes['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" name="clientes_porcentaje_{{year}}" readonly [(ngModel)]="AnalisisPM.anexo.activo_circulante.clientes['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Inventarios</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_circulante.inventarios['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="inventarios_{{year}}" [(ngModel)]="AnalisisPM.anexo.activo_circulante.inventarios['_' +year]" (input)="recalcularTotales()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_circulante.inventarios['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" name="inventarios_porcentaje_{{year}}" readonly [(ngModel)]="AnalisisPM.anexo.activo_circulante.inventarios['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Cuentas por Cobrar</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_circulante.cuentas_por_cobrar['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="cuentas_por_cobrar_{{year}}" [(ngModel)]="AnalisisPM.anexo.activo_circulante.cuentas_por_cobrar['_' +year]" (input)="recalcularTotales()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_circulante.cuentas_por_cobrar['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" name="cuentas_por_cobrar_porcentaje_{{year}}" readonly [(ngModel)]="AnalisisPM.anexo.activo_circulante.cuentas_por_cobrar['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Impuestos a favor</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_circulante.impuestos_a_favor['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="impuestos_a_favor_{{year}}" [(ngModel)]="AnalisisPM.anexo.activo_circulante.impuestos_a_favor['_' +year]" (input)="recalcularTotales()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_circulante.impuestos_a_favor['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" name="impuestos_a_favor_porcentaje_{{year}}" readonly [(ngModel)]="AnalisisPM.anexo.activo_circulante.impuestos_a_favor['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Iva pendiente a acred.</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_circulante.iva_pendiente['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="iva_pendiente_{{year}}" [(ngModel)]="AnalisisPM.anexo.activo_circulante.iva_pendiente['_' +year]" (input)="recalcularTotales()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_circulante.iva_pendiente['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" name="iva_pendiente_porcentaje_{{year}}" readonly [(ngModel)]="AnalisisPM.anexo.activo_circulante.iva_pendiente['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Deudores diversos</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_circulante.deudores_diversos['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="deudores_diversos_{{year}}" [(ngModel)]="AnalisisPM.anexo.activo_circulante.deudores_diversos['_' +year]" (input)="recalcularTotales()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_circulante.deudores_diversos['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" name="deudores_diversos_porcentaje_{{year}}" readonly [(ngModel)]="AnalisisPM.anexo.activo_circulante.deudores_diversos['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Pagos Anticipados</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_circulante.pagos_anticipados['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="pagos_anticipados_{{year}}" [(ngModel)]="AnalisisPM.anexo.activo_circulante.pagos_anticipados['_' +year]" (input)="recalcularTotales()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_circulante.pagos_anticipados['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" name="pagos_anticipados_porcentaje_{{year}}" readonly [(ngModel)]="AnalisisPM.anexo.activo_circulante.pagos_anticipados['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Inversiones</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_circulante.inversiones['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="inversiones_{{year}}" [(ngModel)]="AnalisisPM.anexo.activo_circulante.inversiones['_' +year]" (input)="recalcularTotales()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_circulante.inversiones['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" name="inversiones_porcentaje_{{year}}" readonly [(ngModel)]="AnalisisPM.anexo.activo_circulante.inversiones['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Otras cuentas</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_circulante.otras_cuentas['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="otras_cuentas_{{year}}" [(ngModel)]="AnalisisPM.anexo.activo_circulante.otras_cuentas['_' +year]" (input)="recalcularTotales()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_circulante.otras_cuentas['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" name="otras_cuentas_porcentaje_{{year}}" readonly [(ngModel)]="AnalisisPM.anexo.activo_circulante.otras_cuentas['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td ><b> Total Activo circ.</b> </td>
          <td ><input style="width: 100px; font-weight: 700;"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2022" [(ngModel)]="AnalisisPM.anexo.activo_circulante.total_activo_circulante._2022" /></td>
          <td ><input style="width: 100px; font-weight: 700;"  mask="separator.0" [allowNegativeNumbers]="true"  thousandSeparator="," name="total_activo_circulante_porcentaje_2022" readonly [(ngModel)]="AnalisisPM.anexo.activo_circulante.total_activo_circulante.porcentaje_2022"  suffix="%"/></td>
          <td ><input style="width: 100px; font-weight: 700;"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="total_activo_circulante_2023" [(ngModel)]="AnalisisPM.anexo.activo_circulante.total_activo_circulante._2023" /></td>
          <td ><input style="width: 100px; font-weight: 700;"  mask="separator.0" [allowNegativeNumbers]="true"  thousandSeparator="," name="total_activo_circulante_porcentaje_2023" readonly [(ngModel)]="AnalisisPM.anexo.activo_circulante.total_activo_circulante.porcentaje_2023"  suffix="%"/></td>
          <td ><input style="width: 135px; font-weight: 700;"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="total_activo_circulante_parcial_2024" [(ngModel)]="AnalisisPM.anexo.activo_circulante.total_activo_circulante._2024"/></td>
          <td ><input style="width: 135px; font-weight: 700;"  mask="separator.0" [allowNegativeNumbers]="true"  thousandSeparator="," name="total_activo_circulante_analisis_vertical" [(ngModel)]="AnalisisPM.anexo.activo_circulante.total_activo_circulante.porcentaje_2024" suffix="%" readonly/></td>
        </tr>
      </tbody>
    </table>
    </div>
    <div style="overflow: scroll;">
      <table class="tg">
        
        <thead>
          <tr>
            <th colspan="5">Analisis horizontal</th>
          </tr>
          <tr>
            <th  colspan="2">Var(Absoluta)</th>
            <th ></th>
            <th  colspan="2">Var (Porcentual)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>22-23</td>
            <td>23-24</td>
            <td></td>
            <td>22-23</td>
            <td>23-24</td>
          </tr>
          <ng-container *ngFor="let item of ['caja', 'bancos', 'clientes', 'inventarios', 'cuentas_por_cobrar', 'impuestos_a_favor', 'iva_pendiente', 'deudores_diversos', 'pagos_anticipados', 'inversiones', 'otras_cuentas', 'total_activo_circulante']">
            <tr>
              <td><input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_circulante[item].var_absoluta_22_23)" style="width: 85px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="{{ item }}_var_absoluta_22_23" [(ngModel)]="AnalisisPM.anexo.activo_circulante[item].var_absoluta_22_23" /></td>
              <td><input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_circulante[item].var_absoluta_23_24)" style="width: 85px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="{{ item }}_var_absoluta_23_24" [(ngModel)]="AnalisisPM.anexo.activo_circulante[item].var_absoluta_23_24" /></td>
              <td></td>
              <td><input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_circulante[item].var_porcentual_22_23)" style="width: 85px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="{{ item }}_var_porcentual_22_23" [(ngModel)]="AnalisisPM.anexo.activo_circulante[item].var_porcentual_22_23" suffix="%" /></td>
              <td><input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_circulante[item].var_porcentual_23_24)" style="width: 85px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="{{ item }}_var_porcentual_23_24" [(ngModel)]="AnalisisPM.anexo.activo_circulante[item].var_porcentual_23_24" suffix="%" /></td>
            </tr>
          </ng-container>
        </tbody>
        
        </table>
    </div>
  </div>
  <div style="height: 40px;"></div>
  <div style="display: grid; width: 100%; grid-template-columns: 70% 30%; gap: 2%;overflow: scroll;  ">
    <div style="overflow: scroll;">
    <table>
      <thead>

        <tr> 
          <th colspan="5" style="text-align: start;"><div style="height: 28px;"></div> </th>         
        </tr>
        <tr>
          <th colspan="5" style="text-align: start;"><div style="height: 20px;"></div> </th>
        </tr>
        <tr>
          <th  >Activo Fijo</th>
          <th  colspan="2">2022</th>
          <th  colspan="2">2023</th>
          <th >
            {{obtenerNombreMes(AnalisisPM.anexo.mesSelected.id)}}
          </th>
          <th >Análisis Vertical</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Terrenos y Edificios</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_fijo.terrenos_edificios['_' +year])"  style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," [name]="'terrenos_edificios_' + year" [(ngModel)]="AnalisisPM.anexo.activo_fijo.terrenos_edificios['_' +year]" (input)="recalcularTotalesActivoFijo()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_fijo.terrenos_edificios['porcentaje_' + year])"  style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" [name]="'terrenos_edificios_porcentaje_' + year" [(ngModel)]="AnalisisPM.anexo.activo_fijo.terrenos_edificios['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        
        <tr>
          <td>Dep. Terrenos</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input class="red-text" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," [name]="'terrenos_edificios_' + year" [(ngModel)]="AnalisisPM.anexo.activo_fijo.depreciacion_terrenos['_' +year]" (input)="recalcularTotalesActivoFijo()" />
            </td>
            <td>
              <input   style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" [name]="'terrenos_edificios_porcentaje_' + year" [(ngModel)]="AnalisisPM.anexo.activo_fijo.depreciacion_terrenos['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Maquinaria y equipo</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_fijo.maquinaria_equipo['_' +year])"  style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," [name]="'terrenos_edificios_' + year" [(ngModel)]="AnalisisPM.anexo.activo_fijo.maquinaria_equipo['_' +year]" (input)="recalcularTotalesActivoFijo()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_fijo.maquinaria_equipo['porcentaje_' + year])"  style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" [name]="'maquinaria_equipo_porcentaje_' + year" [(ngModel)]="AnalisisPM.anexo.activo_fijo.maquinaria_equipo['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Dep. Maquinaria y equipo</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input class="red-text"  style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," [name]="'depreciacion_maquinaria_equipo_' + year" [(ngModel)]="AnalisisPM.anexo.activo_fijo.depreciacion_maquinaria_equipo['_' +year]" (input)="recalcularTotalesActivoFijo()" />
            </td>
            <td>
              <input   style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" [name]="'depreciacion_maquinaria_equipo_porcentaje_' + year" [(ngModel)]="AnalisisPM.anexo.activo_fijo.depreciacion_maquinaria_equipo['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Mob. de oficina y equipo</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_fijo.mobiliario_oficina_equipo_comp['_' +year])"  style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," [name]="'terrenos_edificios_' + year" [(ngModel)]="AnalisisPM.anexo.activo_fijo.mobiliario_oficina_equipo_comp['_' +year]" (input)="recalcularTotalesActivoFijo()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_fijo.mobiliario_oficina_equipo_comp['porcentaje_' + year])"  style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" [name]="'mobiliario_oficina_equipo_comp_porcentaje_' + year" [(ngModel)]="AnalisisPM.anexo.activo_fijo.mobiliario_oficina_equipo_comp['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Dep. de mob. oficina y equipo</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input class="red-text"  style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," [name]="'depreciacion_mob_oficina_equipo' + year" [(ngModel)]="AnalisisPM.anexo.activo_fijo.depreciacion_mob_oficina_equipo['_' +year]" (input)="recalcularTotalesActivoFijo()" />
            </td>
            <td>
              <input style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" [name]="'depreciacion_mob_oficina_equipo' + year" [(ngModel)]="AnalisisPM.anexo.activo_fijo.depreciacion_mob_oficina_equipo['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Equipo de transporte</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_fijo.equipo_transporte['_' +year])"  style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," [name]="'terrenos_edificios_' + year" [(ngModel)]="AnalisisPM.anexo.activo_fijo.equipo_transporte['_' +year]" (input)="recalcularTotalesActivoFijo()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_fijo.equipo_transporte['porcentaje_' + year])"  style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" [name]="'equipo_transporte_porcentaje_' + year" [(ngModel)]="AnalisisPM.anexo.activo_fijo.equipo_transporte['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Dep. equipo transporte</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input class="red-text"  style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," [name]="'depreciacion_equipo_transp' + year" [(ngModel)]="AnalisisPM.anexo.activo_fijo.depreciacion_equipo_transp['_' +year]" (input)="recalcularTotalesActivoFijo()" />
            </td>
            <td>
              <input  style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" [name]="'depreciacion_equipo_transp' + year" [(ngModel)]="AnalisisPM.anexo.activo_fijo.depreciacion_equipo_transp['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Otras cuentas</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," [name]="'otras_cuentas_' + year" [(ngModel)]="AnalisisPM.anexo.activo_fijo.otras_cuentas['_' +year]" (input)="recalcularTotalesActivoFijo()" />
            </td>
            <td>
              <input style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" [name]="'otras_cuentas_porcentaje_' + year" [(ngModel)]="AnalisisPM.anexo.activo_fijo.otras_cuentas['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        
        <tr>
          <td ><b> Activo Fijo Total</b> </td>
          <td ><input style="width: 100px; font-weight: 700;"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="total_activo_fijo_2022" [(ngModel)]="AnalisisPM.anexo.activo_fijo.activo_fijo_total._2022"/></td>
          <td ><input style="width: 100px; font-weight: 700;"  mask="separator.0" [allowNegativeNumbers]="true"  thousandSeparator="," name="total_activo_fijo_2022" [(ngModel)]="AnalisisPM.anexo.activo_fijo.activo_fijo_total.porcentaje_2022" suffix="%"/></td>
          <td ><input style="width: 100px; font-weight: 700;"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="total_activo_fijo_2023" [(ngModel)]="AnalisisPM.anexo.activo_fijo.activo_fijo_total._2023"/></td>
          <td ><input style="width: 100px; font-weight: 700;"  mask="separator.0" [allowNegativeNumbers]="true"  thousandSeparator="," name="total_activo_fijo_2023" [(ngModel)]="AnalisisPM.anexo.activo_fijo.activo_fijo_total.porcentaje_2023" suffix="%"/></td>
          <td ><input style="width: 135px; font-weight: 700;"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="total_activo_fijo_parcial_2024" [(ngModel)]="AnalisisPM.anexo.activo_fijo.activo_fijo_total._2024"/></td>
          <td ><input style="width: 135px; font-weight: 700;"  mask="separator.0" [allowNegativeNumbers]="true"  thousandSeparator="," name="total_activo_fijo_analisis_vertical" [(ngModel)]="AnalisisPM.anexo.activo_fijo.activo_fijo_total.porcentaje_2022" suffix="%" /></td>
        </tr>
        <tr>
          <td  style="height: 20px;"></td>
          <td  style="height: 20px;"></td>
          <td  style="height: 20px;"></td>
          <td  style="height: 20px;"></td>
        </tr>
        <tr>
          <td>Otros activos</td>
          <td ><input style="width: 100px"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="otros_activos_circulante_2022" [(ngModel)]="AnalisisPM.anexo.otros_activos_22" (input)="calcularActivocirculanteFijoTotal()"/></td>
          <td ></td>
          <td ><input style="width: 100px"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="otros_activos_circulante_2023" [(ngModel)]="AnalisisPM.anexo.otros_activos_23" (input)="calcularActivocirculanteFijoTotal()"/></td>
          <td ></td>
          <td ><input style="width: 135px"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="otros_activos_circulante_parcial_2024" [(ngModel)]="AnalisisPM.anexo.otros_activos_24" (input)="calcularActivocirculanteFijoTotal()"/></td>
          <td ></td>
        </tr>
        <tr>
          <td  style="height: 20px;"></td>
          <td  style="height: 20px;"></td>
          <td  style="height: 20px;"></td>
          <td  style="height: 20px;"></td>
        </tr>
        <tr>
          <td><b>Activo Total</b></td>
          <td ><input style="width: 100px; font-weight: 700;"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="activo_total_circulante_2022"  [(ngModel)]="AnalisisPM.anexo.total_activos_circulante_fijo_22"/></td>
          <td ></td>
          <td ><input style="width: 100px; font-weight: 700;"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="activo_total_circulante_2023" [(ngModel)]="AnalisisPM.anexo.total_activos_circulante_fijo_23"/></td>
          <td ></td>
          <td ><input style="width: 135px; font-weight: 700;"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="activo_total_circulante_parcial_2024" [(ngModel)]="AnalisisPM.anexo.total_activos_circulante_fijo_24"/></td>
          <td ></td>
        </tr>
      </tbody>
    </table>
    </div>
    <div style="overflow: scroll;">
      <table class="tg">
        
        <thead>
          <tr>
            <th colspan="5">Analisis horizontal</th>
          </tr>
          <tr>
            <th  colspan="2">Var(Absoluta)</th>
            <th ></th>
            <th  colspan="2">Var (Porcentual)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td >22-23</td>
            <td >23-24</td>
            <td ></td>
            <td >22-23</td>
            <td >23-24</td>
          </tr>
          <ng-container *ngFor="let item of ['terrenos_edificios', 'depreciacion_terrenos', 'maquinaria_equipo', 'depreciacion_maquinaria_equipo', 'mobiliario_oficina_equipo_comp', 'depreciacion_mob_oficina_equipo', 'equipo_transporte', 'depreciacion_equipo_transp', 'otras_cuentas', 'activo_fijo_total']">
            <tr>
              <td><input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_fijo[item].var_absoluta_22_23)" style="width: 85px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="{{ item }}_var_absoluta_22_23" [(ngModel)]="AnalisisPM.anexo.activo_fijo[item].var_absoluta_22_23" /></td>
              <td><input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_fijo[item].var_absoluta_23_24)" style="width: 85px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="{{ item }}_var_absoluta_23_24" [(ngModel)]="AnalisisPM.anexo.activo_fijo[item].var_absoluta_23_24" /></td>
              <td></td>
              <td><input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_fijo[item].var_porcentual_22_23)" style="width: 85px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="{{ item }}_var_porcentual_22_23" [(ngModel)]="AnalisisPM.anexo.activo_fijo[item].var_porcentual_22_23" suffix="%" /></td>
              <td><input [ngClass]="getClassForValue(AnalisisPM.anexo.activo_fijo[item].var_porcentual_23_24)" style="width: 85px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="{{ item }}_var_porcentual_23_24" [(ngModel)]="AnalisisPM.anexo.activo_fijo[item].var_porcentual_23_24" suffix="%" /></td>
            </tr>
          </ng-container>
          <tr>
            <td  style="height: 20px;"></td>
            <td  style="height: 20px;"></td>
            <td  style="height: 20px;"></td>
            <td  style="height: 20px;"></td>
          </tr>
          <tr>
            <td ><input style="width: 85px"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="otros_activos_var_absoluta_22_23" [(ngModel)]="AnalisisPM.anexo.otras_cuentas_fijo_var_absoluta_22_23"/></td>
            <td ><input style="width: 85px"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="otros_activos_var_absoluta_23_24" [(ngModel)]="AnalisisPM.anexo.otras_cuentas_fijo_var_absoluta_23_24"/></td>
            <td ></td>
            <td ><input style="width: 85px"  mask="separator.0" [allowNegativeNumbers]="true"  thousandSeparator="," name="otros_activos_var_porcentual_22_23" [(ngModel)]="AnalisisPM.anexo.otras_cuentas_fijo_var_porcentual_22_23" suffix="%"/></td>
            <td ><input style="width: 85px"  mask="separator.0" [allowNegativeNumbers]="true"  thousandSeparator="," name="otros_activos_var_porcentual_23_24" [(ngModel)]="AnalisisPM.anexo.otras_cuentas_fijo_var_porcentual_23_24" suffix="%"/></td>
          </tr>
          <tr>
            <td  style="height: 20px;"></td>
            <td  style="height: 20px;"></td>
            <td  style="height: 20px;"></td>
            <td  style="height: 20px;"></td>
          </tr>
          <tr>
            <td ><input style="width: 85px; font-weight: 700;"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="activo_total_var_absoluta_22_23" [(ngModel)]="AnalisisPM.anexo.total_activos_circulante_fijo_absoluto_22_23"/></td>
            <td ><input style="width: 85px; font-weight: 700;"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="activo_total_var_absoluta_23_24" [(ngModel)]="AnalisisPM.anexo.total_activos_circulante_fijo_absoluto_23_24"/></td>
            <td ></td>
            <td ><input style="width: 85px; font-weight: 700;"  mask="separator.0" [allowNegativeNumbers]="true"  thousandSeparator="," name="activo_total_var_porcentual_22_23" [(ngModel)]="AnalisisPM.anexo.total_activos_circulante_fijo_porcentual_22_23" suffix="%"/></td>
            <td ><input style="width: 85px; font-weight: 700;"  mask="separator.0" [allowNegativeNumbers]="true"  thousandSeparator="," name="activo_total_var_porcentual_23_24" [(ngModel)]="AnalisisPM.anexo.total_activos_circulante_fijo_porcentual_23_24" suffix="%"/></td>
          </tr>

        </tbody>
        </table>
    </div>
  </div>
  <div style="height: 40px;"></div>
  <div style="display: grid; width: 100%; grid-template-columns: 70% 30%; gap: 2%;overflow: scroll;  ">
    <div style="overflow: scroll;">
    <table>
      <thead>

        <tr> 
          <th colspan="5" style="text-align: start;"><div style="height: 28px;"></div> </th>         
        </tr>
        <tr>
          <th colspan="5" style="text-align: start;">PASIVO </th>
        </tr>
        <tr>
          <th  >Pasivo Circulante</th>
          <th  colspan="2">2022</th>
          <th  colspan="2">2023</th>
          <th >
            {{obtenerNombreMes(AnalisisPM.anexo.mesSelected.id)}}
          </th>
          <th >Análisis Vertical</th>
        </tr>
      </thead>
      <tbody>
        
        <tr>
          <td>Proveedores</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.proveedores['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="proveedores_{{year}}" [(ngModel)]="AnalisisPM.anexo.pasivo.proveedores['_' +year]" (input)="recalcularTotales()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.proveedores['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" name="proveedores_porcentaje_{{year}}" readonly [(ngModel)]="AnalisisPM.anexo.pasivo.proveedores['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Acreedores</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.acreedores['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="acreedores_{{year}}" [(ngModel)]="AnalisisPM.anexo.pasivo.acreedores['_' +year]" (input)="recalcularTotales()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.acreedores['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" name="acreedores_porcentaje_{{year}}" readonly [(ngModel)]="AnalisisPM.anexo.pasivo.acreedores['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Impuestos por pagar</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.impuestos_pagar['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="impuestos_pagar_{{year}}" [(ngModel)]="AnalisisPM.anexo.pasivo.impuestos_pagar['_' +year]" (input)="recalcularTotales()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.impuestos_pagar['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" name="impuestos_pagar_porcentaje_{{year}}" readonly [(ngModel)]="AnalisisPM.anexo.pasivo.impuestos_pagar['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Documentos x pagar</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.documentos_pagar['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="documentos_pagar_{{year}}" [(ngModel)]="AnalisisPM.anexo.pasivo.documentos_pagar['_' +year]" (input)="recalcularTotales()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.documentos_pagar['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" name="documentos_pagar_porcentaje_{{year}}" readonly [(ngModel)]="AnalisisPM.anexo.pasivo.documentos_pagar['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Iva x trasladar</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.iva_trasladar['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="iva_trasladar_{{year}}" [(ngModel)]="AnalisisPM.anexo.pasivo.iva_trasladar['_' +year]" (input)="recalcularTotales()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.iva_trasladar['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" name="iva_trasladar_porcentaje_{{year}}" readonly [(ngModel)]="AnalisisPM.anexo.pasivo.iva_trasladar['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Provisiones</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.provisiones['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="provisiones_{{year}}" [(ngModel)]="AnalisisPM.anexo.pasivo.provisiones['_' +year]" (input)="recalcularTotales()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.provisiones['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" name="provisiones_porcentaje_{{year}}" readonly [(ngModel)]="AnalisisPM.anexo.pasivo.provisiones['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Intereses</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.intereses['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="intereses_{{year}}" [(ngModel)]="AnalisisPM.anexo.pasivo.intereses['_' +year]" (input)="recalcularTotales()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.intereses['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" name="intereses_porcentaje_{{year}}" readonly [(ngModel)]="AnalisisPM.anexo.pasivo.intereses['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Otras cuentas</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.otras_cuentas['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="otras_cuentas_{{year}}" [(ngModel)]="AnalisisPM.anexo.pasivo.otras_cuentas['_' +year]" (input)="recalcularTotales()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.otras_cuentas['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" name="otras_cuentas_porcentaje_{{year}}" readonly [(ngModel)]="AnalisisPM.anexo.pasivo.otras_cuentas['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Total Pasivo Circulante</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.total_pasivo_circulante['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="total_pasivo_circulante_{{year}}" [(ngModel)]="AnalisisPM.anexo.pasivo.total_pasivo_circulante['_' +year]" (input)="recalcularTotales()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.total_pasivo_circulante['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" name="total_pasivo_circulante_porcentaje_{{year}}" readonly [(ngModel)]="AnalisisPM.anexo.pasivo.total_pasivo_circulante['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        
        <tr>
          <td  style="height: 20px;"></td>
          <td  style="height: 20px;"></td>
          <td  style="height: 20px;"></td>
          <td  style="height: 20px;"></td>
        </tr>
        <tr>
          <td>Total otros pasivos largo plazo</td>
          <td ><input style="width: 100px; font-weight: 700;" [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.otros_pasivos_lg._2022)" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="otros_pasivos_largo_plazo_circulante_2022" [(ngModel)]="AnalisisPM.anexo.pasivo.otros_pasivos_lg._2022" (input)="recalcularTotalesPasivo()"/></td>
          <td ></td>
          <td ><input style="width: 100px; font-weight: 700;" [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.otros_pasivos_lg._2023)" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="otros_pasivos_largo_plazo_circulante_2023" [(ngModel)]="AnalisisPM.anexo.pasivo.otros_pasivos_lg._2023" (input)="recalcularTotalesPasivo()"/></td>
          <td ></td>
          <td ><input style="width: 135px; font-weight: 700;" [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.otros_pasivos_lg._2024)" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="otros_pasivos_largo_plazo_circulante_parcial_2024" [(ngModel)]="AnalisisPM.anexo.pasivo.otros_pasivos_lg._2024" (input)="recalcularTotalesPasivo()"/></td>
          <td ></td>
        </tr>
        <tr>
          <td  style="height: 20px;"></td>
          <td  style="height: 20px;"></td>
          <td  style="height: 20px;"></td>
          <td  style="height: 20px;"></td>
        </tr>
        <tr>
          <td><b>Total pasivos</b></td>
          <td ><input style="width: 100px; font-weight: 700;" [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.total_pasivos_cir_lg._2022)"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="total_pasivos_2022" [(ngModel)]="AnalisisPM.anexo.pasivo.total_pasivos_cir_lg._2022" /></td>
          <td ></td>
          <td ><input style="width: 100px; font-weight: 700;" [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.total_pasivos_cir_lg._2023)"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="total_pasivos_2023" [(ngModel)]="AnalisisPM.anexo.pasivo.total_pasivos_cir_lg._2023"/></td>
          <td ></td>
          <td ><input style="width: 135px; font-weight: 700;" [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.total_pasivos_cir_lg._2024)"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="total_pasivos_2024" [(ngModel)]="AnalisisPM.anexo.pasivo.total_pasivos_cir_lg._2024"/></td>
          <td ></td>
        </tr>
        
      </tbody>
    </table>
    </div>
    <div style="overflow: scroll;">
      <table class="tg">
        
        <thead>
          <tr>
            <th colspan="5">Analisis horizontal</th>
          </tr>
          <tr>
            <th  colspan="2">Var(Absoluta)</th>
            <th ></th>
            <th  colspan="2">Var (Porcentual)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td >22-23</td>
            <td >23-24</td>
            <td ></td>
            <td >22-23</td>
            <td >23-24</td>
          </tr>
          <ng-container *ngFor="let item of ['proveedores', 'acreedores', 'impuestos_pagar', 'documentos_pagar', 'iva_trasladar', 'provisiones', 'intereses', 'otras_cuentas', 'total_pasivo_circulante']">
            <tr>
              <td><input [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo[item].var_absoluta_22_23)" style="width: 85px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="{{ item }}_var_absoluta_22_23" [(ngModel)]="AnalisisPM.anexo.pasivo[item].var_absoluta_22_23" /></td>
              <td><input [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo[item].var_absoluta_23_24)" style="width: 85px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="{{ item }}_var_absoluta_23_24" [(ngModel)]="AnalisisPM.anexo.pasivo[item].var_absoluta_23_24" /></td>
              <td></td>
              <td><input [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo[item].var_porcentual_22_23)" style="width: 85px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="{{ item }}_var_porcentual_22_23" [(ngModel)]="AnalisisPM.anexo.pasivo[item].var_porcentual_22_23" suffix="%" /></td>
              <td><input [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo[item].var_porcentual_23_24)" style="width: 85px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="{{ item }}_var_porcentual_23_24" [(ngModel)]="AnalisisPM.anexo.pasivo[item].var_porcentual_23_24" suffix="%" /></td>
            </tr>
          </ng-container>
         
          <tr>
            <td  style="height: 20px;"></td>
            <td  style="height: 20px;"></td>
            <td  style="height: 20px;"></td>
            <td  style="height: 20px;"></td>
          </tr>
          <tr>
            <td ><input style="width: 85px; font-weight: 700;" [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.otros_pasivos_lg.var_absoluta_22_23)" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="total_pasivo_largo_plazo_absoluta_21_22" [(ngModel)]="AnalisisPM.anexo.pasivo.otros_pasivos_lg.var_absoluta_22_23" /></td>
            <td ><input style="width: 85px; font-weight: 700;" [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.otros_pasivos_lg.var_absoluta_23_24)" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="total_pasivo_largo_plazo_absoluta_22_23" [(ngModel)]="AnalisisPM.anexo.pasivo.otros_pasivos_lg.var_absoluta_23_24"/></td>
            <td ></td>
            <td ><input style="width: 85px; font-weight: 700;" [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.otros_pasivos_lg.var_porcentual_22_23)" mask="separator.0" [allowNegativeNumbers]="true"  thousandSeparator="," name="total_pasivo_largo_plazo_porcentual_21_22" [(ngModel)]="AnalisisPM.anexo.pasivo.otros_pasivos_lg.var_porcentual_22_23" suffix="%"/></td>
            <td ><input style="width: 85px; font-weight: 700;" [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.otros_pasivos_lg.var_porcentual_23_24)" mask="separator.0" [allowNegativeNumbers]="true"  thousandSeparator="," name="total_pasivo_largo_plazo_porcentual_22_23" [(ngModel)]="AnalisisPM.anexo.pasivo.otros_pasivos_lg.var_porcentual_23_24" suffix="%"/></td>
          </tr>
          <tr>
            <td  style="height: 20px;"></td>
            <td  style="height: 20px;"></td>
            <td  style="height: 20px;"></td>
            <td  style="height: 20px;"></td>
          </tr>
          <tr>
            <td ><input style="width: 85px; font-weight: 700;" [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.total_pasivos_cir_lg.var_absoluta_22_23)" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="total_pasivo_absoluta_21_22" [(ngModel)]="AnalisisPM.anexo.pasivo.total_pasivos_cir_lg.var_absoluta_22_23"/></td>
            <td ><input style="width: 85px; font-weight: 700;" [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.total_pasivos_cir_lg.var_absoluta_23_24)" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="total_pasivo_absoluta_22_23" [(ngModel)]="AnalisisPM.anexo.pasivo.total_pasivos_cir_lg.var_absoluta_23_24"/></td>
            <td ></td>
            <td ><input style="width: 85px; font-weight: 700;" [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.total_pasivos_cir_lg.var_porcentual_22_23)" mask="separator.0" [allowNegativeNumbers]="true"  thousandSeparator="," name="total_pasivo_porcentual_21_22" [(ngModel)]="AnalisisPM.anexo.pasivo.total_pasivos_cir_lg.var_porcentual_22_23" suffix="%"/></td>
            <td ><input style="width: 85px; font-weight: 700;" [ngClass]="getClassForValue(AnalisisPM.anexo.pasivo.total_pasivos_cir_lg.var_porcentual_23_24)" mask="separator.0" [allowNegativeNumbers]="true"  thousandSeparator="," name="total_pasivo_porcentual_22_23" [(ngModel)]="AnalisisPM.anexo.pasivo.total_pasivos_cir_lg.var_porcentual_23_24" suffix="%"/></td>
          </tr>
          

        </tbody>
        </table>
    </div>
  </div>
  <div style="height: 40px;"></div>
  <div style="display: grid; width: 100%; grid-template-columns: 70% 30%; gap: 2%;overflow: scroll;  ">
    <div style="overflow: scroll;">
    <table>
      <thead>

        <tr> 
          <th colspan="5" style="text-align: start;"><div style="height: 28px;"></div> </th>         
        </tr>
        <tr>
          <th colspan="5" style="text-align: start;">CAPITAL </th>
        </tr>
        <tr>
          <th  >Capital</th>
          <th  colspan="2">2022</th>
          <th  colspan="2">2023</th>
          <th >
            {{obtenerNombreMes(AnalisisPM.anexo.mesSelected.id)}}
          </th>
          <th >Análisis Vertical</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Capital social</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.capital.capital_social['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," (input)="recalcularTotales()" [(ngModel)]="AnalisisPM.anexo.capital.capital_social['_' + year]" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.capital.capital_social['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" readonly [ngModel]="AnalisisPM.anexo.capital.capital_social['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Ap. a fut. aumentos a cap</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.capital.apor_futuros_aum_cap['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," (input)="recalcularTotales()" [(ngModel)]="AnalisisPM.anexo.capital.apor_futuros_aum_cap['_' + year]" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.capital.apor_futuros_aum_cap['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" readonly [ngModel]="AnalisisPM.anexo.capital.apor_futuros_aum_cap['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Reserva Legal</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.capital.reserva_legal['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," (input)="recalcularTotales()" [(ngModel)]="AnalisisPM.anexo.capital.reserva_legal['_' + year]" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.capital.reserva_legal['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" readonly [ngModel]="AnalisisPM.anexo.capital.reserva_legal['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Resultados acumulados</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.capital.resultados_acumulados['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," (input)="recalcularTotales()" [(ngModel)]="AnalisisPM.anexo.capital.resultados_acumulados['_' + year]" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.capital.resultados_acumulados['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" readonly [ngModel]="AnalisisPM.anexo.capital.resultados_acumulados['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Resultado del ejercicio</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.capital.resultado_del_ejercicio['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," (input)="recalcularTotales()" [(ngModel)]="AnalisisPM.anexo.capital.resultado_del_ejercicio['_' + year]" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.capital.resultado_del_ejercicio['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" readonly [ngModel]="AnalisisPM.anexo.capital.resultado_del_ejercicio['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Otras cuentas</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.capital.otras_cuentas['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," (input)="recalcularTotales()" [(ngModel)]="AnalisisPM.anexo.capital.otras_cuentas['_' + year]" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.capital.otras_cuentas['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" readonly [ngModel]="AnalisisPM.anexo.capital.otras_cuentas['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Capital Contable</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.capital.total_capital['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," (input)="recalcularTotales()" [(ngModel)]="AnalisisPM.anexo.capital.total_capital['_' + year]" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.capital.total_capital['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" readonly [ngModel]="AnalisisPM.anexo.capital.total_capital['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td ><b>Pasivo + Capital</b></td>
          <td ><input style="width: 100px; font-weight: 700;" [ngClass]="getClassForValue(AnalisisPM.anexo.total_pasivo_capital._2022)"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="pasivo_mas_capital_2022" [(ngModel)]="AnalisisPM.anexo.total_pasivo_capital._2022"/></td>
          <td ><input style="width: 100px; font-weight: 700;" [ngClass]="getClassForValue(AnalisisPM.anexo.total_pasivo_capital.porcentaje_2022)"  mask="separator.0" [allowNegativeNumbers]="true"  thousandSeparator="," name="pasivo_mas_capital_2022"  [(ngModel)]="AnalisisPM.anexo.total_pasivo_capital.porcentaje_2022" suffix="%"/></td>
          <td ><input style="width: 100px; font-weight: 700;" [ngClass]="getClassForValue(AnalisisPM.anexo.total_pasivo_capital._2023)"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="pasivo_mas_capital_2023"  [(ngModel)]="AnalisisPM.anexo.total_pasivo_capital._2023"/></td>
          <td ><input style="width: 100px; font-weight: 700;" [ngClass]="getClassForValue(AnalisisPM.anexo.total_pasivo_capital.porcentaje_2023)"  mask="separator.0" [allowNegativeNumbers]="true"  thousandSeparator="," name="pasivo_mas_capital_2023"  [(ngModel)]="AnalisisPM.anexo.total_pasivo_capital.porcentaje_2023" suffix="%"/></td>
          <td ><input style="width: 135px; font-weight: 700;" [ngClass]="getClassForValue(AnalisisPM.anexo.total_pasivo_capital._2024)"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="pasivo_mas_capital_parcial_2024"  [(ngModel)]="AnalisisPM.anexo.total_pasivo_capital._2024"/></td>
          <td ><input style="width: 135px; font-weight: 700;" [ngClass]="getClassForValue(AnalisisPM.anexo.total_pasivo_capital.porcentaje_2024)"  mask="separator.0" [allowNegativeNumbers]="true"  thousandSeparator="," name="pasivo_mas_capital_analisis_vertical"  [(ngModel)]="AnalisisPM.anexo.total_pasivo_capital.porcentaje_2024" suffix="%"/></td>
        </tr>
      </tbody>
    </table>
    </div>
    <div style="overflow: scroll;">
      <table class="tg">
        
        <thead>
          <tr>
            <th colspan="5">Analisis horizontal</th>
          </tr>
          <tr>
            <th  colspan="2">Var(Absoluta)</th>
            <th ></th>
            <th  colspan="2">Var (Porcentual)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td >22-23</td>
            <td >23-24</td>
            <td ></td>
            <td >22-23</td>
            <td >23-24</td>
          </tr>
          <ng-container *ngFor="let item of ['capital_social', 'apor_futuros_aum_cap', 'reserva_legal', 'resultados_acumulados', 'resultado_del_ejercicio', 'otras_cuentas', 'total_capital']">
            <tr>
              <td><input [ngClass]="getClassForValue(AnalisisPM.anexo.capital[item].var_absoluta_22_23)" style="width: 85px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="{{ item }}_var_absoluta_22_23" [(ngModel)]="AnalisisPM.anexo.capital[item].var_absoluta_22_23" /></td>
              <td><input [ngClass]="getClassForValue(AnalisisPM.anexo.capital[item].var_absoluta_23_24)" style="width: 85px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="{{ item }}_var_absoluta_23_24" [(ngModel)]="AnalisisPM.anexo.capital[item].var_absoluta_23_24" /></td>
              <td></td>
              <td><input [ngClass]="getClassForValue(AnalisisPM.anexo.capital[item].var_porcentual_22_23)" style="width: 85px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="{{ item }}_var_porcentual_22_23" [(ngModel)]="AnalisisPM.anexo.capital[item].var_porcentual_22_23" suffix="%" /></td>
              <td><input [ngClass]="getClassForValue(AnalisisPM.anexo.capital[item].var_porcentual_23_24)" style="width: 85px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="{{ item }}_var_porcentual_23_24" [(ngModel)]="AnalisisPM.anexo.capital[item].var_porcentual_23_24" suffix="%" /></td>
            </tr>
          </ng-container>
          
          <tr>
            <td ><input style="width: 85px; font-weight: 700;" [ngClass]="getClassForValue(AnalisisPM.anexo.total_pasivo_capital.var_absoluta_22_23)"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="pasivo_mas_capital_fijo_var_absoluta_22_23" [(ngModel)]=" AnalisisPM.anexo.total_pasivo_capital.var_absoluta_22_23" /></td>
            <td ><input style="width: 85px; font-weight: 700;" [ngClass]="getClassForValue(AnalisisPM.anexo.total_pasivo_capital.var_absoluta_23_24)"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="pasivo_mas_capital_fijo_var_absoluta_23_24" [(ngModel)]=" AnalisisPM.anexo.total_pasivo_capital.var_absoluta_23_24"/></td>
            <td ></td>
            <td ><input style="width: 85px; font-weight: 700;" [ngClass]="getClassForValue(AnalisisPM.anexo.total_pasivo_capital.var_porcentual_22_23)"  mask="separator.0" [allowNegativeNumbers]="true"  thousandSeparator="," name="pasivo_mas_capital_fijo_var_porcentual_22_23" [(ngModel)]=" AnalisisPM.anexo.total_pasivo_capital.var_porcentual_22_23" suffix="%"/></td>
            <td ><input style="width: 85px; font-weight: 700;" [ngClass]="getClassForValue(AnalisisPM.anexo.total_pasivo_capital.var_porcentual_23_24)"  mask="separator.0" [allowNegativeNumbers]="true"  thousandSeparator="," name="pasivo_mas_capital_fijo_var_porcentual_23_24" [(ngModel)]=" AnalisisPM.anexo.total_pasivo_capital.var_porcentual_23_24" suffix="%"/></td>
          </tr>         
        </tbody>
        </table>
    </div>
  </div>
  <div style="height: 40px;"></div>
  <div style="display: grid; width: 100%; grid-template-columns: 70% 30%; gap: 2%;overflow: scroll;  ">
    <div style="overflow: scroll;">
    <table>
      <thead>
        
        <tr>
          <th colspan="5" style="text-align: start;">ESTADO DE RESULTADOS </th>
        </tr>
        <tr> 
          <th colspan="5" style="text-align: start;"><div style="height: 28px;"></div> </th>         
        </tr>
        <tr>
          <th  >Concepto</th>
          <th  colspan="2">2022</th>
          <th  colspan="2">2023</th>
          <th>
            <mat-select [(ngModel)]="AnalisisPM.anexo.mesSelected" (ngModelChange)="calcularGastoFinacieroTotal()">
              <mat-option *ngFor="let mes of MESES" [value]="{ id: mes.id, value: mes.value }">
                {{ mes.mes }}
              </mat-option>
            </mat-select>
          </th>
          
          <th >Análisis Vertical</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td  >Ventas</td>
          <td ><input style="width: 100px" [ngClass]="getClassForValue(AnalisisPM.anexo.estado_resultados.ventas._2022)"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="ventas_2022" [(ngModel)]="AnalisisPM.anexo.estado_resultados.ventas._2022" (input)="reCalcularUtilidades()"/></td>
          <td ></td>
          <td ><input style="width: 100px" [ngClass]="getClassForValue(AnalisisPM.anexo.estado_resultados.ventas._2023)"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="ventas_2023" [(ngModel)]="AnalisisPM.anexo.estado_resultados.ventas._2023" (input)="reCalcularUtilidades()"/></td>
          <td ></td>
          <td ><input style="width: 135px" [ngClass]="getClassForValue(AnalisisPM.anexo.estado_resultados.ventas._2024)"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="ventas_parcial_2024" [(ngModel)]="AnalisisPM.anexo.estado_resultados.ventas._2024" (input)="reCalcularUtilidades()"/></td>
          <td ></td>
        </tr>
        <tr>
          <td>Costo Ventas</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.estado_resultados.costo_ventas['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="costo_ventas_{{year}}" [(ngModel)]="AnalisisPM.anexo.estado_resultados.costo_ventas['_' +year]" (input)="recalcularTotales()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.estado_resultados.costo_ventas['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" name="costo_ventas_porcentaje_{{year}}" readonly [(ngModel)]="AnalisisPM.anexo.estado_resultados.costo_ventas['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Utilidad bruta</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.estado_resultados.utilidad_bruta['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="utilidad_bruta_{{year}}" [(ngModel)]="AnalisisPM.anexo.estado_resultados.utilidad_bruta['_' +year]" (input)="recalcularTotales()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.estado_resultados.utilidad_bruta['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" name="utilidad_bruta_porcentaje_{{year}}" readonly [(ngModel)]="AnalisisPM.anexo.estado_resultados.utilidad_bruta['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Gastos de operación</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.estado_resultados.gastos_operacion['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="gastos_operacion_{{year}}" [(ngModel)]="AnalisisPM.anexo.estado_resultados.gastos_operacion['_' +year]" (input)="recalcularTotales()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.estado_resultados.gastos_operacion['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" name="gastos_operacion_porcentaje_{{year}}" readonly [(ngModel)]="AnalisisPM.anexo.estado_resultados.gastos_operacion['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Utilidad operativa</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.estado_resultados.utilidad_operativa['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="utilidad_operativa_{{year}}" [(ngModel)]="AnalisisPM.anexo.estado_resultados.utilidad_operativa['_' +year]" (input)="recalcularTotales()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.estado_resultados.utilidad_operativa['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" name="utilidad_operativa_porcentaje_{{year}}" readonly [(ngModel)]="AnalisisPM.anexo.estado_resultados.utilidad_operativa['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Gastos financieros</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.estado_resultados.gastos_financieros['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="gastos_financieros_{{year}}" [(ngModel)]="AnalisisPM.anexo.estado_resultados.gastos_financieros['_' +year]" (input)="recalcularTotales()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.estado_resultados.gastos_financieros['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" name="gastos_financieros_porcentaje_{{year}}" readonly [(ngModel)]="AnalisisPM.anexo.estado_resultados.gastos_financieros['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Productos financieros</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.estado_resultados.productos_financieros['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="productos_financieros_{{year}}" [(ngModel)]="AnalisisPM.anexo.estado_resultados.productos_financieros['_' +year]" (input)="recalcularTotales()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.estado_resultados.productos_financieros['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" name="productos_financieros_porcentaje_{{year}}" readonly [(ngModel)]="AnalisisPM.anexo.estado_resultados.productos_financieros['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Utilidad antes de impuestos</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="utilidad_antes_impuestos_{{year}}" [(ngModel)]="AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos['_' +year]" (input)="recalcularTotales()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" name="utilidad_antes_impuestos_porcentaje_{{year}}" readonly [(ngModel)]="AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Impuestos</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.estado_resultados.impuestos['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="impuestos_{{year}}" [(ngModel)]="AnalisisPM.anexo.estado_resultados.impuestos['_' +year]" (input)="recalcularTotales()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.estado_resultados.impuestos['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" name="impuestos_porcentaje_{{year}}" readonly [(ngModel)]="AnalisisPM.anexo.estado_resultados.impuestos['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
        <tr>
          <td>Utilidad neta</td>
          <ng-container *ngFor="let year of [2022, 2023, 2024]">
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.estado_resultados.utilidad_neta['_' +year])" style="width: 100px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="utilidad_neta_{{year}}" [(ngModel)]="AnalisisPM.anexo.estado_resultados.utilidad_neta['_' +year]" (input)="recalcularTotales()" />
            </td>
            <td>
              <input [ngClass]="getClassForValue(AnalisisPM.anexo.estado_resultados.utilidad_neta['porcentaje_' + year])" style="width: 100px" mask="separator.0" [allowNegativeNumbers]="true" name="utilidad_neta_porcentaje_{{year}}" readonly [(ngModel)]="AnalisisPM.anexo.estado_resultados.utilidad_neta['porcentaje_' + year]" suffix="%" />
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>
    </div>
    <div style="overflow: scroll;">
      <table class="tg">
        
        <thead>
          <tr>
            <th colspan="5">Analisis horizontal</th>
          </tr>
          <tr>
            <th  colspan="2">Var(Absoluta)</th>
            <th ></th>
            <th  colspan="2">Var (Porcentual)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td >22-23</td>
            <td >23-24</td>
            <td ></td>
            <td >22-23</td>
            <td >23-24</td>
          </tr>
          <tr>
            <td ><input style="width: 85px" [ngClass]="getClassForValue(AnalisisPM.anexo.estado_resultados.ventas.var_absoluta_22_23)"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="ventas_var_absoluta_22_23" [(ngModel)]="AnalisisPM.anexo.estado_resultados.ventas.var_absoluta_22_23"/></td>
            <td ><input style="width: 85px" [ngClass]="getClassForValue(AnalisisPM.anexo.estado_resultados.ventas.var_absoluta_23_24)"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="ventas_var_absoluta_23_24" [(ngModel)]="AnalisisPM.anexo.estado_resultados.ventas.var_absoluta_23_24"/></td>
            <td ></td>
            <td ><input style="width: 85px" [ngClass]="getClassForValue(AnalisisPM.anexo.estado_resultados.ventas.var_porcentual_22_23)"  mask="separator.0" [allowNegativeNumbers]="true"  thousandSeparator="," name="ventas_var_porcentual_22_23" [(ngModel)]="AnalisisPM.anexo.estado_resultados.ventas.var_porcentual_22_23" suffix="%"/></td>
            <td ><input style="width: 85px" [ngClass]="getClassForValue(AnalisisPM.anexo.estado_resultados.ventas.var_porcentual_23_24)"  mask="separator.0" [allowNegativeNumbers]="true"  thousandSeparator="," name="ventas_var_porcentual_23_24" [(ngModel)]="AnalisisPM.anexo.estado_resultados.ventas.var_porcentual_23_24" suffix="%"/></td>
          </tr>
          <ng-container *ngFor="let item of ['costo_ventas', 'utilidad_bruta', 'gastos_operacion', 'utilidad_operativa', 'gastos_financieros', 'productos_financieros', 'utilidad_antes_impuestos', 'impuestos', 'utilidad_neta']">
            <tr>
              <td><input [ngClass]="getClassForValue(AnalisisPM.anexo.estado_resultados[item].var_absoluta_22_23)" style="width: 85px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="{{ item }}_var_absoluta_22_23" [(ngModel)]="AnalisisPM.anexo.estado_resultados[item].var_absoluta_22_23" /></td>
              <td><input [ngClass]="getClassForValue(AnalisisPM.anexo.estado_resultados[item].var_absoluta_23_24)" style="width: 85px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="{{ item }}_var_absoluta_23_24" [(ngModel)]="AnalisisPM.anexo.estado_resultados[item].var_absoluta_23_24" /></td>
              <td></td>
              <td><input [ngClass]="getClassForValue(AnalisisPM.anexo.estado_resultados[item].var_porcentual_22_23)" style="width: 85px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="{{ item }}_var_porcentual_22_23" [(ngModel)]="AnalisisPM.anexo.estado_resultados[item].var_porcentual_22_23" suffix="%" /></td>
              <td><input [ngClass]="getClassForValue(AnalisisPM.anexo.estado_resultados[item].var_porcentual_23_24)" style="width: 85px" mask="separator.2" [allowNegativeNumbers]="true" thousandSeparator="," name="{{ item }}_var_porcentual_23_24" [(ngModel)]="AnalisisPM.anexo.estado_resultados[item].var_porcentual_23_24" suffix="%" /></td>
            </tr>
          </ng-container>
           
        </tbody>
        </table>
    </div>
  </div>
  <div style="height: 80px;"></div>
    <div style="display: flex; justify-content: center; text-align: center;">
      <table>
        <th></th>
        <tr>
          <td><b>GASTOS FINANCIEROS</b></td>
          <td><input style="width: 125px; font-weight: 700"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," name="total_gastos_financieros" [(ngModel)]="AnalisisPM.anexo.gastos_financieros" /></td>
        </tr>
      </table>
    </div>
    <div style="height: 80px;"></div>
    <div class="container-btn">
      <button class="btn-secondary-new" (click)="guardarYRetroceder()">Atras</button>
      <button class="btn-primary-new" (click)="guardarYAvanzar()">
        <mat-spinner *ngIf="loading" diameter="20" ></mat-spinner> <span *ngIf="!loading">Guardar</span>
      </button>
    </div>
</div>
