import { ChangeDetectorRef, Component, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import {
  AuthenticationService,
  BuroService,
  SolicitudService,
  UserService,
} from "src/app/_services";
import { Analisis, tiposContrato } from "./analisis/Analisis";
import { MatStepper } from "@angular/material/stepper";
import {
  EntrevistaActEconomica,
  EntrevistaAval,
  EntrevistaConsultorio,
  EntrevistaDatosGenerales,
  EntrevistaReferencias,
} from "./entrevista/Entrevista";
import { customPatterns } from "src/app/_models/maskPattern";
import { FormGroup } from "@angular/forms";
import { AnalisisPF } from "./analisis/AnalisisPF";

@Component({
  selector: "app-valuacion",
  templateUrl: "./valuacion.component.html",
  styleUrls: ["./valuacion.component.scss"],
})
export class ValuacionComponent implements OnInit {
  @ViewChild("stepper") private stepper: MatStepper;
  folio = null;
  isLinear = false;
  analisisPf: any;
  AnalisisPF: any = {};
  frmActEconomica: FormGroup;
  frmConsultorio: FormGroup;
  frmAval: FormGroup;
  Referencias = EntrevistaReferencias;
  online = false;
  customPattern = customPatterns;
  id_cliente = null;
  DatosGeneralesTres: any = {};
  DatosGeneralesDos: any = {};
  saldo_inicial = 0

  solicitud: any = {}

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private solicitudService: SolicitudService,
    private userService: UserService,
    private buroService: BuroService,
    private cdr: ChangeDetectorRef
  ) {}
  ngAfterViewInit() {
    this.stepper.selectionChange.subscribe(async(step) => {
      console.log('Cambió de stepper:', step.selectedIndex);
      await this.onStepperChange(); // Llama a tu función aquí
      this.actualizarAnalisisPf(this.analisisPf)
    });
  }


  async onStepperChange() {
    console.log("stepper",this.AnalisisPF)
    this.AnalisisPF.capacidad_pago.comprobantes_gastos = Number(this.AnalisisPF.entrevista_general.mmr) + Number(this.AnalisisPF.entrevista_general.emd) + Number(this.AnalisisPF.entrevista_general.gastos_buro)
      
    this.AnalisisPF.capacidad_pago.efectivo_gastos = Number(this.AnalisisPF.capacidad_pago.comprobantes_gastos) + Number(this.AnalisisPF.entrevista_consultorio.monto_renta)
    this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_gastos = Number(this.AnalisisPF.capacidad_pago.efectivo_gastos) + parseFloat(this.AnalisisPF.cotizacion.mensualidad.toFixed(2).replace(/[$,]/g, ''))
    this.AnalisisPF.capacidad_pago.comprobantes_factor = ((Number(this.AnalisisPF.capacidad_pago.comprobantes_gastos) / Number(this.AnalisisPF.capacidad_pago.ingresos_nomina)) * 100).toFixed(1)
    this.AnalisisPF.capacidad_pago.efectivo_factor =Number((( Number(this.AnalisisPF.capacidad_pago.efectivo_gastos) / Number(this.AnalisisPF.capacidad_pago.ingresos_efectivo)) * 100).toFixed(1))
    console.log("NUEVOS DATOS", this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_factor)
    console.log("NUEVOS DATOS", this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_gastos)
    console.log("NUEVOS DATOS", this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_ingresos)
    this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_factor =(( Number(this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_gastos) / Number(this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_ingresos)) * 100).toFixed(1)
    console.log("NUEVOS DATOS", this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_factor)
    this.actualizarPromediosAnalisis();
    this.AnalisisPF.capacidad_pago.factor_renta = Number((((parseFloat(this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_ingresos) - parseFloat(this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_gastos)) / parseFloat(this.AnalisisPF.cotizacion.mensualidad.toFixed(2).replace(/[$,]/g, '')))).toFixed(2))
        // const PromedioFactor = (this.AnalisisPF.comprobantes_factor + this.AnalisisPF.efectivo_factor + this.AnalisisPF.extra_nuevo_equipo_factor) / this.AnalisisPF.historial_movimientos.length
        const PromedioFactor = Number(this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_factor);
        this.AnalisisPF.capacidad_pago.puntuacion_capacidad_pago = (PromedioFactor > 81) ? 0 
        : (PromedioFactor > 75) ? 25
        : (PromedioFactor > 70) ? 28
        : (PromedioFactor >= 66) ? 30 : 33
        this.AnalisisPF.capacidad_pago.puntuacion_capacidad_pago = (this.AnalisisPF.capacidad_pago.factor_renta > 2)
          ? this.AnalisisPF.capacidad_pago.puntuacion_capacidad_pago += 3
          : this.AnalisisPF.capacidad_pago.puntuacion_capacidad_pago;
    
          this.AnalisisPF.capacidad_pago.puntuacion_capacidad_pago = this.AnalisisPF.capacidad_pago.puntuacion_capacidad_pago > 33 ? 33 : this.AnalisisPF.capacidad_pago.puntuacion_capacidad_pago;
    this.AnalisisPF.perfil_solicitante.consejo_especialidad = this.AnalisisPF.entrevista_general.consejo_especialidad  ? 'SI' : 'NO';
    const arraigo_domicilio = this.AnalisisPF?.entrevista_general?.domicilio_antiguedad ? 
    (this.AnalisisPF.entrevista_general.domicilio_antiguedad.match(/\d+/)?.[0] ?? 0) : 0;
    const arraigo_empleo = this.AnalisisPF?.entrevista_consultorio?.antiguedad ? 
    (this.AnalisisPF.entrevista_consultorio.antiguedad.match(/\d+/)?.[0] ?? 0) : 0;
    this.AnalisisPF.perfil_solicitante.arraigo_domicilio = this.AnalisisPF?.entrevista_general?.domicilio_antiguedad 
    this.cambiarPuntuacionAnalisis()
    this.AnalisisPF.capacidad_pago.ingresos_efectivo = Number(this.AnalisisPF.entrevista_consultorio.ingresos_efectivo)
    this.AnalisisPF.capacidad_pago.ingresos_extra = Number(this.AnalisisPF.entrevista_consultorio.ingresos_extras_equipo)
    this.AnalisisPF.perfil_solicitante.arraigo_empleo = Number(arraigo_empleo)
    this.PuntuacionCaracter()
    this.AnalisisPF.puntuacion_total = this.AnalisisPF.caracter.puntuacion_comportamiento_pago + this.AnalisisPF.puntuacion_caracter + this.AnalisisPF.capacidad_pago.puntuacion_capacidad_pago + this.AnalisisPF.colateral.puntuacion_colateral
    
    for (let i = 0; i < this.AnalisisPF.historial_credito.activas.length; i++) {
      const comportamiento = this.AnalisisPF.historial_credito.activas[i].id_comportamiento_pago
      this.AnalisisPF.historial_credito.activas[i].comp_pago = comportamiento == 1 ? 'Bueno'
      : comportamiento == 2 ? 'Regular' : 'Malo'
    }
    
    for (let i = 0; i < this.AnalisisPF.historial_credito.cerradas.length; i++) {
      const comportamiento = this.AnalisisPF.historial_credito.cerradas[i].id_comportamiento_pago
      this.AnalisisPF.historial_credito.cerradas[i].comp_pago = comportamiento == 1 ? 'Bueno'
      : comportamiento == 2 ? 'Regular' : 'Malo'
    }
  }

  async ngOnInit() {
    
    this.AnalisisPF = JSON.parse(JSON.stringify(AnalisisPF));
    this.analisisPf = this.AnalisisPF;
    const { folio } = JSON.parse(localStorage.getItem("client_details"));
    this.folio = folio;
    this.AnalisisPF.folio = this.folio;
    // this.frmDatosGenerales = EntrevistaDatosGenerales()
    // this.frmActEconomica = EntrevistaActEconomica()
    // this.frmConsultorio = EntrevistaConsultorio()
    // this.frmAval = EntrevistaAval()

    await this.getAnalisisData();
    await this.ObtenerSolicitudPrecalificacion()
    
  }
  // get DatosGenerales(){ return this.frmDatosGenerales.value }
  // get ActEconomica() { return this.frmActEconomica.value }
  // get Consultorio() { return this.frmConsultorio.value }
  // get Aval() { return this.frmAval.value }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(["/login"]);
  }

  async getAnalisisData() {
    console.log("Entro a get analisis");
    const res: any = this.solicitudService
      .ObtenerAnalisisCredito(this.folio)
      .subscribe(
        (res: any) => {
          if (res.data && res.data != null) {
            console.log("SI HAY INFO", res);
            this.AnalisisPF = {
              ...res.data,
            };
            this.obtenerDatosCotizacion()
            this.analisisPf = { ...this.AnalisisPF };
            console.log("DI", this.AnalisisPF);
          } else {
            console.log("NO HAY INFO", res);

            this.getInitData();
          }

          console.log(this.AnalisisPF);
        },
        (error) => {
          console.log("Entro a get analisis, ERROR");
          this.getInitData();
        }
      );
  }

  actualizarPromediosAnalisis(){
      this.AnalisisPF.promedio_depositos = 0;
      this.AnalisisPF.promedio_gastos = 0;
      this.AnalisisPF.promedio_saldo_final = 0;
  
      const saldo_inicial = this.AnalisisPF.saldo_inicial
      this.AnalisisPF.historial_movimientos.forEach( (mov, i) => {
        const saldo_anterior = (i > 0) ? Number(this.AnalisisPF.historial_movimientos[i -1].saldo_final) : Number(saldo_inicial)
        
        mov.saldo_final = Number(mov.depositos) - Number(mov.gastos) + saldo_anterior
  
        this.AnalisisPF.promedio_depositos += (Number(mov.depositos) / this.AnalisisPF.historial_movimientos.length);
        this.AnalisisPF.promedio_gastos += (Number(mov.gastos) / this.AnalisisPF.historial_movimientos.length);
        this.AnalisisPF.promedio_saldo_final += (Math.abs(mov.saldo_final) / this.AnalisisPF.historial_movimientos.length);
  
      });
  
      // this.AnalisisPF.comprobantes_gastos = this.AnalisisPF.promedio_gastos
  
      this.AnalisisPF.capacidad_pago.comprobantes_ingresos = (this.AnalisisPF.promedio_depositos * 1) + (this.AnalisisPF.capacidad_pago.ingresos_nomina * 1);
      this.AnalisisPF.capacidad_pago.efectivo_ingresos = (this.AnalisisPF.entrevista_consultorio.ingresos_efectivo * 0.6) + this.AnalisisPF.capacidad_pago.comprobantes_ingresos;
      this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_ingresos = (this.AnalisisPF.capacidad_pago.ingresos_extra * 0.5) + this.AnalisisPF.capacidad_pago.efectivo_ingresos;
  
  
      this.AnalisisPF.capacidad_pago.comprobantes_factor = Math.trunc(100 * (this.AnalisisPF.capacidad_pago.comprobantes_gastos / this.AnalisisPF.capacidad_pago.comprobantes_ingresos));
      this.AnalisisPF.capacidad_pago.efectivo_factor = Math.trunc(100 * (this.AnalisisPF.capacidad_pago.efectivo_gastos / this.AnalisisPF.capacidad_pago.efectivo_ingresos));
      this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_factor = Math.trunc(100 * (this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_gastos / this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_ingresos));
      // this.actualizarPromedios.emit()
    

  }
  cambiarPuntuacionAnalisis(){
    this.AnalisisPF.perfil_solicitante.bc_score_punt =
      (this.AnalisisPF.perfil_solicitante.bc_score >= 630) ? 7
      : (this.AnalisisPF.perfil_solicitante.bc_score >= 615 ) ? 5
      : (this.AnalisisPF.perfil_solicitante.bc_score >= 600 ) ? 4 : 0;

    this.AnalisisPF.perfil_solicitante.arraigo_domicilio_punt =
      (this.AnalisisPF.perfil_solicitante.arraigo_domicilio >= 2) ? 2 : 
      (this.AnalisisPF.perfil_solicitante.arraigo_domicilio >= 1) ? 1 : 0;
    this.AnalisisPF.perfil_solicitante.arraigo_empleo_punt =
      (this.AnalisisPF.perfil_solicitante.arraigo_empleo >= 2) ? 2 : 1;

    this.AnalisisPF.perfil_solicitante.consejo_especialidad_punt =
      (this.AnalisisPF.perfil_solicitante.consejo_especialidad == 'SI') ? 3 : 0;

    this.ActualizarPuntuacion('perfil_solicitante');
  }
  puntuacionCaracterAnalisis(){
  }

  ActualizarPuntuacion(perfil) {
    if (perfil === 'perfil_aval') {
      this.AnalisisPF.perfil_aval.icc_punt = Number(this.AnalisisPF.perfil_aval.icc_punt);
      this.AnalisisPF.perfil_aval.ocupacion_punt = Number(this.AnalisisPF.perfil_aval.ocupacion_punt);
      this.AnalisisPF.perfil_aval.parentezco_punt = Number(this.AnalisisPF.perfil_aval.parentezco_punt);
    }
  
    const {
      bc_score_punt = 0,
      icc_punt = 0,
      arraigo_domicilio_punt = 0,
      arraigo_empleo_punt = 0,
      consejo_especialidad_punt = 0,
      documentacion_punt = 0,
      parentezco_punt = 0,
      ocupacion_punt = 0,
    } = this.AnalisisPF[perfil];
  
    const safeNumber = (val) => isNaN(Number(val)) ? 0 : Number(val);
  
    this.AnalisisPF[perfil].total = (
      safeNumber(bc_score_punt) +
      safeNumber(icc_punt) +
      safeNumber(arraigo_domicilio_punt) +
      safeNumber(arraigo_empleo_punt) +
      safeNumber(consejo_especialidad_punt) +
      safeNumber(documentacion_punt) +
      safeNumber(parentezco_punt) +
      safeNumber(ocupacion_punt)
    );
  
    this.PuntuacionCaracter(); // Actualizamos los puntos de la sección Caracter
  }
  PuntuacionCaracter() {
    const puntuacionCaracter = (
      (this.AnalisisPF.perfil_solicitante.total ?? 0) + 
      (this.AnalisisPF.perfil_aval.total ?? 0) + 
      (this.AnalisisPF.puntos_credito_abierto ?? 0) + 
      (this.AnalisisPF.puntos_credito_cerradas ?? 0)
    );

    this.AnalisisPF.puntuacion_caracter = puntuacionCaracter > 34 ? 34 : puntuacionCaracter;
}
  async getInitData() {
    const { id_cliente, folio, tipo_persona, comercial, vendor, email } =
      JSON.parse(localStorage.getItem("client_details"));
    let guardado = { id_cliente, folio, parte: 1, tipo_persona };
    this.folio = folio;
    this.id_cliente = id_cliente;
   

    // this.AnalisisPF.

    this.solicitudService.getDatosGenerales({ guardado }).subscribe((res) => {
      console.log("Parte DATOS GENERALES: ", res);
      this.AnalisisPF.solicitante = `${res.nombres} ${res.ap_paterno} ${res.ap_materno}`;
      this.AnalisisPF.tel_casa = res.tel_casa;
      this.AnalisisPF.tel_cel = res.tel_cel;
      this.AnalisisPF.rfc = res.rfc;
      this.AnalisisPF.entrevista_economica.puesto = res.ocupacion;
      this.analisisPf = { ...this.AnalisisPF };
    });
    guardado.parte = 2;
    this.solicitudService.getDatosGenerales({ guardado }).subscribe((res) => {
      console.log("Parte 2: ", res);
      // this.DatosGeneralesDos = res;
      const _domicilio = res.domicilio.replace(/\|/g, " ").split("&")[0];
      this.AnalisisPF.entrevista_general.domicilio = _domicilio;
      this.AnalisisPF.entrevista_general.domicilio_propio =
        res.tipo_vivienda == "PROPIA" ? true : false;
      this.AnalisisPF.entrevista_general.domicilio_antiguedad =
        res.tiempo_residencia;
      // this.AnalisisPF.entrevista_general.mmr = res.pago_mensual;
      this.analisisPf = { ...this.AnalisisPF };
    });
    guardado.parte = 3;
    this.solicitudService.getDatosGenerales({ guardado }).subscribe((res) => {
      console.log("Parte 3: ", res);
      // this.DatosGeneralesTres = res;
      this.AnalisisPF.entrevista_general.dependientes = res.numero_dependientes;
      this.AnalisisPF.entrevista_general.consejo_especialidad =
        res.pertenece && res.pertenece == "true" ? true : false;
      this.AnalisisPF.caracteristica_activo.descripcion_activo =
        res.equipo_solicitado;
      this.AnalisisPF.actividad_economica = res.ocupacion_medica;
      this.analisisPf = { ...this.AnalisisPF };
    });

    // Obtenemos datos del aval
    guardado.parte = 1;
    const solicitud = await this.solicitudService.ObtenerSolicitud(this.folio)
    this.analisisPf.solicitud = solicitud
    const direccionActivo = solicitud && solicitud.direccionActivo ? solicitud.direccionActivo.direccionCompleta : ''
    this.AnalisisPF.entrevista_consultorio.domicilio = direccionActivo
    console.log({solicitud});
    
    if (solicitud.aval) {
      console.log("AVAL", solicitud)
      this.AnalisisPF.aval.aval_nombre = `${solicitud.aval.nombreCompleto} ${solicitud.aval.apellidoPaterno} ${solicitud.aval.apellidoMaterno}`
      const { direccionActivo = {direccionCompleta: ''}} = solicitud
      const {direccionCompleta}= direccionActivo
      
      this.AnalisisPF.caracteristica_activo.domicilio = direccionCompleta;

    }
    this.solicitudService.geteAval({ guardado }).subscribe(async ({ body }) => {
      console.log("AVAL",body);
      // this.AnalisisPF.aval.aval_domicilio = body.aval_domicilio;
      this.AnalisisPF.aval.aval_telefono = body.aval_telefono;
      this.AnalisisPF.aval.aval_ocupacion = body.aval_ocupacion;
      this.AnalisisPF.aval.aval_parentezco = body.aval_parentesco || body.parentezco;
      this.AnalisisPF.aval.tiempo_conocerlo = body.aval_tiempo_conocerlo
      try {
        const aval: any = await this.solicitudService.BuscarAval(id_cliente);
        console.log("Aval encontrado: ", aval);

        // this.AnalisisPF.aval.aval_nombre = aval.aval_nombre;
        this.AnalisisPF.aval.aval_parentezco =
          aval.aval_parentesco || aval.parentezco;
        // this.AnalisisPF.aval.aval_ocupacion = aval.aval_ocupacion;
        // this.AnalisisPF.aval.aval_domicilio = aval.aval_domicilio;
        // this.AnalisisPF.aval.aval_telefono = aval.aval_telefono;
        // this.AnalisisPF.aval.tiempo_conocerlo =
        //   aval.aval_tiempo_conocerlo || aval.tiempo_conocerlo;
        this.AnalisisPF.aval.lo_recomienda = aval.lo_recomienda;
      } catch (e) {
        console.log(e);
      }
    });

    guardado.parte = 2;
    this.solicitudService
      .geteReferencias({ guardado })
      .subscribe(({ body: res }) => {
        console.log("REFERENCIAS", res)
        this.AnalisisPF.referencias = this.AnalisisPF.referencias.map((ref, i) => ({
          ...ref,
          nombre: res[`rp_nombre${i + 1}`],
          domicilio: res[`rp_direccion${i + 1}`],
          telefono: res[`rp_telefono${i + 1}`],
          parentezco: res[`rp_parentesco${i + 1}`],
          [`lo_recomienda${i + 1}`]: res[`lo_recomienda${i + 1}`],
          ocupacion: res[`ocupacion${i + 1}`],
          tiempo_conocerlo: res[`rp_tiempo_conocerlo${i + 1}`] || ref.tiempo_conocerlo,
          
        }));
        console.log("REFERENCIAS", this.AnalisisPF.referencias)
        this.analisisPf = { ...this.AnalisisPF };

      });

      this.obtenerDatosCotizacion()

    // const res = this.userService.GuardarAnalisis( {id_solicitud: this.AnalisisPF.id_solicitud, ...this.AnalisisPF} )
    // .subscribe( ({message}) => {
    //   console.log(message)
    // })
    // console.log("GUARDANTO DATOS ANALISIS", res)
    // const dataBuro = {
    //   id_cliente: id_cliente,
    //   rfc: this.AnalisisPF.rfc,
    //   correo: email,
    // };
    // console.log(dataBuro);
    // const resBuro: any = await this.buroService.ClientePrecalificado(dataBuro);
    // console.log("RESPONSE BURO", resBuro);
    // if (resBuro.cliente_precalificado.length > 0) {
    //   this.AnalisisPF.perfil_solicitante.bc_score =
    //     resBuro.cliente_precalificado[0].score;
    //   const rawBuro = JSON.parse(resBuro.cliente_precalificado[0].raw);
    //    this.AnalisisPF.ultima_consulta_buro =
    //     resBuro.cliente_precalificado[0].fecha_creacion

    //   console.log("RAW BURO", rawBuro);
    //   const data = {
    //     referencia_operador:
    //       resBuro.cliente_precalificado[0].referencia_operador,
    //   };
      // const ReporteClienteBuro: any = await this.buroService.ReporteBCCliente(
      //   data
      // );
    //   console.log("OBTENIENDO REPORTE", ReporteClienteBuro);

    //   console.log("HISTORIAL", this.AnalisisPF.historial_credito)
    // }

    // const res:any = await this.solicitudService.GuardarAnalisisCredito(
    //   data
    // );
    // console.log("GUARDANDO ANALISIS", res)
  }

  actualizarAnalisisPf(nuevoValor: any) {
    this.analisisPf = nuevoValor;
    this.cdr.detectChanges();

  }
  extraerNumeroDeCadena(cadena) {
    const match = cadena.match(
      /(?:\d{1,3}(?:,\d{3})*(?:\.\d+)?|\d+(?:\.\d+)?)/
    );
    return match ? parseFloat(match[0].replace(/,/g, "")) : null;
  }
  convertDate(dateStr: string): string {
    const months: { [key: string]: string } = {
        "enero": "01",
        "febrero": "02",
        "marzo": "03",
        "abril": "04",
        "mayo": "05",
        "junio": "06",
        "julio": "07",
        "agosto": "08",
        "septiembre": "09",
        "octubre": "10",
        "noviembre": "11",
        "diciembre": "12"
    };

    const [day, , monthName, , year] = dateStr.split(" ");
    const month = months[monthName.toLowerCase()];

    if (!day || !month || !year) {
        throw new Error("Invalid date format");
    }

    return `${year}-${month}-${day.padStart(2, "0")}`;
}
async ObtenerDireccionActivo() {
  const solicitud = await this.solicitudService.ObtenerSolicitud(this.folio)
  console.log("SOLICITUD", solicitud)
  if (solicitud) {
    const { direccionActivo} = solicitud
    console.log({direccionActivo});
    this.AnalisisPF.caracteristica_activo.domicilio = direccionActivo
  }
}

  async ObtenerSolicitudPrecalificacion() {
    console.log('ObtenerSolicitudPrecalificacion');
    
    this.solicitud = await this.solicitudService.ObtenerSolicitud(this.folio)

    const resp: any = await this.buroService.ClientePrecalificado({rfc: this.solicitud.contacto.rfc})
    console.log("OBENER PRE", resp)
    if (resp && resp.cliente_precalificado && resp.cliente_precalificado.length) {
      const { score } = resp.cliente_precalificado[0]
      this.AnalisisPF.perfil_solicitante.bc_score = score

      console.log("DATABC", resp.dataBC);
      const { ResumenReporte: {ResumenReporte}, Cuentas: {Cuenta} } = resp.dataBC
      const {TotalSaldosVencidosRevolventes,TotalPagosRevolventes,TotalPagosPagosFijos} = ResumenReporte
      const gastosBuro = parseFloat(TotalPagosRevolventes) + parseFloat(TotalPagosPagosFijos)
      console.log({gastosBuro});
      this.AnalisisPF.entrevista_general.gastos_buro = gastosBuro

      if (!this.AnalisisPF.historial_credito.cerradas.length || !this.AnalisisPF.historial_credito.activas.length) {
        this.AnalisisPF.historial_credito.cerradas = []
        this.AnalisisPF.historial_credito.activas = []
        for (const cuenta of Cuenta) {
          let mop_max = 0
          const mops = cuenta.HistoricoPagos ? cuenta.HistoricoPagos.split('') : '0'
          for (const mop of mops) {
            const curMop = parseInt(mop) || 0
            mop_max = curMop > mop_max ? curMop : mop_max
          }

          const _cuenta = {
            tipo_credito: tiposContrato[cuenta.TipoContrato],
            monto: parseFloat(cuenta.CreditoMaximo),
            saldo_insoluto: parseFloat(cuenta.SaldoActual),
            mop_max,
            id_comportamiento_pago: mop_max == 1 ? 1 : 2,
          }
          if (cuenta.FechaCierreCuenta) {
            console.log({cuenta, _cuenta});
            
            this.analisisPf.historial_credito.cerradas.push(_cuenta)
          } else {
            this.AnalisisPF.historial_credito.activas.push(_cuenta)
          }
        }
        this.CalcularPuntosHistorialPagos()
      }
      
    }
    if(this.solicitud.aval.rfc != ""){
    const respAval: any = await this.buroService.ClientePrecalificado({rfc: this.solicitud?.aval?.rfc})
    if (respAval && respAval.cliente_precalificado && respAval.cliente_precalificado.length) {
      const { score } = respAval.cliente_precalificado[0]
      this.AnalisisPF.perfil_aval.bc_score = score
      this.AnalisisPF.perfil_aval.bc_score_punt =
      (score >= 630) ? 4
      : (score >= 615 ) ? 2
      : (score >= 600 ) ? 1 : 0;
    }
    console.log(this.solicitud, {resp});
  }
  this.AnalisisPF.entrevista_general.domicilio = this.solicitud.direccion.direccionCompleta
  this.AnalisisPF.aval.aval_domicilio = this.solicitud.aval.direccion.direccionCompleta
  this.AnalisisPF.entrevista_consultorio.domicilio = this.solicitud.direccionActivo.direccionCompleta
  }

  CalcularPuntosHistorialPagos() {
    console.log('CalcularPuntosHistorialPagos', this.AnalisisPF.historial_credito.activas.length);
    let puntosActivas = 0
    let puntosCerradas = 0

    for (let i = 0 ; i < this.AnalisisPF.historial_credito.activas.length; i++) {
      const comportamiento = this.AnalisisPF.historial_credito.activas[i].id_comportamiento_pago
      const puntos = comportamiento == 1 
        ? this.AnalisisPF.historial_credito.activas.length == 1 && this.AnalisisPF.historial_credito.cerradas.length == 0 ? 16
          : this.AnalisisPF.historial_credito.activas.length == 1 && this.AnalisisPF.historial_credito.cerradas.length == 1 ? 8
            : 4
          : comportamiento == 2 ? 2 : 0
          
      this.AnalisisPF.historial_credito.activas[i].puntuacion = puntos
      puntosActivas += puntos
    }

    for (let i = 0 ; i < this.AnalisisPF.historial_credito.cerradas.length; i++) {
      const comportamiento = this.AnalisisPF.historial_credito.cerradas[i].id_comportamiento_pago
      const puntos = comportamiento == 1 
        ? this.AnalisisPF.historial_credito.cerradas.length == 1 && this.AnalisisPF.historial_credito.cerradas.length == 0 ? 16
          : this.AnalisisPF.historial_credito.cerradas.length == 1 && this.AnalisisPF.historial_credito.cerradas.length == 1 ? 8
            : 4
          : comportamiento == 2 ? 2 : 0
          
      this.AnalisisPF.historial_credito.cerradas[i].puntuacion = puntos
      puntosCerradas += puntos
    }

    console.log({puntosActivas, puntosCerradas});
    
    this.AnalisisPF.puntos_credito_abierto = puntosActivas
    this.AnalisisPF.puntos_credito_cerradas = puntosCerradas
  }

  async obtenerDatosCotizacion(){
    const { id_cliente, folio, tipo_persona, comercial, vendor, email } =
    JSON.parse(localStorage.getItem("client_details"));
    let guardado = { id_cliente, folio, parte: 1, tipo_persona };
    this.folio = folio;
    this.id_cliente = id_cliente;
    const obtenerTramite: any = await this.solicitudService.ObtenerTramite(
      folio
    );
    const _tramite =
      obtenerTramite.data.length > 0
        ? obtenerTramite.data.filter((tramite) => tramite.confirmada == true)
        : null;
    const rawCot = JSON.parse(_tramite[0].raw_cot);
    console.log("OBTENIENDO", rawCot);

    const { mensualidad, renta_inicial = 0 } = await (<any>(
      this.solicitudService
        .ObtenerMensualidad(
          folio,
          obtenerTramite.data[0].version,
          obtenerTramite.data[0].plazo_confirmado
        )
        .then((mensualidad) => mensualidad)
    ));


    const { residual } = await (<any>(
      this.solicitudService
        .ObtenerResidual(
          folio,
          obtenerTramite.data[0].version,
          obtenerTramite.data[0].plazo_confirmado
        )
        .then((residual) => residual)
    ));
    console.log("Valor residual: ", residual);
    this.AnalisisPF.cotizacion.vendor = vendor;
    this.AnalisisPF.cotizacion.comercial = comercial;
    const plazoConfirmado = this.extraerNumeroDeCadena(
      _tramite[0].plazo_confirmado
    );
    const cotConfirm = rawCot.periods.find((periods) => {
      if (periods.initial_payment === "0.00%") {
        return periods.period === plazoConfirmado - 1;
      } else {
        return periods.period === plazoConfirmado;
      }
    });
    console.log("OBTENIENDO PERIODS", plazoConfirmado, cotConfirm, rawCot);
    this.AnalisisPF.cotizacion.plazo = plazoConfirmado;
    this.AnalisisPF.caracteristica_activo.tipo_activo_arrendar = rawCot.type;
    this.AnalisisPF.cotizacion.monto_arrendar =
      (rawCot.monto_financiar + Number(rawCot.pago_inicial)).toFixed(2);
    this.AnalisisPF.cotizacion.mensualidad =Number(cotConfirm.monthly_payment.replace(/[$,]/g, '')
)
    this.AnalisisPF.cotizacion.fecha_cotizacion = await this.convertDate(rawCot.date);
    this.AnalisisPF.colateral.pago_inicial = cotConfirm.initial_payment_amount;
    this.AnalisisPF.cotizacion.factor_porcentaje = cotConfirm.monthly_rate;
    this.AnalisisPF.cotizacion.valor_residual = cotConfirm.residual_amount;
    this.AnalisisPF.cotizacion.mar_total =
      this.AnalisisPF.cotizacion.monto_arrendar -
      this.extraerNumeroDeCadena(this.AnalisisPF.colateral.pago_inicial);
      this.AnalisisPF.cotizacion.rentas_anticipadas = ((parseFloat(cotConfirm.initial_payment_amount.replace(/[$,]/g, '')) / this.AnalisisPF.cotizacion.monto_arrendar) * 100).toFixed(2);
     const _mensualidad = typeof this.AnalisisPF.cotizacion.mensualidad == 'number' ? this.AnalisisPF.cotizacion.mensualidad.toFixed(2) : this.AnalisisPF.cotizacion.mensualidad.replace(/[$,]/g, '')
    this.AnalisisPF.colateral.factor = (parseFloat(this.AnalisisPF.cotizacion.mensualidad.toFixed(2).replace(/[$,]/g, '')) * plazoConfirmado).toFixed(2)
    console.log(_mensualidad);
    
    console.log("VALOR FUTURO", this.AnalisisPF.cotizacion.valor_futuro, parseFloat(_mensualidad), plazoConfirmado)
    
  }

}
