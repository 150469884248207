import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/_services';

@Component({
  selector: 'app-lista-cotizaciones',
  templateUrl: './lista-cotizaciones.component.html',
  styleUrls: ['./lista-cotizaciones.component.scss']
})
export class ListaCotizacionesComponent implements OnInit {

  @Input() cotizaciones = []
  perfil = null
  folio = null
  constructor(private router: Router, private userService:UserService) { }

  ngOnInit(): void {
    this.perfil = Number(JSON.parse(localStorage.getItem('currentUser')).perfil)
    this.folio = Number(JSON.parse(localStorage.getItem('client_details')).folio)
  }

  VerCotizacion(url, idx, id_cotizacion, version){
    if( !this.userService.alertService.isOnline() ) return
    console.log(this.perfil);
    console.log("Selected: ", this.cotizaciones[idx]);
    
    // if( this.perfil != 3 ){
      if( url ){
        localStorage.setItem('documento', JSON.stringify({url}))
        this.router.navigate(["/dashboard", "cliente", this.folio, "ver_documento"])
      }
    // }
    // if( this.perfil == 3) {
    //   localStorage.setItem('cotizacion', JSON.stringify({
    //     version, url, id_cotizacion
    //   }))
    //   return this.router.navigate(['/dashboard', 'detalle_cotizacion'])
    // }
  }
  DescargarPDF(url){
    window.open(url, '_blank');
  }

}
