import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogDetaleCarteraAnexoComponent } from 'src/app/_dialogs/dialog-detale-cartera-anexo/dialog-detale-cartera-anexo.component';
import * as ExcelJS from 'exceljs';
import { CarteraService } from 'src/app/_services/cartera.service';
import { MESES } from '../valuacion/analisis/Analisis';

@Component({
  selector: 'app-cartera',
  templateUrl: './cartera.component.html',
  styleUrls: ['./cartera.component.scss']
})
export class CarteraComponent implements OnInit {
  anexosCompletos: any = []
  anexos: any = []
  resumen: any = []

  filtroResumen: any = []
  filtroAnexos: any = {}

  filtroCartera: any = {}

  loading = false
  constructor(
    private carteraService: CarteraService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    const currentDate = new Date()
    const initialDate = new Date()

    initialDate.setDate(1)

    if (currentDate.getDate() == 1) {
      initialDate.setMonth(initialDate.getMonth() -1)
    }

    this.filtroCartera.fechaInicio = `${initialDate.toISOString().split('T')[0]}T00:00:00.000Z`,
    this.filtroCartera.fechaFin = `${currentDate.toISOString().split('T')[0]}T23:59:59.000Z`,
    
    this.ObtenerCartera(this.filtroCartera)
  }

  async ObtenerCartera(filtro) {
    this.loading = true
    
    const res = await this.carteraService.ObtenerCartera(filtro).catch(() => {
      this.loading = false
    })
    if (res.statusCode == 200) {
      this.anexosCompletos = [...res.data.anexos]
      this.resumen = [...res.data.summary]
      this.filtroResumen = [...res.data.summary]
      // this._cargarDetalle([...res.data.anexos])

      this.BuscarAnexo('')
    }
    this.loading = false
  }

  MostrarDetalle(anexo) {
    if (anexo) {
      if (this.filtroAnexos[anexo.folio] == undefined) {
        this.filtroAnexos[anexo.folio] = {...anexo}
      } else {
        delete this.filtroAnexos[anexo.folio]
      }
    }

    this.filtroResumen = Object.keys(this.filtroAnexos).length ? this.ObtenerResumenCustomizado(this.filtroAnexos) : [...this.resumen] 
    
    // this.dialog.open(DialogDetaleCarteraAnexoComponent, {
    //   width: "620px",
    //   minHeight: "450px",
    //   data: anexo
    // })
  }

  ObtenerResumenCustomizado(filtroAnexos) {
    const summary: any = {}
    const customSummary: any = []

    const keys = Object.keys(filtroAnexos)
    for (const key of keys) {
      for (const pago of filtroAnexos[key].desglose) {
        const [year, month, day] = pago.fecha.split('-')

        if (!summary[year]) {
          summary[year] = {}
        } 

        if (!summary[year][month]) {
          summary[year][month] = {
            monto: 0,
            pago: 0,
            capital: 0,
            interes: 0,
            insoluto: 0,
            residual: 0,
            mensualidad: 0,
            seguroSinIva: 0,
            anexo: []
          }
        }

        summary[year][month].monto += pago.monto
        summary[year][month].pago += pago.pago
        summary[year][month].capital += pago.capital
        summary[year][month].interes += pago.interes
        summary[year][month].insoluto += pago.insoluto
        summary[year][month].residual += pago.residual
        summary[year][month].seguroSinIva += pago.seguroSinIva

        summary[year][month].anexo.push({
          folio: key,
          ...pago,
        })
        
      }
    }

    const years = Object.keys(summary)
    for (const year of years) {
      const months = Object.keys(summary[year])
      for (const month of months) {
        customSummary.push({
            ...summary[year][month],
            periodo: `${year}-${month}-01T06:00:00.000Z`
          })
      }
    }
    
    customSummary.sort((a: any, b: any) => a.periodo < b.periodo ? -1 : 0)

    return customSummary.length ? [...customSummary] : []
  }

  MostrarDetalleResumen(resumen) {
    this.dialog.open(DialogDetaleCarteraAnexoComponent, {
      width: "620px",
      minHeight: "450px",
      data: {
        ...resumen,
        resumen: true
      }
    })
  }

  _cargarDetalle(anexos) {
    let _resumen = {}
    for (const anexo of anexos) {
      if (!anexo.desglose || !anexo.desglose.length) {
        console.error(anexo);
        continue 
      }
      const fechaResidual = new Date(anexo.fechaResidual)
      fechaResidual.setDate(1)

      if (_resumen[fechaResidual.toUTCString()]) {
        _resumen[fechaResidual.toUTCString()].residual += anexo.valorResidual || 0
      } else {
        _resumen[fechaResidual.toUTCString()] = {
          capital: 0,
          interes: 0,
          insoluto: 0,
          pago: 0,
          monto: 0,
          residual: anexo.valorResidual,
        }
      }

      
      for (const renta of anexo.desglose) {
        const periodo = new Date(renta.fecha)
        periodo.setDate(1)
       
        if (_resumen[periodo.toUTCString()]) {
           _resumen[periodo.toUTCString()].capital += renta.capital || 0
           _resumen[periodo.toUTCString()].interes += renta.interes || 0
           _resumen[periodo.toUTCString()].insoluto += renta.insoluto || 0
           _resumen[periodo.toUTCString()].pago += renta.pago || 0
           _resumen[periodo.toUTCString()].monto += renta.capital || 0
        } else {
          _resumen[periodo.toUTCString()] = {
            capital: renta.capital || 0,
            interes: renta.interes || 0,
            insoluto: renta.insoluto || 0,
            pago: renta.pago || 0,
            monto: renta.capital || 0,
            residual: 0
          }
        }
      }
    }


    for (const key in _resumen) {
      if (Object.prototype.hasOwnProperty.call(_resumen, key)) {
        this.resumen.push({fecha: key, ..._resumen[key]});
      }
    }
  }

  AnexoSeleccionado(folio) {
    return this.filtroAnexos[folio] != undefined
  }

  exportToExcel() {
    const filename = "reporte"
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Anexos');
    const worksheetCronograma = workbook.addWorksheet('Cronograma');
    const worksheetSummary = workbook.addWorksheet('Resumen');
    const Anexos = Object.keys(this.filtroAnexos).length ? this.ObtenerAnexosSeleccionados() : [...this.anexos]
  
    worksheet.addRow([
      "N",
      "Folio",
      "Periodo",
      "Fecha contrato macro",
      "Cliente",
      "Monto a financiar",
      "Tasa interés",
      "Tasa implicita",
      "Valor residual",
      "Renta sin iva",
      "Seguro sin iva",
      "Renta total sin iva",
      "Plazo",
      "Fecha primer renta",
      "Fecha residual",
      "Días de atraso",
      "Reestructura",
    ]);
    let count = 0
    Anexos.forEach(anexo => {
      worksheet.addRow([
        ++count,
        anexo.folio,
        anexo.periodo,
        anexo.fechaContratoMarco,
        anexo.cliente,
        anexo.montoFinanciar,
        anexo.tasa,
        anexo.tasaActiva,
        anexo.valorResidual,
        anexo.rentaSinIva,
        Number(anexo.seguroSinIva),
        anexo.rentaTotal,
        anexo.plazo,
        anexo.fechaPrimerRenta,
        anexo.fechaResidual,
        anexo.diasAtraso,
        anexo.reestructurado,
      ]);
    });

    for (const anexo of Anexos) {
      const cronograma = this.CrearCronograma(anexo)
      worksheetCronograma.addRow([`Anexo: ${anexo.folio}`])
      worksheetCronograma.addRows(cronograma)
      worksheetCronograma.addRow([])
    }

    const periodos = ['Resumen']
    const saldoInicial = ['Saldo inicial']
    const capital = ['Amortización / Capital']
    const interes = ['Interés']
    const residual = ['Residual']
    const insoluto = ['Saldo final / Insoluto']
    const seguro = ['Seguro']
    const rentaMensual = ['Renta mensual']

    for (let i = 0; i < this.filtroResumen.length; i++) {
      periodos.push(this.filtroResumen[i].periodo)
      saldoInicial.push(this.filtroResumen[i].monto)
      capital.push(this.filtroResumen[i].capital)
      interes.push(this.filtroResumen[i].interes)
      residual.push(this.filtroResumen[i].residual)
      insoluto.push(this.filtroResumen[i].insoluto)
      seguro.push(this.filtroResumen[i].seguroSinIva)
      rentaMensual.push(this.filtroResumen[i].pago)
    }

    worksheetSummary.addRow(periodos)
    worksheetSummary.addRow(saldoInicial)
    worksheetSummary.addRow(capital)
    worksheetSummary.addRow(interes)
    worksheetSummary.addRow(residual)
    worksheetSummary.addRow(insoluto)
    worksheetSummary.addRow(seguro)
    worksheetSummary.addRow(rentaMensual)
  
    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename + `_${(new Date()).getTime()}` + '.xlsx';
      link.click();
      window.URL.revokeObjectURL(url);
    });
  }

  CrearCronograma(anexo) {
    const _cronograma = {
      periodo: [''],
      inicial: ['Saldo inicial'],
      capital: ['Amortización / Capital'],
      interes: ['Interés'],
      residual: ['Residual'],
      insoluto: ['Saldo final / insoluto'],
      seguro: ['Seguro'],
      mensualidad: ['Mensualidad'],
      mensualidadSeguro: ['Mensualidad con seguro'],
      pago: ['Pago'],
      pagado: ['Pagado'],
      fechaPago: ['Fecha pago']
    }
    
    for (const cronograma of anexo.desglose) {
      const pagado = (cronograma.pago - cronograma.montoPago) < 1 ? 'Sí' : 'No'
      const [year, month, _] = cronograma.fecha.split('-')
      
      const mes = MESES[parseInt(month) -1]
      _cronograma.periodo.push(`${mes} ${year}`)
      _cronograma.inicial.push(cronograma.monto)
      _cronograma.capital.push(cronograma.capital)
      _cronograma.interes.push(cronograma.interes)
      _cronograma.residual.push(cronograma.residual)
      _cronograma.insoluto.push(cronograma.insoluto)
      _cronograma.seguro.push(cronograma.seguroSinIva)
      _cronograma.mensualidad.push(cronograma.pago)
      _cronograma.mensualidadSeguro.push(cronograma.pago + cronograma.seguroSinIva)
      _cronograma.pago.push(cronograma.montoPago)
      _cronograma.pagado.push(pagado)
      _cronograma.fechaPago.push(cronograma.fechaPago)
    }

    return [
      _cronograma.periodo,
      _cronograma.inicial,
      _cronograma.capital,
      _cronograma.interes,
      _cronograma.residual,
      _cronograma.insoluto,
      _cronograma.seguro,
      _cronograma.mensualidad,
      _cronograma.mensualidadSeguro,
      _cronograma.pago,
      _cronograma.pagado,
      _cronograma.fechaPago,
    ]
  }

  BuscarAnexo(word) {
    const filter = word.toLowerCase()
    this.anexos = []
    this.filtroAnexos = []
    for (const anexo of this.anexosCompletos) {
      if (anexo.folio.toLowerCase().includes(filter) || anexo.cliente.toLowerCase().includes(filter)) {
        this.anexos.push({...anexo})
      }
    }
    this.MostrarDetalle(null)
  }

  ObtenerAnexosSeleccionados() {
    const _anexos = []
    Object.keys(this.filtroAnexos).forEach( anexo => {
      _anexos.push({...this.filtroAnexos[anexo]})
    })
    return _anexos
  }

}
