import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { catchError, first } from "rxjs/operators";
import { SolicitudService, UserService } from "src/app/_services";
import { Analisis } from "../../valuacion/analisis/Analisis";
import { AnalisisPM } from "../AnalisisPM";
import { StepperNavigationService } from "src/app/_services/stepper-navigation.service";
import { AnalisisPMService } from "src/app/_services/analisis.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-resumen",
  templateUrl: "./resumen.component.html",
  styleUrls: ["./resumen.component.scss"],
})
export class ResumenComponent implements OnInit {
  @Input() analisisPm: any = {};
  @Output() analisisPmChange = new EventEmitter();

  ngOnChanges(changes: SimpleChanges) {
    if (changes.analisisPm && changes.analisisPm.currentValue) {
      console.log("Nuevo valor de analisisPm RESUMEN:", this.analisisPm);
      this.AnalisisPM = { ...this.analisisPm };
      this.sumarPromedioAccionistas();
      this.sumarPuntosTotalesResumen();
    }
  }
  frmResumen: FormGroup;
  folio = null;
  id_cliente = null;
  Resumen: any = {};
  comportamiento_pago = "";
  total_participacion_accionistas: number;
  AnalisisPM: any = {};
  confirmado = false;
  data = null;
  puntuacion_final_resumen = 0;
  loading = false;


  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private solicitudService: SolicitudService,
    private userService: UserService,
    private stepperNavigationService: StepperNavigationService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    // this.AnalisisPM = {...this.analisisPm}

    console.log("PRUEBA RESUMEN", this.analisisPm);
    this.AnalisisPM = JSON.parse(JSON.stringify(AnalisisPM));

  }
  actualizarAnalisisPm(nuevoValor: any) {
    this.analisisPm = nuevoValor;
  }

  async getAnalisisPMData() {
    const res: any = this.solicitudService
      .ObtenerAnalisisCredito(this.folio)
      .subscribe(
        (res: any) => {
          this.AnalisisPM = {
            ...res.data,
          };
          this.analisisPmChange.emit(this.AnalisisPM);
        },
        (error) => {
          // this.getInitData()
          this.analisisPmChange.emit(this.AnalisisPM);
        }
      );
  }

  puntosComportamiento() {
    if (this.AnalisisPM.comportamiento_pago == "BUENO") {
      this.AnalisisPM.puntos = 10;
    } else if (this.AnalisisPM.comportamiento_pago == "REGULAR") {
      this.AnalisisPM.puntos = 5;
    } else {
      this.AnalisisPM.puntos = 0;
    }
    this.sumarPuntosTotalesResumen();
  }

  sumarPuntosTotalesResumen(){
    const puntosComportamiento = this.AnalisisPM.puntos == 0 || this.AnalisisPM.puntos == null ? 0 : this.AnalisisPM.puntos;
    const puntuacionFinalActtivo = this.AnalisisPM.puntuacion_final_activo == 0 || this.AnalisisPM.puntuacion_final_activo == null ? 0 : this.AnalisisPM.puntuacion_final_activo
    this.puntuacion_final_resumen = puntuacionFinalActtivo + puntosComportamiento;
  }
  extraerNumeroDeCadena(cadena) {
    const match = cadena.match(/\b\d+\b/);
    return match ? parseInt(match[0], 10) : null;
  }

  async guardarYAvanzar() {
    console.log("Guardando analisis", this.AnalisisPM);
    try {
      this.loading = true
      const res: any = await this.solicitudService.GuardarAnalisisCredito(
        this.AnalisisPM
      );
      console.log("RESPONSE", res);
      if (res.statusCode == 200) {
        this.loading = false;
        this.analisisPmChange.emit(this.AnalisisPM);
        console.log(this.AnalisisPM);
        // this.replaceNaNWithZero(this.AnalisisPM);
        this.stepperNavigationService.nextStep();
      } else {
        this.loading = false;
        this._snackBar.open(
          "Ha ocurrido un error, por favor vuelve a intentarlo más tarde",
          "Ok",
          {
            duration: 10000,
            horizontalPosition: "center",
            verticalPosition: "top",
            panelClass: ["warning-snackbar"],
          }
        );
      }
    } catch (err) {
      this.loading = false;
      this._snackBar.open(
        "Ha ocurrido un error, por favor vuelve a intentarlo",
        "Ok",
        {
          duration: 10000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: ["warning-snackbar"],
        }
      );
    }
  }

  sumarTotales(...valores: number[]): number {
    return valores.reduce((total, valor) => total + (valor || 0), 0);
  }
  sumarPromedioAccionistas() {
    this.total_participacion_accionistas = this.AnalisisPM.accionistas.reduce((total, accionista) => {
      return total + parseInt(accionista.participacion || 0);
    }, 0);
  }
//  replaceNaNWithZero(obj) {
//     for (let prop in obj) {
//         if (typeof obj[prop] === 'object') {
//             this.replaceNaNWithZero(obj[prop]); 
//         } else if (isNaN(obj[prop])) {
//             obj[prop] = 0; 
//         }
//     }
// }


  
}
