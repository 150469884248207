<div class="container">
  <div class="top">
    <div class="header">
      <img
        class="logo"
        mat-button
        height="60px"
        width="auto"
        [routerLink]="['/dashboard']"
        src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/logo-header.svg"
        alt=""
        style="padding-left: 20px"
      />
      <div>
        <button mat-button routerLink="/changePassword">
          Cambiar Contraseña
          <svg
            style="height: 20px; width: auto"
            xmlns="http://www.w3.org/2000/svg"
            width="31.992"
            height="31.992"
            viewBox="0 0 31.992 31.992"
          >
            <defs>
              <style>
                .a {
                  fill: #3ca2d6;
                }
              </style>
            </defs>
            <path
              class="a"
              d="M18,2a16,16,0,1,0,16,16A16.006,16.006,0,0,0,18,2Zm0,1.391A14.6,14.6,0,1,1,3.391,18,14.594,14.594,0,0,1,18,3.391Zm0,4.868a4.164,4.164,0,0,0-4.173,4.173v2.086h-.7A2.1,2.1,0,0,0,11.041,16.6V23.56a2.1,2.1,0,0,0,2.086,2.086h9.737a2.1,2.1,0,0,0,2.086-2.086V16.6a2.1,2.1,0,0,0-2.086-2.086h-.7V12.432A4.164,4.164,0,0,0,18,8.259ZM18,9.65a2.724,2.724,0,0,1,2.782,2.782v2.086H15.214V12.432A2.724,2.724,0,0,1,18,9.65Zm-4.868,6.259H14.4a.7.7,0,0,0,.227,0h6.728a.7.7,0,0,0,.227,0h1.281a.693.693,0,0,1,.7.7V23.56a.693.693,0,0,1-.7.7H13.128a.693.693,0,0,1-.7-.7V16.6A.693.693,0,0,1,13.128,15.909ZM18,18a1.39,1.39,0,0,0-.7,2.594v.883a.7.7,0,1,0,1.391,0v-.884A1.389,1.389,0,0,0,18,18Z"
              transform="translate(-2 -2)"
            />
          </svg>
        </button>
        <button mat-button (click)="logout()">
          Cerrar Sesión
          <svg
            style="height: 20px; width: auto"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="31.992"
            viewBox="0 0 32 31.992"
          >
            <defs>
              <style>
                .a {
                  fill: #3ca2d6;
                }
              </style>
            </defs>
            <path
              class="a"
              d="M18,2A16,16,0,1,0,30.9,27.449a.7.7,0,1,0-1.121-.823,14.6,14.6,0,1,1,0-17.259A.7.7,0,1,0,30.9,8.543,15.976,15.976,0,0,0,18,2Zm9.73,9.73a.7.7,0,0,0-.485,1.194L31.617,17.3H16.6a.7.7,0,1,0,0,1.391H31.617l-4.377,4.377a.7.7,0,1,0,.984.984l5.5-5.5a.7.7,0,0,0,0-1.107l-5.5-5.5A.7.7,0,0,0,27.726,11.73Z"
              transform="translate(-2 -2)"
            />
          </svg>
        </button>
      </div>
    </div>

    <div class="secciones">
      <div class="title">Reporte <br />Buró de Crédito</div>
    </div>
  </div>
  <div>
    <button *ngIf="!loading" class="principal_button" (click)="exportReporteToExcel()">Descargar Reporte Buro ARCUS</button>
    <mat-spinner *ngIf="loading && processCompleted == -1" diameter="20"></mat-spinner>
    <mat-spinner *ngIf="loading && processCompleted > 0" diameter="40" mode="determinate" [value]="processCompleted"></mat-spinner>
  </div>
  <div class="container-filter">
    <div class="input">
      <mat-label>Fecha inicial</mat-label>
      <input type="date" [(ngModel)]="fecha_inicio" (change)="getTableBC()" />
    </div>
    <div class="input">
      <mat-label>Fecha final</mat-label>
      <input
        type="date"
        [(ngModel)]="fecha_final"
        (change)="getTableBC()"
      />
      
    </div>
    <div>
      <button class="secondary_button" (click)="resetBC()">Limpiar</button>
    </div>
  </div>
  <div style="margin-bottom: 20px;">
    <button
      class="principal_button"
      (click)="exportToExcel('tablaBC', 'tablaBC')"
    >
      Descargar
    </button>
  </div>
  <div class="body">
    <div class="box">
      <table id="tablaBC" [cellSpacing]="0" [cellPadding]="0">
        <thead>
          <tr>
            <th>No.</th>
            <th>Reporte</th>
            <th>Acuse</th>
            <th>No. de transacción(Referencia del operador)</th>
            <th>Fecha</th>
            <th>Score</th>
            <th>Nombre(s)</th>
            <th>Paterno</th>
            <th>Materno</th>
            <th>RFC</th>
            <th>Calle - No.</th>
            <th>Colonia</th>
            <th>Ciudad</th>
            <th>Edo.</th>
            <th>Ingreso nuevamente de NIP</th>
            <th>Respuesta a leyenda de autorización</th>
            <th>Cuenta</th>
            <th>No. folio de consulta BC</th>
            <th>NIP</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let reporte of datosBC; let i = index">
            <td style="text-align: center;">{{ i + 1 }}</td>
            <td style="text-align: center;">
              <mat-progress-spinner *ngIf="downloading && control_consulta_downloading == reporte?.control_consulta" diameter="20" mode="indeterminate"></mat-progress-spinner>
              <img *ngIf="!(downloading && control_consulta_downloading == reporte?.control_consulta)" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/pdf.svg" alt="Ver PDF" width="20" height="20" style="cursor: pointer;" (click)="DescargarPDF(reporte)">
            </td>
            <td style="text-align: center;">
              <mat-progress-spinner *ngIf="downloadingAcuse && acuse_downloading == reporte?.control_consulta" diameter="20" mode="indeterminate"></mat-progress-spinner>
              <img *ngIf="!(downloadingAcuse && acuse_downloading == reporte?.control_consulta)" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/pdf.svg" alt="Ver PDF" width="20" height="20" style="cursor: pointer;" (click)="DescargarPDFAcuseBuro(reporte)">
            </td>
            <td>{{ reporte?.referencia_operador }}</td>
            <td>{{ reporte.fecha_creacion | date : "dd/MM/yyyy" }}</td>
            <td style="text-align: right;">{{ reporte.score }}</td>
            <td>
              {{ reporte?.raw.PrimerNombre }}{{ " "
              }}{{ reporte?.raw.SegundoNombre }}
            </td>
            <td>{{ reporte?.raw.ApellidoPaterno }}</td>
            <td>{{ reporte?.raw.ApellidoMaterno }}</td>
            <td>{{ reporte?.rfc }}</td>
            <td>
              {{ reporte?.raw?.Domicilio?.Direccion1 }}{{ "-"
              }}{{ reporte?.raw?.Domicilio?.Numero }}
            </td>
            <td>{{ reporte?.raw?.Domicilio?.ColoniaPoblacion }}</td>
            <td>{{ reporte?.raw?.Domicilio?.Ciudad }}</td>
            <td>{{ reporte?.raw?.Domicilio?.Estado }}</td>
            <td>Si</td>
            <td>Si</td>
            <td>Cuenta</td>
            <td>{{ reporte?.control_consulta }}</td>
            <td>{{ reporte?.nip ? 'SÍ' : 'NO'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <app-socials-footer style="width: 100%"></app-socials-footer>
</div>
