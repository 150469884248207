<app-sidenav-v2
[shortMenu]="true"
>
<div class="container">
	<div class="top">
		<div class="title"> <app-go-bak></app-go-bak><span> Referencias</span></div>
	</div>
	<main>
		<!-- <div class="titulo">Referencias</div>
		<div class="row-center">
			<div class="tipo-persona active">
				<div *ngIf="typeP == 1">
					SOLICITUD PERSONA FÍSICA
				</div>				  
				<div *ngIf="typeP == 2">
					SOLICITUD PERSONA MORAL
				</div>
			</div>
		</div> -->
		<mat-card >
			<mat-card-content>
				<app-solicitud-r-p1 (parte)="changeForm($event)" *ngIf="parte == 1" [tipoPersona]="typeP"></app-solicitud-r-p1>
				<app-solicitud-r-p2 (parte)="changeForm($event)" *ngIf="parte == 2" [tipoPersona]="typeP"></app-solicitud-r-p2>
				<app-solicitud-r-p3 (parte)="changeForm($event)" *ngIf="parte == 3" [tipoPersona]="typeP"></app-solicitud-r-p3>
			</mat-card-content>
		</mat-card>
	</main>
	<app-socials-footer style="width:100%;"></app-socials-footer>
</div>
</app-sidenav-v2>
