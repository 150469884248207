import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AlertService, AuthenticationService, UserService } from '../../../../_services';
import { SolicitudService } from 'src/app/_services/solicitud.service';

import { customPatterns, emailPattern } from '../../../../_models/maskPattern'
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { ArcusService } from 'src/app/_services/arcus.service';
/* tslint:disable */

@Component({
	selector: 'app-solicitud-dg-p1',
	templateUrl: './solicitudDGp1.component.html',
	styleUrls: ['./../../solicitud.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class SolicitudDGp1Component implements OnInit {
	@Input() solicitud: any
	@Input() tipoPersona: number;
	@Input() esMedico: boolean = false
	@Output() parte = new EventEmitter<number>();
	@Output() getDatosGenerales = new EventEmitter();
	@Output() stepCompletion =new EventEmitter<any>();
	 
	email : string = null;
	DynamoCliente : any ;
	rfc_ClieteDynamo: string= null;

	customPattern = customPatterns
	dgFForm: FormGroup;
	dgMForm: FormGroup;
    loading = false;
    submitted = false;
	returnUrl: string;
	minDate:any
	datosGen:any = {}
	folio = null
	perfil = null
	id_cliente = null
	cliente_nombre = ''
	cliente_apellido_paterno = ''
	cliente_apellido_materno = ''
	cliente_email = ''
	cliente_telefono = ''
	actividades = []
	id_vendor = null
	regimenes = [
		{
			"nombre": "General de Ley Personas Morales",
			"id": "1650398981458x218692573462708760"
		},
		{
			"nombre": "Personas Morales con Fines no Lucrativos",
			"id": "1650398981458x329108365758673700"
		},
		{
			"nombre": "Personas Físicas con Actividades Empresariales y Profesionales",
			"id": "1650398981459x198069605886681100"
		},
		{
			"nombre": "Ingresos por Dividendos (socios y accionistas)",
			"id": "1650398981459x211103908695358120"
		},
		{
			"nombre": "Residentes en el Extranjero sin Establecimiento Permanente en México",
			"id": "1650398981459x437946474161068160"
		},
		{
			"nombre": "Demás ingresos",
			"id": "1650398981459x522021446067531200"
		},
		{
			"nombre": "Consolidación",
			"id": "1650398981459x743341538815614800"
		},
		{
			"nombre": "Arrendamiento",
			"id": "1650398981459x769312061157237900"
		},
		{
			"nombre": "Sueldos y Salarios e Ingresos Asimilados a Salarios",
			"id": "1650398981459x871991104860487800"
		},
		{
			"nombre": "Régimen de Enajenación o Adquisición de Bienes",
			"id": "1650398981459x986648174462116200"
		},
		{
			"nombre": "Ingresos por intereses",
			"id": "1650398981460x423486058288840450"
		},
		{
			"nombre": "Régimen de los ingresos por obtención de premios",
			"id": "1650398981460x801095824257317000"
		},
		{
			"nombre": "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
			"id": "1650398981461x178202637037240030"
		},
		{
			"nombre": "Incorporación Fiscal",
			"id": "1650398981461x194608203056072500"
		},
		{
			"nombre": "Régimen Simplificado de Confianza",
			"id": "1650398981461x283510780211937900"
		},
		{
			"nombre": "Opcional para Grupos de Sociedades",
			"id": "1650398981461x294901337918111550"
		},
		{
			"nombre": "Coordinados",
			"id": "1650398981461x367677311882572700"
		},
		{
			"nombre": "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
			"id": "1650398981461x759229525028249100"
		},
		{
			"nombre": "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
			"id": "1650398981461x852495051636040300"
		},
		{
			"nombre": "Sin obligaciones fiscales",
			"id": "1650398981461x943776525975512200"
		},
		{
			"nombre": "Hidrocarburos",
			"id": "1650398981462x161677904012120800"
		},
		{
			"nombre": "De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales",
			"id": "1650398981462x531689845653666500"
		},
		{
			"nombre": "Enajenación de acciones en bolsa de valores",
			"id": "1650398981462x600817349128310000"
		}
	  ]
    constructor(
        private formBuilder: FormBuilder,
        // private route: ActivatedRoute,
        private router: Router,
        // private authenticationService: AuthenticationService,
        // private alertService: AlertService,
		private solicitudService: SolicitudService,
		private userService:UserService,
		private dialog:MatDialog,
		public arcusService:ArcusService,
		private cdr: ChangeDetectorRef
    ) {
        // redirect to home if already logged in

	}

    async ngOnInit() {
		console.log("_________",this.solicitud)
		console.log("Antes de obtener: ", this.solicitudService.solicitud);
		this.minDate = new Date();
    	this.minDate.setFullYear( this.minDate.getFullYear() - 18 );
     console.log('this.minDate :', this.minDate);
		 this.id_vendor = JSON.parse(localStorage.getItem('client_details')).id_vendor
		this.folio = JSON.parse(localStorage.getItem('client_details')).folio
		this.perfil = JSON.parse(localStorage.getItem('currentUser')).perfil

		let id = 0
		let referencia_dolicitud_datos = null

		if( this.perfil == 1 ){
			console.log("Es un cliente");
			const { IDSolicitud, ReferenciaSolicitudDatos } = this.solicitudService.solicitud
			id = IDSolicitud
			referencia_dolicitud_datos = ReferenciaSolicitudDatos

			const currentUser = JSON.parse(localStorage.getItem("currentUser"))
			const { id_cliente, CotNombreCliene, CotApellidoPaterno, CotApellidoMaterno, EmailCliente, ClienteTelefono  } = currentUser.quatation
			this.cliente_nombre = CotNombreCliene
			this.cliente_apellido_paterno = CotApellidoPaterno
			this.cliente_apellido_materno = CotApellidoMaterno
			this.cliente_telefono = ClienteTelefono
			this.cliente_email = EmailCliente
			this.id_cliente = id_cliente
			id = id_cliente
		}else {
			const { id_cliente, cliente_nombre, cliente_apellido_paterno, cliente_apellido_materno, email, cliente_telefono  } = JSON.parse(localStorage.getItem("client_details"))
			this.cliente_nombre = cliente_nombre
			this.cliente_apellido_paterno = cliente_apellido_paterno
			this.cliente_apellido_materno = cliente_apellido_materno
			this.cliente_telefono = cliente_telefono
			this.cliente_email = email
			this.id_cliente = id_cliente
			id = id_cliente
		}

		const tipo_persona = this.tipoPersona
		const parte = 1

        this.dgFForm = this.formBuilder.group({
            titulo: ['', [Validators.required]],
            nombres: ['' || '', [Validators.required]],
			ap_materno: [''],
			ap_paterno: ['', Validators.required],
			fecha_nac: ['', Validators.required],
			rfc: ['', [Validators.required, Validators.minLength(13)]],
			nacionalidad: ['', Validators.required],
			tel_casa: ['', 
				this.id_vendor === 7228 ? [] : [Validators.required, Validators.minLength(10)]
			],
			tel_cel: ['', [Validators.required, Validators.minLength(10)]],
			sexo: ['', Validators.required],
			estado_civil: ['', Validators.required],
			ocupacion: ['', Validators.required],
			regimen: [''],
			tipo_identificacion: ['Credencial para votar'],
			numero_identificacion: [''],
			// numero_serie: ['', Validators.required],
			curp: ['', [Validators.minLength(18)]],
			correo: ['', [Validators.required, Validators.pattern( emailPattern )]],
			actividad_principal: ['', Validators.required],
			sector: ['', Validators.required],
			regimen_contribucion: ['', Validators.required],
		});
		
		this.dgMForm = this.formBuilder.group({
			razon_social: ['', Validators.required],
			sector: ['', Validators.required],
			actividad_principal: ['', Validators.required],
			monto_linea: ['', Validators.required],
			activo_arrendar: ['', Validators.required],
			pagina: [''],
			telefono: ['',[Validators.required, Validators.minLength(10)]],
			fecha_constitucion: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
			pm_rfc: ['', [Validators.required, Validators.minLength(12)]],
			numero_empleados: ['', Validators.required],
			nombre_director: ['', Validators.required],
			pm_correo: ['', [Validators.required, Validators.pattern( emailPattern )]]
		});

		const guardado = { id_cliente: id, referencia_dolicitud_datos, tipo_persona, parte, folio: this.folio}
		console.log("Guardado: ", guardado);
		
		this.solicitud =  await this.solicitudService.ObtenerSolicitud(this.folio);

		console.log("ESTE ES VALOR DE LA SOLICITUD", this.solicitud)

		this.solicitudService.getDatosGenerales( {guardado} ).subscribe( (res) => {
				console.log("GET: ", res);
				this.email = res.correo;
				this.datosGen = res
				this.setData( res )
				this.arcusService.obtenerOcupaciones().then(res => {
					console.log('aqui');
					
					this.actividades = this.arcusService.CargarActividadesPrincipales(this.f.sector.value)
				})
			}
		)

		

		this.dgFForm.markAllAsTouched ();
		this.dgMForm.markAllAsTouched ();
        // get return url from route parameters or default to '/'
        // this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/dashboard';
    }

    // convenience getter for easy access to form fields
	get f() { return this.dgFForm.controls; }
	get m() { return this.dgMForm.controls; }

	ngOnChanges(changes: SimpleChanges) {
		// const { IDSolicitud: id, ReferenciaSolicitudDatos: referencia_dolicitud_datos } = this.solicitudService.solicitud
		const solicitud = this.solicitudService.getReferencia()
		const tipo_persona = this.tipoPersona
		const parte = 1

		const guardado = { ...solicitud, tipo_persona, parte}
		console.log("Guardado: ", guardado);
		
		this.solicitudService.getDatosGenerales( {guardado} ).subscribe( res => {
			console.log("GET Changes: ", res);
			this.datosGen = res
			this.setData( res )
		}
	)
        
        // You can also use categoryId.previousValue and 
        // categoryId.firstChange for comparing old and new values

		// console.log(changes.tipoPersona.currentValue);
		// if (changes.tipoPersona.currentValue == 1) {
		// 	this.datosGeneralesForm = this.formBuilder.group({
		// 		nombres: ['', [Validators.required]],
		// 		ap_materno: ['', Validators.required],
		// 		ap_paterno: ['', Validators.required],
		// 		fecha_nac: ['', Validators.required],
		// 		rfc: ['', Validators.required],
		// 		nacionalidad: ['', Validators.required],
		// 		tel_casa: ['', Validators.required],
		// 		tel_cel: ['', Validators.required],
		// 		sexo: ['', Validators.required],
		// 		estado_civil: ['', Validators.required],
		// 		ocupacion: ['', Validators.required],
		// 		regimen: ['', Validators.required],
		// 		tipo_identificacion: ['', Validators.required],
		// 		numero_identificacion: ['', Validators.required],
		//		numero_serie: ['', Validators.required],
		// 		curp: ['', Validators.required],
		// 		correo: ['', Validators.required],
		// 	});
		// } else {
		// 	this.datosGeneralesForm = this.formBuilder.group({
		// 		razon_social: ['', Validators.required],
		// 		password: ['', Validators.required],
		// 		sector: ['', Validators.required],
		// 		actividad_principal: ['', Validators.required],
		// 		monto_linea: ['', Validators.required],
		// 		activo_arrendar: ['', Validators.required],
		// 		pagina: ['', Validators.required],
		// 		telefono: ['', Validators.required],
		// 		fecha_constitucion: ['', Validators.required],
		// 		pm_rfc: ['', Validators.required],
		// 		numero_empleados: ['', Validators.required],
		// 		nombre_director: ['', Validators.required],
		// 		pm_correo: ['', Validators.required]
		// 	});
		// }
	}
	
	async getClienteDynamoDB(){
		this.DynamoCliente = await this.solicitudService.ObtenerDatosCliente(this.email || this.cliente_email);
		this.rfc_ClieteDynamo= this.DynamoCliente?.rfc;
	}
    async onSubmit(button: number) {
		//this.parte.emit(2);
		this.dgMForm.markAllAsTouched();
		this.dgFForm.markAllAsTouched();
		if ( !this.userService.alertService.isOnline() ) return
		this.loading = true;
		var data: any;
		this.submitted = true;
		const update:boolean = localStorage.getItem('update') == 'true'

		// if( (this.datosGen.nombres || this.datosGen.razon_social) && button === 2) return this.parte.emit(2) // si ya hay un registro cambiamos de pagina
		// stop here if form is invalid
		if ([1,3].includes(this.tipoPersona)) {
			if (this.dgFForm.invalid) {
				this.loading = false;
				console.log('invalido');
				this.stepCompletion.emit(false);
				this.cdr.detectChanges();
				return;
			}
			data = this.dgFForm.value;
			this.stepCompletion.emit(true);
			this.cdr.detectChanges();
		}else {
			if (this.dgMForm.invalid) {
				this.loading = false;
				console.log(this.dgMForm.value);
				this.stepCompletion.emit(false)
				this.cdr.detectChanges();
				return;
			}
			data = this.dgMForm.value;
			data.monto_linea = Number(data.monto_linea)
			data.numero_empleados = Number(data.numero_empleados)
			this.stepCompletion.emit(true);
			
		}
		data.id_cliente = this.id_cliente
		data.folio = this.folio
		data.es_medico = `${this.esMedico}`
		this.loading = true;
		console.log('before calling api', data);
		const tipo_guardado = (button == 2 && update == false) ? 1 : 1;//&& update == false
		this.SendValues();
		(await this.solicitudService.saveDatosGenerales(data, 1, this.tipoPersona, tipo_guardado)).subscribe(
			async (res: any) => {
				console.log(res);
				// if (res.status <= 300) {
					const { quatation: {Code}} = JSON.parse( localStorage.getItem('currentUser'))
					// this.userService.changeStatus(Code, 1, 1).subscribe(res => res)
					
					this.loading = false;
					// this.alertService.success()
					if ( button === 1 ) { // go to dashboard
						const dialogref = this.dialog.open(MessageBoxComponent, {
							width: "500px",
							data: {
								text: "Éxito",
								text3: "tu información se ha guardado",
								icon: "success",
								type: "success"
							}
						})
						dialogref.afterClosed().subscribe( _=> {
							this.userService.goToDashboard()
						})
					} else { // next form
						
						this.parte.emit(2);
						this.cdr.detectChanges();
					}
				// }
				// this.router.navigateByUrl('/login', { replaceUrl: true } );
			},
			(err: any) => {
				this.loading = false;
				console.log('Error 2 :', err)
				this.dialog.open(MessageBoxComponent, {
					width: "500px",
					data:{
						text2: "No se guardó tu información",
						text3: "Vuelve a intentarlo.",
						type: "error",
						icon: "error"
					}
				})
				//let er = JSON.parse(err);
				
			}
		);


		const contacto = {
            tipoPersona: this.tipoPersona == 1 ? "Persona fisica" : "Persona moral",
            tituloPersona: this.f.titulo.value,
            nombreCompleto: this.f.nombres.value,
            apellidoPaterno: this.f.ap_paterno.value,
            apellidoMaterno: this.f.ap_materno.value,
            nacionalidad: "1595863462706x437395425278820350",
            fechaNacimiento: this.f.fecha_nac.value,
            sectorEconomico: this.f.sector.value,
            actividadPrincipal: this.f.actividad_principal.value,
            genero: this.f.sexo.value == 'M' ? "Hombre" : 'Mujer',
            regimenContribucion: this.f.regimen_contribucion.value,
            tipoIdentificacion: this.f.tipo_identificacion.value,
            numeroIdentificacion: this.f.numero_identificacion.value,
            rfc: this.f.rfc.value,
            email: this.f.correo.value,
        }
        const datosAdicionales ={   
            telCasa: this.f.tel_casa.value,
            telCel: this.f.tel_cel.value,
            estado_civil:this.f.estado_civil.value,
            ocupacion: this.f.ocupacion.value,
            sector: this.f.sector.value
        }
        const datosMoral={      
            sector :this.m.sector.value,
            actividadPrincipal:this.m.actividad_principal.value,
            linea:this.m.monto_linea.value,
            activoArrendar:this.m.activo_arrendar.value,
            pagina:this.m.pagina.value,
            telefono:this.m.telefono.value,
            fechaConstitucion:this.m.fecha_constitucion.value,
            pmrfc:this.m.pm_rfc.value,
            numeroEmpleados:this.m.numero_empleados.value,
            nombreDirector:this.m.nombre_director.value,
            pmCorreo:this.m.pm_correo.value
        }
		const persona = {
			birthday: this.f.fecha_nac.value,
			email: this.f.correo.value,
			first_lastname: this.f.ap_paterno.value,
			rfc: this.f.rfc.value,
			second_lastname: this.f.ap_materno.value,
		  };

        const infoComplementaria = this.tipoPersona==1?datosAdicionales:datosMoral;

        console.log(`Guardando solicitud: ${this.folio}`);
        localStorage.setItem('estadoCivil', datosAdicionales.estado_civil);

        console.log("Valores a Guardar Dynamo: ",contacto," Valor DATO:",datosAdicionales)
        
        await this.solicitudService.GuardarSolicitud(this.folio, {contacto,infoComplementaria})
		
		/*
		console.log("this.DynamoCliente.rfc",this.rfc_ClieteDynamo);
		await this.solicitudService.GuardarCliente(this.email || this.cliente_email, this.rfc_ClieteDynamo, {
			birthday: this.f.fecha_nac.value,
			email: this.f.correo.value,
			first_lastname: this.f.ap_paterno.value,
			rfc: this.f.rfc.value,
			second_lastname: this.f.ap_materno.value,
		  })*/
	}
	
	changePart(part: number){
		this.parte.emit(part);
		this.cdr.detectChanges();
	}

	ValidarFechaConstitucion(){
		console.log("Validando");
		if( this.m.fecha_constitucion.value.length != 10 ) {
			console.log("Fecha no valdia");
			return this.m.fecha_constitucion.setErrors({invalid: true})
		}
		const hoy = new Date()
		const fecha = new Date(this.m.fecha_constitucion.value)
		const diff = Math.floor(( hoy.getTime() - fecha.getTime() ) / 86400000 / 365)
		if( diff < 2 ){
			return this.m.fecha_constitucion.setErrors({invalid: true})
		}
		
	}

	async setData( res ){
		await this.getClienteDynamoDB();
		console.log('SETDATA::RES:::',res);
		console.log('SETDATA::Solicitud:::',this.solicitud);
		console.log("***Datos Cliente Dynamo***",this.DynamoCliente);
		
		console.log('SETDATA::Contacto:::',this.solicitud.contacto);
		this.cdr.detectChanges();
		
		if( this.tipoPersona == 1 ){
			this.f.nombres.setValue( this.cliente_nombre || this.solicitud.contacto?.nombreCompleto || this.DynamoCliente?.first_name)
			this.f.ap_materno.setValue( this.cliente_apellido_materno || this.solicitud.contacto?.apellidoMaterno|| this.cliente_apellido_materno ||this.DynamoCliente?.second_name)
			this.f.ap_paterno.setValue( this.cliente_apellido_paterno || this.solicitud.contacto?.apellidoPaterno || this.cliente_apellido_paterno || this.DynamoCliente?.first_lastname)
			this.f.fecha_nac.setValue( this.solicitud.contacto?.fechaNacimiento || this.DynamoCliente?.birthday.split('/').reverse().join('-'))
			this.f.rfc.setValue( this.solicitud.contacto?.rfc || this.DynamoCliente?.rfc)
			this.f.nacionalidad.setValue( res.nacionalidad )
			this.f.tel_casa.setValue( res.tel_casa )
			this.f.tel_cel.setValue( res.tel_cel || this.cliente_telefono )
			this.f.sexo.setValue( res.sexo )
			this.f.estado_civil.setValue( res.estado_civil )
			this.f.ocupacion.setValue( res.ocupacion || 'Médico' )
			this.f.regimen.setValue( res.regimen )
			this.f.tipo_identificacion.setValue( res.tipo_identificacion )
			this.f.numero_identificacion.setValue( res.numero_identificacion )
			// // this.f.numero_serie.setValue( res.numero_serie )
			this.f.curp.setValue( res.curp )
			this.f.correo.setValue( res.correo || this.cliente_email || this.DynamoCliente?.email)
			this.f.actividad_principal.setValue( res.actividad_principal || '')
			this.f.sector.setValue( res.sector || '')
			this.f.titulo.setValue( this.solicitud.contacto?.tituloPersona || '')
			this.f.regimen_contribucion.setValue( this.solicitud.contacto?.regimenContribucion || '')
		}else {
			this.m.razon_social.setValue(res.razon_social)
			this.m.sector.setValue(res.sector)
			this.m.actividad_principal.setValue(res.actividad_principal)
			this.m.monto_linea.setValue(res.monto_linea)
			this.m.activo_arrendar.setValue(res.activo_arrendar)
			this.m.pagina.setValue(res.pagina)
			this.m.telefono.setValue(res.telefono || this.cliente_telefono)
			this.m.fecha_constitucion.setValue(res.fecha_constitucion)
			this.m.pm_rfc.setValue(res.pm_rfc)
			this.m.numero_empleados.setValue(res.numero_empleados)
			this.m.nombre_director.setValue(res.nombre_director)
			this.m.pm_correo.setValue(res.pm_correo || this.cliente_email)
		}
		this.SendValues()
	}

	ChangeActividad() {
		console.log('ChangeActividad');
		
		console.log(this.f.sector.value);
		this.actividades = this.arcusService.CargarActividadesPrincipales(this.f.sector.value)
	}

	SendValues() {
		this.getDatosGenerales.emit(this.dgFForm.getRawValue())
	}	
	
}
