import { Component, OnInit } from '@angular/core';
import { AuthenticationService, UserService } from 'src/app/_services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-comite',
  templateUrl: './dashboard-comite.component.html',
  styleUrls: ['./dashboard-comite.component.scss']
})
export class DashboardComiteComponent implements OnInit {
  tramites:any = []
  tramites_filtrados: any []

  constructor(private authenticationService:AuthenticationService,
    private router:Router,
    private userService:UserService) { }

  ngOnInit(): void {
    this.CargarStatus()
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  CargarStatus(){
    this.userService.getListStatus({comite: true}).subscribe( tramites => {      
      this.tramites = tramites
      this.Filtrar('')
    })
  }

  Filtrar(word){
    console.log(this.tramites);
    this.tramites_filtrados = this.tramites.filter( tramite => {
      return tramite.folio.includes(word) || 
             tramite.cliente.toLowerCase().includes(word.toLowerCase()) || 
             tramite.activo_nombre.toLowerCase().includes(word.toLowerCase())
    })
  }

  VerDetalles(folio) {
    console.log(folio);
    
    const cliente = this.tramites.filter( tramite => tramite.folio == folio )
    localStorage.setItem("client_details", JSON.stringify( cliente[0] ) )
    this.router.navigateByUrl(`dashboard/comite/${folio}`)
    
  }
}
