import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { AuthGuard } from './_guards';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { CodeActivationComponent } from './pages/code-activation/code-activation.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DashboardComercialComponent } from './pages/dashboard-comercial/dashboard-comercial.component';
import { DashboardComiteComponent } from './pages/dashboard-comite/dashboard-comite.component';
import { EstatusTramiteComponent } from './pages/comercial/estatus-tramite/estatus-tramite.component';
import { DashboardAdministradorComponent } from './pages/dashboard-administrador/dashboard-administrador.component';
import { CrearPerfilComponent } from './pages/crear-perfil/crear-perfil.component';
import { ParametrosSistemaComponent } from './pages/parametros-sistema/parametros-sistema.component';
import { SolicitudDGComponent } from './pages/solicitud-datos-generales/solicitud.component';
import { SolicitudComponent } from './pages/solicitud/solicitud.component';
import { EntrevistaComponent } from './pages/valuacion/entrevista/entrevista.component';
import { SolicitudRComponent } from './pages/solicitud-referencias/solicitudR.component';
import { SolicitudAvalComponent } from './pages/solicitud-aval/solicitudAval.component';
import { AnalisisComponent } from './pages/valuacion/analisis/analisis.component';
import { ComentariosComponent } from './pages/valuacion/comentarios/comentarios.component';
import { CaratulaComponent } from './pages/valuacion/caratula/caratula.component';
import { MinutaComponent } from './pages/valuacion/minuta/minuta.component';
import { CartasClienteComponent } from './pages/valuacion/cartas-cliente/cartas-cliente.component';

import { ActualizarAvalComponent } from './pages/actualizar-aval/actualizar-aval.component';

import { CargaDocumentosComponent } from './pages/carga-documentos/carga-documentos.component';
import { VerDocumentoComponent } from './pages/ver-documento/ver-documento.component';
import { VerCotizacionesComponent } from './pages/ver-cotizaciones/ver-cotizaciones.component';
import { DetalleCotizacionComponent } from './pages/detalle-cotizacion/detalle-cotizacion.component';
import { HistorialCotizacionesComponent } from './pages/historial-cotizaciones/historial-cotizaciones.component';
import { VerTramitesComponent } from './pages/ver-tramites/ver-tramites.component';
import { ConfirmarCotizacionComponent } from './pages/confirmar-cotizacion/confirmar-cotizacion.component';
import { SeleccionarPlazoComponent } from './pages/confirmar-cotizacion/seleccionar-plazo/seleccionar-plazo.component';
import { ValidacionIdentidadComponent } from './pages/valuacion/validacion-identidad/validacion-identidad.component';
import { ComercialConfirmarCotizacionComponent } from './pages/comercial-confirmar-cotizacion/comercial-confirmar-cotizacion.component';
import { from } from 'rxjs';
import { CrearCotizacionComponent } from './pages/crear-cotizacion/crear-cotizacion.component';
import { VerCotizacionCreadaComponent } from './pages/crear-cotizacion/ver-cotizacion-creada/ver-cotizacion-creada.component';
import { ActualizarInformacionComponent } from './pages/actualizar-informacion/actualizar-informacion.component';
import { ActualizarCapacidadPagoComponent } from './pages/actualizar-capacidad-pago/actualizar-capacidad-pago.component';
import { ConsultaBuroComponent } from './pages/valuacion/consulta-buro/consulta-buro.component';
import { MainDashboardComponent } from './pages/main-dashboard/main-dashboard.component';
import { ConsultaSolicitudComponent } from './pages/consulta-solicitud/consulta-solicitud.component';
import { ReasignacionComponent } from './pages/reasignacion/reasignacion.component';
import { ReasignacionSelectComponent } from './pages/reasignacion-select/reasignacion-select.component';
import { CrudProductoComponent } from './pages/productos/crud-producto/crud-producto.component';
import { AdminComponent } from './pages/productos/admin/admin.component';
import { VendorsListComponent } from './pages/vendors/vendors-list/vendors-list.component';
import { VendorsEditComponent } from './pages/vendors/vendors-edit/vendors-edit.component';
import { FirmaDigitalComponent } from './pages/firma-digital/firma-digital.component';
import { PagenotfoundComponent } from './pages/firma-digital/pagenotfound/pagenotfound.component';
import { PreAprobadoComponent } from './pages/mejorar-cotizacion/pre-aprobado/pre-aprobado.component';
import { MejorarCotizacionComponent } from './pages/mejorar-cotizacion/mejorar-cotizacion.component';
import { ActualizarReferenciasComponent } from './pages/actualizar-referencias/actualizar-referencias.component';
import { CalculoTIRComponent } from './pages/calculo-tir/calculo-tir.component';
import { PromocionesComponent } from './pages/promociones/promociones.component';
import { CrearPromocionComponent } from './pages/promociones/crear-promocion/crear-promocion.component';
import { ReporteBuroComponent } from './pages/reporte-buro/reporte-buro.component';
import { VendedorEditComponent } from './pages/vendors/vendedores/vendedor-edit/vendedor-edit.component';
import { CotizadorAutoComponent } from './pages/catalogos/cotizador-auto/cotizador-auto.component';
import { ValuacionPMComponent } from './pages/valuacion_PM/valuacion-pm.component';
import { SolicitudesDashboardComponent } from './pages/main-dashboard/solicitudes-dashboard/solicitudes-dashboard.component';
import { TablaPipelinesComponent } from './pages/tabla-pipelines/tabla-pipelines.component';
import { ValuacionComponent } from './pages/valuacion/valuacion.component';
import { RegisterClientComponent } from './pages/nueva-solicitud/register-client/register-client.component';
import { NuevaSolicitudComponent } from './pages/nueva-solicitud/nueva-solicitud.component';
import { AdminActivosComponent } from './pages/activos/admin-activos/admin-activos.component';
import { VistasPreviasComponent } from './pages/vistas-previas/vistas-previas.component';
import { DashboardGuardGuard } from './_guards/dashboard-guard.guard';
import { CarteraComponent } from './pages/cartera/cartera.component';
import { ReferidosComponent } from './pages/referidos/referidos.component';

const routes: Routes = [
  // { path: '', component: HomeComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/comercial', component: DashboardComercialComponent , canActivate: [AuthGuard]},
  { path: 'dashboard/elegir_cotizacion', component: ConfirmarCotizacionComponent, canActivate: [AuthGuard]},
  { path: 'dashboard/confirmar_cotizacion', component: SeleccionarPlazoComponent, canActivate: [AuthGuard]},
  { path: 'dashboard/comercial/validacionId', component: ValidacionIdentidadComponent, canActivate: [AuthGuard]},
  { path: 'dashboard/comercial/confirmar_cotizacion', component: ComercialConfirmarCotizacionComponent, canActivate: [AuthGuard]},
  { path: 'dashboard/comercial/:folio', component: EstatusTramiteComponent, canActivate: [AuthGuard]},
  { path: 'dashboard/ver_cotizaciones', component: VerCotizacionesComponent, canActivate: [AuthGuard]},
  { path: 'dashboard/detalle_cotizacion', component: DetalleCotizacionComponent, canActivate: [AuthGuard]},
  { path: 'dashboard/comite', component: DashboardComiteComponent , canActivate: [AuthGuard]},
  { path: 'dashboard/comite/:folio', component: EstatusTramiteComponent, canActivate: [AuthGuard]},
  { path: 'dashboard/comite/valuacion/consultaBuro/:tipoPersona', component: ConsultaBuroComponent, canActivate: [AuthGuard]},
  { path: 'dashboard/comite/valuacion/validacionId', component: ValidacionIdentidadComponent, canActivate: [AuthGuard]},
  { path: 'dashboard/comite/valuacion/entrevista', component: EntrevistaComponent, canActivate: [AuthGuard]},
  { path: 'dashboard/comite/valuacion/analisis', component: AnalisisComponent, canActivate: [AuthGuard]},
  { path: 'dashboard/comite/valuacion/comentarios', component: ComentariosComponent, canActivate: [AuthGuard]},
  { path: 'dashboard/comite/valuacion/caratula', component: CaratulaComponent, canActivate: [AuthGuard]},
  { path: 'dashboard/comite/valuacion/minuta', component: MinutaComponent, canActivate: [AuthGuard]},
  { path: 'dashboard/comite/valuacion/cartas', component: CartasClienteComponent, canActivate: [AuthGuard]},
  { path: 'dashboard/comite/valuacion/valuacionPM', component: ValuacionPMComponent, canActivate: [AuthGuard]},
  { path: 'dashboard/administrador/parametros', component: ParametrosSistemaComponent , canActivate: [AuthGuard]},
  { path: 'dashboard/administrador/reporte_buro', component: ReporteBuroComponent , canActivate: [AuthGuard]},
  { path: 'dashboard/administrador/crearperfil', component: CrearPerfilComponent , canActivate: [AuthGuard]},
  { path: 'dashboard/administrador/reasignar', component: ReasignacionComponent , canActivate: [AuthGuard]},
  { path: 'dashboard/administrador', component:  DashboardAdministradorComponent , canActivate: [AuthGuard]},
  { path: 'dashboard/cliente/:folio/carga_documentos', component: CargaDocumentosComponent, canActivate: [AuthGuard]},
  { path: 'dashboard/cliente/:folio/ver_documento', component: VerDocumentoComponent, canActivate: [AuthGuard]},
  { path: 'dashboard/cliente/:folio', component: EstatusTramiteComponent, canActivate: [AuthGuard]},
  { path: 'dashboard/historial_cotizaciones', component: HistorialCotizacionesComponent,  canActivate: [AuthGuard]},
  { path: 'dashboard/ver_tramites', component: VerTramitesComponent, canActivate: [AuthGuard]},
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent , },
  { path: 'changePassword', component: ChangePasswordComponent , canActivate: [AuthGuard]},
  { path: 'codeActivation', component: CodeActivationComponent , canActivate: [AuthGuard]},
  { path: 'codeActivation/:nuevo', component: CodeActivationComponent , canActivate: [AuthGuard]},
  { path: 'reset', component: ResetPasswordComponent},
  { path: 'consulta_solicitud', component: ConsultaSolicitudComponent , canActivate: [AuthGuard]},
  { path: 'solicitud/dg', component: SolicitudDGComponent , canActivate: [AuthGuard]},
  { path: 'solicitud/referencias', component: SolicitudRComponent , canActivate: [AuthGuard]},
  { path: 'solicitud/aval', component: SolicitudAvalComponent , canActivate: [AuthGuard]},
  { path: 'solicitud', component: SolicitudComponent , canActivate: [AuthGuard]},
  { path: 'crear_cotizacion', component: CrearCotizacionComponent, canActivate: [AuthGuard]},
  { path: 'crear_cotizacion/ver_cotizacion', component: VerCotizacionCreadaComponent,  canActivate: [AuthGuard]},
  { path: 'crear_cotizacion/:folio', component: CrearCotizacionComponent,  canActivate: [AuthGuard] },
  { path: 'actualizar_informacion', component: ActualizarInformacionComponent , canActivate: [AuthGuard]},
  { path: 'actualizar_informacion/actualizar_aval', component: ActualizarAvalComponent, canActivate: [AuthGuard]},
  { path: 'actualizar_informacion/capacidad_pago', component: ActualizarCapacidadPagoComponent , canActivate: [AuthGuard]},
  {path: 'actualizar_informacion/actualizar_referencias', component: ActualizarReferenciasComponent, canActivate: [AuthGuard]},
  // { path: 'dashboard/principal', component: MainDashboardComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/solicitudes', component: SolicitudesDashboardComponent, canActivate: [AuthGuard, DashboardGuardGuard] },
  { path: 'productos/crear', component: CrudProductoComponent , canActivate: [AuthGuard]},
  { path: 'productos/editar', component: CrudProductoComponent, canActivate: [AuthGuard] },
  { path: 'productos/admin', component: AdminComponent,  canActivate: [AuthGuard] },
  { path: 'reasignar/select/:tipo', component: ReasignacionSelectComponent , canActivate: [AuthGuard]},
  { path: 'vendors', component: VendorsListComponent , canActivate: [AuthGuard]},
  { path: 'vendors/editar', component: VendorsEditComponent , canActivate: [AuthGuard]},
  { path: 'vendedor/editar', component: VendedorEditComponent , canActivate: [AuthGuard]},
  {path:'perfil/:id', component: FirmaDigitalComponent},
  {path:'perfilnotfound', component: PagenotfoundComponent},
  {path: 'mejorar_cotizacion/pre-aprobado', component: PreAprobadoComponent, },
  { path: 'mejorar_cotizacion', component: MejorarCotizacionComponent},
  { path: 'detalle_solicitud', component: CalculoTIRComponent, canActivate: [AuthGuard]},
  { path: 'detalle_solicitud/:folio', component: CalculoTIRComponent, canActivate: [AuthGuard]},
  { path: 'promociones', component: PromocionesComponent, canActivate: [AuthGuard]},
  { path: 'promociones/crear', component: CrearPromocionComponent, canActivate: [AuthGuard]},
  { path: 'promociones/editar', component: CrearPromocionComponent, canActivate: [AuthGuard]},
  { path: 'dashboard/catalogos/cotizador', component: CotizadorAutoComponent, canActivate: [AuthGuard]},
  { path: 'dashboard/comite/valuacion/valuacionPF', component: ValuacionComponent, canActivate: [AuthGuard]},
  { path: 'nueva_solicitud/registro', component: RegisterClientComponent,},
  { path: 'nueva_solicitud', component: NuevaSolicitudComponent,},
  { path: 'admin/activos/:folio', component: AdminActivosComponent,canActivate: [AuthGuard]},
  { path: 'dashboard/cartera', component: CarteraComponent,canActivate: [AuthGuard]},
  { path: 'dashboard/vistas_previas', component: VistasPreviasComponent ,canActivate: [AuthGuard]},
  { path: 'referidos', component: ReferidosComponent, canActivate: [AuthGuard] },



  // Pipelines
  // { path: 'dashboard/metricas/tablas', component: TablaPipelinesComponent, canActivate: [AuthGuard]},


  // otherwise redirect to home
  { path: '**', redirectTo: 'dashboard/solicitudes' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
