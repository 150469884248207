import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-select-validation',
  templateUrl: './select-validation.component.html',
  styleUrls: ['./select-validation.component.scss']
})
export class SelectValidationComponent implements OnInit {
  @Input() options: { label: string, value: any }[] = [];
  @Input() formControl: FormControl;
  @Input() formGroup: FormGroup;
  @Input() controlName: string;
  @Input() label: any 
  @Input() isError: boolean = false
  @Input() errorMessage: string = '' 
  @Input() mask: string

  validIcon: string = '../../../../assets/icons/new-icons/check-input.svg';
  errorIcon: string = '../../../../assets/icons/new-icons/invalid-input.svg';

  constructor() { }

  ngOnInit(): void {
  }

  getFormControl(controlName: string): FormControl | null {
    if (this.formControl) {
      return this.formControl;
    } else if (this.formGroup && controlName) {
      return this.formGroup.get(controlName) as FormControl;
    } else {
      return null;
    }
  }
  

}
