<div class="container-countdown">
  <button [disabled]="begin_count" (click)="startCountdown()" class="btn-secondary" >
    {{
        !begin_count
        ? "Solicitar un código ahora"
        : "Volver a solicitar un código en: "
    }}
  </button>
  <span *ngIf="timeLeft > 0" [ngClass]="{'disabled-span': begin_count}">({{ !begin_count ? '' : formatTime(timeLeft) }})</span>
</div>
