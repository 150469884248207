
<!-- <div class="progress">
	<div class="progress-left active" (click)="changePart(1)"></div>
	<div class="progress-mid" (click)="changePart(2)"></div>
	<div class="progress-right" (click)="changePart(3)"></div>
</div> -->

<form *ngIf="tipoPersona == 1" [formGroup]="dgFForm" >
	<div class="datos_generales_1">
		<div style="text-align: center; height: 50px;">
			<span class="seccion">1</span>
		</div>
		<div>
			<mat-form-field appearance="outline">
			<mat-label>Titúlo</mat-label>
			<mat-select formControlName="titulo" 
			[value]="datosGen.titulo">
				<mat-option [value]="'Sr.'">Sr.</mat-option>
				<mat-option [value]="'Sra.'">Sra.</mat-option>
				<mat-option [value]="'Ing.'">Ing.</mat-option>
				<mat-option [value]="'Lic.'">Lic.</mat-option>
				<mat-option [value]="'Dr.'">Dr.</mat-option>
				<mat-option [value]="'Dra.'">Dra.</mat-option>
			</mat-select>
		</mat-form-field>
			<mat-error *ngIf="submitted && f.nombres.errors" class="invalid-feedback">
				<div *ngIf="f.nombres.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>
		<div>
			<mat-form-field appearance="outline">
			<mat-label>Nombre (s)</mat-label>
				<input matInput 
				[specialCharacters]="['space']"
				[patterns]="customPattern"
				mask="S{100}"
					formControlName="nombres" 
					[value]="datosGen.nombres"
					[ngClass]="{ 'is-invalid': submitted && f.nombres.errors }"
					placeholder="Como aparece en el I.N.E"
				>
			</mat-form-field>
			<mat-error *ngIf="submitted && f.nombres.errors" class="invalid-feedback">
				<div *ngIf="f.nombres.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<div>
			<mat-form-field appearance="outline">
			<mat-label>Apellido Paterno</mat-label>
				<input  matInput
				[specialCharacters]="['space']"
				[patterns]="customPattern"
				mask="S{100}"
					formControlName="ap_paterno" 
					[value]="datosGen.ap_paterno"
					[ngClass]="{ 'is-invalid': submitted && f.ap_paterno.errors }"
					placeholder="Como aparece en el I.N.E"
				>
		</mat-form-field>
			<mat-error *ngIf="submitted && f.ap_paterno.errors" class="invalid-feedback">
				<div *ngIf="f.ap_paterno.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<div>
			<mat-form-field appearance="outline">
				<mat-label>Apellido Materno</mat-label>
				<input  matInput
				[specialCharacters]="['space']"
				[patterns]="customPattern"
				mask="S{100}"
					formControlName="ap_materno" 
					[value]="datosGen.ap_materno"
					[ngClass]="{ 'is-invalid': submitted && f.ap_materno.errors }"
					placeholder="Como aparece en el I.N.E"
				>
		</mat-form-field>
			<mat-error *ngIf="submitted && f.ap_materno.errors" class="invalid-feedback">
				<div *ngIf="f.ap_materno.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<div>
			<mat-form-field appearance="outline">
				<mat-label>Fecha de nacimiento</mat-label>
				<input  matInput
					formControlName="fecha_nac" 
					[value]="datosGen.fecha_nac"
					[ngClass]="{ 'is-invalid': submitted && f.fecha_nac.errors }"
					type="date"
					max="{{minDate | date: 'yyyy-MM-dd'}}"
				>
		</mat-form-field>

			<!-- <input
			id="myDate"
			formControlName="fecha_nac" 
			[value]="datosGen.fecha_nac"
			[ngClass]="{ 'is-invalid': submitted && f.fecha_nac.errors }"
			type="date"
			max="{{minDate | date: 'yyyy-MM-dd'}}"
		> -->
			<mat-error *ngIf="submitted && f.fecha_nac.errors" class="invalid-feedback">
				<div *ngIf="f.fecha_nac.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<div>
			<mat-form-field appearance="outline">
			<mat-label>R.F.C.</mat-label>
				<input  matInput
					mask="SSSS000000 AAA"
					formControlName="rfc" 
					[value]="datosGen.rfc"
					[ngClass]="{ 'is-invalid': submitted && !f.rfc.valid }"
				>
			</mat-form-field>
			<mat-error *ngIf="submitted && !f.rfc.valid" class="invalid-feedback">
				<div *ngIf="!f.rfc.valid">Campo obligatorio</div>
			</mat-error>
		</div>
		<div>
			<mat-form-field appearance="outline">
			<mat-label>CURP</mat-label>
			<input matInput
			placeholder="Ingresa Curp"
			mask="AAAA000000AAAAAA00"
				formControlName="curp" 
				[value]="datosGen.curp"
				[ngClass]="{ 'is-invalid': submitted && !f.curp.valid }"
			>
			</mat-form-field>
			<mat-error *ngIf="submitted && !f.curp.valid" class="invalid-feedback">
				<div *ngIf="!f.curp.valid">Campo obligatorio</div>
			</mat-error>
		</div>

		<!-- <div>
			<mat-form-field appearance="outline">
			<mat-label>Ocupación</mat-label>
			<mat-select formControlName="ocupacion" >
				<mat-option [value]="'Médico'" default>Médico</mat-option>
				<mat-option [value]="'Otro'">Otro</mat-option>
			</mat-select>
		</mat-form-field> -->
			<!-- <input  
			[specialCharacters]="['space']"
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="ocupacion" 
				[value]="datosGen.ocupacion"
				[ngClass]="{ 'is-invalid': submitted && !f.ocupacion.valid }"
			>
			<mat-error *ngIf="submitted && !f.ocupacion.valid" class="invalid-feedback">
				<div *ngIf="!f.ocupacion.valid">Campo obligatorio</div>
			</mat-error> -->
		<!-- </div> -->

		<div>
			<mat-form-field appearance="outline">
				<mat-label>Nacionalidad</mat-label>
				<mat-select formControlName="nacionalidad" [ngClass]="{ 'is-invalid': submitted && f.nacionalidad.errors }">
					<mat-option [value]="'Mexicana'">Mexicana</mat-option>
				</mat-select>
			</mat-form-field>
			<!-- <input  
			[specialCharacters]="['space']"
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="nacionalidad" 
				[value]="datosGen.nacionalidad"
				[ngClass]="{ 'is-invalid': submitted && f.nacionalidad.errors }"
			> -->
			<mat-error *ngIf="submitted && f.nacionalidad.errors" class="invalid-feedback">
				<div *ngIf="f.nacionalidad.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<!-- <div *ngIf="id_vendor != 7228">
			<mat-form-field appearance="outline">
			<mat-label>Teléfono casa</mat-label>
			<input  matInput
				mask="00 0000 0000"
				formControlName="tel_casa" 
				[value]="datosGen.tel_casa"
				[ngClass]="{ 'is-invalid': submitted && !f.tel_casa.valid }"
			>
			</mat-form-field>
			<mat-error *ngIf="submitted && !f.tel_casa.valid" class="invalid-feedback">
				<div *ngIf="!f.tel_casa.valid">Campo obligatorio</div>
			</mat-error>
		</div> -->

		<div>
			<mat-form-field appearance="outline">
			<mat-label>Teléfono Móvil</mat-label>
			<input  matInput
				mask="00 0000 0000"
				formControlName="tel_cel" 
				[value]="datosGen.tel_cel"
				[ngClass]="{ 'is-invalid': submitted && !f.tel_cel.valid }"
			>
			</mat-form-field>
			<mat-error *ngIf="submitted && !f.tel_cel.valid" class="invalid-feedback">
				<div *ngIf="!f.tel_cel.valid">Campo obligatorio</div>
			</mat-error>
		</div>
	</div>

	<div class="datos_generales_2">
		<div style="text-align: center; height: 50px;">
			<span class="seccion">2</span>
		</div>
		<div>
			<mat-label>Género</mat-label>
			<mat-radio-group formControlName="sexo" [value]="datosGen.sexo" name="select">
				<mat-radio-button labelPosition="before" value="M">Masculino</mat-radio-button>
				<mat-radio-button labelPosition="before" value="F">Femenino</mat-radio-button>
			</mat-radio-group>
			<!-- <select name="select" [value]="datosGen.sexo"
				formControlName="sexo" >
				<option value="F">Femenino</option> 
				<option value="M">Masculino</option>
				<option value="O">Prefiero omitir elección</option>
			</select> -->
			<mat-error *ngIf="submitted && f.sexo.errors" class="invalid-feedback">
				<div *ngIf="f.sexo.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<!-- <div>
			<mat-label>Estado civil</mat-label>
			<mat-radio-group 
				formControlName="estado_civil" 
				[value]="datosGen.estado_civil"
				[ngClass]="{ 'is-invalid': submitted && f.estado_civil.errors }">
				<mat-radio-button labelPosition="before" value="SOLTERO">Soltero</mat-radio-button>
				<mat-radio-button labelPosition="before" value="CASADO">Casado</mat-radio-button>
			</mat-radio-group> -->
			<!-- <input  
				[patterns]="customPattern"
				mask="S{100}"
				formControlName="estado_civil" 
				[value]="datosGen.estado_civil"
				[ngClass]="{ 'is-invalid': submitted && f.estado_civil.errors }"
			> -->
			<!-- <mat-error *ngIf="submitted && f.estado_civil.errors" class="invalid-feedback">
				<div *ngIf="f.estado_civil.errors.required">Campo obligatorio</div>
			</mat-error>
		</div> -->

		<!-- <div *ngIf="f.estado_civil.value == 'CASADO'">
			<mat-form-field appearance="outline">
			<mat-label>Régimen</mat-label>
			<input  matInput
				placeholder="Solo casados"
				[specialCharacters]="['space']"
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="regimen" 
				[value]="datosGen.regimen"
				[ngClass]="{ 'is-invalid': submitted && f.regimen.errors }"
			>
			</mat-form-field>
			<mat-error *ngIf="submitted && f.regimen.errors" class="invalid-feedback">
				<div *ngIf="f.regimen.errors.required">Campo obligatorio</div>
			</mat-error>
		</div> -->

		<div *ngIf="false">
			<mat-form-field appearance="outline">
			<mat-label>Tipo identificacion</mat-label>
			<mat-radio-group 
				formControlName="tipo_identificacion" 
				[ngClass]="{ 'is-invalid': submitted && f.tipo_identificacion.errors }">
				<mat-radio-button labelPosition="before" value="INE">I.N.E.</mat-radio-button>
				<mat-radio-button labelPosition="before" value="PASAPORTE">Pasaporte</mat-radio-button>
				<mat-radio-button labelPosition="before" value="CEDULA">Cédula</mat-radio-button>
				<mat-radio-button labelPosition="before" value="FM2">F.M. 2</mat-radio-button>
			</mat-radio-group>
		</mat-form-field>
			<!-- <input  
				formControlName="tipo_identificacion" 
				[value]="datosGen.tipo_identificacion"
				[ngClass]="{ 'is-invalid': submitted && f.tipo_identificacion.errors }"
			> -->
			<mat-error *ngIf="submitted && f.tipo_identificacion.errors" class="invalid-feedback">
				<div *ngIf="f.tipo_identificacion.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<div *ngIf="false">
			<mat-form-field appearance="outline">
			<mat-label>Número de identificación</mat-label>
			<input  
			mask="A{100}"
				formControlName="numero_identificacion" 
				[value]="datosGen.numero_identificacion"
				[ngClass]="{ 'is-invalid': submitted && f.numero_identificacion.errors }"
			>
		</mat-form-field>
			<mat-error *ngIf="submitted && f.numero_identificacion.errors" class="invalid-feedback">
				<div *ngIf="f.numero_identificacion.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<!-- <div>
			<mat-label>Número de serie F.E.A.</mat-label>
			<input 
			placeholder="Firma Electrónica Avanzada"
				mask="A{100}"
				formControlName="numero_serie" 
				[value]="datosGen.numero_serie"
				[ngClass]="{ 'is-invalid': submitted && f.numero_serie.errors }"
			>
			<mat-error *ngIf="submitted && f.numero_serie.errors" class="invalid-feedback">
				<div *ngIf="f.numero_serie.errors.required">Campo obligatorio</div>
			</mat-error>
		</div> -->

		<div *ngIf="false">
			<mat-label>C.U.R.P.</mat-label>
			<input  
				mask="SSSS000000SSSAAAAA"
				formControlName="curp" 
				[value]="datosGen.curp"
				[ngClass]="{ 'is-invalid': submitted && !f.curp.valid }"
			>
			<mat-error *ngIf="submitted && !f.curp.valid" class="invalid-feedback">
				<div *ngIf="!f.curp.valid">Campo obligatorio</div>
			</mat-error>
		</div>

		<div>
			<mat-form-field appearance="outline">
			<mat-label>Correo electrónico para facturas</mat-label>
			<input  matInput
			placeholder="@"
				type="email"
				formControlName="correo" 
				[value]="datosGen.correo"
				[ngClass]="{ 'is-invalid': submitted && !f.correo.valid }"
			>
			</mat-form-field>
			<mat-error *ngIf="submitted && !f.correo.valid" class="invalid-feedback">
				<div *ngIf="!f.correo.valid">Campo obligatorio</div>
			</mat-error>
		</div>

		<div>
			<mat-form-field appearance="outline" *ngIf="arcusService.sectoresEconomicos.length">
				<mat-label>Sector económico</mat-label>
				<mat-select  formControlName="sector" (selectionChange)="ChangeActividad()"> 
					<mat-option value="">Seleciona el sector económico</mat-option>
					<mat-option *ngFor="let ocupacion of arcusService.sectoresEconomicos" [value]="ocupacion">{{ocupacion}}</mat-option> 
				</mat-select>
			</mat-form-field>
			<!-- <input 
			[specialCharacters]="['space']"
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="sector" 
				[value]="datosGen.sector"
				[ngClass]="{ 'is-invalid': submitted && m.sector.errors }"
			> -->
			<mat-error *ngIf="submitted && f.sector.errors" class="invalid-feedback">
				<div *ngIf="f.sector.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<div>
			<mat-form-field appearance="outline" *ngIf="actividades.length" >
				<mat-label>Actividad principal de la empresa</mat-label>
				<mat-select formControlName="actividad_principal">
					<!-- <mat-option value="">Seleciona una actividad principal</mat-option> -->
					<mat-option *ngFor="let actividad of actividades" [value]="actividad.actividadPrincipal">{{actividad.actividadPrincipal}}</mat-option>
				</mat-select>
			</mat-form-field>
			<!-- <input 
			[specialCharacters]="['space']"
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="actividad_principal" 
				[value]="datosGen.actividad_principal"
				[ngClass]="{ 'is-invalid': submitted && f.actividad_principal.errors }"
			> -->
			<mat-error *ngIf="submitted && f.actividad_principal.errors" class="invalid-feedback">
				<div *ngIf="f.actividad_principal.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<div>
			<mat-form-field appearance="outline">
			<mat-label>Regimen contribución</mat-label>
			<mat-select formControlName="regimen_contribucion">
				<!-- <mat-option value="">Seleciona un regimen</mat-option> -->
				<mat-option *ngFor="let regimen of regimenes" [value]="regimen.id">{{regimen.nombre}}</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-error *ngIf="submitted && f.regimen_contribucion.errors" class="invalid-feedback">
			<div *ngIf="f.regimen_contribucion.errors.required">Campo obligatorio</div>
		</mat-error>
		</div>
		<div>
			<mat-form-field appearance="outline">
			<mat-label>Tipo de identificación</mat-label>
			<mat-select formControlName="tipo_identificacion">
				<mat-option default value="Credencial para votar">Credencial para votar</mat-option>
				<mat-option value="Pasaporte">Pasaporte</mat-option>
				<mat-option value="FM2">FM2</mat-option>
				<mat-option value="FM3">FM3</mat-option>
			</mat-select>
		</mat-form-field>
		</div>
		<div>
			<mat-form-field appearance="outline">
			<mat-label>Número de identificación</mat-label>
			<input  matInput
			placeholder=""
				type="text"
				formControlName="numero_identificacion" 
				[value]="datosGen.numero_identificacion"
				[ngClass]="{ 'is-invalid': submitted && !f.numero_identificacion.valid }"
			>
		</mat-form-field>
			<mat-error *ngIf="submitted && !f.numero_identificacion.valid" class="invalid-feedback">
				<div *ngIf="!f.numero_identificacion.valid">Campo obligatorio</div>
			</mat-error>
		</div>
	</div>
</form>




<!-- 
	------------------------------------------------------------------------------------------------------- 
	--------------------------------------- PERSONA MORAL -------------------------------------------------
	-------------------------------------------------------------------------------------------------------
-->





<form *ngIf="tipoPersona == 2" [formGroup]="dgMForm" >
	<div class="datos_generales_1">
		<div style="text-align: center; height: 50px;">
			<span class="seccion">1</span>
		</div>

		<div>
			<mat-form-field appearance="outline">
			<mat-label>Razón social</mat-label>
			<input matInput
			[specialCharacters]="['space']"
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="razon_social" 
				[value]="datosGen.razon_social"
				[ngClass]="{ 'is-invalid': submitted && m.razon_social.errors }"
			>
			</mat-form-field>
			<mat-error *ngIf="submitted && m.razon_social.errors" class="invalid-feedback">
				<div *ngIf="m.razon_social.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<div>
			<mat-form-field appearance="outline">
			<mat-label>Actividad principal de la empresa</mat-label>
			<input matInput
			[specialCharacters]="['space']"
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="actividad_principal" 
				[value]="datosGen.actividad_principal"
				[ngClass]="{ 'is-invalid': submitted && m.actividad_principal.errors }"
			>
			</mat-form-field>
			<mat-error *ngIf="submitted && m.actividad_principal.errors" class="invalid-feedback">
				<div *ngIf="m.actividad_principal.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<div>
			<mat-form-field appearance="outline">
			<mat-label>Sector</mat-label>
			<input matInput
			[specialCharacters]="['space']"
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="sector" 
				[value]="datosGen.sector"
				[ngClass]="{ 'is-invalid': submitted && m.sector.errors }"
			>
			</mat-form-field>
			<mat-error *ngIf="submitted && m.sector.errors" class="invalid-feedback">
				<div *ngIf="m.sector.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<div>
			<mat-form-field appearance="outline">
			<mat-label>Monto de la Línea Solicitada</mat-label>
			<input matInput
				mask="separator.2" 
				prefix=" $ "
				thousandSeparator=","
				formControlName="monto_linea" 
				[value]="datosGen.monto_linea"
				[ngClass]="{ 'is-invalid': submitted && m.monto_linea.errors }"
			>
			</mat-form-field>
			<mat-error *ngIf="submitted && m.monto_linea.errors" class="invalid-feedback">
				<div *ngIf="m.monto_linea.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<div>
			<mat-form-field appearance="outline">
			<mat-label>Activo a arrendar</mat-label>
			<input  matInput
			[specialCharacters]="['space']"
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="activo_arrendar" 
				[value]="datosGen.activo_arrendar"
				[ngClass]="{ 'is-invalid': submitted && m.activo_arrendar.errors }"
			>
			</mat-form-field>
			<mat-error *ngIf="submitted && m.activo_arrendar.errors" class="invalid-feedback">
				<div *ngIf="m.activo_arrendar.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<div>
			<mat-form-field appearance="outline">
			<mat-label>Teléfono</mat-label>
			<input matInput
			mask="00 0000 0000"
				formControlName="telefono" 
				[value]="datosGen.telefono"
				[ngClass]="{ 'is-invalid': submitted && m.telefono.errors }"
			>
			</mat-form-field>
			<mat-error *ngIf="submitted && m.telefono.errors" class="invalid-feedback">
				<div *ngIf="m.telefono.errors">Campo obligatorio</div>
			</mat-error>
		</div>
	</div>

	<div class="datos_generales_2">
		<div style="text-align: center; height: 50px;">
			<span class="seccion">2</span>
		</div>

		<div>
			<mat-form-field appearance="outline">
			<mat-label>Página WEB</mat-label>
			<input  matInput
			type="text"
			[patterns]="customPattern"
			mask="W{100}"
			placeholder="www"
				formControlName="pagina" 
				[value]="datosGen.pagina"
				[ngClass]="{ 'is-invalid': submitted && m.pagina.errors }"
			>
			</mat-form-field>
			<mat-error *ngIf="submitted && m.pagina.errors" class="invalid-feedback">
				<div *ngIf="m.pagina.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<div>
			<mat-form-field appearance="outline">
			<mat-label>Fecha de Constitución</mat-label>
			<input matInput
			type="date"
			min="1900-01-01"
			max="2100-12-31"
			(input)="ValidarFechaConstitucion()"
				formControlName="fecha_constitucion"
				[value]="datosGen.fecha_constitucion"
				[ngClass]="{ 'is-invalid': submitted && m.fecha_constitucion.errors }"
			>
			</mat-form-field>
			<mat-error *ngIf="(submitted || m.fecha_constitucion.touched) && m.fecha_constitucion.errors" class="invalid-feedback">
				<div *ngIf="m.fecha_constitucion.errors.required">Campo obligatorio</div>
				<div *ngIf="m.fecha_constitucion.errors.invalid">Fecha no valida</div>
			</mat-error>
		</div>

		<div>
			<mat-form-field appearance="outline">
			<mat-label>R.F.C.</mat-label>
			<input matInput
			placeholder="Con homoclave"
			mask="SSS000000 AAA"
				formControlName="pm_rfc" 
				[value]="datosGen.pm_rfc"
				[ngClass]="{ 'is-invalid': submitted && !m.pm_rfc.valid }"
			>
			</mat-form-field>
			<mat-error *ngIf="submitted && !m.pm_rfc.valid" class="invalid-feedback">
				<div *ngIf="!m.pm_rfc.valid">Campo obligatorio</div>
			</mat-error>
		</div>
		<div *ngIf="tipoPersona!=2">
			<mat-form-field appearance="outline">
			<mat-label>CURP</mat-label>
			<input matInput
			placeholder="Ingresa Curp"
			mask="LLL00000000L"
				formControlName="curp" 
				[value]="datosGen.curp"
				[ngClass]="{ 'is-invalid': submitted && !m.curp.valid }"
			>
			</mat-form-field>
			<mat-error *ngIf="submitted && !m.curp.valid" class="invalid-feedback">
				<div *ngIf="!m.curp.valid">Campo obligatorio</div>
			</mat-error>
		</div>

		<div>
			<mat-form-field appearance="outline">
			<mat-label>Correo electrónico (facturas)</mat-label>
			<input matInput
			type="email"
			placeholder="@"
				formControlName="pm_correo" 
				[value]="datosGen.pm_correo"
				[ngClass]="{ 'is-invalid': submitted && !m.pm_correo.valid }"
			>
			</mat-form-field>
			<mat-error *ngIf="submitted && !m.pm_correo.valid" class="invalid-feedback">
				<div *ngIf="!m.pm_correo.valid">Campo obligatorio</div>
			</mat-error>
		</div>
		<div>
			<mat-form-field appearance="outline">
			<mat-label>Número de empleados</mat-label>
			<input matInput
			mask="00000"
				formControlName="numero_empleados" 
				[value]="datosGen.numero_empleados"
				[ngClass]="{ 'is-invalid': submitted && m.numero_empleados.errors }"
			>
			</mat-form-field>
			<mat-error *ngIf="submitted && m.numero_empleados.errors" class="invalid-feedback">
				<div *ngIf="m.numero_empleados.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<div>
			<mat-form-field appearance="outline">
			<mat-label>Nombre del Director General</mat-label>
			<input matInput
			[specialCharacters]="['space']"
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="nombre_director" 
				[value]="datosGen.nombre_director"
				[ngClass]="{ 'is-invalid': submitted && m.nombre_director.errors }"
			>
			</mat-form-field>
			<mat-error *ngIf="submitted && m.nombre_director.errors" class="invalid-feedback">
				<div *ngIf="m.nombre_director.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>
	</div>
</form>


<div class="row-end">
	<div></div>
	<mat-spinner *ngIf="loading" diameter="30"></mat-spinner>
	<!-- <button *ngIf="!loading" class="save-button" mat-button (click)="onSubmit(1)" style="height: 60px!important; width: 60px!important;">
		<svg style="height: 30px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="48.14" height="48.14" viewBox="0 0 48.14 48.14"><defs><style>.ab{fill:#fff;}</style></defs><path class="ab" d="M8.439,4A3.377,3.377,0,0,0,5,7.439V48.7A3.377,3.377,0,0,0,8.439,52.14H49.7A3.377,3.377,0,0,0,53.14,48.7V14.544a1.039,1.039,0,0,0-.345-.8l-9.4-9.4A1.039,1.039,0,0,0,42.6,4Zm5.731,2.292H41.678V20.047a2.3,2.3,0,0,1-2.292,2.292H16.462a2.3,2.3,0,0,1-2.292-2.292ZM32.508,9.731V18.9h4.585V9.731ZM16.462,30.362H41.678a2.3,2.3,0,0,1,2.292,2.292V49.847h-29.8V32.654A2.3,2.3,0,0,1,16.462,30.362ZM8.439,46.409h2.292V48.7H8.439Zm38.97,0H49.7V48.7H47.409Z" transform="translate(-5 -4)"/></svg>
	</button> -->
	<button *ngIf="!loading" (click)="onSubmit(2)" mat-button>
		Siguiente
		<svg style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><defs><style>.abc{fill:#707070;}</style></defs><path class="abc" d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0ZM22,38V18.7L13.448,27.25l-3.394-3.394L24,9.908,37.948,23.856,34.554,27.25,26,18.7V38Z" transform="translate(48) rotate(90)"/></svg>
	</button>
</div>