import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { customPatterns, emailPattern } from 'src/app/_models/maskPattern';
import { SolicitudService } from 'src/app/_services';

@Component({
  selector: 'app-form-fisica',
  templateUrl: './form-fisica.component.html',
  styleUrls: ['./../../consulta-buro.component.scss']
})
export class FormFisicaComponent implements OnInit {
  @Input() procesos;
  @Input() formData;
  @Output() data = new EventEmitter();
  
  isClient = false
  personaForm: FormGroup;
  datosGen:any = {
    ap_paterno: '',
    ap_materno: '',
    primer_nombre: '',
    segundo_nombre: '',
    fecha_nacimiento: '',
    correo: '',
    rfc: '',
    sexo: '',
    nacionalidad: 'MX',
    target: ''
  };
  customPattern = customPatterns;
  submitted = false;
  nacionalidades = [
    { id: 'MX', valor: 'México' },
    { id: 'AF', valor: 'Afganistán' },
    { id: 'AL', valor: 'Albania' },
    { id: 'DE', valor: 'Alemania' },
    { id: 'AD', valor: 'Andorra' },
    { id: 'AO', valor: 'Angola' },
    { id: 'AI', valor: 'Anguila' },
    { id: 'AQ', valor: 'Antártida' },
    { id: 'AG', valor: 'Antigua y Barbuda' },
    { id: 'SA', valor: 'Arabia Saudita' },
    { id: 'DZ', valor: 'Argelia' },
    { id: 'AR', valor: 'Argentina' },
    { id: 'AM', valor: 'Armenia' },
    { id: 'AW', valor: 'Aruba' },
    { id: 'AU', valor: 'Australia' },
    { id: 'AT', valor: 'Austria' },
    { id: 'AZ', valor: 'Azerbaiyán' },
    { id: 'BS', valor: 'Bahamas' },
    { id: 'BH', valor: 'Bareín' },
    { id: 'BD', valor: 'Bangladés' },
    { id: 'BB', valor: 'Barbados' },
    { id: 'BE', valor: 'Bélgica' },
    { id: 'BZ', valor: 'Belice' },
    { id: 'BJ', valor: 'Benin' },
    { id: 'BM', valor: 'Bermudas' },
    { id: 'BY', valor: 'Bielorrusia' },
    { id: 'BO', valor: 'Bolivia, Estado Plurinacional de' },
    { id: 'BQ', valor: 'Bonaire, San Eustaquio y Saba' },
    { id: 'BA', valor: 'Bosnia y Herzegovina' },
    { id: 'BW', valor: 'Botsuana' },
    { id: 'BR', valor: 'Brasil' },
    { id: 'BN', valor: 'Brunéi Darussalam' },
    { id: 'BG', valor: 'Bulgaria' },
    { id: 'BF', valor: 'Burkina Faso' },
    { id: 'BI', valor: 'Burundi' },
    { id: 'BT', valor: 'Bután' },
    { id: 'CV', valor: 'Cabo Verde' },
    { id: 'KH', valor: 'Camboya' },
    { id: 'CM', valor: 'Camerún' },
    { id: 'CA', valor: 'Canadá' },
    { id: 'CL', valor: 'Chile' },
    { id: 'CN', valor: 'China' },
    { id: 'CY', valor: 'Chipre' },
    { id: 'CO', valor: 'Colombia' },
    { id: 'CI', valor: 'Costa de Marfil (Côte dIvoire)' },
    { id: 'CR', valor: 'Costa Rica' },
    { id: 'HR', valor: 'Croacia' },
    { id: 'CU', valor: 'Cuba' },
    { id: 'CW', valor: 'Curaçao' },
    { id: 'DK', valor: 'Dinamarca' },
    { id: 'DJ', valor: 'Yibuti' },
    { id: 'DM', valor: 'Dominica' },
    { id: 'EC', valor: 'Ecuador' },
    { id: 'EG', valor: 'Egipto' },
    { id: 'SV', valor: 'El Salvador' },
    { id: 'ER', valor: 'Eritrea' },
    { id: 'SK', valor: 'Eslovaquia' },
    { id: 'SI', valor: 'Eslovenia' },
    { id: 'ES', valor: 'España' },
    { id: 'US', valor: 'Estados Unidos' },
    { id: 'EE', valor: 'Estonia' },
    { id: 'ET', valor: 'Ethiopia' },
    { id: 'FJ', valor: 'Fiji' },
    { id: 'PH', valor: 'Filipinas (las)' },
    { id: 'FI', valor: 'Finlandia' },
    { id: 'FR', valor: 'Francia' },
    { id: 'GA', valor: 'Gabón' },
    { id: 'GM', valor: 'Gambia (la)' },
    { id: 'GS', valor: 'Georgia del sur y las islas sandwich del sur' },
    { id: 'GH', valor: 'Ghana' },
    { id: 'GI', valor: 'Gibraltar' },
    { id: 'GD', valor: 'Granada' },
    { id: 'GR', valor: 'Grecia' },
    { id: 'GL', valor: 'Groenlandia' },
    { id: 'GP', valor: 'Guadalupe' },
    { id: 'GU', valor: 'Guam' },
    { id: 'GT', valor: 'Guatemala' },
    { id: 'GY', valor: 'Guayana' },
    { id: 'GF', valor: 'Guayana Francesa' },
    { id: 'GG', valor: 'Guernsey' },
    { id: 'GN', valor: 'Guinea-Bisáu' },
    { id: 'GQ', valor: 'Guinea Ecuatorial' },
    { id: 'GW', valor: 'Guinea-Bissau' },
    { id: 'HT', valor: 'Haití' },
    { id: 'HN', valor: 'Honduras' },
    { id: 'HK', valor: 'Hong Kong' },
    { id: 'HU', valor: 'Hungría' },
    { id: 'IN', valor: 'India' },
    { id: 'ID', valor: 'Indonesia' },
    { id: 'IQ', valor: 'Irak' },
    { id: 'IR', valor: 'Irán (La República Islámica de)' },
    { id: 'IE', valor: 'Irlanda' },
    { id: 'BV', valor: 'Isla Bouvet' },
    { id: 'IM', valor: 'Isla de Man' },
    { id: 'CX', valor: 'Isla de Navidad' },
    { id: 'HM', valor: 'Isla Heard e Islas McDonald' },
    { id: 'NF', valor: 'Isla Norfolk' },
    { id: 'IS', valor: 'Islandia' },
    { id: 'AX', valor: 'Islas Åland' },
    { id: 'KY', valor: 'Islas Caimán (las)' },
    { id: 'CK', valor: 'Islas Cook (las)' },
    { id: 'FK', valor: 'Islas Malvinas [Falkland] (las)' },
    { id: 'FO', valor: 'Islas Faroe (las)' },
    { id: 'MP', valor: 'Islas Marianas del Norte (las)' },
    { id: 'MH', valor: 'Islas Marshall (las)' },
    { id: 'PN', valor: 'Islas Pitcairn' },
    { id: 'RE', valor: 'Islas Reunión' },
    { id: 'SB', valor: 'Islas Salomón (las)' },
    { id: 'VI', valor: 'Islas Vírgenes (EE.UU.)' },
    { id: 'VG', valor: 'Islas Vírgenes Birtánicas' },
    { id: 'IL', valor: 'Israel' },
    { id: 'IT', valor: 'Italia' },
    { id: 'JM', valor: 'Jamaica' },
    { id: 'JP', valor: 'Japón' },
    { id: 'JE', valor: 'Jersey' },
    { id: 'JO', valor: 'Jordania' },
    { id: 'QA', valor: 'Catar' },
    { id: 'KZ', valor: 'Kazajistán' },
    { id: 'KE', valor: 'Kenia' },
    { id: 'KG', valor: 'Kirguistán' },
    { id: 'KI', valor: 'Kiribatí' },
    { id: 'KW', valor: 'Kuwait' },
    { id: 'SY', valor: 'La República Árabe de Siria' },
    { id: 'KR', valor: 'La República de Corea del Sur' },
    { id: 'CD', valor: 'Congo (La República Democrática del // antes Zaire)' },
    { id: 'KP', valor: 'La República Popular Democrática de Corea (Corea del Norte)' },
    { id: 'LA', valor: 'Lao, (la) República Democrática Popular' },
    { id: 'UM', valor: 'Las Islas de Ultramar Menores de Estados Unidos' },
    { id: 'TC', valor: 'Las Islas Turcas y Caicos' },
    { id: 'LS', valor: 'Lesoto' },
    { id: 'LV', valor: 'Letonia' },
    { id: 'LB', valor: 'Líbano' },
    { id: 'LR', valor: 'Liberia' },
    { id: 'LY', valor: 'Libia' },
    { id: 'LI', valor: 'Liechtenstein' },
    { id: 'LT', valor: 'Lituania' },
    { id: 'AE', valor: 'Los Emiratos Árabes Unidos' },
    { id: 'NL', valor: 'Los Países Bajos (Holanda)' },
    { id: 'LU', valor: 'Luxemburgo' },
    { id: 'MO', valor: 'Macao' },
    { id: 'MK', valor: 'Macedonia (la antigua República Yugoslava de)' },
    { id: 'MG', valor: 'Madagascar' },
    { id: 'MY', valor: 'Malasia' },
    { id: 'MW', valor: 'Malaui' },
    { id: 'MV', valor: 'Maldivias' },
    { id: 'ML', valor: 'Mali' },
    { id: 'MT', valor: 'Malta' },
    { id: 'MA', valor: 'Marruecos' },
    { id: 'MQ', valor: 'Martinica' },
    { id: 'MU', valor: 'Mauricio' },
    { id: 'MR', valor: 'Mauritania' },
    { id: 'YT', valor: 'Mayotte' },
    { id: 'FM', valor: 'Micronesia (los Estados Federados de)' },
    { id: 'MD', valor: 'Moldavia (la República de)' },
    { id: 'MC', valor: 'Mónaco' },
    { id: 'MN', valor: 'Mongolia' },
    { id: 'ME', valor: 'Montenegro' },
    { id: 'MS', valor: 'Montserrat' },
    { id: 'MZ', valor: 'Mozambique' },
    { id: 'NA', valor: 'Namibia' },
    { id: 'NR', valor: 'Nauru' },
    { id: 'NP', valor: 'Nepal' },
    { id: 'NI', valor: 'Nicaragua' },
    { id: 'NE', valor: 'Níger (el)' },
    { id: 'NG', valor: 'Nigeria' },
    { id: 'NU', valor: 'Niue' },
    { id: 'NO', valor: 'Noruega' },
    { id: 'NC', valor: 'Nueva Caledonia' },
    { id: 'NZ', valor: 'Nueva Zelandia' },
    { id: 'OM', valor: 'Omán' },
    { id: 'PK', valor: 'Pakistán' },
    { id: 'PW', valor: 'Palaos' },
    { id: 'PS', valor: 'Palestina, Estado de' },
    { id: 'PA', valor: 'Panamá' },
    { id: 'PG', valor: 'Papua Nueva Guinea' },
    { id: 'PY', valor: 'Paraguay' },
    { id: 'PE', valor: 'Perú' },
    { id: 'PF', valor: 'Polinesia Francesa' },
    { id: 'PL', valor: 'Polonia' },
    { id: 'PT', valor: 'Portugal' },
    { id: 'PR', valor: 'Puerto Rico' },
    { id: 'GB', valor: 'Reino Unido' },
    { id: 'CF', valor: 'República Centro Africana (la)' },
    { id: 'CZ', valor: 'República Checa (la)' },
    { id: 'GE', valor: 'Georgia' },
    { id: 'MM', valor: 'Birmania (o Myanmar)' },
    { id: 'TD', valor: 'Chad' },
    { id: 'CG', valor: 'Congo' },
    { id: 'ST', valor: 'Santo Tomé y Príncipe' },
    { id: 'TL', valor: 'República Democrática de Timor Oriental (Timor-Leste)' },
    { id: 'DO', valor: 'República Dominicana (la)' },
    { id: 'TZ', valor: 'Tanzania, República Unida de' },
    { id: 'RW', valor: 'Ruanda' },
    { id: 'RO', valor: 'Rumania' },
    { id: 'RU', valor: 'Rusia, (la) Federación de' },
    { id: 'EH', valor: 'Sahara Occidental' },
    { id: 'AS', valor: 'Samoa Americana' },
    { id: 'WS', valor: 'Samoa' },
    { id: 'BL', valor: 'San Bartolomé' },
    { id: 'KN', valor: 'San Cristóbal y Nieves' },
    { id: 'SM', valor: 'San Marino' },
    { id: 'MF', valor: 'San Martín (parte francesa)' },
    { id: 'PM', valor: 'San Pedro y Miquelón' },
    { id: 'VC', valor: 'San Vicente y las Granadinas' },
    { id: 'SH', valor: 'Santa Helena, Ascensión y Tristán de Acuña' },
    { id: 'LC', valor: 'Santa Lucía' },
    { id: 'SN', valor: 'Senegal' },
    { id: 'RS', valor: 'Serbia' },
    { id: 'SC', valor: 'Seychelles' },
    { id: 'SL', valor: 'Sierra leona' },
    { id: 'SG', valor: 'Singapur' },
    { id: 'SX', valor: 'Sint Maarten (parte holandesa)' },
    { id: 'SO', valor: 'Somalia' },
    { id: 'LK', valor: 'Sri Lanka' },
    { id: 'ZA', valor: 'Sudáfrica' },
    { id: 'SD', valor: 'Sudán del Norte' },
    { id: 'SS', valor: 'República de Sudán del Sur' },
    { id: 'SE', valor: 'Suecia' },
    { id: 'CH', valor: 'Suiza' },
    { id: 'SR', valor: 'Surinam' },
    { id: 'SJ', valor: 'Svalbard y Jan Mayen' },
    { id: 'SZ', valor: 'Swazilandia' },
    { id: 'TH', valor: 'Tailandia' },
    { id: 'TW', valor: 'Taiwán (Provincia de China)' },
    { id: 'TJ', valor: 'Tayikistán' },
    { id: 'IO', valor: 'Territorio Británico del Océano Índico (el)' },
    { id: 'CC', valor: 'Territorio de las Islas Cocos (Keeling)' },
    { id: 'TF', valor: 'Territorios Australes Franceses (los)' },
    { id: 'TG', valor: 'Togo' },
    { id: 'TK', valor: 'Tokelau' },
    { id: 'TO', valor: 'Tonga' },
    { id: 'TT', valor: 'Trinidad y Tobago' },
    { id: 'TN', valor: 'Túnez' },
    { id: 'TM', valor: 'Turkmenistán' },
    { id: 'TR', valor: 'Turquía' },
    { id: 'TV', valor: 'Tuvalu' },
    { id: 'UA', valor: 'Ucrania' },
    { id: 'UG', valor: 'Uganda' },
    { id: 'KM', valor: 'Unión de las Comoras (Comoras o Comores)' },
    { id: 'UY', valor: 'Uruguay' },
    { id: 'UZ', valor: 'Uzbekistán' },
    { id: 'VU', valor: 'Vanuatu' },
    { id: 'VA', valor: 'Ciudad del Vaticano' },
    { id: 'VE', valor: 'Venezuela, República Bolivariana de' },
    { id: 'VN', valor: 'Viet Nam' },
    { id: 'WF', valor: 'Wallis y Futuna' },
    { id: 'YE', valor: 'Yemen' },
    { id: 'ZM', valor: 'Zambia' },
    { id: 'ZW', valor: 'Zimbabue' }
  ];
  minDate;

  constructor(
    private formBuilder: FormBuilder,
    private solicitud: SolicitudService
  ) { }

  ngOnInit(): void {
    console.log('fornData: ', this.formData);

    const fechaNacimiento = this.formData.FechaNacimiento ? this.formData.FechaNacimiento.split('-').reverse().join('-') : ''
    
    const client_details = localStorage.getItem('client_details')
    const email = client_details ? JSON.parse(client_details).email : ''
    this.isClient = email != ''

    this.minDate = new Date();
    this.minDate.setFullYear( this.minDate.getFullYear() - 18 );
    this.personaForm = this.formBuilder.group({
      ap_paterno: [ this.formData.ApellidoPaterno || '', [Validators.required]],
      ap_materno: [this.formData.ApellidoMaterno || '', [Validators.required]],
      primer_nombre: [this.formData.PrimerNombre || '', [Validators.required]],
      segundo_nombre: [this.formData.SegundoNombre || ''],
      fecha_nacimiento: [fechaNacimiento, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      correo: [this.formData.correo || email, [Validators.required, Validators.pattern( emailPattern )]],
      rfc: [this.formData.RFC || '', [Validators.required, Validators.minLength(10)]],
      sexo: [this.formData.Sexo || '', [Validators.required]],
      nacionalidad: ['MX', [Validators.required]],
      target: [this.formData.Authorization.target || '', [Validators.required, Validators.minLength(10)]]
    });

    this.setInfo()
  }

  get f() { return this.personaForm.controls; }

  showTooltip(id) {
    let elemento = document.getElementById(id);
    if (elemento.classList.contains('tooltipText')) {
      elemento.classList.remove('tooltipText');
      elemento.classList.add('noDisplay');
    } else {
      elemento.classList.remove('noDisplay');
      elemento.classList.add('tooltipText');
    }
  }

  async onSubmit() {
    this.submitted = true;
    if (this.personaForm.invalid) {
      console.log('invalido ', this.submitted);
      return;
    } else {
      const fechaNacimiento = this.personaForm.value.fecha_nacimiento.split('-').reverse().join('-')

      if( this.camposInvalidos() ) return
      this.formData.ApellidoPaterno = this.personaForm.value.ap_paterno;
		  this.formData.ApellidoMaterno = this.personaForm.value.ap_materno;
		  this.formData.PrimerNombre = this.personaForm.value.primer_nombre;
		  this.formData.SegundoNombre = this.personaForm.value.segundo_nombre;
		  this.formData.FechaNacimiento = fechaNacimiento;
      this.formData.RFC = this.personaForm.value.rfc;
      this.formData.Nacionalidad = this.personaForm.value.nacionalidad;
      this.formData.Sexo = this.personaForm.value.sexo;
      this.formData.correo = this.personaForm.value.correo;
      this.formData.Authorization.target = this.personaForm.value.target;
      this.data.emit(this.formData);
      this.procesos[1].status = 1;
    }
  }

  camposInvalidos() {
    
    return false
  }

  campoValido(target, {target: {value}}){
    value = value.toLowerCase()
    if( value.length == 2 && (value[0] == value[1])){
      this.f[target].setErrors({invalid: true})
    }
    
  }

  setInfo(){
    const client_details = JSON.parse( localStorage.getItem('client_details'))
    if( client_details ){
      const { id_cliente, folio, tipo_persona } = client_details
      const guardado = { id_cliente, folio, parte: 1, tipo_persona }
      this.solicitud.getDatosGenerales( {guardado} ).subscribe( (res) => {
        const { nombres, ap_paterno, ap_materno, rfc, fecha_nac, sexo} = res
        
        const primer_nombre = nombres.toUpperCase().split(' ')[0] || ''
        const segundo_nombre = nombres.toUpperCase().split(' ')[1] || ''

        this.f.ap_paterno.setValue(ap_paterno.toString().toUpperCase())
        this.f.ap_materno.setValue(ap_materno.toUpperCase())
        this.f.primer_nombre.setValue(primer_nombre)
        this.f.segundo_nombre.setValue(segundo_nombre)
        this.f.rfc.setValue(rfc.toUpperCase())
        this.f.fecha_nacimiento.setValue(fecha_nac)
        this.f.sexo.setValue(sexo)
      })
    }
  }
}
