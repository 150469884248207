<div class="container">
    <div class="top">
        <div class="header">
            <img class="logo" mat-button height="60px" width="auto" [routerLink]="['/dashboard']" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/logo-header.svg" alt="" style="padding-left: 20px;">
            <div>
                <button mat-button routerLink="/changePassword">Cambiar Contraseña
                    <svg style="height: 20px; width: auto;" xmlns="http://www.w3.org/2000/svg" width="31.992" height="31.992" viewBox="0 0 31.992 31.992"><defs><style>.a{fill:#3ca2d6;}</style></defs><path class="a" d="M18,2a16,16,0,1,0,16,16A16.006,16.006,0,0,0,18,2Zm0,1.391A14.6,14.6,0,1,1,3.391,18,14.594,14.594,0,0,1,18,3.391Zm0,4.868a4.164,4.164,0,0,0-4.173,4.173v2.086h-.7A2.1,2.1,0,0,0,11.041,16.6V23.56a2.1,2.1,0,0,0,2.086,2.086h9.737a2.1,2.1,0,0,0,2.086-2.086V16.6a2.1,2.1,0,0,0-2.086-2.086h-.7V12.432A4.164,4.164,0,0,0,18,8.259ZM18,9.65a2.724,2.724,0,0,1,2.782,2.782v2.086H15.214V12.432A2.724,2.724,0,0,1,18,9.65Zm-4.868,6.259H14.4a.7.7,0,0,0,.227,0h6.728a.7.7,0,0,0,.227,0h1.281a.693.693,0,0,1,.7.7V23.56a.693.693,0,0,1-.7.7H13.128a.693.693,0,0,1-.7-.7V16.6A.693.693,0,0,1,13.128,15.909ZM18,18a1.39,1.39,0,0,0-.7,2.594v.883a.7.7,0,1,0,1.391,0v-.884A1.389,1.389,0,0,0,18,18Z" transform="translate(-2 -2)"/></svg>
                </button>
                <button mat-button (click)="logout()">Cerrar Sesión
                    <svg style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="32" height="31.992" viewBox="0 0 32 31.992"><defs><style>.a{fill:#3ca2d6;}</style></defs><path class="a" d="M18,2A16,16,0,1,0,30.9,27.449a.7.7,0,1,0-1.121-.823,14.6,14.6,0,1,1,0-17.259A.7.7,0,1,0,30.9,8.543,15.976,15.976,0,0,0,18,2Zm9.73,9.73a.7.7,0,0,0-.485,1.194L31.617,17.3H16.6a.7.7,0,1,0,0,1.391H31.617l-4.377,4.377a.7.7,0,1,0,.984.984l5.5-5.5a.7.7,0,0,0,0-1.107l-5.5-5.5A.7.7,0,0,0,27.726,11.73Z" transform="translate(-2 -2)"/></svg>
                </button>
            </div>
        </div>
        <div class="secciones">
            <div class="title">Parámetros <br>del sistema</div>
        </div>
    </div>
    <div class="body">
        <div class="divsInline" *ngIf="perfil != 4">
            <div style="padding-right: 18%;">
                <p class="colorSubs">Kilómetros entre registro y solicitud</p>
                <mat-slider min="1" max="70" step="1" [value]="parametros.km" thumbLabel [(ngModel)]="parametros.km"></mat-slider>
                <div>
                    <label style="float: left;">1</label>
                    <label style="float: right;">70 km</label>
                </div>
            </div>
            <div class="etiqueta">
                <p class="valorSlider">{{parametros.km}} km</p>
            </div>
        </div>
        <br *ngIf="perfil != 4">
        <div class="divsInline" *ngIf="perfil != 4">
            <div style="padding-right: 15%;" class="divsPausa">
                <p class="colorSubs">Pausa de Solicitud</p>
                <div class="horasInactivas input">
                    <label class="colorSubs">Horas inactivas</label>
                    <input type="number" (keypress)="ValidarHoras($event)" [value]="parametros.horasSolicitud" maxlength="3" min="1" max="999" [(ngModel)]="parametros.horasSolicitud" [pattern]="horasPattern" #horasSol="ngModel">
                     <mat-error *ngIf="horasSol.errors?.pattern">Número no válido</mat-error>
                </div>
            </div>
            <div style="padding-right: 15%;" class="divsPausa">
                <p class="colorSubs">Pausa de carga de documentos
                    <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/infoIcon.svg" alt="" (click)="info()" width="17.328" height="17.328">
                    <!-- <svg  xmlns="http://www.w3.org/2000/svg" width="17.328" height="17.328" viewBox="0 0 17.328 17.328"><defs><style>.info_icon{fill:#fff; stroke: #3F4A57;}</style></defs><path class="info_icon" d="M10.664,2a8.664,8.664,0,1,0,8.664,8.664A8.664,8.664,0,0,0,10.664,2Zm.866,13H9.8V13.263H11.53Zm0-3.466H9.8v-5.2H11.53Z" transform="translate(-2 -2)"/></svg> -->
                </p>
                <p class="cargaDocsTipo colorSubs">Vendedor</p>
                <div class="horasInactivas input" style="margin-top: 0;">
                    <label class="colorSubs">Horas inactivas</label>
                    <input type="number" (keypress)="ValidarHoras($event)" [value]="parametros.horasDocVendor" maxlength="3" min="1" max="999" [(ngModel)]="parametros.horasDocVendor" [pattern]="horasPattern" #horasVendedor="ngModel">
                    <mat-error *ngIf="horasVendedor.errors?.pattern">Número no válido</mat-error>
                </div>
            </div>
            <div class="divsPausa">
                <p class="colorSubs">Pausa de Comité</p>
                <div class="horasInactivas input">
                    <label class="colorSubs">Horas inactivas</label>
                    <input type="number" (keypress)="ValidarHoras($event)" [value]="parametros.horasComite" maxlength="3" min="1" max="999" [(ngModel)]="parametros.horasComite" [pattern]="horasPattern" #horasCom="ngModel">
                    <mat-error *ngIf="horasCom.errors?.pattern">Número no válido</mat-error>
                </div>
            </div>
        </div>
        <br *ngIf="perfil != 4">
        <div class="divsInline" *ngIf="perfil != 4">
            <div style="padding-right: 15%;" class="divsPausa">
                <p class="colorSubs ">Pausa de carga de documentos</p>
                <p class="cargaDocsTipo colorSubs">Cliente</p>
                <div class="horasInactivas input" style="margin-top: 0;">
                    <label class="colorSubs">Horas inactivas</label>
                    <input type="number" (keypress)="ValidarHoras($event)" [value]="parametros.horasDocCliente" maxlength="3" min="1" max="999" [(ngModel)]="parametros.horasDocCliente" [pattern]="horasPattern" #horasCliente="ngModel">
                    <mat-error *ngIf="horasCliente.errors?.pattern">Número no válido</mat-error>
                </div>
            </div>
            <div class="divsPausa">
                <p class="colorSubs">Pausa de Cotización</p>
                <div class="horasInactivas input">
                    <label class="colorSubs">Horas inactivas</label>
                    <input type="number" (keypress)="ValidarHoras($event)" [value]="parametros.horasCotizacion" maxlength="3" min="1" max="999" [(ngModel)]="parametros.horasCotizacion" [pattern]="horasPattern" #horasCot="ngModel">
                    <mat-error *ngIf="horasCot.errors?.pattern">Número no válido</mat-error>
                </div>
            </div>
        </div>
        <br *ngIf="perfil != 4">
        <div class="divsInline" *ngIf="perfil != 4">
            <div style="padding-right: 18%;">
                <p class="colorSubs">Tamaño de los archivos aceptados</p>
                <mat-slider min="1" max="50" step="1" [value]="parametros.tamanoArchivo" thumbLabel [(ngModel)]="parametros.tamanoArchivo"></mat-slider>
                <div>   
                    <label style="float: left;">1</label>
                    <label style="float: right;">50 GB</label>
                </div>
            </div>
            <div class="etiqueta">
                <p class="valorSlider"> {{parametros.tamanoArchivo}} GB</p>
            </div>
        </div>
        <br *ngIf="perfil != 4">
        <div *ngIf="perfil != 4">
            <p class="colorSubs">Tipo de documentos</p>
            <div class="horasInactivas input formato">
                <label class="colorSubs">Formato</label>
                <input type="text" [value]="parametros.formato" [(ngModel)]="parametros.formato" readonly>
            </div>
        </div>
        <br *ngIf="perfil != 4">
        <hr *ngIf="perfil != 4">
        <div>
            <p class="colorSubs">Credencial de Buró de Crédito</p>
            <div style="display: block;">
                <div class="input formato" style="display: inline-block; margin-right: 15%; width: 280px;">
                    <label class="colorSubs">Usuario</label>
                    <input type="text" [value]="parametros.usuarioBuro" [(ngModel)]="parametros.usuarioBuro">
                </div>
                <div class="input formato" style="display: inline-block; width: 280px;">
                    <label class="colorSubs">Fecha de inicio</label>
                    <input type="text" [value]="parametros.fechaModificacion" [(ngModel)]="parametros.fechaModificacion" readonly>
                </div>

            </div>
            <div style="display: block;">
                <div class="input formato" style="display: inline-block; margin-right: 15%; width: 280px;">
                    <label class="colorSubs">Contraseña</label>
                    <input [type]="show_password ? 'text' : 'password'" [value]="parametros.contrasenaBuro" [(ngModel)]="parametros.contrasenaBuro">
                    <mat-icon matSuffix (click)="show_password = !show_password" style="position:relative; top: -30px; left: 250px;">{{ show_password ? 'visibility_off' : 'visibility' }}</mat-icon>
                </div>
                <div class="input formato" style="display: inline-block; width: 280px; position: relative; top: -30px;">
                    <label class="colorSubs">Días restantes</label>
                    <input type="text" [value]="parametros.diasRestantes" [(ngModel)]="parametros.diasRestantes" [ngClass]="{'alerta': parametros.diferenciaDias <= 2}" readonly>
                </div>
            </div>
        </div>


        <div>
            <p class="colorSubs">Credencial de Buró de Crédito Persona Moral</p>
            <div style="display: block;">
                <div class="input formato" style="display: inline-block; margin-right: 15%; width: 280px;">
                    <label class="colorSubs">Usuario</label>
                    <input type="text" [value]="parametros.usuarioBuroPM" [(ngModel)]="parametros.usuarioBuroPM">
                </div>
                <div class="input formato" style="display: inline-block; width: 280px;">
                    <label class="colorSubs">Fecha de inicio</label>
                    <input type="text" [value]="parametros.fechaModificacionPM" [(ngModel)]="parametros.fechaModificacionPM" readonly>
                </div>

            </div>
            <div style="display: block;">
                <div class="input formato" style="display: inline-block; margin-right: 15%; width: 280px;">
                    <label class="colorSubs">Contraseña</label>
                    <input [type]="show_passwordPM ? 'text' : 'password'" [value]="parametros.contrasenaBuroPM" [(ngModel)]="parametros.contrasenaBuroPM">
                    <mat-icon matSuffix (click)="show_passwordPM = !show_passwordPM" style="position:relative; top: -30px; left: 250px;">{{ show_passwordPM ? 'visibility_off' : 'visibility' }}</mat-icon>
                </div>
                <div class="input formato" style="display: inline-block; width: 280px; position: relative; top: -30px;">
                    <label class="colorSubs">Días restantes</label>
                    <input type="text" [value]="parametros.diasRestantesPM" [(ngModel)]="parametros.diasRestantesPM" [ngClass]="{'alerta': parametros.diferenciaDiasPM <= 2}" readonly>
                </div>
            </div>
        </div>


        <button mat-button class="botonGuardar" (click)="guardarCambios()"> 
            <span class="colorBoton">Guardar cambios</span>
        </button>
        <br><br>
    </div>
    <app-socials-footer style="width:100%;"></app-socials-footer>
</div>


