<app-sidenav-v2
[shortMenu]="true">
<div class="container">
    <div class="top">
        <div class="title">Cotizaciones previas</div>
    </div>
    <div class="container-btn-asignar">
        <div class="button-sec" (click)="AsignarVistaPrevia(null)">
            <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/plus.svg">
            Agregar Folio
        </div>
    </div>

    <div style="width: 100%;">
        <app-card-vista-previa 
        *ngFor="let vista_previa of CotizacionesPrevias; let i=index"
        [vista_previa]="vista_previa"
        [index]="i"
        (VerVistaPrevia)="VerVistaPrevia(vista_previa)"
        (AsignarVistaPrevia)="AsignarVistaPrevia($event)"
        >

        </app-card-vista-previa>
    </div>

</div>


</app-sidenav-v2>
