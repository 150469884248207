import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, AuthenticationService, BuroService, UserService } from '../../../../_services';
import { SolicitudService } from 'src/app/_services/solicitud.service';

import { customPatterns } from '../../../../_models/maskPattern'
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { MatDialog } from '@angular/material/dialog';
/* tslint:disable */

@Component({
	selector: 'app-solicitud-dg-p2',
	templateUrl: './solicitudDGp2.component.html',
	styleUrls: ['./../../solicitud.component.scss']
})

export class SolicitudDGp2Component implements OnInit {

	@Input() tipoPersona: number;
	@Input() solicitud: any;
	@Output() parte = new EventEmitter<number>();
	@Output() onBack = new EventEmitter<number>();
	@Output() getDatosGenerales = new EventEmitter();
	@Output() stepCompletion2 =new EventEmitter<any>();
	

	email : string = null;
	DynamoCliente : any ;

	es_medico = false
	estado_civil = null
	customPattern = customPatterns
	dgFForm: FormGroup;
	dgMForm: FormGroup;
    loading = false;
    submitted = false;
	returnUrl: string;
	datosGen:any = {}
	folio = null
	perfil = null
	isCorrectCP=true
	isCorrectCPFiscal=true

	showDireccionFiscal = true
	direccionInvalida = false
	direccionFiscalInvalida = false

	direccionCliente={
		city:"",
		country: "",
		number: "",
		state: "",
		street: "",
		suburb: "",
		town_hall: "",
		zip: "",
	}

	direccion = {
		direccion: '',
		direccionGeografica: '',
		calle: '',
		numExterior: '',
		numInterior: '',
		colonia: '',
		ciudad: '',
		municipio: '',
		estado: '',
		codigoPostal: '',
	}

	direccionFiscal = {
		direccion: '',
		direccionGeografica: '',
		calle: '',
		numExterior: '',
		numInterior: '',
		colonia: '',
		ciudad: '',
		municipio: '',
		estado: '',
		codigoPostal: '',
	}

	coloniasDisponibles = []
	coloniasDisponiblesFiscal = []

    constructor(
        private formBuilder: FormBuilder,
        // private route: ActivatedRoute,
        private router: Router,
        // private authenticationService: AuthenticationService,
        private alertService: AlertService,
		private solicitudService: SolicitudService,
		private userService:UserService,
		private buroService:BuroService,
		private dialog:MatDialog,
    ) {
        // redirect to home if already logged in

	}
    ngOnInit() {
		
		this.perfil = JSON.parse( localStorage.getItem('client_details') ).perfil
		this.folio = JSON.parse(localStorage.getItem('client_details')).folio
		this.email = JSON.parse(localStorage.getItem('client_details')).email;
		

        this.dgFForm = this.formBuilder.group({
            r_nombres: [''],
            r_segundo_nombre: [''],
			r_apellidos: [''],
			r_apellido_paterno: [''],
			r_apellido_materno: [''],
			r_ocupacion: [''],
			ingreso_mensual: [''],
			tipo_vivienda: ['', [Validators.required]],
			valor_aprox: ['', Validators.required],
			pago_mensual: [''],
			tiempo_residencia: ['', Validators.required],
			domicilio: ['', [Validators.required]]
		});
		
		this.dgMForm = this.formBuilder.group({
            rl_nombres: ['', [Validators.required]],
			rl_apellidos: ['', Validators.required],
			rl_rfc: ['', [Validators.required, Validators.minLength(12)]],
			ce_nombres: ['', Validators.required],
			ce_apellidos: ['', [Validators.required]],
			ce_correo: ['', [Validators.required, Validators.email]],
			ce_puesto: ['', [Validators.required]],
			domicilio_empresa: ['', Validators.required]
		});

		console.log("SET DYNAMO_CLIENTES",this.DynamoCliente);

		const solicitud = this.solicitudService.getReferencia()
		const tipo_persona = this.tipoPersona
		const parte = 2

		const guardado = { ...solicitud, tipo_persona, parte}
		this.solicitudService.getDatosGenerales( {guardado} ).subscribe(async res => {
			console.log(":GET: ", res);
			this.datosGen = res
			await this.getClienteDynamoDB()
			this.setData( res )
		})


		const guardado2 = { ...solicitud, tipo_persona, parte: 1}
		console.log("Guardado: ", guardado);
		
		this.solicitudService.getDatosGenerales( {guardado: guardado2} ).subscribe( res => {
				console.log("GET: ", res);
				this.estado_civil = res.estado_civil
				this.email= res.correo
				
			}
		)

        // get return url from route parameters or default to '/'
        // this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/dashboard';
    }

    // convenience getter for easy access to form fields
	get f() { return this.dgFForm.controls; }
	get m() { return this.dgMForm.controls; }

	async getClienteDynamoDB(){
		console.log("this.email",this.email)
		try {
			this.DynamoCliente = await this.solicitudService.ObtenerDatosCliente(this.email);
			console.log("Datos de DynamoCliente:", this.DynamoCliente);
			this.direccionCliente = this.DynamoCliente?.direccion;
			console.log("Datos de direccioncliente:",this.direccionCliente);
		} catch (error) {
			console.error("Error al obtener cliente de DynamoDB", error);
		}
	}

    async onSubmit(button: number) {
		this.dgMForm.markAllAsTouched();
		this.dgFForm.markAllAsTouched();
		console.log("");
		//this.parte.emit(2);
		if( !this.userService.alertService.isOnline() ) {console.log("Entrando1.."); return}
		this.loading = true;
		var data: any;
		this.submitted = true;

		//if( (this.datosGen.r_nombres) && button === 2 ) {console.log("Entrando2..");return this.parte.emit(3)}
		// stop here if form is invalid
		if (this.tipoPersona == 1) {
			if (this.dgFForm.invalid) {
				this.loading = false;
				console.log('invalido');
				this.stepCompletion2.emit(false);
				return;
			}
			this.f.r_apellidos.setValue(`${this.f.r_apellido_paterno.value} ${this.f.r_apellido_materno.value}`)
			this.f.ingreso_mensual.setValue( Number(this.f.ingreso_mensual.value) );
			this.f.valor_aprox.setValue( Number(this.f.valor_aprox.value) );
			this.f.pago_mensual.setValue( Number(this.f.pago_mensual.value) );
			data = this.dgFForm.value;
			
		}else {
			if (this.dgMForm.invalid) {
				this.loading = false;
				console.log(this.dgMForm.value);
				this.stepCompletion2.emit(false);
				return;
			}
			data = this.dgMForm.value;
			this.stepCompletion2.emit(true);
		}
		
		this.direccionInvalida = this.tipoPersona == 1 ? !this.direccion || !this.direccion.calle || !this.direccion.codigoPostal : false
		this.direccionFiscalInvalida = this.tipoPersona == 1 ? !this.direccionFiscal || !this.direccionFiscal.calle || !this.direccionFiscal.codigoPostal : false

		console.log(this.direccionInvalida);
		console.log(this.direccionFiscalInvalida);
		if (this.direccionInvalida || this.direccionFiscalInvalida) {
			this.loading = false
			return
		}

	
		this.loading = true;
		console.log('before calling api', data);
		this.getDatosGenerales.emit({direccion: this.direccion, direccionFiscal: this.direccionFiscal});
		(await this.solicitudService.saveDatosGenerales(data, 2, this.tipoPersona, 1)).subscribe(
			async (res: any) => {
				console.log(res);
				// if (data.status <= 300) {
					this.loading = false;
					// this.alertService.success()
					if ( button === 1 ) { // go to dashboard
							const dialogref = this.dialog.open(MessageBoxComponent, {
								width: "500px",
								data: {
									text: "Éxito",
									text3: "tu información se ha guardado",
									icon: "success",
									type: "success"
								}
							})
							dialogref.afterClosed().subscribe( _=> {
								this.userService.goToDashboard()
							})
					} else { // next form
						console.log("Emitir parte 3:::::")
						this.stepCompletion2.emit(true);
						this.parte.emit(3);
					}
				// }
				// this.router.navigateByUrl('/login', { replaceUrl: true } );
			},
			(err: any) => {
				this.loading = false;
				// this.alertService.error(err.error.message);
				this.dialog.open(MessageBoxComponent, {
					width: "500px",
					data: {
						text2: "tu información no se ha guardado",
						icon: "error",
						type: "error"
					}
				})
				console.log('Error 2 :', err)
				//let er = JSON.parse(err);
			}
		);				

		
		const direccion = {
			direccionCompleta: `${this.direccion.calle} ${this.direccion.numExterior} ${this.direccion.numInterior}, ${this.direccion.colonia}, ${this.direccion.municipio}, ${this.direccion.estado}, C.P.: ${this.direccion.codigoPostal}`.trim(),
			estado: this.direccion.estado,
			municipio: this.direccion.municipio,
			ciudad: this.direccion.ciudad,
			colonia: this.direccion.colonia,
			calle: this.direccion.calle,
			numeroExterior: this.direccion.numExterior,
			numeroInterior: this.direccion.numInterior,
			cp: this.direccion.codigoPostal
		}
		const direccionFiscal = {
			direccionCompleta: `${this.direccionFiscal.calle} ${this.direccionFiscal.numExterior} ${this.direccionFiscal.numInterior}, ${this.direccionFiscal.colonia}, ${this.direccionFiscal.municipio}, ${this.direccionFiscal.estado}, C.P.: ${this.direccionFiscal.codigoPostal}`.trim(),
			estado: this.direccionFiscal.estado,
			municipio: this.direccionFiscal.municipio,
			ciudad: this.direccionFiscal.ciudad,
			colonia: this.direccionFiscal.colonia,
			calle: this.direccionFiscal.calle,
			numeroExterior: this.direccionFiscal.numExterior,
			numeroInterior: this.direccionFiscal.numInterior,
			cp: this.direccionFiscal.codigoPostal
		}
		const datosAdicionalesDomicilio={
			ingreso_mensual:this.f.ingreso_mensual.value,
			tipo_vivienda: this.f.tipo_vivienda.value,
			valor_aprox: this.f.valor_aprox.value,
			pago_mensual: this.f.pago_mensual.value,
			tiempo_residencia: this.f.tiempo_residencia.value

		}

		const conyuge = {
			primerNombre: this.f.r_nombres.value,
			segundoNombre: this.f.r_segundo_nombre.value,
			apellidoPaterno: this.f.r_apellido_paterno.value,
			apellidoMaterno: this.f.r_apellido_materno.value,
			ocupacion: this.f.r_ocupacion.value,
		}
		

		await this.solicitudService.GuardarSolicitud(this.folio, {direccion, direccionFiscal, conyuge})
	}
	
	changePart(part: number){
		this.parte.emit(part);
	}

	async setData( res ){
		this.direccionCliente  = await this.DynamoCliente?.direccion

		let direcciones = null
		if(res.domicilio){
			console.log("ENTRO A DOMICILIO RES")
			direcciones = res.domicilio ? res.domicilio.split('&') : []
		}else{
			console.log("ENTRO A DOMICILIO DYN")
			console.log("ENTRO A DOMICILIO DYN", this.direccionCliente)

			const calles = document.getElementsByName('calle')
			const numExterior = document.getElementsByName('numExterior')
			const numInterior = document.getElementsByName('numInterior')
			const codigoPostal = document.getElementsByName('codigoPostal')
			const colonia = document.getElementsByName('colonia')
			const municipio = document.getElementsByName('municipio')
			const ciudad = document.getElementsByName('ciudad')
			const estado = document.getElementsByName('estado')

			calles[0].setAttribute('value', this.direccionCliente.street)

			numExterior[0].setAttribute('value', this.direccionCliente.number)
			numInterior[0].setAttribute('value', " ")
			codigoPostal[0].setAttribute('value', this.direccionCliente.zip)
			colonia[0].setAttribute('value', this.direccionCliente.suburb)
			municipio[0].setAttribute('value', this.direccionCliente.town_hall)
			ciudad[0].setAttribute('value', this.direccionCliente.city)
			estado[0].setAttribute('value', this.direccionCliente.state)

		}
		
		
		console.log("SET DATA", this.datosGen)
		console.log("SetDataDynamo", await this.DynamoCliente)
		console.log("Estado civil", this.estado_civil);
		
		
		if( this.tipoPersona == 1 ){
			this.f.r_nombres.setValue( this.solicitud.conyuge.primerNombre )
			this.f.r_segundo_nombre.setValue( this.solicitud.conyuge.segundoNombre )
			this.f.r_apellidos.setValue( res.r_apellidos )
			this.f.r_apellido_paterno.setValue( this.solicitud.conyuge.apellidoPaterno )
			this.f.r_apellido_materno.setValue( this.solicitud.conyuge.apellidoMaterno )
			this.f.r_ocupacion.setValue( this.solicitud.conyuge.ocupacion )
			this.f.ingreso_mensual.setValue( res.ingreso_mensual )
			this.f.tipo_vivienda.setValue( res.tipo_vivienda )
			this.f.valor_aprox.setValue( res.valor_aprox )
			this.f.pago_mensual.setValue( res.pago_mensual )
			this.f.tiempo_residencia.setValue( res.tiempo_residencia )
			this.f.domicilio.setValue( res.domicilio )
		}else {
			this.m.rl_nombres.setValue( res.rl_nombres )
			this.m.rl_apellidos.setValue( res.rl_apellidos )
			this.m.rl_rfc.setValue( res.rl_rfc )
			this.m.ce_nombres.setValue( res.ce_nombres )
			this.m.ce_apellidos.setValue( res.ce_apellidos )
			this.m.ce_correo.setValue( res.ce_correo )
			this.m.ce_puesto.setValue( res.ce_puesto )
			this.m.domicilio_empresa.setValue( res.domicilio_empresa )
		}
		if (direcciones?.length == 2) {
			await this.BuscarDireccion(this.direccion.codigoPostal, 'Direccion')
			await this.BuscarDireccion(this.direccion.codigoPostal, 'DireccionFiscal')

			const _direccionRaw = direcciones[0]
			const _direccionFiscalRaw = direcciones[1]

			const _direccion = _direccionRaw.split('|')
			const _direccionFiscal = _direccionFiscalRaw.split('|')

			const calles = document.getElementsByName('calle')
			const numExterior = document.getElementsByName('numExterior')
			const numInterior = document.getElementsByName('numInterior')
			const codigoPostal = document.getElementsByName('codigoPostal')
			const colonia = document.getElementsByName('colonia')
			const municipio = document.getElementsByName('municipio')
			const ciudad = document.getElementsByName('ciudad')
			const estado = document.getElementsByName('estado')
			console.log({calles});

			calles[0].setAttribute('value', _direccion[0])
			calles[1].setAttribute('value', _direccionFiscal[0])

			numExterior[0].setAttribute('value', _direccion[1])
			numInterior[0].setAttribute('value', _direccion[2])
			codigoPostal[0].setAttribute('value', _direccion[7])
			colonia[0].setAttribute('value', _direccion[3])
			municipio[0].setAttribute('value', _direccion[4])
			ciudad[0].setAttribute('value', _direccion[6])
			estado[0].setAttribute('value', _direccion[5])

			numExterior[1].setAttribute('value', _direccionFiscal[1])
			numInterior[1].setAttribute('value', _direccionFiscal[2])
			codigoPostal[1].setAttribute('value', _direccionFiscal[7])
			colonia[1].setAttribute('value', _direccionFiscal[3])
			municipio[1].setAttribute('value', _direccionFiscal[4])
			ciudad[1].setAttribute('value', _direccionFiscal[6])
			estado[1].setAttribute('value', _direccionFiscal[5])
			
			this.direccion.calle = _direccion[0]
			this.direccion.numExterior = _direccion[1]
			this.direccion.numInterior = _direccion[2]
			this.direccion.codigoPostal = _direccion[7]
			this.direccion.colonia = _direccion[3]
			this.direccion.municipio = _direccion[4]
			this.direccion.ciudad = _direccion[6]
			this.direccion.estado = _direccion[5]

			this.direccionFiscal.calle = _direccionFiscal[0]
			this.direccionFiscal.numExterior = _direccionFiscal[1]
			this.direccionFiscal.numInterior = _direccionFiscal[2]
			this.direccionFiscal.codigoPostal = _direccionFiscal[7]
			this.direccionFiscal.colonia = _direccionFiscal[3]
			this.direccionFiscal.municipio = _direccionFiscal[4]
			this.direccionFiscal.ciudad = _direccionFiscal[6]
			this.direccionFiscal.estado = _direccionFiscal[5]

			console.log("Colonia::::",this.direccion.colonia)

		}else{
			this.isCorrectCP=false;
			this.isCorrectCPFiscal=false;
		}

	}

	ConstruirDireccion() {
		const direccionRaiz = `${this.direccion.calle } |${this.direccion.numExterior }|${this.direccion.numInterior}|${this.direccion.colonia }|${this.direccion.municipio }|${this.direccion.estado }|${this.direccion.ciudad }|${this.direccion.codigoPostal }&${this.direccionFiscal.calle}|${this.direccionFiscal.numExterior}|${this.direccionFiscal.numInterior}|${this.direccionFiscal.colonia}|${this.direccionFiscal.municipio}|${this.direccionFiscal.estado}|${this.direccionFiscal.ciudad}|${this.direccionFiscal.codigoPostal}`
		const direccion = `${this.direccion.calle} ${this.direccion.numExterior}-${this.direccion.numInterior}, ${this.direccion.colonia}, ${this.direccion.municipio}, ${this.direccion.estado}, ${this.direccion.ciudad}, C.P.: ${this.direccion.codigoPostal}`
		const direccionFiscal = `${this.direccionFiscal.calle} ${this.direccionFiscal.numExterior}-${this.direccionFiscal.numInterior}, ${this.direccionFiscal.colonia}, ${this.direccionFiscal.municipio}, ${this.direccionFiscal.estado}, ${this.direccionFiscal.ciudad}, C.P.: ${this.direccionFiscal.codigoPostal}`
		this.direccion.direccion = direccion
		this.direccion.direccionGeografica = direccion
		console.log("DIR", direccionRaiz)

		this.direccionFiscal.direccion = direccionFiscal
		this.direccionFiscal.direccionGeografica = direccionFiscal
		this.f.domicilio.setValue( direccionRaiz )
		
	}

	setDireccion(obj, prop, value) {
		console.log({obj, prop, value});
		
		this[obj][prop] = value
		this.ConstruirDireccion()

		if (!this.showDireccionFiscal) {
			this.CambiarDireccion(true)
		}
	}

	CambiarDireccion(value) {
		this.showDireccionFiscal = !value
		if (value) {
			this.direccionFiscal = {...this.direccion}
			this.ConstruirDireccion()
		}
	}

	async BuscarDireccion(cp, target) {
		console.log(cp, target);

		if (cp.length == 5) {
			if(target=='Direccion'){
				this.isCorrectCP= true;
			}else{
				this.isCorrectCPFiscal=true;
			}
			
			console.log(`Buscando info: ${cp}`);
			const res: any = await this.buroService.buscarEstado(cp)
			console.log("RES buscar estado", res)
			if(res){
				this.guardarColonias(res, target)
			}
			console.log(res);
			if (res[0].error == false) {
				const { asentamiento, municipio, estado, ciudad } = res[0].response

				console.log({target, municipio, estado, ciudad});
				

				const $colonia = document.getElementById(`colonia${target}`)
				const $municipio = document.getElementById(`municipio${target}`)
				const $ciudad = document.getElementById(`ciudad${target}`)
				const $estado = document.getElementById(`estado${target}`)
				console.log($municipio);
				
				// $colonia.setAttribute('value', asentamiento)
				$municipio.setAttribute('value', municipio)
				$ciudad.setAttribute('value', ciudad)
				$estado.setAttribute('value', estado)
				if (target == 'Direccion') {
					// this.direccion.colonia = asentamiento
					this.direccion.municipio = municipio
					this.direccion.ciudad = ciudad
					this.direccion.estado = estado
					this.ConstruirDireccion()
				} else {
					// this.direccionFiscal.colonia = asentamiento
					this.direccionFiscal.municipio = municipio
					this.direccionFiscal.ciudad = ciudad
					this.direccionFiscal.estado = estado
					this.isCorrectCPFiscal = this.direccionFiscal.ciudad.length>0 ?true: false;
					this.ConstruirDireccion()
				}
			}else{
				return
			}
			
		}

	}

	guardarColonias(direcciones, target) {
		if(target == 'Direccion'){
			this.coloniasDisponibles = []
		}else{
			this.coloniasDisponiblesFiscal = []
		}
		direcciones.forEach(element => {
			const { response: {asentamiento} } = element
			if (target == 'Direccion') {
				this.coloniasDisponibles.push( asentamiento )
			} else {
				this.coloniasDisponiblesFiscal.push( asentamiento )
			}
			
		});
	}

	back(step) {
		localStorage.setItem('update', 'true')
		this.onBack.emit(step)
	}
}
