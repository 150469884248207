<div>
  <div
    style="
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 25px;
    "
  >
    <div
      class="btn_selected"
      [ngClass]="{ actived: tipo_cotizacion == 1 }"
      (click)="tipo_cotizacion = 1; LimpiarProducto()"
    >
      Con Producto
    </div>
    <div
      class="btn_selected"
      [ngClass]="{ actived: tipo_cotizacion == 2 }"
      (click)="tipo_cotizacion = 2; CotizarSinProducto()"
    >
      Sin Producto
    </div>
    <div
      *ngIf="cotizar_auto"
      class="btn_selected"
      [ngClass]="{ actived: tipo_cotizacion == 3 }"
      (click)="tipo_cotizacion = 3; CotizarAuto()"
    >
      Auto
    </div>
  </div>
  <div class="cotizacion-header" *ngIf="tipo_cotizacion != 1">
    <div
        class="container-icon-table"
        style="justify-content: flex-end; width: 100%;"
        (click)="table = !table"
      >
        <img
          [src]="
            table
              ? 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/table-inactive.svg'
              : 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/table-active.svg'
          "
        />
        <span style="margin-left: 5px">Tabla</span>
      </div>
  </div>
  <div class="cotizacion-header" *ngIf="tipo_cotizacion == 1">
    <div>
      <div class="input-search">
        <img
          src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/search_blue.svg"
          alt=""
          class="icon-prefix"
        />
        <input
          type="text"
          placeholder="Buscar"
          aria-label="String"
          matInput
          class="input-custom"
          [formControl]="myControl"
          [matAutocomplete]="auto"
          style="background: white;"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="BuscarDetalles()"
        >
          <mat-option
            *ngFor="let option of filteredOptions | async"
            [value]="option.name"
          >
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </div>
    </div>
    <div class="container-btn-products">
      <button
        class="btn-primary-new"
        routerLink="/productos/admin"
        style="width: 150px !important; cursor: pointer"
      >
        <mat-icon>view_list</mat-icon> Ver Productos
      </button>
      <div
        *ngIf="CotizarProducto?.id > 0"
        class="container-icon-table"
        (click)="table = !table"
      >
        <img
          [src]="
            table
              ? 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/table-inactive.svg'
              : 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/table-active.svg'
          "
        />
        <span style="margin-left: 5px">Tabla</span>
      </div>
    </div>
  </div>

  <div class="client-details"></div>
  <div class="details">
    <div *ngIf="CotizarProducto">
      <svg
        (click)="clearImg()"
        *ngIf="custom_image"
        class="close-svg"
        style="
          width: 1.5em;
          height: 1.5em;
          vertical-align: middle;
          fill: currentColor;
          overflow: hidden;
          cursor: pointer;
        "
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M 590.265 511.987 l 305.521 -305.468 c 21.617 -21.589 21.617 -56.636 0.027 -78.252 c -21.616 -21.617 -56.663 -21.617 -78.279 0 L 512.012 433.735 L 206.544 128.213 c -21.617 -21.617 -56.635 -21.617 -78.252 0 c -21.616 21.589 -21.616 56.635 -0.027 78.252 L 433.76 511.987 L 128.211 817.482 c -21.617 21.59 -21.617 56.635 0 78.251 c 10.808 10.81 24.967 16.213 39.125 16.213 c 14.159 0 28.318 -5.403 39.126 -16.213 l 305.522 -305.468 L 817.48 895.788 C 828.289 906.597 842.447 912 856.606 912 s 28.317 -5.403 39.125 -16.212 c 21.618 -21.59 21.618 -56.636 0.028 -78.252 L 590.265 511.987 Z"
          fill="#333333"
        />
      </svg>
      <div class="container-product-img">
        <img
          id="product_image"
          [src]="custom_image ? custom_image : image_preview"
          alt="Agregar imagen"
          style="max-width: 400px; max-height: 240px"
        />
      </div>
      <input
        *ngIf="tipo_cotizacion == 2 || tipo_cotizacion == 3"
        type="file"
        name="product_image"
        (change)="ImagePreview()"
        accept="image/png, image/jpeg"
      />

      <div class="details-product" *ngIf="(tipo_cotizacion == 1 && CotizarProducto.id > 0) || (tipo_cotizacion == 3 || tipo_cotizacion == 2)">
        <!-- <span>{{description}}</span> -->

        <div class="table-tir" *ngIf="arrendamiento.length && table">
          <table>
            <thead>
              <th>Periodo</th>
              <th *ngIf="[3, 6].includes(perfil)">TIR</th>
              <th *ngIf="[3, 6].includes(perfil)">Tasa Activa</th>
              <th>Pago inicial</th>
              <th>Mensualidad</th>
              <!-- <th>Residual</th> -->
            </thead>
            <tbody>
              <tr *ngFor="let item of arrendamiento">
                <td>{{ item.period }} {{ item.type }}</td>
                <td *ngIf="[3, 6].includes(perfil)">{{ item.tir }}</td>
                <td *ngIf="[3, 6].includes(perfil)">{{ item.active_rate }}</td>
                <td>{{ item.country == 'PE' ? item.only_initial_payment_amount_sin_igv : item.initial_payment_amount | currency : "MXN" }}</td>
                <td>{{ item.country == 'PE' ? item.only_monthly_payment_sin_igv : item.monthly_payment | currency : "MXN" }}</td>
                <!-- <td>{{item.residual_amount | currency:'MXN'}}</td> -->
              </tr>
            </tbody>
          </table>
        </div>
        <div
          (click)="opciones_avanzadas = !opciones_avanzadas"
          class="container-option"
        >
          <span>Opciones avanzadas</span>
          <img
            src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_abajo.svg"
            *ngIf="!opciones_avanzadas"
          />
          <img
            src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/flecha_arriba.svg"
            *ngIf="opciones_avanzadas"
          />
        </div>

        <div
          style="display: flex; flex-direction: column"
          *ngIf="opciones_avanzadas"
        >
          <div *ngIf="CotizarProducto.id > 0">
            <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
              <label for="accesorios" class="title-label">Accesorios </label>
              <input
                class="client-info"
                type="text"
                name="accesorios"
                id="accesorios"
                [ngModel]="CotizarProducto.accessories"
                readonly
              />
            </div>
          </div>
          <div class="row-detail" *ngIf="CotizarProducto.id > 0">
            <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
              <label for="model" class="title-label">Modelo </label>
              <input
                class="client-info"
                type="text"
                name="model"
                id="model"
                [(ngModel)]="model"
                [readonly]="!edit_product"
              />
            </div>
            <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
              <label for="model" class="title-label">Año </label>
              <input
                class="client-info"
                type="text"
                name="model"
                id="model"
                [(ngModel)]="year"
                [readonly]="!edit_product"
              />
            </div>
          </div>

          <div class="row-detail" *ngIf="CotizarProducto.id > 0">
            <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
              <label for="garantia" class="title-label">Garantía</label>
              <input
                class="client-info"
                type="text"
                name="garantia"
                id="garantia"
                [(ngModel)]="warranty"
                [readonly]="!edit_product"
              />
            </div>
          </div>
          <div>
            <div class="row-detail" *ngIf="COMPANY.country == 'MX'">
              <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
                <label for="tc" class="title-label">T.C.</label>
                <input
                  class="client-info"
                  type="text"
                  name="tc"
                  id="tc"
                  [disabled]="CotizarProducto.currency === 'MXN'"
                  [(ngModel)]="tipo_cambio"
                  maxlength="7"
                  (input)="ChangeUnitPrice()"
                />
              </div>
              <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
                <label for="tc" class="title-label"
                  >Precio con IVA {{ COMPANY.currency }}</label
                >
                <input
                  class="client-info"
                  type="text"
                  name="tc"
                  id="tc"
                  [ngModel]="
                    CotizarProducto.customer_price * tipo_cambio -
                      descuento * tipo_cambio || 0 | currency : 'MXN'
                  "
                />
              </div>
            </div>
          </div>
          <div
            class="row-detail"
            *ngIf="!msi_disponibles.length"
            style="margin-bottom: 15px"
          >
            <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
              <label for="descuento_ciego" class="title-label"
                >Descuento Ciego %</label
              >
              <input
                class="client-info"
                type="text"
                name="descuento_ciego"
                id="descuento_ciego"
                matInput
                mask="separator.1"
                type="text"
                class="client-info"
                [value]="descuento_ciego"
                [(ngModel)]="descuento_ciego"
                (input)="ChangeDiscountPercent()"
              />
            </div>
            <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
              <label for="descuento_ciego" class="title-label"
                >Descuento Ciego $</label
              >
              <input
                class="client-info"
                type="text"
                name="descuento_ciego"
                id="descuento_ciego"
                matInput
                mask="separator.2"
                thousandSeparator=","
                prefix="$ "
                type="text"
                class="client-info"
                [(ngModel)]="descuento_ciego_amount"
                (input)="ChangeDiscount()"
                maxlength="15"
              />
            </div>
          </div>
          <mat-error *ngIf="error_max_descuento_ciego"
            >El descuento ciego indicado es incorrecto, por favor
            verificalo</mat-error
          >

          <label *ngIf="!msi_disponibles.length" for="delivery_time"
            >Tiempo de entrega / Gracia (meses)</label
          >
          <select
            *ngIf="!msi_disponibles.length"
            name="delivery_time"
            id="delivery_time"
            [(ngModel)]="delivery_time"
          >
            <option value="0">Inmediato</option>
            <option value="1">1 mes</option>
            <option value="2">2 meses</option>
            <option value="3">3 meses</option>
          </select>

          <div *ngIf="tipo_cotizacion != 3">
            <div>
              <div class="custom-radio">
                <label>Tipo de activo</label>
                <div class="options-container">
                  <div
                    *ngFor="let option of tipo_activo; let i = index"
                    class="radio-option"
                    [class.selected]="selectedOption === option.value"
                    (click)="updateSelectedOption(option.value)"
                  >
                    <div class="radio-button"></div>
                    <img
                      [src]="getOptionImageUrl(option)"
                      alt="{{ option.label }}"
                      class="radio-img"
                    />
                    <span>{{ option.label }}</span>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
            <label for="client-name" class="title-label"
              >Nombre del cliente*</label
            >
            <input
              class="client-info"
              type="text"
              name="client_name"
              id="client_name"
              [(ngModel)]="client_name"
              placeholder="Nombre(s)"
            />
          </div>
          <mat-error *ngIf="error_form && !client_name"
            >Campo obligatorio</mat-error
          >
          <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
            <label for="client-name" class="title-label"
              >Apellido Paterno del cliente*</label
            >
            <input
              class="client-info"
              type="text"
              name="client_name"
              id="client_name"
              [(ngModel)]="client_first_lastname"
              placeholder="Apellido paterno"
            />
          </div>
          <mat-error *ngIf="error_form && !client_first_lastname"
            >Campo obligatorio</mat-error
          >
          <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
            <label for="client-name" class="title-label"
              >Apellido Materno del cliente*</label
            >
            <input
              class="client-info"
              type="text"
              name="client_name"
              id="client_name"
              [(ngModel)]="client_second_lastname"
              placeholder="Apellido materno"
            />
          </div>
          <mat-error *ngIf="error_form && !client_second_lastname"
            >Campo obligatorio</mat-error
          >
        </div>
      </div>
      <div
        class="details-product"
        *ngIf="tipo_cotizacion == 2 || tipo_cotizacion == 3"
      >
        <div class="table-tir" *ngIf="arrendamiento.length && table">
          <table>
            <thead>
              <th>Periodo</th>
              <th *ngIf="[3, 6].includes(perfil)">TIR</th>
              <th *ngIf="[3, 6].includes(perfil)">Tasa Activa</th>
              <th>Pago inicial</th>
              <th>Mensualidad</th>
              <!-- <th>Residual</th> -->
            </thead>
            <tbody>
              <tr *ngFor="let item of arrendamiento">
                <td>{{ item.period }} {{ item.type }}</td>
                <td *ngIf="[3, 6].includes(perfil)">{{ item.tir }}</td>
                <td *ngIf="[3, 6].includes(perfil)">{{ item.active_rate }}</td>
                <td>{{ item.country == 'PE' ? item.only_initial_payment_amount_sin_igv : item.initial_payment_amount | currency : "MXN" }}</td>
                <td>{{ item.country == 'PE' ? item.only_monthly_payment_sin_igv : item.monthly_payment | currency : "MXN" }}</td>
                <!-- <td>{{item.residual_amount | currency:'MXN'}}</td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div *ngIf="CotizarProducto">
      <div
        class="details-product"
        *ngIf="
          (tipo_cotizacion == 1 && CotizarProducto.id > 0) ||
          tipo_cotizacion == 2 || tipo_cotizacion == 3
        "
      >
        <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
          <label for="nombre_producto" class="title-label"
            >Nombre Producto</label
          >
          <input
            class="client-info"
            type="text"
            name="nombre_producto"
            id="nombre_producto"
            [ngModel]="CotizarProducto.name | uppercase"
            readonly
          />
        </div>

        <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
          <label for="description_activo-name" class="title-label"
            >Descripción</label
          >
          <textarea
            [value]="description"
            name="description_activo"
            id="description_activo"
            [(ngModel)]="description"
            maxlength="250"
            rows="4"
            style="border-radius: 12px; font-family: 'Poppins'; width: 100%"
          ></textarea>
        </div>

        <div *ngIf="tipo_cotizacion == 3" style="margin-top: 12px">
          <div class="row-detail">
            <span class="title-label">Tipo auto</span>
            <span
              class="title-label"
              *ngIf="CotizarProducto!.caracteristicas.nuevo == 0"
              >Año</span
            >
            <span
              class="title-label"
              *ngIf="CotizarProducto!.caracteristicas.nuevo != 0"
            ></span>

            <select
              style="width: 40% !important"
              [(ngModel)]="CotizarProducto!.caracteristicas.nuevo"
              (change)="
                CotizarProducto!.caracteristicas.year =
                  CotizarProducto!.caracteristicas.nuevo == 1
                    ? current_year
                    : CotizarProducto!.caracteristicas.year;
                CotizacionAuto()
              "
            >
              <option [value]="1">NUEVO</option>
              <option [value]="0">SEMINUEVO</option>
            </select>

            <select
              *ngIf="CotizarProducto.caracteristicas.nuevo == 0"
              style="width: 40% !important"
              [(ngModel)]="CotizarProducto.caracteristicas.year"
            >
              <option *ngFor="let _ of availables_years; let i = index">
                {{ current_year - i }}
              </option>
            </select>
          </div>

          <div class="row-detail">
            <span class="title-label">Estado</span>
            <span></span>

            <app-lista-estados
              (onChange)="
                CotizarProducto!.caracteristicas.estado = $event.name;
                CotizacionAuto()
              "
            ></app-lista-estados>
            <span></span>
          </div>
          <div class="row-detail">
            <span class="title-label" *ngIf="PUEDE_CAMBIAR_TASA_AUTO">Tasa</span>
            <span class="title-label"></span>
            <input
              matInput
              mask="separator.3"
              type="text"
              class="client-info"
              [value]="tasa_personalizada"
              [(ngModel)]="tasa_personalizada"
              *ngIf="PUEDE_CAMBIAR_TASA_AUTO"
            />
          </div>
        </div>

        <div class="row-detail">
          <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
            <label for="precio_activo-name" class="title-label">{{
              COMPANY.country == "MX" ? "Precio con IVA" : "Precio con IGV USD"
            }}</label>
            <input
              matInput
              class="client-info"
              name="precio_activo"
              id="precio_activo"
              mask="separator.2"
              thousandSeparator=","
              prefix="$ "
              type="text"
              [(ngModel)]="CotizarProducto.customer_price"
              maxlength="15"
              (input)="SetDescuento()"
            />
          </div>
          <div
            class="custom-form-field"
            [class.non-empty]="!isInputEmpty"
            *ngIf="tipo_cotizacion == 1"
          >
            <label for="moneda" class="title-label">Moneda</label>
            <input
              class="client-info"
              type="text"
              name="moneda"
              id="moneda"
              [(ngModel)]="CotizarProducto.currency"
              readonly
            />
          </div>
        </div>

        <div
          class="row-detail"
          *ngIf="tipo_cotizacion == 1 && !msi_disponibles.length"
        >
          <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
            <label for="descuento_disponible" class="title-label"
              >Descuento disponible</label
            >
            <input
              class="client-info"
              type="text"
              name="descuento_disponible"
              id="descuento_disponible"
              [ngModel]="descuento_disponible | currency : 'MXN'"
              readonly
            />
          </div>
          <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
            <label for="descuento" class="title-label">Descuento</label>
            <input
              class="client-info"
              type="text"
              name="descuento"
              id="descuento"
              matInput
              mask="separator.2"
              thousandSeparator=","
              prefix="$ "
              type="text"
              [(ngModel)]="descuento"
              maxlength="10"
              (input)="SetDescuento()"
              [disabled]="msi_disponibles.length"
            />
          </div>
        </div>
        <mat-error *ngIf="error_descuento">Descuento invàlido</mat-error>
        <div class="row-detail" *ngIf="!msi_disponibles.length">
          <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
            <label for="descuento" class="title-label">Enganche %</label>
            <input
              class="client-info"
              type="text"
              name="enganche_p"
              id="enganche_p"
              mask="separator.1"
              type="text"
              class="client-info"
              [value]="initial_payment"
              [(ngModel)]="initial_payment"
              (input)="ChangeInitialPaymentPercent()"
            />
          </div>
          <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
            <label for="descuento" class="title-label">Enganche $</label>
            <input
              class="client-info"
              type="text"
              name="enganche_a"
              id="enganche_a"
              mask="separator.1"
              prefix="$"
              type="text"
              class="client-info"
              [(ngModel)]="initial_payment_amount"
              (input)="ChangeInitialPayment()"
              maxlength="15"
            />
          </div>
        </div>
        <mat-error
          *ngIf="initial_payment > 0 && initial_payment < MIN_PERCENT_AVAILABLE"
          >Enganche no válido</mat-error
        >
        <mat-error *ngIf="error_max_percent && CotizarProducto.customer_price > 0 "
          >El porcentaje maximo debe ser {{ MAX_INITIAL_PERCENT }}%</mat-error
        >
        <mat-error
          *ngIf="
            unit_price > 0 &&
            error_min_financing_amount &&
            this.COMPANY.country == 'MX'
          "
          >El monto minimo a financiar debe ser
          {{ MIN_FINANCING_AMOUNT }}</mat-error
        >
        <mat-error
          *ngIf="
            unit_price > 0 &&
            error_min_financing_amount_usd &&
            this.COMPANY.country == 'PE'
          "
          >El monto minimo a financiar debe ser
          {{ MIN_FINANCING_AMOUNT_USD }}</mat-error
        >

        <div class="row-detail" *ngIf="tipo_cotizacion == 3">
          <span class="title-label">Seguro</span>
          <span class="title-label"></span>

          <select
            [(ngModel)]="CotizarProducto!.caracteristicas.seguro_financiado"
            name="seguro"
            id="seguro"
            style="width: 150px !important"
          >
            <option [value]="0">CONTADO</option>
            <option [value]="1">FINANCIADO</option>
          </select>
          <span></span>
          <!-- <input matInput
  mask="separator.2"
  thousandSeparator=","
  prefix="$ " type="text" class="client-info" [(ngModel)]="CotizarProducto!.caracteristicas.seguro_auto" maxlength="15"> -->
        </div>

        <div class="row-detail" *ngIf="tipo_cotizacion == 3">
          <span
            ><mat-checkbox
              [checked]="incluir_placas"
              (change)="incluir_placas = !incluir_placas; CotizacionAuto()"
              >Incluir placas</mat-checkbox
            ></span
          >
          <span
            ><mat-checkbox
              [checked]="incluir_tenencia"
              (change)="incluir_tenencia = !incluir_tenencia; CotizacionAuto()"
              >Incluir tenencia</mat-checkbox
            ></span
          >

          <span class="title-label">Placas con IVA</span>
          <span class="title-label">Tenencia con IVA</span>

          <span>{{
            CotizarProducto.caracteristicas.placas | currency : "MXN"
          }}</span>
          <span>{{
            CotizarProducto.caracteristicas.tenencia | currency : "MXN"
          }}</span>
        </div>

        <div class="row-detail" *ngIf="tipo_cotizacion == 3">
          <span
            ><mat-checkbox
              [checked]="incluir_gps"
              (change)="incluir_gps = !incluir_gps; CotizacionAuto()"
              >Incluir GPS</mat-checkbox
            ></span
          >
          <span></span>

          <span class="title-label">GPS inicial con IVA</span>
          <span class="title-label">GPS mensualidad con IVA</span>

          <span>{{
            CotizarProducto.caracteristicas.gps_inicial | currency : "MXN"
          }}</span>
          <span>{{
            CotizarProducto.caracteristicas.gps_mensualidad | currency : "MXN"
          }}</span>
        </div>

        <div class="row-detail" *ngIf="PUEDE_CAMBIAR_TASA || (tipo_cotizacion == 3 && USUARIO_ESPECIAL_CAMBIAR_TASA_AUTO.includes(id_usuario))">
          <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
            <label for="client-tasa_personalizada" class="title-label"
              >Tasa personalizada</label
            >
            <input
              name="tasa_personalizada"
              id="tasa_personalizada"
              mask="separator.3"
              type="text"
              class="client-info"
              [value]="tasa_personalizada"
              [(ngModel)]="tasa_personalizada"
              (input)="ChangeSeguro()"
            />
          </div>
        </div>
        

        <div class="row-detail" *ngIf="false">
          <span class="title-label">Seguro %</span>
          <span class="title-label">Seguro Anual $</span>
          <input
            type="text"
            class="client-info"
            [(ngModel)]="porcentajeSeguro"
            (input)="ChangeSeguro()"
          />
          <span>{{ precioSeguroAnual | currency : "MXN" }}</span>
        </div>

        <div class="row-detail" *ngIf="false">
          <span class="title-label">Seguro Mensual $</span>
          <span class="title-label"></span>
          <span>{{ precioSeguroMensualFinanciado | currency : "MXN" }}</span>
          <span></span>
        </div>

        <div style="width: 100%; align-self: center; margin-top: 18px">
          <div>
            <div colspan="6" style="color: #0277d9; font-weight: 600">
              Plazos
            </div>
          </div>
          <div *ngIf="!msi_disponibles.length">
            <div
              colspan="6"
              style="color: #0277d9; font-weight: 600; text-align: center"
            >
              Arrendamiento Puro
            </div>
          </div>
          <div *ngIf="!msi_disponibles.length" class="container-plazos">
            <div
              class="plazos"
              [ngClass]="{ plazo_activo: Plazos.ap12 }"
              (click)="ActivarDesactivarPlazo('ap12')"
            >
              12 meses
            </div>
            <div
              class="plazos"
              [ngClass]="{ plazo_activo: Plazos.ap18 }"
              (click)="ActivarDesactivarPlazo('ap18')"
            >
              18 meses
            </div>
          </div>
          <div *ngIf="!msi_disponibles.length" class="container-plazos">
            <div
              class="plazos"
              [ngClass]="{ plazo_activo: Plazos.ap24 }"
              (click)="ActivarDesactivarPlazo('ap24')"
            >
              24 meses
            </div>
            <div
              class="plazos"
              [ngClass]="{ plazo_activo: Plazos.ap36 }"
              (click)="ActivarDesactivarPlazo('ap36')"
            >
              36 meses
            </div>
          </div>
          <div *ngIf="!msi_disponibles.length" class="container-plazos">
            <div
              class="plazos"
              [ngClass]="{ plazo_activo: Plazos.ap48 }"
              (click)="ActivarDesactivarPlazo('ap48')"
            >
              48 meses
            </div>
            <div
              class="plazos"
              [ngClass]="{ plazo_activo: Plazos.ap60 }"
              (click)="ActivarDesactivarPlazo('ap60')"
            >
              60 meses
            </div>
          </div>

          <div *ngIf="msi_disponibles.length">
            <div
              colspan="6"
              style="
                text-align: center;
                color: #0277d9;
                font-weight: 600;
                margin-bottom: 15px;
              "
            >
              Meses sin intereses disponibles
            </div>
          </div>
          <!-- <tr *ngIf="msi_disponibles.length">
  <td colspan="6">
  <select name="plazo_msi" id="plazo_msi" [(ngModel)]="msi_seleccionado" (change)="ActivarDesactivarPlazo($event.target.value.split('-')[0])">
  <option [value]="''">Meses sin intereses</option>
  <option *ngFor="let item of msi_disponibles" [value]="'msi'+item.plazo+'-'+item.porcentaje">{{item.plazo}} MSI - {{item.porcentaje}}% Pago inicial</option>
  </select>
  </td>
  </tr> -->
          <div *ngIf="msi_disponibles.length">
            <div colspan="6">
              <div *ngFor="let msi of msi_disponibles; let i = index">
                <div
                  (click)="AgregaQuitarMSI(msi)"
                  *ngIf="ValidarMinFinancingMSI(msi.porcentaje)"
                  style="
                    display: grid;
                    grid-template-columns: 70% 30%;
                    margin-bottom: 16px;
                    align-items: center;
                  "
                >
                  <div
                    class="plazos"
                    [ngClass]="{ plazo_activo: CheckMSISeleccionado(msi) }"
                  >
                    {{ msi.plazo }} meses
                  </div>
                  <span>
                    Enganche: {{ msi.porcentaje }}%
                    <!-- {{ ((CotizarProducto.customer_price * tipo_cambio) - (descuento * tipo_cambio) || 0 ) * (msi.porcentaje / 100)| currency:'MXN'}} -->
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- <tr>
  <td class="plazos" [ngClass]="{'plazo_activo': Plazos.msi12}" (click)="ActivarDesactivarPlazo('msi12')">12</td>
  <td class="plazos" [ngClass]="{'plazo_activo': Plazos.msi18}" (click)="ActivarDesactivarPlazo('msi18')">18</td>
  <td class="plazos" [ngClass]="{'plazo_activo': Plazos.msi24}" (click)="ActivarDesactivarPlazo('msi24')">24</td>
  <td class="plazos" [ngClass]="{'plazo_activo': Plazos.msi36}" (click)="ActivarDesactivarPlazo('msi36')">36</td>
  <td class="plazos" [ngClass]="{'plazo_activo': Plazos.msi48}" (click)="ActivarDesactivarPlazo('msi48')">48</td>
  <td class="plazos" [ngClass]="{'plazo_activo': Plazos.msi60}" (click)="ActivarDesactivarPlazo('msi60')">60</td>
  </tr> -->
          <div>
            <div colspan="6">
              <mat-error *ngIf="error_plazo"
                >Selecciona por lo menos un plazo</mat-error
              >
            </div>
          </div>
        </div>
        <!-- {{CotizarProducto| json}} -->
        <div *ngIf="!msi_disponibles.length" style="padding-top: 20px">
          <div
            colspan="6"
            style="
              text-align: center;
              color: #0277d9;
              font-weight: 600;
              margin-bottom: 12px;
            "
          >
            Valores Residuales
          </div>
        </div>
        <div>
          <div *ngIf="!msi_disponibles.length" class="container-residual">
            <div>12</div>
            <div>18</div>
            <div>24</div>
            <div>36</div>
            <div>48</div>
            <div>60</div>
          </div>
          <div *ngIf="!msi_disponibles.length" class="container-residual">
            <div>
              <input
                [ngClass]="{
                  'input-error': Residuales.ap12 > max_residuales.ap12
                }"
                [(ngModel)]="Residuales.ap12"
                type="number"
                min="0"
                [max]="max_residuales.ap12"
                style="width: 45px"
                (input)="ValidarResiduales()"
              />
            </div>
            <div>
              <input
                [ngClass]="{
                  'input-error': Residuales.ap18 > max_residuales.ap18
                }"
                [(ngModel)]="Residuales.ap18"
                type="number"
                min="0"
                [max]="max_residuales.ap18"
                style="width: 45px"
                (input)="ValidarResiduales()"
              />
            </div>
            <div>
              <input
                [ngClass]="{
                  'input-error': Residuales.ap24 > max_residuales.ap24
                }"
                [(ngModel)]="Residuales.ap24"
                type="number"
                min="0"
                [max]="max_residuales.ap24"
                style="width: 45px"
                (input)="ValidarResiduales()"
              />
            </div>
            <div>
              <input
                [ngClass]="{
                  'input-error': Residuales.ap36 > max_residuales.ap36
                }"
                [(ngModel)]="Residuales.ap36"
                type="number"
                min="0"
                [max]="max_residuales.ap36"
                style="width: 45px"
                (input)="ValidarResiduales()"
              />
            </div>
            <div>
              <input
                [ngClass]="{
                  'input-error': Residuales.ap48 > max_residuales.ap48
                }"
                [(ngModel)]="Residuales.ap48"
                type="number"
                min="0"
                [max]="max_residuales.ap48"
                style="width: 45px"
                (input)="ValidarResiduales()"
              />
            </div>
            <div>
              <input
                [ngClass]="{
                  'input-error': Residuales.ap60 > max_residuales.ap60
                }"
                [(ngModel)]="Residuales.ap60"
                type="number"
                min="0"
                [max]="max_residuales.ap60"
                style="width: 45px"
                (input)="ValidarResiduales()"
              />
            </div>
          </div>
        </div>
        <div *ngIf="error_residual">
          <div colspan="6">
            <mat-error>Error: Verifica los valores residuales</mat-error>
          </div>
        </div>

        <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
          <label for="client-name" class="title-label"
            >Correo electrónico*</label
          >
          <input
            class="client-info"
            type="text"
            name="client_email"
            id="client_email"
            [(ngModel)]="client_email"
            placeholder="correo@dominio.com"
          />
        </div>
        <mat-error *ngIf="error_form && !client_email"
          >Campo obligatorio</mat-error
        >
        <div class="custom-form-field" [class.non-empty]="!isInputEmpty">
          <label for="client-name" class="title-label">Teléfono*</label>
          <input
            matInput
            mask="00 0000 0000"
            class="client-info"
            type="text"
            name="client_phone"
            id="client_phone"
            [(ngModel)]="client_phone"
          />
        </div>
        <mat-error *ngIf="error_form && !client_phone"
          >Campo obligatorio</mat-error
        >
        <mat-error
          *ngIf="
            error_form && client_phone.length > 0 && client_phone.length < 10
          "
          >Teléfono inválido</mat-error
        >
      </div>
      <div
        class="action_buttons"
        style="
          display: flex;
          justify-content: space-around;
          margin-top: 20px;
          flex-direction: column;
        "
      >
        <!-- <button class="cancel_button" (click)="Cancelar()">Cancelar</button> -->
        <div
          class="action_buttons"
          style="
            display: flex;
            justify-content: space-around;
            margin-top: 20px;
            flex-direction: row;
            align-items: center;
          "
        >
          <button
            *ngIf="
              (CotizarProducto.id ||
                tipo_cotizacion == 2 ||
                tipo_cotizacion == 3) &&
              !is_loading
            "
            class="btn-env"
            (click)="Cotizar(true, true, false, false)"
            [disabled]="HayError()"
          >
            <img
              src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/send-email.svg"
            />
            Enviar
          </button>
          <button
            *ngIf="
              (CotizarProducto.id ||
                tipo_cotizacion == 2 || tipo_cotizacion == 3) &&
              !is_loading
            "
            class="btn-sec"
            (click)="Cotizar(true, false, false, false)"
            [disabled]="HayError()"
          >
            <img
              src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/download.svg"
            />
            Generar vista Previa
          </button>
        </div>
        <div
          class="action_buttons"
          style="
            display: flex;
            justify-content: space-around;
            margin-top: 20px;
            flex-direction: row;
            align-items: center;
          "
        >
          <!-- <button *ngIf="(CotizarProducto.id || tipo_cotizacion == 3) && !is_loading" class="btn-sec" (click)="Cotizar(false, false, false, false)" [disabled]="HayError()">
  Crear cotización
  </button> -->
          <button
            *ngIf="(CotizarProducto.id || tipo_cotizacion == 3) && !is_loading"
            class="btn-new-format"
            (click)="Cotizar(false, false, false, true)"
            [disabled]="HayError()"
          >
            Crear solicitud
          </button>
        </div>
        <div
          class="action_buttons"
          style="
            display: flex;
            justify-content: space-around;
            margin-top: 20px;
            flex-direction: column;
            align-items: center;
          "
        >
          <button
            *ngIf="(CotizarProducto.id || tipo_cotizacion == 3) && !is_loading"
            class="btn-whatsapp"
            (click)="shareOnWhatsApp()"
            [disabled]="HayError()"
          >
            <img
              src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/whatsapp-white.svg"
            />
            Enviar por whatsapp
          </button>
        </div>
        <div
          *ngIf="is_loading"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
          "
        >
          <mat-spinner *ngIf="is_loading" diameter="30"></mat-spinner>
        </div>
      </div>
    </div>
  </div>
</div>
