<span *ngIf="item.title">{{ item.title }}</span>
<div class="filter-row">
    <div *ngIf="item.type == 'yesno'" style="display: flex; width: 100%; justify-content: space-between; gap: 8px; padding: 0!important;">
        <div class="field-option yes-option">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 8L7.33333 9.33333L10 6.66667M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z" stroke="#20A155" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Sí
        </div>
        <div class="field-option no-option">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.66667 6.66667L9.33333 9.33333M9.33333 6.66667L6.66667 9.33333M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z" stroke="#D61B1A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            No
        </div>
    </div>

    <div *ngIf="item.type == 'date'" class="background-blue">
        <img src="/assets/icons/calendar.png" alt="calendar" width="16" height="16">{{item.placeholder}} <span>18/01/2024</span>
    </div>

    <input *ngIf="item.type == 'input'" type="text" [placeholder]="item.placeholder">

    <div class="icon-button"><img src="/assets/icons/icon-trash.png" alt="trash" width="16" height="16"></div>
</div>