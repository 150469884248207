export const Perfil =[
    null,
    'Cliente',
    'Vendor',
    'Comercial',
    'Comité',
    'Administrador',
    'Director General',
    'Vendedor',
    'Gerente Comercial',
    'Super Comercial',
    'Backoffice',
    'Super Vendor',
    'Director Comercial',
    'Director de Operaciones',
    'Gerente de Operaciones',
    'Mesa de Control',
    'Gerente de Crédito',
    'Gestión de Activos',
    'Marketing',
    'Gerente de Personal',
    'Seguros',
]