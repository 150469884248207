import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Perfil } from '../../_models/perfiles'

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  tipo_dashboard = ''

  puede_crear_perfil = [6,8,10,12]
  puede_reasignar_tramite = [6,12,8]
  puede_reasignar_usuario = [6,12,8]
  puede_consulta_buro = [2,7]
  puede_crear_tramite = [6,12,8,2,7,3,9,10,11]
  puede_configuraciones = [4,6]
  puede_ver_tir = [6,12,14,15,16]
  puede_ver_promociones = [6,12]
  puede_admin_vendor = [3,6,10,12,14,15,16]
  puede_reporte_buro = [4,6,10,3]


  admin_vendors = false
  tir = false
  crear_perfil = true
  reasignar_tramite = true
  reasignar_usuario = true
  consulta_buro = true
  crear_tramite = true
  configuraciones = true
  ver_promociones = true
  reporte_buro = true

  perfil = null
  id = 0

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    const user = JSON.parse( localStorage.getItem('currentUser') )
    this.perfil = Number(user.perfil)
    this.id = Number(user.id)
    this.tipo_dashboard = Perfil[this.perfil]
    console.log(this.perfil);
    console.log(![2,7,11].includes(this.perfil));
    
    this.setPermissions()
  }

  To( navigate ) {
    console.log(navigate);
	   let params = {};
	   if (navigate == 'crear_cotizacion') {
		params = {queryParams: {tramite: true}};
	}
    this.router.navigate([navigate], params );
  }

  setPermissions(){
    const NOT_MATCH = -1
    
    this.admin_vendors = this.puede_admin_vendor.indexOf(this.perfil) == NOT_MATCH
    this.crear_perfil = this.puede_crear_perfil.indexOf(this.perfil) == NOT_MATCH
    this.reasignar_tramite = this.puede_reasignar_tramite.indexOf(this.perfil) == NOT_MATCH
    this.reasignar_usuario = this.puede_reasignar_usuario.indexOf(this.perfil) == NOT_MATCH
    this.consulta_buro = this.puede_consulta_buro.indexOf(this.perfil) == NOT_MATCH
    this.crear_tramite = this.puede_crear_tramite.indexOf(this.perfil) == NOT_MATCH
    this.configuraciones = this.puede_configuraciones.indexOf(this.perfil) == NOT_MATCH
    this.tir = this.puede_ver_tir.indexOf(this.perfil) == NOT_MATCH
    this.ver_promociones = this.puede_ver_promociones.indexOf(this.perfil) == NOT_MATCH
    this.reporte_buro = this.puede_reporte_buro.indexOf(this.perfil) == NOT_MATCH
    
    console.log(
      {admin_vendors: this.admin_vendors,
      crear_perfil: this.crear_perfil,
      reasignar_tramite: this.reasignar_tramite,
      reasignar_usuario: this.reasignar_usuario,
      consulta_buro: this.consulta_buro,
      crear_tramite: this.crear_tramite,
      configuraciones: this.configuraciones,
      tir: this.tir,
      ver_promociones: this.ver_promociones,}
    );
    
  }

  ShowHideCatalogo() {
    const menu = document.getElementById("catalogo-menu");
    menu.style.display = (menu.style.display === "none") ? "block" : "none";
  }
}
