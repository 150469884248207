<div class="dashboard-container">
    <div class="header">
        <img class="logo" mat-button height="60px" width="auto" [routerLink]="['/dashboard']" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/logo-header.svg" alt="">
        <div>
            <button mat-button routerLink="/changePassword">Cambiar Contraseña
                <svg style="height: 20px; width: auto;" xmlns="http://www.w3.org/2000/svg" width="31.992" height="31.992" viewBox="0 0 31.992 31.992"><defs><style>.a{fill:#3ca2d6;}</style></defs><path class="a" d="M18,2a16,16,0,1,0,16,16A16.006,16.006,0,0,0,18,2Zm0,1.391A14.6,14.6,0,1,1,3.391,18,14.594,14.594,0,0,1,18,3.391Zm0,4.868a4.164,4.164,0,0,0-4.173,4.173v2.086h-.7A2.1,2.1,0,0,0,11.041,16.6V23.56a2.1,2.1,0,0,0,2.086,2.086h9.737a2.1,2.1,0,0,0,2.086-2.086V16.6a2.1,2.1,0,0,0-2.086-2.086h-.7V12.432A4.164,4.164,0,0,0,18,8.259ZM18,9.65a2.724,2.724,0,0,1,2.782,2.782v2.086H15.214V12.432A2.724,2.724,0,0,1,18,9.65Zm-4.868,6.259H14.4a.7.7,0,0,0,.227,0h6.728a.7.7,0,0,0,.227,0h1.281a.693.693,0,0,1,.7.7V23.56a.693.693,0,0,1-.7.7H13.128a.693.693,0,0,1-.7-.7V16.6A.693.693,0,0,1,13.128,15.909ZM18,18a1.39,1.39,0,0,0-.7,2.594v.883a.7.7,0,1,0,1.391,0v-.884A1.389,1.389,0,0,0,18,18Z" transform="translate(-2 -2)"/></svg>
            </button>
            <button mat-button (click)="logout()">Cerrar Sesión
                <svg style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="32" height="31.992" viewBox="0 0 32 31.992"><defs><style>.a{fill:#3ca2d6;}</style></defs><path class="a" d="M18,2A16,16,0,1,0,30.9,27.449a.7.7,0,1,0-1.121-.823,14.6,14.6,0,1,1,0-17.259A.7.7,0,1,0,30.9,8.543,15.976,15.976,0,0,0,18,2Zm9.73,9.73a.7.7,0,0,0-.485,1.194L31.617,17.3H16.6a.7.7,0,1,0,0,1.391H31.617l-4.377,4.377a.7.7,0,1,0,.984.984l5.5-5.5a.7.7,0,0,0,0-1.107l-5.5-5.5A.7.7,0,0,0,27.726,11.73Z" transform="translate(-2 -2)"/></svg>
            </button>
        </div>
    </div>

    <div class="body">
        <div class="title">
            <h2>Reasignar perfil</h2>
        </div>
        <div class="formulario">
			<!-- <form [formGroup]="createForm" #create="ngForm" (ngSubmit)="onSubmit()"></form> -->
				<div class="row" style="margin: 0;">
					<div class="col-4"><h2 style="margin: 0;">¿Qué perfil<br>deseas reasignar?</h2></div>
				</div>
				<div class="row" style="margin: 0;">
					<div class="col-4">Elige al usuarios a reasignar y al usuario que estará a su cargo</div>
				</div>
				<div class="row pad">
					<div class="sel">
						<div class="dark-theme">
							<mat-label>Usuario a reasignar</mat-label>
							<div class="control">
								<mat-select [(ngModel)]="primerSelect" required>
									<mat-option [value]="0">Seleccionar</mat-option>
									<mat-option *ngFor="let usr of usrReassign" [value]="usr.id">{{ usr.nombre_cliente }}</mat-option>
								</mat-select>
							</div>
							<mat-error *ngIf="submited && primerSelect == 0" [ngClass]="{'invalid-input': submited && primerSelect == 0}">Campo obligatorio</mat-error>
						</div>
					</div>
					<div style="margin: 0 20px;">
						<svg xmlns="http://www.w3.org/2000/svg" width="28" height="30" viewBox="0 0 41 46"><defs><style>.a{fill:#fff;}</style></defs><path class="a" d="M35,3.586l.607.607a1,1,0,0,1,.186.184l0,.006,11.91,11.91a1,1,0,1,1-1.414,1.414L36,7.414V38a7.015,7.015,0,0,1-7,7H3a1,1,0,1,1,0-2H29a4.984,4.984,0,0,0,5-5V7.414L23.707,17.707a1,1,0,1,1-1.414-1.414L34.209,4.377a1,1,0,0,1,.187-.187Z" transform="translate(45 -2) rotate(90)"/></svg>
					</div>
					<div class="sel">
						<div class="dark-theme" >
							<div classs="control">
								<mat-label>Usuario a su cargo</mat-label>
								<mat-select [(ngModel)]="segundoSelect" required>
									<mat-option [value]="0">Seleccionar</mat-option>
									<mat-option *ngFor="let usr of usrCargo" [value]="usr.id">{{ usr.nombre_cliente }}</mat-option>
								</mat-select>
							</div>
							<mat-error *ngIf="submited && segundoSelect == 0" [ngClass]="{'invalid-input': submited && segundoSelect == 0}">Campo obligatorio</mat-error>
						</div>
					</div>
				</div>
            <!-- </form> -->
		</div>
		<mat-progress-spinner *ngIf="loading" mode="indeterminate" diameter="25"></mat-progress-spinner>
        <button *ngIf="!loading" class="button-next" mat-mini-fab (click)="onSubmit()">
			<!-- Reasignar -->
			<mat-icon>navigate_next</mat-icon>
		</button>
    </div>
    <app-socials-footer style="width:100%;"></app-socials-footer>
</div>