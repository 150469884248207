<div class="editar-contacto">
    <div class="title">Editar {{tipoContacto}}</div>
    <div class="column">
        <div>
            <div class="input">
                <label for="">Nombre</label>
                <input type="text" [(ngModel)]="contacto.nombreCompleto">
            </div>
            <div class="input">
                <label for="">Apellido paterno</label>
                <input type="text" [(ngModel)]="contacto.apellidoPaterno">
            </div>
            <div class="input">
                <label for="">Apellido materno</label>
                <input type="text" [(ngModel)]="contacto.apellidoMaterno">
            </div>
            <div class="input">
                <label for="">RFC</label>
                <input type="text" [(ngModel)]="contacto.rfc">
            </div>
            <div class="input">
                <label for="">Email</label>
                <input type="text" [(ngModel)]="contacto.email">
            </div>
        </div>
        <div>
            <div class="input">
                <label for="">Calle</label>
                <input type="text" [(ngModel)]="direccion.calle">
            </div>
            <div style="display: flex; gap: 8px;">
                <div class="input">
                    <label for="">Numero exterior</label>
                    <input type="text" [(ngModel)]="direccion.numeroExterior">
                </div>
                <div class="input">
                    <label for="">Numero interior</label>
                    <input type="text" [(ngModel)]="direccion.numeroInterior">
                </div>
            </div>
            <div class="input">
                <label for="">C.P,</label>
                <input type="text" [(ngModel)]="direccion.cp" (input)="BuscarDireccion()">
            </div>
            <div class="input">
                <label for="">Colonia</label>
			    <app-select-colonias [colonia]="direccion.colonia" [colonias]="coloniasDisponibles" (onSelected)="direccion.colonia = $event"></app-select-colonias>
                <!-- <input type="text" [(ngModel)]="direccion.colonia"> -->
            </div>
            <div class="input">
                <label for="">Municipio</label>
                <input type="text" [(ngModel)]="direccion.municipio" readonly>
            </div>
            <div class="input">
                <label for="">Ciudad</label>
                <input type="text" [(ngModel)]="direccion.ciudad" readonly>
            </div>
            <div class="input">
                <label for="">Estado</label>
                <input type="text" [(ngModel)]="direccion.estado" readonly>
            </div>
        </div>
    </div>
    <div class="buttons">
        <button class="btn-cancel" (click)="Cerrar()">Cancelar</button>
        <button *ngIf="!loading" class="btn-primary-main" (click)="Actualizar()">Actualizar</button>
        <span *ngIf="loading" style="width: 210px; display: flex; justify-content: center;">
            <mat-spinner diameter="20"></mat-spinner>
        </span>
    </div>
</div>