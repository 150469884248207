import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { UserService, AuthenticationService } from 'src/app/_services';
import { SolicitudService } from 'src/app/_services/solicitud.service';
import { saveAs } from 'file-saver';
import { RechazarDocumentoComponent } from 'src/app/_dialogs/rechazar-documento/rechazar-documento.component';
import { EntrevistaReferencias } from '../entrevista/Entrevista';

@Component({
  selector: 'app-comentarios',
  templateUrl: './comentarios.component.html',
  styleUrls: ['./comentarios.component.scss']
})
export class ComentariosComponent implements OnInit {
  @Input() analisisPf: any;  
  @Output() analisisPfChange = new EventEmitter<any>();
  ngOnChanges(changes: SimpleChanges) {
    if (changes.analisisPf && changes.analisisPf.currentValue) {
      console.log("Nuevo valor de analisisPf COMENTARIOS:", this.analisisPf);
      this.AnalisisPF = { ...this.analisisPf };
      this.ResolucionDatos.colateral_comentarios = this.AnalisisPF.colateral.comentarios_colateral
      this.ResolucionDatos.capacidad_pago_comentarios = this.AnalisisPF.capacidad_pago.comentarios_capacidad_pago
      this.ResolucionDatos.caracter_comentarios = this.AnalisisPF.caracter.comentarios_caracter
      console.log("REFERENCIAS COMENTARIOS 2", this.AnalisisPF.referencias)
      this.Referencias = this.AnalisisPF.referencias
      this.validarReferencias()
      
      this.AnalisisPF.comentarios_resolucion_comite = this.Resolucion.comentarios
      for (let i = 0; i < this.AnalisisPF.participantes.length; i++) {
        const participante = this.AnalisisPF.participantes[i];
        const numero_participante = `participante_${this.numeroToTexto(i + 1)}`;
        console.log("PARTICIPANTE", participante)
        console.log("PARTICIPANTE", numero_participante)
        this.ParticipanteSeleccionado(participante, numero_participante);
       
      }
      this.cdr.detectChanges();
      
    }
  }
  AnalisisPF:any = {};
  public code = '';
  public codeValido = '';
  public idx = 3;
  public isValid = false;
  public invalidCode = false;
  public sent = false;
  public submitCode = false;
  public additionalInformation = false;
  public optSelected = 0;
  public comentarios = '';
  public loading = false;
  public participantes = false;
  public suplente1 = false;
  public suplente2 = false;
  public suplente3 = false;
  public suplente4 = false;

  folio = null;
  id_cliente = null;
  tipo_persona = null;
  submit = false;
  ActualizoInformacion = false;
  Referencias = null;
  dataDocumentos = {
    folio: '', tipo_persona: 0, tipo_aval: 0
  }

  Documentos = null
  documentosSolicitanteFaltantes = [];
  documentosAvalFaltantes = [];
  

  documentosCompletos = true

  constructor(private dialog: MatDialog,
              private router: Router,
              private userService: UserService,
              private solicitudService: SolicitudService,
              private authenticationService: AuthenticationService,
              private cdr: ChangeDetectorRef) { }
  ResolucionDatos: any = {};
  Resolucion: any = {
    participante_uno_puesto: 'Puesto 1',
    participante_dos_puesto: 'Puesto 2',
    participante_tres_puesto: 'Puesto 3',
    participante_cuatro_puesto: 'Puesto 4'
  };
  Actualizado = false;
  Aprobado = false;
  AprobadoCondicional = false;
  NoAprobado = false;
  
  ngOnInit(): void {

    localStorage.setItem('comentarios', 'true');
    const {
      folio,
      id_cliente,
      tipo_persona,
      tipo_aval = 1,
      datosCliente,
    } = JSON.parse( localStorage.getItem('client_details'));
    this.dataDocumentos = { folio, tipo_persona, tipo_aval };
    const id_solicitud = localStorage.getItem('id_solicitud')
    this.Resolucion.id_solicitud = Number(id_solicitud)

    console.log(datosCliente)
    this.folio = folio;
    this.id_cliente = id_cliente
    this.tipo_persona = tipo_persona
    console.log('El folio es: ', folio);
    this.ObtenerStatusDocumentos();
    this.userService.ObtenerComentarios(Number(this.folio))
    .subscribe( res => {
      console.log(res);
      this.ResolucionDatos = res;
      this.Resolucion.id_solicitud = res.id_solicitud;
      this.Resolucion.puntuacion_final = res.puntuacion_total;
      this.getResolucion(res.id_solicitud);
    }, err => console.log(err));
    
   
      
      const guardado = {id_cliente, folio, parte: 2, tipo_persona}
      console.log(guardado)
      this.solicitudService.geteReferencias({guardado })
        .subscribe( ({body: res}) => {
          console.log("REFERENC", res)
          this.Referencias.forEach( (ref, i) => {
            ref.nombre = res[`rp_nombre${i +1}`]
            ref.domicilio = res[`rp_direccion${i +1}`]
            ref.telefono = res[`rp_telefono${i +1}`]
            ref.parentezco = res[`rp_parentesco${i +1}`]
            ref.lo_recomienda = res[`lo_recomienda${i +1}`]
            ref.ocupacion = res[`ocupacion${i +1}`]
            if( tipo_persona == 2 ){
              ref.nombre = res[`proveedor_nombre${i +1}`]
            }
          })
        }, err => console.log(err))
  
        console.log("REFERENCIAS COMENTARIOS", this.Referencias)
   
  
    
  }
  numeroToTexto(numero: number): string {
    const textos = ['uno', 'dos', 'tres', 'cuatro'];
    return textos[numero - 1] || '';
  }


  public getResolucion(idSolicitud: any) {
    this.solicitudService.getResolucion(idSolicitud)
        .subscribe((resp: any) => {
          console.log(resp);
          if (resp.status && resp.status === 200) {
            this.sent = true;
            this.Resolucion.id_resolucion = resp.body.id_resolucion || false;
            this.Resolucion.participante_uno = resp.body.participante_uno || false;
            this.Resolucion.participante_dos = resp.body.participante_dos || false;
            this.Resolucion.participante_tres = resp.body.participante_tres || false;
            this.Resolucion.participante_cuatro = resp.body.participante_cuatro || false;
            this.Resolucion.comentarios = resp.body.comentarios;
            this.codeValido = resp.body.codigo;
            // console.log('res.codigo :', resp.body.codigo);
            // console.log('this.codeValido :', this.codeValido);
          } else {
            //this.Resolucion.id_resolucion = 1;
          }
        }, (err: any) => {
          this.sent = false;
          console.log(err);
          //this.Resolucion.id_resolucion = 1;
        });
  }

  ObtenerPDF() {
    this.Actualizado = true;
  }

  ActualizarInformacion() {
    this.ActualizoInformacion = true;
  }

  async Save() {
    // Se quita flujo de información adicional
    // if (this.Resolucion.id_resolucion == 3) {
    //   this.additionalInformationEvent();
    // } else {
      console.log(this.Resolucion)
      this.AnalisisPF.comentarios_resolucion_comite = this.Resolucion.comentarios
      this.AnalisisPF.id_resolucion_comite = this.Resolucion.id_resolucion
      this.AnalisisPF.participantes[0].nombre =  this.Resolucion.participante_uno_nombre
      this.AnalisisPF.participantes[0].puesto =  this.Resolucion.participante_uno_puesto
      this.AnalisisPF.participantes[0].asistencia =  this.Resolucion.participante_uno
      this.AnalisisPF.participantes[0].iniciales =  this.Resolucion.participante_uno_iniciales
      this.AnalisisPF.participantes[1].nombre =  this.Resolucion.participante_dos_nombre
      this.AnalisisPF.participantes[1].puesto =  this.Resolucion.participante_dos_puesto
      this.AnalisisPF.participantes[1].asistencia =  this.Resolucion.participante_dos
      this.AnalisisPF.participantes[1].iniciales =  this.Resolucion.participante_dos_iniciales
      this.AnalisisPF.participantes[2].nombre =  this.Resolucion.participante_tres_nombre
      this.AnalisisPF.participantes[2].puesto =  this.Resolucion.participante_tres_puesto
      this.AnalisisPF.participantes[2].asistencia =  this.Resolucion.participante_tres
      this.AnalisisPF.participantes[2].iniciales =  this.Resolucion.participante_tres_iniciales
      this.AnalisisPF.participantes[3].nombre =  this.Resolucion.participante_cuatro_nombre
      this.AnalisisPF.participantes[3].puesto =  this.Resolucion.participante_cuatro_puesto
      this.AnalisisPF.participantes[3].asistencia =  this.Resolucion.participante_cuatro
      this.AnalisisPF.participantes[3].iniciales =  this.Resolucion.participante_cuatro_iniciales
    try {
      
      if(this.codeValido){
      const resGuardado: any = await this.solicitudService.GuardarAnalisisCredito(this.AnalisisPF);
  
      console.log(resGuardado);
  
      if (resGuardado.statusCode === 200) {
        this.loading = false;
        this.analisisPfChange.emit(this.AnalisisPF);
        if(this.Resolucion.id_resolucion){

          const res = await this.userService.ActualizaResolucionStatus({
            folio: this.folio,
            id_solicitud: this.Resolucion.id_solicitud
          }).toPromise();
          await this.userService.changeStatus(this.folio, 4, 3).toPromise();
      
        }
        this.dialog.open(MessageBoxComponent, {
          width: '500px',
          data: {
            text: 'Éxito',
            text2: 'Se han creado los Comentarios',
            // text3: 'Revisa tu correo para descargar el documento',
            type: 'success',
            icon: 'success'
          }
        });
        this.router.navigate(['/dashboard', 'comite', this.folio]);

        
      } else {
        this.loading = false;
      }
    }
    } catch (err) {
      console.error('err :', err);
      this.dialog.open(MessageBoxComponent, {
        width: '500px',
        data: {
          text: "El servicio no está disponible por el momento",
          text3: "Vuelve a intentarlo.",
          type: "error",
          icon: "error"
        }
      });
    }
    // }
  }
  

  ActualizarComite() {
    if( !this.userService.alertService.isOnline() ) return
    this.Actualizado = true
    const mail = JSON.parse(localStorage.getItem('currentUser')).email;
    const cliente = JSON.parse(localStorage.getItem('client_details'));
    console.log('--Z  ', mail);

    console.log('solicitud', JSON.parse(localStorage.getItem('solicitud')));

    const resolucionFinal = {
      1: 'Aprobada',
      2: 'Aprobada Condicionalmente',
      3: 'Información Adicional',
      4: 'Rechazada',
    };

    this.submit = true;

    if ( !this.Resolucion.id_resolucion ||
      !this.Resolucion.comentarios ||
      !this.Resolucion.participante_uno ||
      !this.Resolucion.participante_dos ||
      !this.Resolucion.participante_tres ||
      !this.Resolucion.participante_cuatro) { this.Actualizado = false; return; }

    this.checarParticipantes()

    this.submitCode = true;

    if (this.code.length < 6) {
      this.Actualizado = false
      return this.dialog.open(MessageBoxComponent, {
        data: {
          text: 'Por favor ingrese el código de resolución.',
          type: 'error',
          icon: 'error'
        }
      });
    }

    if (this.code !== this.codeValido ) {
      this.Actualizado = false
      return this.dialog.open(MessageBoxComponent, {
        data: {
          text: 'El código de resolución es incorrecto.',
          type: 'error',
          icon: 'error'
        }
      });
    }

    console.log('limpio');

    const request = {
      email: mail,
      opcion: 2,
      filename: 'comentarios.pdf',
      subjec: 'Comentarios',
      type: 13,
      params: {
        comentariosCaracter: this.ResolucionDatos.caracter_comentarios,
        comentariosCapacidad: this.ResolucionDatos.capacidad_pago_comentarios,
        comentariosColateral: this.ResolucionDatos.colateral_comentarios,
        comentarioResolucion: this.Resolucion.comentarios,
        puntosResolucion: this.ResolucionDatos.puntuacion_total,
        resolucionComite: resolucionFinal[this.Resolucion.id_resolucion],
        nombreSolicitante: cliente.cliente,
        activoArrendar: cliente.activo_nombre,
        folio: cliente.folio,
        participantes: [
          {nombre: this.Resolucion.participante_uno_nombre, puesto: this.Resolucion.participante_uno_puesto},
          {nombre: this.Resolucion.participante_dos_nombre, puesto: this.Resolucion.participante_dos_puesto},
          {nombre: this.Resolucion.participante_tres_nombre, puesto: this.Resolucion.participante_tres_puesto},
          {nombre: this.Resolucion.participante_cuatro_nombre, puesto: this.Resolucion.participante_cuatro_puesto},
        ]
      }
    };

    console.log('request: ', request);

    if (this.Resolucion.comentarios && (this.Resolucion.participante_uno
      || this.Resolucion.participante_dos || this.Resolucion.participante_tres || this.Resolucion.participante_cuatro)) {
      console.log('this.Resolucion :', this.Resolucion);
      this.enviaEmail(request);
      }
  }

  enviaEmail(request: { filename: any; }) {
    this.solicitudService.sendMailCommentaries(request)
    .subscribe( (res: any) => {
      console.log('res: ', res);
      this.Actualizado = true;

      const file = new Blob([res], {type: 'application/pdf'});
      /*var fileURL = URL.createObjectURL(file);
      window.open(fileURL);*/
      saveAs(file, request.filename);

      const dialogref = this.dialog.open(MessageBoxComponent, {
        width: '500px',
        data: {
          text: 'Éxito',
          text2: 'Se ha guardado la información',
          text3: 'Revisa tu correo para descargar el documento',
          type: 'success',
          icon: 'success'
        }
      });
    }, (err) => {
      console.log('error: ', err);
      this.Actualizado = false
      this.dialog.open(MessageBoxComponent, {
        data: {
          text: 'El servicio no está disponible.',
          text3: 'Vuelve a intentarlo',
          type: 'error',
          icon: 'error'
        }
      });
    });
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  public generateCode() {

    if (this.userService.alertService.isOnline()){
      this.submit = true;
  
      if (!this.Resolucion.id_resolucion ||
        !this.Resolucion.comentarios ||
        !this.Resolucion.participante_uno ||
        !this.Resolucion.participante_dos ||
        !this.Resolucion.participante_tres ||
        !this.Resolucion.participante_cuatro) {
          this.Actualizado = false
          return;
      }
  
      this.loading = true;
      this.solicitudService.CodeResolucion(this.Resolucion)
      .subscribe((res: any) => {
        this.loading = false;
        // console.log(res);
        this.getResolucion(this.Resolucion.id_solicitud);
      },
      (err: any) => {
        console.log(err);
        this.loading = false;
        this.dialog.open(MessageBoxComponent, {
          data: {
            text: 'Hubo un error al guardar la información',
            text2: err,
            type: 'error',
            icon: 'error'
          }
        });
      });
    }

  }

  public cancelCode() {
    if (this.userService.alertService.isOnline()){
      this.loading = true;
      this.solicitudService.cancelResolucion(this.Resolucion.id_solicitud)
      .subscribe((res: any) => {
        this.loading = false;
        console.log(res);
        this.submitCode = false;
        this.getResolucion(this.Resolucion.id_solicitud);
      },
      (err: any) => {
        console.log(err);
        this.loading = false;
        this.dialog.open(MessageBoxComponent, {
          data: {
            text: 'Hubo un error al guardar la información',
            text2: err,
            type: 'error',
            icon: 'error'
          }
        });
      });
    }
  }

  public onFullFill(data: any) {
   if (data.length === 6) {
      this.code = data;
      this.isValid = true;
      this.invalidCode = false;
    } else if (isNaN(Number(data.key)) ) {
      this.code = '';
      this.isValid = false;
      this.invalidCode = true;
      return false;
    }
  }

  public additionalInformationEvent() {
    // console.log(this.additionalInformation);
    const dataAlert = {
      text: 'Información adicional',
      text3: `Se requiere información adicional \n¿En qué proceso falta está\n información?`,
      type: 'confirm',
      options: 'options',
      icon: 'info',
      opt1: 'Confirmación',
      opt2: 'Expediente',
  
      confirmopt: (val: number) => this.optSelected = val,
    };

    const dialogref = this.dialog.open(MessageBoxComponent, {
      width: '500px',
      data: dataAlert,
    });

    dialogref.afterClosed().subscribe((res: any) => {
      if (res) {
        console.log('res1 :', res);
        if (this.optSelected === 1) {
          console.log('Confirmación');
          this.confirmacionEvent();
        } else if (this.optSelected === 2) {
          console.log('Expediente');
          this.router.navigateByUrl(`/dashboard/cliente/${this.folio}/carga_documentos`);
        }
      } else {
        console.log('Salida sin opcion :', res);
      }
    });
  }

  private confirmacionEvent () {
    const dialogRef = this.dialog.open(RechazarDocumentoComponent, {
      width: "550px",
      height: "350px",
      data: {
        textBtn: 'Cancelar Cotización'
      }
    })

    dialogRef.afterClosed().subscribe(comentarios => {
      console.log(comentarios);
      this.comentarios = comentarios;
      this.userService.ObtenerIdCotizacion({
        folio: this.folio, 
        id_cliente: this.id_cliente
      }).subscribe((res: any) => {
        this.updateStatusCotizacion(res.id_cotizacion);
        // if (res.status && res.status === 200) {
        //   debugger;
        // }
      }, (err: any)=>{
        console.log(err)
        this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data:{
            text: "El servicio no está disponible por el momento",
            text3: "Vuelve a intentarlo.",
            type: "error",
            icon: "error"
          }
        });
      });
    }, err=> console.log(err))
  }

  private updateStatusCotizacion(id_cotizacion: number) {

    const request = {
      status: 2,
      id_cotizacion: id_cotizacion,
      id_cliente: this.id_cliente,
      comentarios: this.comentarios,
      folio: this.folio,
    };

    this.userService.CambiarStatusCotizacion(request).subscribe((res: any) => {
        this.DeleteProcessState();
    }, (err: any) => {
      console.error('err :', err);
      this.dialog.open(MessageBoxComponent, {
        width: '500px',
        data: {
          text2: 'El servicio no está disponible por el momento.',
          text3: 'Vuelve a intentarlo',
          type: 'error',
          icon: 'error'
        }
      });
    });
  }

  public DeleteProcessState(): void {
    this.userService.DeleteProcessState({
      folio: this.folio,
      id_cliente: this.id_cliente
    }).subscribe((res: any) => {
      console.log(res);
      this.router.navigateByUrl(`/dashboard`);
    }, (err: any) => {
      console.error('err :', err);
      this.dialog.open(MessageBoxComponent,{
        width: '500px',
        data: {
          text: "El servicio no está disponible por el momento",
          text3: "Vuelve a intentarlo.",
          type: "error",
          icon: "error"
        }
      })
    });
  }

  checarParticipantes() {
    this.participantes = (
      this.Resolucion.participante_uno ||
      this.Resolucion.participante_dos )

      console.log(this.Resolucion.participante_uno,this.Resolucion.participante_dos,this.Resolucion.participante_tres,this.Resolucion.participante_cuatro,this.participantes);
      
  }
  
  validarReferencias(){
    console.log("ENTRO A VALIDAR REFERENCIAS");
    const rec = this.Referencias;
    console.log("ENTRO A VALIDAR REFERENCIAS", rec);
    let count = 0;
  
    for(let i = 0; i < rec.length; i++ ){
      if(rec[i][`lo_recomienda${i + 1}`] === true){
        ++count
      }
    }
    this.Aprobado = true; 
  
    switch(count){
      case 0:
        console.log("ENTRO NO APROBADA");

        this.NoAprobado = true;
        this.Resolucion.id_resolucion = 3;
        this.cdr.detectChanges();

        break;
      case 1:
        console.log("ENTRO APROBADA");
        this.Aprobado = true;
        this.Resolucion.id_resolucion = 1;
        this.cdr.detectChanges();

        break;
    }
  }
  

  ObtenerStatusDocumentos() {
    if (!this.userService.alertService.isOnline()) return;
    this.userService.ObtenerStatusDocuments(this.dataDocumentos).subscribe(
      (res) => {
         this.Documentos = res
        this.Documentos.DocumentosSolicitante.forEach(
          (documento) => {
            if (documento.tipo_documento != 21 && documento.status != 3 && documento.requerido) {
              console.log("Entro a documentos solicitante")
              this.Resolucion.id_resolucion = 3
              this.documentosCompletos = false
              this.documentosSolicitanteFaltantes.push(documento);

            }
          }
        );
        this.Documentos.DocumentosAval.forEach(
          (documento) => {
            // (status += documento.status)
            if (documento.status != 3  && documento.requerido) {
              console.log("Entro a documentos aval")
              this.Resolucion.id_resolucion = 3
              this.documentosCompletos = false
              this.documentosAvalFaltantes.push(documento);

            }
          }
        );
    
        console.log("DOCUMENTOS", this.Documentos)
      },
      (err) => {
        this.dialog.open(MessageBoxComponent, {
          width: "500px",
          data: {
            text: "El servicio no está disponible por el momento",
            text3: "Vuelve a intentarlo.",
            type: "error",
            icon: "error",
          },
        });
      }
    );
  }

  ParticipanteSeleccionado(participante, numero_participante){
    console.log(participante);
    this.Resolucion[`${numero_participante}_nombre`] = participante.nombre
    this.Resolucion[`${numero_participante}_puesto`] = participante.puesto

  }
}



/*
    this.userService.GuardarResolucion(this.Resolucion)
      .subscribe( res => {
        this.Actualizado = true
          window.print()
          const dialogref = this.dialog.open(MessageBoxComponent, {
            width: "500px",
            data: {
              text: "Se ha Actualizado correctamente.",
              type: "success",
              icon: "success"
            }
          })
          if( !this.Resolucion.id_resolucion ||
              !this.Resolucion.comentarios ||
              !this.Resolucion.participante_uno ||
              !this.Resolucion.participante_dos) return

          if( !this.Resolucion.id_resolucion ) {
            return this.dialog.open(MessageBoxComponent, {
              data: {
                text: "Por favor selecciona la resolución.",
                type: "error",
                icon: "error"
              }
            })
          }
          dialogref.afterClosed().subscribe( _=> window.print() )
      },
      err => {
        console.log(err);
        this.dialog.open(MessageBoxComponent, {
          data: {
            text: "Hubo un error al actualizar Comité",
            text2: err,
            type: "error",
            icon: "error"
          }
        })
      })
  }*/
