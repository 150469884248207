import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import CryptoJS from 'crypto-js';
import sha256 from 'crypto-js/sha256';

import { User } from '../_models';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { SolicitudService } from './solicitud.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient, private userService: UserService, private router: Router,
        private solicitudService: SolicitudService
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        if (!this.currentUserSubject) {
            localStorage.clear()
        }
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(email: string, password: string) {
        const hasPassword = sha256(password).toString(CryptoJS.enc.Hex);
        password = hasPassword;
        console.log(`URL:> ${environment.config.apiUrl}`);

        return this.http.post<any>(`${environment.config.apiUrl}authentication/login`, { email, password },
        {observe: 'response' as 'body'})
        .pipe(map(user => {
            console.log('Header: ', (new Headers()));
            console.log('User: ', user, 'User header: ', user.headers);
            // if (user.body && user.headers.get('Authorization')) {
            if (user.body && user.body.id) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                const dataUser = user.body;
                // dataUser.token = user.headers.get('authorization');
                localStorage.setItem('currentUser', JSON.stringify(dataUser));
                localStorage.setItem("tipoPersona", dataUser.typePerson)
                this.userService.perfil = Number( dataUser.perfil )
                this.currentUserSubject.next(user.body);
            }

            return user;
        }));
    }

    logout() {
        const dataUser = JSON.parse(localStorage.getItem('currentUser'));
        localStorage.clear()
        this.solicitudService.Clear()
        
        this.logoutService(dataUser)
            .pipe(first())
            .subscribe(
                data => {
                    if (data.status == 200) {
                        // remove user from local storage to log user out
                        // localStorage.removeItem('currentUser');
                        this.router.navigate(['/login']);

                        return true;
                    }
                },
                error => {
                    console.log('e', error);
                });
        this.currentUserSubject.next(null);
    }

    logoutService(dataUser) {
        localStorage.clear()
        this.solicitudService.Clear()
        return this.http.post<any>(`${environment.config.apiUrl}authentication/logout`, { email: dataUser.email },
        {observe: 'response' as 'body'})
        .pipe(map(resp => {
            return resp;
        }));
    }

    changePassword(user) {
        return this.http.post<any>(`${environment.config.apiUrl}authentication/login`, { user },
        {observe: 'response' as 'body'})
        .pipe(map(user => {
            if (user.body && user.headers.get('Authorization')) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                const dataUser = user.body;
                dataUser.token = user.headers.get('Authorization');
                localStorage.setItem('currentUser', JSON.stringify(dataUser));
                this.currentUserSubject.next(user.body);
            }

            return user;
        }));
    }
}
