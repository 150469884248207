import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PromocionesService {

  constructor(private http: HttpClient,) { }

  CrearPromocion(promocion) {
    return this.http.post<any>(`${environment.config.apiUrl}promociones`, promocion).toPromise();
  }

  ObtenerPromociones() {
    return this.http.get<any>(`${environment.config.apiUrl}promociones`).toPromise();
  }
}
