import { ChangeDetectorRef,Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, AuthenticationService, UserService } from '../../../../_services';
import { SolicitudService } from 'src/app/_services/solicitud.service';
import {referenciasM} from '../../modalSolicitudR';
import { customPatterns } from '../../../../_models/maskPattern'
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
/* tslint:disable */

@Component({
	selector: 'app-solicitud-r-p2',
	templateUrl: './solicitudRp2.component.html',
	styleUrls: ['./../../solicitudR.component.scss']
})

export class SolicitudRp2Component implements OnInit {

	@Input() tipoPersona: number;
	@Output() parte = new EventEmitter<number>();
	@Output() onBack = new EventEmitter<number>();
	// @Output() solicitudM = new EventEmitter<any>();
	// @Input() referenciasProveedor : any;
	

	customPattern = customPatterns
	dgFForm: FormGroup;
	dgMForm: FormGroup;
    loading = false;
    submitted = false;
	returnUrl: string;
	datosRef:any = {}
	perfil = null
	ref={}
    constructor(
        private formBuilder: FormBuilder,
        // private route: ActivatedRoute,
        private router: Router,
        // private authenticationService: AuthenticationService,
        private alertService: AlertService,
		private solicitudService: SolicitudService,
		private userService:UserService,
		private dialog:MatDialog
    ) {
        // redirect to home if already logged in

	}
    ngOnInit() {
		const currentUser = JSON.parse(localStorage.getItem('currentUser'))
		this.perfil = currentUser.perfil

        this.dgFForm = this.formBuilder.group({
            rp_nombre1: ['', [Validators.required]],
			rp_nombre2: ['', ],
			rp_nombre3: ['', ],
			ocupacion1: ['', [Validators.required] ],
			ocupacion2: ['', ],
			ocupacion3: ['', ],
			rp_direccion1: ['',[Validators.required] ],
			rp_direccion2: ['', ],
			rp_direccion3: ['', ],
			rp_telefono1: ['',[Validators.required] ],
			rp_telefono2: ['', ],
			rp_telefono3: ['', ],
			rp_parentesco1: ['',[Validators.required] ],
			rp_parentesco2: ['', ],
			rp_parentesco3: ['', ],
		});
		
		this.dgMForm = this.formBuilder.group({
			proveedor_nombre1: ['', [Validators.required]],
			proveedor_nombre2: ['', []],
			proveedor_nombre3: ['', []],
			proveedor_contacto1: ['', Validators.required],
			proveedor_contacto2: ['', []],
			proveedor_contacto3: ['', []],
			proveedor_puesto1: ['', Validators.required],
			proveedor_puesto2: ['', []],
			proveedor_puesto3: ['', []],
			proveedor_promedio_compras1: ['', Validators.required],
			proveedor_promedio_compras2: ['', []],
			proveedor_promedio_compras3: ['', []],
			proveedor_total1: ['', Validators.required],
			proveedor_total2: ['', []],
			proveedor_total3: ['', []]
		});

		const solicitud = this.solicitudService.getReferencia()
		const tipo_persona = this.tipoPersona
		const parte = 2

		const guardado = { ...solicitud, tipo_persona, parte}
		console.log("Guardado: ", guardado);
		
		this.getReferencias(guardado);
        // get return url from route parameters or default to '/'
        // this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/dashboard';
    }

    // convenience getter for easy access to form fields
	get f() { return this.dgFForm.controls; }
	get m() { return this.dgMForm.controls; }	

    async onSubmit(button: number) {
		this.dgMForm.markAllAsTouched();
		this.dgFForm.markAllAsTouched();
		if( !this.userService.alertService.isOnline() ) return
		//this.parte.emit(2);
		this.loading = true;
		var data: any;
		this.submitted = true;
		console.log("Ref ", this.dgFForm.value.rp_nombre1)
		console.log("REFERENCIA OCUPACION: ", this.dgFForm.value.rp_ocupacion1)
		// stop here if form is invalid
		if (this.tipoPersona == 1) {
		
			if(this.dgFForm.value.rp_nombre1 == ""){
				this.loading = false;
				this.dgFForm.value.rp_nombre1 = " "
				this.ShowDialog("error", "Por favor, complete los campos obligatorios.")
				return
			}
			if (this.dgFForm.invalid) {
				this.loading = false;
				this.ShowDialog("error", "Por favor, complete los campos obligatorios.")
				return;
			}
			data = this.dgFForm?.value;
			console.log("DATA", data)
		}else {
			if (this.dgMForm.invalid) {
				this.loading = false;
				console.log(this.dgMForm?.value);
				return;
			}
			data = this.dgMForm.value;
		}

		this.loading = true;
		console.log('before calling api', data, this.tipoPersona);
		(await this.solicitudService.saveReferencias(data, 2, this.tipoPersona, this.tipoPersona === 1 ? 2 : 1)).subscribe(
			async (res: any) => {
				console.log(res);
				// if (data.status <= 300) {
					this.loading = false;
					// this.alertService.success()
					if ( this.tipoPersona == 1){
						// this.alertService.success('Se guardó correctamente.')
						const dialogref = this.dialog.open(MessageBoxComponent, {
							width: "500px",
							data: {
								text: "Éxito",
								text3: "tu información se ha guardado",
								icon: "success",
								type: "success"
							}
						})
						dialogref.afterClosed().subscribe( _=> {
							this.userService.goToDashboard()
						})
					}else{
						if ( button === 1 ) { // go to dashboard
							// this.alertService.success('Se guardó correctamente.')
							this.userService.goToDashboard()
							
						} else { // next form
							this.parte.emit(3);
						}
					}
				// }
				// this.router.navigateByUrl('/login', { replaceUrl: true } );
			},
			(err: any) => {
				this.loading = false;
				// this.alertService.error(err.error.message);
				console.log('Error 2 :', err)
				this.dialog.open(MessageBoxComponent, {
					width: "500px",
					data: {
						text: "Error",
						text3: "tu información no se ha guardado",
						icon: "error",
						type: "error"
					}
				})
				//let er = JSON.parse(err);
			}
		);	
		let referencias ={}
		if(JSON.parse(localStorage.getItem('client_details')).tipo_persona==2){
			referencias={
				
					proveedor_nombre1:this.m.proveedor_nombre1.value,
					proveedor_nombre2:this.m.proveedor_nombre2.value,
					proveedor_nombre3:this.m.proveedor_nombre3.value,
					proveedor_contacto1:this.m.proveedor_contacto1.value,
					proveedor_contacto2:this.m.proveedor_contacto2.value,
					proveedor_contacto3:this.m.proveedor_contacto3.value,
					proveedor_puesto1:this.m.proveedor_puesto1.value,
					proveedor_puesto2:this.m.proveedor_puesto2.value,
					proveedor_puesto3:this.m.proveedor_puesto3.value,
					proveedor_promedio_compras1:this.m.proveedor_promedio_compras1.value,
					proveedor_promedio_compras2:this.m.proveedor_promedio_compras2.value,
					proveedor_promedio_compras3:this.m.proveedor_promedio_compras3.value,
					proveedor_total1:this.m.proveedor_total1.value,
					proveedor_total2:this.m.proveedor_total2.value,
					proveedor_total3:this.m.proveedor_total3.value
					
			}
		}else{
				referencias={
					referencias:{
					rp_nombre1 :this.f.rp_nombre1.value,
					rp_nombre2:this.f.rp_nombre2.value,
					rp_nombre3:this.f.rp_nombre3.value,
					ocupacion1:this.f.ocupacion1.value,
					ocupacion2:this.f.ocupacion2.value,
					ocupacion3:this.f.ocupacion3.value,
					rp_direccion1:this.f.rp_direccion1.value,
					rp_direccion2:this.f.rp_direccion2.value,
					rp_direccion3:this.f.rp_direccion3.value,
					rp_telefono1:this.f.rp_telefono1.value,
					rp_telefono2:this.f.rp_telefono2.value,
					rp_telefono3:this.f.rp_telefono3.value,
					rp_parentesco1:this.f.rp_parentesco1.value,
					rp_parentesco2:this.f.rp_parentesco2.value,
					rp_parentesco3:this.f.rp_parentesco3.value
				}
				}
			}

			this.tipoPersona==1?this.seveDynamoF(referencias):this.SendValues(referencias);

			//await this.solicitudService.GuardarSolicitud(JSON.parse(localStorage.getItem('client_details')).folio,{referencias});
	}
	
	setDataDynamo( res ){
		console.log("Este el sete data:",res);
		if( this.tipoPersona == 1 ){
			this.f.rp_nombre1.setValue( res.referencias.rp_nombre1 )
			this.f.rp_nombre2.setValue( res.referencias.rp_nombre2 )
			this.f.rp_nombre3.setValue( res.referencias.rp_nombre3 )
			this.f.ocupacion1.setValue( res.referencias.ocupacion1 )
			this.f.ocupacion2.setValue( res.referencias.ocupacion2 )
			this.f.ocupacion3.setValue( res.referencias.ocupacion3 )
			this.f.rp_direccion1.setValue( res.referencias.rp_direccion1 )
			this.f.rp_direccion2.setValue( res.referencias.rp_direccion2 )
			this.f.rp_direccion3.setValue( res.referencias.rp_direccion3 )
			this.f.rp_telefono1.setValue( res.referencias.rp_telefono1 )
			this.f.rp_telefono2.setValue( res.referencias.rp_telefono2 )
			this.f.rp_telefono3.setValue( res.referencias.rp_telefono3 )
			this.f.rp_parentesco1.setValue( res.referencias.rp_parentesco1 )
			this.f.rp_parentesco2.setValue( res.referencias.rp_parentesco2 )
			this.f.rp_parentesco3.setValue( res.referencias.rp_parentesco3 )
		}else {
			this.m.proveedor_nombre1.setValue( res.referencias.referenciasProveedor.proveedor_nombre1 )
			this.m.proveedor_nombre2.setValue( res.referencias.referenciasProveedor.proveedor_nombre2 )
			this.m.proveedor_nombre3.setValue( res.referencias.referenciasProveedor.proveedor_nombre3 )
			this.m.proveedor_contacto1.setValue( res.referencias.referenciasProveedor.proveedor_contacto1 )
			this.m.proveedor_contacto2.setValue( res.referencias.referenciasProveedor.proveedor_contacto2 )
			this.m.proveedor_contacto3.setValue( res.referencias.referenciasProveedor.proveedor_contacto3 )
			this.m.proveedor_puesto1.setValue( res.referencias.referenciasProveedor.proveedor_puesto1 )
			this.m.proveedor_puesto2.setValue( res.referencias.referenciasProveedor.proveedor_puesto2 )
			this.m.proveedor_puesto3.setValue( res.referencias.referenciasProveedor.proveedor_puesto3 )
			this.m.proveedor_promedio_compras1.setValue( res.referencias.referenciasProveedor.proveedor_promedio_compras1 )
			this.m.proveedor_promedio_compras2.setValue( res.referencias.referenciasProveedor.proveedor_promedio_compras2 )
			this.m.proveedor_promedio_compras3.setValue( res.referencias.referenciasProveedor.proveedor_promedio_compras3 )
			this.m.proveedor_total1.setValue( res.referencias.referenciasProveedor.proveedor_total1 )
			this.m.proveedor_total2.setValue( res.referencias.referenciasProveedor.proveedor_total2 )
			this.m.proveedor_total3.setValue( res.referencias.referenciasProveedor.proveedor_total3 )
		}
	}

	setData( res ){
		console.log("Esta es la respuesta:",res)
		if( this.tipoPersona == 1 ){
			this.f.rp_nombre1.setValue( res.rp_nombre1 )
			this.f.rp_nombre2.setValue( res.rp_nombre2 )
			this.f.rp_nombre3.setValue( res.rp_nombre3 )
			this.f.ocupacion1.setValue( res.ocupacion1 )
			this.f.ocupacion2.setValue( res.ocupacion2 )
			this.f.ocupacion3.setValue( res.ocupacion3 )
			this.f.rp_direccion1.setValue( res.rp_direccion1 )
			this.f.rp_direccion2.setValue( res.rp_direccion2 )
			this.f.rp_direccion3.setValue( res.rp_direccion3 )
			this.f.rp_telefono1.setValue( res.rp_telefono1 )
			this.f.rp_telefono2.setValue( res.rp_telefono2 )
			this.f.rp_telefono3.setValue( res.rp_telefono3 )
			this.f.rp_parentesco1.setValue( res.rp_parentesco1 )
			this.f.rp_parentesco2.setValue( res.rp_parentesco2 )
			this.f.rp_parentesco3.setValue( res.rp_parentesco3 )
		}else {
			this.m.proveedor_nombre1.setValue( res.proveedor_nombre1 )
			this.m.proveedor_nombre2.setValue( res.proveedor_nombre2 )
			this.m.proveedor_nombre3.setValue( res.proveedor_nombre3 )
			this.m.proveedor_contacto1.setValue( res.proveedor_contacto1 )
			this.m.proveedor_contacto2.setValue( res.proveedor_contacto2 )
			this.m.proveedor_contacto3.setValue( res.proveedor_contacto3 )
			this.m.proveedor_puesto1.setValue( res.proveedor_puesto1 )
			this.m.proveedor_puesto2.setValue( res.proveedor_puesto2 )
			this.m.proveedor_puesto3.setValue( res.proveedor_puesto3 )
			this.m.proveedor_promedio_compras1.setValue( res.proveedor_promedio_compras1 )
			this.m.proveedor_promedio_compras2.setValue( res.proveedor_promedio_compras2 )
			this.m.proveedor_promedio_compras3.setValue( res.proveedor_promedio_compras3 )
			this.m.proveedor_total1.setValue( res.proveedor_total1 )
			this.m.proveedor_total2.setValue( res.proveedor_total2 )
			this.m.proveedor_total3.setValue( res.proveedor_total3 )
		}
	}
	changePart(part: number){
		this.parte.emit(part);
	}

	back(step) {
		localStorage.setItem('update', 'true')
		this.onBack.emit(step)
	}
	async getReferencias(guardado){
		const ref = await this.solicitudService.ObtenerSolicitud(JSON.parse(localStorage.getItem('client_details')).folio);
		console.log('Valor de solicitud:::',ref);
		if(ref.referencias?.rp_nombre1?.trim().length>0 || ref.referencias?.referenciasProveedor?.proveedor_nombre1?.trim().length>0 ){
			console.log("valor de validacion::",ref.referencias?.rp_nombre1?.trim().length>0,ref.referencias?.proveedor_nombre1?.trim().length>0 )
			this.setDataDynamo(ref);
		}else{
			this.solicitudService.geteReferencias( {guardado} ).subscribe( res => {
				console.log("GET: ", res);
				this.datosRef = res.body
				this.setData( res.body )
			})
		}
	}
	SendValues(valores) {
		if(this.tipoPersona==2){
			referenciasM.referencias.referenciasProveedor = {...valores};
			console.log("Valor : ",referenciasM);
		}else{
			// this.soliicitudF={...valores};
		}
	}
	async seveDynamoF(data){

		//this.referencias=data
		console.log("Guardado de Fisica:::",data)
		await this.solicitudService.GuardarSolicitud(JSON.parse(localStorage.getItem('client_details')).folio,data);
	
	}
	async saveDynamo(){
		await this.solicitudService.GuardarSolicitud(JSON.parse(localStorage.getItem('client_details')).folio,{...referenciasM});
	}

	ShowDialog(type, text, text2 = '') {
		this.dialog.open(MessageBoxComponent, {
		  width: "500px",
		  data: {
			text,
			text2,
			type,
			icon: type,
		  },
		});
	  }

}