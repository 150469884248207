import { ChangeDetectorRef,Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, AuthenticationService, UserService } from '../../../../_services';
import { SolicitudService } from 'src/app/_services/solicitud.service';

import {referenciasM} from '../../modalSolicitudR';

import { customPatterns } from '../../../../_models/maskPattern'
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
/* tslint:disable */

@Component({
	selector: 'app-solicitud-r-p1',
	templateUrl: './solicitudRp1.component.html',
	styleUrls: ['./../../solicitudR.component.scss']
})

export class SolicitudRp1Component implements OnInit {

	@Input() tipoPersona: number;
	@Output() parte = new EventEmitter<number>();
	// @Input() referenciasBancarias :any;
	// @Output() solicitudM=new EventEmitter<any>();
	
	//@Output() soliicitudMora = new EventEmitter<any>();

	customPattern = customPatterns
	dgFForm: FormGroup;
	dgMForm: FormGroup;
    loading = false;
    submitted = false;
	returnUrl: string;
	datosRef:any = {}
	folio=null
	solicitud={}

    constructor(
        private formBuilder: FormBuilder,
        // private route: ActivatedRoute,
        private router: Router,
        // private authenticationService: AuthenticationService,
        private alertService: AlertService,
		private solicitudService: SolicitudService,
		private userService:UserService,
		private dialog:MatDialog
    ) {
        // redirect to home if already logged in

	}
    ngOnInit() {

		this.folio = JSON.parse(localStorage.getItem('client_details')).folio;

        this.dgFForm = this.formBuilder.group({
            institucion1: ['', [Validators.required]],
			institucion2: ['', []],
			institucion3: ['', []],
			tipo_credito1: ['', [Validators.required]],
			tipo_credito2: ['',],
			tipo_credito3: ['', []],
			monto1: ['', [Validators.required]],
			monto2: ['', []],
			monto3: ['', []],
			antiguedad1: ['', [Validators.required]],
			antiguedad2: ['',[]],
			antiguedad3: ['', []],
		});
		
		this.dgMForm = this.formBuilder.group({
			institucion1: ['', [Validators.required]],
			institucion2: ['', []],
			institucion3: ['', []],
			tipo_credito1: ['AHORRO', Validators.required],
			tipo_credito2: ['AHORRO', []],
			tipo_credito3: ['AHORRO', []],
			monto1: ['', Validators.required],
			monto2: ['', []],
			monto3: ['', []],
			tasa1: ['', Validators.required],
			tasa2: ['', []],
			tasa3: ['', []],
			vigencia1: ['', Validators.required],
			vigencia2: ['', []],
			vigencia3: ['', []]
		});

        // get return url from route parameters or default to '/'
		// this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/dashboard';
		const solicitud = this.solicitudService.getReferencia()
		const tipo_persona = this.tipoPersona
		const parte = 1

		const guardado = { ...solicitud, tipo_persona, parte}
		console.log("Guardado: ", guardado);
		
		this.getSolicitud(guardado);
		
    }

    // convenience getter for easy access to form fields
	get f() { return this.dgFForm.controls; }
	get m() { return this.dgMForm.controls; }	

    async onSubmit(button: number) {
		this.dgMForm.markAllAsTouched();
		this.dgFForm.markAllAsTouched();
		if( !this.userService.alertService.isOnline() ) return
		//this.parte.emit(2);
		this.loading = true;
		var data: any;
		this.submitted = true;

		if( this.datosRef.institucion1 && button === 2 ) return this.parte.emit(2)
		// stop here if form is invalid
		if (this.tipoPersona == 1) {
			if (this.dgFForm.invalid) {
				this.loading = false;
				return;
			}
			this.f.monto1.setValue( Number(this.f.monto1.value ))
			this.f.monto2.setValue( Number(this.f.monto2.value ))
			this.f.monto3.setValue( Number(this.f.monto3.value ))
			data = this.dgFForm.value;
		}else {
			if (this.dgMForm.invalid) {
				this.loading = false;
				console.log(this.dgMForm.value);
				return;
			}
			this.m.monto1.setValue( Number(this.m.monto1.value ))
			this.m.monto2.setValue( Number(this.m.monto2.value ))
			this.m.monto3.setValue( Number(this.m.monto3.value ))
			data = this.dgMForm.value;
		}

		this.loading = true;
		console.log('before calling api');
		(await this.solicitudService.saveReferencias(data, 1, this.tipoPersona, 1)).subscribe(
			async (res: any) => {
				console.log(res);
				// if (data.status <= 300) {
					this.loading = false;
					// this.alertService.success()
					if ( button === 1 ) { // go to dashboard
						// this.alertService.success('Se guardó correctamente.')
						const dialogref = this.dialog.open(MessageBoxComponent, {
							width: "500px",
							data: {
								text: "Éxito",
								text3: "tu información se ha guardado",
								icon: "success",
								type: "success"
							}
						})
						dialogref.afterClosed().subscribe( _=> this.router.navigate(['/dashboard']))
					} else { // next form
						this.parte.emit(2);

					}
				// }
				// this.router.navigateByUrl('/login', { replaceUrl: true } );
			},
			(err: any) => {
				this.loading = false;
				// this.alertService.error(err.error.message);
				console.log('Error 2 :', err)
				this.dialog.open(MessageBoxComponent, {
					width: "500px",
					data: {
						text: "Éxito",
						text3: "tu información no se ha guardado",
						icon: "error",
						type: "error"
					}
				})
				//let er = JSON.parse(err);
			}
		);	
		let referencias={};

		
		
		if(this.tipoPersona==1){
			referencias={
				institucion1: this.f.institucion1.value,
				institucion2: this.f.institucion2.value,
				institucion3: this.f.institucion3.value,
				tipo_credito1: this.f.tipo_credito1.value,
				tipo_credito2: this.f.tipo_credito2.value,
				tipo_credito3: this.f.tipo_credito3.value,
				monto1: this.f.monto1.value,
				monto2: this.f.monto2.value,
				monto3: this.f.monto3.value,
				antiguedad1: this.f.antiguedad1.value,
				antiguedad2: this.f.antiguedad2.value,
				antiguedad3: this.f.antiguedad3.value
			}
			
		}else{
			referencias ={
			institucion1: this.m.institucion1.value,
			institucion2: this.m.institucion2.value,
			institucion3: this.m.institucion3.value,
			tipo_credito1: this.m.tipo_credito1.value,
			tipo_credito2: this.m.tipo_credito2.value,
			tipo_credito3: this.m.tipo_credito3.value,
			monto1: this.m.monto1.value,
			monto2: this.m.monto2.value,
			monto3: this.m.monto3.value,
			tasa1: this.m.tasa1.value,
			tasa2: this.m.tasa2.value,
			tasa3: this.m.tasa3.value,
			vigencia1: this.m.vigencia1.value,
			vigencia2: this.m.vigencia2.value,
			vigencia3: this.m.vigencia3.value
				
			}
		}
		this.SendValues(referencias);
		
		// await this.solicitudService.GuardarSolicitud(this.folio, {referencias});
	}
	
	setData( {body} ) {
		console.log(body);
		if( this.tipoPersona == 1 ){
			this.f.institucion1.setValue( body.institucion1 )
			this.f.institucion2.setValue( body.institucion2 )
			this.f.institucion3.setValue( body.institucion3 )
			this.f.tipo_credito1.setValue( body.tipo_credito1 )
			this.f.tipo_credito2.setValue( body.tipo_credito2 )
			this.f.tipo_credito3.setValue( body.tipo_credito3 )
			this.f.monto1.setValue( body.monto1 )
			this.f.monto2.setValue( body.monto2 )
			this.f.monto3.setValue( body.monto3 )
			this.f.antiguedad1.setValue( body.antiguedad1 )
			this.f.antiguedad2.setValue( body.antiguedad2 )
			this.f.antiguedad3.setValue( body.antiguedad3 )
		}else{
			this.m.institucion1.setValue( body.institucion1 )
			this.m.institucion2.setValue( body.institucion2 )
			this.m.institucion3.setValue( body.institucion3 )
			this.m.tipo_credito1.setValue( body.tipo_credito1 )
			this.m.tipo_credito2.setValue( body.tipo_credito2 )
			this.m.tipo_credito3.setValue( body.tipo_credito3 )
			this.m.monto1.setValue( body.monto1 )
			this.m.monto2.setValue( body.monto2 )
			this.m.monto3.setValue( body.monto3 )
			this.m.tasa1.setValue( body.tasa1 )
			this.m.tasa2.setValue( body.tasa2 )
			this.m.tasa3.setValue( body.tasa3 )
			this.m.vigencia1.setValue( body.vigencia1 )
			this.m.vigencia2.setValue( body.vigencia2 )
			this.m.vigencia3.setValue( body.vigencia3 )
		}
	}
	setDataDynamo( body ) {
		console.log(body);
		if( this.tipoPersona == 1 ){
			this.f.institucion1.setValue( body.referencias.institucion1 )
			this.f.institucion2.setValue( body.referencias.institucion2 )
			this.f.institucion3.setValue( body.referencias.institucion3 )
			this.f.tipo_credito1.setValue( body.referencias.tipo_credito1 )
			this.f.tipo_credito2.setValue( body.referencias.tipo_credito2 )
			this.f.tipo_credito3.setValue( body.referencias.tipo_credito3 )
			this.f.monto1.setValue( body.referencias.monto1 )
			this.f.monto2.setValue( body.referencias.monto2 )
			this.f.monto3.setValue( body.referencias.monto3 )
			this.f.antiguedad1.setValue( body.referencias.antiguedad1 )
			this.f.antiguedad2.setValue( body.referencias.antiguedad2 )
			this.f.antiguedad3.setValue( body.referencias.antiguedad3 )
		}else{
			this.m.institucion1.setValue( body.referencias.referenciasBancarias.institucion1 )
			this.m.institucion2.setValue( body.referencias.referenciasBancarias.institucion2 )
			this.m.institucion3.setValue( body.referencias.referenciasBancarias.institucion3 )
			this.m.tipo_credito1.setValue( body.referencias.referenciasBancarias.tipo_credito1 )
			this.m.tipo_credito2.setValue( body.referencias.referenciasBancarias.tipo_credito2 )
			this.m.tipo_credito3.setValue( body.referencias.referenciasBancarias.tipo_credito3 )
			this.m.monto1.setValue( body.referencias.referenciasBancarias.monto1 )
			this.m.monto2.setValue( body.referencias.referenciasBancarias.monto2 )
			this.m.monto3.setValue( body.referencias.referenciasBancarias.monto3 )
			this.m.tasa1.setValue( body.referencias.referenciasBancarias.tasa1 )
			this.m.tasa2.setValue( body.referencias.referenciasBancarias.tasa2 )
			this.m.tasa3.setValue( body.referencias.referenciasBancarias.tasa3 )
			this.m.vigencia1.setValue( body.referencias.referenciasBancarias.vigencia1 )
			this.m.vigencia2.setValue( body.referencias.referenciasBancarias.vigencia2 )
			this.m.vigencia3.setValue( body.referencias.referenciasBancarias.vigencia3 )
		}
	}

	changePart(part: number){
		console.log(part);
		this.parte.emit(part);
	}

	async getSolicitud(guardado){
		const ref = await this.solicitudService.ObtenerSolicitud(this.folio);
		if(ref.referencias?.referenciasBancarias?.institucion1?.trim().length>0 || ref.referencias?.institucion1?.trim().length>0){
			console.log("GET REF :",ref)
			const solicitud=ref;
			console.log("GET REF :",solicitud)
			this.setDataDynamo(solicitud);
		}else{
			this.solicitudService.geteReferencias( {guardado} ).subscribe( res => {
				console.log("GET: ", res);
				
				this.setData( res )
			})
		}
	}
	SendValues(valores) {
		if(this.tipoPersona==2){
			referenciasM.referencias.referenciasBancarias = {...valores};
			//this.solicitudM = {...valores};
			console.log("Valor : ",referenciasM);
			// this.solicitudM.emit(this.solicitud);
		}else{
			
		}
	}
	
}
