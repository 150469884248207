
<div class="progress">
	<div class="progress-left active" (click)="changePart(1)"></div>
	<div class="progress-mid active" (click)="changePart(2)"></div>
	<div class="progress-right active" (click)="changePart(3)"></div>
</div>



<form *ngIf="tipoPersona == 1" [formGroup]="dgFForm" >
	<div class="datos_generales_1">
		<div style="display: flex; justify-content: flex-start; align-items: center;">
			<span class="seccion">1</span>
			<p><b>Ocupación/<br>Profesión actual</b></p>
		</div>

		<mat-form-field appearance="outline">
			<mat-label>{{es_medico ? 'Especialidad Médica' : 'Titulo Profesional'}}</mat-label>
			<mat-select formControlName="ocupacion_medica" >
				<mat-option *ngFor="let especialidad of especialidades" [value]="especialidad">{{especialidad}}</mat-option>
			</mat-select>
			<!-- <input *ngIf="!es_medico"
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="ocupacion_medica" 
				[value]="datosGen.ocupacion_medica"
				[ngClass]="{ 'is-invalid': submitted && f.ocupacion_medica.errors }"
			> -->
			<mat-error *ngIf="submitted && f.ocupacion_medica.errors" class="invalid-feedback">
				<div *ngIf="f.ocupacion_medica.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label>Año de graduación / titulaciòn</mat-label>
			<input  matInput
			mask="0000"
				formControlName="anio_grad" 
				[value]="datosGen.anio_grad"
				[ngClass]="{ 'is-invalid': submitted && f.anio_grad.errors }"
			>
		
			<mat-error *ngIf="submitted && f.anio_grad.errors" class="invalid-feedback">
				<div *ngIf="f.anio_grad.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
		

		<mat-form-field appearance="outline">
			<mat-label>Instituto donde realizó Residencia Médica / Estudios</mat-label>
			<input matInput
			[specialCharacters]="['space']"
			[patterns]="customPattern"
			mask="E{100}"
				formControlName="lugar_recidencia" 
				[value]="datosGen.lugar_recidencia"
				[ngClass]="{ 'is-invalid': submitted && f.lugar_recidencia.errors }"
			>
			<mat-error *ngIf="submitted && f.lugar_recidencia.errors" class="invalid-feedback">
				<div *ngIf="f.lugar_recidencia.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<div class="input size-2" *ngIf="es_medico">
			<mat-label>¿Pertenece al Consejo Nacional de su Especialidad?</mat-label>
			<mat-slide-toggle
				formControlName="pertenece"
				[ngClass]="{ 'is-invalid': submitted && f.pertenece.errors }"
			></mat-slide-toggle>
			<!-- <input  
				formControlName="pertenece" 
				[value]="datosGen.pertenece"
				[ngClass]="{ 'is-invalid': submitted && f.pertenece.errors }"
			> -->
			<!-- <mat-error *ngIf="submitted && f.pertenece.errors" class="invalid-feedback">
				<div *ngIf="f.pertenece.errors.required">Campo obligatorio</div>
			</mat-error> -->
		</div>

		<mat-form-field appearance="outline">
			<mat-label>Lugares de trabajo</mat-label>
			<input  matInput
			[specialCharacters]="['space']"
			[patterns]="customPattern"
			mask="E{100}"
				formControlName="lugares_trabajo" 
				[value]="datosGen.lugares_trabajo"
				[ngClass]="{ 'is-invalid': submitted && f.lugares_trabajo.errors }"
			>
			<mat-error *ngIf="submitted && f.lugares_trabajo.errors" class="invalid-feedback">
				<div *ngIf="f.lugares_trabajo.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<div class="input size-2">
			<mat-label>Para Consultorios ¿Propios o privados?</mat-label>
			<mat-radio-group 
				formControlName="tipo_consultorio" 
				[value]="datosGen.tipo_consultorio"
				[ngClass]="{ 'is-invalid': submitted && f.tipo_consultorio.errors }">
				<mat-radio-button labelPosition="before" value="PROPIO">Propio</mat-radio-button>
				<mat-radio-button labelPosition="before" value="RENTADO">Rentado</mat-radio-button>
			</mat-radio-group>
			<!-- <input  
				formControlName="tipo_consultorio" 
				[value]="datosGen.tipo_consultorio"
				[ngClass]="{ 'is-invalid': submitted && f.tipo_consultorio.errors }"
			> -->
			<mat-error *ngIf="submitted && f.tipo_consultorio.errors" class="invalid-feedback">
				<div *ngIf="f.tipo_consultorio.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>
	</div>


	<div class="datos_generales_1">
		<div style="display: flex; justify-content: flex-start; align-items: center;">
			<span class="seccion">2</span>
		</div>

		<mat-form-field appearance="outline">
			<mat-label>Total Gasto Mensual Consultorio</mat-label>
			<input  matInput
			mask="separator.2"
			thousandSeparator=","
			prefix="$ "
				formControlName="total_gasto" 
				[value]="datosGen.total_gasto"
				[ngClass]="{ 'is-invalid': submitted && f.total_gasto.errors }"
			>
			<mat-error *ngIf="submitted && f.total_gasto.errors" class="invalid-feedback">
				<div *ngIf="f.total_gasto.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label>Ingreso Mensual Promedio</mat-label>
			<input  matInput
			mask="separator.2"
			thousandSeparator=","
			prefix="$ "
				formControlName="ingreso_mensual_promedio" 
				[value]="datosGen.ingreso_mensual_promedio"
				[ngClass]="{ 'is-invalid': submitted && f.ingreso_mensual_promedio.errors }"
			>
			<mat-error *ngIf="submitted && f.ingreso_mensual_promedio.errors" class="invalid-feedback">
				<div *ngIf="f.ingreso_mensual_promedio.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label>Detalle del equipamiento actual</mat-label>
			<textarea matInput rows="4" cols="50" [value]="datosGen.equipamiento"
				formControlName="equipamiento" 
				[ngClass]="{ 'is-invalid': submitted && f.equipamiento.errors }"
			>
			</textarea>
				
			<mat-error *ngIf="submitted && f.equipamiento.errors" class="invalid-feedback">
				<div *ngIf="f.equipamiento.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label>Equipo Solicitado de Arrendamiento</mat-label>
			<input  matInput
				formControlName="equipo_solicitado" 
				[value]="datosGen.equipo_solicitado"
				[ngClass]="{ 'is-invalid': submitted && f.equipo_solicitado.errors }"
			>
			<mat-error *ngIf="submitted && f.equipo_solicitado.errors" class="invalid-feedback">
				<div *ngIf="f.equipo_solicitado.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label>Número de Dependientes Económicos</mat-label>
			<mat-select
				formControlName="numero_dependientes" 
				[value]="datosGen.numero_dependientes"
				[ngClass]="{ 'is-invalid': submitted && f.numero_dependientes.errors }">
				<mat-option>Seleccionar</mat-option>
				<mat-option value="1">1</mat-option>
				<mat-option value="2">2</mat-option>
				<mat-option value="3">3</mat-option>
				<mat-option value="4">4</mat-option>
				<mat-option value="5">5</mat-option>
				<mat-option value="6">6</mat-option>
			</mat-select>
			<!-- <input  
				formControlName="numero_dependientes" 
				[value]="datosGen.numero_dependientes"
				[ngClass]="{ 'is-invalid': submitted && f.numero_dependientes.errors }"
			> -->
			<mat-error *ngIf="submitted && f.numero_dependientes.errors" class="invalid-feedback">
				<div *ngIf="f.numero_dependientes.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<div>
			<mat-form-field appearance="outline" *ngIf="f.numero_dependientes.value >= 1">
			<mat-label>Edades de los dependientes economicos</mat-label>
				<input matInput mask="00"
					formControlName="edad_dependiente1" 
					[value]="datosGen.edad_dependiente1"
					[ngClass]="{ 'is-invalid': submitted && f.edad_dependiente1.errors }"
				>
				<mat-error *ngIf="submitted && f.edad_dependiente1.errors" class="invalid-feedback">
					<div *ngIf="f.edad_dependiente1.errors.required">Campo obligatorio</div>
				</mat-error>
			</mat-form-field>
	
			<mat-form-field appearance="outline" *ngIf="f.numero_dependientes.value >= 2">
				<input matInput mask="00"
					formControlName="edad_dependiente2" 
					[value]="datosGen.edad_dependiente2"
					[ngClass]="{ 'is-invalid': submitted && f.edad_dependiente2.errors }"
				>
				<mat-error *ngIf="submitted && f.edad_dependiente2.errors" class="invalid-feedback">
					<div *ngIf="f.edad_dependiente2.errors.required">Campo obligatorio</div>
				</mat-error>
			</mat-form-field>

			<mat-form-field appearance="outline" *ngIf="f.numero_dependientes.value >= 3">
				
				<input matInput mask="00"
					formControlName="edad_dependiente3" 
					[value]="datosGen.edad_dependiente3"
					[ngClass]="{ 'is-invalid': submitted && f.edad_dependiente3.errors }"
				>
				<mat-error *ngIf="submitted && f.edad_dependiente3.errors" class="invalid-feedback">
					<div *ngIf="f.edad_dependiente3.errors.required">Campo obligatorio</div>
				</mat-error>
			</mat-form-field>

			<mat-form-field appearance="outline" *ngIf="f.numero_dependientes.value >= 4">
				
				<input matInput mask="00"
					formControlName="edad_dependiente4" 
					[value]="datosGen.edad_dependiente4"
					[ngClass]="{ 'is-invalid': submitted && f.edad_dependiente4.errors }"
				>
				<mat-error *ngIf="submitted && f.edad_dependiente4.errors" class="invalid-feedback">
					<div *ngIf="f.edad_dependiente4.errors.required">Campo obligatorio</div>
				</mat-error>
			</mat-form-field>

			<mat-form-field appearance="outline" *ngIf="f.numero_dependientes.value >= 5">
				
				<input  matInput mask="00"
					formControlName="edad_dependiente5" 
					[value]="datosGen.edad_dependiente5"
					[ngClass]="{ 'is-invalid': submitted && f.edad_dependiente5.errors }"
				>
				<mat-error *ngIf="submitted && f.edad_dependiente5.errors" class="invalid-feedback">
					<div *ngIf="f.edad_dependiente5.errors.required">Campo obligatorio</div>
				</mat-error>
			</mat-form-field>

			<mat-form-field appearance="outline" *ngIf="f.numero_dependientes.value >= 6">
				
				<input matInput mask="00"
					formControlName="edad_dependiente6" 
					[value]="datosGen.edad_dependiente6"
					[ngClass]="{ 'is-invalid': submitted && f.edad_dependiente6.errors }"
				>
				<mat-error *ngIf="submitted && f.edad_dependiente6.errors" class="invalid-feedback">
					<div *ngIf="f.edad_dependiente6.errors.required">Campo obligatorio</div>
				</mat-error>
			</mat-form-field>
		</div>
	</div>

</form>

<!-- 
	------------------------------------------------------------------------------------------------------- 
	--------------------------------------- PERSONA MORAL -------------------------------------------------
	-------------------------------------------------------------------------------------------------------
-->

<form *ngIf="tipoPersona == 2" [formGroup]="dgMForm" >
	<div class="datos_generales_1">
		<div style="display: flex; justify-content: flex-start; align-items: center;">
			<span><b>Información<br>corporativa</b></span>
		</div>

		<div class="accionista-title">Accionista 1</div>
		<mat-form-field appearance="outline">
			<mat-label>Nombres</mat-label>
			<input matInput
			[patterns]="customPattern"
			mask="S{100}"
			placeholder="Como aparece en el I.N.E."
				formControlName="accionista1"
				[value]="datosGen.accionista1"
				[ngClass]="{ 'is-invalid': submitted && m.accionista1.errors }"
			>
			<mat-error *ngIf="submitted && m.accionista1.errors" class="invalid-feedback">
				<div *ngIf="m.accionista1.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label>Participación</mat-label>
			<input matInput
			placeholder="%"
				suffix=" %"
				mask="00"
				formControlName="participacion1" 
				[value]="datosGen.participacion1"
				[ngClass]="{ 'is-invalid': submitted && m.participacion1.errors }"
			>
			<mat-error *ngIf="submitted && m.participacion1.errors" class="invalid-feedback">
				<div *ngIf="m.participacion1.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label>RFC</mat-label>
			<input  matInput
			placeholder="Con homoclave"
				mask="SSSS000000 AAA"
				formControlName="participacion1_rfc" 
				[value]="datosGen.participacion1_rfc"
				[ngClass]="{ 'is-invalid': submitted && m.participacion1_rfc.errors }"
			>
			<mat-error *ngIf="submitted && m.participacion1_rfc.errors" class="invalid-feedback">
				<div *ngIf="m.participacion1_rfc.errors">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<div class="accionista-title">Accionista 3</div>
		<mat-form-field appearance="outline">
			<mat-label>Nombres</mat-label>
			<input matInput
			[patterns]="customPattern"
			mask="S{100}"
			placeholder="Como aparece en el I.N.E."
				formControlName="accionista3" 
				[value]="datosGen.accionista3"
				[ngClass]="{ 'is-invalid': submitted && m.accionista3.errors }"
			>
			<mat-error *ngIf="submitted && m.accionista3.errors" class="invalid-feedback">
				<div *ngIf="m.accionista3.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label>Participación</mat-label>
			<input matInput
			placeholder="%"
				suffix=" %"
				mask="00"
				formControlName="participacion3" 
				[value]="datosGen.participacion3"
				[ngClass]="{ 'is-invalid': submitted && m.participacion3.errors }"
			>
			<mat-error *ngIf="submitted && m.participacion3.errors" class="invalid-feedback">
				<div *ngIf="m.participacion3.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label>RFC</mat-label>
			<input matInput
			placeholder="Con homoclave"
				mask="SSSS000000 AAA"
				formControlName="participacion_rfc3" 
				[value]="datosGen.participacion_rfc3"
				[ngClass]="{ 'is-invalid': submitted && m.participacion_rfc3.errors }"
			>
			<mat-error *ngIf="submitted && m.participacion_rfc3.errors" class="invalid-feedback">
				<div *ngIf="m.participacion_rfc3.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
	</div>


	<div class="datos_generales_1">
		<div style="display: flex; justify-content: flex-start; align-items: center;">
			<span style="height: 20px;"> </span>
		</div>
		<div class="accionista-title">Accionista 2</div>
		<mat-form-field appearance="outline">
			<mat-label>Nombres</mat-label>
			<input matInput
			[patterns]="customPattern"
			mask="S{100}"
			placeholder="Como aparece en el I.N.E."
				formControlName="accionista2" 
				[value]="datosGen.accionista2"
				[ngClass]="{ 'is-invalid': submitted && m.accionista2.errors }"
			>
			<mat-error *ngIf="submitted && m.accionista2.errors" class="invalid-feedback">
				<div *ngIf="m.accionista2.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
		<mat-form-field appearance="outline">
			<mat-label>Participación</mat-label>
			<input matInput
				suffix=" %"
				mask="00"
				placeholder="%"
				formControlName="participacion2" 
				[value]="datosGen.participacion2"
				[ngClass]="{ 'is-invalid': submitted && m.participacion2.errors }"
			>
			<mat-error *ngIf="submitted && m.participacion2.errors" class="invalid-feedback">
				<div *ngIf="m.participacion2.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label>RFC</mat-label>
			<input matInput
			mask="SSSS000000 AAA"
			placeholder="Con homoclave"
				formControlName="participacion_rfc2" 
				[value]="datosGen.participacion_rfc2"
				[ngClass]="{ 'is-invalid': submitted && m.participacion_rfc2.errors }"
			>
			<mat-error *ngIf="submitted && m.participacion_rfc2.errors" class="invalid-feedback">
				<div *ngIf="m.participacion_rfc2.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<div class="accionista-title">Accionista 4</div>
		<mat-form-field appearance="outline">
			<mat-label>Nombres</mat-label>
			<input matInput
			[patterns]="customPattern"
			mask="S{100}"
			placeholder="Como aparece en el I.N.E."
				formControlName="accionista4" 
				[value]="datosGen.accionista4"
				[ngClass]="{ 'is-invalid': submitted && m.accionista4.errors }"
			>
			<mat-error *ngIf="submitted && m.accionista4.errors" class="invalid-feedback">
				<div *ngIf="m.accionista4.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
		<mat-form-field appearance="outline">
			<mat-label>Participación</mat-label>
			<input matInput
			suffix=" %"
			mask="00"
				placeholder="%"
				formControlName="participacion4" 
				[value]="datosGen.participacion4"
				[ngClass]="{ 'is-invalid': submitted && m.participacion4.errors }"
			>
			<mat-error *ngIf="submitted && m.participacion4.errors" class="invalid-feedback">
				<div *ngIf="m.participacion4.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label>RFC</mat-label>
			<input matInput
			mask="SSSS000000 AAA"
			placeholder="Con homoclave"
				formControlName="participacion_rfc4" 
				[value]="datosGen.participacion_rfc4"
				[ngClass]="{ 'is-invalid': submitted && m.participacion_rfc4.errors }"
			>
			<mat-error *ngIf="submitted && m.participacion_rfc4.errors" class="invalid-feedback">
				<div *ngIf="m.participacion_rfc4.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
	</div>

	<hr style="width: 100%;">

	<div class="datos_generales_1">
		<div style="display: flex; justify-content: flex-start; align-items: center;">
			<span></span>
		</div>

		<mat-form-field appearance="outline">
			<mat-label>Nacionalidad de la Matriz</mat-label>
			<input matInput 
			[patterns]="customPattern"
			mask="S{100}"
			placeholder="Solo si aplica"
				formControlName="nacionalidad_matriz" 
				[value]="datosGen.nacionalidad_matriz"
				[ngClass]="{ 'is-invalid': submitted && m.nacionalidad_matriz.errors }"
			>
			<mat-error *ngIf="submitted && m.nacionalidad_matriz.errors" class="invalid-feedback">
				<div *ngIf="m.nacionalidad_matriz.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<div class="input size-2">
			<mat-slide-toggle formControlName="grupo_em_opt" labelPosition="before">Pertenece a algún grupo empresarial</mat-slide-toggle>
		</div>

		<mat-form-field appearance="outline">
			<mat-label>Especifique</mat-label>
			<input matInput
			[patterns]="customPattern"
			mask="S{100}"
			[readonly]="!m.grupo_em_opt.value"
				formControlName="grupo_emp" 
				[value]="datosGen.grupo_emp"
				[ngClass]="{ 'is-invalid': submitted && m.grupo_emp.errors }"
			>
			<mat-error *ngIf="submitted && m.grupo_emp.errors" class="invalid-feedback">
				<div *ngIf="m.grupo_emp.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<div class="input size-2">
			<mat-slide-toggle formControlName="filial_opt" labelPosition="before">Cuenta con alguna filial</mat-slide-toggle>
		</div>

		<mat-form-field appearance="outline">
			<mat-label>Especifique</mat-label>
			<input matInput
			[patterns]="customPattern"
			mask="S{100}"
			[readonly]="!m.filial_opt.value"
				formControlName="filial" 
				[value]="datosGen.filial"
				[ngClass]="{ 'is-invalid': submitted && m.filial.errors }"
			>
			<mat-error *ngIf="submitted && m.filial.errors" class="invalid-feedback">
				<div *ngIf="m.filial.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
	</div>

</form>

<div class="row-end">
	<div></div>
	<mat-spinner *ngIf="loading" diameter="30"></mat-spinner>
	<!--<button mat-button (click)="back(2)">
		<svg style="height: 20px; width:auto; transform: rotate(180deg);" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><defs><style>.cba{fill:#3fa4d7;}</style></defs><path class="cba" d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0ZM22,38V18.7L13.448,27.25l-3.394-3.394L24,9.908,37.948,23.856,34.554,27.25,26,18.7V38Z" transform="translate(48) rotate(90)"/></svg>
		Regresar
	</button>
	<div></div>
	 <button class="save-button" mat-button (click)="onSubmit(1)" style="height: 60px!important; width: 60px!important;">
		<svg style="height: 30px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="48.14" height="48.14" viewBox="0 0 48.14 48.14"><defs><style>.ab{fill:#fff;}</style></defs><path class="ab" d="M8.439,4A3.377,3.377,0,0,0,5,7.439V48.7A3.377,3.377,0,0,0,8.439,52.14H49.7A3.377,3.377,0,0,0,53.14,48.7V14.544a1.039,1.039,0,0,0-.345-.8l-9.4-9.4A1.039,1.039,0,0,0,42.6,4Zm5.731,2.292H41.678V20.047a2.3,2.3,0,0,1-2.292,2.292H16.462a2.3,2.3,0,0,1-2.292-2.292ZM32.508,9.731V18.9h4.585V9.731ZM16.462,30.362H41.678a2.3,2.3,0,0,1,2.292,2.292V49.847h-29.8V32.654A2.3,2.3,0,0,1,16.462,30.362ZM8.439,46.409h2.292V48.7H8.439Zm38.97,0H49.7V48.7H47.409Z" transform="translate(-5 -4)"/></svg>
	</button> -->
	<mat-spinner *ngIf="loading" diameter="30"></mat-spinner>
	<button *ngIf="!loading" mat-button (click)="onSubmit(2)">
		Siguiente
		<svg style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><defs><style>.abc{fill:#707070;}</style></defs><path class="abc" d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0ZM22,38V18.7L13.448,27.25l-3.394-3.394L24,9.908,37.948,23.856,34.554,27.25,26,18.7V38Z" transform="translate(48) rotate(90)"/></svg>
	</button>
</div>