import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../_services';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = this.authenticationService.currentUserValue;
        if (currentUser && currentUser.token && !request.url.includes('copomex.com')) {
            request = request.clone({
                setHeaders: { 
                    Authorization: `${currentUser.token}`
                }
            });
        }

        return next.handle(request).pipe(
            tap(
              (event: any) => {
                console.log(`Event status: `, event.status);
                this.CloseSesion(event.status)
              },
              (error: any) => {
                console.log(`Error status: `, error.status);
                this.CloseSesion(error.status)
              }
            )
          );
    }

    CloseSesion(statusCode) {
        if (Number(statusCode) == 402) {
            this.authenticationService.logout()
        }
    }
}