<div class="container-modal">
    <h1>Aviso de la consulta de Buró de Crédito</h1>
  <mat-dialog-content class="container-btn">
    <p>
      Por este conducto autorizo a LEASE FOR YOU SA DE CV (en adelante “Lease
      for U”), para que, por conducto de funcionarios facultados, lleve a cabo
      investigaciones sobre mi comportamiento e historial crediticio, así como
      de cualquier de naturaleza análoga en las sociedades de información
      crediticia que estime conveniente.
    </p>
    <p>
      Asimismo, declaro que conozco la naturaleza y alcance de la información
      que se solicitará, del uso que Lease for U hará de tal información y de
      que este podrá realizar consultas periódicas de mi historial crediticio,
      consintiendo que esta autorización se encuentra vigente por un periodo de
      3 (tres) años contados a partir de la fecha de su expedición y en todo
      caso, durante el tiempo que mantengamos una relación jurídica.
    </p>
  </mat-dialog-content>
</div>
