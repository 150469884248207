import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/_services';

@Component({
  selector: 'app-cotizacion-preconfirmada',
  templateUrl: './cotizacion-preconfirmada.component.html',
  styleUrls: ['./cotizacion-preconfirmada.component.scss']
})
export class CotizacionPreconfirmadaComponent implements OnInit {
  @Input() cotizacion:any

  constructor(
    private userService:UserService,
    private router:Router
  ) { }

  ngOnInit(): void {
  }

  SeleccionarCotizacion(){
    if( !this.userService.alertService.isOnline() ) return
    
    const { version, url, id_cotizacion, plazo } = this.cotizacion
    localStorage.setItem('cotizacion', JSON.stringify({
      version,
      url,
      id_cotizacion,
      plazo: plazo
    }))
    this.router.navigate(['dashboard/detalle_cotizacion'])
  }
}
