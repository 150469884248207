import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { first } from 'rxjs/operators';

import { AlertService, UserService, AuthenticationService } from '../../_services';

@Component({
  selector: 'app-code-activation',
  templateUrl: './code-activation.component.html',
  styleUrls: ['./code-activation.component.scss']
})
export class CodeActivationComponent implements OnInit {
  codeForm: FormGroup;
  loading = false;
  submitted = false;
  code = '';
  isValid = false;
  invalid_code = false
  nuevo = false

  idx = 3
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private alertService: AlertService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.nuevo = this.route.snapshot.paramMap.get('nuevo') ? true : false
    this.codeForm = this.formBuilder.group({
        code: ['', [Validators.required, Validators.minLength(6), Validators.min(1)]]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.codeForm.controls; }

  onFullFill(data) { 
    console.log("Code: ", data);
    
    if (data.keyCode == 8) {
      this.code = '';
      this.isValid = false;
      this.invalid_code = false;
    } else if (data.length == 6){
      this.code = data;
      this.isValid = true;
      this.invalid_code = false;
      // this.onSubmit()
    }
    // else if( isNaN(Number(data.key)) ){
    //   this.code = '';
    //   this.isValid = false;
    //   this.invalid_code = true;
    //   return false
    // }
  }

  onKeypress(data){
    console.log("KeyPress: ", data);
    return data
    
  }

  sendCode() {
    if( !this.userService.alertService.isOnline() ) return
    const idCliente = JSON.parse(localStorage.getItem('currentUser'));
    console.log('--->>  ', idCliente);

    this.loading = true;
    this.userService.sendCode(idCliente.email)
      .pipe(first())
      .subscribe(
        data => {
          console.log('data function', data);
          if (data.status === 220) {
              this.loading = false;
              this.invalid_code = true;
          } else if (data.status === 200) {
            this.dialog.open(MessageBoxComponent, {
              width: '500px',
              data: {
                text: 'Éxito',
                text3: 'Hemos enviado un nuevo Código a tu correo electrónico.',
                type: 'success',
                icon: 'success'
              }
            })
          }
        },
        error => {
          console.log('Error al activar el código: ', error);

          this.dialog.open(MessageBoxComponent, {
            width: '500px',
            data: {
              text: 'El servicio no está disponible por el momento.',
              text3: 'Vuelve a intentarlo',
              type: 'error',
              icon: 'error'
            }
          })
        });
  }

  onSubmit() {
    if( !this.userService.alertService.isOnline() ) return
    this.submitted = true;
    if (!this.isValid) {
      return;
    }

    let id_cliente = JSON.parse(localStorage.getItem('currentUser'));

    this.loading = true;
    this.userService.activateCode(id_cliente.id, this.code, this.nuevo)
        .pipe(first())
        .subscribe(
            data => {
              if (data.status == 220) {
                this.loading = false;
                this.invalid_code = true
            } else if (data.status == 200) {
              if( this.nuevo ){
                const {id} = JSON.parse(localStorage.getItem('currentUser'))
                this.userService.getStatus(id, this.code)
              }else{
                this.router.navigate(['dashboard']);
              }
            }
        },
            error => {
              console.log("Error al activar el código: ", error);

              this.invalid_code = true
              this.loading = false;
            });
}
}
