import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from 'src/app/_services';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ModalConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router,
    private userService: UserService
  ) { }
  ngOnInit(): void {
  }

  guardar(){
    if(this.data.aceptar){
      this.data.aceptar()
    }
    this.dialogRef.close()
  }
  cerrar(){
    if(this.data.cerrar){
      this.data.cerrar()
    }
    this.dialogRef.close();
  }
}
