import { FormGroup, FormBuilder, Validators } from '@angular/forms';
const cur_date = (new Date()).toISOString().split('T')[0]
const formBuilder: FormBuilder = new FormBuilder

export const EntrevistaDatosGenerales = () => formBuilder.group({
    nombre: [''],
    fecha: [cur_date, [Validators.required]],
    tel_casa: [''],
    tel_cel: [''],
    rfc: [''],
    domicilio: [''],
    domicilio_propio: [false],
    domicilio_antiguedad: [''],
    mmr: [0], // Monto Mensual de la Renta
    dependientes: [],
    emd: [0], // Egresos mensuales de dependientes
    gastos_buro: [0],
    consejo_especialidad: ['']
})

export const EntrevistaActEconomica = () => formBuilder.group({
    sector_publico: [false],
    hospital_clinica: ['', []],
    tipo_contrato: ['', ],
    puesto: ['', ],
    puesto_antiguedad: ['',],
    ingreso_neto_mensual: ['', ],
    puesto_domicilio: ['', ]
  })

  export const EntrevistaConsultorio = () => formBuilder.group({
    propio: [false],
    monto_renta: ['', ],
    telefono: ['',  Validators.minLength(10)],
    domicilio: ['', ],
    pacientes_por_mes: [0, ],
    precio_consulta: [0, ],
    ingresos_aprox_pacientes: [0, ],
    estudios_por_mes: [0, ],
    precio_estudio: [0, ],
    ingresos_aprox_estudios: [0, ],
    cirugias_por_mes: [0, ],
    precio_cirugia: [0, ],
    ingresos_aprox_cirugias: [0, ],
    ingresos_efectivo: [0, ],
    ingresos_aprox_mensuales: [0, ],
    tipo_equipo: ['Nuevo', ],
    ingresos_extras_equipo: [0, ],
    estudios_por_mes_equipo: [0, ],
    precio_estudio_equipo: [0, ],
    ingresos_aprox_estudios_equipo: [0, ],
    tipo_estudios: ['', ]
  })

  export const EntrevistaAval = () => formBuilder.group({
    aval_nombre: ['', ],
    parentezco: ['', ],
    aval_ocupacion: ['', ],
    aval_domicilio: ['', ],
    aval_telefono: ['', Validators.minLength(10)],
    tiempo_conocerlo: ['', ],
    lo_recomienda: [false, ]
  })

  export const EntrevistaReferencias = [
    {
      nombre: '',
      parentezco: '',
      ocupacion: '',
      domicilio: '',
      telefono: '',
      tiempo_conocerlo: '',
      lo_recomienda: false,
    },
    {
      nombre: '',
      parentezco: '',
      ocupacion: '',
      domicilio: '',
      telefono: '',
      tiempo_conocerlo: '',
      lo_recomienda: false,
    },{
      nombre: '',
      parentezco: '',
      ocupacion: '',
      domicilio: '',
      telefono: '',
      tiempo_conocerlo: '',
      lo_recomienda: false,
    }
  ]