import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-documents-estado-cuenta-new',
  templateUrl: './documents-estado-cuenta-new.component.html',
  styleUrls: ['./documents-estado-cuenta-new.component.scss']
})
export class DocumentsEstadoCuentaNewComponent implements OnInit {
  @Input() documentos: any[];
  @Input() index;
  @Input() solicitante;
  @Input() show = true;

  @Output() VerInfo = new EventEmitter();
  @Output() Upload = new EventEmitter();
  @Output() VerDocumento = new EventEmitter();
  @Output() VerComentarios = new EventEmitter();
  aceptando = false;
  rechazando = false;
  perfil = null;

  validar_documento;

  documentos_estados_cuenta = [];
  estadosCuentaCompletos:boolean;
  constructor() { }
  ngOnChanges(changes: SimpleChanges){
    console.log("ESTADOS", this.documentos )
    this.filtrarEstatusDocumentos();
  }

  ngOnInit(): void {
    console.log("DOCUMENTOS ESTADOS", this.documentos)
    this.filtrarEstatusDocumentos();
   
  }
  filtrarEstatusDocumentos(){
    const documentosCompletos =  this.documentos.filter(documento => documento.status === 1);
    this.estadosCuentaCompletos = documentosCompletos.length > 0
    console.log("DOCUMENTOS ESTADOS COMPLETO", documentosCompletos)
    console.log("DOCUMENTOS ESTADOS COMPLETO", this.estadosCuentaCompletos)
  }
  verDocumento(documentoIndex: number): void {
    const documento = this.documentos[documentoIndex];
    if (!documento) return;
    console.log("documento.status :", documento.status);
    console.log("documento.tipo_documento :", documento.tipo_documento);
    console.log("DOc", documento)
    this.VerDocumento.emit(documento);
  }

  upload(documentoIndex: number): void {
    const documento = this.documentos[documentoIndex];
    if (!documento) return;
    // Agrega las condiciones lógicas necesarias según tus requerimientos
    this.Upload.emit(documento);
  }

  info(descripcion: string): void {
    this.VerInfo.emit(descripcion);
  }
  getOrdinal(index: number): string {
    const suffixes = ['er', 'do', 'er']; 
    const suffix = suffixes[index];
    return (index + 1) + suffix;
  }


}
