import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { MustMatch } from '../../_helpers/must-match.validator';

import { AlertService, UserService, AuthenticationService } from '../../_services';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { CustomValidators } from './custom-validators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordComponent implements OnInit {
    resetForm: FormGroup;
    loading = false;
    submitted = false;
    fase1 = false;
    passwordHide = false;
    newPasswordHide = false;
    confirmPasswordHide = false;
    user_not_register = false;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private alertService: AlertService,
        private dialog: MatDialog
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.resetForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.pattern(/^([a-zA-Z]|[0-9]+[a-zA-Z]+)([a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~])*@[a-zA-Z0-9\-]+\.[a-zA-Z]{2,}/)]],
            //newPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]],
            // newPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&#.])[A-Za-z0-9$@$!%*?&#.].{7,19}$'), Validators.maxLength(20)]],
            newPassword: [
                "",
                [
                  Validators.compose([
                    Validators.required,
                    Validators.minLength(8),
                    // Validators.pattern(/\d/, { hasNumber: true }),
                    CustomValidators.patternValidator(/\d/, { hasNumber: true }),
                    CustomValidators.patternValidator(/[A-Z]/, {
                      hasCapitalCase: true,
                    }),
                    CustomValidators.patternValidator(/[a-z]/, {
                      hasSmallCase: true,
                    }),
                    CustomValidators.patternValidator(
                      /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
                      {
                        hasSpecialCharacters: true,
                      }
                    ),
                  ]),
                ],
              ],
            confirmPassword: ['', [Validators.required]],
            password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]]
        }, {
            validator: MustMatch('newPassword', 'confirmPassword')
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.resetForm.controls; }

    onSubmit() {
        if ( this.userService.alertService.isOnline() ) {

            this.submitted = true;

            // stop here if form is invalid
            if (!this.resetForm.controls.email.valid) {
                return;
            } else if (!this.fase1) {
                this.loading = true;
                this.userService.forgotPassword(this.f.email.value)
                    .pipe(first())
                    .subscribe(
                        ({body, body: {message}}) => {
                            console.log('res: ', body);

                            this.loading = false;
                            this.fase1 = true;
                            this.submitted = false;
                            // this.dialog.open(MessageBoxComponent,{
                            //     data: {
                            //         width: "400px",
                            //         text: message,
                            //         type: "success",
                            //         icon: "success"
                            //     }
                            // })
                        },
                        error => {
                            console.log('Error: ', error);
                            this.loading = false;

                            // if( error.error.errorMessage == 'Correo no registrado' ) return this.user_not_register = true

                            this.dialog.open(MessageBoxComponent, {
                                width: '500px',
                                data: {
                                    text: error.error.errorMessage,
                                    text3: 'Vuelve a intentarlo.',
                                    type: 'error',
                                    icon: 'error'
                                }
                            });
                        });
            } else {
                if (!this.resetForm.controls.password.valid ||
                    !this.resetForm.controls.newPassword.valid ||
                    (this.resetForm.controls.newPassword.valid !== this.resetForm.controls.confirmPassword.valid)
                ) { return }
                this.loading = true;

                this.userService.resetPassword(this.resetForm.value)
                    .pipe(first())
                    .subscribe(
                        ({body: {message}}) => {
                            this.loading = false;
                            this.dialog.open(MessageBoxComponent, {
                                width: '500px',
                                data: {
                                    text: 'Éxito',
                                    text3: 'Se ha generado tu nueva contraseña',
                                    type: 'success',
                                    icon: 'success'
                                }
                            });
                            this.router.navigate(['/login']);
                        },
                        error => {
                            // this.user_not_register = true
                            this.dialog.open(MessageBoxComponent, {
                                width: '500px',
                                data: {
                                    text: 'La contraseña es incorrecta.',
                                    text3: 'Velve a intentarlo.',
                                    type: 'error',
                                    icon: 'error'
                                }
                            });
                            this.loading = false;
                        });
            }
        }
    }
    onKeyup({keyCode}) {
        if ( keyCode === 13 ) { this.onSubmit() }
    }
}

