import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/_models';
import { UserService, AuthenticationService } from 'src/app/_services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  currentUser: User;
  // procesos_iniciales = [
  //   {id_proceso: 1, nombre:'SOLICITUD', status: 0},
  //   {id_proceso: 2, nombre:'EXPEDIENTE', status: 0},
  //   {id_proceso: 3, nombre:'AUTORIZACION', status: 0},
  //   {id_proceso: 4, nombre:'COMITÉ', status: 0},
  // ];

  constructor(
      private userService: UserService,
      private router: Router,
      private authenticationService: AuthenticationService
    ) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    }

  ngOnInit(): void {
// 	this.authenticationService.logout();
//  this.router.navigate(['/login']);
 const { perfil } = this.currentUser; // Si el usuario es cliente obtiene el estatus,
 if (perfil !== 1 ) { return this.userService.checkProcess(); } // sino es redireccionado a su dashboard
 this.userService.checkProcess().
      subscribe( client => {
        // const folio = JSON.parse( localStorage.getItem('currentUser'));
        // const client_status = {
        //   cliente: client.nombre,
        //   folio: folio.quatation.Code,
        //   procesos: {}
        // };
        // console.log('getStatus: ', client, 'folio: ', folio);

        // client_status.procesos = client.estauts ? client.estauts.map(
        //   ({id_proceso, nombre, estatus: status}) => ({id_proceso, nombre, status})
        // ) : this.procesos_iniciales;

        // localStorage.setItem('client_details', JSON.stringify(client_status));
        // this.router.navigateByUrl(`/dashboard/cliente/${folio.quatation.Code}`)
      },
	  err => console.log('Error: ', err));
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
}
}
