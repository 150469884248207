<form [formGroup]="frmConyugue">
  <div class="container-form-first">
    <app-input-validation
      type="text"
      [formGroup]="frmConyugue"
      [controlName]="'nombres'"
      [errorMessage]=""
      label="Nombre(s)"
      mask=""
    ></app-input-validation>
  </div>
  <div class="container-form">
    <div class="column">
    <app-input-validation
      type="text"
      [errorMessage]=""
      [formGroup]="frmConyugue"
      [controlName]="'apellido_paterno'"
      label="Apellido Paterno"
      mask=""
    ></app-input-validation>
    <app-input-validation
      type="text"
      [formGroup]="frmConyugue"
      [controlName]="'apellido_materno'"
      [errorMessage]=""
      label="Apellido Materno"
      mask=""
    ></app-input-validation>
    </div>
    <div class="column" style="margin-left: 15px;">
    <app-input-validation
      type="text"
      [formGroup]="frmConyugue"
      [controlName]="'ocupacion'"
      [errorMessage]=""
      label="Ocupación"
      mask=""
    ></app-input-validation>
    <app-input-validation
      type="text"
      [formGroup]="frmConyugue"
      [controlName]="'tel'"
      [errorMessage]=""
      label="Teléfono"
      mask="00-0000-0000"
    ></app-input-validation>
    </div>
  </div>
</form>
