<span *ngIf="field.type == 'text'">
    {{item}}
</span>

<span *ngIf="field.type == 'currency'">
    {{item | currency:'MXN'}}
</span>

<span *ngIf="field.type == 'date'">
    {{item | date:'dd/MM/yyyy'}}
</span>
<span *ngIf="field.type == 'number'" class="box-number" [ngClass]="{
    'green_box': item >= field?.class?.green_box?.min && item <= field?.class?.green_box?.max,
    'yellow_box': item >= field?.class?.yellow_box?.min && item <= field?.class?.yellow_box?.max,
    'red_box': item >= field?.class?.red_box?.min && item <= field?.class?.red_box?.max
}">
    {{item}} {{field.label || ''}}
</span>

<select *ngIf="field.type == 'select'" name="select" [id]="'select-' + item" [(ngModel)]="item">
    <option *ngFor="let i of field?.options" [value]="i">{{i}}</option>
</select>

<div *ngIf="field.type == 'yesno'">
    <span *ngIf="field?.readonly; else elseYesno" class="box-yesno" [ngClass]="{'green_box': item == 'yes', 'red_box': item == 'no'}">
        {{item == 'yes' ? 'Sí' : 'No'}}
    </span>
    <ng-template #elseYesno>
        <select class="box-yesno" [ngClass]="{'green_box': item == 'yes', 'red_box': item == 'no'}" name="select-yesno" [id]="'select-yesno-' + item" [(ngModel)]="item">
            <option [value]="'yes'">Sí</option>
            <option [value]="'no'">No</option>
        </select>
    </ng-template>

    
</div>