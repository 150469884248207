<div class="container-page">
    <div class="top">
        <div class="header">
            <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                <img class="logo" mat-button height="60px" width="auto" [routerLink]="['/dashboard']" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/logo-header.svg" alt="">
            </div>
            <div>
                <button mat-button routerLink="/changePassword">Cambiar Contraseña
                    <svg style="height: 20px; width: auto;" xmlns="http://www.w3.org/2000/svg" width="31.992" height="31.992" viewBox="0 0 31.992 31.992"><defs><style>.a{fill:#3ca2d6;}</style></defs><path class="a" d="M18,2a16,16,0,1,0,16,16A16.006,16.006,0,0,0,18,2Zm0,1.391A14.6,14.6,0,1,1,3.391,18,14.594,14.594,0,0,1,18,3.391Zm0,4.868a4.164,4.164,0,0,0-4.173,4.173v2.086h-.7A2.1,2.1,0,0,0,11.041,16.6V23.56a2.1,2.1,0,0,0,2.086,2.086h9.737a2.1,2.1,0,0,0,2.086-2.086V16.6a2.1,2.1,0,0,0-2.086-2.086h-.7V12.432A4.164,4.164,0,0,0,18,8.259ZM18,9.65a2.724,2.724,0,0,1,2.782,2.782v2.086H15.214V12.432A2.724,2.724,0,0,1,18,9.65Zm-4.868,6.259H14.4a.7.7,0,0,0,.227,0h6.728a.7.7,0,0,0,.227,0h1.281a.693.693,0,0,1,.7.7V23.56a.693.693,0,0,1-.7.7H13.128a.693.693,0,0,1-.7-.7V16.6A.693.693,0,0,1,13.128,15.909ZM18,18a1.39,1.39,0,0,0-.7,2.594v.883a.7.7,0,1,0,1.391,0v-.884A1.389,1.389,0,0,0,18,18Z" transform="translate(-2 -2)"/></svg>
                </button>
                <button mat-button (click)="logout()">Cerrar Sesión
                    <svg style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="32" height="31.992" viewBox="0 0 32 31.992"><defs><style>.a{fill:#3ca2d6;}</style></defs><path class="a" d="M18,2A16,16,0,1,0,30.9,27.449a.7.7,0,1,0-1.121-.823,14.6,14.6,0,1,1,0-17.259A.7.7,0,1,0,30.9,8.543,15.976,15.976,0,0,0,18,2Zm9.73,9.73a.7.7,0,0,0-.485,1.194L31.617,17.3H16.6a.7.7,0,1,0,0,1.391H31.617l-4.377,4.377a.7.7,0,1,0,.984.984l5.5-5.5a.7.7,0,0,0,0-1.107l-5.5-5.5A.7.7,0,0,0,27.726,11.73Z" transform="translate(-2 -2)"/></svg>
                </button>
            </div>
        </div>
        <div class="title">{{ promocion.id > 0 ? 'Editar' : 'Crear'}} Promoción</div>
    </div>

    <div class="box">
        <div class="image-box">
            <span>Logo</span>
            <img id="product_image" [src]="image_preview" alt="Agregar imagen">
            <input type="file" name="product_image" (change)="ImagePreview()"/>
        </div>
        <div class=" crear-promocion">
            <div>
                Nombre de la promoción:
                <input type="text" [(ngModel)]="promocion.name" (input)="error_name = false">
            </div>
            <mat-error *ngIf="error_name">Campo obligatorio</mat-error>
            <div>
                Descripción de la promoción:
                <textarea style="height: 40px; padding: 3px;" [(ngModel)]="promocion.description" (input)="error_description = false"></textarea>
            </div>
            <mat-error *ngIf="error_description">Campo obligatorio</mat-error>
            <div class="line-controls">
                Inicio:
                <input type="datetime-local" [(ngModel)]="promocion.date_start" [min]="hoy" (change)="error_dates = false">
                Fin:
                <input type="datetime-local" [(ngModel)]="promocion.date_end" [min]="hoy" (change)="error_dates = false">
            </div>
            <mat-error *ngIf="error_dates">Las fechas son inválidas</mat-error>
            <div class="line-controls" style="width: 100%;">
                <input class="checkbox" type="checkbox" style="height: 18px; width: 18px; margin-left: 0; margin-right: 5px;"
                [(ngModel)]="promocion.active">
                Activa
            </div>


            <!-- <div> -->
            <!-- <div class="line-controls">
                Tasa mensual:
                <input type="number" [(ngModel)]="promocion.condiciones.monthly_rate" style="width: 50px;" (input)="error_rate = false">
                <mat-error *ngIf="error_rate">Tasa mensual inválida</mat-error>
                Descuento ciego:
                <input type="number" [(ngModel)]="promocion.condiciones.monthly_rate" style="width: 50px;" (input)="error_rate = false">
            </div> -->
            <div class="line-controls">
                Descuento ciego:
                <select name="commission_for_opening_type" id="commission_for_opening_type" [(ngModel)]="promocion.condiciones.descuento_ciego_type" >
                    <option value="a">Monto $</option>
                    <option value="p">Porcentaje %</option>
                </select>
                
                <input *ngIf="promocion.condiciones.descuento_ciego_type === 'a'" matInput name="commission_for_opening" id="commission_for_opening"
                    mask="separator.2"
                    thousandSeparator=","
                    prefix="$ "
                    [(ngModel)]="promocion.condiciones.descuento_ciego" 
                    style="width: 150px;"
                    placeholder="$ 0"
                    maxlength="13"/>
                    <input *ngIf="promocion.condiciones.descuento_ciego_type === 'p'" matInput name="commission_for_opening" id="commission_for_opening"
                    mask="separator.1"
                    thousandSeparator=","
                    suffix=" %"
                    [(ngModel)]="promocion.condiciones.descuento_ciego" 
                    style="width: 150px;"
                    placeholder="0 %"
                    maxlength="5"/>
            </div>
            <!-- <div class="line-controls">
                Descuento ciego MSI:
                <select name="decuento_ciego_type" id="commission_for_opening_type" [(ngModel)]="promocion.condiciones.descuento_ciego_type_msi" >
                    <option value="a">Monto $</option>
                    <option value="p">Porcentaje %</option>
                </select>
                
                <input *ngIf="promocion.condiciones.descuento_ciego_type_msi === 'a'" matInput name="decuento_ciego_type" id="decuento_ciego_type"
                    mask="separator.2"
                    thousandSeparator=","
                    prefix="$ "
                    [(ngModel)]="promocion.condiciones.descuento_ciego_msi" 
                    style="width: 150px;"
                    placeholder="$ 0"
                    maxlength="13"/>
                    <input *ngIf="promocion.condiciones.descuento_ciego_type_msi === 'p'" matInput name="decuento_ciego_type" id="commission_for_opening"
                    mask="separator.1"
                    thousandSeparator=","
                    suffix=" %"
                    [(ngModel)]="promocion.condiciones.descuento_ciego_msi" 
                    style="width: 150px;"
                    placeholder="0 %"
                    maxlength="5"/>
            </div> -->

            
            <table class="vendor_config">
                <thead>
                    <tr><th colspan="2">Tasas mensuales</th></tr>
                </thead>
                <thead>
                    <tr>
                        <th>Arrendamiento Puro</th>
                        <th>Meses sin Intereses</th>
                    </tr>
                </thead>
                <tr>
                    <td style="text-align: center;">
                        
                        <input type="number" [(ngModel)]="promocion.condiciones.monthly_rate" style="width: 50px;" (input)="error_rate = false">
                        <mat-error *ngIf="error_rate">Tasa mensual inválida</mat-error>
                    </td>
                    <td style="text-align: center;">
                        <input type="number" [(ngModel)]="promocion.condiciones.monthly_rate_msi" style="width: 80px;"  />
                    </td>
                </tr>
            </table>

            <table class="vendor_config">
                <thead>
                    <tr><th colspan="2">Comisión por apertura </th></tr>
                </thead>
                <thead>
                    <tr>
                        <th>Arrendamiento Puro</th>
                        <th>Meses sin Intereses</th>
                    </tr>
                </thead>
                <tr>
                    <td style="text-align: center;">
                        
                        <div>
                            <select name="commission_for_opening_type" id="commission_for_opening_type" [(ngModel)]="promocion.condiciones.commission_for_opening_type" (change)="ResetCommission()">
                                <option value="a">Monto $</option>
                                <option value="p">Porcentaje %</option>
                            </select>
                            
                            <input *ngIf="promocion.condiciones.commission_for_opening_type === 'a'" matInput name="commission_for_opening" id="commission_for_opening"
                                mask="separator.2"
                                thousandSeparator=","
                                prefix="$ "
                                [(ngModel)]="promocion.condiciones.commission_for_opening" 
                                style="width: 150px;"
                                placeholder="$ 0"
                                maxlength="13"/>
                        
                            <input *ngIf="promocion.condiciones.commission_for_opening_type === 'p'" matInput name="commission_for_opening" id="commission_for_opening"
                                mask="separator.1"
                                thousandSeparator=","
                                suffix=" %"
                                [(ngModel)]="promocion.condiciones.commission_for_opening" 
                                style="width: 150px;"
                                placeholder="0 %"
                                maxlength="5"/>
                        </div>
                    </td>
                    <td style="text-align: center;">
                        <div>
                            <select name="commission_for_opening_type" id="commission_for_opening_type" [(ngModel)]="promocion.condiciones.commission_for_opening_type_msi" (change)="ResetCommission()">
                                <option value="a">Monto $</option>
                                <option value="p">Porcentaje %</option>
                            </select>
                            
                            <input *ngIf="promocion.condiciones.commission_for_opening_type_msi === 'a'" matInput name="commission_for_opening" id="commission_for_opening"
                                mask="separator.2"
                                thousandSeparator=","
                                prefix="$ "
                                [(ngModel)]="promocion.condiciones.commission_for_opening_msi" 
                                style="width: 150px;"
                                placeholder="$ 0"
                                maxlength="13"/>
                        
                            <input *ngIf="promocion.condiciones.commission_for_opening_type_msi === 'p'" matInput name="commission_for_opening" id="commission_for_opening"
                                mask="separator.1"
                                thousandSeparator=","
                                suffix=" %"
                                [(ngModel)]="promocion.condiciones.commission_for_opening_msi" 
                                style="width: 150px;"
                                placeholder="0 %"
                                maxlength="5"/>
                        </div>
                    </td>
                </tr>
            </table>
                
         
            <div class="line-controls">
                <mat-select [(ngModel)]="agregar_vendor">
                    <mat-option [value]="0">Seleccionar un vendor</mat-option>
                    <mat-option *ngFor="let vendor of lista_vendors; let i = index" [value]=" i +1">{{ vendor.name | uppercase}}</mat-option>
                </mat-select>
                <button mat-mini-fab extended style="color: #FFF; margin-left: 12px;" (click)="AgregarVendor()"><mat-icon>add</mat-icon></button>
            </div>
    
            <div style="width: 100%; max-height: 300px; overflow-y: scroll;">
                <table [cellSpacing]="0" [cellPadding]="0">
                    <thead>
                        <tr><th>N.</th><th>Vendor</th><th></th></tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td *ngIf="promocion.vendors.list.length == 0" colspan="2" style="text-align: center;"><mat-error>Agrega por lo menos un vendor</mat-error></td>
                        </tr>
                        <tr *ngFor="let vendor of promocion.vendors.list; let i = index">
                            <td style="text-align: center;">{{i +1}}</td>
                            <td>{{vendor.name | uppercase}}</td>
                            <td style="text-align: right;"><mat-icon style="color: red; cursor: pointer;" (click)="EliminarVendor(i)">delete</mat-icon></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
    
            <div class="line-controls buttons" style="width: 100%; margin-bottom: 12px;">
                <button class="cancel_button" (click)="location.back()">Cancelar</button>
                <button *ngIf="!loading" class="principal_button" (click)="CrearPromocion()">
                    <span>Guardar</span>
                </button>
                <mat-progress-spinner *ngIf="loading" mode="indeterminate" diameter="25" style="margin: 0 50px;"></mat-progress-spinner>
            
            </div>
        </div>
    </div>
    <app-socials-footer style="width: 100%;"></app-socials-footer>
</div>