import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-cotizacion',
  templateUrl: './cotizacion.component.html',
  styleUrls: ['./cotizacion.component.scss']
})
export class CotizacionComponent implements OnInit {
  @Input() Cotizacion = null
  @Output() onClick = new EventEmitter

  constructor() { }

  ngOnInit(): void {
  }

  Click() {
    this.onClick.emit()
  }

}
