<div>
  <div class="container-date">
    <mat-form-field appearance="outline">
      <mat-label>Vendor</mat-label>
      <input matInput type="text"  [(ngModel)]="AnalisisPM?.cotizacion.vendor" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Comercial</mat-label>
      <input matInput type="text" [(ngModel)]="AnalisisPM?.cotizacion.comercial" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Plazo</mat-label>
      <input matInput type="text" [(ngModel)]="AnalisisPM?.cotizacion.plazo" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Factor</mat-label>
      <input matInput type="text" [(ngModel)]="AnalisisPM?.cotizacion.factor" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Tiempo de entrega</mat-label>
      <input matInput type="text" [(ngModel)]="AnalisisPM?.cotizacion.tiempo_entrega" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Garantia</mat-label>
      <input matInput type="text" [(ngModel)]="AnalisisPM?.cotizacion.garantia" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Porcentaje pago inicial</mat-label>
      <input prefix="%" matInput [(ngModel)]="AnalisisPM?.cotizacion.porcentaje_pago_inicial" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Pago Inicial</mat-label>
      <input
        prefix="$"
        mask="separator.2"
        thousandSeparator=","
        matInput
        [(ngModel)]="AnalisisPM.cotizacion.monto_pago_inicial"
      />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Valor Residual</mat-label>
      <input matInput type="text" [(ngModel)]="AnalisisPM.cotizacion.valor_residual" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Mensualidad</mat-label>
      <input
        prefix="$"
        mask="separator.2"
        thousandSeparator=","
        matInput
        [(ngModel)]="AnalisisPM.cotizacion.mensualidad"
      />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Valor Pagare Final</mat-label>
      <input
        prefix="$"
        mask="separator.2"
        thousandSeparator=","
        matInput
        [(ngModel)]="AnalisisPM.cotizacion.valor_pagare_final"
      />
    </mat-form-field>
  </div>
  <div class="container-date">
    <div style="display: grid; grid-template-columns: 80% 20%; ">
    <mat-form-field appearance="outline">
      <mat-label>Vida útil</mat-label>
      <input matInput type="text" [(ngModel)]="AnalisisPM.caracteristica_activo.vida_util" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Puntos</mat-label>
      <input matInput type="text" [(ngModel)]="AnalisisPM.caracteristica_activo.puntos_vida_util"  (ngModelChange)="sumarPuntuacionCaracteristicaActivo()"/>
    </mat-form-field>
    </div>
    <div style="display: grid; grid-template-columns: 80% 20%; ">
    <mat-form-field appearance="outline">
      <mat-label>Mercado Secundario</mat-label>
      <input matInput type="text" [(ngModel)]="AnalisisPM.caracteristica_activo.mercado_secundario" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Puntos</mat-label>
      <input matInput type="text" [(ngModel)]="AnalisisPM.caracteristica_activo.puntos_mercado_secundario" (ngModelChange)="sumarPuntuacionCaracteristicaActivo()" />
    </mat-form-field>
    </div>
    <div style="display: grid; grid-template-columns: 80% 20%; ">
    <mat-form-field appearance="outline">
      <mat-label>Estimado Colocacion</mat-label>
      <input matInput type="text" [(ngModel)]="AnalisisPM.caracteristica_activo.estimado_colocacion" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Puntos</mat-label>
      <input matInput type="text" [(ngModel)]="AnalisisPM.caracteristica_activo.puntos_estimado_colocacion" (ngModelChange)="sumarPuntuacionCaracteristicaActivo()"/>
    </mat-form-field>
    </div>
    <div style="display: grid; grid-template-columns: 80% 20%; ">
    <mat-form-field appearance="outline">
      <mat-label>Ubicacion</mat-label>
      <input matInput type="text" [(ngModel)]="AnalisisPM.caracteristica_activo.ubicacion" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Puntos</mat-label>
      <input matInput type="text" [(ngModel)]="AnalisisPM.caracteristica_activo.puntos_ubicacion" (ngModelChange)="sumarPuntuacionCaracteristicaActivo()"/>
    </mat-form-field>
    </div>
    <div style="display: grid; grid-template-columns: 80% 20%; ">
    <mat-form-field appearance="outline">
      <mat-label>Tipo de Financiamiento</mat-label>
      <input matInput type="text" [(ngModel)]="AnalisisPM.caracteristica_activo.tiempo_financiamiento" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Puntos</mat-label>
      <input matInput type="text" [(ngModel)]="AnalisisPM.caracteristica_activo.puntos_tiempo_financiamiento" (ngModelChange)="sumarPuntuacionCaracteristicaActivo()"/>
    </mat-form-field>
    </div>
  </div>
  <div >
    <mat-form-field appearance="outline">
        <mat-label>Puntuación total</mat-label>
        <input matInput type="text" [(ngModel)]="AnalisisPM.puntuacion_total_activo" readonly/>
      </mat-form-field>
  </div>
  <div><h3>Comentarios Activo</h3></div>
  <mat-form-field appearance="outline">
    <mat-label>Comentarios activo</mat-label>
    <textarea matInput [(ngModel)]="AnalisisPM.comentarios_activo"></textarea>
  </mat-form-field>
  <div >
    <mat-form-field appearance="outline">
        <mat-label>Puntuación final</mat-label>
        <input matInput type="text" [(ngModel)]="AnalisisPM.puntuacion_final_activo"/>
      </mat-form-field>
  </div>
  
        <h3>Resolución De Comité</h3>
   

    <div style="width: 100%; display: flex; justify-content: space-between; gap: 50px;" >
        <mat-radio-group  required style="width: 100%; display: flex; justify-content: space-between; align-items: center;" [(ngModel)]="AnalisisPM.id_resolucion_comite" >
            <mat-radio-button labelPosition="before" [value]="1" >Aprobada</mat-radio-button>
            <mat-radio-button labelPosition="before" [value]="3" >Información<br>Adicional</mat-radio-button>
            <mat-radio-button labelPosition="before" [value]="2">Aprobada<br>Condicionalmente</mat-radio-button>
            <mat-radio-button labelPosition="before" [value]="4" >Rechazada</mat-radio-button>
        </mat-radio-group>
    </div>
    <div>
        <div class="input">
            <textarea maxlength="500" placeholder="Comentarios" [(ngModel)]="AnalisisPM.comentarios_resolucion_comite" ></textarea>
        </div>
    </div>
    <div class="container-btn">
      <button class="btn-secondary-new" (click)="guardarYRetroceder()">Atras</button>
      <button class="btn-primary-new" (click)="guardarYAvanzar()">
        <mat-spinner *ngIf="loading" diameter="20" ></mat-spinner> <span *ngIf="!loading">Guardar</span>
      </button>
    </div>
   

</div>
