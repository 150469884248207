import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ver-documento',
  templateUrl: './ver-documento.component.html',
  styleUrls: ['./ver-documento.component.scss']
})
export class VerDocumentoComponent implements OnInit {
  documento:any = null
  url:any = ""
  constructor() { }

  ngOnInit(): void {
    this.documento = JSON.parse(localStorage.getItem('documento'))
    this.url = this.documento.url
    console.log(this.documento);
    
  }


}
