<div class="filtro-avanzado">
    <div (click)="showFiltroAvanzado = true" style="cursor: pointer;">
        <img src="/assets/icons/filter.png" alt="filtro">
        <span>Filtro avanzado</span>
    </div>
</div>
<div class="stats">
    <div *ngFor="let item of summary">
        <span class="title">{{item.title}}</span>
        <div>
            <span *ngIf="item.type == 'currency'" class="value">{{item.value | currency:'MXN'}}</span>
            <span *ngIf="item.type != 'currency'" class="value">{{item.value}}</span>
            <span class="percent">
                {{item.percent}}
                <img *ngIf="item.percent != '0%'" src="/assets/icons/trending-up.png" alt="trending">
            </span>
        </div>
    </div>
</div>
<div style="width: 100%; overflow-y: auto ;">
    <table class="pipeline-table">
        <thead>
            <tr>
                <th *ngFor="let i of _headers" [style]="{'min-width': headers[i].width}">{{headers[i].value}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of data">
                <td *ngFor="let i of _headers">
                    <app-pipelines-tramites-table-item [item]="item[i]" [field]="headers[i]"></app-pipelines-tramites-table-item>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div *ngIf="showFiltroAvanzado" class="splash" [ngClass]="{visible: showFiltroAvanzado}">
    <app-advanced-filters style="width: 100%;" (close)="showFiltroAvanzado = false"></app-advanced-filters>
</div>