import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { customPatterns, emailPattern } from 'src/app/_models/maskPattern';
import { SolicitudService } from 'src/app/_services';

@Component({
  selector: 'app-form-moral',
  templateUrl: './form-moral.component.html',
  styleUrls: ['./../../consulta-buro.component.scss']
})
export class FormMoralComponent implements OnInit {
  @Input() procesos;
  @Input() formData;
  @Output() data = new EventEmitter();
  
  isClient = false
  personaForm: FormGroup;
  datosGen:any = {
    rfc: '',
    correo: '',
    razon_social: ''
  }
  customPattern = customPatterns;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private solicitud: SolicitudService,
  ) { }

  ngOnInit(): void {
    const client_details = localStorage.getItem('client_details')
    const email = client_details ? JSON.parse(client_details).email : ''
    this.isClient = email != ''
    this.personaForm = this.formBuilder.group({

      rfc: ['', [Validators.required, Validators.minLength(12)]],
      correo: [email, [Validators.required, Validators.pattern( emailPattern )]],
      razon_social: ['', [Validators.required]],
    });

    this.setInfo()
  }

  get f() { return this.personaForm.controls; }

  async onSubmit() {
    this.submitted = true;
    if (this.personaForm.invalid) {
      console.log('invalido ', this.submitted);
      return;
    } else {
      this.formData.RazonSocial = this.personaForm.value.razon_social;
      this.formData.RFC = this.personaForm.value.rfc;
      this.formData.correo = this.personaForm.value.correo;
      this.data.emit(this.formData);
      this.procesos[1].status = 1;
    }
  }

  setInfo(){
    const client_details = JSON.parse( localStorage.getItem('client_details'))
    if( client_details ){
      const { id_cliente, folio, tipo_persona } = client_details
      const guardado = { id_cliente, folio, parte: 1, tipo_persona }
      this.solicitud.getDatosGenerales( {guardado} ).subscribe( (res) => {
        const { razon_social, pm_rfc} = res
        
        this.f.rfc.setValue(pm_rfc.toUpperCase())
        this.f.razon_social.setValue(razon_social.toUpperCase())
      })
    }
  }
}
