export class User {
    id: number;
    perfil: number;
    email: string;
    password: string;
    newPassword: string;
    confirmPassword: string;
    place: string;
    token: string;
    typeUser: number;
    latitude: string;
    longitude: string;
    quatation?: {
        Status: boolean
    }
}