<table class="table table-change-status" [cellSpacing]="0" [cellPadding]="0" *ngIf="tramites">
    <tr>
        <th>Folio</th>
        <th>Nombre Cliente</th>
        <th>Comercial</th>
        <th>Nombre Activo</th>
        <th>Precio con IVA</th>
        <th>Dìas transcurridos</th>
        <th>Status ACTUAL</th>
        <th>Cambiar Status</th>
    </tr>
    <tr *ngFor="let tramite of tramites.comite" [ngClass]="{'row-hidden': tramite.id_resolucion_comite !== null}">
        <td>{{tramite.folio}}</td>
        <td>{{tramite.nombre_cliente}}</td>
        <td>{{tramite.nombre_comercial}}</td>
        <td [matTooltip]="tramite.activo_descripcion">{{tramite.activo_descripcion | slice:1:50}}</td>
        <td>{{tramite.precio_con_iva | currency:'MXN'}}</td>
        <td>{{tramite.dias_totales_tramite ? tramite.dias_totales_tramite.days : 0}} dìas</td>
        <td></td>
        <td>
           <app-change-status-list [omitirStatus]="[]" (onSelect)="ActualizarStatus(tramite, $event)"></app-change-status-list>
        </td>
    </tr>
    <!-- <tr *ngFor="let tramite of tramites.aprobadas">
        <td>{{tramite.folio}}</td>
        <td>{{tramite.nombre_cliente}}</td>
        <td>{{tramite.nombre_comercial}}</td>
        <td [matTooltip]="tramite.activo_descripcion">{{tramite.activo_descripcion | slice:1:50}}</td>
        <td>{{tramite.precio_con_iva | currency:'MXN'}}</td>
        <td>{{tramite.dias_totales_tramite.days}} dìas</td>
        <td>Aprobada</td>
        <td>
           <app-change-status-list [omitirStatus]="[1]" (onSelect)="ActualizarStatus(tramite, $event)"></app-change-status-list>
        </td>
    </tr> -->
    <tr *ngFor="let tramite of tramites.aprobadas_con">
        <td>{{tramite.folio}}</td>
        <td>{{tramite.nombre_cliente}}</td>
        <td>{{tramite.nombre_comercial}}</td>
        <td [matTooltip]="tramite.activo_descripcion">{{tramite.activo_descripcion | slice:1:50}}</td>
        <td>{{tramite.precio_con_iva | currency:'MXN'}}</td>
        <td>{{tramite.dias_totales_tramite ? tramite.dias_totales_tramite.days : 0}} dìas</td>
        <td>Condicionada</td>
        <td>
           <app-change-status-list [omitirStatus]="[2]" (onSelect)="ActualizarStatus(tramite, $event)"></app-change-status-list>
        </td>
    </tr>
    <tr *ngFor="let tramite of tramites.info_adicional">
        <td>{{tramite.folio}}</td>
        <td>{{tramite.nombre_cliente}}</td>
        <td>{{tramite.nombre_comercial}}</td>
        <td [matTooltip]="tramite.activo_descripcion">{{tramite.activo_descripcion | slice:1:50}}</td>
        <td>{{tramite.precio_con_iva | currency:'MXN'}}</td>
        <td>{{tramite.dias_totales_tramite ? tramite.dias_totales_tramite.days : 0}} dìas</td>
        <td>Informaciòn Adicional</td>
        <td>
            <app-change-status-list [omitirStatus]="[3]" (onSelect)="ActualizarStatus(tramite, $event)"></app-change-status-list>
        </td>
    </tr>
    <tr *ngFor="let tramite of tramites.rechazadas">
        <td>{{tramite.folio}}</td>
        <td>{{tramite.nombre_cliente}}</td>
        <td>{{tramite.nombre_comercial}}</td>
        <td [matTooltip]="tramite.activo_descripcion">{{tramite.activo_descripcion | slice:1:50}}</td>
        <td>{{tramite.precio_con_iva | currency:'MXN'}}</td>
        <td>{{tramite.dias_totales_tramite.days}} dìas</td>
        <td>Rechazada</td>
        <td>
            <app-change-status-list [omitirStatus]="[4]" (onSelect)="ActualizarStatus(tramite, $event)"></app-change-status-list>
        </td>
    </tr>
    <!-- <tr *ngFor="let tramite of tramites.cerrados">
        <td>{{tramite.folio}}</td>
        <td>{{tramite.nombre_cliente}}</td>
        <td>{{tramite.nombre_comercial}}</td>
        <td [matTooltip]="tramite.activo_descripcion">{{tramite.activo_descripcion | slice:1:50}}</td>
        <td>{{tramite.precio_con_iva | currency:'MXN'}}</td>
        <td>{{tramite.dias_totales_tramite.days}} dìas</td>
        <td>Cerrada</td>
        <td>
            <app-change-status-list [omitirStatus]="[99]" (onSelect)="ActualizarStatus(tramite, $event)"></app-change-status-list>
        </td>
    </tr> -->
</table>
