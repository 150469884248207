import { NgModule, Pipe, PipeTransform } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";

@Pipe({
  name: "customDate",
})
export class CustomDatepipeModule extends DatePipe implements PipeTransform {
  transform(value: any, args?: any, locale?: string): any {
    console.log("FECHA CAMBIADA", value);

    const fecha = super.transform(value, "EEEE, d MMMM, y");
    const fechaSplit = fecha.split(" ")

    const dia = fechaSplit[0];
    const numero = fechaSplit[1];
    const mes = fechaSplit[2].substring(0, fechaSplit[2].length - 1);
    const año = fechaSplit[3];
    const diaCapitalize = dia[0].toUpperCase() + dia.substring(1, dia.length -1);

    // const fechaFinal = fecha.map((palabra) => {
    //   return palabra[0].toUpperCase + palabra;
    // }).join(" ")

    return  `${diaCapitalize} ${numero} de ${mes} de ${año} ` ;
  }
}
