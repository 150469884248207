import { Component, OnInit, Input } from '@angular/core';
import { LIST_STATUS } from '../../_helpers/status'

@Component({
  selector: 'app-circular-options',
  templateUrl: './circular-options.component.html',
  styleUrls: ['./circular-options.component.scss']
})
export class CircularOptionsComponent implements OnInit {
  @Input() procesos = null
  STATUS = LIST_STATUS
  constructor() {
    
   }

  ngOnInit(): void {
    // console.log("Procesos: ", this.procesos);
    
  }

}
