import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/_services';
import { PromocionesService } from 'src/app/_services/promociones.service';

@Component({
  selector: 'app-promociones',
  templateUrl: './promociones.component.html',
  styleUrls: ['./promociones.component.scss']
})
export class PromocionesComponent implements OnInit {
  lista_promociones = []

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private promocionesService: PromocionesService
  ) { }

  async ngOnInit() {
    localStorage.removeItem('promocion')
    const resp = await this.promocionesService.ObtenerPromociones()
    this.lista_promociones = resp
    console.log({resp});
    
  }

  Editar(promocion) {
    console.log(promocion);
    localStorage.setItem('promocion', JSON.stringify(promocion))
    this.router.navigate(['/','promociones', 'editar'])
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
