<div class="container">
  <h1>Filtro avanzado</h1>
  <p>Seleccione una o varias opciones para filtrar.</p>
  <div *ngIf="perfil != 2">
      Empresa: <select [(ngModel)]="empresa" (change)="ChangeCountry()">
          <option value="">Todas</option>
          <option value="MX">México</option>
          <option value="PE">Perú</option>
      </select>
  </div>
  <div style="display: flex; flex-direction: column; width: 100%; margin-top: 12px;">
    Semana compromiso:
    <div class="container-semana-compromiso">
        <div *ngFor="let semana of [1, 2, 3, 4]" 
                (click)="ChangeSemanaCompromiso(semana)"
                [ngClass]="{'selected': busqueda.semana_compromiso?.includes(semana)}"
                class="semana-button">
            {{ semana }}
        </div>
    </div>
</div>
  <p class="input-name" style="margin-bottom: 0">
    Seleccione un rango de fechas:
  </p>
  <div *ngIf="find_by_date" class="container-date">
    <div class="input">
      <mat-label class="input-name">Fecha inicial:</mat-label>
      <input
        matInput
        type="date"
        (change)="DateChange()"
        [(ngModel)]="fecha_inicial"
      />
      <mat-error *ngIf="fecha_inicial_error">Fecha no válida</mat-error>
    </div>
    <div class="input">
      <mat-label class="input-name">Fecha final:</mat-label>
      <input
        matInput
        type="date"
        (change)="DateChange()"
        [(ngModel)]="fecha_final"
        [disabled]="!fecha_inicial"
      />
      <mat-error *ngIf="fecha_final && fecha_final_error"
        >Fecha no válida</mat-error
      >
      <mat-error *ngIf="!fecha_final && fecha_inicial"
        >Selecciona una fecha</mat-error
      >
    </div>
  </div>
  <p class="input-name" style="margin-bottom: 0">
    Seleccione un representante:
  </p>
  <div class="container-range">
    <app-perfil
      [selected]="director_comercial"
      *ngIf="custom > 0"
      label="Director Comercial"
      [users]="filtro.hijos_director_comercial"
      (onSelect)="
        director_comercial = $event.id;
        Seleccionar($event.id, $event.id_perfil, 'hijos_gerente')
      "
    ></app-perfil>

    <app-perfil
      [selected]="gerente_comercial"
      *ngIf="director_comercial > 0"
      label="Gerente Comercial"
      [users]="filtro.hijos_gerente"
      (onSelect)="
        gerente_comercial = $event.id;
        Seleccionar($event.id, $event.id_perfil, 'hijos_comercial')
      "
    ></app-perfil>

    <app-perfil
      [selected]="comercial"
      *ngIf="gerente_comercial > 0"
      label="Comercial"
      [users]="filtro.hijos_comercial"
      (onSelect)="
        comercial = $event.id;
        Seleccionar($event.id, $event.id_perfil, 'hijos_vendor')
      "
    ></app-perfil>

    <app-perfil
      [selected]="vendor"
      *ngIf="comercial > 0"
      label="Vendor / Super Vendor"
      [users]="filtro.hijos_vendor"
      (onSelect)="
        vendor = $event.id;
        Seleccionar($event.id, $event.id_perfil, 'hijos_vendedor')
      "
    ></app-perfil>

    <app-perfil
      [selected]="vendedor"
      *ngIf="vendor > 0"
      label="Vendedor"
      [users]="filtro.hijos_vendedor"
      (onSelect)="
        vendedor = $event.id; Seleccionar($event.id, $event.id_perfil, '')
      "
    ></app-perfil>
  </div>
  <div class="container-btn">
    <button (click)="limpiarFiltros()"  class="btn-action-cancelar">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.3335 5.83333H16.6668M8.3335 9.16667V14.1667M11.6668 9.16667V14.1667M4.16683 5.83333L5.00016 15.8333C5.00016 16.2754 5.17576 16.6993 5.48832 17.0118C5.80088 17.3244 6.2248 17.5 6.66683 17.5H13.3335C13.7755 17.5 14.1994 17.3244 14.512 17.0118C14.8246 16.6993 15.0002 16.2754 15.0002 15.8333L15.8335 5.83333M7.50016 5.83333V3.33333C7.50016 3.11232 7.58796 2.90036 7.74424 2.74408C7.90052 2.5878 8.11248 2.5 8.3335 2.5H11.6668C11.8878 2.5 12.0998 2.5878 12.2561 2.74408C12.4124 2.90036 12.5002 3.11232 12.5002 3.33333V5.83333"
          stroke="#F58A89"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        /></svg>
        Borrar Filtros
    </button>
  </div>
</div>

