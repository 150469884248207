<div class="container">
    <div class="container-list" *ngFor="let cotizacion of cotizaciones; let i = index" >
        <div class="list" (click)="VerCotizacion(cotizacion.url, i, cotizacion.id, cotizaciones.length - i)">
        <svg style="width: 30px; height:auto;" xmlns="http://www.w3.org/2000/svg" width="39.832" height="28.969" viewBox="0 0 39.832 28.969"><defs><style>.a{fill:#3ca2d6;}</style></defs><path class="a" d="M20.916,4C6.432,4,1,18.484,1,18.484S6.432,32.969,20.916,32.969,40.832,18.484,40.832,18.484,35.4,4,20.916,4Zm0,3.621c9.553,0,14.384,7.726,15.949,10.856-1.566,3.109-6.434,10.87-15.949,10.87-9.553,0-14.384-7.726-15.949-10.856C6.536,15.383,11.4,7.621,20.916,7.621Zm0,3.621a7.242,7.242,0,1,0,7.242,7.242A7.243,7.243,0,0,0,20.916,11.242Zm0,3.621A3.621,3.621,0,1,1,17.3,18.484,3.62,3.62,0,0,1,20.916,14.863Z" transform="translate(-1 -4)"/></svg>
        <div class="left">
            <div class="version">Versión {{cotizaciones.length - i}}</div>
            <div class="date">{{cotizacion.fecha | date:'dd MMM yyyy'}}</div>
        </div>
        <div class="right">
            <div class="status">{{ cotizacion.approved == 3 ? 'Aceptada' : cotizacion.approved == 2 ? 'Rechazada' : 'Sin confirmar'}}</div>
            <span class="status" [ngClass]="{'aceptada': cotizacion.approved == 3, 'rechazada': cotizacion.approved == 2}"></span>
        </div>
        </div>
        <div class="contianer-download">
            <div style="cursor: pointer;" (click)="DescargarPDF(cotizacion.url)"><img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/file-download.svg"/></div>
            <p>Descargar</p>
        </div>
    </div>

</div>