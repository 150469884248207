import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FirmaDigitalService {
  id = null

  constructor(private http: HttpClient) { 
    console.log('entro')
  }

  getById(id){
    return this.http.get(`${environment.config.apiUrl}profile/${id}`)
  }
}
