import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services';
import { Router } from '@angular/router';
import { PerfilService } from 'src/app/_services/perfil.service';
import { Tramite } from 'src/app/_models/tramite.model';
import { Perfil } from '../../_models/perfiles'
import { ComentariosService } from 'src/app/_services/comentarios.service';
import { VendorsService } from 'src/app/_services/vendors.service';

@Component({
  selector: 'app-main-dashboard',
  templateUrl: './main-dashboard.component.html',
  styleUrls: ['./main-dashboard.component.scss']
})
export class MainDashboardComponent implements OnInit {
  PERFIL_VENDOR = 2
  country = ''
  new_home = false
  sumar_procesos = ['expediente', 'confirmacion', 'comite']
  tipo_dashboard = ''
  importe = 0
  proceso_seleccionado = 0
  perfil = null
  id_usuario = null

  verCienteAllowed = [2,3,6,12,7,10]
  verCliente = false
  updateStatus = false
  updateStatusList = false
  precalificar = false
  precalificar_usuario = false
  ver_productos = true
  cotizar = true

  filtro = {
    hijos_director_comercial: [],
    hijos_gerente: [],
    hijos_comercial: [],
    hijos_vendor: [],
    hijos_vendedor: []
  }

  busqueda = {
    id_cliente: null, 
    tipo_cliente: null, 
    fecha_inicio: null, 
    fecha_final: null, 
    numero_pagina: 1, 
    tamano_pagina: 50,
    is_demo: false,
    country: ''
  }

  tramites_origen:any = {}
  tramites:any = {}
  tramites_solicitud: any = {}

  comments_unread = []
  show_notifications = false
  extended = true
  total_unread_comments = 0

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private perfilService: PerfilService,
    private comentariosServicios: ComentariosService,
    private vendorService: VendorsService,
  ) { }

  ngOnInit(): void {
    localStorage.removeItem('client_details')
    localStorage.removeItem('solicitud')
    localStorage.removeItem('update')
    localStorage.removeItem('actualizar_informacion')
    localStorage.removeItem('ver_comentarios')
    localStorage.removeItem('vendor')
    const { id, perfil, is_demo = false, id_master } = JSON.parse(localStorage.getItem('currentUser'))

    this.perfil = Number(perfil)
    this.new_home = true
    this.precalificar = [2,3,4,6,7,12].includes(Number(this.perfil))
    this.precalificar_usuario = [3019].includes(Number(id))

    this.ver_productos = ![13,14,15,16,17,18,19,20].includes(this.perfil)
    this.cotizar = ![13,14,15,16,17,18,19,20].includes(this.perfil)
    this.id_usuario = Number(id)
    this.tipo_dashboard = Perfil[this.perfil]
    this.verCliente = this.verCienteAllowed.includes( this.perfil )
    this.updateStatus = this.perfil === 4
    this.updateStatusList = this.perfil === 4
    this.busqueda.is_demo = is_demo
    // this.ObtenerSubperfil( this.perfil, this.id_usuario, 'hijos_director_comercial')
    this.comentariosServicios.ObtenerComentariosSinLeer('', this.id_usuario).then( value => {
      console.log(`Comentarios: `, value);
      this.comments_unread = value
      this.comments_unread.forEach(comment => this.total_unread_comments += Number(comment.unread_comments))
    })

    if ([2,7].includes(this.perfil)) {
      this.BuscarVendor( this.perfil == this.PERFIL_VENDOR ? id : id_master)
    }
  }

  async ObtenerSubperfil(perfil, id_usuario, path){

    this.tramites = null
    
    this.filtro[path] = []
    const usuarios = await this.perfilService.getUsuarios( perfil, id_usuario );
    this.filtro[path] = usuarios['children'][path]

    this.busqueda.id_cliente = id_usuario
    this.busqueda.tipo_cliente = perfil
    this.ObtenerTramites()
  }

  async ObtenerTramites(){
    this.importe = 0
    const tramites = await this.perfilService.getTramitesComite( this.busqueda )
    this.tramites_origen = tramites['cuentas'] || {solicitud: [], expediente: [],confirmacion: [],comite: [], cerradas: [], contrato:[]}
    this.tramites = {}
    this.tramites_origen.todas = []

    this.GuardarTramites()
    this.Filtrar('')
    this.ImporteTotal()
   // this.Solicitudes()
    
    
  }
  // Solicitudes(){
  //   const filterSolicitud = this.tramites.solicitud.filter(function(e){
  //     return e.dias_tramite.days < 90
  //   })    
  //   this.tramites.solicitud = filterSolicitud
  // }

  ImporteTotal(){
    this.importe = 0
    for( const proceso in this.tramites_origen){
      this.tramites[proceso].forEach( (item:Tramite) => this.importe += this.sumar_procesos.includes(proceso) ? Number(item.precio_con_iva) : 0)
    }
  }

  GuardarTramites(){
    for( const proceso in this.tramites_origen){
      this.tramites[proceso] = this.tramites_origen[proceso].map( i=> i)
    }
  }

  Filtrar( word ) {
    for (const proceso in this.tramites_origen) {
     
      this.tramites[proceso] = this.tramites_origen[proceso].filter( (item:Tramite) => 
        (item.folio.toString().includes(word) || 
        item.nombre_cliente.toString().toLocaleLowerCase().includes(word.toLocaleLowerCase()))
      )

    }
   
  }

  CambiarStatus() {
    this.updateStatusList = !this.updateStatusList
  }

  MostrarSolicitudes() {
    this.router.navigate(['/dashboard/solicitudes']);
  }

  async ChangeCountry( country ) {
    console.log(`Cambiar país: ${country}`);
    this.busqueda.country = country
    await this.ObtenerTramites()
  }

  async BuscarVendor(id) {
    console.log(`Vendor: ${id}`);
    const {data} = await this.vendorService.ObtenerVendorPorId(id).toPromise()
    const {country, name, user_id} = data
    localStorage.setItem('vendor', JSON.stringify({country, name, id: user_id}))
    console.log('Get vendor: ', data)
  }
  toggleNotifiocations() { this.show_notifications = !this.show_notifications }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
