import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-go-bak',
  templateUrl: './go-bak.component.html',
  styleUrls: ['./go-bak.component.scss']
})
export class GoBakComponent implements OnInit {

  constructor(
    private location: Location
  ) { }

  ngOnInit(): void {
  }

  Regresar() {
    this.location.back()
  }

}
