<mat-sidenav-container class="menu" [hasBackdrop]="true">

    <mat-sidenav-content >
      <app-sidenav-v2
        [shortMenu]="true"
      >
        <div class="main">
            <mat-progress-spinner
                    *ngIf="!anexos.length"
                    mode="indeterminate"
                    diameter="30"
                ></mat-progress-spinner>
            <div *ngIf="anexos.length" class="table-cartera">
                <table class="table-cartera-resumen" [cellSpacing]="0" [cellPadding]="0">
                    <thead>
                        <tr>
                            <th></th>
                            <th *ngFor="let periodo of resumen">{{periodo.fecha | date:'MMM yy'}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Renta mensual</td>
                            <td *ngFor="let periodo of resumen">{{periodo.pago | currency:'MXN'}}</td>
                        </tr>
                        <tr>
                            <td>Capital</td>
                            <td *ngFor="let periodo of resumen">{{periodo.capital | currency:'MXN'}}</td>
                        </tr>
                        <tr>
                            <td>Interés</td>
                            <td *ngFor="let periodo of resumen">{{periodo.interes | currency:'MXN'}}</td>
                        </tr>
                        <tr>
                            <td>Insoluto</td>
                            <td *ngFor="let periodo of resumen">{{periodo.insoluto | currency:'MXN'}}</td>
                        </tr>
                        <tr>
                            <td>Residual</td>
                            <td *ngFor="let periodo of resumen">{{periodo.residual | currency:'MXN'}}</td>
                        </tr>
                    </tbody>

                </table>
            </div>
            <div *ngIf="anexos.length" class="table-cartera">
                <table class="table-cartera" [cellSpacing]="0" [cellPadding]="0">
                    <thead>
                        <tr>
                            <th style="max-width: 15px;">N.</th>
                            <th style="min-width: 200px!important;">Folio</th>
                            <th style="min-width: 100px!important;">Periodo</th>
                            <th style="min-width: 200px!important;">Fecha Contrato Marco</th>
                            <th style="min-width: 400px!important;">Cliente</th>
                            <th style="min-width: 200px!important;">Monto a Financiar</th>
                            <th style="min-width: 10px!important;">Tasa interés</th>
                            <th style="min-width: 100px!important;">Valor Residual</th>
                            <th style="min-width: 200px!important;">Renta sin IVA</th>
                            <th style="min-width: 10px!important;">Plazo</th>
                            <th style="min-width: 200px!important;">Fecha 1er Renta</th>
                            <th style="min-width: 200px!important;">Fecha Residual</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let anexo of anexos; let i = index" (click)="MostrarDetalle(anexo)">
                            <td>{{ i +1 }}</td>
                            <td style="text-align: left;">{{anexo.folio}}</td>
                            <td style="text-align: left;">{{anexo.periodo | date:'MMMM yy'}}</td>
                            <td style="text-align: center;">{{anexo.fechaContratoMarco | date:'dd/MM/yyyy'}}</td>
                            <td style="text-align: left;">{{anexo.cliente}}</td>
                            <td style="text-align: right;">{{anexo.montoFinanciar | currency:'MXN'}}</td>
                            <td style="text-align: center;">{{(anexo.tasa || 0.0) * 100}}%</td>
                            <td style="text-align: right;">{{anexo.valorResidual | currency:'MXN'}}</td>
                            <td style="text-align: right;">{{anexo.rentaSinIva | currency:'MXN'}}</td>
                            <td style="text-align: center;">{{anexo.plazo}}</td>
                            <td style="text-align: center;">{{anexo.fechaPrimerRenta | date:'dd/MM/yyyy'}}</td>
                            <td style="text-align: center;">{{anexo.fechaResidual | date:'dd/MM/yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        <app-socials-footer style="width: 100%;"></app-socials-footer>
        </div>
    </app-sidenav-v2>

    </mat-sidenav-content>
</mat-sidenav-container>