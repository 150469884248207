import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-referidos-message',
  templateUrl: './modal-referidos-message.component.html',
  styleUrls: ['./modal-referidos-message.component.scss']
})
export class ModalReferidosMessageComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ModalReferidosMessageComponent>,
              @Inject(MAT_DIALOG_DATA) public data
  ) { }
  estado:boolean=true;
  folio=null
  texto='';
  ngOnInit(): void {
    console.log("DATA",this.data);
    this.folio=this.data.folio;
    this.estado=this.data.estado;
    this.texto=this.data.texto;

    setTimeout(() => {
      this.dialogRef.close();
    }, 4000);
  }

}
