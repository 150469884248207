<div class="mat-drawer-container" style="border-radius: 18px">
  <!-- O usa simplemente "mat-drawer" si es solo para el fondo -->
  <div [@flipCard]="cardState" class="flip-card-inner">
    <div class="card" [ngClass]="{ column: column }" *ngIf="tramite">
      <div class="card-header">
        <div class="container-folio">
          <p># {{ tramite.folio }}{{valorReferido}}</p>
          <span *ngIf="tramite.id_proceso != 1">{{
            formatearFechaNueva(tramite.fecha_inicio_tramite)
          }}</span>
        </div>
        <div class="container-horas" *ngIf="tramite.id_proceso != 1">
          <div
            *ngIf="tramite.id_proceso != 1 || tramite.id_proceso < 5"
            class="tramite-horas"
            [ngClass]="{
              success: tramite[procesos[tramite.id_proceso - 1]] <= 4 && !tramite.id_resolucion,
              warning: tramite[procesos[tramite.id_proceso - 1]] >= 4 && !tramite.id_resolucion
            }"
          >
            <span>{{
              convertirHorasPorTramite(
                tramite.id_proceso,
                tramite.id_resolucion
              )
            }}</span>
          </div>
        </div>

        <div *ngIf="tramite.id_proceso == 1"></div>
        <div class="proceso">
          <span
            *ngIf="!resolucion && tramite.id_proceso < 5"
            class="status"
            [ngClass]="{
              enproceso: tramite.status == 1,
              actualizar: tramite.status == 2,
              completado: tramite.status == 3
            }"
            >{{ STATUS[tramite.status] }}</span
          >
          <span
            *ngIf="
              (resolucion && tramite.id_resolucion != null) ||
              tramite.id_proceso == 4
            "
            class="status"
            [ngClass]="{
              enproceso: tramite.id_resolucion == 3,
              actualizar:
                tramite.id_resolucion == 4 || tramite.id_resolucion == 99,
              completado:
                tramite.id_resolucion == 1 || tramite.id_resolucion == 2
            }"
            >{{ resoluciones[tramite.id_resolucion] }}</span
          >
          <span *ngIf="tramite.id_proceso == 5 && tramite.status != 2" class="status">{{
            statusCoFi[tramite.id_proceso]
          }}</span>
          <span *ngIf="tramite.id_proceso == 5 && tramite.status == 2" class="status actualizar" (click)="MostrarPopup(tramite.documentosRechazados)" style="cursor: pointer;">{{tramite.documentosRechazados && tramite.documentosRechazados.length ? 'Rechazado' : 'Cancelado'}}</span>
          <span
            *ngIf="tramite.id_proceso == 7 && tramite.status == 0"
            class="status"
            [ngClass]="{
              enproceso: tramite.id_proceso == 7 && tramite.status == 0
            }"
            >Enviado</span
          >
          <span
            *ngIf="tramite.id_proceso == 6 && tramite.status == 3"
            class="status"
            [ngClass]="{
              enproceso: tramite.id_proceso == 6 && tramite.status == 3
            }"
            >Recibido</span
          >
          <span
            *ngIf="tramite.id_proceso == 8"
            class="status"
            [ngClass]="{
              enproceso: tramite.status == 1,
              actualizar: tramite.status == 2,
              completado: tramite.status == 3
            }"
            >{{ tramite.status == 1 ? "Activado" : "Pendiente" }}</span
          >
        </div>
      </div>

      <div class="card-body">
        <div class="container-select-client" >
          <div *ngIf="tramite.id_proceso == 2 && !tramite.celula && puede_semana_compromiso" >
            <div class="seleccionar_celula" (click)="seleccionarCelula()">
              <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/celula-sin-asignar.svg"/>
            </div>
          </div>
          <div>
            <div class="celula_seleccionada" *ngIf="tramite.celula">{{tramite.celula}}</div>
          </div>
          <div
            class="button_client"
            [ngClass]="{ disabled: verCliente == false }"
            (click)="tramite.id_proceso <= 9 ? Ver('cliente') : null"
          >
            {{ tramite.nombre_cliente }}
          </div>
          <div *ngIf="tramite.id_proceso != 1">
            <img
              height="20px"
              [src]="
                tramite.tipo_persona == 1
                  ? 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/persona_fisica.png'
                  : 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/persona_moral.png'
              "
              alt="persona"
            />
          </div>
          <div *ngIf="tramite.id_proceso ==1"></div>
        </div>
        <div class="info-comercial">
          <p>
            {{ tramite.nombre_vendor ? tramite.nombre_vendor : "Lease For U" }}
          </p>
          <p>{{ tramite.nombre_comercial }}</p>
        </div>
        <div *ngIf="tramite.fecha_fin_compromiso && tramite.id_proceso != 9">
          <p class="fecha_compr" [ngClass]="getClassForFechaCompromiso(tramite.fecha_fin_compromiso, this.tramite.fecha_fin_compromiso_created)">
            Fecha compromiso:
            <span class="fecha_compr">{{formatearFechaNueva(tramite.fecha_fin_compromiso)}}</span>
          </p>
        </div>
        <div *ngIf="!tramite.fecha_fin_compromiso && tramite.id_proceso != 1 && tramite.id_proceso != 9 && puede_semana_compromiso">
          <select class="select-fecha-compr" (change)="seleccionarSemanaCompromiso($event)" [ngModel]="_semana_seleccionada">
            <option value="">Sem compromiso</option>
            <option value="1">Semana 1</option>
            <option value="2">Semana 2</option>
            <option value="3">Semana 3</option>
            <option value="4">Semana 4</option>
          </select>
        </div>

        <div
          class="info-producto"
          style="margin-top: 10px"
          [@openCard]="sideCardState ? 'open' : 'close'"
        >
          <p>
            {{ tramite.activo_nombre }}
          </p>
          <p>
            {{ tramite.precio_con_iva | currency : "$" }}
          </p>
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-around;
            "
            *ngIf="tramite.id_proceso >= 5"
          >
            <div
              style="
                display: flex;
                flex-direction: column;
                align-items: flex-start;
              "
            >
              <b>Anexo: </b>
              <p *ngIf="tramite.id_proceso >= 5">{{ tramite.anexo }}</p>
            </div>
            <div
              style="
                display: flex;
                flex-direction: column;
                align-items: flex-start;
              "
            >
              <b>Creación anexo: </b>
              <p *ngIf="tramite.id_proceso >= 5">
                {{ tramite.anexo_fecha_creacion | date : "dd/MM/yyy" }}
              </p>
            </div>
          </div>
          <div
            *ngIf="tramite.id_proceso == 5"
            style="display: flex; justify-content: center; margin: 5px 0px"
          >
            <button (click)="enviarContrato(tramite.folio)" class="btn-enviar">
              <img
                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/enviar-new.svg"
              />Enviar
            </button>
          </div>
          <!-- <div
            *ngIf="tramite.id_proceso == 7 && tramite.status == 0"
            style="display: flex; justify-content: center; margin: 5px 0px"
          >
            <button (click)="recibirContrato(tramite.folio)" class="btn-enviar">
              <img
                src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/enviar-new.svg"
              />Recibir
            </button>
          </div> -->
        </div>
      </div>

      <div class="card-footer">
        <div class="bottom-button">
          <div (click)="toggleFlipCard()" class="options-button">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.33333 7V7.00667M7 7V7.00667M9.66667 7V7.00667M13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7Z"
                stroke="#0277D9"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="toggle-info" (click)="onCardToggle()">
            <div class="column-info" *ngIf="tramite.id_proceso != 1">
              <p>
                {{ convertirHoras(tramite.horas_tramite_completo_exp_now) }}
              </p>
              <ng-container
                *ngIf="tramite.id_proceso != 2"
                class="tooltip-container"
              >
                <img
                  mat-raised-button
                  [src]="
                    'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/info_new.png'
                  "
                  class="tooltip-trigger"
                  [attr.title]="
                    infoTooltip(
                      tramite.id_proceso,
                      tramite.status,
                      tramite.id_resolucion,
                      tramite
                    )
                  "
                  data-toggle="tooltip"
                />
                <div class="tooltip">
                  {{
                    infoTooltip(
                      tramite.id_proceso,
                      tramite.status,
                      tramite.id_resolucion,
                      tramite
                    )
                  }}
                </div>
              </ng-container>
            </div>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              [ngClass]="{ rotate: true, rotate180: svgRotation }"
            >
              <path
                d="M4 6L8 10L12 6"
                stroke="#0277D9"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <div
            style="
              margin-top: 0;
              margin-left: 0;
              width: 100%;
              height: 100%;
              font-size: 12px;
              cursor: pointer;
              display: flex;
              align-items: center;
            "
            class="unread-comments"
            [ngClass]="{ unread: unread_comments }"
            (click)="Ver('cliente', true)"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 6.99967V7.00634M4.33333 6.99967V7.00634M9.66667 6.99967V7.00634M1 12.3331L1.86667 9.73306C1.11763 8.62525 0.846662 7.31334 1.10415 6.04126C1.36163 4.76919 2.13004 3.62351 3.26651 2.81724C4.40298 2.01098 5.83017 1.59899 7.28273 1.65789C8.73528 1.71678 10.1143 2.24255 11.1635 3.13743C12.2127 4.03232 12.8606 5.23542 12.9867 6.52305C13.1128 7.81068 12.7086 9.09521 11.8492 10.1378C10.9899 11.1804 9.73377 11.9101 8.31453 12.1913C6.89529 12.4725 5.40947 12.2859 4.13333 11.6664L1 12.3331Z"
                stroke="#0277D9"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="flip-card-back">
      <div class="container-buttons">
        <div
          class="button"
          [ngClass]="{ disabled: verSolicitud == false }"
          (click)="Ver('solicitud')"
        >
          <span>Ver solicitud</span>
        </div>
        <div
          class="button"
          [ngClass]="{ disabled: verCotizacion == false }"
          (click)="Ver('cotizacion')"
        >
          <span>Ver cotización</span>
        </div>
        <div
          class="button"
          [ngClass]="{ disabled: !verExpediente}"
          (click)="Ver('expediente')"
        >
          <span>Ver expediente</span>
        </div>
        <div
          class="button"
          [ngClass]="{ disabled: !verActivos}"
          (click)="Ver('activo')"
        >
          <span>Ver Activos</span>
        </div>

        <div
          class="button"
          *ngIf="puede_reasignar"
          [ngClass]="{ more: [6, 8, 12].includes(perfil) }"
          (click)="reasignar(tramite.folio)"
        >
          Reasignar
        </div>
        <div
          class="button"
          *ngIf="type == 0"
          (click)="cancelar($event, tramite.folio)"
          [ngClass]="{ more: [6, 8, 12].includes(perfil) }"
          style="color: red"
        >
          Cancelar trámite
        </div>
        <div
          class="button"
          *ngIf="type == 7"
          [ngClass]="{ 'btn-pop-left': perfil != 4, 'btn-pop': perfil == 4 }"
          (click)="carta($event, tramite)"
        >
          Descargar carta
        </div>
      </div>
      <div class="footer-return">
        <div class="button" (click)="toggleFlipCard()">
          <svg
            width="13"
            height="11"
            viewBox="0 0 13 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.00016 4.33464L1.3335 7.0013M1.3335 7.0013L4.00016 9.66797M1.3335 7.0013H8.66683C9.37407 7.0013 10.0524 6.72035 10.5524 6.22025C11.0525 5.72016 11.3335 5.04188 11.3335 4.33464C11.3335 3.62739 11.0525 2.94911 10.5524 2.44902C10.0524 1.94892 9.37407 1.66797 8.66683 1.66797H8.00016"
              stroke="#73787C"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Regresar
        </div>
      </div>
    </div>
  </div>
  <div class="mat-drawer-backdrop" (click)="toggleFlipCard()"></div>
</div>
