import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ComentariosService {

  constructor(
    private http: HttpClient
  ) { }

  ObtenerComentarios(folio, id_user) {
    return this.http.post<any>(`${environment.config.apiUrl}comments/get`, {folio, id_user}).toPromise();
  }

  ObtenerComentariosSinLeer(folio, id_user) {
    return this.http.post<any>(`${environment.config.apiUrl}comments/get_unread`, {folio, id_user}).toPromise();
  }

  CrearComentario(folio, id_user, comment) {
    return this.http.post<any>(`${environment.config.apiUrl}comments/create`, {folio, id_user, comment}).toPromise();
  }
}
