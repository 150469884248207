import { NgModule, LOCALE_ID }      from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule }    from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { DecimalPipe } from '@angular/common';
import { AppComponent }  from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es-MX';

import { AlertComponent } from './_components/alert/alert.component';
import { BtnActualizarInfoComponent } from './_components/btn-actualizar-info/btn-actualizar-info.component';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { MaterialModule } from './angular-material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { Angular7PincodeModule } from 'angular7-pincode';
import { CodeActivationComponent } from './pages/code-activation/code-activation.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { StatusClientComponent } from './pages/status-client/status-client.component';
import { ChartsModule } from 'ng2-charts';
import { CircularOptionsComponent } from './_components/circular-options/circular-options.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SocialsFooterComponent } from './_components/socials-footer/socials-footer.component';
import { DashboardComercialComponent } from './pages/dashboard-comercial/dashboard-comercial.component';
import { EstatusTramiteComponent } from './pages/comercial/estatus-tramite/estatus-tramite.component';
import { ChangeStatusComponent } from './_dialogs/change-status/change-status.component';
import { TramitesActivosComponent } from './_components/tramites-activos/tramites-activos.component';
import { DashboardComiteComponent } from './pages/dashboard-comite/dashboard-comite.component';
import { DashboardAdministradorComponent } from './pages/dashboard-administrador/dashboard-administrador.component';
import { CrearPerfilComponent } from './pages/crear-perfil/crear-perfil.component';
import { ParametrosSistemaComponent } from './pages/parametros-sistema/parametros-sistema.component';
import { MessageBoxComponent } from './_dialogs/message-box/message-box.component';
import { ReassignProcedureComponent } from './_dialogs/reassign-procedure/reassign-procedure.component';
import { SolicitudDGComponent } from './pages/solicitud-datos-generales/solicitud.component';
import { SolicitudComponent } from './pages/solicitud/solicitud.component';
import { SolicitudDGp1Component } from './pages/solicitud-datos-generales/formularios/parte1/solicitudDGp1.component';
import { SolicitudDGp2Component } from './pages/solicitud-datos-generales/formularios/parte2/solicitudDGp2.component';
import { SolicitudDGp3Component } from './pages/solicitud-datos-generales/formularios/parte3/solicitudDGp3.component';
import { EntrevistaComponent } from './pages/valuacion/entrevista/entrevista.component';
import { EstadoFormularioComponent } from './_components/estado-formulario/estado-formulario.component';
import { SolicitudRComponent } from './pages/solicitud-referencias/solicitudR.component';
import { SolicitudRp1Component } from './pages/solicitud-referencias/formularios/parte1/solicitudRp1.component';
import { SolicitudRp2Component } from './pages/solicitud-referencias/formularios/parte2/solicitudRp2.component';
import { SolicitudRp3Component } from './pages/solicitud-referencias/formularios/parte3/solicitudRp3.component';
import { SolicitudAvalComponent } from './pages/solicitud-aval/solicitudAval.component';
import { SolicitudAvalp1Component } from './pages/solicitud-aval/formularios/parte1/solicitudAvalp1.component';
import { SolicitudAvalp2Component } from './pages/solicitud-aval/formularios/parte2/solicitudAvalp2.component';
import { AnalisisComponent } from './pages/valuacion/analisis/analisis.component';
import { ComentariosComponent } from './pages/valuacion/comentarios/comentarios.component';
import { CaratulaComponent } from './pages/valuacion/caratula/caratula.component';
import { MinutaComponent } from './pages/valuacion/minuta/minuta.component';
import { CartasClienteComponent } from './pages/valuacion/cartas-cliente/cartas-cliente.component';

import { ActualizarAvalComponent } from './pages/actualizar-aval/actualizar-aval.component';

import { FormsModule } from '@angular/forms';
import { CargaDocumentosComponent } from './pages/carga-documentos/carga-documentos.component';
import { VerDocumentoComponent } from './pages/ver-documento/ver-documento.component';
import { RechazarDocumentoComponent } from './_dialogs/rechazar-documento/rechazar-documento.component';
import { InfoComponent } from './_components/info/info.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { VerCotizacionesComponent } from './pages/ver-cotizaciones/ver-cotizaciones.component';
import { CotizacionesComponent } from './_components/cotizaciones/cotizaciones.component';
import { DetalleCotizacionComponent } from './pages/detalle-cotizacion/detalle-cotizacion.component';

import { PdfViewerModule } from 'ng2-pdf-viewer';

import { DocumentoComponent } from './_components/documento/documento.component';
import { HistorialCotizacionesComponent } from './pages/historial-cotizaciones/historial-cotizaciones.component';
import { ListaCotizacionesComponent } from './_components/lista-cotizaciones/lista-cotizaciones.component';
import { VerTramitesComponent } from './pages/ver-tramites/ver-tramites.component';
import { ListaTramitesComponent } from './_components/lista-tramites/lista-tramites.component';
import { TramiteComponent } from './_components/tramite/tramite.component';
import { ConfirmarCotizacionComponent } from './pages/confirmar-cotizacion/confirmar-cotizacion.component';
import { CotizacionComponent } from './pages/confirmar-cotizacion/cotizacion/cotizacion.component';
import { SeleccionarPlazoComponent } from './pages/confirmar-cotizacion/seleccionar-plazo/seleccionar-plazo.component';
import { ValidacionIdentidadComponent } from './pages/valuacion/validacion-identidad/validacion-identidad.component';
import { ComercialConfirmarCotizacionComponent } from './pages/comercial-confirmar-cotizacion/comercial-confirmar-cotizacion.component';
import { CotizacionPreconfirmadaComponent } from './pages/comercial-confirmar-cotizacion/cotizacion-preconfirmada/cotizacion-preconfirmada.component';
import { CrearCotizacionComponent } from './pages/crear-cotizacion/crear-cotizacion.component';
import { EquipoComponent } from './pages/crear-cotizacion/equipo/equipo.component';
import { DatosClienteComponent } from './pages/crear-cotizacion/datos-cliente/datos-cliente.component';
import { InfoCotizacionComponent } from './pages/crear-cotizacion/info-cotizacion/info-cotizacion.component';
import { ResidualComponent } from './pages/crear-cotizacion/residual/residual.component';
import { VerCotizacionCreadaComponent } from './pages/crear-cotizacion/ver-cotizacion-creada/ver-cotizacion-creada.component';
import { FinProcesoMessageComponent } from './_dialogs/fin-proceso-message/fin-proceso-message.component';
import { ImageComponent } from './_components/image/image.component';
import { ActualizarInformacionComponent } from './pages/actualizar-informacion/actualizar-informacion.component';
import { ActualizarCapacidadPagoComponent } from './pages/actualizar-capacidad-pago/actualizar-capacidad-pago.component';
import { ConsultaBuroComponent } from './pages/valuacion/consulta-buro/consulta-buro.component';
import { AnalisisCrediticioComponent } from './pages/valuacion/consulta-buro/steps/analisis-crediticio/analisis-crediticio.component';
import { FormDomicilioComponent } from './pages/valuacion/consulta-buro/steps/form-domicilio/form-domicilio.component';
import { FormFisicaComponent } from './pages/valuacion/consulta-buro/steps/form-fisica/form-fisica.component';
import { FormMoralComponent } from './pages/valuacion/consulta-buro/steps/form-moral/form-moral.component';
import { MainDashboardComponent } from './pages/main-dashboard/main-dashboard.component';
import { FiltroComponent } from './pages/main-dashboard/filtro/filtro.component';
import { ImporteComponent } from './pages/main-dashboard/importe/importe.component';
import { PerfilComponent } from './pages/main-dashboard/filtro/perfil/perfil.component';
import { TramiteProcesoComponent } from './pages/main-dashboard/tramite-proceso/tramite-proceso.component';
import { TramiteCardComponent } from './pages/main-dashboard/tramite-card/tramite-card.component';
import { ConsultaSolicitudComponent } from './pages/consulta-solicitud/consulta-solicitud.component';
import { SidenavComponent } from './_components/sidenav/sidenav.component';
import { ReasignacionComponent } from './pages/reasignacion/reasignacion.component';
import { ReasignacionSelectComponent } from './pages/reasignacion-select/reasignacion-select.component';
import { CodeInputModule } from 'angular-code-input';
import { UpdateStatusListComponent } from './pages/main-dashboard/update-status-list/update-status-list.component';
import { ChangeStatusListComponent } from './_components/change-status-list/change-status-list.component';
import { CrudProductoComponent } from './pages/productos/crud-producto/crud-producto.component';
import { AdminComponent } from './pages/productos/admin/admin.component';
import { NuevoFlujoComponent } from './pages/crear-cotizacion/nuevo-flujo/nuevo-flujo.component';
import { NewHomeComponent } from './pages/main-dashboard/new-home/new-home.component';
import { MsiTableComponent } from './_components/msi-table/msi-table.component';
import { VendorsListComponent } from './pages/vendors/vendors-list/vendors-list.component';
import { VendorsEditComponent } from './pages/vendors/vendors-edit/vendors-edit.component';
import { FirmaDigitalComponent } from './pages/firma-digital/firma-digital.component';
import { PagenotfoundComponent } from './pages/firma-digital/pagenotfound/pagenotfound.component';
import { MejorarCotizacionComponent } from './pages/mejorar-cotizacion/mejorar-cotizacion.component';
import { PreAprobadoComponent } from './pages/mejorar-cotizacion/pre-aprobado/pre-aprobado.component';
registerLocaleData(localeEs, 'es');
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { ActualizarReferenciasComponent } from './pages/actualizar-referencias/actualizar-referencias.component';
import { CalculoTIRComponent } from './pages/calculo-tir/calculo-tir.component';
import { PaymentDetailsComponent } from './_components/payment-details/payment-details.component';
import { CentroMensajeriaComponent } from './_components/centro-mensajeria/centro-mensajeria.component';
import { CentroMensajeriaItemComponent } from './_components/centro-mensajeria-item/centro-mensajeria-item.component';
import { PromocionesComponent } from './pages/promociones/promociones.component';
import { CrearPromocionComponent } from './pages/promociones/crear-promocion/crear-promocion.component';
import { GenerateCsvComponent } from './_dialogs/generate-csv/generate-csv.component';
import { SelectorParticipantesComponent } from './_components/selector-participantes/selector-participantes.component';
import { ReporteBuroComponent } from './pages/reporte-buro/reporte-buro.component';
import { VendedoresListComponent } from './pages/vendors/vendedores/vendedores-list/vendedores-list.component';
import { VendedorEditComponent } from './pages/vendors/vendedores/vendedor-edit/vendedor-edit.component';
import { ListaEstadosComponent } from './_components/lista-estados/lista-estados.component';
import { CotizadorAutoComponent } from './pages/catalogos/cotizador-auto/cotizador-auto.component';
import { NipBuroComponent } from './pages/valuacion/consulta-buro/steps/nip-buro/nip-buro.component';
import { AsignarSeguroComponent } from './_components/asignar-seguro/asignar-seguro.component';
import { SelectInvoiceDateInitComponent } from './_components/select-invoice-date-init/select-invoice-date-init.component';
import { GoBakComponent } from './_components/go-bak/go-bak.component';
import { ResumenComponent } from './pages/valuacion_PM/resumen/resumen.component';
import { CaracterComponent } from './pages/valuacion_PM/caracter/caracter.component';
import { ValuacionPMComponent } from './pages/valuacion_PM/valuacion-pm.component';
import { CapacidadPagoComponent } from './pages/valuacion_PM/capacidad-pago/capacidad-pago.component';
import { ActivoComponent } from './pages/valuacion_PM/activo/activo.component';
import { AnexoComponent } from './pages/valuacion_PM/anexo/anexo.component';
import { MinutaPmComponent } from './pages/valuacion_PM/minuta-pm/minuta-pm.component';
import { CartasClientePmComponent } from './pages/valuacion_PM/cartas-cliente-pm/cartas-cliente-pm.component';
import { SolicitudesDashboardComponent } from './pages/main-dashboard/solicitudes-dashboard/solicitudes-dashboard.component';
import { SidenavV2Component } from './_components/sidenav-v2/sidenav-v2.component';
import { TablaPipelinesComponent } from './pages/tabla-pipelines/tabla-pipelines.component';
import { PipelinesTramitesTableComponent } from './_components/pipelines-tramites-table/pipelines-tramites-table.component';
import { PipelinesTramitesTableItemComponent } from './_components/pipelines-tramites-table-item/pipelines-tramites-table-item.component';
import { RoundedNumberPipe } from './_helpers/roundedNumber';
import { NegativeNumberDirective } from './_directives/negativeNumber';
import { FilterFieldComponent } from './_components/filter-field/filter-field.component';
import { AdvancedFiltersComponent } from './_components/advanced-filters/advanced-filters.component';
import { ColorNegativoDirective } from './_directives/colorNegative';
import { CustomDatepipeModule } from './pages/solicitud/_custom/custom-datepipe.module';
import { RegisterClientComponent } from './pages/nueva-solicitud/register-client/register-client.component';
import { EmailValidationComponent } from './pages/nueva-solicitud/register-client/email-validation/email-validation.component';
import { InputValidationComponent } from './_components/nueva-solicitud/input-validation/input-validation.component';
import { NavbarTitleComponent } from './_components/nueva-solicitud/navbar-title/navbar-title.component';
import { ModuleInstructionsComponent } from './_components/nueva-solicitud/module-instructions/module-instructions.component';
import { CountdownComponent } from './_components/nueva-solicitud/countdown/countdown.component';
import { ModalContratoComponent } from './_dialogs/modal-contrato/modal-contrato.component';
import { NuevaSolicitudComponent } from './pages/nueva-solicitud/nueva-solicitud.component';
import { CargaIneComponent } from './pages/nueva-solicitud/carga-ine/carga-ine.component';
import { PreCalificarComponent } from './pages/nueva-solicitud/register-client/pre-calificar/pre-calificar.component';
import { DomicilioComponent } from './pages/nueva-solicitud/informacion/domicilio/domicilio.component'; 
import { DatosPersonalesComponent } from './pages/nueva-solicitud/informacion/datos-personales/datos-personales.component'; 
import { ModalConfirmComponent } from './_dialogs/modal-confirm/modal-confirm.component';
import { ModalPoliticasComponent } from './_dialogs/modal-politicas/modal-politicas.component';
import { AvalNuevaSolicitudComponent } from './pages/nueva-solicitud/carga-documentacion/aval-nueva-solicitud/aval-nueva-solicitud.component';
import { ConyugueNuevaSolicitudComponent } from './pages/nueva-solicitud/carga-documentacion/conyugue-nueva-solicitud/conyugue-nueva-solicitud.component';
import { ReferenciasNuevaSolicitudComponent } from './pages/nueva-solicitud/carga-documentacion/referencias-nueva-solicitud/referencias-nueva-solicitud.component';
import { CargaDocumentacionComponent } from './pages/nueva-solicitud/carga-documentacion/carga-documentacion.component';
import { DocumentacionPersonalComponent } from './pages/nueva-solicitud/carga-documentacion/documentacion-personal/documentacion-personal.component';
import { DocumentsNewComponent } from './_components/nueva-solicitud/documents-new/documents-new.component';
import { StepperNuevaSolicitudComponent } from './_components/nueva-solicitud/stepper-nueva-solicitud/stepper-nueva-solicitud.component';
import { SelectValidationComponent } from './_components/nueva-solicitud/select-validation/select-validation.component';
import { RadioValidationComponent } from './_components/nueva-solicitud/radio-validation/radio-validation.component';
import { DocumentsEstadoCuentaNewComponent } from './_components/nueva-solicitud/documents-estado-cuenta-new/documents-estado-cuenta-new.component';
import { DocumentsAvalNewComponent } from './_components/nueva-solicitud/documents-aval-new/documents-aval-new.component';
import { NewModalMessageComponent } from './_dialogs/new-modal-message/new-modal-message.component';
import { SolicitudCompletadaComponent } from './pages/nueva-solicitud/solicitud-completada/solicitud-completada.component';
import { SelectorFechasComponent } from './_components/selector-fechas/selector-fechas.component';
import { ValuacionComponent } from './pages/valuacion/valuacion.component';
import { AdminActivosComponent } from './pages/activos/admin-activos/admin-activos.component';
import { CrudActivosComponent } from './pages/activos/crud-activos/crud-activos.component';
import { ModalCrearActivoComponent } from './_dialogs/modal-crear-activo/modal-crear-activo.component';
import { IndicarDireccionComponent } from './_dialogs/indicar-direccion/indicar-direccion.component';
import { VistasPreviasComponent } from './pages/vistas-previas/vistas-previas.component';
import { CardVistaPreviaComponent } from './_components/card-vista-previa/card-vista-previa.component';
import { ModalVistaPreviaComponent } from './_dialogs/modal-vista-previa/modal-vista-previa.component';
import { SelectColoniasComponent } from './_components/select-colonias/select-colonias.component';
import { PrecalificarContactoComponent } from './_dialogs/precalificar-contacto/precalificar-contacto.component';
import { EditarContactoComponent } from './_dialogs/editar-contacto/editar-contacto.component';
import { DocumentosContratoRechazadosComponent } from './_dialogs/documentos-contrato-rechazados/documentos-contrato-rechazados.component';
import { CarteraComponent } from './pages/cartera/cartera.component';
import { DialogDetaleCarteraAnexoComponent } from './_dialogs/dialog-detale-cartera-anexo/dialog-detale-cartera-anexo.component';
import { ReferidosComponent } from './pages/referidos/referidos.component';
import { ModalCotizacionReferidosComponent } from './_dialogs/modal-cotizacion-referidos/modal-cotizacion-referidos.component';
import { ModalReferidosRechazoComponent } from './_dialogs/modal-referidos-rechazo/modal-referidos-rechazo.component';
import { ModalReferidosMessageComponent } from './_dialogs/modal-referidos-message/modal-referidos-message.component';
import { DocumentosVendorComponent } from './pages/vendors/documentos-vendor/documentos-vendor.component';
import { SelectorDocumentosVendorComponent } from './_components/selector-documentos-vendor/selector-documentos-vendor.component';


export function playerFactory(){
    return player
}

const maskConfigFunction: () => Partial<IConfig> = () => {
    return {
      validation: false,
    };
  };
@NgModule({
    imports: [
        LottieModule.forRoot({player: playerFactory }),
        NgxMaskModule.forRoot(maskConfigFunction),
        BrowserAnimationsModule,
        FormsModule,
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        MaterialModule,
        Angular7PincodeModule,
        ChartsModule,
        PdfViewerModule,
        CodeInputModule,

    ],
    declarations: [
        AppComponent,
        AlertComponent,
        BtnActualizarInfoComponent,
        HomeComponent,
        LoginComponent,
        RegisterComponent,
        ChangePasswordComponent,
        CodeActivationComponent,
        ResetPasswordComponent,
        StatusClientComponent,
        CircularOptionsComponent,
        DashboardComponent,
        SocialsFooterComponent,
        DashboardComercialComponent,
        EstatusTramiteComponent,
        ChangeStatusComponent,
        TramitesActivosComponent,
        DashboardComiteComponent,
        DashboardAdministradorComponent,
        CrearPerfilComponent,
        ParametrosSistemaComponent,
        MessageBoxComponent,
        ReassignProcedureComponent,
        SolicitudDGComponent,
        SolicitudComponent,
        SolicitudDGp1Component,
        SolicitudDGp2Component,
        SolicitudDGp3Component,
        SolicitudRComponent,
        SolicitudRp1Component,
        SolicitudRp2Component,
        SolicitudRp3Component,
        SolicitudAvalComponent,
        SolicitudAvalp1Component,
        SolicitudAvalp2Component,
        EntrevistaComponent,
        EstadoFormularioComponent,
        AnalisisComponent,
        ComentariosComponent,
        CaratulaComponent,
        MinutaComponent,
        CartasClienteComponent,
        ActualizarAvalComponent,
        CargaDocumentosComponent,
        VerDocumentoComponent,
        RechazarDocumentoComponent,
        InfoComponent,
        VerCotizacionesComponent,
        CotizacionesComponent,
        DetalleCotizacionComponent,
        DocumentoComponent,
        HistorialCotizacionesComponent,
        ListaCotizacionesComponent,
        VerTramitesComponent,
        ListaTramitesComponent,
        TramiteComponent,
        ConfirmarCotizacionComponent,
        CotizacionComponent,
        SeleccionarPlazoComponent,
        ValidacionIdentidadComponent,
        ComercialConfirmarCotizacionComponent,
        CotizacionPreconfirmadaComponent,
        CrearCotizacionComponent,
        EquipoComponent,
        InfoCotizacionComponent,
        ResidualComponent,
        VerCotizacionCreadaComponent,
        FinProcesoMessageComponent,
        ImageComponent,
        ActualizarInformacionComponent,
        ActualizarCapacidadPagoComponent,
        DatosClienteComponent,
        ConsultaBuroComponent,
        AnalisisCrediticioComponent,
        FormDomicilioComponent,
        FormFisicaComponent,
        FormMoralComponent,
        ActualizarCapacidadPagoComponent,
        MainDashboardComponent,
        FiltroComponent,
        ImporteComponent,
        PerfilComponent,
        TramiteProcesoComponent,
        TramiteCardComponent,
        ConsultaSolicitudComponent,
        SidenavComponent,
        ReasignacionComponent,
        ReasignacionSelectComponent,
        UpdateStatusListComponent,
        ChangeStatusListComponent,
        CrudProductoComponent,
        AdminComponent,
        NuevoFlujoComponent,
        NewHomeComponent,
        MsiTableComponent,
        VendorsListComponent,
        VendorsEditComponent,
        FirmaDigitalComponent,
        PagenotfoundComponent,
        MejorarCotizacionComponent,
        PreAprobadoComponent,
        ActualizarReferenciasComponent,
        CalculoTIRComponent,
        PaymentDetailsComponent,
        CentroMensajeriaComponent,
        CentroMensajeriaItemComponent,
        PromocionesComponent,
        CrearPromocionComponent,
        GenerateCsvComponent,
        SelectorParticipantesComponent,
        ReporteBuroComponent,
        VendedoresListComponent,
        VendedorEditComponent,
        ListaEstadosComponent,
        CotizadorAutoComponent,
        NipBuroComponent,
        AsignarSeguroComponent,
        SelectInvoiceDateInitComponent,
        GoBakComponent,
        ResumenComponent,
        CaracterComponent,
        ValuacionPMComponent,
        CapacidadPagoComponent,
        ActivoComponent,
        AnexoComponent,
        MinutaPmComponent,
        CartasClientePmComponent,
        SolicitudesDashboardComponent,
        SidenavV2Component,
        TablaPipelinesComponent,
        PipelinesTramitesTableComponent,
        PipelinesTramitesTableItemComponent,
        RoundedNumberPipe,
        NegativeNumberDirective,
        FilterFieldComponent,
        AdvancedFiltersComponent,
        ColorNegativoDirective,
        CustomDatepipeModule,
        RegisterClientComponent,
        EmailValidationComponent,
        InputValidationComponent,
        NavbarTitleComponent,
        ModuleInstructionsComponent,
        CountdownComponent,
        ModalContratoComponent,
        NuevaSolicitudComponent,
        CargaIneComponent,
        PreCalificarComponent,
        DomicilioComponent,
        DatosPersonalesComponent,
        ModalConfirmComponent,
        ModalPoliticasComponent,
        AvalNuevaSolicitudComponent,
        ConyugueNuevaSolicitudComponent,
        ReferenciasNuevaSolicitudComponent,
        CargaDocumentacionComponent,
        DocumentacionPersonalComponent,
        DocumentsNewComponent,
        StepperNuevaSolicitudComponent,
        SelectValidationComponent,
        RadioValidationComponent,
        DocumentsEstadoCuentaNewComponent,
        DocumentsAvalNewComponent,
        NewModalMessageComponent,
        SelectorFechasComponent,
        ValuacionComponent,
        SolicitudCompletadaComponent,
        SelectorFechasComponent,
        SelectorFechasComponent,
        AdminActivosComponent,
        CrudActivosComponent,
        ModalCrearActivoComponent,
        IndicarDireccionComponent,
        VistasPreviasComponent,
        CardVistaPreviaComponent,
        ModalVistaPreviaComponent,
        SelectColoniasComponent,
        PrecalificarContactoComponent,
        EditarContactoComponent,
        DocumentosContratoRechazadosComponent,
        CarteraComponent,
        DialogDetaleCarteraAnexoComponent,
        ReferidosComponent,
        ModalCotizacionReferidosComponent,
        ModalReferidosRechazoComponent,
        ModalReferidosMessageComponent,
        DocumentosVendorComponent,
        SelectorDocumentosVendorComponent
    ],
    entryComponents: [
        ChangeStatusComponent,
        MessageBoxComponent,
        SocialsFooterComponent,
        ComentariosComponent,
        RechazarDocumentoComponent,
        InfoComponent,
        FinProcesoMessageComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: 'es' },
        DatePipe,
        DecimalPipe
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
