<app-sidenav-v2
[shortMenu]="true"
(openNotifications)="openNotifications()"
[commentsUnreadLength]="comments_unread.length"
(buscar)="Filtrar($event)"
[placeholderSearch]="placeholderSearch"
>
        <div class="container">
            <div class="top">
                
                <div class="title">Productos</div>
                <div>
                    <mat-icon style="cursor: pointer;" [ngClass]="{'view-active': View.view_list}" (click)="ChangeView('view_list')">view_list</mat-icon>
                    <mat-icon style="cursor: pointer;" [ngClass]="{'view-active': View.view_module}" (click)="ChangeView('view_module')">view_module</mat-icon>
                </div>
            </div>
        
            <div class="box">
                <!-- <div *ngIf="perfil != 2" style="margin-bottom: 15px;">
                    Empresa: <select [(ngModel)]="empresa" (change)="ObtenerProductos()" style="border-radius: 12px; width: 100px; font-family: 'Poppins';">
                        <option value="">Todos</option>
                        <option value="MX">México</option>
                        <option value="PE">Perú</option>
                    </select>
                </div> -->
                <div *ngIf="perfil != 2" class="btns-banderas">
                      <button (click)="setEmpresa('MX')" style="border: none; background: none; cursor: pointer;" >
                        <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/bandera-mex.jpg" alt="México" style="width: 30px; height: 22px;" [ngClass]="{'active': empresa === 'MX'}" >
                      </button>
                      <button (click)="setEmpresa('PE')" style="border: none; background: none; cursor: pointer;" >
                        <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/bandera-peru.png" alt="Perú" style="width: 30px; height: 22px;" [ngClass]="{'active': empresa === 'PE'}" >
                      </button>
                </div>
                <div style="width: 100%; display: flex; justify-content: space-between;">
                    <div></div>
                    <button *ngIf="isVendor" id="btnNuevoProducto" (click)="Crear()">Nuevo Producto</button>
                </div>
                <div class="container-charging" *ngIf="loading && ProductosFiltro.length === 0 || loading">
                    <div class="text">
                        <p>Cargando productos...</p>
                    </div>
                    <div class="container-progress">
                        <img src="../../../../assets/images/letter-u.svg"/>
                        <!-- <mat-progress-bar mode="indeterminate"></mat-progress-bar> -->
                        <div class="progress">
                            <div class="progress-value"></div>
                          </div>
                    </div>
                  </div>
                <div class="table-products" *ngIf="View.view_list && !loading ">
                    <p *ngIf="!loading && ProductosFiltro.length == 0">No hay productos que mostrar</p>
                    <table class="table-products" [cellSpacing]="0" [cellPadding]="0" *ngIf="ProductosFiltro.length">
                        <thead>
                            <tr>
                                <th>N.</th>
                                <th>Nombre</th>
                                <th>Modelo</th>
                                <th style="max-width: 20px;">Año</th>
                                <th style="width: 200px;">Garantía</th>
                                <th style="max-width: 100px;">Precio</th>
                                <th style="max-width: 100px;">Precio Sugerido</th>
                                <th style="max-width: 20px;">Moneda</th>
                                <th>Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let Producto of ProductosFiltro; let i = index">
                                <td>{{i +1}}</td>
                                <td>{{Producto.name}}</td>
                                <td>{{Producto.model}}</td>
                                <td>{{Producto.year}}</td>
                                <td>{{Producto.warranty}}</td>
                                <td style="text-align: right; max-width: 100px;">{{Producto.customer_price | currency:'MXN'}}</td>
                                <td style="text-align: right; max-width: 100px;">{{Producto.suggested_customer_price | currency:'MXN'}}</td>
                                <td style="text-align: center;">{{Producto.currency}}</td>
                                <td id="options">
                                    <mat-icon matTooltip="Cotizar" (click)="Cotizar(Producto)">request_quote</mat-icon>
                                    <mat-icon *ngIf="!isVendor" matTooltip="Ver" (click)="Editar(Producto)">visibility</mat-icon>
                                    <mat-icon *ngIf="isVendor" matTooltip="Editar" (click)="Editar(Producto)">edit</mat-icon>
                                    <mat-icon *ngIf="isVendor" matTooltip="Copiar" (click)="Copiar(Producto)">file_copy</mat-icon>
                                    <mat-icon *ngIf="isVendor" matTooltip="Eliminar" (click)="Eliminar(Producto)" style="color: red!important;">delete</mat-icon>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <cdk-virtual-scroll-viewport itemSize="70" class="virtual-scroll-viewport">
                    <div class="card-products" *ngIf="View.view_module && !loading" style="min-height: 60vh;">
                        <p *ngIf="!loading && ProductosFiltro.length == 0">No hay productos que mostrar</p>
                        
                            <div class="card-product" *cdkVirtualFor="let Producto of ProductosFiltro">
                                <div class="container-img">
                                    <div style="display: flex; justify-content: center;">
                                    <img [src]="Producto.url_image" alt="">
                                    </div>
                                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <div *ngIf="Producto.show_msi" class="container-msi">MSI</div>   
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="Cotizar(Producto)" class="btn-menu">
                                            <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/cotizar-producto.svg">
                                            <span>Cotizar</span>
                                        </button>
                                        <button mat-menu-item *ngIf="!isVendor" (click)="Editar(Producto)"  class="btn-menu">
                                            <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/ver-producto.svg">
                                            <span>Ver</span>
                                        </button>
                                        <button mat-menu-item *ngIf="isVendor" (click)="Editar(Producto)"  class="btn-menu">
                                            <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/editar-producto.svg">
                                            <span>Editar</span>
                                        </button>
                                        <button mat-menu-item *ngIf="isVendor" (click)="Copiar(Producto)"  class="btn-menu">
                                            <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/duplicar-producto.svg">
                                            <span>Duplicar</span>
                                        </button>
                                        <button mat-menu-item *ngIf="isVendor" (click)="Eliminar(Producto)"  class="btn-menu">
                                            <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/eliminar-producto.svg">
                                            <span>Eliminar</span>
                                        </button>
                                    </mat-menu>   
                                                            
                                </div>
                                <div class="details-card">
                                    <div style="font-size: 17px; font-weight: 500;">{{Producto.name}}</div>
                                    <div style="font-size: 10px; font-weight: 300;">por {{Producto.nombres}}</div>
                                    <div>{{Producto.year}}</div>
                                    <div style="color:#0277D9">{{Producto.customer_price | currency:'MXN'}} {{Producto.currency}}</div>
                                </div>
                            </div>
                        
                    </div>
                </cdk-virtual-scroll-viewport>

            </div>
            <div *ngIf="comments_unread.length" class="notificaciones"[ngClass]="{'show-notifications': !show_notifications}">
                <!-- <h1>
                  Notificaciones ({{ total_unread_comments }})
                  <span [ngClass]="{ hide_notifications: !show_notifications }"
                    ><mat-icon (click)="toggleNotifiocations()"
                      >expand_less</mat-icon
                    ></span
                  >
                </h1> -->
                <div >
                  <div
                    *ngFor="let comment of comments_unread"
                    class="notificacion"
                    [ngClass]="{ show_notifications: show_notifications }"
                  >
                    <span>Folio: {{ comment.folio }}</span>
                    <p>Tienes {{ comment.unread_comments }} comentarios sin leer.</p>
                  </div>
                </div>
              </div>
            <app-socials-footer style="width: 100%; bottom: 0;"></app-socials-footer>
        </div>

</app-sidenav-v2>