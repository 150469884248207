<div class="container">
    <div class="row-detail">
        <span class="title-label">Seguro</span>
        <span class="title-label">Seguro anual con IVA</span>
    
        <select [(ngModel)]="seguro_financiado" name="seguro" id="seguro" style="width: 150px !important;">
          <option [value]="0">CONTADO</option>
          <option [value]="1">FINANCIADO</option>
        </select>
        <input matInput
          mask="separator.2"
          thousandSeparator=","
          prefix="$ " type="text" class="client-info" [(ngModel)]="seguro_auto" maxlength="15">
    </div>
    <div class="row-detail">
        <button [disabled]="seguro_auto == 0" class="principal_button" (click)="Guardar()">Guardar</button>
    </div>
</div>