import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[appColorNegativo]'
})
export class ColorNegativoDirective implements OnChanges {
  @Input() valor: number;

  constructor(private el: ElementRef) {}

  ngOnChanges() {
    this.el.nativeElement.style.color = this.valor < 0 ? 'red' : '';
  }
}
