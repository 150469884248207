<div class="container">
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="186px" height="80px" viewBox="0 0 186 80" enable-background="new 0 0 186 80" xml:space="preserve">
<g>
	<g>
		<g>
			<path fill="#000000" d="M9.518,4.67v33.995H2.757V4.67H9.518z"/>
			<path fill="#000000" d="M19.55,32.762c1.016,0.983,2.476,1.475,4.381,1.475c1.364,0,2.539-0.34,3.522-1.023
				c0.984-0.682,1.587-1.404,1.81-2.166h5.952c-0.952,2.952-2.413,5.063-4.381,6.332c-1.969,1.271-4.349,1.905-7.142,1.905
				c-1.937,0-3.683-0.31-5.237-0.929c-1.556-0.619-2.874-1.5-3.952-2.643c-1.08-1.143-1.913-2.507-2.5-4.095
				c-0.588-1.587-0.881-3.333-0.881-5.237c0-1.84,0.302-3.555,0.905-5.143c0.603-1.586,1.459-2.959,2.57-4.118
				c1.111-1.158,2.437-2.071,3.976-2.737c1.54-0.667,3.245-1,5.119-1c2.095,0,3.92,0.404,5.476,1.214
				c1.555,0.81,2.833,1.897,3.833,3.261c0.999,1.365,1.721,2.922,2.166,4.667c0.444,1.745,0.603,3.571,0.476,5.476H17.884
				C17.979,30.189,18.533,31.777,19.55,32.762z M27.191,19.811c-0.81-0.889-2.04-1.334-3.689-1.334c-1.08,0-1.977,0.184-2.69,0.548
				c-0.714,0.365-1.285,0.817-1.714,1.356c-0.429,0.541-0.73,1.112-0.905,1.715c-0.175,0.604-0.277,1.143-0.309,1.619h10.998
				C28.564,22,28.001,20.699,27.191,19.811z"/>
			<path fill="#000000" d="M36.591,21.619c0.095-1.586,0.491-2.904,1.189-3.951c0.698-1.048,1.587-1.889,2.667-2.523
				c1.078-0.635,2.293-1.088,3.643-1.357c1.349-0.27,2.705-0.404,4.07-0.404c1.238,0,2.492,0.088,3.762,0.262
				c1.27,0.175,2.428,0.516,3.476,1.023s1.904,1.215,2.571,2.119s1,2.104,1,3.594v12.809c0,1.111,0.063,2.175,0.19,3.189
				c0.126,1.018,0.349,1.779,0.666,2.286H52.97c-0.128-0.381-0.231-0.769-0.31-1.167c-0.08-0.396-0.136-0.801-0.167-1.214
				c-1.079,1.112-2.35,1.89-3.809,2.333c-1.461,0.444-2.952,0.667-4.477,0.667c-1.174,0-2.27-0.144-3.285-0.429
				s-1.904-0.73-2.666-1.333s-1.357-1.364-1.785-2.286c-0.429-0.92-0.644-2.015-0.644-3.285c0-1.396,0.246-2.547,0.738-3.451
				s1.127-1.627,1.904-2.167c0.777-0.539,1.667-0.944,2.667-1.214s2.007-0.483,3.023-0.643c1.016-0.158,2.015-0.286,2.999-0.381
				c0.983-0.096,1.857-0.238,2.619-0.429s1.364-0.468,1.81-0.833c0.443-0.365,0.649-0.896,0.618-1.596
				c0-0.729-0.118-1.309-0.356-1.737s-0.556-0.763-0.952-1c-0.397-0.238-0.857-0.396-1.381-0.476
				c-0.524-0.08-1.088-0.119-1.69-0.119c-1.333,0-2.381,0.285-3.143,0.856s-1.207,1.523-1.333,2.856H36.591z M52.207,26.619
				c-0.285,0.254-0.643,0.452-1.071,0.595c-0.428,0.144-0.889,0.263-1.38,0.357c-0.493,0.095-1.009,0.175-1.548,0.237
				c-0.54,0.064-1.079,0.144-1.619,0.238c-0.508,0.096-1.008,0.223-1.5,0.381c-0.492,0.16-0.921,0.374-1.285,0.643
				c-0.365,0.271-0.659,0.612-0.881,1.024c-0.223,0.413-0.333,0.937-0.333,1.571c0,0.604,0.11,1.111,0.333,1.523
				c0.222,0.413,0.523,0.738,0.904,0.977s0.825,0.404,1.334,0.5c0.507,0.095,1.03,0.143,1.571,0.143
				c1.333,0,2.364-0.222,3.095-0.667c0.729-0.444,1.269-0.976,1.618-1.595s0.563-1.246,0.644-1.881
				c0.078-0.635,0.118-1.143,0.118-1.523V26.619z"/>
			<path fill="#000000" d="M67.233,32.547c0.301,0.523,0.69,0.952,1.166,1.285c0.477,0.334,1.024,0.58,1.643,0.738
				c0.619,0.159,1.263,0.238,1.929,0.238c0.477,0,0.977-0.055,1.5-0.167c0.523-0.11,1-0.286,1.429-0.523
				c0.428-0.238,0.785-0.556,1.071-0.952c0.285-0.396,0.428-0.896,0.428-1.5c0-1.016-0.675-1.777-2.023-2.285
				c-1.35-0.508-3.23-1.016-5.642-1.523c-0.984-0.223-1.945-0.484-2.881-0.786c-0.937-0.302-1.77-0.698-2.5-1.19
				s-1.317-1.111-1.762-1.857c-0.444-0.745-0.666-1.658-0.666-2.737c0-1.587,0.31-2.888,0.928-3.904
				c0.619-1.016,1.437-1.816,2.452-2.404c1.016-0.587,2.158-1,3.429-1.237c1.269-0.238,2.571-0.357,3.904-0.357
				s2.626,0.127,3.88,0.381s2.373,0.683,3.357,1.285c0.983,0.604,1.801,1.404,2.452,2.404c0.649,1,1.039,2.262,1.166,3.785h-6.428
				c-0.095-1.301-0.588-2.182-1.476-2.643c-0.89-0.459-1.937-0.689-3.143-0.689c-0.381,0-0.794,0.023-1.238,0.07
				c-0.444,0.049-0.85,0.151-1.214,0.311c-0.365,0.158-0.675,0.389-0.929,0.689c-0.254,0.303-0.381,0.707-0.381,1.215
				c0,0.604,0.222,1.095,0.667,1.476c0.443,0.381,1.023,0.69,1.737,0.929c0.715,0.238,1.531,0.452,2.452,0.643
				s1.857,0.397,2.81,0.619c0.983,0.223,1.943,0.492,2.881,0.81c0.936,0.317,1.769,0.737,2.499,1.262
				c0.73,0.523,1.317,1.175,1.762,1.952s0.667,1.738,0.667,2.881c0,1.618-0.326,2.976-0.977,4.07c-0.65,1.096-1.5,1.977-2.547,2.643
				c-1.048,0.666-2.246,1.135-3.595,1.404c-1.35,0.27-2.723,0.405-4.119,0.405c-1.428,0-2.825-0.144-4.189-0.429
				c-1.365-0.285-2.579-0.762-3.643-1.428c-1.063-0.667-1.937-1.548-2.619-2.643c-0.683-1.096-1.056-2.469-1.118-4.119h6.428
				C66.781,31.396,66.932,32.023,67.233,32.547z"/>
			<path fill="#000000" d="M91.815,32.762c1.016,0.983,2.476,1.475,4.381,1.475c1.364,0,2.539-0.34,3.522-1.023
				c0.984-0.682,1.587-1.404,1.81-2.166h5.952c-0.952,2.952-2.413,5.063-4.381,6.332c-1.969,1.271-4.349,1.905-7.142,1.905
				c-1.937,0-3.683-0.31-5.237-0.929c-1.556-0.619-2.874-1.5-3.952-2.643c-1.08-1.143-1.913-2.507-2.5-4.095
				c-0.588-1.587-0.881-3.333-0.881-5.237c0-1.84,0.302-3.555,0.905-5.143c0.603-1.586,1.459-2.959,2.57-4.118
				c1.111-1.158,2.437-2.071,3.976-2.737c1.54-0.667,3.245-1,5.119-1c2.095,0,3.92,0.404,5.476,1.214
				c1.555,0.81,2.833,1.897,3.833,3.261c0.999,1.365,1.721,2.922,2.166,4.667c0.444,1.745,0.603,3.571,0.476,5.476H90.149
				C90.244,30.189,90.799,31.777,91.815,32.762z M99.457,19.811c-0.81-0.889-2.04-1.334-3.689-1.334c-1.08,0-1.977,0.184-2.69,0.548
				c-0.714,0.365-1.285,0.817-1.714,1.356c-0.429,0.541-0.73,1.112-0.905,1.715c-0.175,0.604-0.277,1.143-0.309,1.619h10.998
				C100.83,22,100.267,20.699,99.457,19.811z"/>
		</g>
		<g>
			<path fill="#000000" d="M50.33,54.407v-4.523h4.048v-1.904c0-2.19,0.682-3.983,2.047-5.38c1.364-1.396,3.428-2.096,6.189-2.096
				c0.603,0,1.206,0.023,1.81,0.072c0.603,0.047,1.19,0.088,1.762,0.119v5.047c-0.794-0.096-1.619-0.144-2.476-0.144
				c-0.921,0-1.58,0.214-1.977,0.644c-0.397,0.428-0.595,1.15-0.595,2.166v1.476h4.666v4.523h-4.666V74.5h-6.761V54.407H50.33z"/>
			<path fill="#000000" d="M65.861,56.859c0.603-1.603,1.46-2.968,2.571-4.095c1.11-1.126,2.443-2,3.999-2.618
				c1.555-0.619,3.301-0.93,5.237-0.93s3.69,0.311,5.262,0.93c1.571,0.618,2.912,1.492,4.023,2.618
				c1.11,1.127,1.968,2.492,2.571,4.095c0.603,1.604,0.904,3.389,0.904,5.356s-0.302,3.745-0.904,5.333
				c-0.604,1.588-1.461,2.944-2.571,4.07c-1.111,1.127-2.452,1.992-4.023,2.596c-1.571,0.602-3.325,0.904-5.262,0.904
				s-3.683-0.303-5.237-0.904c-1.556-0.604-2.889-1.469-3.999-2.596c-1.111-1.126-1.969-2.482-2.571-4.07
				c-0.604-1.588-0.905-3.365-0.905-5.333S65.258,58.463,65.861,56.859z M72.003,65.096c0.19,0.938,0.516,1.779,0.977,2.524
				c0.46,0.746,1.071,1.341,1.833,1.785s1.714,0.667,2.856,0.667s2.103-0.223,2.881-0.667c0.777-0.444,1.396-1.039,1.857-1.785
				c0.459-0.745,0.785-1.587,0.976-2.524c0.19-0.936,0.286-1.896,0.286-2.88c0-0.983-0.096-1.952-0.286-2.904
				s-0.517-1.793-0.976-2.523c-0.461-0.729-1.08-1.325-1.857-1.786c-0.778-0.459-1.738-0.689-2.881-0.689s-2.095,0.23-2.856,0.689
				c-0.762,0.461-1.373,1.057-1.833,1.786c-0.461,0.73-0.786,1.571-0.977,2.523s-0.285,1.921-0.285,2.904
				C71.718,63.2,71.812,64.16,72.003,65.096z"/>
			<path fill="#000000" d="M98.523,49.884v4.571h0.096c0.316-0.762,0.745-1.469,1.285-2.119c0.539-0.65,1.158-1.206,1.857-1.666
				c0.697-0.46,1.443-0.817,2.237-1.072c0.793-0.253,1.619-0.381,2.476-0.381c0.444,0,0.937,0.08,1.477,0.238v6.285
				c-0.318-0.062-0.699-0.119-1.143-0.166c-0.445-0.048-0.874-0.072-1.286-0.072c-1.237,0-2.285,0.207-3.143,0.619
				c-0.856,0.413-1.547,0.977-2.071,1.69c-0.523,0.714-0.896,1.548-1.118,2.499c-0.223,0.953-0.334,1.984-0.334,3.096V74.5h-6.761
				V49.884H98.523z"/>
		</g>
	</g>
	<rect x="112.243" y="4.5" fill="#3DA4D8" width="71" height="71"/>
	<g>
		<g>
			<path fill="#FFFFFF" d="M160.834,58.25c-3.163,2.754-7.536,4.131-13.12,4.131c-5.663,0-10.046-1.367-13.15-4.102
				c-3.104-2.732-4.656-6.949-4.656-12.652V19.621h9.196v26.006c0,1.135,0.097,2.246,0.293,3.34
				c0.194,1.094,0.604,2.061,1.229,2.898c0.624,0.842,1.494,1.523,2.606,2.051c1.113,0.527,2.606,0.791,4.481,0.791
				c3.28,0,5.544-0.732,6.795-2.197c1.249-1.463,1.874-3.758,1.874-6.883V19.621h9.196v26.006
				C165.579,51.291,163.997,55.498,160.834,58.25z"/>
		</g>
	</g>
</g>
</svg>
<h2>Estatus a cambiar</h2>
<div class="options">
    <button (click)="CambiarStatus(1)">En Proceso</button>
    <button (click)="CambiarStatus(2)">Requiere Atención</button>
    <button (click)="CambiarStatus(3)">Completado</button>
</div>
<img *ngIf="loading" style="margin-top: 10px;" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
</div>