import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { SolicitudService } from "src/app/_services";
import { AnalisisPM, MESES } from "../AnalisisPM";
import { StepperNavigationService } from "src/app/_services/stepper-navigation.service";
import { AnalisisPMService } from "src/app/_services/analisis.service";

@Component({
  selector: "app-anexo",
  templateUrl: "./anexo.component.html",
  styleUrls: ["./anexo.component.scss"],
})
export class AnexoComponent implements OnInit {
  @Input() analisisPm: any;  
  @Output() analisisPmChange = new EventEmitter<any>();
  folio = null;
  AnalisisPM: any = {};
  loading = false;
  ngOnChanges(changes: SimpleChanges) {
    if (changes.analisisPm && changes.analisisPm.currentValue !== null && changes.analisisPm.currentValue !== undefined && changes.analisisPm.currentValue !== 0) {
      console.log("Nuevo valor de analisisPm ANEXO:", this.analisisPm);
      this.AnalisisPM = {...this.analisisPm}

      this.recalcularTotales()
      this.recalcularTotalesActivoFijo()
      this.recalcularTotalesCapital()
      this.recalcularTotalesPasivo()
      this.reCalcularUtilidades()
      this.calcularGastoFinacieroTotal()
      this.calcularAnalisisHorizontalCapitalAbsoluto();
      this.calcularAnalisisHorizontalCapitalPorcentual();

    }
  }
  MESES = MESES


  constructor(
    private solicitudService: SolicitudService,
    private stepperNavigationService: StepperNavigationService,
    private analisisPM: AnalisisPMService

    ) {}

   ngOnInit(): void {
    const { folio } = JSON.parse(localStorage.getItem("client_details"));
    this.AnalisisPM = JSON.parse(JSON.stringify(AnalisisPM));

    this.folio = folio;


    
  }
  redondearValor(valorOriginal): number {
    return Math.round(valorOriginal);
  }

  roundNumber(value: number): number {
    return Math.round(value * 100) / 100;
  }
  calcularPorcentaje(valor, total) {
    return Math.round(valor / total * 100);
  }
  recalcularTotales() {
    this.calcularTotalesActivo();
  }
  
  calcularTotalesActivo() {
   
    this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2022 = Math.round(this.sumarTotales(
      parseFloat(this.AnalisisPM.anexo.activo_circulante.caja._2022),
      parseFloat(this.AnalisisPM.anexo.activo_circulante.bancos._2022),
      parseFloat(this.AnalisisPM.anexo.activo_circulante.clientes._2022),
      parseFloat(this.AnalisisPM.anexo.activo_circulante.inventarios._2022),
      parseFloat(this.AnalisisPM.anexo.activo_circulante.cuentas_por_cobrar._2022),
      parseFloat(this.AnalisisPM.anexo.activo_circulante.impuestos_a_favor._2022),
      parseFloat(this.AnalisisPM.anexo.activo_circulante.iva_pendiente._2022),
      parseFloat(this.AnalisisPM.anexo.activo_circulante.deudores_diversos._2022),
      parseFloat(this.AnalisisPM.anexo.activo_circulante.pagos_anticipados._2022),
      parseFloat(this.AnalisisPM.anexo.activo_circulante.inversiones._2022),
      parseFloat(this.AnalisisPM.anexo.activo_circulante.otras_cuentas._2022))
    );
    console.log(this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2022, this.AnalisisPM.anexo.activo_circulante.caja._2022 )
    this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2023 = this.sumarTotales(
      parseFloat(this.AnalisisPM.anexo.activo_circulante.caja._2023),
      parseFloat(this.AnalisisPM.anexo.activo_circulante.bancos._2023),
      parseFloat(this.AnalisisPM.anexo.activo_circulante.clientes._2023),
      parseFloat(this.AnalisisPM.anexo.activo_circulante.inventarios._2023),
      parseFloat(this.AnalisisPM.anexo.activo_circulante.cuentas_por_cobrar._2023),
      parseFloat(this.AnalisisPM.anexo.activo_circulante.impuestos_a_favor._2023),
      parseFloat(this.AnalisisPM.anexo.activo_circulante.iva_pendiente._2023),
      parseFloat(this.AnalisisPM.anexo.activo_circulante.deudores_diversos._2023),
      parseFloat(this.AnalisisPM.anexo.activo_circulante.pagos_anticipados._2023),
      parseFloat(this.AnalisisPM.anexo.activo_circulante.inversiones._2023),
      parseFloat(this.AnalisisPM.anexo.activo_circulante.otras_cuentas._2023)
    );
    this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2024 = this.sumarTotales(
      parseFloat(this.AnalisisPM.anexo.activo_circulante.caja._2024),
      parseFloat(this.AnalisisPM.anexo.activo_circulante.bancos._2024),
      parseFloat(this.AnalisisPM.anexo.activo_circulante.clientes._2024),
      parseFloat(this.AnalisisPM.anexo.activo_circulante.inventarios._2024),
      parseFloat(this.AnalisisPM.anexo.activo_circulante.cuentas_por_cobrar._2024),
      parseFloat(this.AnalisisPM.anexo.activo_circulante.impuestos_a_favor._2024),
      parseFloat(this.AnalisisPM.anexo.activo_circulante.iva_pendiente._2024),
      parseFloat(this.AnalisisPM.anexo.activo_circulante.deudores_diversos._2024),
      parseFloat(this.AnalisisPM.anexo.activo_circulante.pagos_anticipados._2024),
      parseFloat(this.AnalisisPM.anexo.activo_circulante.inversiones._2024),
      parseFloat(this.AnalisisPM.anexo.activo_circulante.otras_cuentas._2024)
    );
      this.AnalisisPM.anexo.activo_circulante.caja.porcentaje_2022 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.caja._2022), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2022)
      this.AnalisisPM.anexo.activo_circulante.bancos.porcentaje_2022 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.bancos._2022), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2022)
      this.AnalisisPM.anexo.activo_circulante.clientes.porcentaje_2022 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.clientes._2022), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2022)
      this.AnalisisPM.anexo.activo_circulante.inventarios.porcentaje_2022 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.inventarios._2022), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2022)
      this.AnalisisPM.anexo.activo_circulante.cuentas_por_cobrar.porcentaje_2022 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.cuentas_por_cobrar._2022), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2022)
      this.AnalisisPM.anexo.activo_circulante.impuestos_a_favor.porcentaje_2022 =  this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.impuestos_a_favor._2022), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2022)
      this.AnalisisPM.anexo.activo_circulante.iva_pendiente.porcentaje_2022 =  this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.iva_pendiente._2022), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2022)
      this.AnalisisPM.anexo.activo_circulante.deudores_diversos.porcentaje_2022 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.deudores_diversos._2022), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2022)
      this.AnalisisPM.anexo.activo_circulante.pagos_anticipados.porcentaje_2022 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.pagos_anticipados._2022), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2022)
      this.AnalisisPM.anexo.activo_circulante.inversiones.porcentaje_2022 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.inversiones._2022), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2022)
      this.AnalisisPM.anexo.activo_circulante.otras_cuentas.porcentaje_2022 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.otras_cuentas._2022), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2022)

      this.AnalisisPM.anexo.activo_circulante.total_activo_circulante.porcentaje_2022 = this.sumarTotales(
        parseFloat(this.AnalisisPM.anexo.activo_circulante.caja.porcentaje_2022),
        parseFloat(this.AnalisisPM.anexo.activo_circulante.bancos.porcentaje_2022),
        parseFloat(this.AnalisisPM.anexo.activo_circulante.clientes.porcentaje_2022),
        parseFloat(this.AnalisisPM.anexo.activo_circulante.inventarios.porcentaje_2022),
        parseFloat(this.AnalisisPM.anexo.activo_circulante.cuentas_por_cobrar.porcentaje_2022),
        parseFloat(this.AnalisisPM.anexo.activo_circulante.impuestos_a_favor.porcentaje_2022),
        parseFloat(this.AnalisisPM.anexo.activo_circulante.iva_pendiente.porcentaje_2022),
        parseFloat(this.AnalisisPM.anexo.activo_circulante.deudores_diversos.porcentaje_2022),
        parseFloat(this.AnalisisPM.anexo.activo_circulante.pagos_anticipados.porcentaje_2022),
        parseFloat(this.AnalisisPM.anexo.activo_circulante.inversiones.porcentaje_2022),
      )

      this.AnalisisPM.anexo.activo_circulante.caja.porcentaje_2023 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.caja._2023), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2023)
      this.AnalisisPM.anexo.activo_circulante.bancos.porcentaje_2023 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.bancos._2023), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2023)
      this.AnalisisPM.anexo.activo_circulante.clientes.porcentaje_2023 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.clientes._2023), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2023)
      this.AnalisisPM.anexo.activo_circulante.inventarios.porcentaje_2023 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.inventarios._2023), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2023)
      this.AnalisisPM.anexo.activo_circulante.cuentas_por_cobrar.porcentaje_2023 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.cuentas_por_cobrar._2023), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2023)
      this.AnalisisPM.anexo.activo_circulante.impuestos_a_favor.porcentaje_2023 =  this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.impuestos_a_favor._2023), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2023)
      this.AnalisisPM.anexo.activo_circulante.iva_pendiente.porcentaje_2023 =  this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.iva_pendiente._2023), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2023)
      this.AnalisisPM.anexo.activo_circulante.deudores_diversos.porcentaje_2023 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.deudores_diversos._2023), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2023)
      this.AnalisisPM.anexo.activo_circulante.pagos_anticipados.porcentaje_2023 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.pagos_anticipados._2023), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2023)
      this.AnalisisPM.anexo.activo_circulante.inversiones.porcentaje_2023 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.inversiones._2023), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2023)
      this.AnalisisPM.anexo.activo_circulante.otras_cuentas.porcentaje_2023 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.otras_cuentas._2023), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2023)

      this.AnalisisPM.anexo.activo_circulante.total_activo_circulante.porcentaje_2023 = this.sumarTotales(
        parseFloat(this.AnalisisPM.anexo.activo_circulante.caja.porcentaje_2023),
        parseFloat(this.AnalisisPM.anexo.activo_circulante.bancos.porcentaje_2023),
        parseFloat(this.AnalisisPM.anexo.activo_circulante.clientes.porcentaje_2023),
        parseFloat(this.AnalisisPM.anexo.activo_circulante.inventarios.porcentaje_2023),
        parseFloat(this.AnalisisPM.anexo.activo_circulante.cuentas_por_cobrar.porcentaje_2023),
        parseFloat(this.AnalisisPM.anexo.activo_circulante.impuestos_a_favor.porcentaje_2023),
        parseFloat(this.AnalisisPM.anexo.activo_circulante.iva_pendiente.porcentaje_2023),
        parseFloat(this.AnalisisPM.anexo.activo_circulante.deudores_diversos.porcentaje_2023),
        parseFloat(this.AnalisisPM.anexo.activo_circulante.pagos_anticipados.porcentaje_2023),
        parseFloat(this.AnalisisPM.anexo.activo_circulante.inversiones.porcentaje_2023),
      )
      this.AnalisisPM.anexo.activo_circulante.caja.porcentaje_2024 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.caja._2024), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2024)
      this.AnalisisPM.anexo.activo_circulante.bancos.porcentaje_2024 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.bancos._2024), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2024)
      this.AnalisisPM.anexo.activo_circulante.clientes.porcentaje_2024 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.clientes._2024), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2024)
      this.AnalisisPM.anexo.activo_circulante.inventarios.porcentaje_2024 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.inventarios._2024), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2024)
      this.AnalisisPM.anexo.activo_circulante.cuentas_por_cobrar.porcentaje_2024 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.cuentas_por_cobrar._2024), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2024)
      this.AnalisisPM.anexo.activo_circulante.impuestos_a_favor.porcentaje_2024 =  this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.impuestos_a_favor._2024), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2024)
      this.AnalisisPM.anexo.activo_circulante.iva_pendiente.porcentaje_2024 =  this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.iva_pendiente._2024), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2024)
      this.AnalisisPM.anexo.activo_circulante.deudores_diversos.porcentaje_2024 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.deudores_diversos._2024), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2024)
      this.AnalisisPM.anexo.activo_circulante.pagos_anticipados.porcentaje_2024 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.pagos_anticipados._2024), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2024)
      this.AnalisisPM.anexo.activo_circulante.inversiones.porcentaje_2024 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.inversiones._2024), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2024)
      this.AnalisisPM.anexo.activo_circulante.otras_cuentas.porcentaje_2024 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_circulante.otras_cuentas._2024), this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2024)

      this.AnalisisPM.anexo.activo_circulante.total_activo_circulante.porcentaje_2024 = this.sumarTotales(
        parseFloat(this.AnalisisPM.anexo.activo_circulante.caja.porcentaje_2024),
        parseFloat(this.AnalisisPM.anexo.activo_circulante.bancos.porcentaje_2024),
        parseFloat(this.AnalisisPM.anexo.activo_circulante.clientes.porcentaje_2024),
        parseFloat(this.AnalisisPM.anexo.activo_circulante.inventarios.porcentaje_2024),
        parseFloat(this.AnalisisPM.anexo.activo_circulante.cuentas_por_cobrar.porcentaje_2024),
        parseFloat(this.AnalisisPM.anexo.activo_circulante.impuestos_a_favor.porcentaje_2024),
        parseFloat(this.AnalisisPM.anexo.activo_circulante.iva_pendiente.porcentaje_2024),
        parseFloat(this.AnalisisPM.anexo.activo_circulante.deudores_diversos.porcentaje_2024),
        parseFloat(this.AnalisisPM.anexo.activo_circulante.pagos_anticipados.porcentaje_2024),
        parseFloat(this.AnalisisPM.anexo.activo_circulante.inversiones.porcentaje_2024),
      )
      this.calcularAnalisisHorizontalActivoAbsoluto();
      this.calcularAnalisisHorizontalActivoPorcentual();
      this.calcularActivocirculanteFijoTotal();
  }
  calcularActivocirculanteFijoTotal(){
    this.AnalisisPM.anexo.total_activos_circulante_fijo_22 = this.sumarTotales(this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2022, this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2022, Math.floor(this.AnalisisPM.anexo.otros_activos_22))
    this.AnalisisPM.anexo.total_activos_circulante_fijo_23 = this.sumarTotales(this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2023, this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2023, Math.floor(this.AnalisisPM.anexo.otros_activos_23))
    this.AnalisisPM.anexo.total_activos_circulante_fijo_24 = this.sumarTotales(this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2024, this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2024, Math.floor(this.AnalisisPM.anexo.otros_activos_24))

    this.AnalisisPM.anexo.total_activos_circulante_fijo_absoluto_22_23 = this.AnalisisPM.anexo.total_activos_circulante_fijo_23 - this.AnalisisPM.anexo.total_activos_circulante_fijo_22
    this.AnalisisPM.anexo.total_activos_circulante_fijo_absoluto_23_24 = this.AnalisisPM.anexo.total_activos_circulante_fijo_24 - this.AnalisisPM.anexo.total_activos_circulante_fijo_23

    this.AnalisisPM.anexo.total_activos_circulante_fijo_porcentual_22_23 = Math.round(((this.AnalisisPM.anexo.total_activos_circulante_fijo_23 / this.AnalisisPM.anexo.total_activos_circulante_fijo_22) - 1) * 100);
    this.AnalisisPM.anexo.total_activos_circulante_fijo_porcentual_23_24 = Math.round(((this.AnalisisPM.anexo.total_activos_circulante_fijo_24 / this.AnalisisPM.anexo.total_activos_circulante_fijo_23) - 1) * 100);

    this.AnalisisPM.anexo.otras_cuentas_fijo_var_absoluta_22_23 = this.AnalisisPM.anexo.otros_activos_23 - this.AnalisisPM.anexo.otros_activos_22
    this.AnalisisPM.anexo.otras_cuentas_fijo_var_absoluta_23_24  = this.AnalisisPM.anexo.otros_activos_24 - this.AnalisisPM.anexo.otros_activos_23

    this.AnalisisPM.anexo.otras_cuentas_fijo_var_porcentual_22_23  = Math.round(((this.AnalisisPM.anexo.otros_activos_23 / this.AnalisisPM.anexo.otros_activos_22) - 1) * 100);
    this.AnalisisPM.anexo.otras_cuentas_fijo_var_porcentual_23_24  = Math.round(((this.AnalisisPM.anexo.otros_activos_24 / this.AnalisisPM.anexo.otros_activos_23) - 1) * 100);

  }
  sumarTotales(...valores: number[]): number {
    return valores.reduce((total, valor) => total + parseFloat(valor.toFixed(2)), 0);
  }
 
  restarAnalisisHorizontalActivo(primerAnio, segundoAnio){
    return Math.round(segundoAnio - primerAnio)
  }
  calcularAnalisisHorizontalActivoAbsoluto(){
    const activoCirculante = this.AnalisisPM.anexo.activo_circulante;
    const year2022 = '_2022';
    const year2023 = '_2023';
    
    for (const key in activoCirculante) {
      if (activoCirculante.hasOwnProperty(key) && key !== 'total_activo_circulante') {
        activoCirculante[key].var_absoluta_22_23 = activoCirculante[key][year2023] - activoCirculante[key][year2022];
      }
    }

    this.AnalisisPM.anexo.activo_circulante.total_activo_circulante.var_absoluta_22_23 = this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2023 - this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2022


    const year2024 = '_2024';
      
    for (const key in activoCirculante) {
      if (activoCirculante.hasOwnProperty(key) && key !== 'total_activo_circulante') {
        activoCirculante[key].var_absoluta_23_24 = activoCirculante[key][year2024] - activoCirculante[key][year2023];
      }
    }

    this.AnalisisPM.anexo.activo_circulante.total_activo_circulante.var_absoluta_23_24  = this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2024 - this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2023
  }
  calcularAnalisisHorizontalActivoPorcentual(){
    const activoCirculante = this.AnalisisPM.anexo.activo_circulante;
    const year2022 = '_2022';
    const year2023 = '_2023';
    
    for (const key in activoCirculante) {
      if (activoCirculante.hasOwnProperty(key) && key !== 'total_activo_circulante') {
        const currentValue2022 = activoCirculante[key][year2022];
        const currentValue2023 = activoCirculante[key][year2023];
        const varPorcentualKey = `var_porcentual_${year2022.substring(1)}_${year2023.substring(1)}`;
        
        activoCirculante[key][varPorcentualKey] = Math.round(((currentValue2023 / currentValue2022) - 1) * 100);
      }
    }
    
    this.AnalisisPM.anexo.activo_circulante.total_activo_circulante.var_porcentual_22_23 = Math.round(((this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2023 / this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2022) - 1) * 100);

    const year2024 = '_2024';
    
    for (const key in activoCirculante) {
      if (activoCirculante.hasOwnProperty(key) && key !== 'total_activo_circulante') {
        const currentValue2023 = activoCirculante[key][year2023];
        const currentValue2024 = activoCirculante[key][year2024];
        const varPorcentualKey = `var_porcentual_${year2023.substring(1)}_${year2024.substring(1)}`;
        
        activoCirculante[key][varPorcentualKey] = Math.round(((currentValue2024 / currentValue2023) - 1) * 100);
      }
    }
    
    this.AnalisisPM.anexo.activo_circulante.total_activo_circulante.var_porcentual_23_24 = Math.round(((this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2024 / this.AnalisisPM.anexo.activo_circulante.total_activo_circulante._2023) - 1) * 100);
  }

  recalcularTotalesActivoFijo() {
    this.calcularTotalesActivoFijo();
  }
  
  calcularTotalesActivoFijo() {
    this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2022 = 
      (parseFloat(this.AnalisisPM.anexo.activo_fijo.terrenos_edificios._2022) -
       parseFloat(this.AnalisisPM.anexo.activo_fijo.depreciacion_terrenos._2022)) +
      (parseFloat(this.AnalisisPM.anexo.activo_fijo.maquinaria_equipo._2022) -
       parseFloat(this.AnalisisPM.anexo.activo_fijo.depreciacion_maquinaria_equipo._2022)) +
      (parseFloat(this.AnalisisPM.anexo.activo_fijo.mobiliario_oficina_equipo_comp._2022) -
       parseFloat(this.AnalisisPM.anexo.activo_fijo.depreciacion_mob_oficina_equipo._2022)) +
      (parseFloat(this.AnalisisPM.anexo.activo_fijo.equipo_transporte._2022) -
       parseFloat(this.AnalisisPM.anexo.activo_fijo.depreciacion_equipo_transp._2022)) +
       parseFloat(this.AnalisisPM.anexo.activo_fijo.otras_cuentas._2022)
    
    this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2023 = 
      (parseFloat(this.AnalisisPM.anexo.activo_fijo.terrenos_edificios._2023) -
       parseFloat(this.AnalisisPM.anexo.activo_fijo.depreciacion_terrenos._2023)) +
      (parseFloat(this.AnalisisPM.anexo.activo_fijo.maquinaria_equipo._2023) -
       parseFloat(this.AnalisisPM.anexo.activo_fijo.depreciacion_maquinaria_equipo._2023)) +
      (parseFloat(this.AnalisisPM.anexo.activo_fijo.mobiliario_oficina_equipo_comp._2023) -
       parseFloat(this.AnalisisPM.anexo.activo_fijo.depreciacion_mob_oficina_equipo._2023)) +
      (parseFloat(this.AnalisisPM.anexo.activo_fijo.equipo_transporte._2023) -
       parseFloat(this.AnalisisPM.anexo.activo_fijo.depreciacion_equipo_transp._2023)) +
       parseFloat(this.AnalisisPM.anexo.activo_fijo.otras_cuentas._2023)
    
    this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2024 = 
      (parseFloat(this.AnalisisPM.anexo.activo_fijo.terrenos_edificios._2024) -
       parseFloat(this.AnalisisPM.anexo.activo_fijo.depreciacion_terrenos._2024)) +
      (parseFloat(this.AnalisisPM.anexo.activo_fijo.maquinaria_equipo._2024) -
       parseFloat(this.AnalisisPM.anexo.activo_fijo.depreciacion_maquinaria_equipo._2024)) +
      (parseFloat(this.AnalisisPM.anexo.activo_fijo.mobiliario_oficina_equipo_comp._2024) -
       parseFloat(this.AnalisisPM.anexo.activo_fijo.depreciacion_mob_oficina_equipo._2024)) +
      (parseFloat(this.AnalisisPM.anexo.activo_fijo.equipo_transporte._2024) -
       parseFloat(this.AnalisisPM.anexo.activo_fijo.depreciacion_equipo_transp._2024)) +
       parseFloat(this.AnalisisPM.anexo.activo_fijo.otras_cuentas._2024)
    
      this.AnalisisPM.anexo.activo_fijo.terrenos_edificios.porcentaje_2022 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_fijo.terrenos_edificios._2022), this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2022)
      this.AnalisisPM.anexo.activo_fijo.depreciacion_terrenos.porcentaje_2022 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_fijo.depreciacion_terrenos._2022), this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2022)
      this.AnalisisPM.anexo.activo_fijo.maquinaria_equipo.porcentaje_2022 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_fijo.maquinaria_equipo._2022), this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2022)
      this.AnalisisPM.anexo.activo_fijo.depreciacion_maquinaria_equipo.porcentaje_2022 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_fijo.depreciacion_maquinaria_equipo._2022), this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2022)
      this.AnalisisPM.anexo.activo_fijo.mobiliario_oficina_equipo_comp.porcentaje_2022 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_fijo.mobiliario_oficina_equipo_comp._2022), this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2022)
      this.AnalisisPM.anexo.activo_fijo.depreciacion_mob_oficina_equipo.porcentaje_2022 =  this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_fijo.depreciacion_mob_oficina_equipo._2022), this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2022)
      this.AnalisisPM.anexo.activo_fijo.equipo_transporte.porcentaje_2022 =  this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_fijo.equipo_transporte._2022), this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2022)
      this.AnalisisPM.anexo.activo_fijo.depreciacion_equipo_transp.porcentaje_2022 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_fijo.depreciacion_equipo_transp._2022), this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2022)
      this.AnalisisPM.anexo.activo_fijo.otras_cuentas.porcentaje_2022 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_fijo.otras_cuentas._2022), this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2022)
      
      

      this.AnalisisPM.anexo.activo_fijo.activo_fijo_total.porcentaje_2022 = this.sumarTotales(
        parseFloat(this.AnalisisPM.anexo.activo_fijo.terrenos_edificios.porcentaje_2022),
        parseFloat(this.AnalisisPM.anexo.activo_fijo.depreciacion_terrenos.porcentaje_2022),
        parseFloat(this.AnalisisPM.anexo.activo_fijo.maquinaria_equipo.porcentaje_2022),
        parseFloat(this.AnalisisPM.anexo.activo_fijo.depreciacion_maquinaria_equipo.porcentaje_2022),
        parseFloat(this.AnalisisPM.anexo.activo_fijo.mobiliario_oficina_equipo_comp.porcentaje_2022),
        parseFloat(this.AnalisisPM.anexo.activo_fijo.depreciacion_mob_oficina_equipo.porcentaje_2022),
        parseFloat(this.AnalisisPM.anexo.activo_fijo.equipo_transporte.porcentaje_2022),
        parseFloat(this.AnalisisPM.anexo.activo_fijo.depreciacion_equipo_transp.porcentaje_2022),
        parseFloat(this.AnalisisPM.anexo.activo_fijo.otras_cuentas.porcentaje_2022),
        
      )

      this.AnalisisPM.anexo.activo_fijo.terrenos_edificios.porcentaje_2023 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_fijo.terrenos_edificios._2023), this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2023)
      this.AnalisisPM.anexo.activo_fijo.depreciacion_terrenos.porcentaje_2023 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_fijo.depreciacion_terrenos._2023), this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2023)
      this.AnalisisPM.anexo.activo_fijo.maquinaria_equipo.porcentaje_2023 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_fijo.maquinaria_equipo._2023), this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2023)
      this.AnalisisPM.anexo.activo_fijo.depreciacion_maquinaria_equipo.porcentaje_2023 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_fijo.depreciacion_maquinaria_equipo._2023), this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2023)
      this.AnalisisPM.anexo.activo_fijo.mobiliario_oficina_equipo_comp.porcentaje_2023 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_fijo.mobiliario_oficina_equipo_comp._2023), this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2023)
      this.AnalisisPM.anexo.activo_fijo.depreciacion_mob_oficina_equipo.porcentaje_2023 =  this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_fijo.depreciacion_mob_oficina_equipo._2023), this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2023)
      this.AnalisisPM.anexo.activo_fijo.equipo_transporte.porcentaje_2023 =  this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_fijo.equipo_transporte._2023), this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2023)
      this.AnalisisPM.anexo.activo_fijo.depreciacion_equipo_transp.porcentaje_2023 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_fijo.depreciacion_equipo_transp._2023), this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2023)
      this.AnalisisPM.anexo.activo_fijo.otras_cuentas.porcentaje_2023 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_fijo.otras_cuentas._2023), this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2023)
      
      

      this.AnalisisPM.anexo.activo_fijo.activo_fijo_total.porcentaje_2023 = this.sumarTotales(
        parseFloat(this.AnalisisPM.anexo.activo_fijo.terrenos_edificios.porcentaje_2023),
        parseFloat(this.AnalisisPM.anexo.activo_fijo.depreciacion_terrenos.porcentaje_2023),
        parseFloat(this.AnalisisPM.anexo.activo_fijo.maquinaria_equipo.porcentaje_2023),
        parseFloat(this.AnalisisPM.anexo.activo_fijo.depreciacion_maquinaria_equipo.porcentaje_2023),
        parseFloat(this.AnalisisPM.anexo.activo_fijo.mobiliario_oficina_equipo_comp.porcentaje_2023),
        parseFloat(this.AnalisisPM.anexo.activo_fijo.depreciacion_mob_oficina_equipo.porcentaje_2023),
        parseFloat(this.AnalisisPM.anexo.activo_fijo.equipo_transporte.porcentaje_2023),
        parseFloat(this.AnalisisPM.anexo.activo_fijo.depreciacion_equipo_transp.porcentaje_2023),
        parseFloat(this.AnalisisPM.anexo.activo_fijo.otras_cuentas.porcentaje_2023),
        
      )
      this.AnalisisPM.anexo.activo_fijo.terrenos_edificios.porcentaje_2024 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_fijo.terrenos_edificios._2024), this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2024)
      this.AnalisisPM.anexo.activo_fijo.depreciacion_terrenos.porcentaje_2024 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_fijo.depreciacion_terrenos._2024), this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2024)
      this.AnalisisPM.anexo.activo_fijo.maquinaria_equipo.porcentaje_2024 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_fijo.maquinaria_equipo._2024), this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2024)
      this.AnalisisPM.anexo.activo_fijo.depreciacion_maquinaria_equipo.porcentaje_2024 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_fijo.depreciacion_maquinaria_equipo._2024), this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2024)
      this.AnalisisPM.anexo.activo_fijo.mobiliario_oficina_equipo_comp.porcentaje_2024 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_fijo.mobiliario_oficina_equipo_comp._2024), this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2024)
      this.AnalisisPM.anexo.activo_fijo.depreciacion_mob_oficina_equipo.porcentaje_2024 =  this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_fijo.depreciacion_mob_oficina_equipo._2024), this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2024)
      this.AnalisisPM.anexo.activo_fijo.equipo_transporte.porcentaje_2024 =  this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_fijo.equipo_transporte._2024), this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2024)
      this.AnalisisPM.anexo.activo_fijo.depreciacion_equipo_transp.porcentaje_2024 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_fijo.depreciacion_equipo_transp._2024), this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2024)
      this.AnalisisPM.anexo.activo_fijo.otras_cuentas.porcentaje_2024 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.activo_fijo.otras_cuentas._2024), this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2024)
      
      

      this.AnalisisPM.anexo.activo_fijo.activo_fijo_total.porcentaje_2024 = this.sumarTotales(
        parseFloat(this.AnalisisPM.anexo.activo_fijo.terrenos_edificios.porcentaje_2024),
        parseFloat(this.AnalisisPM.anexo.activo_fijo.depreciacion_terrenos.porcentaje_2024),
        parseFloat(this.AnalisisPM.anexo.activo_fijo.maquinaria_equipo.porcentaje_2024),
        parseFloat(this.AnalisisPM.anexo.activo_fijo.depreciacion_maquinaria_equipo.porcentaje_2024),
        parseFloat(this.AnalisisPM.anexo.activo_fijo.mobiliario_oficina_equipo_comp.porcentaje_2024),
        parseFloat(this.AnalisisPM.anexo.activo_fijo.depreciacion_mob_oficina_equipo.porcentaje_2024),
        parseFloat(this.AnalisisPM.anexo.activo_fijo.equipo_transporte.porcentaje_2024),
        parseFloat(this.AnalisisPM.anexo.activo_fijo.depreciacion_equipo_transp.porcentaje_2024),
        parseFloat(this.AnalisisPM.anexo.activo_fijo.otras_cuentas.porcentaje_2024),
        
      )
      this.calcularAnalisisHorizontalActivoFijoAbsoluto();
      this.calcularAnalisisHorizontalActivoFijoPorcentual();
      this.calcularActivocirculanteFijoTotal();
  }
  calcularAnalisisHorizontalActivoFijoAbsoluto(){
    this.AnalisisPM.anexo.activo_fijo.terrenos_edificios.var_absoluta_22_23 = this.AnalisisPM.anexo.activo_fijo.terrenos_edificios._2023 - this.AnalisisPM.anexo.activo_fijo.terrenos_edificios._2022;
    this.AnalisisPM.anexo.activo_fijo.depreciacion_terrenos.var_absoluta_22_23 = this.AnalisisPM.anexo.activo_fijo.depreciacion_terrenos._2023 - this.AnalisisPM.anexo.activo_fijo.depreciacion_terrenos._2022
    this.AnalisisPM.anexo.activo_fijo.maquinaria_equipo.var_absoluta_22_23 = this.AnalisisPM.anexo.activo_fijo.maquinaria_equipo._2023 - this.AnalisisPM.anexo.activo_fijo.maquinaria_equipo._2022
    this.AnalisisPM.anexo.activo_fijo.depreciacion_maquinaria_equipo.var_absoluta_22_23 = this.AnalisisPM.anexo.activo_fijo.depreciacion_maquinaria_equipo._2023 - this.AnalisisPM.anexo.activo_fijo.depreciacion_maquinaria_equipo._2022
    this.AnalisisPM.anexo.activo_fijo.mobiliario_oficina_equipo_comp.var_absoluta_22_23 = this.AnalisisPM.anexo.activo_fijo.mobiliario_oficina_equipo_comp._2023 - this.AnalisisPM.anexo.activo_fijo.mobiliario_oficina_equipo_comp._2022
    this.AnalisisPM.anexo.activo_fijo.depreciacion_mob_oficina_equipo.var_absoluta_22_23 = this.AnalisisPM.anexo.activo_fijo.depreciacion_mob_oficina_equipo._2023 - this.AnalisisPM.anexo.activo_fijo.depreciacion_mob_oficina_equipo._2022
    this.AnalisisPM.anexo.activo_fijo.equipo_transporte.var_absoluta_22_23 = this.AnalisisPM.anexo.activo_fijo.equipo_transporte._2023 - this.AnalisisPM.anexo.activo_fijo.equipo_transporte._2022
    this.AnalisisPM.anexo.activo_fijo.depreciacion_equipo_transp.var_absoluta_22_23 = this.AnalisisPM.anexo.activo_fijo.depreciacion_equipo_transp._2023 - this.AnalisisPM.anexo.activo_fijo.depreciacion_equipo_transp._2022
    this.AnalisisPM.anexo.activo_fijo.otras_cuentas.var_absoluta_22_23 = this.AnalisisPM.anexo.activo_fijo.otras_cuentas._2023 - this.AnalisisPM.anexo.activo_fijo.otras_cuentas._2022

    this.AnalisisPM.anexo.activo_fijo.activo_fijo_total.var_absoluta_22_23 = this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2023 - this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2022
    
    console.log("VAR ABSOLUTO", this.AnalisisPM.anexo.activo_fijo.activo_fijo_total.var_absoluta_22_23, this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2023 , this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2022)
    this.AnalisisPM.anexo.activo_fijo.terrenos_edificios.var_absoluta_23_24 = this.AnalisisPM.anexo.activo_fijo.terrenos_edificios._2024 - this.AnalisisPM.anexo.activo_fijo.terrenos_edificios._2023;
    this.AnalisisPM.anexo.activo_fijo.depreciacion_terrenos.var_absoluta_23_24 = this.AnalisisPM.anexo.activo_fijo.depreciacion_terrenos._2024 - this.AnalisisPM.anexo.activo_fijo.depreciacion_terrenos._2023
    this.AnalisisPM.anexo.activo_fijo.maquinaria_equipo.var_absoluta_23_24 = this.AnalisisPM.anexo.activo_fijo.maquinaria_equipo._2024 - this.AnalisisPM.anexo.activo_fijo.maquinaria_equipo._2023
    this.AnalisisPM.anexo.activo_fijo.depreciacion_maquinaria_equipo.var_absoluta_23_24 = this.AnalisisPM.anexo.activo_fijo.depreciacion_maquinaria_equipo._2024 - this.AnalisisPM.anexo.activo_fijo.depreciacion_maquinaria_equipo._2023
    this.AnalisisPM.anexo.activo_fijo.mobiliario_oficina_equipo_comp.var_absoluta_23_24 = this.AnalisisPM.anexo.activo_fijo.mobiliario_oficina_equipo_comp._2024 - this.AnalisisPM.anexo.activo_fijo.mobiliario_oficina_equipo_comp._2023
    this.AnalisisPM.anexo.activo_fijo.depreciacion_mob_oficina_equipo.var_absoluta_23_24 = this.AnalisisPM.anexo.activo_fijo.depreciacion_mob_oficina_equipo._2024 - this.AnalisisPM.anexo.activo_fijo.depreciacion_mob_oficina_equipo._2023
    this.AnalisisPM.anexo.activo_fijo.equipo_transporte.var_absoluta_23_24 = this.AnalisisPM.anexo.activo_fijo.equipo_transporte._2024 - this.AnalisisPM.anexo.activo_fijo.equipo_transporte._2023
    this.AnalisisPM.anexo.activo_fijo.depreciacion_equipo_transp.var_absoluta_23_24 = this.AnalisisPM.anexo.activo_fijo.depreciacion_equipo_transp._2024 - this.AnalisisPM.anexo.activo_fijo.depreciacion_equipo_transp._2023
    this.AnalisisPM.anexo.activo_fijo.otras_cuentas.var_absoluta_23_24 = this.AnalisisPM.anexo.activo_fijo.otras_cuentas._2024 - this.AnalisisPM.anexo.activo_fijo.otras_cuentas._2023
    
    this.AnalisisPM.anexo.activo_fijo.activo_fijo_total.var_absoluta_23_24 = this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2024 - this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2023

  }
  calcularAnalisisHorizontalActivoFijoPorcentual(){
    this.AnalisisPM.anexo.activo_fijo.terrenos_edificios.var_porcentual_22_23 = Math.round(((this.AnalisisPM.anexo.activo_fijo.terrenos_edificios._2023 / this.AnalisisPM.anexo.activo_fijo.terrenos_edificios._2022) - 1) * 100);
    this.AnalisisPM.anexo.activo_fijo.depreciacion_terrenos.var_porcentual_22_23 = Math.round(((this.AnalisisPM.anexo.activo_fijo.depreciacion_terrenos._2023 / this.AnalisisPM.anexo.activo_fijo.depreciacion_terrenos._2022) - 1) * 100);
    this.AnalisisPM.anexo.activo_fijo.maquinaria_equipo.var_porcentual_22_23 = Math.round(((this.AnalisisPM.anexo.activo_fijo.maquinaria_equipo._2023 / this.AnalisisPM.anexo.activo_fijo.maquinaria_equipo._2022) - 1) * 100);
    this.AnalisisPM.anexo.activo_fijo.depreciacion_maquinaria_equipo.var_porcentual_22_23 = Math.round(((this.AnalisisPM.anexo.activo_fijo.depreciacion_maquinaria_equipo._2023 / this.AnalisisPM.anexo.activo_fijo.depreciacion_maquinaria_equipo._2022) - 1) * 100);
    this.AnalisisPM.anexo.activo_fijo.mobiliario_oficina_equipo_comp.var_porcentual_22_23 = Math.round(((this.AnalisisPM.anexo.activo_fijo.mobiliario_oficina_equipo_comp._2023 / this.AnalisisPM.anexo.activo_fijo.mobiliario_oficina_equipo_comp._2022) - 1) * 100);
    this.AnalisisPM.anexo.activo_fijo.depreciacion_mob_oficina_equipo.var_porcentual_22_23 = Math.round(((this.AnalisisPM.anexo.activo_fijo.depreciacion_mob_oficina_equipo._2023 / this.AnalisisPM.anexo.activo_fijo.depreciacion_mob_oficina_equipo._2022) - 1) * 100);
    this.AnalisisPM.anexo.activo_fijo.equipo_transporte.var_porcentual_22_23 = Math.round(((this.AnalisisPM.anexo.activo_fijo.equipo_transporte._2023 / this.AnalisisPM.anexo.activo_fijo.equipo_transporte._2022) - 1) * 100);
    this.AnalisisPM.anexo.activo_fijo.depreciacion_equipo_transp.var_porcentual_22_23 = Math.round(((this.AnalisisPM.anexo.activo_fijo.depreciacion_equipo_transp._2023 / this.AnalisisPM.anexo.activo_fijo.depreciacion_equipo_transp._2022) - 1) * 100);
    this.AnalisisPM.anexo.activo_fijo.otras_cuentas.var_porcentual_22_23 = Math.round(((this.AnalisisPM.anexo.activo_fijo.otras_cuentas._2023 / this.AnalisisPM.anexo.activo_fijo.otras_cuentas._2022) - 1) * 100);
    
    this.AnalisisPM.anexo.activo_fijo.activo_fijo_total.var_porcentual_22_23 = Math.round(((this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2023 / this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2022) - 1) * 100);


    this.AnalisisPM.anexo.activo_fijo.terrenos_edificios.var_porcentual_23_24 = Math.round(((this.AnalisisPM.anexo.activo_fijo.terrenos_edificios._2024 / this.AnalisisPM.anexo.activo_fijo.terrenos_edificios._2023) - 1) * 100);
    this.AnalisisPM.anexo.activo_fijo.depreciacion_terrenos.var_porcentual_23_24 = Math.round(((this.AnalisisPM.anexo.activo_fijo.depreciacion_terrenos._2024 / this.AnalisisPM.anexo.activo_fijo.depreciacion_terrenos._2023) - 1) * 100);
    this.AnalisisPM.anexo.activo_fijo.maquinaria_equipo.var_porcentual_23_24 = Math.round(((this.AnalisisPM.anexo.activo_fijo.maquinaria_equipo._2024 / this.AnalisisPM.anexo.activo_fijo.maquinaria_equipo._2023) - 1) * 100);
    this.AnalisisPM.anexo.activo_fijo.depreciacion_maquinaria_equipo.var_porcentual_23_24 = Math.round(((this.AnalisisPM.anexo.activo_fijo.depreciacion_maquinaria_equipo._2024 / this.AnalisisPM.anexo.activo_fijo.depreciacion_maquinaria_equipo._2023) - 1) * 100);
    this.AnalisisPM.anexo.activo_fijo.mobiliario_oficina_equipo_comp.var_porcentual_23_24 = Math.round(((this.AnalisisPM.anexo.activo_fijo.mobiliario_oficina_equipo_comp._2024 / this.AnalisisPM.anexo.activo_fijo.mobiliario_oficina_equipo_comp._2023) - 1) * 100);
    this.AnalisisPM.anexo.activo_fijo.depreciacion_mob_oficina_equipo.var_porcentual_23_24 = Math.round(((this.AnalisisPM.anexo.activo_fijo.depreciacion_mob_oficina_equipo._2024 / this.AnalisisPM.anexo.activo_fijo.depreciacion_mob_oficina_equipo._2023) - 1) * 100);
    this.AnalisisPM.anexo.activo_fijo.equipo_transporte.var_porcentual_23_24 = Math.round(((this.AnalisisPM.anexo.activo_fijo.equipo_transporte._2024 / this.AnalisisPM.anexo.activo_fijo.equipo_transporte._2023) - 1) * 100);
    this.AnalisisPM.anexo.activo_fijo.depreciacion_equipo_transp.var_porcentual_23_24 = Math.round(((this.AnalisisPM.anexo.activo_fijo.depreciacion_equipo_transp._2024 / this.AnalisisPM.anexo.activo_fijo.depreciacion_equipo_transp._2023) - 1) * 100);
    this.AnalisisPM.anexo.activo_fijo.otras_cuentas.var_porcentual_23_24 = Math.round(((this.AnalisisPM.anexo.activo_fijo.otras_cuentas._2024 / this.AnalisisPM.anexo.activo_fijo.otras_cuentas._2023) - 1) * 100);
    
    this.AnalisisPM.anexo.activo_fijo.activo_fijo_total.var_porcentual_23_24 = Math.round(((this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2024 / this.AnalisisPM.anexo.activo_fijo.activo_fijo_total._2023) - 1) * 100);

  }

  recalcularTotalesPasivo(){
    this.calcularTotalesPasivo();
  }

  calcularTotalesPasivo() {
    this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2022 = this.sumarTotales(
      parseFloat(this.AnalisisPM.anexo.pasivo.proveedores._2022),
      parseFloat(this.AnalisisPM.anexo.pasivo.acreedores._2022),
      parseFloat(this.AnalisisPM.anexo.pasivo.impuestos_pagar._2022),
      parseFloat(this.AnalisisPM.anexo.pasivo.documentos_pagar._2022),
      parseFloat(this.AnalisisPM.anexo.pasivo.iva_trasladar._2022),
      parseFloat(this.AnalisisPM.anexo.pasivo.provisiones._2022),
      parseFloat(this.AnalisisPM.anexo.pasivo.intereses._2022),
      parseFloat(this.AnalisisPM.anexo.pasivo.otras_cuentas._2022)
    );
    this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2023 = this.sumarTotales(
      parseFloat(this.AnalisisPM.anexo.pasivo.proveedores._2023),
      parseFloat(this.AnalisisPM.anexo.pasivo.acreedores._2023),
      parseFloat(this.AnalisisPM.anexo.pasivo.impuestos_pagar._2023),
      parseFloat(this.AnalisisPM.anexo.pasivo.documentos_pagar._2023),
      parseFloat(this.AnalisisPM.anexo.pasivo.iva_trasladar._2023),
      parseFloat(this.AnalisisPM.anexo.pasivo.provisiones._2023),
      parseFloat(this.AnalisisPM.anexo.pasivo.intereses._2023),
      parseFloat(this.AnalisisPM.anexo.pasivo.otras_cuentas._2023)
    );
    this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2024 = this.sumarTotales(
      parseFloat(this.AnalisisPM.anexo.pasivo.proveedores._2024),
      parseFloat(this.AnalisisPM.anexo.pasivo.acreedores._2024),
      parseFloat(this.AnalisisPM.anexo.pasivo.impuestos_pagar._2024),
      parseFloat(this.AnalisisPM.anexo.pasivo.documentos_pagar._2024),
      parseFloat(this.AnalisisPM.anexo.pasivo.iva_trasladar._2024),
      parseFloat(this.AnalisisPM.anexo.pasivo.provisiones._2024),
      parseFloat(this.AnalisisPM.anexo.pasivo.intereses._2024),
      parseFloat(this.AnalisisPM.anexo.pasivo.otras_cuentas._2024)
    );
      this.AnalisisPM.anexo.pasivo.proveedores.porcentaje_2022 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.pasivo.proveedores._2022), this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2022)
      this.AnalisisPM.anexo.pasivo.acreedores.porcentaje_2022 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.pasivo.acreedores._2022), this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2022)
      this.AnalisisPM.anexo.pasivo.impuestos_pagar.porcentaje_2022 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.pasivo.impuestos_pagar._2022), this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2022)
      this.AnalisisPM.anexo.pasivo.documentos_pagar.porcentaje_2022 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.pasivo.documentos_pagar._2022), this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2022)
      this.AnalisisPM.anexo.pasivo.iva_trasladar.porcentaje_2022 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.pasivo.iva_trasladar._2022), this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2022)
      this.AnalisisPM.anexo.pasivo.provisiones.porcentaje_2022 =  this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.pasivo.provisiones._2022), this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2022)
      this.AnalisisPM.anexo.pasivo.intereses.porcentaje_2022 =  this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.pasivo.intereses._2022), this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2022)
      this.AnalisisPM.anexo.pasivo.otras_cuentas.porcentaje_2022 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.pasivo.otras_cuentas._2022), this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2022)
  
  
  

      this.AnalisisPM.anexo.pasivo.total_pasivo_circulante.porcentaje_2022 = this.sumarTotales(
        parseFloat(this.AnalisisPM.anexo.pasivo.proveedores.porcentaje_2022),
        parseFloat(this.AnalisisPM.anexo.pasivo.acreedores.porcentaje_2022),
        parseFloat(this.AnalisisPM.anexo.pasivo.impuestos_pagar.porcentaje_2022),
        parseFloat(this.AnalisisPM.anexo.pasivo.documentos_pagar.porcentaje_2022),
        parseFloat(this.AnalisisPM.anexo.pasivo.iva_trasladar.porcentaje_2022),
        parseFloat(this.AnalisisPM.anexo.pasivo.provisiones.porcentaje_2022),
        parseFloat(this.AnalisisPM.anexo.pasivo.intereses.porcentaje_2022),
        parseFloat(this.AnalisisPM.anexo.pasivo.otras_cuentas.porcentaje_2022),
       
       
      )

      this.AnalisisPM.anexo.pasivo.proveedores.porcentaje_2023 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.pasivo.proveedores._2023), this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2023)
      this.AnalisisPM.anexo.pasivo.acreedores.porcentaje_2023 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.pasivo.acreedores._2023), this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2023)
      this.AnalisisPM.anexo.pasivo.impuestos_pagar.porcentaje_2023 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.pasivo.impuestos_pagar._2023), this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2023)
      this.AnalisisPM.anexo.pasivo.documentos_pagar.porcentaje_2023 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.pasivo.documentos_pagar._2023), this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2023)
      this.AnalisisPM.anexo.pasivo.iva_trasladar.porcentaje_2023 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.pasivo.iva_trasladar._2023), this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2023)
      this.AnalisisPM.anexo.pasivo.provisiones.porcentaje_2023 =  this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.pasivo.provisiones._2023), this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2023)
      this.AnalisisPM.anexo.pasivo.intereses.porcentaje_2023 =  this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.pasivo.intereses._2023), this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2023)
      this.AnalisisPM.anexo.pasivo.otras_cuentas.porcentaje_2023 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.pasivo.otras_cuentas._2023), this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2023)
     
     
     

      this.AnalisisPM.anexo.pasivo.total_pasivo_circulante.porcentaje_2023 = this.sumarTotales(
        parseFloat(this.AnalisisPM.anexo.pasivo.proveedores.porcentaje_2023),
        parseFloat(this.AnalisisPM.anexo.pasivo.acreedores.porcentaje_2023),
        parseFloat(this.AnalisisPM.anexo.pasivo.impuestos_pagar.porcentaje_2023),
        parseFloat(this.AnalisisPM.anexo.pasivo.documentos_pagar.porcentaje_2023),
        parseFloat(this.AnalisisPM.anexo.pasivo.iva_trasladar.porcentaje_2023),
        parseFloat(this.AnalisisPM.anexo.pasivo.provisiones.porcentaje_2023),
        parseFloat(this.AnalisisPM.anexo.pasivo.intereses.porcentaje_2023),
        parseFloat(this.AnalisisPM.anexo.pasivo.otras_cuentas.porcentaje_2023),
       
       
      )
      this.AnalisisPM.anexo.pasivo.proveedores.porcentaje_2024 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.pasivo.proveedores._2024), this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2024)
      this.AnalisisPM.anexo.pasivo.acreedores.porcentaje_2024 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.pasivo.acreedores._2024), this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2024)
      this.AnalisisPM.anexo.pasivo.impuestos_pagar.porcentaje_2024 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.pasivo.impuestos_pagar._2024), this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2024)
      this.AnalisisPM.anexo.pasivo.documentos_pagar.porcentaje_2024 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.pasivo.documentos_pagar._2024), this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2024)
      this.AnalisisPM.anexo.pasivo.iva_trasladar.porcentaje_2024 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.pasivo.iva_trasladar._2024), this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2024)
      this.AnalisisPM.anexo.pasivo.provisiones.porcentaje_2024 =  this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.pasivo.provisiones._2024), this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2024)
      this.AnalisisPM.anexo.pasivo.intereses.porcentaje_2024 =  this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.pasivo.intereses._2024), this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2024)
      this.AnalisisPM.anexo.pasivo.otras_cuentas.porcentaje_2024 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.pasivo.otras_cuentas._2024), this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2024)
      
      
      

      this.AnalisisPM.anexo.pasivo.total_pasivo_circulante.porcentaje_2024 = this.sumarTotales(
        parseFloat(this.AnalisisPM.anexo.pasivo.proveedores.porcentaje_2024),
        parseFloat(this.AnalisisPM.anexo.pasivo.acreedores.porcentaje_2024),
        parseFloat(this.AnalisisPM.anexo.pasivo.impuestos_pagar.porcentaje_2024),
        parseFloat(this.AnalisisPM.anexo.pasivo.documentos_pagar.porcentaje_2024),
        parseFloat(this.AnalisisPM.anexo.pasivo.iva_trasladar.porcentaje_2024),
        parseFloat(this.AnalisisPM.anexo.pasivo.provisiones.porcentaje_2024),
        parseFloat(this.AnalisisPM.anexo.pasivo.intereses.porcentaje_2024),
        parseFloat(this.AnalisisPM.anexo.pasivo.otras_cuentas.porcentaje_2024),
      )

      this.AnalisisPM.anexo.pasivo.total_pasivos_cir_lg._2022 = this.sumarTotales(
        this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2022,
        this.AnalisisPM.anexo.pasivo.otros_pasivos_lg._2022
      )
      this.AnalisisPM.anexo.pasivo.total_pasivos_cir_lg._2023 = this.sumarTotales(
        this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2023,
        this.AnalisisPM.anexo.pasivo.otros_pasivos_lg._2023
      )
      
      this.AnalisisPM.anexo.pasivo.total_pasivos_cir_lg._2024 = this.sumarTotales(
        this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2024,
        this.AnalisisPM.anexo.pasivo.otros_pasivos_lg._2024
      )
      this.calcularTotalesPasivoCapital();
      this.calcularAnalisisHorizontalPasivoAbsoluto();
      this.calcularAnalisisHorizontalPasivoPorcentual();
  }

  calcularAnalisisHorizontalPasivoAbsoluto(){
    this.AnalisisPM.anexo.pasivo.proveedores.var_absoluta_22_23 = this.AnalisisPM.anexo.pasivo.proveedores._2023 - this.AnalisisPM.anexo.pasivo.proveedores._2022
    this.AnalisisPM.anexo.pasivo.acreedores.var_absoluta_22_23 = this.AnalisisPM.anexo.pasivo.acreedores._2023 - this.AnalisisPM.anexo.pasivo.acreedores._2022
    this.AnalisisPM.anexo.pasivo.impuestos_pagar.var_absoluta_22_23 = this.AnalisisPM.anexo.pasivo.impuestos_pagar._2023 - this.AnalisisPM.anexo.pasivo.impuestos_pagar._2022
    this.AnalisisPM.anexo.pasivo.documentos_pagar.var_absoluta_22_23 = this.AnalisisPM.anexo.pasivo.documentos_pagar._2023 - this.AnalisisPM.anexo.pasivo.documentos_pagar._2022
    this.AnalisisPM.anexo.pasivo.iva_trasladar.var_absoluta_22_23 = this.AnalisisPM.anexo.pasivo.iva_trasladar._2023 - this.AnalisisPM.anexo.pasivo.iva_trasladar._2022
    this.AnalisisPM.anexo.pasivo.provisiones.var_absoluta_22_23 = this.AnalisisPM.anexo.pasivo.provisiones._2023 - this.AnalisisPM.anexo.pasivo.provisiones._2022
    this.AnalisisPM.anexo.pasivo.intereses.var_absoluta_22_23 = this.AnalisisPM.anexo.pasivo.intereses._2023 - this.AnalisisPM.anexo.pasivo.intereses._2022
    this.AnalisisPM.anexo.pasivo.otras_cuentas.var_absoluta_22_23 = Number(this.AnalisisPM.anexo.pasivo.otras_cuentas._2023 ) - Number(this.AnalisisPM.anexo.pasivo.otras_cuentas._2022)
    
    console.log("OTRAS CUENTAS", this.AnalisisPM.anexo.pasivo.otras_cuentas.var_absoluta_22_23, typeof this.AnalisisPM.anexo.pasivo.otras_cuentas._2023, typeof this.AnalisisPM.anexo.pasivo.otras_cuentas._2022)

    this.AnalisisPM.anexo.pasivo.total_pasivo_circulante.var_absoluta_22_23 = this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2023 - this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2022
    this.AnalisisPM.anexo.pasivo.otros_pasivos_lg.var_absoluta_22_23 = this.AnalisisPM.anexo.pasivo.otros_pasivos_lg_2023 - this.AnalisisPM.anexo.pasivo.otros_pasivos_lg._2022
    this.AnalisisPM.anexo.pasivo.total_pasivos_cir_lg.var_absoluta_22_23 = this.AnalisisPM.anexo.pasivo.total_pasivos_cir_lg._2023 - this.AnalisisPM.anexo.pasivo.total_pasivos_cir_lg._2022

    this.AnalisisPM.anexo.pasivo.proveedores.var_absoluta_23_24 = this.AnalisisPM.anexo.pasivo.proveedores._2024 - this.AnalisisPM.anexo.pasivo.proveedores._2023
    this.AnalisisPM.anexo.pasivo.acreedores.var_absoluta_23_24 = this.AnalisisPM.anexo.pasivo.acreedores._2024 - this.AnalisisPM.anexo.pasivo.acreedores._2023
    this.AnalisisPM.anexo.pasivo.impuestos_pagar.var_absoluta_23_24 = this.AnalisisPM.anexo.pasivo.impuestos_pagar._2024 - this.AnalisisPM.anexo.pasivo.impuestos_pagar._2023
    this.AnalisisPM.anexo.pasivo.documentos_pagar.var_absoluta_23_24 = this.AnalisisPM.anexo.pasivo.documentos_pagar._2024 - this.AnalisisPM.anexo.pasivo.documentos_pagar._2023
    this.AnalisisPM.anexo.pasivo.iva_trasladar.var_absoluta_23_24 = this.AnalisisPM.anexo.pasivo.iva_trasladar._2024 - this.AnalisisPM.anexo.pasivo.iva_trasladar._2023
    this.AnalisisPM.anexo.pasivo.provisiones.var_absoluta_23_24 = this.AnalisisPM.anexo.pasivo.provisiones._2024 - this.AnalisisPM.anexo.pasivo.provisiones._2023
    this.AnalisisPM.anexo.pasivo.intereses.var_absoluta_23_24 = this.AnalisisPM.anexo.pasivo.intereses._2024 - this.AnalisisPM.anexo.pasivo.intereses._2023
    this.AnalisisPM.anexo.pasivo.otras_cuentas.var_absoluta_23_24 = this.AnalisisPM.anexo.pasivo.otras_cuentas._2024 - this.AnalisisPM.anexo.pasivo.otras_cuentas._2023

    this.AnalisisPM.anexo.pasivo.total_pasivo_circulante.var_absoluta_23_24 = this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2024 - this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2023
    this.AnalisisPM.anexo.pasivo.otros_pasivos_lg.var_absoluta_23_24 = this.AnalisisPM.anexo.pasivo.otros_pasivos_lg._2024 - this.AnalisisPM.anexo.pasivo.otros_pasivos_lg._2023
    this.AnalisisPM.anexo.pasivo.total_pasivos_cir_lg.var_absoluta_23_24 = this.AnalisisPM.anexo.pasivo.total_pasivos_cir_lg._2024 - this.AnalisisPM.anexo.pasivo.total_pasivos_cir_lg._2023

    
  }
  calcularAnalisisHorizontalPasivoPorcentual(){
    this.AnalisisPM.anexo.pasivo.proveedores.var_porcentual_22_23  = Math.round(((this.AnalisisPM.anexo.pasivo.proveedores._2023 / this.AnalisisPM.anexo.pasivo.proveedores._2022) - 1) * 100);
    this.AnalisisPM.anexo.pasivo.acreedores.var_porcentual_22_23  = Math.round(((this.AnalisisPM.anexo.pasivo.acreedores._2023 / this.AnalisisPM.anexo.pasivo.acreedores._2022) - 1) * 100);
    this.AnalisisPM.anexo.pasivo.impuestos_pagar.var_porcentual_22_23  = Math.round(((this.AnalisisPM.anexo.pasivo.impuestos_pagar._2023 / this.AnalisisPM.anexo.pasivo.impuestos_pagar._2022) - 1) * 100);
    this.AnalisisPM.anexo.pasivo.documentos_pagar.var_porcentual_22_23  = Math.round(((this.AnalisisPM.anexo.pasivo.documentos_pagar._2023 / this.AnalisisPM.anexo.pasivo.documentos_pagar._2022) - 1) * 100);
    this.AnalisisPM.anexo.pasivo.iva_trasladar.var_porcentual_22_23  = Math.round(((this.AnalisisPM.anexo.pasivo.iva_trasladar._2023 / this.AnalisisPM.anexo.pasivo.iva_trasladar._2022) - 1) * 100);
    this.AnalisisPM.anexo.pasivo.provisiones.var_porcentual_22_23  = Math.round(((this.AnalisisPM.anexo.pasivo.provisiones._2023 / this.AnalisisPM.anexo.pasivo.provisiones._2022) - 1) * 100);
    this.AnalisisPM.anexo.pasivo.intereses.var_porcentual_22_23  = Math.round(((this.AnalisisPM.anexo.pasivo.intereses._2023 / this.AnalisisPM.anexo.pasivo.intereses._2022) - 1) * 100);
    this.AnalisisPM.anexo.pasivo.otras_cuentas.var_porcentual_22_23  = Math.round(((this.AnalisisPM.anexo.pasivo.otras_cuentas._2023 / this.AnalisisPM.anexo.pasivo.otras_cuentas._2022) - 1) * 100);
    
    this.AnalisisPM.anexo.pasivo.total_pasivo_circulante.var_porcentual_22_23= Math.round(((this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2023 / this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2022) - 1) * 100);
    this.AnalisisPM.anexo.pasivo.otros_pasivos_lg.var_porcentual_22_23 = Math.round(((this.AnalisisPM.anexo.pasivo.otros_pasivos_lg_2023 / this.AnalisisPM.anexo.pasivo.otros_pasivos_lg._2022) - 1) * .100);
    this.AnalisisPM.anexo.pasivo.total_pasivos_cir_lg.var_porcentual_22_23 = Math.round(((this.AnalisisPM.anexo.pasivo.total_pasivos_cir_lg._2023 / this.AnalisisPM.anexo.pasivo.total_pasivos_cir_lg._2022) - 1) * 100);


    this.AnalisisPM.anexo.pasivo.proveedores.var_porcentual_23_24  = Math.round(((this.AnalisisPM.anexo.pasivo.proveedores._2024 / this.AnalisisPM.anexo.pasivo.proveedores._2023) - 1) * 100);
    this.AnalisisPM.anexo.pasivo.acreedores.var_porcentual_23_24  = Math.round(((this.AnalisisPM.anexo.pasivo.acreedores._2024 / this.AnalisisPM.anexo.pasivo.acreedores._2023) - 1) * 100);
    this.AnalisisPM.anexo.pasivo.impuestos_pagar.var_porcentual_23_24  = Math.round(((this.AnalisisPM.anexo.pasivo.impuestos_pagar._2024 / this.AnalisisPM.anexo.pasivo.impuestos_pagar._2023) - 1) * 100);
    this.AnalisisPM.anexo.pasivo.documentos_pagar.var_porcentual_23_24  = Math.round(((this.AnalisisPM.anexo.pasivo.documentos_pagar._2024 / this.AnalisisPM.anexo.pasivo.documentos_pagar._2023) - 1) * 100);
    this.AnalisisPM.anexo.pasivo.iva_trasladar.var_porcentual_23_24  = Math.round(((this.AnalisisPM.anexo.pasivo.iva_trasladar._2024 / this.AnalisisPM.anexo.pasivo.iva_trasladar._2023) - 1) * 100);
    this.AnalisisPM.anexo.pasivo.provisiones.var_porcentual_23_24  = Math.round(((this.AnalisisPM.anexo.pasivo.provisiones._2024 / this.AnalisisPM.anexo.pasivo.provisiones._2023) - 1) * 100);
    this.AnalisisPM.anexo.pasivo.intereses.var_porcentual_23_24  = Math.round(((this.AnalisisPM.anexo.pasivo.intereses._2024 / this.AnalisisPM.anexo.pasivo.intereses._2023) - 1) * 100);
    this.AnalisisPM.anexo.pasivo.otras_cuentas.var_porcentual_23_24  = Math.round(((this.AnalisisPM.anexo.pasivo.otras_cuentas._2024 / this.AnalisisPM.anexo.pasivo.otras_cuentas._2023) - 1) * 100);
    
    this.AnalisisPM.anexo.pasivo.total_pasivo_circulante.var_porcentual_23_24 = Math.round(((this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2024 / this.AnalisisPM.anexo.pasivo.total_pasivo_circulante._2023) - 1) * 100);
    this.AnalisisPM.anexo.pasivo.otros_pasivos_lg.var_porcentual_23_24 = Math.round(((this.AnalisisPM.anexo.pasivo.otros_pasivos_lg._2024 / this.AnalisisPM.anexo.pasivo.otros_pasivos_lg_2023) - 1) * .100);
    this.AnalisisPM.anexo.pasivo.total_pasivos_cir_lg.var_porcentual_23_24 = Math.round(((this.AnalisisPM.anexo.pasivo.total_pasivos_cir_lg._2024 / this.AnalisisPM.anexo.pasivo.total_pasivos_cir_lg._2023) - 1) * 100);

  }

  //--------
  recalcularTotalesCapital(){
    this.calcularTotalesCapital();
  }

  calcularTotalesCapital() {
    this.AnalisisPM.anexo.capital.total_capital._2022 = this.sumarTotales(
      parseFloat(this.AnalisisPM.anexo.capital.capital_social._2022),
      parseFloat(this.AnalisisPM.anexo.capital.apor_futuros_aum_cap._2022),
      parseFloat(this.AnalisisPM.anexo.capital.reserva_legal._2022),
      parseFloat(this.AnalisisPM.anexo.capital.resultados_acumulados._2022),
      parseFloat(this.AnalisisPM.anexo.capital.resultado_del_ejercicio._2022),
      parseFloat(this.AnalisisPM.anexo.capital.otras_cuentas._2022),
      
    );
    this.AnalisisPM.anexo.capital.total_capital._2023 = this.sumarTotales(
      parseFloat(this.AnalisisPM.anexo.capital.capital_social._2023),
      parseFloat(this.AnalisisPM.anexo.capital.apor_futuros_aum_cap._2023),
      parseFloat(this.AnalisisPM.anexo.capital.reserva_legal._2023),
      parseFloat(this.AnalisisPM.anexo.capital.resultados_acumulados._2023),
      parseFloat(this.AnalisisPM.anexo.capital.resultado_del_ejercicio._2023),
      parseFloat(this.AnalisisPM.anexo.capital.otras_cuentas._2023),
    );
    this.AnalisisPM.anexo.capital.total_capital._2024 = this.sumarTotales(
      parseFloat(this.AnalisisPM.anexo.capital.capital_social._2024),
      parseFloat(this.AnalisisPM.anexo.capital.apor_futuros_aum_cap._2024),
      parseFloat(this.AnalisisPM.anexo.capital.reserva_legal._2024),
      parseFloat(this.AnalisisPM.anexo.capital.resultados_acumulados._2024),
      parseFloat(this.AnalisisPM.anexo.capital.resultado_del_ejercicio._2024),
      parseFloat(this.AnalisisPM.anexo.capital.otras_cuentas._2024),
    );

      this.AnalisisPM.anexo.capital.capital_social.porcentaje_2022 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.capital.capital_social._2022), this.AnalisisPM.anexo.capital.total_capital._2022)
      this.AnalisisPM.anexo.capital.apor_futuros_aum_cap.porcentaje_2022 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.capital.apor_futuros_aum_cap._2022), this.AnalisisPM.anexo.capital.total_capital._2022)
      this.AnalisisPM.anexo.capital.reserva_legal.porcentaje_2022 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.capital.reserva_legal._2022), this.AnalisisPM.anexo.capital.total_capital._2022)
      this.AnalisisPM.anexo.capital.resultados_acumulados.porcentaje_2022 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.capital.resultados_acumulados._2022), this.AnalisisPM.anexo.capital.total_capital._2022)
      this.AnalisisPM.anexo.capital.resultado_del_ejercicio.porcentaje_2022 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.capital.resultado_del_ejercicio._2022), this.AnalisisPM.anexo.capital.total_capital._2022)
      this.AnalisisPM.anexo.capital.otras_cuentas.porcentaje_2022 =  this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.capital.otras_cuentas._2022), this.AnalisisPM.anexo.capital.total_capital._2022)
      
      this.AnalisisPM.anexo.capital.total_capital.porcentaje_2022 = this.sumarTotales(
        parseFloat(this.AnalisisPM.anexo.capital.capital_social.porcentaje_2022),
        parseFloat(this.AnalisisPM.anexo.capital.apor_futuros_aum_cap.porcentaje_2022),
        parseFloat(this.AnalisisPM.anexo.capital.reserva_legal.porcentaje_2022),
        parseFloat(this.AnalisisPM.anexo.capital.resultados_acumulados.porcentaje_2022),
        parseFloat(this.AnalisisPM.anexo.capital.resultado_del_ejercicio.porcentaje_2022),
        parseFloat(this.AnalisisPM.anexo.capital.otras_cuentas.porcentaje_2022),
        
      )

      this.AnalisisPM.anexo.capital.capital_social.porcentaje_2023 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.capital.capital_social._2023), this.AnalisisPM.anexo.capital.total_capital._2023)
      this.AnalisisPM.anexo.capital.apor_futuros_aum_cap.porcentaje_2023 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.capital.apor_futuros_aum_cap._2023), this.AnalisisPM.anexo.capital.total_capital._2023)
      this.AnalisisPM.anexo.capital.reserva_legal.porcentaje_2023 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.capital.reserva_legal._2023), this.AnalisisPM.anexo.capital.total_capital._2023)
      this.AnalisisPM.anexo.capital.resultados_acumulados.porcentaje_2023 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.capital.resultados_acumulados._2023), this.AnalisisPM.anexo.capital.total_capital._2023)
      this.AnalisisPM.anexo.capital.resultado_del_ejercicio.porcentaje_2023 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.capital.resultado_del_ejercicio._2023), this.AnalisisPM.anexo.capital.total_capital._2023)
      this.AnalisisPM.anexo.capital.otras_cuentas.porcentaje_2023 =  this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.capital.otras_cuentas._2023), this.AnalisisPM.anexo.capital.total_capital._2023)

      this.AnalisisPM.anexo.capital.total_capital.porcentaje_2023= this.sumarTotales(
        parseFloat(this.AnalisisPM.anexo.capital.capital_social.porcentaje_2023),
        parseFloat(this.AnalisisPM.anexo.capital.apor_futuros_aum_cap.porcentaje_2023),
        parseFloat(this.AnalisisPM.anexo.capital.reserva_legal.porcentaje_2023),
        parseFloat(this.AnalisisPM.anexo.capital.resultados_acumulados.porcentaje_2023),
        parseFloat(this.AnalisisPM.anexo.capital.resultado_del_ejercicio.porcentaje_2023),
        parseFloat(this.AnalisisPM.anexo.capital.otras_cuentas.porcentaje_2023),
        
      )
      this.AnalisisPM.anexo.capital.capital_social.porcentaje_2024 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.capital.capital_social._2024), this.AnalisisPM.anexo.capital.total_capital._2024)
      this.AnalisisPM.anexo.capital.apor_futuros_aum_cap.porcentaje_2024 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.capital.apor_futuros_aum_cap._2024), this.AnalisisPM.anexo.capital.total_capital._2024)
      this.AnalisisPM.anexo.capital.reserva_legal.porcentaje_2024 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.capital.reserva_legal._2024), this.AnalisisPM.anexo.capital.total_capital._2024)
      this.AnalisisPM.anexo.capital.resultados_acumulados.porcentaje_2024 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.capital.resultados_acumulados._2024), this.AnalisisPM.anexo.capital.total_capital._2024)
      this.AnalisisPM.anexo.capital.resultado_del_ejercicio.porcentaje_2024 = this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.capital.resultado_del_ejercicio._2024), this.AnalisisPM.anexo.capital.total_capital._2024)
      this.AnalisisPM.anexo.capital.otras_cuentas.porcentaje_2024 =  this.calcularPorcentaje(Math.round(this.AnalisisPM.anexo.capital.otras_cuentas._2024), this.AnalisisPM.anexo.capital.total_capital._2024)      

      this.AnalisisPM.anexo.capital.total_capital.porcentaje_2024 = this.sumarTotales(
        parseFloat(this.AnalisisPM.anexo.capital.capital_social.porcentaje_2024),
        parseFloat(this.AnalisisPM.anexo.capital.apor_futuros_aum_cap.porcentaje_2024),
        parseFloat(this.AnalisisPM.anexo.capital.reserva_legal.porcentaje_2024),
        parseFloat(this.AnalisisPM.anexo.capital.resultados_acumulados.porcentaje_2024),
        parseFloat(this.AnalisisPM.anexo.capital.resultado_del_ejercicio.porcentaje_2024),
        parseFloat(this.AnalisisPM.anexo.capital.otras_cuentas.porcentaje_2024),
      )

      this.calcularTotalesPasivoCapital();

      this.calcularAnalisisHorizontalCapitalAbsoluto();
      this.calcularAnalisisHorizontalCapitalPorcentual();
  }
  calcularAnalisisHorizontalCapitalAbsoluto(){
    this.AnalisisPM.anexo.capital.capital_social.var_absoluta_22_23 = this.AnalisisPM.anexo.capital.capital_social._2023 - this.AnalisisPM.anexo.capital.capital_social._2022
    this.AnalisisPM.anexo.capital.apor_futuros_aum_cap.var_absoluta_22_23 = this.AnalisisPM.anexo.capital.apor_futuros_aum_cap._2023 - this.AnalisisPM.anexo.capital.apor_futuros_aum_cap._2022
    this.AnalisisPM.anexo.capital.reserva_legal.var_absoluta_22_23 = this.AnalisisPM.anexo.capital.reserva_legal._2023 - this.AnalisisPM.anexo.capital.reserva_legal._2022
    this.AnalisisPM.anexo.capital.resultados_acumulados.var_absoluta_22_23 = this.AnalisisPM.anexo.capital.resultados_acumulados._2023 - this.AnalisisPM.anexo.capital.resultados_acumulados._2022
    this.AnalisisPM.anexo.capital.resultado_del_ejercicio.var_absoluta_22_23 = this.AnalisisPM.anexo.capital.resultado_del_ejercicio._2023 - this.AnalisisPM.anexo.capital.resultado_del_ejercicio._2022
    this.AnalisisPM.anexo.capital.otras_cuentas.var_absoluta_22_23 = this.AnalisisPM.anexo.capital.otras_cuentas._2023 - this.AnalisisPM.anexo.capital.otras_cuentas._2022
    this.calcularTotalesPasivoCapital();
    this.AnalisisPM.anexo.capital.total_capital.var_absoluta_22_23 = this.AnalisisPM.anexo.capital.total_capital._2023 - this.AnalisisPM.anexo.capital.total_capital._2022
    this.AnalisisPM.anexo.total_pasivo_capital.var_absoluta_22_23 = this.AnalisisPM.anexo.total_pasivo_capital._2023 - this.AnalisisPM.anexo.total_pasivo_capital._2022
    
    this.AnalisisPM.anexo.capital.capital_social.var_absoluta_23_24 = this.AnalisisPM.anexo.capital.capital_social._2024 - this.AnalisisPM.anexo.capital.capital_social._2023
    this.AnalisisPM.anexo.capital.apor_futuros_aum_cap.var_absoluta_23_24 = this.AnalisisPM.anexo.capital.apor_futuros_aum_cap._2024 - this.AnalisisPM.anexo.capital.apor_futuros_aum_cap._2023
    this.AnalisisPM.anexo.capital.reserva_legal.var_absoluta_23_24 = this.AnalisisPM.anexo.capital.reserva_legal._2024 - this.AnalisisPM.anexo.capital.reserva_legal._2023
    this.AnalisisPM.anexo.capital.resultados_acumulados.var_absoluta_23_24 = this.AnalisisPM.anexo.capital.resultados_acumulados._2024 - this.AnalisisPM.anexo.capital.resultados_acumulados._2023
    this.AnalisisPM.anexo.capital.resultado_del_ejercicio.var_absoluta_23_24 = this.AnalisisPM.anexo.capital.resultado_del_ejercicio._2024 - this.AnalisisPM.anexo.capital.resultado_del_ejercicio._2023
    this.AnalisisPM.anexo.capital.otras_cuentas.var_absoluta_23_24 = this.AnalisisPM.anexo.capital.otras_cuentas._2024 - this.AnalisisPM.anexo.capital.otras_cuentas._2023
    
    this.AnalisisPM.anexo.capital.total_capital.var_absoluta_23_24 = this.AnalisisPM.anexo.capital.total_capital._2024 - this.AnalisisPM.anexo.capital.total_capital._2023
    this.AnalisisPM.anexo.total_pasivo_capital.var_absoluta_23_24 = this.AnalisisPM.anexo.total_pasivo_capital._2024 - this.AnalisisPM.anexo.total_pasivo_capital._2023


    
  }
  calcularAnalisisHorizontalCapitalPorcentual(){
    this.AnalisisPM.anexo.capital.capital_social.var_porcentual_22_23  = Math.round(((this.AnalisisPM.anexo.capital.capital_social._2023 / this.AnalisisPM.anexo.capital.capital_social._2022) - 1) * 100);
    this.AnalisisPM.anexo.capital.apor_futuros_aum_cap.var_porcentual_22_23  = Math.round(((this.AnalisisPM.anexo.capital.apor_futuros_aum_cap._2023 / this.AnalisisPM.anexo.capital.apor_futuros_aum_cap._2022) - 1) * 100);
    this.AnalisisPM.anexo.capital.reserva_legal.var_porcentual_22_23  = Math.round(((this.AnalisisPM.anexo.capital.reserva_legal._2023 / this.AnalisisPM.anexo.capital.reserva_legal._2022) - 1) * 100);
    this.AnalisisPM.anexo.capital.resultados_acumulados.var_porcentual_22_23  = Math.round(((this.AnalisisPM.anexo.capital.resultados_acumulados._2023 / this.AnalisisPM.anexo.capital.resultados_acumulados._2022) - 1) * 100);
    this.AnalisisPM.anexo.capital.resultado_del_ejercicio.var_porcentual_22_23  = Math.round(((this.AnalisisPM.anexo.capital.resultado_del_ejercicio._2023 / this.AnalisisPM.anexo.capital.resultado_del_ejercicio._2022) - 1) * 100);
    this.AnalisisPM.anexo.capital.otras_cuentas.var_porcentual_22_23  = Math.round(((this.AnalisisPM.anexo.capital.otras_cuentas._2023 / this.AnalisisPM.anexo.capital.otras_cuentas._2022) - 1) * 100);

    this.AnalisisPM.anexo.capital.total_capital.var_porcentual_22_23 = Math.round(((this.AnalisisPM.anexo.capital.total_capital._2023 / this.AnalisisPM.anexo.capital.total_capital._2022) - 1) * 100);
    this.AnalisisPM.anexo.total_pasivo_capital.var_porcentual_22_23 = Math.round(((this.AnalisisPM.anexo.total_pasivo_capital._2023 / this.AnalisisPM.anexo.total_pasivo_capital._2022) - 1) * 100);

    
    this.AnalisisPM.anexo.capital.capital_social.var_porcentual_23_24  = Math.round(((this.AnalisisPM.anexo.capital.capital_social._2024 / this.AnalisisPM.anexo.capital.capital_social._2023) - 1) * 100);
    this.AnalisisPM.anexo.capital.apor_futuros_aum_cap.var_porcentual_23_24  = Math.round(((this.AnalisisPM.anexo.capital.apor_futuros_aum_cap._2024 / this.AnalisisPM.anexo.capital.apor_futuros_aum_cap._2023) - 1) * 100);
    this.AnalisisPM.anexo.capital.reserva_legal.var_porcentual_23_24  = Math.round(((this.AnalisisPM.anexo.capital.reserva_legal._2024 / this.AnalisisPM.anexo.capital.reserva_legal._2023) - 1) * 100);
    this.AnalisisPM.anexo.capital.resultados_acumulados.var_porcentual_23_24  = Math.round(((this.AnalisisPM.anexo.capital.resultados_acumulados._2024 / this.AnalisisPM.anexo.capital.resultados_acumulados._2023) - 1) * 100);
    this.AnalisisPM.anexo.capital.resultado_del_ejercicio.var_porcentual_23_24  = Math.round(((this.AnalisisPM.anexo.capital.resultado_del_ejercicio._2024 / this.AnalisisPM.anexo.capital.resultado_del_ejercicio._2023) - 1) * 100);
    this.AnalisisPM.anexo.capital.otras_cuentas.var_porcentual_23_24  = Math.round(((this.AnalisisPM.anexo.capital.otras_cuentas._2024 / this.AnalisisPM.anexo.capital.otras_cuentas._2023) - 1) * 100);
    
    this.AnalisisPM.anexo.capital.total_capital.var_porcentual_23_24 = Math.round(((this.AnalisisPM.anexo.capital.total_capital._2024 / this.AnalisisPM.anexo.capital.total_capital._2023) - 1) * 100);
    this.AnalisisPM.anexo.total_pasivo_capital.var_porcentual_23_24 = Math.round(((this.AnalisisPM.anexo.total_pasivo_capital._2024 / this.AnalisisPM.anexo.total_pasivo_capital._2023) - 1) * 100);

    
    
  }

  calcularTotalesPasivoCapital(){
    this.AnalisisPM.anexo.total_pasivo_capital._2022  = this.AnalisisPM.anexo.pasivo.total_pasivos_cir_lg._2022 + this.AnalisisPM.anexo.capital.total_capital._2022 ?? 0
    this.AnalisisPM.anexo.total_pasivo_capital._2023  = this.AnalisisPM.anexo.pasivo.total_pasivos_cir_lg._2023 + this.AnalisisPM.anexo.capital.total_capital._2023
    this.AnalisisPM.anexo.total_pasivo_capital._2024  = this.AnalisisPM.anexo.pasivo.total_pasivos_cir_lg._2024 + this.AnalisisPM.anexo.capital.total_capital._2024

    console.log("PASIVOCAPITAL", this.AnalisisPM.anexo.total_pasivo_capital._2022, this.AnalisisPM.anexo.pasivo.total_pasivos_cir_lg._2022 , this.AnalisisPM.anexo.capital.total_capital._2022)

    this.AnalisisPM.anexo.total_pasivo_capital.porcentaje_2022 = this.sumarTotales(this.AnalisisPM.anexo.capital.apor_futuros_aum_cap.porcentaje_2022,this.AnalisisPM.anexo.capital.reserva_legal.porcentaje_2022, this.AnalisisPM.anexo.capital.resultados_acumulados.porcentaje_2022, this.AnalisisPM.anexo.capital.resultado_del_ejercicio.porcentaje_2022, this.AnalisisPM.anexo.capital.otras_cuentas.porcentaje_2022);
    this.AnalisisPM.anexo.total_pasivo_capital.porcentaje_2023 = this.calcularPorcentaje(this.AnalisisPM.anexo.total_pasivo_capital._2023,this.AnalisisPM.anexo.capital.total_capital._2023);
    this.AnalisisPM.anexo.total_pasivo_capital.porcentaje_2024 = this.calcularPorcentaje(this.AnalisisPM.anexo.total_pasivo_capital._2024,this.AnalisisPM.anexo.capital.total_capital._2024);

  }

  reCalcularUtilidades(){
    this.calcularUtilidadesResultados();
  }

  calcularUtilidadesResultados(){
    this.AnalisisPM.anexo.estado_resultados.utilidad_bruta._2022  = this.AnalisisPM.anexo.estado_resultados.ventas._2022 - this.AnalisisPM.anexo.estado_resultados.costo_ventas._2022;
    this.AnalisisPM.anexo.estado_resultados.utilidad_bruta._2023  = this.AnalisisPM.anexo.estado_resultados.ventas._2023 - this.AnalisisPM.anexo.estado_resultados.costo_ventas._2023;
    this.AnalisisPM.anexo.estado_resultados.utilidad_bruta._2024  = this.AnalisisPM.anexo.estado_resultados.ventas._2024 - this.AnalisisPM.anexo.estado_resultados.costo_ventas._2024;

    this.AnalisisPM.anexo.estado_resultados.utilidad_operativa._2022 = this.AnalisisPM.anexo.estado_resultados.utilidad_bruta._2022 - this.AnalisisPM.anexo.estado_resultados.gastos_operacion._2022;
    this.AnalisisPM.anexo.estado_resultados.utilidad_operativa._2023 = this.AnalisisPM.anexo.estado_resultados.utilidad_bruta._2023 - this.AnalisisPM.anexo.estado_resultados.gastos_operacion._2023;
    this.AnalisisPM.anexo.estado_resultados.utilidad_operativa._2024 = this.AnalisisPM.anexo.estado_resultados.utilidad_bruta._2024 - this.AnalisisPM.anexo.estado_resultados.gastos_operacion._2024;

    this.AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos._2022 = Number(this.AnalisisPM.anexo.estado_resultados.utilidad_operativa._2022) - Number(this.AnalisisPM.anexo.estado_resultados.gastos_financieros._2022) + Number(this.AnalisisPM.anexo.estado_resultados.productos_financieros._2022 )
    this.AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos._2023 = Number(this.AnalisisPM.anexo.estado_resultados.utilidad_operativa._2023) - Number(this.AnalisisPM.anexo.estado_resultados.gastos_financieros._2023) + Number(this.AnalisisPM.anexo.estado_resultados.productos_financieros._2023 )
    this.AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos._2024 = Number(this.AnalisisPM.anexo.estado_resultados.utilidad_operativa._2024) - Number(this.AnalisisPM.anexo.estado_resultados.gastos_financieros._2024) + Number(this.AnalisisPM.anexo.estado_resultados.productos_financieros._2024 )

    

    this.AnalisisPM.anexo.estado_resultados.utilidad_neta._2022 = this.AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos._2022 - this.AnalisisPM.anexo.estado_resultados.impuestos._2022
    this.AnalisisPM.anexo.estado_resultados.utilidad_neta._2023 = this.AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos._2023 - this.AnalisisPM.anexo.estado_resultados.impuestos._2023
    this.AnalisisPM.anexo.estado_resultados.utilidad_neta._2024 = this.AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos._2024 - this.AnalisisPM.anexo.estado_resultados.impuestos._2024
    console.log("CALCULAR UTILIDADES neta", this.AnalisisPM.anexo.estado_resultados.utilidad_neta._2022 )
    this.AnalisisPM.anexo.estado_resultados.costo_ventas.porcentaje_2022 = this.calcularPorcentaje(this.AnalisisPM.anexo.estado_resultados.costo_ventas._2022, this.AnalisisPM.anexo.estado_resultados.ventas._2022)
    this.AnalisisPM.anexo.estado_resultados.costo_ventas.porcentaje_2023 = this.calcularPorcentaje(this.AnalisisPM.anexo.estado_resultados.costo_ventas._2023, this.AnalisisPM.anexo.estado_resultados.ventas._2023)
    this.AnalisisPM.anexo.estado_resultados.costo_ventas.porcentaje_2024 = this.calcularPorcentaje(this.AnalisisPM.anexo.estado_resultados.costo_ventas._2024, this.AnalisisPM.anexo.estado_resultados.ventas._2024)

    this.AnalisisPM.anexo.estado_resultados.utilidad_bruta.porcentaje_2022 = this.calcularPorcentaje(this.AnalisisPM.anexo.estado_resultados.utilidad_bruta._2022, this.AnalisisPM.anexo.estado_resultados.ventas._2022)
    this.AnalisisPM.anexo.estado_resultados.utilidad_bruta.porcentaje_2023 = this.calcularPorcentaje(this.AnalisisPM.anexo.estado_resultados.utilidad_bruta._2023, this.AnalisisPM.anexo.estado_resultados.ventas._2023)
    this.AnalisisPM.anexo.estado_resultados.utilidad_bruta.porcentaje_2024 = this.calcularPorcentaje(this.AnalisisPM.anexo.estado_resultados.utilidad_bruta._2024, this.AnalisisPM.anexo.estado_resultados.ventas._2024)

    this.AnalisisPM.anexo.estado_resultados.gastos_operacion.porcentaje_2022 = this.calcularPorcentaje(this.AnalisisPM.anexo.estado_resultados.gastos_operacion._2022, this.AnalisisPM.anexo.estado_resultados.ventas._2022)
    this.AnalisisPM.anexo.estado_resultados.gastos_operacion.porcentaje_2023 = this.calcularPorcentaje(this.AnalisisPM.anexo.estado_resultados.gastos_operacion._2023, this.AnalisisPM.anexo.estado_resultados.ventas._2023)
    this.AnalisisPM.anexo.estado_resultados.gastos_operacion.porcentaje_2024 = this.calcularPorcentaje(this.AnalisisPM.anexo.estado_resultados.gastos_operacion._2024, this.AnalisisPM.anexo.estado_resultados.ventas._2024)    

    this.AnalisisPM.anexo.estado_resultados.utilidad_operativa.porcentaje_2022 = this.calcularPorcentaje(this.AnalisisPM.anexo.estado_resultados.utilidad_operativa._2022, this.AnalisisPM.anexo.estado_resultados.ventas._2022)
    this.AnalisisPM.anexo.estado_resultados.utilidad_operativa.porcentaje_2023 = this.calcularPorcentaje(this.AnalisisPM.anexo.estado_resultados.utilidad_operativa._2023, this.AnalisisPM.anexo.estado_resultados.ventas._2023)
    this.AnalisisPM.anexo.estado_resultados.utilidad_operativa.porcentaje_2024 = this.calcularPorcentaje(this.AnalisisPM.anexo.estado_resultados.utilidad_operativa._2024, this.AnalisisPM.anexo.estado_resultados.ventas._2024)

    this.AnalisisPM.anexo.estado_resultados.gastos_financieros.porcentaje_2022 = this.calcularPorcentaje(this.AnalisisPM.anexo.estado_resultados.gastos_financieros._2022, this.AnalisisPM.anexo.estado_resultados.ventas._2022)
    this.AnalisisPM.anexo.estado_resultados.gastos_financieros.porcentaje_2023 = this.calcularPorcentaje(this.AnalisisPM.anexo.estado_resultados.gastos_financieros._2023, this.AnalisisPM.anexo.estado_resultados.ventas._2023)
    this.AnalisisPM.anexo.estado_resultados.gastos_financieros.porcentaje_2024 = this.calcularPorcentaje(this.AnalisisPM.anexo.estado_resultados.gastos_financieros._2024, this.AnalisisPM.anexo.estado_resultados.ventas._2024)    

    this.AnalisisPM.anexo.estado_resultados.productos_financieros.porcentaje_2022 = this.calcularPorcentaje(this.AnalisisPM.anexo.estado_resultados.productos_financieros._2022, this.AnalisisPM.anexo.estado_resultados.ventas._2022)
    this.AnalisisPM.anexo.estado_resultados.productos_financieros.porcentaje_2023 = this.calcularPorcentaje(this.AnalisisPM.anexo.estado_resultados.productos_financieros._2023, this.AnalisisPM.anexo.estado_resultados.ventas._2023)
    this.AnalisisPM.anexo.estado_resultados.productos_financieros.porcentaje_2024 = this.calcularPorcentaje(this.AnalisisPM.anexo.estado_resultados.productos_financieros._2024, this.AnalisisPM.anexo.estado_resultados.ventas._2024)    

    this.AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos.porcentaje_2022 = this.calcularPorcentaje(this.AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos._2022, this.AnalisisPM.anexo.estado_resultados.ventas._2022)
    this.AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos.porcentaje_2023 = this.calcularPorcentaje(this.AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos._2023, this.AnalisisPM.anexo.estado_resultados.ventas._2023)
    this.AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos.porcentaje_2024 = this.calcularPorcentaje(this.AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos._2024, this.AnalisisPM.anexo.estado_resultados.ventas._2024)

    this.AnalisisPM.anexo.estado_resultados.impuestos.porcentaje_2022 = this.calcularPorcentaje(this.AnalisisPM.anexo.estado_resultados.impuestos._2022, this.AnalisisPM.anexo.estado_resultados.ventas._2022)
    this.AnalisisPM.anexo.estado_resultados.impuestos.porcentaje_2023 = this.calcularPorcentaje(this.AnalisisPM.anexo.estado_resultados.impuestos._2023, this.AnalisisPM.anexo.estado_resultados.ventas._2023)
    this.AnalisisPM.anexo.estado_resultados.impuestos.porcentaje_2024 = this.calcularPorcentaje(this.AnalisisPM.anexo.estado_resultados.impuestos._2024, this.AnalisisPM.anexo.estado_resultados.ventas._2024)    

    this.AnalisisPM.anexo.estado_resultados.utilidad_neta.porcentaje_2022 = this.calcularPorcentaje(this.AnalisisPM.anexo.estado_resultados.utilidad_neta._2022, this.AnalisisPM.anexo.estado_resultados.ventas._2022)
    this.AnalisisPM.anexo.estado_resultados.utilidad_neta.porcentaje_2023 = this.calcularPorcentaje(this.AnalisisPM.anexo.estado_resultados.utilidad_neta._2023, this.AnalisisPM.anexo.estado_resultados.ventas._2023)
    this.AnalisisPM.anexo.estado_resultados.utilidad_neta.porcentaje_2024 = this.calcularPorcentaje(this.AnalisisPM.anexo.estado_resultados.utilidad_neta._2024, this.AnalisisPM.anexo.estado_resultados.ventas._2024)

    this.calcularAnalisisHorizontalResultadosAbsoluto()
    this.calcularAnalisisHorizontalResultadosporcentual()
  }
  calcularAnalisisHorizontalResultadosAbsoluto(){
    this.AnalisisPM.anexo.estado_resultados.ventas.var_absoluta_22_23 = this.AnalisisPM.anexo.estado_resultados.ventas._2023 - this.AnalisisPM.anexo.estado_resultados.ventas._2022
    this.AnalisisPM.anexo.estado_resultados.costo_ventas.var_absoluta_22_23 = this.AnalisisPM.anexo.estado_resultados.costo_ventas._2023 - this.AnalisisPM.anexo.estado_resultados.costo_ventas._2022
    this.AnalisisPM.anexo.estado_resultados.utilidad_bruta.var_absoluta_22_23 = this.AnalisisPM.anexo.estado_resultados.utilidad_bruta._2023 - this.AnalisisPM.anexo.estado_resultados.utilidad_bruta._2022
    this.AnalisisPM.anexo.estado_resultados.gastos_operacion.var_absoluta_22_23 = this.AnalisisPM.anexo.estado_resultados.gastos_operacion._2023 - this.AnalisisPM.anexo.estado_resultados.gastos_operacion._2022
    this.AnalisisPM.anexo.estado_resultados.utilidad_operativa.var_absoluta_22_23 = this.AnalisisPM.anexo.estado_resultados.utilidad_operativa._2023 - this.AnalisisPM.anexo.estado_resultados.utilidad_operativa._2022
    this.AnalisisPM.anexo.estado_resultados.gastos_financieros.var_absoluta_22_23 = this.AnalisisPM.anexo.estado_resultados.gastos_financieros._2023 - this.AnalisisPM.anexo.estado_resultados.gastos_financieros._2022
    this.AnalisisPM.anexo.estado_resultados.productos_financieros.var_absoluta_22_23 = this.AnalisisPM.anexo.estado_resultados.productos_financieros._2023 - this.AnalisisPM.anexo.estado_resultados.productos_financieros._2022
    this.AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos.var_absoluta_22_23 = this.AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos._2023 - this.AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos._2022
    this.AnalisisPM.anexo.estado_resultados.impuestos.var_absoluta_22_23 = this.AnalisisPM.anexo.estado_resultados.impuestos._2023 - this.AnalisisPM.anexo.estado_resultados.impuestos._2022
    this.AnalisisPM.anexo.estado_resultados.utilidad_neta.var_absoluta_22_23 = this.AnalisisPM.anexo.estado_resultados.utilidad_neta._2023 - this.AnalisisPM.anexo.estado_resultados.utilidad_neta._2022

    this.AnalisisPM.anexo.estado_resultados.ventas.var_absoluta_23_24 = this.AnalisisPM.anexo.estado_resultados.ventas._2024 - this.AnalisisPM.anexo.estado_resultados.ventas._2023
    this.AnalisisPM.anexo.estado_resultados.costo_ventas.var_absoluta_23_24 = this.AnalisisPM.anexo.estado_resultados.costo_ventas._2024 - this.AnalisisPM.anexo.estado_resultados.costo_ventas._2023
    this.AnalisisPM.anexo.estado_resultados.utilidad_bruta.var_absoluta_23_24 = this.AnalisisPM.anexo.estado_resultados.utilidad_bruta._2024 - this.AnalisisPM.anexo.estado_resultados.utilidad_bruta._2023
    this.AnalisisPM.anexo.estado_resultados.gastos_operacion.var_absoluta_23_24 = this.AnalisisPM.anexo.estado_resultados.gastos_operacion._2024 - this.AnalisisPM.anexo.estado_resultados.gastos_operacion._2023
    this.AnalisisPM.anexo.estado_resultados.utilidad_operativa.var_absoluta_23_24 = this.AnalisisPM.anexo.estado_resultados.utilidad_operativa._2024 - this.AnalisisPM.anexo.estado_resultados.utilidad_operativa._2023
    this.AnalisisPM.anexo.estado_resultados.gastos_financieros.var_absoluta_23_24 = this.AnalisisPM.anexo.estado_resultados.gastos_financieros._2024 - this.AnalisisPM.anexo.estado_resultados.gastos_financieros._2023
    this.AnalisisPM.anexo.estado_resultados.productos_financieros.var_absoluta_23_24 = this.AnalisisPM.anexo.estado_resultados.productos_financieros._2024 - this.AnalisisPM.anexo.estado_resultados.productos_financieros._2023
    this.AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos.var_absoluta_23_24 = this.AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos._2024 - this.AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos._2023
    this.AnalisisPM.anexo.estado_resultados.impuestos.var_absoluta_23_24 = this.AnalisisPM.anexo.estado_resultados.impuestos._2024 - this.AnalisisPM.anexo.estado_resultados.impuestos._2023
    this.AnalisisPM.anexo.estado_resultados.utilidad_neta.var_absoluta_23_24 = this.AnalisisPM.anexo.estado_resultados.utilidad_neta._2024 - this.AnalisisPM.anexo.estado_resultados.utilidad_neta._2023

  }

  calcularAnalisisHorizontalResultadosporcentual(){
    this.AnalisisPM.anexo.estado_resultados.ventas.var_porcentual_22_23 = Math.round(((this.AnalisisPM.anexo.estado_resultados.ventas._2023 / this.AnalisisPM.anexo.estado_resultados.ventas._2022) - 1) * 100);
    this.AnalisisPM.anexo.estado_resultados.costo_ventas.var_porcentual_22_23 = Math.round(((this.AnalisisPM.anexo.estado_resultados.costo_ventas._2023 / this.AnalisisPM.anexo.estado_resultados.costo_ventas._2022) - 1) * 100);
    this.AnalisisPM.anexo.estado_resultados.utilidad_bruta.var_porcentual_22_23 = Math.round(((this.AnalisisPM.anexo.estado_resultados.utilidad_bruta._2023 / this.AnalisisPM.anexo.estado_resultados.utilidad_bruta._2022) - 1) * 100);
    this.AnalisisPM.anexo.estado_resultados.gastos_operacion.var_porcentual_22_23 = Math.round(((this.AnalisisPM.anexo.estado_resultados.gastos_operacion._2023 / this.AnalisisPM.anexo.estado_resultados.gastos_operacion._2022) - 1) * 100);
    this.AnalisisPM.anexo.estado_resultados.utilidad_operativa.var_porcentual_22_23 = Math.round(((this.AnalisisPM.anexo.estado_resultados.utilidad_operativa._2023 / this.AnalisisPM.anexo.estado_resultados.utilidad_operativa._2022) - 1) * 100);
    this.AnalisisPM.anexo.estado_resultados.gastos_financieros.var_porcentual_22_23 = Math.round(((this.AnalisisPM.anexo.estado_resultados.gastos_financieros._2023 / this.AnalisisPM.anexo.estado_resultados.gastos_financieros._2022) - 1) * 100);
    this.AnalisisPM.anexo.estado_resultados.productos_financieros.var_porcentual_22_23 = Math.round(((this.AnalisisPM.anexo.estado_resultados.productos_financieros._2023 / this.AnalisisPM.anexo.estado_resultados.productos_financieros._2022) - 1) * 100);
    this.AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos.var_porcentual_22_23 = Math.round(((this.AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos._2023 / this.AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos._2022) - 1) * 100);
    this.AnalisisPM.anexo.estado_resultados.impuestos.var_porcentual_22_23 = Math.round(((this.AnalisisPM.anexo.estado_resultados.impuestos._2023 / this.AnalisisPM.anexo.estado_resultados.impuestos._2022) - 1) * 100);
    this.AnalisisPM.anexo.estado_resultados.utilidad_neta.var_porcentual_22_23 = Math.round(((this.AnalisisPM.anexo.estado_resultados.utilidad_neta._2023 / this.AnalisisPM.anexo.estado_resultados.utilidad_neta._2022) - 1) * 100);

    this.AnalisisPM.anexo.estado_resultados.ventas.var_porcentual_23_24 = Math.round(((this.AnalisisPM.anexo.estado_resultados.ventas._2024 / this.AnalisisPM.anexo.estado_resultados.ventas._2023) - 1) * 100);
    this.AnalisisPM.anexo.estado_resultados.costo_ventas.var_porcentual_23_24 = Math.round(((this.AnalisisPM.anexo.estado_resultados.costo_ventas._2024 / this.AnalisisPM.anexo.estado_resultados.costo_ventas._2023) - 1) * 100);
    this.AnalisisPM.anexo.estado_resultados.utilidad_bruta.var_porcentual_23_24 = Math.round(((this.AnalisisPM.anexo.estado_resultados.utilidad_bruta._2024 / this.AnalisisPM.anexo.estado_resultados.utilidad_bruta._2023) - 1) * 100);
    this.AnalisisPM.anexo.estado_resultados.gastos_operacion.var_porcentual_23_24 = Math.round(((this.AnalisisPM.anexo.estado_resultados.gastos_operacion._2024 / this.AnalisisPM.anexo.estado_resultados.gastos_operacion._2023) - 1) * 100);
    this.AnalisisPM.anexo.estado_resultados.utilidad_operativa.var_porcentual_23_24 = Math.round(((this.AnalisisPM.anexo.estado_resultados.utilidad_operativa._2024 / this.AnalisisPM.anexo.estado_resultados.utilidad_operativa._2023) - 1) * 100);
    this.AnalisisPM.anexo.estado_resultados.gastos_financieros.var_porcentual_23_24 = Math.round(((this.AnalisisPM.anexo.estado_resultados.gastos_financieros._2024 / this.AnalisisPM.anexo.estado_resultados.gastos_financieros._2023) - 1) * 100);
    this.AnalisisPM.anexo.estado_resultados.productos_financieros.var_porcentual_23_24 = Math.round(((this.AnalisisPM.anexo.estado_resultados.productos_financieros._2024 / this.AnalisisPM.anexo.estado_resultados.productos_financieros._2023) - 1) * 100);
    this.AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos.var_porcentual_23_24 = Math.round(((this.AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos._2024 / this.AnalisisPM.anexo.estado_resultados.utilidad_antes_impuestos._2023) - 1) * 100);
    this.AnalisisPM.anexo.estado_resultados.impuestos.var_porcentual_23_24 = Math.round(((this.AnalisisPM.anexo.estado_resultados.impuestos._2024 / this.AnalisisPM.anexo.estado_resultados.impuestos._2023) - 1) * 100);
    this.AnalisisPM.anexo.estado_resultados.utilidad_neta.var_porcentual_23_24 = Math.round(((this.AnalisisPM.anexo.estado_resultados.utilidad_neta._2024 / this.AnalisisPM.anexo.estado_resultados.utilidad_neta._2023) - 1) * 100);
  }

  calcularGastoFinacieroTotal(){
    this.AnalisisPM.anexo.mesSelected.nombre =  this.obtenerNombreMes(this.AnalisisPM.anexo.mesSelected.id)
    this.AnalisisPM.anexo.gastos_financieros = ((Number(this.AnalisisPM.anexo.estado_resultados.costo_ventas._2024) + Number(this.AnalisisPM.anexo.estado_resultados.gastos_operacion._2024) + Number(this.AnalisisPM.anexo.estado_resultados.gastos_financieros._2024) ) / this.AnalisisPM.anexo.mesSelected.value).toFixed(2)
    
  }
  getClassForValue(value: number): string {
    return value < 0 ? 'red-text' : '';
  }
   obtenerNombreMes(idMes) {
    const mesEncontrado = MESES.find(mes => mes.id === idMes);
    return mesEncontrado ? mesEncontrado.mes : "Parcial 2024";
  }

  
  async guardarYRetroceder() {
    console.log("Guardando analisis", this.AnalisisPM);
    // this.AnalisisPM.anexo.mesSelected.id = this.AnalisisPM.anexo.mes_parcial
    const res:any = await this.solicitudService.GuardarAnalisisCredito(
      this.AnalisisPM
    );
    console.log(res);
    if(res.statusCode == 200){
      this.analisisPmChange.emit(this.AnalisisPM)

      this.stepperNavigationService.previousStep();
    }
  }

  async guardarYAvanzar() {
    this.loading = true;
        console.log("Guardando analisis", this.AnalisisPM);
    const res:any = await this.solicitudService.GuardarAnalisisCredito(
      this.AnalisisPM
    );
    console.log(res);
    if(res.statusCode == 200){
      this.loading = false;
      this.analisisPmChange.emit(this.AnalisisPM)
      this.stepperNavigationService.nextStep();
    }else{
      this.loading = false;
    }
  }


  
}
