import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { UserService, SolicitudService, AuthenticationService } from 'src/app/_services';
import { saveAs } from 'file-saver';
import { customPatterns } from '../../../_models/maskPattern';
import { formatCurrency } from '@angular/common';
@Component({
  selector: 'app-caratula',
  templateUrl: './caratula.component.html',
  styleUrls: ['./caratula.component.scss']
})
export class CaratulaComponent implements OnInit {
  customPattern = customPatterns;
  folio = null;
  CaratulaDatos: any = {};
  Caratula: any = {};
  actualizado = false;
  submit = false;
  pdf = false

  constructor(private dialog: MatDialog,
              private router: Router,
              private userService: UserService,
              private solicitudService: SolicitudService,
              private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    const {folio} = JSON.parse( localStorage.getItem('client_details'));
    this.folio = folio;

    this.userService.ObtenerDatosCaratula( Number(this.folio) ).subscribe( res => {
      console.log('Caratula', res);

      this.CaratulaDatos = res;
      this.CaratulaDatos.fecha = res.fecha.split('T')[0];
      this.CaratulaDatos.cliente = JSON.parse(localStorage.getItem('client_details')).cliente;
      this.Caratula.id_solicitud = res.id_solicitud;
      this.Caratula.cobro_comision_vendor = '0';
      this.Caratula.otro = '0';
      this.Caratula.por_parte_de = 'Cliente';
      this.Caratula.renta_mensual = 'Vencido';
      this.Caratula.pago = 'Contado';
    }, err => console.log(err));
  }

  Save() {
    this.userService.changeStatus(this.folio, 4, 1).subscribe(() => {
      this.dialog.open(MessageBoxComponent, {
        data: {
          text: 'Éxito',
          text2: 'Se ha completado la Carátula de Autorización',
          // text3: 'Revisa tu correo para descargar el documento',
          type: 'success',
          icon: 'success'
        }
      });
      this.router.navigate(['/dashboard', 'comite', this.folio]);
    });
  }

  GuardarCaratula() {
    if( !this.userService.alertService.isOnline() ) return
    this.pdf = true
    const mail = JSON.parse(localStorage.getItem('currentUser')).email;
    const cliente = JSON.parse(localStorage.getItem('client_details'));

    const request = {
      email: mail,
      opcion: 3,
      filename: 'caratula_autorizacion.pdf',
      subjec: 'Carátula Autorización',
      type: 14,
      params: {
        nombreCliente: this.CaratulaDatos.cliente,
        nombreVendor: this.CaratulaDatos.vendor,
        nombreComercial: this.CaratulaDatos.comercial,
        monto: formatCurrency( this.CaratulaDatos.monto_autorizado, 'es-MX', '$' ),
        inputActivo: this.CaratulaDatos.tipo_activo_arrendar,
        inputDesc: this.CaratulaDatos.descripcion_activo,
        inputProducto: this.CaratulaDatos.tipo_producto,
        inputFactor: this.CaratulaDatos.factor_porcentaje,
        inputPlazo: this.CaratulaDatos.plazo,
        inputRentaI: formatCurrency( this.CaratulaDatos.renta_inicial, 'es-MX', '$' ),
        inputRentaM: this.Caratula.renta_mensual,
        inputDeposito: this.CaratulaDatos.rentas_anticipadas > 0 ? 'Sí' : 'No',
        inputMontoDep: this.CaratulaDatos.rentas_anticipadas > 0 ? this.CaratulaDatos.rentas_anticipadas : '-',
        inputSeguro: this.Caratula.seguro ? 'Sí' : 'No',
        inputSeguroQuien: this.Caratula.seguro ? this.Caratula.por_parte_de : '-',
        inputRelAcre: this.Caratula.relacion_acreditario,
        inputGarantias: this.Caratula.garantias,
        inputComisiones: this.Caratula.cobro_comision_vendor,
        inputPago: this.Caratula.pago,
        inputDescCiego: this.Caratula.descuento_ciego ? 'Sí' : 'No',
        montoDescuento: this.Caratula.descuento_ciego ? this.Caratula.monto_descuento : '-',
        comentariosCredito: this.CaratulaDatos.comentarios_comite,
        comentariosAdicionales: this.Caratula.comentarios,
        nombreSolicitante: cliente.cliente,
        activoArrendar: cliente.activo_nombre,
        folio: cliente.folio,
        fecha: this.CaratulaDatos.fecha,
      }
    };
    console.log('request: ', request);

    this.submit = true;
    if ( !this.Caratula.comentarios ||
        !this.Caratula.renta_mensual ||
        !this.Caratula.por_parte_de ||
        !this.Caratula.relacion_acreditario ) { return this.pdf = false; }

    this.userService.GuardarCaratula(this.Caratula)
    .subscribe( res => {
      // this.actualizado = true
      console.log(res);
      this.enviaEmail(request);
    }, (err) => {
      this.pdf = false
      console.log(err);
      this.dialog.open(MessageBoxComponent, {
        data: {
          text: 'Hubo un error al guardar la información',
          text2: err,
          type: 'error',
          icon: 'error'
        }
      });
    });
  }

  enviaEmail(request) {
    this.solicitudService.sendMailCommentaries(request)
    .subscribe( (res: any) => {
      console.log('res: ', res);
      this.actualizado = true;

      const file = new Blob([res], {type: 'application/pdf'});
      /*var fileURL = URL.createObjectURL(file);
      window.open(fileURL);*/
      saveAs(file, request.filename);

      const dialogref = this.dialog.open(MessageBoxComponent, {
        width: '500px',
        data: {
          text: 'Éxito',
          text2: 'Se ha guardado la información',
          text3: 'Revisa tu correo para descargar el documento',
          type: 'success',
          icon: 'success'
        }
      });
    }, (err) => {
      console.log('error: ', err);
      this.dialog.open(MessageBoxComponent, {
        data: {
          text: 'El servicio no está disponible.',
          text3: 'Vuelve a intentarlo',
          type: 'error',
          icon: 'error'
        }
      });
    });
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

    // GUARDAR CARATULA SUBSCRIBE
    /*return this.dialog.open(MessageBoxComponent, {
      width: "500px",
      data: {
        text: "Se ha actualizado con exito.",
        type: "success",
        icon: "success"
      }
    })*/
    // return this.userService.ObtenerPDF({
    //   id:8,
    //   params: {
    //     ...this.Caratula,
    //     ...this.CaratulaDatos
    //   }
    // }).subscribe(res => {
    //   window.print()
    //   const blob = new Blob([res], {type: 'application/pdf'})
    //   saveAs(blob, 'Caratula.pdf')
    // },
    // err=>{
    //   console.log(err)
    //   this.dialog.open(MessageBoxComponent, {
    //     data: {
    //       text: "Se ha actualizado con exito.",
    //       type: "success",
    //       icon: "success"
    //     }
    //   })
    // })
}
