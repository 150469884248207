import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Route, Router } from "@angular/router";
import { DatosAnalisis } from "src/app/_models/Comite";
import { COMPORTAMIENTO_PAGO_CREDITO } from "../../valuacion/analisis/Analisis";
import {
  AnalisisPM,
  RESULTADO_UTILIDAD,
  TIPO_INSTALACIONES,
} from "../AnalisisPM";
import { SolicitudService } from "src/app/_services";
import { StepperNavigationService } from "src/app/_services/stepper-navigation.service";
import { AnalisisPMService } from "src/app/_services/analisis.service";
import { AnalisisCreditoService } from "src/app/_services/analisis-credito.service";

@Component({
  selector: "app-caracter",
  templateUrl: "./caracter.component.html",
  styleUrls: ["./caracter.component.scss"],
})
export class CaracterComponent implements OnInit {
  @Input() analisisPm: any;  
  @Output() analisisPmChange = new EventEmitter<any>();
  frmCaracter: FormGroup;
  AnalisisPM: any = {};
  folio = null;
  DatosAnalisis = DatosAnalisis;
  COMPORTAMIENTO_PAGO_CREDITO = COMPORTAMIENTO_PAGO_CREDITO;
  TIPO_INSTALACIONES = TIPO_INSTALACIONES;
  RESULTADO_UTILIDAD = RESULTADO_UTILIDAD;
  loading = false;
  ngOnChanges(changes: SimpleChanges) {
    if (changes.analisisPm && changes.analisisPm.currentValue !== null && changes.analisisPm.currentValue !== undefined && changes.analisisPm.currentValue !== 0) {
      console.log("Nuevo valor de analisisPm caracter:", this.analisisPm);
      this.AnalisisPM = {...this.analisisPm}
      this.CambiarPuntuacionBuroEmpresa();
      this.ActualizarComportamientoCredito()
    }
  }

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private solicitudService: SolicitudService,
    private stepperNavigationService: StepperNavigationService,
    private cdRef: ChangeDetectorRef,
    private analisisPM: AnalisisPMService,
    private analisis:AnalisisCreditoService
  ) {}

  async ngOnInit() {
    this.AnalisisPM = JSON.parse(JSON.stringify(AnalisisPM));
    const { folio } = JSON.parse(localStorage.getItem("client_details"));
    this.folio = folio;
    this.AnalisisPM.folio = this.folio;

    // await this.getAnalisisPMData();
    this.analisisPm = {
      ...AnalisisPM
    }
   
  }
  async getAnalisisPMData() {
    const res: any = this.solicitudService
      .ObtenerAnalisisCredito(this.folio)
      .subscribe(
        (res: any) => {
          console.log("SI HAY CARACTER", res);
          const data = res.data;
          this.AnalisisPM = {
            ...res.data,
          };
          // this.AnalisisPM = data;
          this.AnalisisPM.puntuacion_caracter = this.sumarTotales(
            Number(this.AnalisisPM.caracter.total_puntos_creditos_cerrados),
            Number(this.AnalisisPM.caracter.total_puntos_creditos_abiertos),
            Number(this.AnalisisPM.perfil_empresa.total_puntos_empresa),
            Number(this.AnalisisPM.buro_empresa.total_puntos_buro_empresa)
          );
          console.log(
            "TODAS PJUNTUACIONES",
            this.AnalisisPM.puntuacion_caracter
          );
          this.AnalisisPM.perfil_empresa.resultado_utilidad_descrip =
            data.perfil_empresa.resultado_utilidad_descrip;
          this.AnalisisPM.perfil_empresa.resultado_utilidad =
            data.perfil_empresa.resultado_utilidad;
          this.puntosResultadoUtilidad();
          this.CambiarPuntuacionBuroEmpresa();
          this.reActualizarComportamientoCredito()
        },
        (error) => {
          console.log("ERROR NO HAY", error);
          this.AnalisisPM;
          this.AnalisisPM.puntuacion_caracter = this.sumarTotales(
            Number(this.AnalisisPM.caracter.total_puntos_creditos_cerrados),
            Number(this.AnalisisPM.caracter.total_puntos_creditos_abiertos),
            Number(this.AnalisisPM.perfil_empresa.total_puntos_empresa),
            Number(this.AnalisisPM.buro_empresa.total_puntos_buro_empresa)
          );
          console.log(
            "TODAS PJUNTUACIONES",
            this.AnalisisPM.puntuacion_caracter
          );
        }
      );
  }
  CambiarPuntuacionEmpresa() {
    this.AnalisisPM.perfil_empresa.tiempo_operaciones_puntos =
      this.AnalisisPM.perfil_empresa.tiempo_operaciones >= 2 ? 1 : 0;

    this.AnalisisPM.perfil_empresa.arraigo_puntos = 
    this.AnalisisPM.perfil_empresa.arraigo >= 2 ? 1 : 0;
      

    this.AnalisisPM.perfil_empresa.bc_representante_legal_puntos =
      this.AnalisisPM.perfil_empresa.bc_representante_legal >= 630
        ? 2
        : this.AnalisisPM.perfil_empresa.bc_representante_legal >= 600 &&
          this.AnalisisPM.perfil_empresa.bc_representante_legal <= 629
        ? 1
        : this.AnalisisPM.perfil_empresa.bc_representante_legal <= 599
        ? 0
        : 0;

    this.AnalisisPM.perfil_empresa.bc_aval_puntos =
      this.AnalisisPM.perfil_empresa.bc_aval >= 630
        ? 2
        : this.AnalisisPM.perfil_empresa.bc_aval >= 600 &&
          this.AnalisisPM.perfil_empresa.bc_aval <= 629
        ? 1
        : this.AnalisisPM.perfil_empresa.bc_aval <= 599
        ? 0
        : 0;

    this.ActualizarPuntuacion("perfil_empresa");
  }
  CambiarPuntuacionBuroEmpresa() {
    console.log("CALIFICACION", this.AnalisisPM.buro_empresa.calificacion);
    this.AnalisisPM.buro_empresa.calificacion_puntos =
      this.AnalisisPM.buro_empresa.calificacion == "A1" ||
      this.AnalisisPM.buro_empresa.calificacion == "A2" ||
      this.AnalisisPM.buro_empresa.calificacion == "B1" ||
      this.AnalisisPM.buro_empresa.calificacion == "B2"
        ? 2
        : 0;

    this.AnalisisPM.buro_empresa.meses_mora_puntos = 
      this.AnalisisPM.buro_empresa.meses_mora >= 90
      ? 0
      : 2
    
      this.AnalisisPM.buro_empresa.porcentaje_cuentas_buenas_puntos = 
      this.AnalisisPM.buro_empresa.porcentaje_cuentas_buenas  >= 80
      ? 2
      : 0
    this.AnalisisPM.buro_empresa.peor_mop_12_meses_puntos = 
       this.AnalisisPM.buro_empresa.peor_mop_12_meses <= 2
      ? 2
      : 0

    this.AnalisisPM.buro_empresa.entrevista_puntos = 
    this.AnalisisPM.buro_empresa.entrevista_descrip == "OK"
    ? 5
    : 0

    this.AnalisisPM.buro_empresa.entrevista = this.AnalisisPM.buro_empresa.entrevista_descrip
    
    
    this.ActualizarPuntuacion("perfil_empresa");

  }
  ActualizarPuntuacion(perfil) {
    if (perfil == "perfil_empresa") {
      const {
        tiempo_operaciones_puntos = 0,
        tipo_instalaciones_puntos = 0,
        arraigo_puntos = 0,
        resultado_utilidad_puntos = 0,
        bc_representante_legal_puntos = 0,
        bc_aval_puntos = 0,
      } = this.AnalisisPM[perfil];

      this.AnalisisPM[perfil].total_puntos_empresa =
        Number(tiempo_operaciones_puntos) +
        Number(tipo_instalaciones_puntos) +
        Number(arraigo_puntos) +
        Number(resultado_utilidad_puntos) +
        Number(bc_representante_legal_puntos) +
        Number(bc_aval_puntos);
    }
    if (perfil == "buro_empresa") {
      const {
        calificacion_puntos = 0,
        meses_mora_puntos = 0,
        porcentaje_cuentas_buenas_puntos = 0,
        peor_mop_12_meses_puntos = 0,
        entrevista_puntos = 0,
      } = this.AnalisisPM[perfil];

      this.AnalisisPM[perfil].total_puntos_buro_empresa =
        Number(calificacion_puntos) +
        Number(meses_mora_puntos) +
        Number(porcentaje_cuentas_buenas_puntos) +
        Number(peor_mop_12_meses_puntos) +
        Number(entrevista_puntos);
      
    }
    this.puntosBuroEmpresa()
    this.AnalisisPM.puntuacion_caracter = this.sumarTotales(
      Number(this.AnalisisPM.caracter.total_puntos_creditos_cerrados),
      Number(this.AnalisisPM.caracter.total_puntos_creditos_abiertos),
      Number(this.AnalisisPM.perfil_empresa.total_puntos_empresa),
      Number(this.AnalisisPM.buro_empresa.total_puntos_buro_empresa)
    );
    // this.PuntuacionCaracter(); // Actualizamos los puntos de la seccion Caracter
  }
  PuntuacionCaracter() {
    const {
      perfil_solicitante: { total: total_solicitante },
      perfil_aval: { total: total_aval },
      puntos_credito_abierto,
      puntos_credito_cerradas,
    } = this.AnalisisPM;

    const puntuacionCaracter =
      total_solicitante +
      total_aval +
      puntos_credito_abierto +
      puntos_credito_cerradas;
    this.AnalisisPM.puntuacion_caracter =
      puntuacionCaracter > 34 ? 34 : puntuacionCaracter;
  }
  CambiarComportamientoPagoCredito(tipo, idx) {
    this.AnalisisPM[`resumen_creditos${tipo}`][idx].puntos =
      this.COMPORTAMIENTO_PAGO_CREDITO.filter(
        (cp) =>
          cp.id ==
          this.AnalisisPM[`resumen_creditos${tipo}`][idx].id_comportamiento_pago
      )[0].puntuacion;
      
    this.ActualizarComportamientoCredito();
    this.PuntuacionCaracter(); // Actualizamos los puntos de la seccion Caracter
  }
  AgregarCuenta(tipo) {
    const status = tipo == "activas" ? true : false;
    console.log(status);

    if (tipo == "activas") {
      console.log(
        "ABIERTAS",
        this.AnalisisPM.caracter.resumen_creditos_abiertos
      );
      this.AnalisisPM.caracter.resumen_creditos_abiertos.push({
        otorgante: "",
        credito_maximo: 0,
        saldo_insoluto: 0,
        mop_maximo: 0,
        comportamiento: "",
        puntos: 0,
      });
      this.ActualizarComportamientoCredito();
    } else if (tipo == "cerradas") {
      console.log(
        "CERRADAS",
        this.AnalisisPM.caracter.resumen_creditos_cerrados
      );
      this.AnalisisPM.caracter.resumen_creditos_cerrados.push({
        otorgante: "",
        credito_maximo: 0,
        saldo_insoluto: 0,
        mop_maximo: 0,
        comportamiento: "",
        puntos: 0,
      });
      this.ActualizarComportamientoCredito();
    }
  }
  reActualizarComportamientoCredito() {
    this.ActualizarComportamientoCredito();
  }
  obtenerNombreComportamientoPago(id: number): string {
    const comportamientoSeleccionado = this.COMPORTAMIENTO_PAGO_CREDITO.find(cp => cp.id === id);
    return comportamientoSeleccionado ? comportamientoSeleccionado.nombre : '';
  }

  ActualizarComportamientoCredito() {
    this.AnalisisPM.caracter.total_puntos_creditos_abiertos = 0;
    this.AnalisisPM.caracter.total_puntos_creditos_cerrados = 0;

    if (this.AnalisisPM.caracter.resumen_creditos_abiertos.length >= 1) {
      console.log("ACTUALIZAR abierta", this.AnalisisPM.caracter.total_puntos_creditos_abiertos );
      this.AnalisisPM.caracter.resumen_creditos_abiertos.forEach(
        (activa) =>
         { 
          
          if(activa.puntos == null) {
            activa.puntos = 0
          }
          (this.AnalisisPM.caracter.total_puntos_creditos_abiertos += Number(
            activa.puntos
          ))
          console.log(this.AnalisisPM.caracter.total_puntos_creditos_abiertos, activa.puntos)
            console.log("COMPORTAMIENTO", activa.comportamiento)
        }
      );
    }
    if (this.AnalisisPM.caracter.resumen_creditos_cerrados.length >= 1) {
      console.log("ACTUALIZAR CERRADA");
      this.AnalisisPM.caracter.resumen_creditos_cerrados.forEach(
        (activa) =>
          {
            
            if(activa.puntos == null) {
              activa.puntos = 0
            }
            (this.AnalisisPM.caracter.total_puntos_creditos_cerrados += Number(
              activa.puntos
            ))
            console.log("ACTUALIZAR CERRADA",this.AnalisisPM.caracter.total_puntos_creditos_cerrados, activa.puntos)

          }
      );
    }

    this.AnalisisPM.puntuacion_caracter = this.sumarTotales(
      Number(this.AnalisisPM.caracter.total_puntos_creditos_cerrados),
      Number(this.AnalisisPM.caracter.total_puntos_creditos_abiertos),
      Number(this.AnalisisPM.perfil_empresa.total_puntos_empresa),
      Number(this.AnalisisPM.buro_empresa.total_puntos_buro_empresa)
    );
    this.cdRef.detectChanges();
  }
  sumarTotales(...valores: number[]): number {
    return valores.reduce((total, valor) => total + (valor || 0), 0);
  }
  BorrarCuenta(tipo, idx) {
    const status = tipo == "activas" ? true : false;
    if (tipo == "activas") {
      console.log(
        "ABIERTAS",
        this.AnalisisPM.caracter.resumen_creditos_abiertos
      );
      this.AnalisisPM.caracter.resumen_creditos_abiertos.splice(idx, 1);
      this.ActualizarComportamientoCredito();
    } else {
      console.log(
        "CERRADAS",
        this.AnalisisPM.caracter.resumen_creditos_cerrados
      );

      this.AnalisisPM.caracter.resumen_creditos_cerrados.splice(idx, 1);
      this.ActualizarComportamientoCredito();
    }
  }
  puntoTipoInstalaciones() {
    console.log(
      "TIPO INSTALACIONES",
      this.AnalisisPM.perfil_empresa.tipo_instalaciones_descrip
    );
    if (this.AnalisisPM.perfil_empresa.tipo_instalaciones_descrip == 1) {
      this.AnalisisPM.perfil_empresa.tipo_instalaciones_puntos = 1;
      this.AnalisisPM.perfil_empresa.tipo_instalaciones = "PROPIA";
      console.log(this.AnalisisPM.perfil_empresa.tipo_instalaciones_puntos);
    } else {
      this.AnalisisPM.perfil_empresa.tipo_instalaciones_puntos = 0;
      this.AnalisisPM.perfil_empresa.tipo_instalaciones = "RENTADA";

      console.log(this.AnalisisPM.perfil_empresa.tipo_instalaciones_puntos);
    }
  }
  puntosResultadoUtilidad() {
    console.log("RESULTADO", this.AnalisisPM.perfil_empresa.resultado_utilidad);

    if (this.AnalisisPM.perfil_empresa.resultado_utilidad == 1) {
      this.AnalisisPM.perfil_empresa.resultado_utilidad_puntos = 2;
      this.AnalisisPM.perfil_empresa.resultado_utilidad_descrip = "POSITIVO";
      console.log(
        "RESULTADO",
        this.AnalisisPM.perfil_empresa.resultado_utilidad_descrip
      );
    } else {
      this.AnalisisPM.perfil_empresa.resultado_utilidad_puntos = 0;
      this.AnalisisPM.perfil_empresa.resultado_utilidad_descrip = "NEGATIVO";

      console.log(
        "RESULTADO2",
        this.AnalisisPM.perfil_empresa.tipo_instalaciones_puntos
      );
    }
  }
  puntosBuroEmpresa() {
    this.AnalisisPM.buro_empresa.total_puntos_buro_empresa = this.sumarTotales(
      this.AnalisisPM.buro_empresa.entrevista_puntos,
      this.AnalisisPM.buro_empresa.peor_mop_12_meses_puntos,
      this.AnalisisPM.buro_empresa.porcentaje_cuentas_buenas_puntos,
      this.AnalisisPM.buro_empresa.meses_mora_puntos,
      this.AnalisisPM.buro_empresa.calificacion_puntos
    );
  }
  obtenerNombreComportamiento(){
    console.log("OBTENGO NOMBRE COMPORTAMIENTO")
    this.AnalisisPM.caracter.resumen_creditos_cerrados.forEach(
      (activa) =>
        {
          activa.comportamiento = this.obtenerNombreComportamientoPago(activa.comportamiento_pago);
        }
    );
  }

  async guardarYRetroceder() {
    console.log("Guardando analisis", this.AnalisisPM);
    
    const res: any = await this.solicitudService.GuardarAnalisisCredito(
      this.AnalisisPM
    );
    console.log(res);
    if (res.statusCode == 200) {
      this.analisisPmChange.emit(this.AnalisisPM)

      this.stepperNavigationService.previousStep();
    }
  }

  async guardarYAvanzar() {
    console.log("Guardando analisis", this.AnalisisPM);
    this.obtenerNombreComportamiento();
    this.loading = true;
    const res: any = await this.solicitudService.GuardarAnalisisCredito(
      this.AnalisisPM
    );
   
    console.log(res);
    if (res.statusCode == 200) {
      this.loading = false;
      this.analisisPmChange.emit(this.AnalisisPM)
      this.stepperNavigationService.nextStep();
    }else{
      this.loading = false;
    }
  }
}
