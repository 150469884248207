<div class="container">
    <div class="body">
        <div>
            <span class="serie">#<span class="version">{{version}}</span> {{ folio_lease }}</span>
            <div class="zoom">
                <button mat-mini-fab (click)="SetZoom(0.1)">+</button>
                <button mat-mini-fab (click)="SetZoom(-0.1)">-</button>
            </div>
            <pdf-viewer
                [src]="url"
                [autoresize]="true"
                [original-size]="false"
                [zoom]="zoom">
            </pdf-viewer>
        </div>
        <div class="validacion">
            <span style="align-self: flex-start;">Elige el plazo</span>
            <mat-radio-group [(ngModel)]="plazo">
                <mat-radio-button *ngIf="PlazoValido('ap12')" [value]="'12 meses'">12 meses</mat-radio-button>
                <mat-radio-button *ngIf="PlazoValido('ap18')" [value]="'18 meses'">18 meses</mat-radio-button>
                <mat-radio-button *ngIf="PlazoValido('ap24')" [value]="'24 meses'">24 meses</mat-radio-button>
                <mat-radio-button *ngIf="PlazoValido('ap36')" [value]="'36 meses'">36 meses</mat-radio-button>
                <mat-radio-button *ngIf="PlazoValido('ap48')" [value]="'48 meses'">48 meses</mat-radio-button>
                <mat-radio-button *ngIf="PlazoValido('ap60')" [value]="'60 meses'">60 meses</mat-radio-button>

                <mat-radio-button *ngIf="PlazoValido('msi6')" [value]="'6 MSI'">6 MSI</mat-radio-button>
                <mat-radio-button *ngIf="PlazoValido('msi9')" [value]="'9 MSI'">9 MSI</mat-radio-button>
                <mat-radio-button *ngIf="PlazoValido('msi12')" [value]="'12 MSI'">12 MSI</mat-radio-button>
                <mat-radio-button *ngIf="PlazoValido('msi15')" [value]="'15 MSI'">15 MSI</mat-radio-button>
                <mat-radio-button *ngIf="PlazoValido('msi18')" [value]="'18 MSI'">18 MSI</mat-radio-button>
                <mat-radio-button *ngIf="PlazoValido('msi21')" [value]="'21 MSI'">21 MSI</mat-radio-button>
                <mat-radio-button *ngIf="PlazoValido('msi24')" [value]="'24 MSI'">24 MSI</mat-radio-button>

                <mat-radio-button *ngFor="let plazo of plazos_validos_msi" [value]="plazo.plazo + ' MSI - ' + plazo.porcentaje + ' %'"> {{plazo.plazo}} MSI - {{plazo.porcentaje}}%</mat-radio-button>
            </mat-radio-group>

            <span style="align-self: flex-start;">Información sobre domiciliación</span>
            <div class="input">
                <label for="">Tipo domiciliación</label>
                <mat-select [(ngModel)]="tipoDomiciliacion">
                    <mat-option [value]="'Sin domiciliación'">Sin domiciliación</mat-option>
                    <mat-option [value]="'Por CLABE'">Por CLABE</mat-option>
                </mat-select>
            </div>

            <div *ngIf="tipoDomiciliacion == 'Por CLABE'" class="input">
                <label for="">Cuenta CLABE</label>
                <input [(ngModel)]="numeroCuenta" />
            </div>

            <div *ngIf="tipoDomiciliacion == 'Por CLABE'" class="input">
                <label for="">Banco</label>
                <mat-select [(ngModel)]="banco">
                    <mat-option *ngFor="let banco of bancos" [value]="banco.id">{{banco.nombre}}</mat-option>
                </mat-select>
            </div>

            <br style="margin-bottom: 12px;" />



            <div class="botones">
                <button mat-button (click)="ConfirmarCotizacion()">Confirmar Cotización</button>
            </div>
            
            <mat-spinner *ngIf="loading" diameter="30"></mat-spinner>
        </div>
    </div>

    <app-socials-footer></app-socials-footer>
</div>