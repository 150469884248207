<div class="container-ine">
    <div>
        <h2 style="margin-bottom: 0px;">¡Crea tu solicitud!</h2>
    </div>
    <div>
        <a class="btn-secondary-sol" (click)="togglePasaporte()"><img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/passport.svg" style="margin-right: 10px;"/>Con {{!pasaporte ? 'Pasaporte' : 'INE'}}</a>
    </div>
    <div class="container-images">
        <div class="container-img-ine">
            <div style="position: relative; overflow: hidden; width: 0; height: 0">
                <input
                    type="file"
                    id="fileIneFront"
                    accept="image/*"
                    (change)="onFileSelected($event)"
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; opacity: 0; cursor: pointer;"
                />
            </div>
            <img (click)="selectFile()" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/ine-front.png"/>
            <div class="container-text-img">
                <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/circle-check.svg" *ngIf="parteFrontal"/>
                <p>Parte frontal</p>
                <!-- <img *ngIf="parteFrontal" [src]="frontImagePath"/> -->
            </div>
        </div>        
        <div class="container-img-ine" *ngIf="!pasaporte">
            <div style="position: relative; overflow: hidden; width: 0; height: 0; cursor: pointer;">
                <input
                    type="file"
                    id="fileIneBack"
                    accept="image/*"
                    (change)="onFileSelected($event)"
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; opacity: 0; cursor: pointer;"
                />
            </div>
            <img (click)="selectFileBack()" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/ine-back.png"/>
            <div class="container-text-img">
                <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/circle-check.svg" *ngIf="partePosterior"/>
                <p>Parte posterior</p>
                <!-- <img *ngIf="parteFrontal" [src]="backImagePath"/> -->
            </div>
        </div>
    </div>
    <p class="nota" style="margin-top: 0; margin-bottom: 0;">*Solo acepta .png .jpg .jpeg .img</p>
    <p class="nota" style="margin-top: 0">*El archivo de identificación debe ser legible, tener fondo blanco y sin reflejos de luz</p>
    <div style="display: grid; grid-template-columns: 62% 35%; width: 100%;">
        <div style="display: flex; flex-direction: column;">
        <div class="error-msg" *ngIf="messageError">Por favor sube tu identificación.</div>
        <div class="container-btn-cont">
            <button class="btn-login" (click)="continuar()" [ngClass]="{
                'disabled': !(parteFrontal && partePosterior) && !pasaporte || !parteFrontal && pasaporte,
                'loading-btn': loading}"  [disabled]="!(parteFrontal && partePosterior) && !pasaporte || !parteFrontal && pasaporte">
                <mat-spinner diameter="30" *ngIf="loading"  class="custom-spinner" ></mat-spinner>

                <div  *ngIf="!loading">Continuar</div>
            </button>
        </div>
        </div>
        <div class="container-btn-cont">
            <button class="btn-secondary-sol" (click)="continuarSinIne()">Continuar sin cargar INE</button>
        </div>
    </div>
    <div style="text-align: center; display: flex; justify-content: center; width: 80%;">
        <p class="nota" style="font-weight: 500; font-size: 13px;">**Puedes continuar sin cargar tu documento de identificación pero se te pedirá en unos pasos más adelante del proceso para completar tu expediente</p>
    </div>

</div>
