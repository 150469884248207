<div class="container" (click)="SeleccionarCotizacion()">
    <div class="left">
        <div class="version">
            #<b>{{cotizacion.version}}</b>
        </div>
        <div class="name">
            <span class="folio"><b>{{cotizacion.folio_leas}}</b></span>
            <span>{{cotizacion.fecha_creacion | date:'dd MMM yyyy'}}</span>
        </div>
    </div>
    <div class="eye">
        <svg xmlns="http://www.w3.org/2000/svg" width="48.77" height="35.469" viewBox="0 0 48.77 35.469"><defs><style>.a{fill:#3ca2d6;}</style></defs><path class="a" d="M25.385,4C7.65,4,1,21.734,1,21.734S7.65,39.469,25.385,39.469,49.77,21.734,49.77,21.734,43.119,4,25.385,4Zm0,4.434c11.7,0,17.612,9.459,19.527,13.292-1.918,3.806-7.878,13.31-19.527,13.31-11.7,0-17.612-9.459-19.527-13.292C7.778,17.937,13.736,8.434,25.385,8.434Zm0,4.434a8.867,8.867,0,1,0,8.867,8.867A8.868,8.868,0,0,0,25.385,12.867Zm0,4.434a4.434,4.434,0,1,1-4.434,4.434A4.432,4.432,0,0,1,25.385,17.3Z" transform="translate(-1 -4)"/></svg>
    </div>
</div>