<div class="container-precal">
  <div class="body-precal">
    <app-module-instructions
      [instructions]="instructions"
    ></app-module-instructions>
    <div class="container-form">
      <div class="container-code">
        <div class="container-input">
          <app-input-validation
            type="phone"
            [formGroup]="frmPhone"
            [controlName]="'phone'"
            [errorMessage]="getErrorMessage()"
            label="Número de teléfono"
            mask="00-0000-0000"
          ></app-input-validation>
        </div>
        <div *ngIf="messageError">
          Por favor ingresa un número de teléfono
        </div>
        <app-countdown
          [initialTime]="initialTime"
          [phoneValidation]="true"
          (solicitarPin)="SolicitarPinPhone()"
        ></app-countdown>
        <div style="margin-top: 15px;">
        <code-input
          [isCodeHidden]="false"
          [codeLength]="4"
          class="code-input-pre"
          (codeChanged)="onFullFill($event)"
          (codeCompleted)="onFullFill($event)"
        >
        </code-input>
        </div>
      </div>
      <div class="container-text">
        <p>
          *Al ingresar el NIP, estoy aceptando la
          <span (click)="openPolitics()">Consula de Buró de Crédito</span> de
          Lease For You S.A. de C.V.
        </p>
        <p>
          *La vigencia de la autorización es po 3 años o mientras dure la
          relación jurídica, si así se especifíca en la leyenda
        </p>
      </div>
    </div>
    <div class="container-btn-new">
      <button class="btn-secondary-new-format" (click)="atras()">Regresar</button>
      <button class="btn-new-format" (click)="enviarDatos()" [disabled]="!validForm" [ngClass]="{disable: !validForm}"><mat-spinner diameter="30" *ngIf="loading"></mat-spinner><span>Continuar</span></button>
    </div>
  </div>
</div>
