import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs/internal/Subject';
import { ReferidosService } from 'src/app/_services/referidos.service';

@Component({
  selector: 'app-modal-referidos-rechazo',
  templateUrl: './modal-referidos-rechazo.component.html',
  styleUrls: ['./modal-referidos-rechazo.component.scss']
})
export class ModalReferidosRechazoComponent implements OnInit {

  referidoDescartado = new Subject<number>();

  constructor(
    private dialogRef: MatDialogRef<ModalReferidosRechazoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private referidoService: ReferidosService
  ) { }

  ngOnInit(): void {
    console.log(this.data);
  }

  cerrarModal(): void {
    this.dialogRef.close();
  }

  async descartarReferido(): Promise<void> {
    try {
      const valor = await this.referidoService.rechazoReferido(this.data.id).toPromise();
      console.log(`::::: ${valor}`);
      // Emitir el ID del referido descartado y cerrar el modal
      this.referidoDescartado.next(this.data.id);
      this.dialogRef.close();
    } catch (error) {
      console.error('Error al descartar referido:', error);
    }
  }

}
