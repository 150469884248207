const width = '120px'
const MESES = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
]

const SEMANAS = [
    'Semana1',
    'Semana2',
    'Semana3',
    'Semana4',
    'Semana5',
    'Semana6',
    'Semana7',
    'Semana8',
    'Semana9',
    'Semana10',
    'Semana11',
    'Semana12',
    'Semana13',
    'Semana14',
    'Semana15',
    'Semana16',
    'Semana17',
    'Semana18',
    'Semana19',
    'Semana20',
    'Semana21',
    'Semana22',
    'Semana23',
    'Semana24',
    'Semana25',
    'Semana26',
    'Semana27',
    'Semana28',
    'Semana29',
    'Semana30',
    'Semana31',
    'Semana32',
    'Semana33',
    'Semana34',
    'Semana35',
    'Semana36',
    'Semana37',
    'Semana38',
    'Semana39',
    'Semana40',
    'Semana41',
    'Semana42',
    'Semana43',
    'Semana44',
    'Semana45',
    'Semana46',
    'Semana47',
    'Semana48',
    'Semana49',
    'Semana50',
    'Semana51',
    'Semana52',
]

export const tableFields = {
    folio: {
        value: 'Folio',
        type: 'text',
        width,
    },
    cliente: {
        value: 'Cliente',
        type: 'text',
        width: '240px',
    },
    tipo_persona: {
        value: 'Tipo de persona',
        type: 'text',
        width,
    },
    monto_financiar: {
        value: 'Monto a financiar',
        type: 'currency',
        width,
    },
    vendor: {
        value: 'Vendor',
        type: 'text',
        width,
    },
    comercial: {
        value: 'Comercial',
        type: 'text',
        width,
    },
    fecha_alta_operacion: {
        value: 'F. Alta de operación',
        type: 'date',
        width,
    },
    dias_transcurridos: {
        value: 'Días transcurridos',
        type: 'number',
        class: {
            green_box: {
                min: 0,
                max: 5
            },
            yellow_box: {
                min: 6,
                max: 90
            },red_box: {
                min: 91,
                max: 365
            }
        },
        width,
    },
    fecha_final_operacion: {
        value: 'F. Final de operación',
        type: 'date',
        width,
    },
    informacion_completa: {
        value: 'Información completa (IC)',
        type: 'yesno',
        readonly: true,
        width,
    },
    ic_fecha_modificacion: {
        value: 'FM. (IC)',
        type: 'date',
        width,
    },
    ic_usuario_modifico: {
        value: 'Mod. (IC)',
        type: 'text',
        width,
    },
    expediente_listo: {
        value: 'Expediente listo',
        type: 'yesno',
        readonly: true,
        width,
    },
    llenado_expediente: {
        value: 'Llenado del expediente',
        type: 'number',
        class: {
            green_box: {
                min: 0,
                max: 1
            },
            red_box: {
                min: 2,
                max: 90
            }
        },
        label: 'días',
        width,
    },
    comite_credito: {
        value: 'Comite de crédito (CC)',
        type: 'yesno',
        width,
    },
    cc_fecha_modificacion: {
        value: 'FM. (CC)',
        type: 'date',
        width,
    },
    cc_usuario_modifico: {
        value: 'Mod. (CC)',
        type: 'text',
        width,
    },
    tiempo_pasar_comite: {
        value: 'T. Pasar a comité',
        type: 'number',
        class: {
            green_box: {
                min: 0,
                max: 1
            },
            red_box: {
                min: 2,
                max: 90
            }
        },
        label: 'días',
        width,
    },
    condicionado: {
        value: 'Condicionado (CN)',
        type: 'yesno',
        width,
    },
    cn_fecha_modificacion: {
        value: 'FM. (CN)',
        type: 'date',
        width,
    },
    cn_usuario_modifico: {
        value: 'Mod. (CN)',
        type: 'text',
        width,
    },
    informacion_adicional: {
        value: 'Información adicional (IA)',
        type: 'yesno',
        width,
    },
    ia_fecha_modificacion: {
        value: 'FM. (IA)',
        type: 'date',
        width,
    },
    ia_usuario_modifico: {
        value: 'Mod. (IA)',
        type: 'text',
        width,
    },
    operacion_liberada: {
        value: 'Operación liberada (OL)',
        type: 'yesno',
        width,
    },
    ol_fecha_modificacion: {
        value: 'FM. (OL)',
        type: 'date',
        width,
    },
    ol_usuario_modifico: {
        value: 'Mod. (OL)',
        type: 'text',
        width,
    },
    rechazada: {
        value: 'Rechazada (RC)',
        type: 'yesno',
        width,
    },
    rc_fecha_modificacion: {
        value: 'FM. (RC)',
        type: 'date',
        width,
    },
    rc_usuario_modifico: {
        value: 'Mod. (RC)',
        type: 'text',
        width,
    },
    factura_recibida: {
        value: 'Factura recibida',
        type: 'yesno',
        width,
    },
    factura_validada: {
        value: 'Factura validada',
        type: 'yesno',
        width,
    },
    contrato_realizado: {
        value: 'Contrato realizado',
        type: 'yesno',
        width,
    },
    tiempo_contrato_realizado: {
        value: 'T. de contrato realizado',
        type: 'number',
        class: {
            green_box: {
                min: 0,
                max: 47
            },
            red_box: {
                min: 48,
                max: 250
            }
        },
        label: 'hrs',
        width,
    },
    control_cambios: {
        value: 'Control de cambios',
        type: 'yesno',
        width,
    },
    contrato_enviado: {
        value: 'Contrato enviado',
        type: 'yesno',
        width,
    },
    tiempo_contrato_enviado: {
        value: 'T. de contrato enviado',
        type: 'number',
        class: {
            green_box: {
                min: 0,
                max: 47
            },
            red_box: {
                min: 48,
                max: 250
            }
        },
        label: 'hrs',
        width,
    },
    contrato_regresado: {
        value: 'Contrato regresado',
        type: 'yesno',
        width,
    },
    tiempo_espera_contrato: {
        value: 'T. de espera de contrato',
        type: 'number',
        class: {
            green_box: {
                min: 0,
                max: 47
            },
            red_box: {
                min: 48,
                max: 250
            }
        },
        label: 'hrs',
        width,
    },
    digitalizacion: {
        value: 'Digitalización',
        type: 'yesno',
        width,
    },
    tiempo_digitalizacion: {
        value: 'T. de digitalización',
        type: 'number',
        class: {
            green_box: {
                min: 0,
                max: 47
            },
            red_box: {
                min: 48,
                max: 250
            }
        },
        label: 'hrs',
        width,
    },
    re_firma_contratos: {
        value: 'Re-firma de contratos',
        type: 'yesno',
        width,
    },
    contrato_activado: {
        value: 'Contrato activado',
        type: 'yesno',
        width,
    },
    tiempo_activacion: {
        value: 'T. de activación',
        type: 'number',
        class: {
            green_box: {
                min: 0,
                max: 47
            },
            red_box: {
                min: 48,
                max: 250
            }
        },
        label: 'hrs',
        width,
    },
    monto_pago_inicial: {
        value: 'Monto de pago inicial',
        type: 'currency',
        width,
    },
    pago_inicial_recibido: {
        value: 'Pago inicial recibido',
        type: 'yesno',
        width,
    },
    proceso_comercial_finalizado: {
        value: 'Proceso comercial finalizado',
        type: 'yesno',
        width,
    },
    cancelado: {
        value: 'Cancelado (CA)',
        type: 'yesno',
        width,
    },
    ca_fecha_modificacion: {
        value: 'FM. (CA)',
        type: 'date',
        width,
    },
    ca_usuario_modifico: {
        value: 'Mod. (CA)',
        type: 'text',
        width,
    },
    etapa_cancelacion: {
        value: 'Etapa de cancelación',
        type: 'select',
        options: [
            'Análisis de crédito',
            'Resultado de comité'
        ],
        width,
    },
    pago_realizado: {
        value: 'Rechazada (PR)',
        type: 'yesno',
        width,
    },
    pr_fecha_modificacion: {
        value: 'FM. (PR)',
        type: 'date',
        width,
    },
    pr_usuario_modifico: {
        value: 'Mod. (PR)',
        type: 'text',
        width,
    },
    proyeccion_pago: {
        value: 'Proyección de pago',
        type: 'yesno',
        width,
    },
    comentarios: {
        value: '',
        type: 'input',
        width,
    },
    mes_a_cerrar: {
        value: 'A cerrar (mes)',
        type: 'select',
        options: MESES,
        width,
    },
    semana_a_cerrar: {
        value: 'A cerrar (semana)',
        type: 'select',
        options: SEMANAS,
        width,
    },
    en_colocacion: {
        value: 'En colocación',
        type: 'yesno',
        width,
    },
    mes_en_colocacion: {
        value: 'En colocación (mes)',
        type: 'select',
        options: MESES,
        width,
    },
}