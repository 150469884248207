import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ActivosService {
    constructor(private http: HttpClient, private router: Router) { }

    ObtenerActivos( {folio = ''} ) {
        return this.http.get<any>(`${environment.config.apiUrl}activo?folio=${folio}`);
      }
    CrearActivo( formData ) {
        return this.http.post<any>(`${environment.config.apiUrl}activo`, formData);
      }
      
      ActualizarActivo( id, formData ) {
        return this.http.put<any>(`${environment.config.apiUrl}activo?id=${id}`, formData);
      }
}