
<!-- <div class="progress">
	<div class="progress-left active" (click)="changePart(1)"></div>
	<div class="progress-mid active" (click)="changePart(2)"></div>
	<div class="progress-right active" (click)="changePart(3)"></div>
</div> -->


<!-- 
	------------------------------------------------------------------------------------------------------- 
	--------------------------------------- PERSONA MORAL -------------------------------------------------
	-------------------------------------------------------------------------------------------------------
-->


<form *ngIf="tipoPersona == 2" [formGroup]="dgMForm" >
	<div class="datos_generales_1">
		<div style="text-align: center; height: 50px;">
			<span><b>Referencias<br>clientes (3)</b></span>
		</div>
		<div class="accionista-title">Referencia 1</div>
		<mat-form-field appearance="outline">
			<mat-label>Nombre de Cliente</mat-label>
			<input matInput
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="cliente_nombre1" 
				[value]="datosRef.cliente_nombre1"
				[ngClass]="{ 'is-invalid': submitted && m.cliente_nombre1.errors }"
			>
			<mat-error *ngIf="submitted && m.cliente_nombre1.errors" class="invalid-feedback">
				<div *ngIf="m.cliente_nombre1.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
		<mat-form-field appearance="outline">
			<mat-label>Contacto</mat-label>
			<input matInput
			[patterns]="customPattern"
			mask="E{100}"
				formControlName="cliente_contacto1" 
				[value]="datosRef.cliente_contacto1"
				[ngClass]="{ 'is-invalid': submitted && m.cliente_contacto1.errors }"
			>
			<mat-error *ngIf="submitted && m.cliente_contacto1.errors" class="invalid-feedback">
				<div *ngIf="m.cliente_contacto1.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label>Puesto</mat-label>
			<input matInput
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="cliente_puesto1" 
				[value]="datosRef.cliente_puesto1"
				[ngClass]="{ 'is-invalid': submitted && m.cliente_puesto1.errors }"
			>
			<mat-error *ngIf="submitted && m.cliente_puesto1.errors" class="invalid-feedback">
				<div *ngIf="m.cliente_puesto1.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label>Promedio Ventas / Mes</mat-label>
			<input matInput
			placeholder=" $ "
			prefix=" $ "
			mask="separator.2"
			thousandSeparator=","
				formControlName="cliente_promedio_compras1" 
				[value]="datosRef.cliente_promedio_compras1"
				[ngClass]="{ 'is-invalid': submitted && m.cliente_promedio_compras1.errors }"
			>
			<mat-error *ngIf="submitted && m.cliente_promedio_compras1.errors" class="invalid-feedback">
				<div *ngIf="m.cliente_promedio_compras1.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label>% del Total de Ventas</mat-label>
			<input matInput
			placeholder=" %"
			suffix=" %"
			mask="00"
				formControlName="cliente_total1" 
				[value]="datosRef.cliente_total1"
				[ngClass]="{ 'is-invalid': submitted && m.cliente_total1.errors }"
			>
			<mat-error *ngIf="submitted && m.cliente_total1.errors" class="invalid-feedback">
				<div *ngIf="m.cliente_total1.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
	</div>

	<div class="datos_generales_1">
		<div style="text-align: center; height: 50px;">
			<span style="height: 30px;"></span>
		</div>
		<div class="accionista-title">Referencia 2</div>
		<mat-form-field appearance="outline">
			<mat-label>Nombre de Cliente</mat-label>
			<input  matInput
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="cliente_nombre2" 
				[value]="datosRef.cliente_nombre2"
				[ngClass]="{ 'is-invalid': submitted && m.cliente_nombre2.errors }"
			>
			<mat-error *ngIf="submitted && m.cliente_nombre2.errors" class="invalid-feedback">
				<div *ngIf="m.cliente_nombre2.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
		<mat-form-field appearance="outline">
			<mat-label>Contacto</mat-label>
			<input matInput
			[patterns]="customPattern"
			mask="E{100}"
				formControlName="cliente_contacto2" 
				[value]="datosRef.cliente_contacto2"
				[ngClass]="{ 'is-invalid': submitted && m.cliente_contacto2.errors }"
			>
			<mat-error *ngIf="submitted && m.cliente_contacto2.errors" class="invalid-feedback">
				<div *ngIf="m.cliente_contacto2.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label>Puesto</mat-label>
			<input  matInput
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="cliente_puesto2" 
				[value]="datosRef.cliente_puesto2"
				[ngClass]="{ 'is-invalid': submitted && m.cliente_puesto2.errors }"
			>
			<mat-error *ngIf="submitted && m.cliente_puesto2.errors" class="invalid-feedback">
				<div *ngIf="m.cliente_puesto2.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label>Promedio Ventas / Mes</mat-label>
			<input  matInput
			placeholder=" $ "
			prefix=" $ "
			mask="separator.2"
			thousandSeparator=","
				formControlName="cliente_promedio_compras2" 
				[value]="datosRef.cliente_promedio_compras2"
				[ngClass]="{ 'is-invalid': submitted && m.cliente_promedio_compras2.errors }"
			>
			<mat-error *ngIf="submitted && m.cliente_promedio_compras2.errors" class="invalid-feedback">
				<div *ngIf="m.cliente_promedio_compras2.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label>% del Total de Ventas</mat-label>
			<input  matInput
			placeholder=" %"
			suffix=" %"
			mask="00"
				formControlName="cliente_total2" 
				[value]="datosRef.cliente_total2"
				[ngClass]="{ 'is-invalid': submitted && m.cliente_total2.errors }"
			>
			<mat-error *ngIf="submitted && m.cliente_total2.errors" class="invalid-feedback">
				<div *ngIf="m.cliente_total2.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
	</div>

	<div class="datos_generales_1">
		<div style="text-align: center; height: 50px;">
			<span style="height: 30px;"></span>
		</div>
		<div class="accionista-title">Referencia 3</div>
		<mat-form-field appearance="outline">
			<mat-label>Nombre de Cliente</mat-label>
			<input matInput
			[patterns]="customPattern"
			mask="E{100}"
				formControlName="cliente_nombre3" 
				[value]="datosRef.cliente_nombre3"
				[ngClass]="{ 'is-invalid': submitted && m.cliente_nombre3.errors }"
			>
			<mat-error *ngIf="submitted && m.cliente_nombre3.errors" class="invalid-feedback">
				<div *ngIf="m.cliente_nombre3.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
		<mat-form-field appearance="outline">
			<mat-label>Contacto</mat-label>
			<input  matInput
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="cliente_contacto3" 
				[value]="datosRef.cliente_contacto3"
				[ngClass]="{ 'is-invalid': submitted && m.cliente_contacto3.errors }"
			>
			<mat-error *ngIf="submitted && m.cliente_contacto3.errors" class="invalid-feedback">
				<div *ngIf="m.cliente_contacto3.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label>Puesto</mat-label>
			<input  matInput
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="cliente_puesto3" 
				[value]="datosRef.cliente_puesto3"
				[ngClass]="{ 'is-invalid': submitted && m.cliente_puesto3.errors }"
			>
			<mat-error *ngIf="submitted && m.cliente_puesto3.errors" class="invalid-feedback">
				<div *ngIf="m.cliente_puesto3.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label>Promedio Ventas / Mes</mat-label>
			<input  matInput
			placeholder=" $ "
			prefix=" $ "
			mask="separator.2"
			thousandSeparator=","
				formControlName="cliente_promedio_compras3" 
				[value]="datosRef.cliente_promedio_compras3"
				[ngClass]="{ 'is-invalid': submitted && m.cliente_promedio_compras3.errors }"
			>
			<mat-error *ngIf="submitted && m.cliente_promedio_compras3.errors" class="invalid-feedback">
				<div *ngIf="m.cliente_promedio_compras3.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label>% del Total de Ventas</mat-label>
			<input matInput
			placeholder=" %"
			suffix=" %"
			mask="00"
				formControlName="cliente_total3" 
				[value]="datosRef.cliente_total3"
				[ngClass]="{ 'is-invalid': submitted && m.cliente_total3.errors }"
			>
			<mat-error *ngIf="submitted && m.cliente_total3.errors" class="invalid-feedback">
				<div *ngIf="m.cliente_total3.errors.required">Campo obligatorio</div>
			</mat-error>
		</mat-form-field>
	</div>


<!-- 

	<h2>Referencias clientes</h2>
	<div class="row">
		
	</div>

	<div class="row">
		<div class="input">
			<input  
				formControlName="cliente_nombre2" 
				[value]="datosRef.cliente_nombre2"
				[ngClass]="{ 'is-invalid': submitted && m.cliente_nombre2.errors }"
			>
			<mat-error *ngIf="submitted && m.cliente_nombre2.errors" class="invalid-feedback">
				<div *ngIf="m.cliente_nombre2.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>
	</div>
	<div class="row">
		<div class="input">
			<input  
				formControlName="cliente_nombre3" 
				[value]="datosRef.cliente_nombre3"
				[ngClass]="{ 'is-invalid': submitted && m.cliente_nombre3.errors }"
			>
			<mat-error *ngIf="submitted && m.cliente_nombre3.errors" class="invalid-feedback">
				<div *ngIf="m.cliente_nombre3.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>
	</div>
	

	<div class="row">
		
	</div>

	<div class="row">
		<div class="input size-2">
			
			<input  
				formControlName="cliente_contacto2" 
				[value]="datosRef.cliente_contacto2"
				[ngClass]="{ 'is-invalid': submitted && m.cliente_contacto2.errors }"
			>
			<mat-error *ngIf="submitted && m.cliente_contacto2.errors" class="invalid-feedback">
				<div *ngIf="m.cliente_contacto2.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<div class="input size-2">
			
			<input  
				formControlName="cliente_puesto2" 
				[value]="datosRef.cliente_puesto2"
				[ngClass]="{ 'is-invalid': submitted && m.cliente_puesto2.errors }"
			>
			<mat-error *ngIf="submitted && m.cliente_puesto2.errors" class="invalid-feedback">
				<div *ngIf="m.cliente_puesto2.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>
	</div>

	<div class="row">
		<div class="input size-2">
			
			<input  
				formControlName="cliente_contacto3" 
				[value]="datosRef.cliente_contacto3"
				[ngClass]="{ 'is-invalid': submitted && m.cliente_contacto3.errors }"
			>
			<mat-error *ngIf="submitted && m.cliente_contacto3.errors" class="invalid-feedback">
				<div *ngIf="m.cliente_contacto3.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<div class="input size-2">
			
			<input  
				formControlName="cliente_puesto3" 
				[value]="datosRef.cliente_puesto3"
				[ngClass]="{ 'is-invalid': submitted && m.cliente_puesto3.errors }"
			>
			<mat-error *ngIf="submitted && m.cliente_puesto3.errors" class="invalid-feedback">
				<div *ngIf="m.cliente_puesto3.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>
	</div>

	<div class="row">

	</div>

	<div class="row">
		<div class="input size-2">
			
			<input  
				formControlName="cliente_promedio_compras2" 
				[value]="datosRef.cliente_promedio_compras2"
				[ngClass]="{ 'is-invalid': submitted && m.cliente_promedio_compras2.errors }"
			>
			<mat-error *ngIf="submitted && m.cliente_promedio_compras2.errors" class="invalid-feedback">
				<div *ngIf="m.cliente_promedio_compras2.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<div class="input size-2">
			
			<input  
				formControlName="cliente_total2" 
				[value]="datosRef.cliente_total2"
				[ngClass]="{ 'is-invalid': submitted && m.cliente_total2.errors }"
			>
			<mat-error *ngIf="submitted && m.cliente_total2.errors" class="invalid-feedback">
				<div *ngIf="m.cliente_total2.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>
	</div>

	<div class="row">
		<div class="input size-2">
			
			<input  
				formControlName="cliente_promedio_compras3" 
				[value]="datosRef.cliente_promedio_compras3"
				[ngClass]="{ 'is-invalid': submitted && m.cliente_promedio_compras3.errors }"
			>
			<mat-error *ngIf="submitted && m.cliente_promedio_compras3.errors" class="invalid-feedback">
				<div *ngIf="m.cliente_promedio_compras3.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<div class="input size-2">
			
			<input  
				formControlName="cliente_total3" 
				[value]="datosRef.cliente_total3"
				[ngClass]="{ 'is-invalid': submitted && m.cliente_total3.errors }"
			>
			<mat-error *ngIf="submitted && m.cliente_total3.errors" class="invalid-feedback">
				<div *ngIf="m.cliente_total3.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>
	</div> -->
</form>

<div class="row-end">
	<!-- <button mat-button (click)="back(2)">
		<svg style="height: 20px; width:auto; transform: rotate(180deg);" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><defs><style>.bca{fill:#3fa4d7;}</style></defs><path class="bca" d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0ZM22,38V18.7L13.448,27.25l-3.394-3.394L24,9.908,37.948,23.856,34.554,27.25,26,18.7V38Z" transform="translate(48) rotate(90)"/></svg>
		Regresar
	</button> -->
	<div></div>
	<mat-spinner *ngIf="loading" diameter="30"></mat-spinner>
	<!-- <button class="save-button" mat-button (click)="onSubmit(1)" style="height: 60px!important; width: 60px!important;">
		<svg style="height: 30px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="48.14" height="48.14" viewBox="0 0 48.14 48.14"><defs><style>.ab{fill:#fff;}</style></defs><path class="ab" d="M8.439,4A3.377,3.377,0,0,0,5,7.439V48.7A3.377,3.377,0,0,0,8.439,52.14H49.7A3.377,3.377,0,0,0,53.14,48.7V14.544a1.039,1.039,0,0,0-.345-.8l-9.4-9.4A1.039,1.039,0,0,0,42.6,4Zm5.731,2.292H41.678V20.047a2.3,2.3,0,0,1-2.292,2.292H16.462a2.3,2.3,0,0,1-2.292-2.292ZM32.508,9.731V18.9h4.585V9.731ZM16.462,30.362H41.678a2.3,2.3,0,0,1,2.292,2.292V49.847h-29.8V32.654A2.3,2.3,0,0,1,16.462,30.362ZM8.439,46.409h2.292V48.7H8.439Zm38.97,0H49.7V48.7H47.409Z" transform="translate(-5 -4)"/></svg>
	</button> -->
	<button *ngIf="!loading" mat-button (click)="onSubmit(2)">
		Siguiente
		<svg style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><defs><style>.abc{fill:#707070;}</style></defs><path class="abc" d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0ZM22,38V18.7L13.448,27.25l-3.394-3.394L24,9.908,37.948,23.856,34.554,27.25,26,18.7V38Z" transform="translate(48) rotate(90)"/></svg>
	</button>
</div>
