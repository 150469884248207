import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { MessageBoxComponent } from "src/app/_dialogs/message-box/message-box.component";
import { customPatterns } from "src/app/_models/maskPattern";
import { saveAs } from "file-saver";
import {
  AuthenticationService,
  SolicitudService,
  UserService,
} from "src/app/_services";
import { EntrevistaReferencias } from "../valuacion/entrevista/Entrevista";

@Component({
  selector: "app-actualizar-referencias",
  templateUrl: "./actualizar-referencias.component.html",
  styleUrls: ["./actualizar-referencias.component.scss"],
})
export class ActualizarReferenciasComponent implements OnInit {
  customPattern = customPatterns;
  submit = false;
  loading = false;
  Referencias = EntrevistaReferencias;
  requestUpdateRef= null;
  
  



  constructor(
    private authenticationService: AuthenticationService,
    private solicitudService: SolicitudService,
    private dialog: MatDialog,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    
    
    this.ObtenerReferencias();
    console.log("REFERENCIAS >>", this.Referencias)
  }
  ObtenerReferencias() {
    
    const { folio, id_cliente, tipo_persona } = JSON.parse(
      localStorage.getItem("client_details")
    );
    const guardado = { id_cliente, folio, parte: 2, tipo_persona };
    console.log(guardado);
    this.solicitudService.geteReferencias({ guardado }).subscribe(
      ({ body: res }) => {
        console.log(res);
        this.Referencias.forEach((ref, i) => {
          ref.nombre = res[`rp_nombre${i + 1}`];
          ref.domicilio = res[`rp_direccion${i + 1}`];
          ref.telefono = res[`rp_telefono${i + 1}`];
          ref.parentezco = res[`rp_parentesco${i + 1}`];
          ref.lo_recomienda = res[`lo_recomienda${i + 1}`];
          ref.ocupacion =
            res[`ocupacion${i + 1}`];
          if (tipo_persona == 2) {
            ref.nombre = res[`proveedor_nombre${i + 1}`];
            ref.nombre = res[`proveedor_puesto${i + 1}`];

          }
        });
      },
      (err) => console.log(err)
    );
  }

  ActualizarReferencias() {
    const { folio, id_cliente, tipo_persona } = JSON.parse(
      localStorage.getItem("client_details")
    );
    this.submit = true;
   
    this.loading = true;
    this.requestUpdateRef = {
      folio,
      id_cliente,
      tipo_persona,
      referencias: this.Referencias
      
    }
 console.log("ACTUALIZADAS", this.requestUpdateRef)
 this.GuardarInfoReferencias()
  }

  GuardarInfoReferencias(){
    this.solicitudService.ActualizarReferencias(this.requestUpdateRef)
    .subscribe((res)=> {
      console.log(res)
      const dialogref = this.dialog.open(MessageBoxComponent, {
        width: '500px',
        data: {
          text2: 'Éxito',
          text3: 'Se ha actualizado las Referencias',
          type: 'success',
          icon: 'success'
        }
      });
      dialogref.afterClosed().subscribe(() => {
        window.history.go(-1);
      });
    })
  }

  

  logout() {
    this.authenticationService.logout();
    this.router.navigate(["/login"]);
  }
}
