export const MejorarCotizacionJson = {
    
    email: "",
    email_code: "",
    phone: "",
    phone_code: "",
    cotizacion: {
        tipo_producto: "",
        monto: 0,
        pago_inicial: 0,
        plazo: 0,
        mensualidad: 0,
        mensualidad_mejorada: 0,
    },
    prospecto: {
        primer_nombre: "",
        segundo_nombre: "",
        apellido_paterno: "",
        apellido_materno: "",
        fecha_nacimiento: "",
        rfc: "",
        numero_celular: "",
        tipo_persona: 0,
    },
    domicilio: {
        calle: "",
        cp: "",
        ciudad: "",
        estado: "",
        delegacion_municipio:"",
        colonia: "",
    },
    equipo: {
        nombre_equipo: "",
        marca_equipo: "",
    },
    laboral: {
        especialidad: "",
        subespecialidad: "",
        certificacion_vigente: "",
        ingresos_mensuales: "",
        otros_ingresos: "",
    },


}