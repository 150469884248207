<app-sidenav-v2
[shortMenu]="true" 
>
<div class="container">
    <div class="top">
        <div class="title">
            Consulta Solicitud
        </div>
    </div>
    <div class="box">
        <div class="product_form">
            <div class="body" style="height: 70vb;">
                <div class="contenedorCajas">
                    <button mat-raised-button class="cajaOpciones" routerLink="/solicitud/dg">
                        Datos Generales
                    </button>
                    <button mat-raised-button class="cajaOpciones" routerLink="/solicitud/referencias">
                        Referencias
                    </button>
                    <button mat-raised-button class="cajaOpciones" routerLink="/solicitud/aval">
                        Aval
                    </button>
                </div>
            </div>
        </div>

    </div>
    <app-socials-footer style="width:100%;"></app-socials-footer>
</div>

</app-sidenav-v2>