import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-home',
  templateUrl: './new-home.component.html',
  styleUrls: ['./new-home.component.scss']
})
export class NewHomeComponent implements OnInit {
  @Input() precalificar = false
  @Input() solicitudes = true
  @Input() ver_productos = false
  @Input() cotizar = false
  @Output() MostrarSolicitudes = new EventEmitter

  buttons = []
  constructor(private router: Router) { }

  ngOnInit(): void {
    localStorage.removeItem('cotizar_producto')

    this.buttons = [
      {
        title: "Solicitudes",
        target: 1,
        icon: 'dashboard',
        show: this.solicitudes,
      },
      {
        title: "Administrar productos",
        target: 2,
        icon: 'view_list',
        show: this.ver_productos,
      },
      {
        title: "Cotizar",
        target: 3,
        icon: 'request_page',
        show: this.cotizar,
      },
      // {
      //   title: "Nueva Solicitud",
      //   target: 4,
      //   icon: 'note_add'
      // },
      {
        title: "Precalificar",
        target: 5,
        icon: 'newspaper',
        show: this.precalificar,
      },
      
    ]
  }

  Abrir(type) {
    console.log('type: ', type);
    switch (type) {
      case 1:
        this.router.navigate(['dashboard','solicitudes'])
        break;
      case 2:
        this.router.navigate(['productos', 'admin'])
        break;
      case 3:
        this.router.navigate(['crear_cotizacion'])
        break;
      case 4:
        return;
        break;
      case 5:
        this.router.navigate(['dashboard','comite','valuacion','consultaBuro','3'])
        break;
    }
  }
}
