export class Activo {
    id: number = 0
    folio: string
    name: string = ''
    description: string
    value: any
    empresa: string
    marca: string
    model: string
    currency: string = 'MXN'
    serial_number: string
    proveedor: string
    factura_relacionada: string
    documentos_relacionados: string  
	quantity: number = 0
    isValid(): Boolean {
		if (this.folio == '' ||
			this.description == '' ||
			this.empresa == '' ||
			this.serial_number == '' ||
			!(this.value > 0)
		) {
			return false
		}
		return true
	} 
    fromJson(json) {
		console.log(`Constructor:`, json);
		this.id = json.id
		this.folio = json.vendor_id
		this.name = json.name
		this.description = json.description
		this.value = json.model
		this.empresa = json.year
		this.marca = json.warranty
		this.model = json.value
		this.currency = json.customer_price
		this.serial_number = json.suggested_customer_price
		this.proveedor = json.currency
		this.factura_relacionada = json.accessories
		this.documentos_relacionados = json.url_image
	}
}