<!-- <mat-toolbar color="primary" *ngIf="currentUser">
    <button
      mat-icon-button
      aria-label="menu"
      class="menu"
      (click)="sidenav.toggle()"
    >
      <mat-icon>menu</mat-icon>
    </button> 
    <a routerLink="/home">
      <img class="img-fluid" src="..https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/logo-header.svg">
    </a>
    <span class="fx-spacer"></span>
    <button mat-button [matMenuTriggerFor]="submenu">Menu 1</button>
    <button mat-button>Menu 2</button>
    <span class="example-spacer"></span>
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon>more_vert</mat-icon>
    </button>
  
    <mat-menu #submenu="matMenu">
      <button mat-menu-item>Sub-menu 1</button>
      <button mat-menu-item [matMenuTriggerFor]="submenu2">Sub-menu 2</button>
    </mat-menu>
  
    <mat-menu #submenu2="matMenu">
      <button mat-menu-item>Menu Item 1</button>
      <button mat-menu-item>Menu Item 2</button>
      <button mat-menu-item>Menu Item 3</button>
    </mat-menu>

    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="logout()">
            <mat-icon>exit_to_app</mat-icon>
            <span>Logout</span>
        </button>
        <button mat-menu-item routerLink="/changePassword">
            <mat-icon>fingerprint</mat-icon>
            <span>Cambiar contraseña</span>
        </button>
    </mat-menu>

</mat-toolbar> -->

<!-- <mat-sidenav-container>
  <mat-sidenav [disableClose]="false" mode="over" #sidenav>
    <mat-nav-list>
      <a
        id="home"
        mat-list-item
        [routerLink]="['../login']"
        (click)="sidenav.close()"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <mat-icon matListAvatar>home</mat-icon>
        <h3 matLine>Home</h3>
        <p matLine>sample home page</p>
      </a>
      <a
        id="sampleData"
        mat-list-item
        [routerLink]="['cspw']"
        (click)="sidenav.close()"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <mat-icon matListAvatar>grid_on</mat-icon>
        <h3 matLine>Data</h3>
        <p matLine>sample data page</p>
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content> -->
    <div class="container-alert">
      <alert></alert>
    </div>
    <router-outlet></router-outlet>
    

<!-- <angular7-pincode size="6">
</angular7-pincode> -->
  <!-- </mat-sidenav-content>
</mat-sidenav-container> -->








<!-- nav -->
<!-- <nav class="navbar navbar-expand navbar-dark bg-dark" *ngIf="currentUser">
  <div class="navbar-nav">
      <a class="nav-item nav-link" routerLink="/">Home</a>
      <a class="nav-item nav-link" (click)="logout()">Logout</a>
  </div>
</nav> -->

<!-- main app container -->
<!-- <div class="jumbotron"> -->
  <!-- <div class="container"> -->
      <!-- <div class="row"> -->
          <!-- <div class="col-sm-6 offset-sm-3"> -->
              <!-- <alert></alert> -->
              <!-- <router-outlet></router-outlet>
          </div>
      </div>
  </div>
</div> -->