<div style="width: 100%;">
  <div style="overflow-x: scroll;">
    <table class="table_evaluacion_financiera">
      <tr>
        <th></th>
        <th></th>
        <th>2022</th>
        <th>2023</th>
        <th  style="width: 65px">PARCIAL 2024</th>
        <th  style="width: 65px">VARIACIÓN 22-23</th>
        <th  style="width: 65px">VARIACIÓN 23-24</th>
        <th  style="width: 65px">PROMEDIO</th>
        <th  style="width: 65px">PUNTOS</th>
        <th  style="width: 65px">PARÁMETROS</th>
      </tr>
      <tr>
        <th rowspan="2">Liquidez</th>
        <td>Liquidez</td>
        <td>
          <input  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," style="width: 85px"  readonly [(ngModel)]="AnalisisPM.capacidad_pago.liquidez.corto_plazo._2022"  name="liquidez_2022" />
        </td>
        <td>
          <input  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," style="width: 85px"  readonly [(ngModel)]="AnalisisPM.capacidad_pago.liquidez.corto_plazo._2023" name="liquidez_2023" />
        </td>
        <td><input  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","  style="width: 85px" readonly [(ngModel)]="AnalisisPM.capacidad_pago.liquidez.corto_plazo._2024" name="liquidez_parcial_2024" /></td>
        <td><input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","  style="width: 85px" readonly  [(ngModel)]="AnalisisPM.capacidad_pago.liquidez.corto_plazo.variacion_22_23" name="liquidez_variacion_22_23" /></td>
        <td><input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","  style="width: 85px" readonly [(ngModel)]="AnalisisPM.capacidad_pago.liquidez.corto_plazo.variacion_23_24" name="liquidez_variacion_23_24" /></td>
        <td><input  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","  style="width: 85px" readonly [(ngModel)]="AnalisisPM.capacidad_pago.liquidez.corto_plazo.promedio" name="liquidez_promedio" /></td>
        <td><input   style="width: 85px" [(ngModel)]="this.AnalisisPM.capacidad_pago.puntos_liquidez" name="liquidez_puntos" (ngModelChange)="recalcularTotalPuntosRF()"/></td>
        <td><input style="width: 85px" value=">=1" readonly name="liquidez_parametros" /></td>
      </tr>
      <tr>
        <td>Capital de Trabajo</td>
        <td><input  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," style="width: 85px" readonly [(ngModel)]="AnalisisPM.capacidad_pago.liquidez.capital_trabajo._2022" name="capital_2022" /></td>
        <td><input  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," style="width: 85px" readonly [(ngModel)]="AnalisisPM.capacidad_pago.liquidez.capital_trabajo._2023" name="capital_2023" /></td>
        <td><input  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," style="width: 85px" readonly [(ngModel)]="AnalisisPM.capacidad_pago.liquidez.capital_trabajo._2024" name="capital_parcial_2024" /></td>
        <td><input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," style="width: 85px" readonly [(ngModel)]="AnalisisPM.capacidad_pago.liquidez.capital_trabajo.variacion_22_23" name="capital_variacion_22_23" /></td>
        <td><input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," style="width: 85px" readonly [(ngModel)]="AnalisisPM.capacidad_pago.liquidez.capital_trabajo.variacion_23_24" name="capital_variacion_23_24" /></td>
        <td><input  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," style="width: 85px" readonly [(ngModel)]="AnalisisPM.capacidad_pago.liquidez.capital_trabajo.promedio" name="capital_promedio" /></td>
        <td><input   [(ngModel)]="this.AnalisisPM.capacidad_pago.puntos_capital_trabajo" style="width: 85px" name="capital_puntos" (ngModelChange)="recalcularTotalPuntosRF()"/></td>
        <td><input  thousandSeparator="," style="width: 85px" readonly value="POSITIVO" name="capital_parametros" /></td>
      </tr>
      <tr>
        <th rowspan="2">Deuda</th>
        <td>Endeudamiento</td>
        <td>
          <input suffix="%"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," style="width: 85px"  readonly [(ngModel)]="AnalisisPM.capacidad_pago.deuda.endeudamiento._2022 " name="endeudamiento_2022" />
        </td>
        <td>
          <input suffix="%"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," style="width: 85px"  readonly [(ngModel)]="AnalisisPM.capacidad_pago.deuda.endeudamiento._2023" name="endeudamiento_2023" />
        </td>
        <td><input suffix="%"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","  style="width: 85px" readonly [(ngModel)]="AnalisisPM.capacidad_pago.deuda.endeudamiento._2024" name="endeudamiento_parcial_2024" /></td>
        <td><input suffix="%"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","  style="width: 85px" readonly [(ngModel)]="AnalisisPM.capacidad_pago.deuda.endeudamiento.variacion_22_23" name="endeudamiento_variacion_22_23" /></td>
        <td><input suffix="%"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","  style="width: 85px" readonly [(ngModel)]="AnalisisPM.capacidad_pago.deuda.endeudamiento.variacion_23_24" name="endeudamiento_variacion_23_24" /></td>
        <td><input suffix="%"  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","  style="width: 85px" readonly [(ngModel)]="AnalisisPM.capacidad_pago.deuda.endeudamiento.promedio" name="endeudamiento_promedio" /></td>
        <td><input   style="width: 85px"[(ngModel)]="this.AnalisisPM.capacidad_pago.puntos_endeudamiento" name="endeudamiento_puntos" (ngModelChange)="recalcularTotalPuntosRF()"/></td>
        <td><input  style="width: 85px" readonly value="<=60%" name="endeudamiento_parametros" /></td>
      </tr>
      <tr>
        <td>Apalancamiento externo</td>
        <td>
          <input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," style="width: 85px"  readonly [(ngModel)]="AnalisisPM.capacidad_pago.deuda.apalancamiento_ext._2022" name="apalancamiento_2022" />
        </td>
        <td>
          <input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," style="width: 85px"  readonly [(ngModel)]="AnalisisPM.capacidad_pago.deuda.apalancamiento_ext._2023" name="apalancamiento_2023" />
        </td>
        <td><input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","  style="width: 85px"  readonly [(ngModel)]="AnalisisPM.capacidad_pago.deuda.apalancamiento_ext._2024" name="apalancamiento_parcial_2024" /></td>
        <td><input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","  style="width: 85px"  readonly [(ngModel)]="AnalisisPM.capacidad_pago.deuda.apalancamiento_ext.variacion_22_23" name="apalancamiento_variacion_22_23" /></td>
        <td><input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","  style="width: 85px"  readonly [(ngModel)]="AnalisisPM.capacidad_pago.deuda.apalancamiento_ext.variacion_23_24" name="apalancamiento_variacion_23_24" /></td>
        <td><input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","  style="width: 85px"  readonly [(ngModel)]="AnalisisPM.capacidad_pago.deuda.apalancamiento_ext.promedio" name="apalancamiento_promedio" /></td>
        <td><input    thousandSeparator=","  style="width: 85px" [(ngModel)]="this.AnalisisPM.capacidad_pago.puntos_apalancamiento_ext" name="apalancamiento_puntos" (ngModelChange)="recalcularTotalPuntosRF()"/></td>
        <td><input   style="width: 85px"  readonly value="<=60%" name="apalancamiento_parametros" /></td>
      </tr>
      <tr>
        <th rowspan="3">Rendimiento</th>
        <td>Quiebra técnica</td>
        <td>
          <input  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","
            style="width: 85px"
            [(ngModel)]="AnalisisPM.capacidad_pago.rendimiento.quiebra_tecnica._2022"
            name="quiebra_tecnica_2022"
            readonly
          suffix="%"
          />
        </td>
        <td>
          <input  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","
            style="width: 85px"
            [(ngModel)]="AnalisisPM.capacidad_pago.rendimiento.quiebra_tecnica._2023"
            name="quiebra_tecnica_2023"
            readonly
suffix="%"
          />
        </td>
        <td><input suffix="%" readonly mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","  style="width: 85px"  name="quiebra_tecnica_parcial_2024" [(ngModel)]="AnalisisPM.capacidad_pago.rendimiento.quiebra_tecnica._2024" /></td>
        <td><input suffix="%" readonly mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","  style="width: 85px"  name="quiebra_tecnica_variacion_22_23" [(ngModel)]="AnalisisPM.capacidad_pago.rendimiento.quiebra_tecnica.variacion_22_23" /></td>
        <td><input suffix="%" readonly mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","  style="width: 85px"  name="quiebra_tecnica_variacion_23_24" [(ngModel)]="AnalisisPM.capacidad_pago.rendimiento.quiebra_tecnica.variacion_23_24" /></td>
        <td><input suffix="%" readonly mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","  style="width: 85px"  name="quiebra_tecnica_promedio" [(ngModel)]="AnalisisPM.capacidad_pago.rendimiento.quiebra_tecnica.promedio" /></td>
        <td><input  style="width: 85px"  name="quiebra_tecnica_puntos" [(ngModel)]="quiebra_tecnica_puntos" (ngModelChange)="recalcularTotalPuntosRF()" [(ngModel)]="this.AnalisisPM.capacidad_pago.puntos_quiebra_tecnica"/></td>
        <td><input  style="width: 85px"  name="quiebra_tecnica_parametros" readonly readonly value=">=33%" /></td>
      </tr>
      <tr>
        <td>Rendimiento sobre activo(ROA)</td>
        <td>
          <input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," style="width: 85px" readonly [(ngModel)]="AnalisisPM.capacidad_pago.rentabilidad.activos_roa._2022" name="rendimiento_2022" />
        </td>
        <td>
          <input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," style="width: 85px" readonly [(ngModel)]="AnalisisPM.capacidad_pago.rentabilidad.activos_roa._2023" name="rendimiento_2023" />
        </td>
        <td><input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","  style="width: 85px" readonly [(ngModel)]="AnalisisPM.capacidad_pago.rentabilidad.activos_roa._2024" name="rendimiento_parcial_2024" /></td>
        <td><input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","  style="width: 85px" readonly [(ngModel)]="AnalisisPM.capacidad_pago.rentabilidad.activos_roa.variacion_22_23" name="rendimiento_variacion_22_23" /></td>
        <td><input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","  style="width: 85px" readonly [(ngModel)]="AnalisisPM.capacidad_pago.rentabilidad.activos_roa.variacion_23_24" name="rendimiento_variacion_23_24" /></td>
        <td><input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","  style="width: 85px" readonly [(ngModel)]="AnalisisPM.capacidad_pago.rentabilidad.activos_roa.promedio" name="rendimiento_promedio" /></td>
        <td><input  style="width: 85px" [(ngModel)]="this.AnalisisPM.capacidad_pago.puntos_rendimiento_roa" name="rendimiento_puntos" (ngModelChange)="recalcularTotalPuntosRF()"/></td>
        <td><input  style="width: 85px" readonly value=">=8%" name="rendimiento_parametros" /></td>
      </tr>
      <tr>
        <td>Rendimiento sobre capital(ROE)</td>
        <td>
          <input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," style="width: 85px" readonly [(ngModel)]="AnalisisPM.capacidad_pago.rentabilidad.capital_roe._2022" name="rendimiento_2022" />
        </td>
        <td>
          <input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," style="width: 85px" readonly [(ngModel)]="AnalisisPM.capacidad_pago.rentabilidad.capital_roe._2023" name="rendimiento_2023" />
        </td>
        <td><input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","  style="width: 85px" readonly [(ngModel)]="AnalisisPM.capacidad_pago.rentabilidad.capital_roe._2024" name="rendimiento_parcial_2024" /></td>
        <td><input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","  style="width: 85px" readonly [(ngModel)]="AnalisisPM.capacidad_pago.rentabilidad.capital_roe.variacion_22_23" name="rendimiento_variacion_22_23" /></td>
        <td><input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","  style="width: 85px" readonly [(ngModel)]="AnalisisPM.capacidad_pago.rentabilidad.capital_roe.variacion_23_24" name="rendimiento_variacion_23_24" /></td>
        <td><input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","  style="width: 85px" readonly [(ngModel)]="AnalisisPM.capacidad_pago.rentabilidad.capital_roe.promedio" name="rendimiento_promedio" /></td>
        <td><input  style="width: 85px" [(ngModel)]="this.AnalisisPM.capacidad_pago.puntos_capital_roe"  name="rendimiento_puntos" (ngModelChange)="recalcularTotalPuntosRF()"/></td>
        <td><input style="width: 85px" readonly value=">=10%" name="rendimiento_parametros" /></td>
      </tr>
      <tr>
        <th rowspan="2">Rentabilidad</th>
        <td>Margen Utilidad Operativa</td>
        <td>
          <input  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","
            style="width: 85px"
            readonly
            [(ngModel)]="AnalisisPM.capacidad_pago.margenes.operativo._2022"
            name="margen_utilidad_operativa_2022"
          suffix="%"
          />
        </td>
        <td>
          <input  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","
            style="width: 85px"
            readonly
            [(ngModel)]="AnalisisPM.capacidad_pago.margenes.operativo._2023"
            name="margen_utilidad_operativa_2023"
suffix="%"
          />
        </td>
        <td>
          <input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","  style="width: 85px" readonly [(ngModel)]="AnalisisPM.capacidad_pago.margenes.operativo._2024" name="margen_utilidad_operativa_parcial_2024" />
        </td>
        <td>
          <input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","
          style="width: 85px"
            [(ngModel)]="AnalisisPM.capacidad_pago.margenes.operativo.variacion_22_23"
            readonly
            name="margen_utilidad_operativa_variacion_22_23"
          />
        </td>
        <td>
          <input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," style="width: 85px"
            [(ngModel)]="AnalisisPM.capacidad_pago.margenes.operativo.variacion_23_24"
            readonly
            name="margen_utilidad_operativa_variacion_23_24"
          />
        </td>
        <td>
          <input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","  style="width: 85px" [(ngModel)]="AnalisisPM.capacidad_pago.margenes.operativo.promedio" readonly name="margen_utilidad_operativa_promedio" />
        </td>
        <td><input  style="width: 85px" [(ngModel)]="this.AnalisisPM.capacidad_pago.puntos_margen_operativo" name="margen_utilidad_operativa_puntos" (ngModelChange)="recalcularTotalPuntosRF()"/></td>
        <td>
          <input  style="width: 85px" value=">=5%" readonly name="margen_utilidad_operativa_parametros" />
        </td>
      </tr>
      <tr>
        <td>Margen Utilidad neta</td>
        <td>
          <input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," style="width: 85px" [(ngModel)]="AnalisisPM.capacidad_pago.margenes.neto._2022" readonly name="margen_utilidad_neta_2022"
          />
        </td>
        <td>
          <input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," style="width: 85px" [(ngModel)]="AnalisisPM.capacidad_pago.margenes.neto._2023" readonly name="margen_utilidad_neta_2023"
          />
        </td>
        <td>
          <input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","  style="width: 85px" readonly [(ngModel)]="AnalisisPM.capacidad_pago.margenes.neto._2024" readonly name="margen_utilidad_neta_parcial_2024" />
        </td>
        <td>
          <input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","  style="width: 85px" readonly [(ngModel)]="AnalisisPM.capacidad_pago.margenes.neto.variacion_22_23" name="margen_utilidad_neta_variacion_22_23" />
        </td>
        <td>
          <input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","  style="width: 85px" readonly [(ngModel)]="AnalisisPM.capacidad_pago.margenes.neto.variacion_23_24" name="margen_utilidad_neta_variacion_23_24" />
        </td>
        <td><input suffix="%" mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator=","  style="width: 85px" readonly [(ngModel)]="AnalisisPM.capacidad_pago.margenes.neto.promedio" name="margen_utilidad_neta_promedio" /></td>
        <td><input  style="width: 85px"  [(ngModel)]="this.AnalisisPM.capacidad_pago.puntos_margen_neta" name="margen_utilidad_neta_puntos" (ngModelChange)="recalcularTotalPuntosRF()" /></td>
        <td><input    style="width: 85px" readonly value=">=7%" name="margen_utilidad_neta_parametros" /></td>
      </tr>
      <tr>
        <td style="width: 85px"></td>
        <td style="width: 85px"></td>
        <td style="width: 85px"></td>
        <td style="width: 85px"></td>
        <td style="width: 85px"></td>
        <td style="width: 85px"></td>
        <td style="width: 85px"></td>
        <td style="width: 85px"></td>
        <td > <b><input  mask="separator.2" [allowNegativeNumbers]="true"  thousandSeparator="," style="width: 85px" [(ngModel)]="AnalisisPM.capacidad_pago.total_puntos_parametros" /></b></td>
      </tr>
    </table>
  </div>

  <div style="height: 60px"></div>
  <div style="display: grid; grid-template-columns: 40% 40%; gap: 5%">
    <table class="table_evaluacion_financiera">
      <tr>
        <th></th>
        <th></th>
        <th>2022</th>
        <th>2023</th>
        <th>PARCIAL 2024</th>
      </tr>
      <tr>
        <th rowspan="3">Margenes</th>
        <td>Bruto</td>
        <td><input  suffix=" %" mask="separator.2" [allowNegativeNumbers]="true"  readonly style="width: 85px"  name="bruto_2022" [(ngModel)]="AnalisisPM.capacidad_pago.margenes.bruto._2022"/></td>
        <td><input  suffix=" %" mask="separator.2" [allowNegativeNumbers]="true"  readonly style="width: 85px"  name="bruto_2023" [(ngModel)]="AnalisisPM.capacidad_pago.margenes.bruto._2023"/></td>
        <td><input  suffix=" %" mask="separator.2" [allowNegativeNumbers]="true"  readonly style="width: 85px"  name="bruto_parcial_2024" [(ngModel)]="AnalisisPM.capacidad_pago.margenes.bruto._2024"/></td>
      </tr>
      <tr>
        <td>Operativo</td>
        <td>
          <input suffix=" %" mask="separator.2" [allowNegativeNumbers]="true"  readonly  style="width: 85px"  name="operativo_2022" [(ngModel)]="AnalisisPM.capacidad_pago.margenes.operativo._2022" />
        </td>
        <td>
          <input suffix=" %" mask="separator.2" [allowNegativeNumbers]="true"  readonly  style="width: 85px"  name="operativo_2023" [(ngModel)]="AnalisisPM.capacidad_pago.margenes.operativo._2023"/>
        </td>
        <td><input  suffix=" %" mask="separator.2" [allowNegativeNumbers]="true"  readonly style="width: 85px"  name="operativo_parcial_2024" [(ngModel)]="AnalisisPM.capacidad_pago.margenes.operativo._2024"/></td>
      </tr>
      <tr>
        <td>Neto</td>
        <td><input  suffix=" %" mask="separator.2" [allowNegativeNumbers]="true"  readonly  style="width: 85px"  name="neto_2022" [(ngModel)]="AnalisisPM.capacidad_pago.margenes.neto._2022"/></td>
        <td><input  suffix=" %" mask="separator.2" [allowNegativeNumbers]="true"  readonly  style="width: 85px"  name="neto_2023" [(ngModel)]="AnalisisPM.capacidad_pago.margenes.neto._2023"/></td>
        <td><input  suffix=" %" mask="separator.2" [allowNegativeNumbers]="true"  readonly  style="width: 85px"  name="neto_parcial_2024" [(ngModel)]="AnalisisPM.capacidad_pago.margenes.neto._2024"/></td>
      </tr>
      <tr style="height: 20px"></tr>
      <tr>
        <th rowspan="3">Liquidez</th>
        <td>Capital de trabajo</td>
        <td>
          <input
            style="width: 85px"
           mask="separator.2" readonly 
            name="capital_trabajo_2022"
            [(ngModel)]="AnalisisPM.capacidad_pago.liquidez.capital_trabajo._2022"
          [allowNegativeNumbers]="true" 
          />
        </td>
        <td>
          <input
            style="width: 85px"
             mask="separator.2" readonly 
            name="capital_trabajo_2023"
            [(ngModel)]="AnalisisPM.capacidad_pago.liquidez.capital_trabajo._2023"
            [allowNegativeNumbers]="true" 
          />
        </td>
        <td><input style="width: 85px"   mask="separator.2" readonly  name="capital_trabajo_parcial_2024" [(ngModel)]="AnalisisPM.capacidad_pago.liquidez.capital_trabajo._2024"   [allowNegativeNumbers]="true"        /></td>
      </tr>
      <tr>
        <td>Corto Plazo</td>
        <td>
          <input  mask="separator.2" readonly  style="width: 85px"  name="corto_plazo_2022"  [(ngModel)]="AnalisisPM.capacidad_pago.liquidez.corto_plazo._2022" [allowNegativeNumbers]="true" />
        </td>
        <td>
          <input  mask="separator.2" readonly  style="width: 85px"  name="corto_plazo_2023" [(ngModel)]="AnalisisPM.capacidad_pago.liquidez.corto_plazo._2023" [allowNegativeNumbers]="true" />
        </td>
        <td><input   mask="separator.2" readonly style="width: 85px" name="corto_plazo_parcial_2024" [(ngModel)]="AnalisisPM.capacidad_pago.liquidez.corto_plazo._2024" [allowNegativeNumbers]="true" /></td>
      </tr>
      <tr>
        <td>Ácida</td>
        <td><input  mask="separator.2" readonly style="width: 85px"  name="acida_2022" [(ngModel)]="AnalisisPM.capacidad_pago.liquidez.acida._2022" [allowNegativeNumbers]="true" /></td>
        <td><input  mask="separator.2" readonly style="width: 85px"  name="acida_2023" [(ngModel)]="AnalisisPM.capacidad_pago.liquidez.acida._2023" [allowNegativeNumbers]="true" /></td>
        <td><input  mask="separator.2" readonly style="width: 85px" name="acida_parcial_2024" [(ngModel)]="AnalisisPM.capacidad_pago.liquidez.acida._2024" [allowNegativeNumbers]="true" /></td>
      </tr>
      <tr style="height: 20px"></tr>
      <tr>
        <th rowspan="3">Rentabilidad</th>
        <td>Ventas</td>
        <td><input suffix=" %" mask="separator.2" [allowNegativeNumbers]="true"  readonly style="width: 85px"  name="ventas_2022" [(ngModel)]="AnalisisPM.capacidad_pago.rentabilidad.ventas._2022" /></td>
        <td><input suffix=" %" mask="separator.2" [allowNegativeNumbers]="true"  readonly style="width: 85px"  name="ventas_2023" [(ngModel)]="AnalisisPM.capacidad_pago.rentabilidad.ventas._2023" /></td>
        <td><input suffix=" %" mask="separator.2" [allowNegativeNumbers]="true"  readonly style="width: 85px"  name="ventas_parcial_2024" [(ngModel)]="AnalisisPM.capacidad_pago.rentabilidad.ventas._2024" /></td>
      </tr>
      <tr>
        <td>Activos(ROA)</td>
        <td>
          <input suffix=" %" mask="separator.2" [allowNegativeNumbers]="true"  readonly style="width: 85px"  name="activos_roa_2022" [(ngModel)]="AnalisisPM.capacidad_pago.rentabilidad.activos_roa._2022"/>
        </td>
        <td>
          <input suffix=" %" mask="separator.2" [allowNegativeNumbers]="true"  readonly style="width: 85px"  name="activos_roa_2023" [(ngModel)]="AnalisisPM.capacidad_pago.rentabilidad.activos_roa._2023"/>
        </td>
        <td><input suffix=" %" mask="separator.2" [allowNegativeNumbers]="true"  readonly style="width: 85px"  name="activos_roa_parcial_2024" [(ngModel)]="AnalisisPM.capacidad_pago.rentabilidad.activos_roa._2024"/></td>
      </tr>
      <tr>
        <td>Capital(ROE)</td>
        <td>
          <input suffix=" %" [allowNegativeNumbers]="true" mask="separator.2" readonly style="width: 85px"  name="capital_roe_2022" [(ngModel)]="AnalisisPM.capacidad_pago.rentabilidad.capital_roe._2022" [allowNegativeNumbers]="true" />
        </td>
        <td>
          <input suffix=" %" [allowNegativeNumbers]="true" mask="separator.2" readonly style="width: 85px"  name="capital_roe_2023" [(ngModel)]="AnalisisPM.capacidad_pago.rentabilidad.capital_roe._2023" [allowNegativeNumbers]="true" />
        </td>
        <td><input suffix=" %" [allowNegativeNumbers]="true" mask="separator.2" readonly style="width: 85px"  name="capital_roe_parcial_2024" [(ngModel)]="AnalisisPM.capacidad_pago.rentabilidad.capital_roe._2024" [allowNegativeNumbers]="true" /></td>
      </tr>
    </table>
    <table class="table_evaluacion_financiera">
      <tr>
        <th></th>
        <th></th>
        <th>2022</th>
        <th>2023</th>
        <th>PARCIAL 2024</th>
      </tr>
      <tr>
        <th rowspan="4">DEUDA</th>
        <td>Endeudamiento</td>
        <td>
          <input suffix=" %"  [allowNegativeNumbers]="true" mask="separator.2" readonly style="width: 85px"  name="endeudamiento_2022" [(ngModel)]="AnalisisPM.capacidad_pago.deuda.endeudamiento._2022" />
        </td>
        <td>
          <input suffix=" %"  [allowNegativeNumbers]="true" mask="separator.2" readonly style="width: 85px"  name="endeudamiento_2023" [(ngModel)]="AnalisisPM.capacidad_pago.deuda.endeudamiento._2023" />
        </td>
        <td><input suffix=" %"  [allowNegativeNumbers]="true" mask="separator.2" readonly style="width: 85px"  name="endeudamiento_parcial_2024" [(ngModel)]="AnalisisPM.capacidad_pago.deuda.endeudamiento._2024" /></td>
      </tr>
      <tr>
        <td>Apalancamiento externo</td>
        <td>
          <input
            style="width: 85px"
            [(ngModel)]="AnalisisPM.capacidad_pago.deuda.apalancamiento_ext._2022"
            name="apalancamiento_externo_2022"
            suffix=" %" mask="separator.2" readonly
            [allowNegativeNumbers]="true"
          />
        </td>
        <td>
          <input
            style="width: 85px"
            [(ngModel)]="AnalisisPM.capacidad_pago.deuda.apalancamiento_ext._2023"
            name="apalancamiento_externo_2023"
            suffix=" %" mask="separator.2" readonly
            [allowNegativeNumbers]="true"
          />
        </td>
        <td>
          <input  suffix=" %" mask="separator.2" [allowNegativeNumbers]="true" readonly style="width: 85px" [(ngModel)]="AnalisisPM.capacidad_pago.deuda.apalancamiento_ext._2024" name="apalancamiento_externo_parcial_2024" />
        </td>
      </tr>
      <tr>
        <td>Cobertura de Intereses</td>
        <td>
          <input
            style="width: 85px"
            [(ngModel)]="AnalisisPM.capacidad_pago.deuda.cobertura_intereses._2022"
            name="cobertura_intereses_2022"
            suffix=" %" mask="separator.2" readonly
            [allowNegativeNumbers]="true"
          />
        </td>
        <td>
          <input
            style="width: 85px"
            [(ngModel)]="AnalisisPM.capacidad_pago.deuda.cobertura_intereses._2023"
            name="cobertura_intereses_2023"
            suffix=" %" mask="separator.2" readonly
            [allowNegativeNumbers]="true"
          />
        </td>
        <td><input suffix=" %" mask="separator.2" [allowNegativeNumbers]="true" readonly style="width: 85px" [(ngModel)]="AnalisisPM.capacidad_pago.deuda.cobertura_intereses._2024" name="cobertura_intereses_parcial_2024" /></td>
      </tr>
      <tr>
        <td>Cobertura de efectivo</td>
        <td>
          <input
            style="width: 85px"
            suffix=" %" mask="separator.2" readonly [(ngModel)]="AnalisisPM.capacidad_pago.deuda.cobertura_efectivo._2022"
            name="cobertura_efectivo_2022"
            [allowNegativeNumbers]="true"
          />
        </td>
        <td>
          <input
            style="width: 85px"
            suffix=" %" mask="separator.2" readonly [(ngModel)]="AnalisisPM.capacidad_pago.deuda.cobertura_efectivo._2023"
            name="cobertura_efectivo_2023"
            [allowNegativeNumbers]="true"
          />
        </td>
        <td><input style="width: 85px" suffix=" %" mask="separator.2" readonly [(ngModel)]="AnalisisPM.capacidad_pago.deuda.cobertura_efectivo._2024" name="cobertura_efectivo_parcial_2024" [allowNegativeNumbers]="true" /></td>
      </tr>
      <tr style="height: 20px"></tr>
      <tr>
        <th rowspan="4">Rotación</th>
        <td>Rotación CxC</td>
        <td>
          <input style="width: 85px"  [allowNegativeNumbers]="true" style="width: 85px" suffix=" %" mask="separator.2" readonly [(ngModel)]="AnalisisPM.capacidad_pago.rotacion.rotacion_cxc._2022" name="rotacion_cxc_2022" />
        </td>
        <td>
          <input style="width: 85px"  [allowNegativeNumbers]="true" style="width: 85px" suffix=" %" mask="separator.2" readonly [(ngModel)]="AnalisisPM.capacidad_pago.rotacion.rotacion_cxc._2023" name="rotacion_cxc_2023" />
        </td>
        <td><input style="width: 85px"  [allowNegativeNumbers]="true" style="width: 85px" suffix=" %" mask="separator.2" readonly [(ngModel)]="AnalisisPM.capacidad_pago.rotacion.rotacion_cxc._2024" name="rotacion_cxc_parcial_2024" /></td>
      </tr>
      <tr>
        <td>Rotación de inventarios</td>
        <td>
          <input
            style="width: 85px"
            style="width: 85px" suffix=" %" mask="separator.2" readonly [(ngModel)]="AnalisisPM.capacidad_pago.rotacion.rotacion_inventarios._2022"
            name="rotacion_inventarios_2022"
            [allowNegativeNumbers]="true"
          />
        </td>
        <td>
          <input
            style="width: 85px"
            style="width: 85px" suffix=" %" mask="separator.2" readonly [(ngModel)]="AnalisisPM.capacidad_pago.rotacion.rotacion_inventarios._2023"
            name="rotacion_inventarios_2023"
            [allowNegativeNumbers]="true"
          />
        </td>
        <td>
          <input style="width: 85px" style="width: 85px" suffix=" %" mask="separator.2" readonly [(ngModel)]="AnalisisPM.capacidad_pago.rotacion.rotacion_inventarios._2024" [allowNegativeNumbers]="true" name="rotacion_inventarios_parcial_2024" />
        </td>
      </tr>
      <tr>
        <td>Rotación Proveedores</td>
        <td>
          <input
            style="width: 85px"
            style="width: 85px" suffix=" %" mask="separator.2" readonly [(ngModel)]="AnalisisPM.capacidad_pago.rotacion.rotacion_proveedores._2022"
            name="rotacion_proveedores_2022"
            [allowNegativeNumbers]="true"
          />
        </td>
        <td>
          <input
            style="width: 85px"
            style="width: 85px" suffix=" %" mask="separator.2" readonly [(ngModel)]="AnalisisPM.capacidad_pago.rotacion.rotacion_proveedores._2023"
            name="rotacion_proveedores_2023"
            [allowNegativeNumbers]="true"
          />
        </td>
        <td>
          <input style="width: 85px" [allowNegativeNumbers]="true" style="width: 85px" suffix=" %" mask="separator.2" readonly [(ngModel)]="AnalisisPM.capacidad_pago.rotacion.rotacion_proveedores._2024" name="rotacion_proveedores_parcial_2024" />
        </td>
      </tr>
      <tr>
        <td>Ciclo financiero</td>
        <td>
          <input
            style="width: 85px"
            style="width: 85px" suffix=" %" mask="separator.2" readonly [(ngModel)]="AnalisisPM.capacidad_pago.rotacion.ciclo_financiero._2022"
            name="ciclo_financiero_2022"
            [allowNegativeNumbers]="true"
          />
        </td>
        <td>
          <input
            style="width: 85px"
            style="width: 85px" suffix=" %" mask="separator.2" readonly [(ngModel)]="AnalisisPM.capacidad_pago.rotacion.ciclo_financiero._2023"
            name="ciclo_financiero_2023"
            [allowNegativeNumbers]="true"
          />
        </td>
        <td><input style="width: 85px" style="width: 85px" suffix=" %" mask="separator.2" [allowNegativeNumbers]="true" readonly [(ngModel)]="AnalisisPM.capacidad_pago.rotacion.ciclo_financiero._2024" name="ciclo_financiero_parcial_2024" /></td>
      </tr>
      <tr style="height: 20px"></tr>
    </table>
  </div>
  <div style="height: 80px;"></div>
  <div style="display: grid; grid-template-columns: 40% 40%; gap: 5%">
    <div>
    <table>
      <tr>
        <td>Renta adicional disponible</td>
        <td>
          <input
            style="width: 105px"
            name="renta_adicional_disp"
            [(ngModel)]="AnalisisPM.capacidad_pago.renta_adicional_disponible"
           mask="separator.2"
            [allowNegativeNumbers]="true"
          />
        </td>
      </tr>
      
      <tr>
        <td>Ingresos en efectivo</td>
        <td>
          <input 
          style="width: 105px" 
          name="ingresos_efectivo" 
          [(ngModel)]="AnalisisPM.capacidad_pago.ingresos_efectivo"
          (ngModelChange)="calcularCapacidadPago()"
          mask="separator.2"
            [allowNegativeNumbers]="true"
          />
        </td>
      </tr>
      <tr>
        <td>Ingresos extras</td>
        <td>
          <input 
          style="width: 105px"  name="ingresos_extras" 
          [(ngModel)]="AnalisisPM.capacidad_pago.ingresos_extras"
          (ngModelChange)="calcularCapacidadPago()"
          mask="separator.2"
            [allowNegativeNumbers]="true"
          />
        </td>
      </tr>
      <tr>
        <td>Mensualidad</td>
        <td><input style="width: 105px" name="mensualidad" mask="separator.2"
          [(ngModel)]="AnalisisPM.capacidad_pago.mensualidad"
          (ngModelChange)="calcularCapacidadPago()"
          /></td>
      </tr>
      <tr>
        <td>Factor de renta</td>
        <td><input style="width: 105px" name="factor_renta"
          [(ngModel)]="AnalisisPM.capacidad_pago.factor_renta"
         mask="separator.2"
            [allowNegativeNumbers]="true"
          /></td>
      </tr>
      <tr>
        <td>Dictamen</td>
        <td><input style="width: 105px" name="dictamen"
          [(ngModel)]="AnalisisPM.capacidad_pago.dictamen"
          mask="separator.2"
            [allowNegativeNumbers]="true"
          /></td>
      </tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      
    </table>
    </div>
    <div>
      <div><h3>CAPACIDAD DE PAGO</h3></div>
      <table class="table_evaluacion_financiera">
        <tr>
          <th>Mes</th>
          <th>Depósitos</th>
          <th>Retiros</th>
          <th>Saldo final</th>
        </tr>
        <tr *ngFor="let periodo of AnalisisPM.capacidad_pago.periodos; let i = index">
          <td><input style="width: 85px" name="" [(ngModel)]="periodo.mes"/></td>
          <td><input style="width: 85px" name="" [(ngModel)]="periodo.depositos" mask="separator.2" (ngModelChange)="calcularCapacidadPago()"/></td>
          <td><input style="width: 85px" name="" [(ngModel)]="periodo.retiros" mask="separator.2" (ngModelChange)="calcularCapacidadPago()"/></td>
          <td><input style="width: 85px" name="" [(ngModel)]="periodo.saldo_final" mask="separator.2" (ngModelChange)="calcularCapacidadPago()"/></td>
        </tr>
        <tr>
          <td> <input value="PROMEDIO" style="width: 85px" name="" readonly/></td>
          <td>
            <input style="width: 85px" name="" mask="separator.2" [(ngModel)]="AnalisisPM.capacidad_pago.depositos_promedio" readonly/>
            
          </td>
          <td>
            <input style="width: 85px" name=""  mask="separator.2" [(ngModel)]="AnalisisPM.capacidad_pago.retiros_promedio" readonly/>
          </td>
          <td> <input style="width: 85px" name="" mask="separator.2" [(ngModel)]="AnalisisPM.capacidad_pago.saldo_final_promedio" readonly/></td>
        </tr>
        <tr style="height: 20px"></tr>
        <tr>
          <td style="width: 85px"></td>
          <td style="width: 85px"><b>Ingresos</b></td>
          <td style="width: 85px"><b>Gastos</b></td>
          <td style="width: 85px"><b>Ratio</b></td>
        </tr>
        <tr>
          <td>Comprobables</td>
          <td>
            <input style="width: 85px"  style="width: 85px" mask="separator.2" readonly [(ngModel)]="AnalisisPM.capacidad_pago.ingresos.comprobables.ingresos" />
          </td>
          <td>
            <input style="width: 85px"  style="width: 85px" mask="separator.2" readonly [(ngModel)]="AnalisisPM.capacidad_pago.ingresos.comprobables.gastos"  />
          </td>
          <td><input style="width: 85px"  style="width: 85px" mask="separator.2" readonly [(ngModel)]="AnalisisPM.capacidad_pago.ingresos.comprobables.ratio"  /></td>
        </tr>
        <tr>
          <td>Con efectivo</td>
          <td>
            <input
              style="width: 85px"
              style="width: 85px" mask="separator.2" readonly [(ngModel)]="AnalisisPM.capacidad_pago.ingresos.efectivo.ingresos"
              name="rotacion_inventarios_2022"
            />
          </td>
          <td>
            <input
              style="width: 85px"
              style="width: 85px" mask="separator.2" readonly [(ngModel)]="AnalisisPM.capacidad_pago.ingresos.efectivo.gastos"
              name="rotacion_inventarios_2023"
            />
          </td>
          <td>
            <input style="width: 85px" style="width: 85px" mask="separator.2" readonly [(ngModel)]="AnalisisPM.capacidad_pago.ingresos.efectivo.ratio" name="rotacion_inventarios_parcial_2024" />
          </td>
        </tr>
        <tr>
          <td>Extra nuevo equipo</td>
          <td>
            <input
              style="width: 85px"
              style="width: 85px" mask="separator.2" readonly [(ngModel)]="AnalisisPM.capacidad_pago.ingresos.extra_nuevo_equipo.ingresos"
              name="rotacion_proveedores_2022"
            />
          </td>
          <td>
            <input
              style="width: 85px"
              style="width: 85px" mask="separator.2" readonly [(ngModel)]="AnalisisPM.capacidad_pago.ingresos.extra_nuevo_equipo.gastos"
              name="rotacion_proveedores_2023"
            />
          </td>
          <td>
            <input style="width: 85px" style="width: 85px" mask="separator.2" readonly [(ngModel)]="AnalisisPM.capacidad_pago.ingresos.extra_nuevo_equipo.ratio" name="rotacion_proveedores_parcial_2024" />
          </td>
        </tr>
        <tr>
          <td  style="width: 85px"></td>
          <td  style="width: 85px"></td>
          <td  style="width: 85px"></td>
          <td  style="width: 85px"><b>Max 60%</b></td>
          <td  style="width: 85px">Puntuación</td>
        </tr>
        <tr>
          <td  style="width: 85px"></td>
          <td  style="width: 85px"></td>
          <td  style="width: 85px"></td>
          <td  style="width: 85px"></td>
          <td > <b><input style="width: 85px" [(ngModel)]="AnalisisPM.capacidad_pago.total_puntos_gastos" (ngModelChange)="recalcularTotalPuntosRF()"/></b></td>
        </tr>
        
        <tr style="height: 20px"></tr>
      </table>
    </div>

  </div>
  <div style="margin-top: 80px; " class="input">
    <textarea maxlength="500" placeholder="Comentarios adicionales" [(ngModel)]="AnalisisPM.capacidad_pago.comentarios_adicionales"></textarea>
  </div>
  <div style="margin-top: 80px; margin-bottom: 100px; display: flex; text-align: center; justify-content: center;">
    <tr>
      <td><b>PUNTUACIÓN FINAL</b></td>
      <td>
        <input style="width: 65px"  type="number" name="puntuacion_final" [(ngModel)]="AnalisisPM.capacidad_pago.total_puntos_razones_financieras" />
      </td>
    </tr>
  </div>
  <div class="container-btn">
    <button class="btn-secondary-new" (click)="guardarYRetroceder()">Atras</button>
    <button class="btn-primary-new" (click)="guardarYAvanzar()">
      <mat-spinner *ngIf="loading" diameter="20" ></mat-spinner> <span *ngIf="!loading">Guardar</span>
    </button>
  </div>
</div>
