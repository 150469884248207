<div class="dashboard-container">
    <div class="header">
        <img class="logo" mat-button height="60px" width="auto" [routerLink]="['/dashboard']" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/logo-header.svg" alt="">
        <div>
            <button mat-button routerLink="/changePassword">Cambiar Contraseña
                <svg style="height: 20px; width: auto;" xmlns="http://www.w3.org/2000/svg" width="31.992" height="31.992" viewBox="0 0 31.992 31.992"><defs><style>.a{fill:#3ca2d6;}</style></defs><path class="a" d="M18,2a16,16,0,1,0,16,16A16.006,16.006,0,0,0,18,2Zm0,1.391A14.6,14.6,0,1,1,3.391,18,14.594,14.594,0,0,1,18,3.391Zm0,4.868a4.164,4.164,0,0,0-4.173,4.173v2.086h-.7A2.1,2.1,0,0,0,11.041,16.6V23.56a2.1,2.1,0,0,0,2.086,2.086h9.737a2.1,2.1,0,0,0,2.086-2.086V16.6a2.1,2.1,0,0,0-2.086-2.086h-.7V12.432A4.164,4.164,0,0,0,18,8.259ZM18,9.65a2.724,2.724,0,0,1,2.782,2.782v2.086H15.214V12.432A2.724,2.724,0,0,1,18,9.65Zm-4.868,6.259H14.4a.7.7,0,0,0,.227,0h6.728a.7.7,0,0,0,.227,0h1.281a.693.693,0,0,1,.7.7V23.56a.693.693,0,0,1-.7.7H13.128a.693.693,0,0,1-.7-.7V16.6A.693.693,0,0,1,13.128,15.909ZM18,18a1.39,1.39,0,0,0-.7,2.594v.883a.7.7,0,1,0,1.391,0v-.884A1.389,1.389,0,0,0,18,18Z" transform="translate(-2 -2)"/></svg>
            </button>
            <button mat-button (click)="logout()">Cerrar Sesión
                <svg style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="32" height="31.992" viewBox="0 0 32 31.992"><defs><style>.a{fill:#3ca2d6;}</style></defs><path class="a" d="M18,2A16,16,0,1,0,30.9,27.449a.7.7,0,1,0-1.121-.823,14.6,14.6,0,1,1,0-17.259A.7.7,0,1,0,30.9,8.543,15.976,15.976,0,0,0,18,2Zm9.73,9.73a.7.7,0,0,0-.485,1.194L31.617,17.3H16.6a.7.7,0,1,0,0,1.391H31.617l-4.377,4.377a.7.7,0,1,0,.984.984l5.5-5.5a.7.7,0,0,0,0-1.107l-5.5-5.5A.7.7,0,0,0,27.726,11.73Z" transform="translate(-2 -2)"/></svg>
            </button>
        </div>
    </div>

    <div class="body">
        <div class="title">
            <h2>Crea un<br>nuevo perfil</h2>
        </div>
        <div class="formulario">
            <div class="row" style="margin: 0;">
                <div class="col-4"><h2 style="margin: 0;">{{ esDirectorGeneral ? 'Director General' : 'Director Comercial'}}</h2></div>
            </div>
            <div class="row">
                <div class="col-2">
                    <div class="dark-theme">
                        <mat-label>Crear</mat-label>
                        <div class="control">
                            <mat-select [(ngModel)]="perfil_seleccionado" (selectionChange)="Buscar()">
                                <mat-option [value]="0">Seleccionar</mat-option>
                                <mat-option *ngFor="let item of perfiles" [value]="item.id">{{ item.nombre }}</mat-option>
                            </mat-select>
                        </div>
                        
                        <mat-error *ngIf="submited && perfil_seleccionado == 0" [ngClass]="{'invalid-input': submited && perfil_seleccionado == 0}">Campo obligatorio</mat-error>
                    </div>
                </div>
            </div>
            <div class="row" style="margin: 0;" *ngIf="perfil_seleccionado && relaciona">
                <div class="col-2">
                    <mat-label>Selecciona relación con:</mat-label>
                    <mat-error *ngIf="submited && perfil_padre == 0" [ngClass]="{'invalid-input': submited && perfil_padre == 0}">Selecciona relación</mat-error>
                </div>
            </div>
            <div class="row perfiles" *ngIf="perfil_seleccionado && relaciona">
                <div class="col" *ngIf="(perfil_seleccionado == 14 || perfil_seleccionado == 15) && perfil == 6">
                    <div class="dark-theme" >
                        <div class="control">
                            <mat-select [(ngModel)]="perfil_director_operaciones" (selectionChange)="GerentesOperaciones()">
                                <mat-option [value]="0">Director de Operaciones</mat-option>
                                <mat-option *ngFor="let item of directores_operaciones" [value]="item.id">{{ item.nombre_cliente }}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
                <div class="col" *ngIf="(perfil_seleccionado == 15)">
                    <div class="dark-theme" >
                        <div class="control">
                            <mat-select [(ngModel)]="perfil_gerente_operaciones" (selectionChange)="perfil_padre = perfil_gerente_operaciones">
                                <mat-option [value]="0">Gerente de Operaciones</mat-option>
                                <mat-option *ngFor="let item of gerentes_operaciones" [value]="item.id">{{ item.nombre_cliente }}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
                <div class="col" *ngIf="(perfil_seleccionado == 7 || perfil_seleccionado == 3 || perfil_seleccionado == 2 || perfil_seleccionado == 8 || perfil_seleccionado == 9 || perfil_seleccionado == 10 || perfil_seleccionado == 11) && perfil == 6">
                    <div class="dark-theme" >
                        <div class="control">
                            <mat-select [(ngModel)]="perfil_director_comercial" (selectionChange)="BuscarGerentesComercial()">
                                <mat-option [value]="0">Director Comercial</mat-option>
                                <mat-option *ngFor="let item of directores_comercial" [value]="item.id">{{ item.nombre_cliente }}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
                <div class="col" *ngIf="perfil_seleccionado == 3 || perfil_seleccionado == 2 || perfil_seleccionado == 11 || perfil_seleccionado == 7">
                    <div class="dark-theme">
                        <!-- <mat-label>Gerente Comercial</mat-label> -->
                        <div class="control">
                            <mat-select [(ngModel)]="perfil_gerente_comercial" (selectionChange)="BuscarComerciales()">
                                <mat-option [value]="0">Gerente Comercial</mat-option>
                                <mat-option *ngFor="let item of gerentes_comercial" [value]="item.id">{{ item.nombre_cliente }}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
                <div class="col" *ngIf="perfil_seleccionado == 2 || perfil_seleccionado == 11 || perfil_seleccionado == 7">
                    <div class="dark-theme">
                        <!-- <mat-label>Comercial</mat-label> -->
                        <div class="control">
                            <mat-select [(ngModel)]="perfil_comercial" (selectionChange)="BuscarVendors()">
                                <mat-option [value]="0">Comercial</mat-option>
                                <mat-option *ngFor="let item of comerciales" [value]="item.id">{{ item.nombre_cliente }}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
                <div class="col" *ngIf="perfil_seleccionado == 7">
                    <div class="dark-theme">
                        <!-- <mat-label>Vendor</mat-label> -->
                        <div class="control">
                            <mat-select [(ngModel)]="perfil_vendor" (selectionChange)="perfil_padre = perfil_vendor">
                                <mat-option [value]="0">Vendor</mat-option>
                                <mat-option *ngFor="let item of vendors" [value]="item.id">{{ item.nombre_cliente }}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-3">
                    <form [formGroup]="createForm" #create="ngForm" (ngSubmit)="onSubmit()">
                        <div class="input">
                            <mat-label>{{perfil_seleccionado != 2 ? 'Nombre (s)' : 'Nombre del vendor'}}</mat-label>
                            <div class="control">
                              <input [specialCharacters]="['space']"
                              [patterns]="customPattern" mask="S{100}" formControlName="nombre" [ngClass]="{'invalid-input': (f.nombre.touched && !f.nombre.valid)}" placeholder="Nombres">
                              <mat-icon *ngIf="(!f.nombre.valid && (f.nombre.touched || submited))" class="invalid-input">clear</mat-icon>
                            </div>
                        </div>
                          <mat-error *ngIf="!f.nombre.valid && (f.nombre.touched || submited)" class="invalid-input">Campo obligatorio</mat-error>
                          
                          <div class="input" *ngIf="perfil_seleccionado != 2">
                            <mat-label>Apellido Paterno</mat-label>
                            <div class="control">
                              <input [specialCharacters]="['space']"
                              [patterns]="customPattern" mask="S{100}" formControlName="a_paterno" [ngClass]="{'invalid-input': (f.a_paterno.touched && !f.a_paterno.valid)}" placeholder="Apellido paterno">
                              <mat-icon *ngIf="(!f.a_paterno.valid && (f.a_paterno.touched || submited))" class="invalid-input">clear</mat-icon>
                            </div>
                          </div>
                          <mat-error *ngIf="!f.a_paterno.valid && (f.a_paterno.touched || submited)" class="invalid-input">Campo obligatorio</mat-error>
                          
                          <div class="input" *ngIf="perfil_seleccionado != 2">
                            <mat-label>Apellido Materno</mat-label>
                            <div class="control">
                              <input [specialCharacters]="['space']"
                              [patterns]="customPattern" mask="S{100}" formControlName="a_materno" [ngClass]="{'invalid-input': (f.a_materno.touched && !f.a_materno.valid)}" placeholder="Apellido materno">
                              <mat-icon *ngIf="(!f.a_materno.valid && (f.a_materno.touched || submited))" class="invalid-input">clear</mat-icon>
                            </div>
                          </div>
                          <mat-error *ngIf="!f.a_materno.valid && (f.a_materno.touched || submited)" class="invalid-input">Campo obligatorio</mat-error>
                          
                          <div class="input">
                            <mat-label>Correo Electrónico</mat-label>
                            <div class="control">
                              <input type="email" formControlName="correo" [ngClass]="{'invalid-input': ((submited || f.correo.touched) && !f.correo.valid)}" placeholder="Correo Electrónico">
                              <mat-icon *ngIf="(!f.correo.valid && (f.correo.touched || submited))" class="invalid-input">clear</mat-icon>
                            </div>
                          </div>
                          <mat-error *ngIf="!f.correo.value && (f.correo.touched || submited)" class="invalid-input">Campo obligatorio</mat-error>
                          <mat-error *ngIf="f.correo.value && f.correo.errors && (f.correo.touched || submited)" class="invalid-input">Correo no valido, vuelve a intentarlo.</mat-error>
                          <mat-error *ngIf="email_registered" [ngClass]="{'invalid-input': email_registered}">Correo ya registrado, vuelve a intentarlo.</mat-error>
                          
                          <!-- <div class="input" *ngIf="perfil_seleccionado == 2 || perfil_seleccionado == 3 || perfil_seleccionado == 7 || perfil_seleccionado == 11"> -->
                          <div class="input" *ngIf="false">
                            <mat-label>Factor</mat-label>
                            <div class="control">
        
                              <input mask="separator.2" maxlength="5" formControlName="factor" [ngClass]="{'invalid-input': reference_error}" (input)="reference_error = false; fail_factor = false" placeholder="0" value="0">
                              <mat-icon *ngIf="(!f.factor.valid && f.factor.touched) || (reference_error)" [ngClass]="{'invalid-input': true}">clear</mat-icon>
                            </div>
                        </div>

                        <!-- <div class="dark-theme" *ngIf="perfil_seleccionado == 2 || perfil_seleccionado == 7 || perfil_seleccionado == 11" style="margin-top: 20px;"> -->
                        <div class="dark-theme" *ngIf="false" style="margin-top: 20px;">
                            <mat-label>¿Descuento ciego?</mat-label>
                            <mat-select formControlName="descuento_ciego" style="margin-top: 8px;">
                                <mat-option [value]="false">No</mat-option>
                                <mat-option [value]="true">Sí</mat-option>
                            </mat-select>
                        </div>
                        <mat-error *ngIf="!f.factor.valid && f.factor.touched && (perfil_seleccionado == 2 || perfil_seleccionado == 3 || perfil_seleccionado == 7)" [ngClass]="{'invalid-input': !f.factor.valid  && f.factor.touched}">Campo obligatorio</mat-error>
                        <!-- <mat-error *ngIf="(submited || f.factor.touched) && fail_factor && (perfil_seleccionado == 2 || perfil_seleccionado == 3 || perfil_seleccionado == 7)" [ngClass]="{'invalid-input': (submited || f.factor.touched) && fail_factor}">Verifica tu factor</mat-error> -->
                    </form>
                </div>
            </div>
        </div>
        <mat-progress-spinner *ngIf="loading" mode="indeterminate" diameter="25"></mat-progress-spinner>
        <button *ngIf="!loading" class="button-next" mat-mini-fab form="this.createForm" (click)="create.ngSubmit.emit()"><mat-icon>navigate_next</mat-icon></button>
    </div>
    <app-socials-footer style="width:100%;"></app-socials-footer>
</div>