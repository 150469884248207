import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/_services';

@Component({
  selector: 'app-reassign-procedure',
  templateUrl: './reassign-procedure.component.html',
  styleUrls: ['./reassign-procedure.component.scss']
})
export class ReassignProcedureComponent implements OnInit {

  constructor(
        private userService:UserService,
        private dialogRef: MatDialogRef<ReassignProcedureComponent>,
        @Inject(MAT_DIALOG_DATA) public data
    ) { }

    usuarios: any;
    perfil_director_comercial = 0;
  perfil_gerente_comercial = 0;
  perfil_comercial = 0;
  perfil_vendor = 0;
  perfil_vendedor = 0;

  perfil_padre = 0;
  error = false;

  directores_comercial = [];
  gerentes_comercial = [];
  comerciales = [];
  vendors = [];
  vendedores = [];

  ngOnInit(): void {
    console.log(this.data);
    this.usuarios = this.data.usuarios;

    if (this.data.perfil == 6) {
        this.directores_comercial = this.data.usuarios.hijos_director_comercial;
        this.directores_comercial.sort( (a,b) => a.nombre_cliente.localeCompare(b.nombre_cliente))
    }
    if (this.data.perfil == 12) {
        this.gerentes_comercial = this.data.usuarios.hijos_gerente;
        this.gerentes_comercial.sort( (a,b) => a.nombre_cliente.localeCompare(b.nombre_cliente))
    }

    if (this.data.perfil == 8) {
        this.comerciales = this.data.usuarios.hijos_comercial;
        this.comerciales.sort( (a,b) => a.nombre_cliente.localeCompare(b.nombre_cliente))
    }

    if (this.data.perfil == 10) {
        this.comerciales = this.data.usuarios.hijos_comercial;
        this.comerciales.sort( (a,b) => a.nombre_cliente.localeCompare(b.nombre_cliente))
    }
  }

  BuscarGerentesComercial() {
    this.perfil_gerente_comercial = 0;
    this.perfil_comercial = 0;
    this.perfil_vendor = 0;
    this.perfil_vendedor = 0;

    this.vendedores = [];
    this.vendors = [];
    this.comerciales = [];
    this.gerentes_comercial = [];

    for (const usr of this.data.usuarios.hijos_gerente) {
        if (usr.id_master == this.perfil_director_comercial) {
          this.gerentes_comercial.push(usr); 
          // this.gerentes_comercial.sort( (a,b) => )
        }
    }
  }

  BuscarComerciales() {
    this.perfil_comercial = 0;
    this.perfil_vendor = 0;
    this.perfil_vendedor = 0;

    this.vendedores = [];
    this.vendors = [];
    this.comerciales = [];

    for (const usr of this.data.usuarios.hijos_comercial) {
        if (usr.id_master == this.perfil_gerente_comercial) {this.comerciales.push(usr); }
    }
    this.comerciales.sort( (a,b) => a.nombre_cliente.localeCompare(b.nombre_cliente))
  }

  BuscarVendors() {
    this.perfil_vendor = 0;
    this.perfil_vendedor = 0;

    this.vendedores = [];
    this.vendors = [];

    for (const usr of this.data.usuarios.hijos_vendor) {
        if (usr.id_master == this.perfil_comercial) {this.vendors.push(usr); }
    }
    this.vendors.sort( (a,b) => a.nombre_cliente.localeCompare(b.nombre_cliente))
  }

  BuscarVendedores() {
    this.perfil_vendedor = 0;
    this.vendedores = [];

    for (const usr of this.data.usuarios.hijos_vendedor) {
		console.log(usr);
  if (usr.id_master == this.perfil_vendor) {this.vendedores.push(usr); }
    }
    this.vendedores.sort( (a,b) => a.nombre_cliente.localeCompare(b.nombre_cliente))
  }

  Confirm(value) {
    if( value && !this.userService.alertService.isOnline() ) return
    if (value) {
        let id_responsable = 0;
        if (this.perfil_vendedor != 0) {
            id_responsable = this.perfil_vendedor;
        } else if (this.perfil_vendor != 0) {
            id_responsable = this.perfil_vendor;
        } else if (this.perfil_comercial != 0) {
            id_responsable = this.perfil_comercial;
        } else {
            this.error = true;
        }
        if (id_responsable != 0) {
            this.data.confirm(id_responsable);
            this.dialogRef.close(value);
        }

    } else { this.dialogRef.close(value); this.data.reject(); }
  }

  public chooseOpt(opt: number): void {
    try {
      this.data.confirmopt(opt);
    } catch (e) {
      console.error('e :', e);
    }

    this.dialogRef.close(true);
  }
}
