<app-sidenav-v2 [shortMenu]="true">

    <div class="container">
        <div class="top">
            <div class="title">Referidos</div>
        </div>
        <div *ngIf="false" class="buttonAccion">
            <p class="fecha">{{fechaActual}}</p>
            <button class="button-campañas" mat-button [matMenuTriggerFor]="menu">CAMPAÑAS
                <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/ReferredFlechaabajo.svg">
            </button>
            <mat-menu #menu="matMenu">
                <ng-container *ngFor="let campaign of campaigns">
                    <button mat-menu-item (click)="selectCampaign(campaign.id)">
                        {{ campaign.name }}
                    </button>
                </ng-container>
            </mat-menu>
            <button class="button-envio-campañas" (click)="enviarCampanas()"><img
                    src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/whatsapp-white.svg" />ENVIAR
                CAMPAÑAS</button>
        </div>
        <div class="container-charging" *ngIf="loading">
            <div class="text">
                <p>Cargando clientes</p>
            </div>
            <div class="container-progress">
                <img src="../../../../assets/images/letter-u.svg" />
                <!-- <mat-progress-bar mode="indeterminate"></mat-progress-bar> -->
                <div class="progress">
                    <div class="progress-value"></div>
                </div>
            </div>
        </div>
        <div class="box" *ngIf="!loading">
            <div class="table-referred">
                <div>
                    <table class="table-referreds" [cellSpacing]="0" [cellPadding]="0">
                        <thead>
                            <tr>
                                <th style="max-width: 15px;"></th>
                                <th></th>
                                <th>CLIENTE</th>
                                <th>TELÉFONO</th>
                                <th>CORREO</th>
                                <th>CAMPAÑA</th>
                                <th>REFERIDOS</th>
                            </tr>
                        </thead>
                    </table>
                    <cdk-virtual-scroll-viewport itemSize="15" class="virtual-scroll-viewport">
                        <table class="table-referreds" [cellSpacing]="0" [cellPadding]="0">
                            <tbody>
                                <ng-container *cdkVirtualFor="let cliente of clientes; let i = index">
                                    <tr style="font-family: 'Poppins';">
                                        <td><mat-checkbox [checked]="isChecked(cliente)" (change)="onCheckboxChange($event, cliente)"></mat-checkbox></td>
                                        <td>
                                            <div *ngIf="cliente.referido.length > 0" (click)="toggleReferidos(i);">
                                                <img *ngIf="expandedIndex !== i" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/collapseDown.svg">
                                                <img *ngIf="expandedIndex === i" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/collapseUp.svg">
                                            </div>
                                            <img *ngIf="cliente.referido.length == 0" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/collapseDisable.svg">
                                        </td>
                                        <td>{{ cliente.names }} {{cliente.father_last_name}}</td>
                                        <td>{{ cliente.phone }}</td>
                                        <td>{{ cliente.email }}</td>
                                        <td>Campaña {{ cliente.id_campaign }}</td>
                                        <td style="padding-left: 30px;">{{cliente.referido.length}}</td>
                                    </tr>
                                    <tr *ngIf="expantionDown === i">
                                        <td colspan="7" style="padding: 0;">
                                            <table class="referidos-table">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th>REFERIDO</th>
                                                        <th>TELÉFONO</th>
                                                        <th>CORREO</th>
                                                        <th>FOLIO</th>
                                                        <th></th>
                                                        <th>ESTATUS</th>
                                                        <th>NO INTERESADO</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let referido of cliente.referido">
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td style="font-family: 'Poppins'; font-size: 14px;">{{ referido.names }} {{ referido.father_last_name }}</td>
                                                        <td style="font-family: 'Poppins'; font-size: 14px;">{{ referido.phone }}</td>
                                                        <td style="font-family: 'Poppins'; font-size: 14px;">{{ referido.email }}</td>
                                                        <td style="font-family: 'Poppins'; font-size: 14px;">{{ referido.folio }}</td>
                                                        <td>
                                                            <button [ngClass]="{'boton-Cotizador-disable': referido.id_cotizacion != null, 'boton-cotzacion': referido.id_cotizacion == null}" (click)="cotizacionReferido(referido)">
                                                                <img *ngIf="referido.id_cotizacion == null" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/botonCotizarBlanco.svg">
                                                                <img *ngIf="referido.id_cotizacion != null" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/botonCotizarGris.svg">
                                                                COTIZAR
                                                            </button>
                                                        </td>
                                                        <td style="font-family: 'Poppins'; font-size: 14px;">{{ referido.estatus }}</td>
                                                        <td>
                                                            <div *ngIf="referido.id_cotizacion == null" class="no-interesado" (click)="rejected(referido)">
                                                                <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/noInteresado.svg">
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </cdk-virtual-scroll-viewport>
                    
                    
                </div>
            </div>
        </div>
        <app-socials-footer style="width: 100%;"></app-socials-footer>
    </div>

</app-sidenav-v2>