import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/_services';

@Component({
  selector: 'app-rechazar-documento',
  templateUrl: './rechazar-documento.component.html',
  styleUrls: ['./rechazar-documento.component.scss']
})
export class RechazarDocumentoComponent implements OnInit {
  comentarios = ''
  closeBtn = true
  messageRequiredError = false
  constructor(
    private userService:UserService,
    public dialogRef:MatDialogRef<RechazarDocumentoComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.closeBtn = this.data.closeBtn != undefined ? this.data.closeBtn : true
  }

  Rechazar(){
    if( !this.userService.alertService.isOnline() ) return

    if(this.data.required == true && this.comentarios == '') {
      this.messageRequiredError = true
      return
    }
    this.dialogRef.close(this.comentarios)
  }

  Close() {
    if (this.data.required == true && this.comentarios == '') {
      this.messageRequiredError = true
    } else {
      this.dialogRef.close()
    }
  }
}
