<div class="body-email">
  <app-module-instructions
    [instructions]="instructions"
  ></app-module-instructions>
  <div class="body-step">
    <div class="container-step-email">
      <app-countdown  [initialTime]="initialTime" [emailValidation]="true" (solicitarPin)="SolicitarPin()"></app-countdown>

      <div class="container-code">
        <code-input [isCodeHidden]="false" [codeLength]="6" class="new_code" (codeChanged)="onFullFill($event)"
        (codeCompleted)="onFullFill($event)"> </code-input>
      </div>
      <div class="container-checkbox">
        <mat-checkbox class="checkbox-lfu">He leído y acepto los <span>términos y condiciones de uso</span> de Lease For Ypu S.A. de C.V.</mat-checkbox>
      </div>
    </div>
  </div>
  <div class="container-btn">
    <button
      class="btn-secondary-new"
      matStepperPrevious
    >
      Regresar
    </button>
    <button
      class="btn-new-p"
      color="primary"
      matStepperNext
      (click)="enviarDatos"
    >
      Continuar
    </button>
  </div>
</div>
