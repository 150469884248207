import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FinProcesoMessageComponent } from 'src/app/_dialogs/fin-proceso-message/fin-proceso-message.component';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { saveAs } from 'file-saver'
import { TipoCartas } from '../../valuacion/cartas-cliente/TipoCartas';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService, SolicitudService, UserService } from 'src/app/_services';
import { DatePipe, DecimalPipe, formatCurrency } from '@angular/common';
import { AnalisisPM } from '../AnalisisPM';
import { StepperNavigationService } from 'src/app/_services/stepper-navigation.service';

@Component({
  selector: 'app-cartas-cliente-pm',
  templateUrl: './cartas-cliente-pm.component.html',
  styleUrls: ['./cartas-cliente-pm.component.scss']
})
export class CartasClientePmComponent implements OnInit {
  @Input() analisisPm: any;  
  @Output() analisisPmChange = new EventEmitter<any>();
  AnalisisPM: any = {};
  ngOnChanges(changes: SimpleChanges) {
    if (changes.analisisPm && changes.analisisPm.currentValue) {
      console.log("Nuevo valor de analisisPm capacidad pago:", this.analisisPm);
      this.AnalisisPM = {...this.analisisPm}

      this.carta = this.AnalisisPM.id_resolucion_comite
      this.condicion = this.AnalisisPM.minuta.comentarios_adicionales
    }
  }

  folio = null
  carta = 0
  TipoCarta = TipoCartas
  DetalleCarta:any = {}
  cliente = null
  id_cliente = null
  email_cliente = null
  email_user = null
  perfil_user = null
  pdf = false
  request = {
    email: '',
    opcion: '',
    filename: '',
    subjec: '',
    params: null
  }
  fecha = new Date()
  fechaFormateada = null
  condicion: ''

  src = null
  tipo_carta = 0
  obteniendo_carta = false
  documentos = ''

  Resoluciones = [
    {id: 17, name: 'Aprobada'},
    {id: 18, name: 'Visita'},
    {id: 19, name: 'Videollamada'},
    {id: 20, name: 'Documentación'},
    {id: 21, name: 'Aval'},
    {id: 22, name: 'Rechazada'},
  ]

  APROBADA = 1
  CONDICIONADA = 2
  INFO_ADICIONAL = 3
  RECHAZADA = 4

  loading = false;
  
  constructor(private dialog: MatDialog,
    private router:Router,
    private userService:UserService,
    private solicitudService: SolicitudService,
    private authenticationService:AuthenticationService,
    private stepperNavigationService: StepperNavigationService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe) { }

  async ngOnInit() {
    localStorage.setItem("cartas", 'true');
    const {folio, cliente, id_cliente, email} = JSON.parse( localStorage.getItem("client_details"))
    const emailUser = JSON.parse(localStorage.getItem('currentUser')).email;
    const idPerfilUser = JSON.parse(localStorage.getItem('currentUser')).perfil;
    this.folio = folio
    this.cliente = cliente
    this.id_cliente = id_cliente
    this.email_cliente = email
    this.email_user = emailUser
    this.perfil_user = idPerfilUser
    this.AnalisisPM = JSON.parse(JSON.stringify(AnalisisPM));
    

    // await this.getAnalisisPMData();
    
    // this.userService.ObtenerDetalleCarta( Number(this.folio) )
    // .subscribe( res => {
    //   console.log('Detalle carta: ', res);
      
    //   this.DetalleCarta = res
    //   this.SeleccionarCompraIVA();
    // }, err => {
    //   console.log(err)
    //   this.carta = 4
    //   this.DetalleCarta.id_solicitud = Number(localStorage.getItem('id_solicitud'))
    // })

    // this.userService.ObtenerMinuta( Number(this.folio)).subscribe( ({id_resolucion, comentarios_adicionales}) => {
    //   this.carta = id_resolucion
    //   this.condicion = comentarios_adicionales
    //   console.log("valor carta id_resolucion: ", this.carta);
    // })
  }
  getAnalisisPMData() {
    const res: any = this.solicitudService
      .ObtenerAnalisisCredito(this.folio)
      .subscribe(
        (res: any) => {
          console.log("SI HAY CAPACIDAD", res);
          const data = res.data;
          const years = [2022, 2023, 2024];
          this.AnalisisPM = {
            ...res.data
          };

          // console.log(this.AnalisisPM.capacidad_pago.periodos[1].mes)
          if (this.AnalisisPM.capacidad_pago.periodos.length === 0) {
            this.AnalisisPM.capacidad_pago.periodos = Array.from({ length: 3 }, () => ({
              mes: "",
              depositos: 0,
              retiros: 0,
              saldo_final: 0,
            }));
          }
          console.log(this.AnalisisPM)
               this.carta = this.AnalisisPM.id_resolucion_comite
              this.condicion = this.AnalisisPM.minuta.comentarios_adicionales
              console.log("valor carta id_resolucion: ", this.carta);
        },
        (error) => {
          console.log("ERROR NO HAY", error);
          this.AnalisisPM;
        }
      );
  }
  SeleccionarCompraIVA(){
    switch(this.AnalisisPM.cotizacion.plazo){
      case 12:
        this.DetalleCarta.opcionCompraIVA = this.DetalleCarta.opcioncompraiva12 
        break;
      case 18:
        this.DetalleCarta.opcionCompraIVA = this.DetalleCarta.opcioncompraiva18 
        break;
      case 24:
        this.DetalleCarta.opcionCompraIVA = this.DetalleCarta.opcioncompraiva24 
        break;
      case 36:
        this.DetalleCarta.opcionCompraIVA = this.DetalleCarta.opcioncompraiva36 
        break;
      case 48:
        this.DetalleCarta.opcionCompraIVA = this.DetalleCarta.opcioncompraiva48 
        break;
      default:
        this.DetalleCarta.opcionCompraIVA = this.DetalleCarta.opcioncompraiva12 
    }
  }

  transformDate(date, format) {
    return this.datePipe.transform(date, format); //format you need. 
  }
  
  transformCifras(num){
    return this.decimalPipe.transform(num, '1.2-2');
  }
  /*ObtenerPDF(){
    if( !this.userService.alertService.isOnline() ) return
    return window.print()
    this.userService.ObtenerPDF({
      id: 6,
      params: {
        comision: 123,
        descripcion_activo: "algo",
        iva: 123,
        iva2: 123,
        monto_arrendar: 213,
        plazo: 12,
        renta_inicial:234234,
        renta_inicial_iva: 34234,
        renta_mensual: 4534,
        renta_mensual_iva: 234,
        seguro: 45353,
        total_pago_inicial: 25354,
        total_renta_mensual: 322324,
        opcion_compra: 45435345,
        cartas_texto: this.TipoCarta[this.carta]
      }
    }).subscribe(res => {
      const blob = new Blob([res], {type: 'application/pdf'})
      saveAs(blob, 'Cartas.pdf')
    }, 
    err=>console.log(err))
  }*/

  GetCarta() {
    this.src = null

    if (this.tipo_carta == 20 && this.documentos == '') return

    const _documentos = this.documentos.toLocaleUpperCase().split('\n')
    this.obteniendo_carta = true
    this.carta = this.tipo_carta == 17 ? this.APROBADA
                : this.tipo_carta == 22 ? this.RECHAZADA : this.CONDICIONADA

                this.userService.ObtenerPDF({
                  email: '',
                    subjec: '',
                    filename: '',
                  opcion: this.tipo_carta,
                  id: this.tipo_carta,
                  params: {
                    vendor: this.AnalisisPM.cotizacion.vendor || 'NOMBRE DEL VENDOR',
                    equipo: this.AnalisisPM.caracteristica_activo.activo.toUpperCase(),
                    plazo: this.AnalisisPM.cotizacion.plazo,
                    monto: formatCurrency( this.AnalisisPM.caracteristica_activo.monto_activo, 'es-MX', '$' ),
                    documentos: _documentos,
                    tipo_persona: true
                  }
                }).subscribe((res: any) => {
                  this.obteniendo_carta = false
                  this.src = res
                }, 
                err=>{
                  this.obteniendo_carta = false
                  console.log(err)
                })

  }

  DownloadPDF() {
    if (this.src == null) return
    const blob = new Blob([this.src], {type: 'application/pdf'})
    saveAs(blob, `CARTA_${this.AnalisisPM.folio}_${this.ObtenerNombreResolucion(this.tipo_carta)}.pdf`)
  }

  ObtenerNombreResolucion(id) {
    return this.Resoluciones.filter(resolucion => resolucion.id == id)[0].name.toUpperCase()
  }

  GuardarCarta(){
    if( !this.userService.alertService.isOnline() ) return
    this.pdf = true
    this.DownloadPDF()
    this.userService.GuardarCarta({id_solicitud: this.AnalisisPM.folio, id_resolucion: this.carta})
    // .subscribe(res => {
    //   this.userService.changeStatus(this.folio, 4, 3).subscribe(res => {
    //     console.log("Se ha concluido el tramite")
    //     // this.EnviarCartaComite();
        
    //     const dialogref = this.dialog.open(MessageBoxComponent, {
    //       width: '500px',
    //       data: {
    //         text: 'Éxito',
    //         text2: 'Se ha guardado la información con éxito',
    //         type: 'success',
    //         icon: 'success',
    //       }
    //     });
    //   })
    // }, 
    // err => {
    //   this.pdf = false
    //   this.dialog.open(MessageBoxComponent, {
    //     data: {
    //       text: "Ocurrió un error al guardar las Cartas.",
    //       type: "error",
    //       icon: "error"
    //     }
    //   })
    // })
  }
  ConstruirRequestPDF(tipoUsuario){
    var opcion = null
    var params = null
    params = {
      condicion: this.condicion,
      nombre_cliente : this.cliente,
      fecha_envio : this.fechaFormateada,
      descripcion_activo: this.AnalisisPM.caracteristica_activo.activo,
      monto_arrendar: this.transformCifras(this.AnalisisPM.caracteristica_activo.monto_activo),
      plazo: this.AnalisisPM.cotizacion.plazo,
      comision: this.transformCifras(this.AnalisisPM.cotizacion.comision),
      renta_inicial: this.transformCifras(this.AnalisisPM.cotizacion.monto_pago_inicial),
      iva: this.transformCifras(this.AnalisisPM.cotizacion.iva),
      total_pago_inicial: this.transformCifras(this.AnalisisPM.cotizacion.total_pago_inicial),
      renta_mensual: this.transformCifras(this.AnalisisPM.cotizacion.mensualidad),
      seguro: this.transformCifras(this.AnalisisPM.cotizacion.seguro),
      iva2: this.transformCifras(this.AnalisisPM.cotizacion.iva2),
      total_renta_inicial: this.transformCifras(this.AnalisisPM.cotizacion.mensualidad),
      mensualidades_pend: this.AnalisisPM.cotizacion.plazo,
      opcion_compra_IVA: this.transformCifras(this.AnalisisPM.cotizacion.opcion_compra_iva)
    }
    switch(this.carta) { 
      case 1: { 
        opcion = 6
        break; 
      } 
      case 2: { 
        opcion = 7
        break; 
      }
      case 3: { 
        opcion = 8
        break; 
      } 
      case 4: { 
        opcion = 9
        params = {
          nombre_cliente : this.cliente,
          fecha_envio : this.fechaFormateada
        }
        break; 
      }  
      default: { 
        opcion = 9
        params = {
          nombre_cliente : this.cliente,
          fecha_envio : this.fechaFormateada
        }
        break; 
      } 
    }
    
    this.request = {
      email: tipoUsuario === 'current_user' ? this.email_user: this.email_cliente,
      opcion: opcion,
      filename: 'carta_cliente.pdf',
      subjec: 'Cartas al cliente',
      params: params
    }
  }
  EnviarCartaComite(){
    this.fechaFormateada = this.transformDate(this.fecha, 'dd/MM/yyyy');
    this.ConstruirRequestPDF('current_user');
    console.log("fecha formateada: ",this.fechaFormateada)
    this.solicitudService.sendMailCommentaries(this.request)
    .subscribe( (res: any) => {
      console.log('Se envío la carta a comité: ', res);

      const file = new Blob([res], {type: 'application/pdf'})
      saveAs(file, this.request.filename)

      const dialogref = this.dialog.open(MessageBoxComponent, {
        width: '500px',
        data: {
          text: 'Éxito',
          text2: 'Se ha guardado la información',
          text3: '¿Deseas enviar la carta al cliente?',
          type: 'confirm',
          icon: 'success',
          confirm: () => {
            this.EnviarCartaCliente();
          },
          reject: () => {
            dialogref.close();
          }
        }
      });
    }, 
    (err) => {
      console.log('error: ', err);
      this.dialog.open(MessageBoxComponent, {
        data: {
          text: 'El servicio no está disponible.',
          text3: 'Vuelve a intentarlo',
          type: 'error',
          icon: 'error'
        }
      });
    });
  }

  EnviarCartaCliente(){
    this.fechaFormateada = this.transformDate(this.fecha, 'dd/MM/yyyy');
    this.ConstruirRequestPDF('client');
    this.solicitudService.sendMailCommentaries(this.request)
    .subscribe( (res: any) => {
      console.log('Se envio la carta al cliente ', res);

      const dialogref = this.dialog.open(MessageBoxComponent, {
        width: '500px',
        data: {
          text: 'Éxito',
          text2: 'Se ha mandado la carta al correo del cliente',
          type: 'success',
          icon: 'success'
        }
      });
    }, 
    (err) => {
      console.log('error: ', err);
      this.dialog.open(MessageBoxComponent, {
        data: {
          text: 'El servicio no está disponible.',
          text3: 'Vuelve a intentarlo',
          type: 'error',
          icon: 'error'
        }
      });
    });
  }

  /*const dialogref = this.dialog.open(MessageBoxComponent, {
    width: "500px",
    data: {
      text: "Se ha completado las Cartas exitosamente.",
      type: "success",
      icon: "success"
    }
  })*/
  /*dialogref.afterClosed().subscribe( async ()=>{
    await this.userService.getStatus(this.id_cliente, this.folio)
    // this.router.navigate(['/dashboard', 'comite', this.folio])
  })*/

  Save() {
    if( !this.userService.alertService.isOnline() ) return
      this.userService.changeStatus(this.folio, 4, 3).subscribe(res => {
        console.log("Se ha concluido el tramite")
        const dialogref = this.dialog.open(FinProcesoMessageComponent, {
          width: "350px",
          height: "380px",
          data: {
            folio: this.folio,
            perfil: this.perfil_user
          }
        })
        dialogref.afterClosed().subscribe( async ()=>{
          // await this.userService.getStatus(this.id_cliente, this.folio)
          this.router.navigate(['/dashboard', 'comite', this.folio])
        })
      })
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
  async guardarYRetroceder() {
   
    console.log("Guardando analisis", this.AnalisisPM);
    const res:any = await this.solicitudService.GuardarAnalisisCredito(
      this.AnalisisPM
    );
    console.log(res);
    if(res.statusCode == 200){
      this.analisisPmChange.emit(this.AnalisisPM)
      this.stepperNavigationService.previousStep();
    }
  }

  async guardarYFinalizar() {
   
    this.loading = true;
    console.log("Guardando analisis", this.AnalisisPM);
    const res:any = await this.solicitudService.GuardarAnalisisCredito(
      this.AnalisisPM
    );
    console.log(res);
    if(res.statusCode == 200){
      this.loading = false;
      this.analisisPmChange.emit(this.AnalisisPM)
      this.router.navigate(['/dashboard', 'comite', this.folio])
    }else{
      this.loading = false;
    }
  }
}
