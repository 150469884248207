import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MejorarCotizacionService } from "src/app/_services/mejorar-cotizacion.service";

@Component({
  selector: "app-email-validation",
  templateUrl: "./email-validation.component.html",
  styleUrls: ["./email-validation.component.scss"],
})
export class EmailValidationComponent implements OnInit {
  @Input() data: any;
  @Output() datosActualizados = new EventEmitter();


  code = "";
  codeValido = "";
  isValid = false;
  invalidCode = false;

  title = "Registra tu correo";
  instructions =
    "Para garantizar la seguridad de tu cuenta, hemos implementado un proceso de verificación por correo electrónico. Da click en solicitar código e inserta el código recibido. *Recuerda revisar también la carpeta de correo no deseado en caso de que no encuentres el correo electrónico en tu bandeja de entrada";

  icon_suffix = "";
  initialTime: number = 30;

  constructor(
    private mejorarCotizacion: MejorarCotizacionService,
    private _snackBar: MatSnackBar,

  ) {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue) {
      console.log("Nuevo valor deDATA en email:", this.data);
      
    }
  }

  ngOnInit(): void {}
  getErrorMessage() {
    if (this.data.correo_cliente == "" || !this.data.correo_cliente) {
      return "El correo electrónico es requerido";
    }
  }

  handleCountdownFinished(): void {
  }
   onFullFill(dataCode: any) {
    if (dataCode.length === 6) {
      this.code = dataCode;
      this.isValid = true;
      this.invalidCode = false;
      
      this.data = {...this.data, 'code_email': this.code}
      this.datosActualizados.emit(this.data);
    }
  }

  async SolicitarPin(){
    const email = {
      email:this.data.correo_cliente
    }
   
    try{
      const response = await this.mejorarCotizacion.EnviarPinEmail(email);
      this._snackBar.open('Se ha enviado correctamente el código, por favor revisa tu correo ', 'Ok',
      {
      duration: 10000,
        horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['success-snackbar']})

    }catch(err){
    }


  }
  enviarDatos(): void {
    this.datosActualizados.emit(this.data);

  }
}
