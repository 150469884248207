export const perfilDirectorGeneral = [
    {
        tipo: 'Director Comercial',
        acro: 'dc'
    },
    {
        tipo: 'Gerente Comercial',
        acro: 'gc'
    },
    {
        tipo: 'Comercial',
        acro: 'co'
    },
    {
        tipo: 'Vendor',
        acro: 've'
    },
    {
        tipo: 'Vendedor',
        acro: 'ven'
    },
    {
        tipo: 'Backoffice',
        acro: 'bo'
    }
];

export const perfilDirectorComercial = [
    {
        tipo: 'Gerente Comercial',
        acro: 'dc'
    },
    {
        tipo: 'Comercial',
        acro: 'co'
    },
    {
        tipo: 'Vendor',
        acro: 've'
    },
    {
        tipo: 'Vendedor',
        acro: 'ven'
    }
];

export const perfilGerenteComercial = [
    {
        tipo: 'Comercial',
        acro: 'co'
    },
    {
        tipo: 'Vendor',
        acro: 've'
    },
    {
        tipo: 'Vendedor',
        acro: 'ven'
    }
];
