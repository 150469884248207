import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { customPatterns } from 'src/app/_models/maskPattern';
import { BuroService, SolicitudService, UserService } from 'src/app/_services';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-form-domicilio',
  templateUrl: './form-domicilio.component.html',
  styleUrls: ['./../../consulta-buro.component.scss']
})
export class FormDomicilioComponent implements OnInit {
  @Input() procesos;
  @Input() formData;
  @Output() buroResponse = new EventEmitter();
  @Output() onBack = new EventEmitter();
  
  domicilioForm: FormGroup;
  datosGen:any = {}
  customPattern = customPatterns;
  submitted = false;
  loading = false;
  estados = [
    { id: 'CDMX', valor: 'Ciudad de México', estado: 'Ciudad de México'},
    { id: 'AGS', valor: 'Aguascalientes', estado: 'Aguascalientes'},
    { id: 'BCN', valor: 'Baja California', estado: 'Baja California'},
    { id: 'BCS', valor: 'Baja California Sur', estado: 'Baja California Sur'},
    { id: 'CAM', valor: 'Campeche', estado: 'Campeche' },
    { id: 'CHS', valor: 'Chiapas', estado: 'Chiapas' },
    { id: 'CHI', valor: 'Chihuahua', estado: 'Chihuahua' },
    { id: 'COA', valor: 'Coahuila', estado: 'Coahuila de Zaragoza' },
    { id: 'COL', valor: 'Colima', estado: 'Colima' },
    { id: 'DGO', valor: 'Durango', estado: 'Durango' },
    { id: 'EM', valor: 'Estado de México', estado: 'México' },
    { id: 'GTO', valor: 'Guanajuato', estado: 'Guanajuato' },
    { id: 'GRO', valor: 'Guerrero', estado: 'Guerrero' },
    { id: 'HGO', valor: 'Hidalgo', estado: 'Hidalgo' },
    { id: 'JAL', valor: 'Jalisco', estado: 'Jalisco' },
    { id: 'MICH', valor: 'Michoacán', estado: 'Michoacán de Ocampo' },
    { id: 'MOR', valor: 'Morelos', estado: 'Morelos' },
    { id: 'NAY', valor: 'Nayarit', estado: 'Nayarit' },
    { id: 'NL', valor: 'Nuevo León', estado: 'Nuevo León' },
    { id: 'OAX', valor: 'Oaxaca', estado: 'Oaxaca' },
    { id: 'PUE', valor: 'Puebla', estado: 'Puebla' },
    { id: 'QRO', valor: 'Querétaro', estado: 'Querétaro' },
    { id: 'QR', valor: 'Quintana Roo', estado: 'Quintana Roo' },
    { id: 'SLP', valor: 'San Luis Potosí', estado: 'San Luis Potosí' },
    { id: 'SIN', valor: 'Sinaloa', estado: 'Sinaloa' },
    { id: 'SON', valor: 'Sonora', estado: 'Sonora' },
    { id: 'TAB', valor: 'Tabasco', estado: 'Tabasco' },
    { id: 'TAM', valor: 'Tamaulipas', estado: 'Tamaulipas' },
    { id: 'TLAX', valor: 'Tlaxcala', estado: 'Tlaxcala' },
    { id: 'VER', valor: 'Veracruz', estado: 'Veracruz de Ignacio de la Llave' },
    { id: 'YUC', valor: 'Yucatán', estado: 'Yucatán' },
    { id: 'ZAC', valor: 'Zacatecas', estado: 'Zacatecas' }
  ];

  constructor(
    private formBuilder: FormBuilder,
    private buroService: BuroService,
    private userService: UserService,
    private dialog: MatDialog,
    private solicitud: SolicitudService

  ) { }

  ngOnInit(): void {
    console.log(this.formData);
    
    this.domicilioForm = this.formBuilder.group({
      codigo_pais: ['MX'],
      calle: [this.formData.Direccion1 || '', [Validators.required]],
      numero: [this.formData.Numero || '', [Validators.required]],
      cp: [this.formData.CP  || '', [Validators.required, Validators.minLength(5), Validators.maxLength(10)]],
      colonia_poblacion: [''],
      delegacion_municipio: ['', [Validators.required]],
      ciudad: ['', [Validators.required]],
      estado: ['CDMX', [Validators.required]]
    });

  }

  get f() { return this.domicilioForm.controls; }

  showTooltip(id) {
    let elemento = document.getElementById(id);
    if (elemento.classList.contains('tooltipText')) {
      elemento.classList.remove('tooltipText');
      elemento.classList.add('noDisplay');
    } else {
      elemento.classList.remove('noDisplay');
      elemento.classList.add('tooltipText');
    }
  }
  
  async SearchState(cp) {
    if (cp.length == 5) {
      console.log('Buscar: ', cp);
      const estados: any = await this.buroService.buscarEstado(cp)
      console.log(estados);
      for (const cur of estados) {
        if (!cur.error) {
          const {municipio, estado, ciudad, asentamiento} = cur.response
          console.log({municipio, estado, ciudad, asentamiento});
          this.f.delegacion_municipio.setValue(municipio)
          this.f.ciudad.setValue(ciudad);
          this.f.estado.setValue(this.getEstado(estado));
          break
        }
      }
    }
    
  }

  getEstado(estado) {
    const result: any = this.estados.filter(e => e.estado === estado)
    return result[0].id
  }

  back() {
    this.onBack.emit(true)
  }

  async onSubmit() {
    if( !this.userService.alertService.isOnline() ) return
    
    this.submitted = true;
    if (this.domicilioForm.invalid) {
      console.log('invalido ', this.submitted);
      return;
    } else {
      this.loading = true;
      
      this.formData.Domicilio.CodPais = this.domicilioForm.value.codigo_pais;
      // this.formData.Domicilio.ColoniaPoblacion = this.domicilioForm.value.colonia_poblacion;
      this.formData.Domicilio.CP = this.domicilioForm.value.cp;
      this.formData.Domicilio.Numero = this.domicilioForm.value.numero;
      this.formData.Domicilio.DelegacionMunicipio = this.domicilioForm.value.delegacion_municipio;
      this.formData.Domicilio.Ciudad = this.domicilioForm.value.ciudad;
      this.formData.Domicilio.Estado = this.domicilioForm.value.estado;
      this.formData.Domicilio.Direccion1 = this.domicilioForm.value.calle;
      let fechaAux = this.formData.FechaNacimiento;
      let re = /-/g;
      let resultado = fechaAux.replace(re, '');
      this.formData.FechaNacimiento = resultado;

      console.log(this.formData);

      const tipo_persona = this.formData.tipo_persona
      console.log(tipo_persona)
      if(tipo_persona != 2){
        this.procesos[2].status = 1;
      }else{
        (await this.buroService.consultaBuroPM(this.formData)).subscribe(
          async (res: any) => {
            console.log(res);
            this.loading = false;
            if (res.score) {
              this.buroResponse.emit(res);
              this.procesos[2].status = 1;
              this.procesos[3].status = 1;
            } else if (res.error.message === 'La última consula a buró fue hace menos de 90 días.') {
              this.buroResponse.emit({ score: 'menos 90 dias' });
              this.procesos[2].status = 1;
              this.procesos[3].status = 1;
            } else if (res.error.message === 'El servicio no está disponible por el momento') {
              this.dialog.open(MessageBoxComponent, {
                width: "500px",
                data: {
                  text2: "Error al consultar buró",
                  icon: "error",
                  type: "error"
                }
              })
            } else {
              this.buroResponse.emit({ score: '' });
              this.procesos[2].status = 1;
              this.procesos[3].status = 1;
            }
          }
        );
      }
      
    }
  }

  setInfo(){
    const client_details = JSON.parse( localStorage.getItem('client_details'))
    if( client_details ){
      const { id_cliente, folio, tipo_persona } = client_details
      const guardado = { id_cliente, folio, parte: 2, tipo_persona }
      this.solicitud.getDatosGenerales( {guardado} ).subscribe( (res) => {
       console.log("GET dATOS GEN", res)
       const { Domicilio} = res
       if(Domicilio.CP != null){
        this.f.cp.setValue(Domicilio.CP)
       }

      })
    }
  }

}
