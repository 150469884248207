
<!-- <div class="progress">
	<div class="progress-left active" (click)="changePart(1)"></div>
	<div class="progress-mid active" (click)="changePart(2)"></div>
</div> -->


<form *ngIf="tipoAval == 1" [formGroup]="dgFForm" >
	<div class="section_form">
		<div class="input">
			<div style="width: 100%; display: flex;align-items: flex-start;">
				<p style="width: 90%;">Su(s) principal(es) accionista(s) o Representante Legal o Aval desempeña(n) actualmente o ha(n) desempeñado en el último año alguna función pública destacada en el país o en el extranjero (jefe de estado, líder político, funcionario de gobierno, judicial o militar de alta jerarquía, alto ejecutivo de empresa estatal o miembro importante de partido político)</p>
				<mat-slide-toggle formControlName="aval_funcion_public"></mat-slide-toggle>
			</div>
		</div>

		<div class="input size-2" *ngIf="f.aval_funcion_public.value">
			<mat-label>Especifique</mat-label>
			<input  
				formControlName="aval_funcion_public_desc" 
				[value]="datosAval.aval_funcion_public_desc"
				placeholder="Especifique"
				[ngClass]="{ 'is-invalid': submitted && f.aval_funcion_public_desc.errors }"
			>
			<mat-error *ngIf="submitted && f.aval_funcion_public_desc.errors" class="invalid-feedback">
				<div *ngIf="f.aval_funcion_public_desc.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>
	</div>

	<div class="section_form">
		<div class="input">
			<div style="width: 100%; display: flex;align-items: flex-start;">
				<p>El cónyugue, Concubino(a) o parientes consanguíneos de los(s) principal(es) accionista(s) o Representante Legal o Aval, hasta el segundo grado (abuelos, padres, hermanos, hijos, primos) desempeñan actualmente o han desempeñado en el último año alguna función pública destacada en el país o en extranjero.</p>
				<mat-slide-toggle formControlName="aval_familiar_funcion_public"></mat-slide-toggle>
			</div>
		</div>

		<div class="input size-2" *ngIf="f.aval_familiar_funcion_public.value">
			<mat-label>Especifique</mat-label>
			<input  
				[specialCharacters]="['space']"
				[patterns]="customPattern"
				mask="S{100}"
				formControlName="aval_familiar_funcion_public_desc" 
				[value]="datosAval.aval_familiar_funcion_public_desc" placeholder="Especifique"
				[ngClass]="{ 'is-invalid': submitted && f.aval_familiar_funcion_public_desc.errors }"
			>
			<mat-error *ngIf="submitted && f.aval_familiar_funcion_public_desc.errors" class="invalid-feedback">
				<div *ngIf="f.aval_familiar_funcion_public_desc.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>
	</div>
	<div style="width: 50%; margin: 0 auto;">
		<div class="input">
			<mat-label>Nombre de Solicitante</mat-label>
			<input  
			[specialCharacters]="['space']"
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="aval_nombre_solicitante" 
				[value]="datosAval.aval_nombre_solicitante"
				[ngClass]="{ 'is-invalid': submitted && f.aval_nombre_solicitante.errors }"
			>
			<mat-error *ngIf="submitted && f.aval_nombre_solicitante.errors" class="invalid-feedback">
				<div *ngIf="f.aval_nombre_solicitante.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>
		<mat-checkbox formControlName="aval_autoriza">Autorización del Solicitante.</mat-checkbox>
		<mat-error *ngIf="submitted && !f.aval_autoriza.value" class="invalid-feedback">
			<div>Campo obligatorio</div>
		</mat-error>
	</div>
	
</form>

<!-- 
	------------------------------------------------------------------------------------------------------- 
	--------------------------------------- PERSONA MORAL -------------------------------------------------
	-------------------------------------------------------------------------------------------------------
-->

<form *ngIf="tipoAval == 2" [formGroup]="dgMForm" >
	<div class="section_form">
		<div class="input">
			<div style="width: 100%; display: flex;align-items: flex-start;">
				<p>Su(s) principal(es) accionista(s) o Representante Legal o Aval desempeña(n) actualmente o ha(n) desempeñado en el último año alguna función pública destacada en el país o en el extranjero (jefe de estado, líder político, funcionario de gobierno, judicial o militar de alta jerarquía, alto ejecutivo de empresa estatal o miembro importante de partido político)</p>
				<mat-slide-toggle formControlName="aval_funcion_public"></mat-slide-toggle>
			</div>
		</div>
		<div class="input size-2">
			<mat-label>Especifique</mat-label>
			<input  [specialCharacters]="['space']"
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="aval_funcion_public_desc" 
				[value]="datosAval.aval_funcion_public_desc" placeholder="Especifique"
				[ngClass]="{ 'is-invalid': submitted && m.aval_funcion_public_desc.errors }"
			>
			<mat-error *ngIf="submitted && m.aval_funcion_public_desc.errors" class="invalid-feedback">
				<div *ngIf="m.aval_funcion_public_desc.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>
	</div>


	<div class="section_form">
		<div class="input">
			<div style="width: 100%; display: flex;align-items: flex-start;">
				<p>El cónyugue, Concubino(a) o parientes consanguíneos de los(s) principal(es) accionista(s) o Representante Legal o Aval, hasta el segundo grado (abuelos, padres, hermanos, hijos, primos) desempeñan actualmente o han desempeñado en el último año alguna función pública destacada en el país o en extranjero.</p>
				<mat-slide-toggle formControlName="aval_familiar_funcion_public"></mat-slide-toggle>
			</div>
		</div>
		<div class="input size-2">
			<mat-label>Especifique</mat-label>
			<input  
			[specialCharacters]="['space']"
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="aval_familiar_funcion_public_desc" 
				[value]="datosAval.aval_familiar_funcion_public_desc" placeholder="Especifique"
				[ngClass]="{ 'is-invalid': submitted && m.aval_familiar_funcion_public_desc.errors }"
			>
			<mat-error *ngIf="submitted && m.aval_familiar_funcion_public_desc.errors" class="invalid-feedback">
				<div *ngIf="m.aval_familiar_funcion_public_desc.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>
	</div>
	

	<div style="width: 50%; margin: 0 auto;">
		<div class="input">
			<mat-label>Empresa</mat-label>
			<input  
			[specialCharacters]="['space']"
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="aval_empresa" 
				[ngClass]="{ 'is-invalid': submitted && !m.aval_empresa.valid }"
			>
			<mat-error *ngIf="submitted && (!m.aval_empresa.valid || !m.aval_empresa.value)" class="invalid-feedback">
				<div *ngIf="!m.aval_empresa.valid || (submitted && !m.aval_empresa.value)">Campo obligatorio</div>
			</mat-error>
		</div>

		<mat-checkbox formControlName="aval_autoriza_empresa">Autorización del Solicitante.</mat-checkbox>
		<mat-error *ngIf="submitted && !m.aval_autoriza_empresa.valid" class="invalid-feedback">
			<div *ngIf="submitted && !m.aval_autoriza_empresa.valid">Campo obligatorio</div>
		</mat-error>

		<div class="input" style="margin-top: 30px;">
			<mat-label>Nombre de Representante Legal</mat-label>
			<input  
			[specialCharacters]="['space']"
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="aval_nombre_representante" 
				[value]="datosAval.aval_nombre_representante"
				[ngClass]="{ 'is-invalid': submitted && !m.aval_nombre_representante.valid }"
			>
			<mat-error *ngIf="submitted && (!m.aval_nombre_representante.valid || !m.aval_nombre_representante.value)" class="invalid-feedback">
				<div *ngIf="!m.aval_nombre_representante.valid || (submitted && !m.aval_nombre_representante.value)">Campo obligatorio</div>
			</mat-error>
		</div>

		<mat-checkbox formControlName="aval_autorizacion_representante">Autorización del Representante Legal.</mat-checkbox>
		<mat-error *ngIf="submitted && !m.aval_autorizacion_representante.valid" class="invalid-feedback">
			<div *ngIf="!m.aval_autorizacion_representante.valid">Campo obligatorio</div>
		</mat-error>
		<div class="input" style="margin-top: 30px;">
			<mat-label>Nombre de Aval</mat-label>
			<input  
			[specialCharacters]="['space']"
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="aval_nombre" 
				[value]="datosAval.aval_nombre"
				[ngClass]="{ 'is-invalid': submitted && m.aval_nombre.errors }"
			>
			<mat-error *ngIf="submitted && m.aval_nombre.errors" class="invalid-feedback">
				<div *ngIf="m.aval_nombre.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<mat-checkbox formControlName="aval_autoriza" [value]="datosAval.aval_autoriza">Autorización del Aval.</mat-checkbox>
		<mat-error *ngIf="submitted && (!m.aval_autoriza.valid || !m.aval_autoriza.value)" class="invalid-feedback">
			<div *ngIf="!m.aval_autoriza.valid || (submitted && !m.aval_autoriza.value)">Campo obligatorio</div>
		</mat-error>
	</div>	
</form>

<div class="row-end">
	<!-- <button mat-button (click)="back(1)">
		<svg style="height: 20px; width:auto; transform: rotate(180deg);" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><defs><style>.bca{fill:#3fa4d7;}</style></defs><path class="bca" d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0ZM22,38V18.7L13.448,27.25l-3.394-3.394L24,9.908,37.948,23.856,34.554,27.25,26,18.7V38Z" transform="translate(48) rotate(90)"/></svg>
		Regresar
	</button> -->
	<div></div>
	<mat-spinner *ngIf="loading" diameter="30"></mat-spinner>

	<!-- <button class="save-button" mat-button (click)="onSubmit(1)" style="height: 60px!important; width: 60px!important;">
		<svg style="height: 30px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="48.14" height="48.14" viewBox="0 0 48.14 48.14"><defs><style>.ab{fill:#fff;}</style></defs><path class="ab" d="M8.439,4A3.377,3.377,0,0,0,5,7.439V48.7A3.377,3.377,0,0,0,8.439,52.14H49.7A3.377,3.377,0,0,0,53.14,48.7V14.544a1.039,1.039,0,0,0-.345-.8l-9.4-9.4A1.039,1.039,0,0,0,42.6,4Zm5.731,2.292H41.678V20.047a2.3,2.3,0,0,1-2.292,2.292H16.462a2.3,2.3,0,0,1-2.292-2.292ZM32.508,9.731V18.9h4.585V9.731ZM16.462,30.362H41.678a2.3,2.3,0,0,1,2.292,2.292V49.847h-29.8V32.654A2.3,2.3,0,0,1,16.462,30.362ZM8.439,46.409h2.292V48.7H8.439Zm38.97,0H49.7V48.7H47.409Z" transform="translate(-5 -4)"/></svg>
	</button> -->
	<button *ngIf="!loading" mat-button (click)="onSubmit(2)">
		Siguiente
		<svg style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><defs><style>.abc{fill:#707070;}</style></defs><path class="abc" d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0ZM22,38V18.7L13.448,27.25l-3.394-3.394L24,9.908,37.948,23.856,34.554,27.25,26,18.7V38Z" transform="translate(48) rotate(90)"/></svg>
	</button>
</div>
