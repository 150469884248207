import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-referencias-nueva-solicitud',
  templateUrl: './referencias-nueva-solicitud.component.html',
  styleUrls: ['./referencias-nueva-solicitud.component.scss']
})
export class ReferenciasNuevaSolicitudComponent implements OnInit {
  @Input() data: any;
  @Output() datosActualizados = new EventEmitter();
  frmRef: FormGroup;
  numRefArray =[];
  datos_conyugue= false;
  datos_aval= false;
  constructor(
    private formBuilder: FormBuilder,

  ) { }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue) {

      if(this.data?.aval){
        this.datos_aval = true
      }else{
        this.datos_aval = false
      }
      if(this.data.conyugue){
        this.datos_conyugue = true
      }else{
        this.datos_conyugue = false

      }
      this.validarDatos()
      this.frmRef.valueChanges.subscribe(() => {
        if (this.frmRef.valid) {
          this.data = { ...this.data, referencias: this.frmRef.value };
          this.datosActualizados.emit(this.data);
        }
      });
      if(this.data.referencias){
        this.frmRef.patchValue({
          nombres: this.data.conyugue.nombres || '',
          apellido_paterno: this.data.conyugue.apellido_paterno || '',
          apellido_materno: this.data.conyugue.apellido_materno || '',
          ocupacion: this.data.conyugue.ocupacion || '',
          tel: this.data.conyugue.tel || ''
        });
      }
    }
  }

  ngOnInit(): void {
    this.frmRef = this.formBuilder.group({
      nombres_ref_1: ['', Validators.required],
      apellido_paterno_ref_1: ['', Validators.required],
      apellido_materno_ref_1: ['', Validators.required],
      ocupacion_ref_1: ['', Validators.required],
      tel_ref_1: ['', [Validators.required, Validators.maxLength(10),Validators.minLength(10)]],

      nombres_ref_2: ['',],
      segundo_nombre_ref_2: ['',],
      apellido_paterno_ref_2: ['',],
      apellido_materno_ref_2: ['',],
      ocupacion_ref_2: ['',],
      tel_ref_2: ['',],

      nombres_ref_3: ['',],
      segundo_nombre_ref_3: ['',],
      apellido_paterno_ref_3: ['',],
      apellido_materno_ref_3: ['',],
      ocupacion_ref_3: ['',],
      tel_ref_3: ['',],
    })
    this.validarDatos()
  }

  validarDatos(){

    if (this.datos_aval && this.datos_conyugue) {
      this.numRefArray = [0];
    } else if (this.datos_conyugue) {
      this.numRefArray = [0, 1]; 
    } else {
      this.numRefArray = [0, 1, 2]; 
    }

  }


}
