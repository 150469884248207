import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PerfilService } from "src/app/_services/perfil.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-new-modal-message",
  templateUrl: "./new-modal-message.component.html",
  styleUrls: ["./new-modal-message.component.scss"],
})
export class NewModalMessageComponent implements OnInit {
  @Output() guardar = new EventEmitter<any>();
  @Input() form = {}
  @Input() loading = false
  vendedor_nuevo_select = "";
  plazo_select = "";
  condiciones_pago_select = "";
  promocion_select = "";
  condicion_activo_select = "";
  nombre_activo = "";
  errorMsg = false;


  constructor(
    public dialogRef: MatDialogRef<NewModalMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private perfilService: PerfilService,

  ) {}

  ngOnInit(): void {
    console.log("data", this.data);
    this.nombre_activo = this.data.descr_activo
  }

  async aceptar() {
    this.loading = true;
    this.form = {
      folio: this.data.folio,
      is_demo: environment.config.isDemo,
      vendedor_nuevo: this.vendedor_nuevo_select,
      plazo: this.plazo_select,
      condiciones_pago: this.condiciones_pago_select,
      promocion: this.promocion_select,
      condicion_activo: this.condicion_activo_select,
      nombre_activo: this.nombre_activo

    };
    console.log("ASIGNACION RESP", this.form);
    if(this.vendedor_nuevo_select != "" &&
      this.plazo_select  != "" &&
      this.condiciones_pago_select != "" &&
      this.promocion_select != "" &&
      this.condicion_activo_select != ""){
        console.log("enviar form")
        this.loading = true
        this.data.aceptar(this.form, this.loading)
        
    }else{
      this.errorMsg = true;
      this.loading = false
    }
    // if (resp) {
    //   this.dialog.closeAll();
    //   this.celula_seleccionada = ''
    //   // window.location.reload();
    // }

    // if (this.data.aceptar) {
    //   this.data.aceptar();
    // }
    // this.dialogRef.close();
  }
  cerrar() {
    if (this.data.cerrar) {
      console.log("CERRAR");
      this.data.cerrar();
      this.dialogRef.close();
    }
  }
  checkInputValue(event: any): void {
    // this.numero_guia = event.target.value;
    // this.isInputEmpty = this.numero_guia === '';
    
  }

}
