<div class="container">
    <div class="list" [ngClass]="{'cancelada': !tramite.activa}" *ngFor="let tramite of tramites; let i = index" (click)="Cambiar(tramite.folio, tramite.activa)">
        <div class="left">
            <div class="version">#{{tramite.folio}}</div>
            <div>
                <div>{{tramite.nombre_equipo}}</div>
                <div class="date">Último cambio: {{tramite.ultimo_cambio | date:'dd MMM yyyy'}}</div>
            </div>
        </div>
        <div class="right">
			<span>{{ tramite.nombre_status }}</span>
            <div *ngIf="tramite.activa; else elseBlock">
                <div>{{ tramite.status == 0 ? 'A realizar' : tramite.status == 1 ? 'En proceso' : tramite.status == 2 ? 'Actualizar' : 'Completado'}}</div>
                <span class="status" [ngClass]="{'inprocess': tramite.status == 1, 'attention': tramite.status == 2, 'completed': tramite.status == 3}"></span>
			</div>
			<ng-template #elseBlock>
                <span class="cancel">Cancelado</span>
            </ng-template>
        </div>
    </div>
</div>