<div class="container">
  <div class="header">
    <img class="logo" mat-button height="60px" width="auto" [routerLink]="['/dashboard']" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/logo-header.svg" alt="">
  </div>


  <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" #register="ngForm" (keyup)="onKeyup($event)">
    <div class="register-form">
      <h2>Registro</h2>

      <div class="input">
        <mat-label>Correo Electrónico</mat-label>
        <div class="control">
          <input type="email" formControlName="email" [ngClass]="{'invalid-input': (f.email.touched && !f.email.valid)}">
          <mat-icon *ngIf="!f.email.valid && f.email.touched" [ngClass]="{'invalid-input': !f.email.valid && f.email.touched}">clear</mat-icon>
        </div>
      </div>
      <mat-error *ngIf="!f.email.valid && f.email.touched" [ngClass]="{'invalid-input': !f.email.valid  && f.email.touched}">Correo no válido, vuelve a intentarlo.</mat-error>
      
      <div class="input">
        <mat-label>Contraseña</mat-label>
        <div class="control">
          <input 
            [type]="show_password ? 'text' : 'password'" 
			formControlName="password" 
			placeholder="De 8 a 20 caracteres"
            [ngClass]="{'invalid-input': !f.password.valid}"
            maxlength="20"
          >
          <mat-icon *ngIf="!f.password.valid && f.password.touched" [ngClass]="{'invalid-input': !f.password.valid && f.password.touched}">clear</mat-icon>
          <mat-icon matSuffix (click)="show_password = !show_password">{{ show_password ? 'visibility_off' : 'visibility' }}</mat-icon>
        </div>
      </div>
      <mat-error *ngIf="!f.password.valid && f.password.touched" [ngClass]="{'invalid-input': !f.password.valid  && f.password.touched}">Contraseña no válida, vuelve a intentarlo.</mat-error>
      
    </div>


    <div class="lugar">
      <p>¿De dónde se realiza el registro?</p>
      <mat-button-toggle-group formControlName="place" class="place-options">
        <mat-button-toggle value="consultorio">Consultorio</mat-button-toggle>
        <mat-button-toggle value="casa">Casa</mat-button-toggle>
        <mat-button-toggle value="otro">Otro</mat-button-toggle>
      </mat-button-toggle-group>
      <div>
        <img *ngIf="loading" class="pl-3" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
      </div>
    </div>
      <!-- <button mat-raised-button color="primary"  class="btn-primary">Aceptar</button> -->
  </form>

  <mat-progress-spinner *ngIf="loading" mode="indeterminate" diameter="25"></mat-progress-spinner>
  <button *ngIf="!loading" class="button-next" mat-mini-fab [disabled]="!register.form.valid" form="this.registerForm" (click)="register.ngSubmit.emit()"><mat-icon>navigate_next</mat-icon></button>

    <app-socials-footer style="width:100%;"></app-socials-footer>
  </div>
