import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from 'src/app/_services';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {
  @Output() onSelect = new EventEmitter;
  @Input() label = ''
  @Input() users = []
  @Input() selected = 0
  
  constructor(
    private userService: UserService
  ) { }

  ngOnInit(): void {
  }

  onSelected( user ){
    if( !this.userService.alertService.isOnline() ) return 
    this.selected = user.id
    this.onSelect.emit( user )
  }
}
