<div>
  <div class="container-result">
    <div class="container-result-img">
      <img
        src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/globos.svg"
      />
    </div>
    <div class="container-result-info">
      <div class="name-client" *ngIf="!flujo_cotizador">{{ nombre_cliente }},</div>
      <div class="name-client" *ngIf="flujo_cotizador">#Folio: {{ folio }}</div>
      <div class="result-score">  {{ !flujo_cotizador ? '¡Tu solicitud ha sido enviada!' : 'La solicitud ha sido guardada' }}      </div>
      <div class="instructions"  *ngIf="!flujo_cotizador">Las indicaciones serán enviadas a tu correo:</div>
      <div class="correo">{{ data.email }}</div>
    </div>
  </div>
  <div class="container-btn-new" style="margin: 10px; display: flex; justify-content: center;">
    <button class="btn-new-format" (click)="enviarDatos()">Finalizar</button>
  </div>
</div>
