import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-lista-estados',
  templateUrl: './lista-estados.component.html',
  styleUrls: ['./lista-estados.component.scss']
})
export class ListaEstadosComponent implements OnInit {
  @Output() onChange = new EventEmitter

  lstIndex = 0

  ESTADOS = [
    {id: 1, name: 'CDMX'},
    {id: 2, name: 'EDOMEX'},
    {id: 3, name: 'MORELOS'},
    {id: 4, name: 'QUERETARO'},
  ]
  constructor() { }

  ngOnInit(): void {
  }

  CambiarEstado() {
    this.onChange.emit(this.ESTADOS[this.lstIndex])
  }

}
