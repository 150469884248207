<div class="filtro-avanzado-screen">
    <div class="bar-filter">
        <div class="bar-header">
            <span class="title">Filtro avanzado</span>
            <mat-icon (click)="Close()">close</mat-icon>
        </div>
        <div class="bar-body">
            <div class="input-search">
                <img src="/assets/icons/search.png" alt="">
                <input type="text" [placeholder]="'Buscar en solicitudes'">
                <img src="/assets/icons/ajustes.png" alt="">
            </div>

            <span>Selecciona un rango de fechas</span>
            <div class="filter-row">
                <div class="background-blue">
                    <img src="/assets/icons/calendar.png" alt="calendar" width="16" height="16">Desde <span>18/01/2024</span>
                </div>
                <div class="background-blue">
                    <img src="/assets/icons/calendar.png" alt="calendar" width="16" height="16">Desde <span>18/01/2024</span>
                </div>
                <div class="icon-button"><img src="/assets/icons/icon-trash.png" alt="trash" width="16" height="16"></div>
            </div>

            <span>Completa los campos que desees filtrar</span>
            <div class="filter-row">
                <input type="text" placeholder="Nombre del cliente">
                <div class="icon-button"><img src="/assets/icons/icon-trash.png" alt="trash" width="16" height="16"></div>
            </div>

            <div class="filter-row">
                <select name="" id="" aria-placeholder="Tipo de persona">
                    <option value="0" selected>Tipo de persona</option>
                    <option value="1">Persona física</option>
                    <option value="2">Persona moral</option>
                </select>
                <div class="icon-button"><img src="/assets/icons/icon-trash.png" alt="trash" width="16" height="16"></div>
            </div>

            <span>Monto a financiar</span>
            <div class="filter-row">
                <div style="padding: unset!important;"><input type="text" placeholder="Desde"></div>
                <div style="padding: unset!important;"><input type="text" placeholder="Hasta"></div>
                <div class="icon-button"><img src="/assets/icons/icon-trash.png" alt="trash" width="16" height="16"></div>
            </div>

            <div class="filter-row">
                <input type="text" placeholder="Nombre del vendor">
                <div class="icon-button"><img src="/assets/icons/icon-trash.png" alt="trash" width="16" height="16"></div>
            </div>

            <div class="filter-row">
                <input type="text" placeholder="Nombre del comercial">
                <div class="icon-button"><img src="/assets/icons/icon-trash.png" alt="trash" width="16" height="16"></div>
            </div>

            <span>Fecha de operación</span>
            <div class="filter-row">
                <div class="background-blue">
                    <img src="/assets/icons/calendar.png" alt="calendar" width="16" height="16">Desde <span>18/01/2024</span>
                </div>
                <div class="background-blue">
                    <img src="/assets/icons/calendar.png" alt="calendar" width="16" height="16">Desde <span>18/01/2024</span>
                </div>
                <div class="icon-button"><img src="/assets/icons/icon-trash.png" alt="trash" width="16" height="16"></div>
            </div>

            <div class="filter-row">
                <input type="text" placeholder="Dias transcurridos">
                <div class="icon-button"><img src="/assets/icons/icon-trash.png" alt="trash" width="16" height="16"></div>
            </div>

            
            <app-filter-field *ngFor="let field of filter_fields" [item]="field"></app-filter-field>
        </div>
    </div>
</div>