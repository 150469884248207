import { Component, OnInit } from '@angular/core';
import { UserService, AuthenticationService } from 'src/app/_services';
import { Router } from '@angular/router';
import { User } from 'src/app/_models';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  currentUser: User;

  constructor(
      private userService: UserService,
      private router: Router,
      private authenticationService: AuthenticationService
    ) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    }

  ngOnInit(): void {
    // check the validation process code
    // this.router.navigate(['/dashboard'])
  }


}
