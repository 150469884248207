import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

@Injectable({
  providedIn: 'root'
})
export class PerfilService {
  webSocketUrl = `wss://dev1.api.leaseforu.com/ws`;
  wsSubject: WebSocketSubject<any>

  processing = false
  
  constructor(
    private http: HttpClient
  ) { 
    console.log({webSocketUrl: this.webSocketUrl});
    this.wsSubject = webSocket(this.webSocketUrl);
  }

  getUsuarios(tipo_usuario: number, id_cliente: number) {
    return this.http.post(`${environment.config.apiUrl}dashboard/accounts`,
    {tipo_usuario, id_cliente}).toPromise();
  }

  getTramites( busqueda ) {
    console.log( 'Buscando', busqueda );

    return this.http.post(`${environment.config.apiUrl}dashboard/general`,
    busqueda ).toPromise();
  }

  async getTramitesComite( busqueda ) {
    if (this.processing) {
      this.processing = false
      return null
    }
    console.log(`Buscando: ${this.processing}`);
    this.processing = true

    
    console.log( 'Buscando', busqueda );
    const cuentas = await this.http.post(`${environment.config.apiUrl}dashboard/tramites_comite`,
      busqueda ).toPromise();
    this.processing = false
    return cuentas
  }

  CambiarResolucionComite( id, status, folio, id_solicitud, nombre_cliente ) {
    return this.http.post(`${environment.config.apiUrl}dashboard/cambiar_resolucion_comite`,
    {id, status, folio, id_solicitud, nombre_cliente } ).toPromise();
  }

  staffReassign(id_padre: number, id_usuario: number) {
    return this.http.post(`${environment.config.apiUrl}reasignacion/personal`,
    {id_padre, id_usuario}).toPromise();
  }

  reasgingProcedure(folio: string, id_responsable: number) {
    return this.http.post(`${environment.config.apiUrl}dashboard/reasignacion/tramites`,
    {folio, id_responsable});
  }

  asignarCelula(data){
    return this.http.post(`${environment.config.apiUrl}dashboard/asignacion/celula`,
    data).toPromise();
  }
  asignarFechaCompromiso(data){
    return this.http.post(`${environment.config.apiUrl}dashboard/asignacion/fecha_compromiso`,
    data).toPromise();
  }
  obtenerCelula(data){
    return this.http.post(`${environment.config.apiUrl}dashboard/obten_celula`,
    data).toPromise();
  }
  obtenerSemanaCompromiso(data){
    return this.http.post(`${environment.config.apiUrl}dashboard/obten_semana_compromiso`,
    data).toPromise();
  }
}
