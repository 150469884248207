import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { customPatterns } from 'src/app/_models/maskPattern';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PerfilService } from 'src/app/_services/perfil.service';
@Component({
  selector: 'app-datos-cliente',
  templateUrl: './datos-cliente.component.html',
  styleUrls: ['./../crear-cotizacion.component.scss']
})
export class DatosClienteComponent implements OnInit {
  @Input() data: any = null;
  @Output() onNextPage = new EventEmitter;
  submit:boolean = false;
  fail_email:boolean = false;
  fail_responsable:boolean = false;
  contador:number = 0;
  customPattern = customPatterns;
  datosClienteForm: FormGroup;

  perfil = null
  id_usuario = null
  id_responsable = null
  nivel = 0

  filtro = {
    hijos_director_comercial: [],
    hijos_gerente: [],
    hijos_comercial: [],
    hijos_vendor: [],
    hijos_vendedor: []
  }

  constructor(
    private formBuilder: FormBuilder,
    private perfilService: PerfilService
  ) { }

  ngOnInit(): void {
    const { id, perfil } = JSON.parse(localStorage.getItem('currentUser'))
    this.perfil = Number(perfil)
    this.id_usuario = Number(id)
    this.ObtenerSubperfil( this.perfil, this.id_usuario, 'hijos_director_comercial')

    this.datosClienteForm = this.formBuilder.group({
		    nombreCliente: [this.data.nombreCliente, [Validators.required]],
      	correo: [this.data.correo, [Validators.required,Validators.pattern(/^([a-zA-Z]|[0-9]+[a-zA-Z]+)([a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~])*@[a-zA-Z0-9\-]+\.[a-zA-Z]{2,}/)]]
    });
  }

  get f() {
    return this.datosClienteForm.controls;
  }

  NextPage() {
    this.submit = true;
    if ( this.f.correo.value  &&  !this.f.correo.valid) { this.fail_email = true; }
    
    if ( this.datosClienteForm.invalid || this.fail_responsable ) { return; }
    this.onNextPage.emit({
      datosCliente: {
        nombreCliente: this.f.nombreCliente.value,
        correo: this.f.correo.value,
        IDResponsable: this.id_responsable
      }
    });
  }

  async ObtenerSubperfil(perfil, id_usuario, path){
    this.nivel++
    
    // this.id_responsable = this.perfil == 9 || (this.perfil == 6 && this.nivel < 5) ? null : id_usuario
    this.id_responsable = (this.perfil == 6 && this.nivel < 5) ? null : id_usuario
    this.fail_responsable = (!this.id_responsable) ?  true :false
    console.log("Responsable: ", this.id_responsable);
    
    this.filtro[path] = []
    const usuarios = await this.perfilService.getUsuarios( perfil, id_usuario );
    this.filtro[path] = usuarios['children'][path]
  }
}
