<div class="row">
    <div class="col-4">
        <ul class="progress-cotizacion">
			<li class="active" *ngIf="tramite"><span>CLIENTE</span></li>
            <li class="active"><span>EQUIPO</span></li>
            <li class="active"><span>COTIZACIÓN</span></li>
            <li class="active"><span>RESIDUAL</span></li>
        </ul>
    </div>
</div>
<form [formGroup]="residualForm">
<div class="row">
    <div class="col-2">
        <div class="input">
            <label>Valor Residual 12 meses
                <svg (click)="Tooltip(41)" style="height: 15px; width:auto; border-radius: 45px; border:2px solid #3F4A57; margin-right: 5px;" xmlns="http://www.w3.org/2000/svg" width="17.328" height="17.328" viewBox="0 0 17.328 17.328"><defs><style>.info_icon{fill:#fff; stroke: #3F4A57;}</style></defs><path class="info_icon" d="M10.664,2a8.664,8.664,0,1,0,8.664,8.664A8.664,8.664,0,0,0,10.664,2Zm.866,13H9.8V13.263H11.53Zm0-3.466H9.8v-5.2H11.53Z" transform="translate(-2 -2)"/></svg>
            </label>
            <input maxlength="6" type="text" placeholder=" %" formControlName="valorResidual12" mask="separator.2" suffix=" %" (input)="fail_residual12 = false">
            <mat-error *ngIf="submit && !(f.valorResidual12.value.length)">Campo obligatorio</mat-error>
            <mat-error *ngIf="f.valorResidual12.value && fail_residual12">Porcentaje no válido</mat-error>
        </div>
        <div class="input">
            <label>Valor Residual 18 meses
                <svg (click)="Tooltip(36)" style="height: 15px; width:auto; border-radius: 45px; border:2px solid #3F4A57; margin-right: 5px;" xmlns="http://www.w3.org/2000/svg" width="17.328" height="17.328" viewBox="0 0 17.328 17.328"><defs><style>.info_icon{fill:#fff; stroke: #3F4A57;}</style></defs><path class="info_icon" d="M10.664,2a8.664,8.664,0,1,0,8.664,8.664A8.664,8.664,0,0,0,10.664,2Zm.866,13H9.8V13.263H11.53Zm0-3.466H9.8v-5.2H11.53Z" transform="translate(-2 -2)"/></svg>
            </label>
            <input maxlength="6" type="text" placeholder=" %" formControlName="valorResidual18" mask="separator.2" suffix=" %" (input)="fail_residual18 = false">
            <mat-error *ngIf="submit && !(f.valorResidual18.value.length)">Campo obligatorio</mat-error>
            <mat-error *ngIf="f.valorResidual18.value && fail_residual18">Porcentaje no válido</mat-error>
        </div>
        <div class="input">
            <label>Valor Residual 24 meses
                <svg (click)="Tooltip(31)" style="height: 15px; width:auto; border-radius: 45px; border:2px solid #3F4A57; margin-right: 5px;" xmlns="http://www.w3.org/2000/svg" width="17.328" height="17.328" viewBox="0 0 17.328 17.328"><defs><style>.info_icon{fill:#fff; stroke: #3F4A57;}</style></defs><path class="info_icon" d="M10.664,2a8.664,8.664,0,1,0,8.664,8.664A8.664,8.664,0,0,0,10.664,2Zm.866,13H9.8V13.263H11.53Zm0-3.466H9.8v-5.2H11.53Z" transform="translate(-2 -2)"/></svg>
            </label>
            <input maxlength="6" type="text" placeholder=" %" formControlName="valorResidual24" mask="separator.2" suffix=" %" (input)="fail_residual24 = false">
            <mat-error *ngIf="submit && !(f.valorResidual24.value.length)">Campo obligatorio</mat-error>
            <mat-error *ngIf="f.valorResidual24.value && fail_residual24">Porcentaje no válido</mat-error>
        </div>
    </div>
    <div class="col-2">
        <div class="input">
            <label>Valor Residual 36 meses
                <svg (click)="Tooltip(26)" style="height: 15px; width:auto; border-radius: 45px; border:2px solid #3F4A57; margin-right: 5px;" xmlns="http://www.w3.org/2000/svg" width="17.328" height="17.328" viewBox="0 0 17.328 17.328"><defs><style>.info_icon{fill:#fff; stroke: #3F4A57;}</style></defs><path class="info_icon" d="M10.664,2a8.664,8.664,0,1,0,8.664,8.664A8.664,8.664,0,0,0,10.664,2Zm.866,13H9.8V13.263H11.53Zm0-3.466H9.8v-5.2H11.53Z" transform="translate(-2 -2)"/></svg>
            </label>
            <input maxlength="6" type="text" placeholder=" %" formControlName="valorResidual36" mask="separator.2" suffix=" %" (input)="fail_residual36 = false">
            <mat-error *ngIf="submit && !(f.valorResidual36.value.length)">Campo obligatorio</mat-error>
            <mat-error *ngIf="f.valorResidual36.value && fail_residual36">Porcentaje no válido</mat-error>
        </div>
        <div class="input">
            <label>Valor Residual 48 meses
                <svg (click)="Tooltip(21)" style="height: 15px; width:auto; border-radius: 45px; border:2px solid #3F4A57; margin-right: 5px;" xmlns="http://www.w3.org/2000/svg" width="17.328" height="17.328" viewBox="0 0 17.328 17.328"><defs><style>.info_icon{fill:#fff; stroke: #3F4A57;}</style></defs><path class="info_icon" d="M10.664,2a8.664,8.664,0,1,0,8.664,8.664A8.664,8.664,0,0,0,10.664,2Zm.866,13H9.8V13.263H11.53Zm0-3.466H9.8v-5.2H11.53Z" transform="translate(-2 -2)"/></svg>
            </label>
            <input maxlength="6" type="text" placeholder=" %" formControlName="valorResidual48" mask="separator.2" suffix=" %" (input)="fail_residual48 = false">
            <mat-error *ngIf="submit && !(f.valorResidual48.value.length)">Campo obligatorio</mat-error>
            <mat-error *ngIf="f.valorResidual48.value && fail_residual48">Porcentaje no válido</mat-error>
        </div>
        <div class="input" *ngIf="residual_60">
            <label>Valor Residual 60 meses
                <svg (click)="Tooltip(16)" style="height: 15px; width:auto; border-radius: 45px; border:2px solid #3F4A57; margin-right: 5px;" xmlns="http://www.w3.org/2000/svg" width="17.328" height="17.328" viewBox="0 0 17.328 17.328"><defs><style>.info_icon{fill:#fff; stroke: #3F4A57;}</style></defs><path class="info_icon" d="M10.664,2a8.664,8.664,0,1,0,8.664,8.664A8.664,8.664,0,0,0,10.664,2Zm.866,13H9.8V13.263H11.53Zm0-3.466H9.8v-5.2H11.53Z" transform="translate(-2 -2)"/></svg>
            </label>
            <input maxlength="6" type="text" placeholder=" %" formControlName="valorResidual60" mask="separator.2" suffix=" %" (input)="fail_residual60 = false">
            <mat-error *ngIf="submit && !(f.valorResidual60.value.length)">Campo obligatorio</mat-error>
            <mat-error *ngIf="f.valorResidual60.value && fail_residual60">Porcentaje no válido</mat-error>
        </div>
    </div>
</div>
</form>
<div class="row">
    <div class="col-4">
        <mat-spinner *ngIf="loading" diameter="30"></mat-spinner>
        <button *ngIf="!loading" mat-button class="btn-ver-cotizacion" (click)="NextPage()">Crear Cotización</button>
    </div>
</div>