<div class="header">
  <img
    class="logo"
    mat-button
    height="50px"
    width="auto"
    [routerLink]="['/dashboard']"
    src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/NewLogo-L4U.png"
    alt="leaseforu"
  />
  <div class="container-btn-login">
    <p>¿Ya tienes cuenta?</p>
    <a class="btn-login" (click)="iniciarSesion()">Iniciar sesión</a>
  </div>
</div>
<div></div>
<div class="container-register">
  <app-stepper-nueva-solicitud
    [currentStep]="currentStep"
  ></app-stepper-nueva-solicitud>
  <div class="container-form-step">
    <app-datos-personales
          [data]="data"
          (datosActualizados)="datosActualizados($event)"
          *ngIf="currentStep == 1"
          (siguiente)="onSiguiente()"
          (anterior)="onSiguiente()"
    ></app-datos-personales>
      <app-pre-calificar
          [data]="data"
          (datosActualizados)="datosActualizados($event)"
          *ngIf="currentStep == 2"
          (siguiente)="onSiguiente()"
          (anterior)="onAnterior()"

    ></app-pre-calificar>
    <app-carga-documentacion
          [data]="data"
          (datosActualizados)="datosActualizados($event)"
          *ngIf="currentStep == 3"
          (siguiente)="onSiguiente()"
          (anterior)="onAnterior()"

    ></app-carga-documentacion>
    <app-solicitud-completada
          [data]="data" 
          *ngIf="currentStep == 4"
          (siguiente)="onSiguiente()"
          (anterior)="onAnterior()"

    ></app-solicitud-completada>

  </div>

</div>
