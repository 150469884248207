import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-change-status-list',
  templateUrl: './change-status-list.component.html',
  styleUrls: ['./change-status-list.component.scss']
})
export class ChangeStatusListComponent implements OnInit {
  @Input() omitirStatus = []
  @Output() onSelect = new EventEmitter
  constructor() { }

  ngOnInit(): void {
  }

  OnSelect(value) {
    this.onSelect.emit(value)
  }

}
