import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BuroService, SolicitudService } from 'src/app/_services';
import { MessageBoxComponent } from '../message-box/message-box.component';

@Component({
  selector: 'app-editar-contacto',
  templateUrl: './editar-contacto.component.html',
  styleUrls: ['./editar-contacto.component.scss']
})
export class EditarContactoComponent implements OnInit {
  tipoContacto = 'Solicitante'
  contacto
  direccion
  esAval = false
  loading = false

  coloniasDisponibles = []

  constructor(
    private buroService: BuroService,
    private solicitudService: SolicitudService,
    private dialogRef: MatDialogRef<EditarContactoComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    if (this.data.aval) {
      this.esAval = true
      this.tipoContacto = 'Aval'
    }

    this.contacto = this.data.contacto
    this.direccion = this.data.direccion

    this.BuscarDireccion()
  }

  async Actualizar() {
    this.loading = true
    this.direccion.direccionCompleta = `${this.direccion.calle} ${this.direccion.numeroExterior} ${this.direccion.numeroInterior}, ${this.direccion.colonia}, ${this.direccion.municipio}, ${this.direccion.estado}, C.P.: ${this.direccion.cp}`.trim()
    if (this.esAval) {
      this.contacto.direccion = this.direccion
    }
    const data = this.esAval ? {aval: this.contacto} : {contacto: this.contacto, direccion: this.direccion}
    console.log(this.data.folio, data);
    await this.solicitudService.GuardarSolicitud(this.data.folio, data)
    this.loading = false
    this.dialogRef.close(data)
    this.dialog.open(MessageBoxComponent,{
      width: "500px",
			  data: {
				text: "Editar contacto",
				text2: "El contacto se ha actualizado con éxito",
				icon: "success",
				type: "success",
			  },
    })
  }

  async BuscarDireccion() {
    const cp = this.direccion.cp

		if (cp.length == 5) {
			console.log(`Buscando info: ${cp}`);
			const res: any = await this.buroService.buscarEstado(cp)
			this.guardarColonias(res)
			console.log(res);
			if (res[0].error == false) {
				const { asentamiento, municipio, estado, ciudad } = res[0].response

				console.log({municipio, estado, ciudad});
				this.direccion.ciudad = ciudad
				this.direccion.municipio = municipio
				this.direccion.estado = estado

				console.log(this.buroService.obtenerCodigoEstado(estado))
			}
			
		}

	}

	guardarColonias(direcciones) {
    this.coloniasDisponibles = []
		direcciones.forEach(element => {
			const { response: {asentamiento} } = element
			this.coloniasDisponibles.push( asentamiento )
		});
	}

  Cerrar() {
    this.dialogRef.close()
  }

}
