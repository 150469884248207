import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { AuthenticationService } from 'src/app/_services';
import { VendorsService } from 'src/app/_services/vendors.service';

@Component({
  selector: 'app-vendors-edit',
  templateUrl: './vendors-edit.component.html',
  styleUrls: ['./vendors-edit.component.scss']
})
export class VendorsEditComponent implements OnInit {
  PAISES = [
    { code: 'MX', name: 'México'},
    { code: 'PE', name: 'Perú'},
  ]

  currentUser: any = {}
  perfil = 0
  id = 0
  is_loading = false
  image_preview: any = 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/no_image.png'
  formData = null
  puede_guardar = [6,12,10,18]
  puede_validar = [15]

  puede_cambiar_nombre = [6,12,10,18]
  puede_cambiar_logo = [6,12,1,18]
  puede_cambiar_configuracion = [6,10,12,18]
  usuario_puede_cambiar_configuracion =[3019, 5853]
  puede_cambiar_residuales = [6,10,12,18]
  puede_cambiar_tasa_ap = [6,10,12,18]
  puede_cambiar_tasa_msi = [6,10,12,18]

  puede_ver_vendedores = [3,6,10,12,18]

  Vendor: any = {
    id: 0,
    fullname: '',
    descuento_ciego: 1,
    factor: 0,
    valores_residuales: {},
    tasa_mensual_financiamiento: {},
    tasa_mensual_msi: {},
    annual_insurance_amount: 0,
    vendor_payment: 0,
    commission_for_opening_type: 'a',
    commission_for_opening: 0,
    cotizar_auto: false,
    country: 'MX',
    commission_for_opening_type_msi: 'a',
    commission_for_opening_msi: 0,
    id_vendor: '',
    comision_vendor: 0,
    comision_activacion: 0,
    cofi_id: '0',
  }

  tasa_mensual_financiamiento = 0
  tasa_mensual_msi = 0

  vista = 1

  listaDocumentos = {
    documentosSolicitantePF: [],
    documentosSolicitantePM: [],
    documentosAvalPF: [],
    documentosAvalPM: [],
  }

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private vendorService: VendorsService,
    private dialog: MatDialog,
    private location: Location
    ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
    this.Vendor = JSON.parse(localStorage.getItem('vendor'))
    this.Vendor.valores_residuales = JSON.parse(this.Vendor.valores_residuales) || {}
    // this.Vendor.tasa_mensual_financiamiento = JSON.parse(this.Vendor.tasa_mensual_financiamiento) || {}
    this.Vendor.tasa_mensual_msi = JSON.parse(this.Vendor.tasa_mensual_msi) || {}
    this.image_preview = this.Vendor.url_image || 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/no_image.png'

    if (!this.Vendor.country) this.Vendor.country = 'MX'
    if (this.Vendor.cotizar_auto === null) this.Vendor.cotizar_auto = false

    // this.Vendor.commission_for_opening_type = this.Vendor.country == 'PE' ? 'p' : this.Vendor.commission_for_opening_type

    this.perfil = Number(this.currentUser.perfil)
    this.id = Number(this.currentUser.id)
    
    console.log(this.Vendor);

    this.tasa_mensual_financiamiento = this.Vendor.tasa_mensual_financiamiento.ap12
    this.tasa_mensual_msi = this.Vendor.tasa_mensual_msi.msi6
    this.Vendor.comision_vendor = this.Vendor.comision_vendor * 100
    this.Vendor.comision_activacion = this.Vendor.comision_activacion * 100
    console.log(`Perfil: ${this.currentUser.perfil}`);

    this.listaDocumentos.documentosSolicitantePF = this.Vendor.documentos_solicitante_pf.split(',').map(Number)
    this.listaDocumentos.documentosSolicitantePM = this.Vendor.documentos_solicitante_pm.split(',').map(Number)
    this.listaDocumentos.documentosAvalPF = this.Vendor.documentos_aval_pf.split(',').map(Number)
    this.listaDocumentos.documentosAvalPM = this.Vendor.documentos_aval_pm.split(',').map(Number)

    console.log(`ID: ${this.id}`);
    console.log("PUEDE CONFITG", this.usuario_puede_cambiar_configuracion.includes(this.id))

  }

  ImagePreview() {
    const vendor_image:any = document.querySelector("input[name='vendor_image']")
    const file = vendor_image.files[0]

    console.log("Seleccion de archivos");

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.image_preview = reader.result
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  GuardarVendor() {
    this.is_loading = true


    this.formData = new FormData();
    const vendor_image:any = document.querySelector("input[name='vendor_image']")

    if (this.puede_cambiar_logo.includes(this.perfil)) {
      const file = vendor_image.files[0]
  
      if (file) {
        console.log(file);
        this.formData.append("image_vendor", file);
      }
    }
    
    this.formData.append("user_id", this.Vendor.user_id)
    this.formData.append("name", this.Vendor.fullname)
    this.formData.append("descuento_ciego", `${this.Vendor.descuento_ciego}`)
    this.formData.append("factor", this.Vendor.factor)
    this.formData.append("valores_residuales", JSON.stringify(this.Vendor.valores_residuales))
    this.formData.append("tasa_mensual_financiamiento", JSON.stringify(this.Vendor.tasa_mensual_financiamiento))
    this.formData.append("tasa_mensual_msi", JSON.stringify(this.Vendor.tasa_mensual_msi))
    this.formData.append("url_image", this.Vendor.url_image || null )
    this.formData.append("annual_insurance_amount", this.Vendor.annual_insurance_amount || 0 )
    this.formData.append("vendor_payment", this.Vendor.vendor_payment || 0 )
    this.formData.append("commission_for_opening_type", this.Vendor.commission_for_opening_type || 0 )
    this.formData.append("commission_for_opening", this.Vendor.commission_for_opening || 0 )
    this.formData.append("commission_for_opening_type_msi", this.Vendor.commission_for_opening_type_msi || 0 )
    this.formData.append("commission_for_opening_msi", this.Vendor.commission_for_opening_msi || 0 )
    this.formData.append("cotizar_auto", `${this.Vendor.cotizar_auto || false}` )
    this.formData.append("country", `${this.Vendor.country || 'MX'}` )
    this.formData.append("id_vendor", `${this.Vendor.id_vendor}` )
    this.formData.append("comision_vendor", `${this.Vendor.comision_vendor / 100}` )
    this.formData.append("comision_activacion", `${this.Vendor.commission_for_opening / 100}` )
    this.formData.append("cofi_id", `${this.Vendor.cofi_id}` )

    this.vendorService.GuardarVendor(this.formData).subscribe( res => {
      console.log(res);
      this.ShowDialog('success', `Se ha actualizado el Vendor con éxito`, '', true)
      this.is_loading = false
    })
  }

  ChangeDescuentoCiego() {
    this.Vendor.descuento_ciego = this.Vendor.descuento_ciego ? false : true
  }

  ChangeCotizarAuto() {
    this.Vendor.cotizar_auto = this.Vendor.cotizar_auto ? false : true
  }

  ShowDialog(type, text, text2 = '', home = false) {
    const dialog = this.dialog.open(MessageBoxComponent, {
      width: "500px",
      data: {
        text,
        text2,
        type,
        icon: type,
      },
    }).afterClosed().subscribe(res => {
      if (home) {
        this.location.back()
      }
    })
  }

  ResetCommission() {
    this.Vendor.commission_for_opening = 0
  }
  ResetCommissionMsi() {
    this.Vendor.commission_for_opening_msi = 0
  }

  ValidarVendor() {
    console.log(`Validar vendor ${this.Vendor.id}`);
    console.log(this.tasa_mensual_msi);
    
    this.ShowDialog('success', "Se ha validado el vendor exitosamente")
  }

  ActualizarTasa(tipo) {
    switch(tipo) {
      case 1:
        this.Vendor.tasa_mensual_financiamiento = {
          ap12: this.tasa_mensual_financiamiento || 0,
          ap18: this.tasa_mensual_financiamiento || 0,
          ap24: this.tasa_mensual_financiamiento || 0,
          ap36: this.tasa_mensual_financiamiento || 0,
          ap48: this.tasa_mensual_financiamiento || 0,
          ap60: this.tasa_mensual_financiamiento || 0
        }
        break;
      case 2:
        this.Vendor.tasa_mensual_msi = {
          msi6: this.tasa_mensual_msi || 0,
          msi9: this.tasa_mensual_msi || 0,
          msi12: this.tasa_mensual_msi || 0,
          msi15: this.tasa_mensual_msi || 0,
          msi18: this.tasa_mensual_msi || 0,
          msi21: this.tasa_mensual_msi || 0,
          msi24: this.tasa_mensual_msi || 0
        }
        break;
    }
  }
  
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
  ResetPassword() {
    this.is_loading = true
    this.vendorService.ResetPassword(this.Vendor.email).subscribe(
      res => {
        this.is_loading = false
        this.ShowDialog("success", "Se ha cambiado la contraseña con exito")
      },
      err => {
        this.is_loading = false
        this.ShowDialog("error", "Ocurrió un error al cambiar la contraseña")
      }
    )
  }
}


