import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService, AuthenticationService } from 'src/app/_services';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-detalle-cotizacion',
  templateUrl: './detalle-cotizacion.component.html',
  styleUrls: ['./detalle-cotizacion.component.scss']
})
export class DetalleCotizacionComponent implements OnInit {
  version = null;
  url = null;
  submit = false;
  loading = false;
  plazo = null;

  data = {
    status: 1,
    id_cotizacion: null,
    comentarios: '',
    id_cliente: null,
    folio: null,
    id_usuario_mod: null
  };

  constructor(private userService: UserService,
              private authenticationService: AuthenticationService,
              private dialog: MatDialog,
              private router: Router) { }

  ngOnInit(): void {
    const { version, url, id_cotizacion, plazo } = JSON.parse(localStorage.getItem('cotizacion'));
    const { folio, id_cliente } = JSON.parse(localStorage.getItem('client_details'));
    const { id } = JSON.parse(localStorage.getItem('currentUser'));

    this.version = version;
    this.url = url;
    this.plazo = plazo;

    this.data.id_cotizacion = id_cotizacion;
    this.data.id_cliente = id_cliente;
    this.data.folio = folio;
    this.data.id_usuario_mod = id
  }

  CambiarStatusCotizacion() {
    if ( !this.userService.alertService.isOnline() ) { return; }

    this.submit = true;
    if ( this.data.status == 1 || !this.data.comentarios.trim().length ) { return; }

    console.log(this.data);
    this.loading = true;
    const data = this.data.status == 3
    ? {
      text: 'Éxito',
      text3: `Se ha confirmado la cotización`,
      type: 'success',
      icon: 'success'
    }
    : {
      text: 'Cotización Rechazada',
      text3: '¿Deseas realizar una nueva cotización?',
      type: 'confirm'
    };

    this.userService.CambiarStatusCotizacion(this.data)
    .subscribe( res => {

      this.userService.DeleteProcessComite({
        folio: this.data.folio,
        id_cliente: this.data.id_cliente
      }).subscribe((res2: any) => {

        const dialogref = this.dialog.open(MessageBoxComponent, {
          width: '500px',
          data
        });

        dialogref.afterClosed().subscribe( res => {
          if ( this.data.status == 2 && res ) {
            this.router.navigate(['crear_cotizacion']);
          } else {
              this.userService.getStatus(this.data.id_cliente, this.data.folio);
              this.router.navigate(['dashboard/solicitudes']);
          }
        });
      }, (err2: any) => {
        console.error('err2 :', err2);
        const dialogref = this.dialog.open(MessageBoxComponent, {
          width: '500px',
          data: {
            text: "El servicio no está disponible por el momento",
            text3: "Vuelve a intentarlo.",
            type: "error",
            icon: "error"
          }
        });
      });
    }, err => {
      this.loading = false;
      this.dialog.open(MessageBoxComponent, {
        width: '500px',
        data: {
          text2: 'El servicio no está disponible por el momento.',
          text3: 'Vuelve a intentarlo',
          type: 'error',
          icon: 'error'
        }
      });
    });
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
}
}
