import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocumentosService } from 'src/app/_services/documentos.service';

@Component({
  selector: 'app-selector-documentos-vendor',
  templateUrl: './selector-documentos-vendor.component.html',
  styleUrls: ['./selector-documentos-vendor.component.scss']
})
export class SelectorDocumentosVendorComponent implements OnInit {
  @Input() documentosSeleccionados = []
  @Output() actualizar = new EventEmitter
  @Output() guardar = new EventEmitter
  @Input() guardando = false

  constructor(
    public documentoService: DocumentosService
  ) {
  }

  ngOnInit(): void {
  }

  DocumentoSeleccionado(id) {
    return this.documentosSeleccionados.includes(id)
  }

  AgregarQuitar(id) {
    const idx = this.documentosSeleccionados.indexOf(id)
    if (idx === -1) {
      this.documentosSeleccionados.push(id)
    } else {
      this.documentosSeleccionados.splice(idx,1)
    }

    this.actualizar.emit(this.documentosSeleccionados)
  }

  GuardarDocumentos() {
    this.guardar.emit()
  }
}
