import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-card-vista-previa',
  templateUrl: './card-vista-previa.component.html',
  styleUrls: ['./card-vista-previa.component.scss']
})
export class CardVistaPreviaComponent implements OnInit {
  @Input() vista_previa
  @Input() index

  @Output() VerVistaPrevia = new EventEmitter()
  @Output() AsignarVistaPrevia = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
  }
  VerVista(): any {
    this.VerVistaPrevia.emit(this.index)
  }
  AsignarVistaPreviaFolio(){
    this.AsignarVistaPrevia.emit(this.vista_previa.folio)
  }

}
