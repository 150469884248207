import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient, private router: Router) { }

	ObtenerProductosVendor( {vendor_id = 0, user_id = 0, perfil_id = 0, country = ''} ) {
    return this.http.get<any>(`${environment.config.apiUrl}product?vendor_id=${vendor_id}&user_id=${user_id}&perfil_id=${perfil_id}&country=${country}`);
  }

	CrearProducto( formData ) {
    return this.http.post<any>(`${environment.config.apiUrl}product`, formData);
  }

	ActualizarProducto( formData ) {
    return this.http.put<any>(`${environment.config.apiUrl}product`, formData);
  }

	CotizarProducto( cotizacion ) {
    return this.http.post<any>(`${environment.config.apiUrl}cotizador/nueva`, cotizacion);
  }

	EliminarProducto( id:number ) {
    try {
      const header: HttpHeaders = new HttpHeaders()
                  .append('Content-Type', 'application/json; charset=UTF-8');
              const httpOptions = {
                  headers: header,
                  body: { id }
              };
      return this.http.delete<any>(`${environment.config.apiUrl}product`, httpOptions);
    } catch(e) {
      throw "Error al eliminar"
    }
  }

  ConsultarTipoCambio(date){
    return this.http.post<any>(`${environment.config.apiUrl}banxico/tipo_cambio`, {date});
  }
}
