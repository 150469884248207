import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { BuroService } from "src/app/_services";
import { emailPattern } from "src/app/_models/maskPattern";
import { MejorarCotizacionService } from "src/app/_services/mejorar-cotizacion.service";
import { HttpClient } from "@angular/common/http";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";
import { async } from "@angular/core/testing";
import { MejorarCotizacionJson } from "src/app/_models/mejorarCotizacion";
import { AnimationOptions } from 'ngx-lottie';



@Component({
  selector: 'app-mejorar-cotizacion',
  templateUrl: './mejorar-cotizacion.component.html',
  styleUrls: ['./mejorar-cotizacion.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],

})
export class MejorarCotizacionComponent implements OnInit {
 
  options:AnimationOptions ={
    path:'../../../assets/lottie/plus-animation.json'
  }

  COLONIAS = [];
  returnUrl: string;
  isLinear = false;
  frmDatosDomicilio: FormGroup;
  datosGen: any = {};
  frmDatosEquipo: FormGroup;
  frmInformacionLaboral: FormGroup;
  id_usuario = 0;
  file: File = null;
  fileName: string;
  frmDatosGenerales: FormGroup;
  correo: string = "";
  code: number ;
  codeConfirm: number
  // phone: number ;
  validator: boolean = false
  invalidatorCode: boolean = false
  mejorarCotizacionJson = MejorarCotizacionJson
  // phone_code: number
  // phone_code_confirm: number
  nombre: void = null;
  apellido_paterno: void = null;
  loading = false;
  frmBuro: FormGroup;

  estados = [
    { id: "CDMX", valor: "Ciudad de México", estado: "Ciudad de México" },
    { id: "AGS", valor: "Aguascalientes", estado: "Aguascalientes" },
    { id: "BCN", valor: "Baja California", estado: "Baja California" },
    { id: "BCS", valor: "Baja California Sur", estado: "Baja California Sur" },
    { id: "CAM", valor: "Campeche", estado: "Campeche" },
    { id: "CHS", valor: "Chiapas", estado: "Chiapas" },
    { id: "CHI", valor: "Chihuahua", estado: "Chihuahua" },
    { id: "COA", valor: "Coahuila", estado: "Coahuila de Zaragoza" },
    { id: "COL", valor: "Colima", estado: "Colima" },
    { id: "DGO", valor: "Durango", estado: "Durango" },
    { id: "EM", valor: "Estado de México", estado: "México" },
    { id: "GTO", valor: "Guanajuato", estado: "Guanajuato" },
    { id: "GRO", valor: "Guerrero", estado: "Guerrero" },
    { id: "HGO", valor: "Hidalgo", estado: "Hidalgo" },
    { id: "JAL", valor: "Jalisco", estado: "Jalisco" },
    { id: "MICH", valor: "Michoacán", estado: "Michoacán de Ocampo" },
    { id: "MOR", valor: "Morelos", estado: "Morelos" },
    { id: "NAY", valor: "Nayarit", estado: "Nayarit" },
    { id: "NL", valor: "Nuevo León", estado: "Nuevo León" },
    { id: "OAX", valor: "Oaxaca", estado: "Oaxaca" },
    { id: "PUE", valor: "Puebla", estado: "Puebla" },
    { id: "QRO", valor: "Querétaro", estado: "Querétaro" },
    { id: "QR", valor: "Quintana Roo", estado: "Quintana Roo" },
    { id: "SLP", valor: "San Luis Potosí", estado: "San Luis Potosí" },
    { id: "SIN", valor: "Sinaloa", estado: "Sinaloa" },
    { id: "SON", valor: "Sonora", estado: "Sonora" },
    { id: "TAB", valor: "Tabasco", estado: "Tabasco" },
    { id: "TAM", valor: "Tamaulipas", estado: "Tamaulipas" },
    { id: "TLAX", valor: "Tlaxcala", estado: "Tlaxcala" },
    { id: "VER", valor: "Veracruz", estado: "Veracruz de Ignacio de la Llave" },
    { id: "YUC", valor: "Yucatán", estado: "Yucatán" },
    { id: "ZAC", valor: "Zacatecas", estado: "Zacatecas" },
  ];
 

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private buroService: BuroService,
    private mejorarCotizacion: MejorarCotizacionService,
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    
  ) {}



  get fd() {
    return this.frmDatosDomicilio.controls;
  }

  get fe() {
    return this.frmDatosEquipo.controls;
  }

  get fl() {
    return this.frmInformacionLaboral.controls;
  }

  get fg(){
    return this.frmDatosGenerales.controls;
  }

  get fb(){
    return this.frmBuro.controls
  }
  
  
  ngOnInit(): void {
    localStorage.removeItem('mejorar_cotizacion')

    this.validator = true
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || "/dashboard";
    // this.id_usuario = JSON.parse(
    //   localStorage.getItem("mejorar_cotizacion")
    // ).id_usuario;
    
    
    console.log(this.mejorarCotizacionJson)

    this.frmDatosDomicilio = this.formBuilder.group({
      calle: ["", Validators.required],
      cp: ["", [Validators.required]],
      ciudad: ["", Validators.required],
      estado: ["", Validators.required],
      delegacion_municipio: ["", Validators.required],
      colonia: ["", Validators.required],
      estado_nombre: [""],
      num_ext: ["", Validators.required]

    });
    this.frmDatosEquipo = this.formBuilder.group({
      nombre_equipo: ["", Validators.required],
      marca_equipo: ["", Validators.required],
    });
    this.frmInformacionLaboral = this.formBuilder.group({
      ocupacion: ["", Validators.required],
      especialidad: ["", ],
      subespecialidad: ["", ],
      certificacion_vigente: ["", ],
      ingresos_mensuales: ["", Validators.required],
      otros_ingresos: [0],
      

    });
    this.frmDatosGenerales = this.formBuilder.group({
      primer_nombre: ['', Validators.required],
      segundo_nombre: ['', ],
      apellido_paterno: ['', Validators.required],
      apellido_materno: ['', Validators.required],
      fecha_nacimiento: ['', Validators.required],
      rfc: ['', [Validators.required, Validators.maxLength(13)]],
      sexo: ['', Validators.required]
    })
    this.frmBuro = this.formBuilder.group({
      phone: ['', Validators.required],
      phone_code: ['', Validators.required],
      phone_code_confirm: ['', Validators.required ],
      checkAcept: [false, Validators.requiredTrue]
    },
     { validator: this.ConfirmedCode('phone_code', 'phone_code_confirm')}
    )
    this.route.queryParams.subscribe( params => {
      // console.log(params);
      const {tipo_producto,monto,plazo,pago_inicial,mensualidad} = params
      console.log(params)
      this.mejorarCotizacionJson.cotizacion = {tipo_producto, monto: Number(monto), plazo: Number(plazo), pago_inicial: Number(pago_inicial), mensualidad: Number(mensualidad), mensualidad_mejorada: Number(mensualidad)}
  })
  console.log(this.mejorarCotizacionJson)

    
  }

  async SolicitarPin(){
    console.log(this.correo)
    const email = {
      email:this.correo
    }
    this.mejorarCotizacionJson.email = this.correo
    try{
      const response = await this.mejorarCotizacion.EnviarPinEmail(email);
      console.log(response)
      this._snackBar.open('Se ha enviado correctamente el código, por favor revisa tu correo ', 'Ok',
      {
      duration: 10000,
        horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['success-snackbar']})

    }catch(err){
      console.log(err)
    }


  }
  async SolicitarPinPhone(){
    console.log(this.fb.phone.value)
    const phone = {
      phone: this.fb.phone.value
    }
    console.log(this.mejorarCotizacionJson)
    try{
      this.mejorarCotizacionJson.phone = this.fb.phone.value.toString()
      const response = await this.mejorarCotizacion.EnviarPinPhone(phone);
      console.log(response)
      this._snackBar.open('Se ha enviado correctamente el código', 'Ok',
      {
      duration: 10000,
        horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['success-snackbar']})

    }catch(err){
      console.log(err)
      this._snackBar.open('Ha ocurrido un error, por favor revisa que tu teléfono sea el correcto. ', 'Ok',
      {
      duration: 10000,
        horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['warning-snackbar']})
    }


  }
  async VerificarEmail(){
    try{
      if(this.code != this.codeConfirm) {
        this.invalidatorCode = true;
       
      }else{
        const res = await this.mejorarCotizacion.VerificarEmail(this.correo, this.code)
        this.mejorarCotizacionJson.email_code = this.code.toString()
        this.validator = false
      }
     

    }catch(e){
      console.log(e)
      this._snackBar.open('Ha ocurrido un error, favor de validar que el código de verificación sea el mismo al que enviamos a tu correo. ', 'Ok',
      {
      duration: 10000,
        horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['warning-snackbar']})
    }
    console.log(this.mejorarCotizacionJson.email)

    
  }
// ------Subir Documento-----
  // // onFileSelect(input: HTMLInputElement) {
  // //   const file = input.files[0];
  // //   // this.file = event.target.files[0];
  // //   console.log(file);
  // //   this.fileName = `${file.name}`;
  // // }

  // // upload() {
  // //   if (this.file) {
  // //     this.uploadService.uploadfile(this.file).subscribe((resp) => {
  // //       alert("Uploaded");
  // //     });
  // //   } else {
  // //     alert("Please select a file first");
  // //   }
  // // }

  async SearchState(cp) {
    if (cp.length == 5) {
      console.log("Buscar: ", cp);
      const estados: any = await this?.buroService.buscarEstado(cp);
      console.log("Esto es:", estados);
      const colonia = estados.map((d) => d.response.asentamiento);
      this.COLONIAS = colonia;
      for (const cur of estados) {
        if (!cur.error) {
          const { municipio, estado, ciudad, asentamiento } = cur.response;
          console.log({ municipio, estado, ciudad, asentamiento });
          this.fd.delegacion_municipio.setValue(municipio);
          this.fd.ciudad.setValue(ciudad);
          this.fd.estado.setValue(this.getEstado(estado));
          this.fd.estado_nombre.setValue(estado);

          break;
        }
      }
    }
  }
  getEstado(estado) {
    const result: any = this.estados.filter((e) => e.estado === estado);
    return result.length ? result[0].id : "CDMX";
  }

  getData(){
    this.mejorarCotizacionJson.prospecto = {...this.frmDatosGenerales.value}
    this.mejorarCotizacionJson.domicilio = {...this.frmDatosDomicilio.value}
    this.mejorarCotizacionJson.laboral = {...this.frmInformacionLaboral.value}
    this.mejorarCotizacionJson.equipo = {...this.frmDatosEquipo.value}
    console.log(this.mejorarCotizacionJson)

  }

  async Siguiente(){
    this.getData()
    try {
      console.log(this.frmDatosDomicilio.value);
      const data = this.mejorarCotizacionJson
      
      console.log("Guardando informacion", data);
      const response = await this.mejorarCotizacion.Mejorar(data);
      console.log(response);
      this.mejorarCotizacionJson.cotizacion.mensualidad_mejorada = response.mensualidad_mejorada
    
    } catch (err){
      console.log(err)
      this._snackBar.open('A ocurrido un error, por favor vuelve a intentarlo más tarde. ', 'Ok',
      {
      duration: 10000,
        horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['warning-snackbar']})
    }
  }

  async Guardar() {
    this.loading = true;
    this.mejorarCotizacionJson.phone = this.fb.phone.value.toString()
      this.mejorarCotizacionJson.phone_code = this.fb.phone_code.value.toString()
      this.nombre = localStorage.setItem('nombreProspecto', JSON.stringify(this.mejorarCotizacionJson.prospecto.primer_nombre))
      this.apellido_paterno = localStorage.setItem('apellidoProspecto', JSON.stringify(this.mejorarCotizacionJson.prospecto.apellido_paterno))
  
    const data = this.mejorarCotizacionJson
 
      console.log(data)
    try {
      
        
        const response = await this.mejorarCotizacion.Mejorar(data);
        console.log(response);
        console.log(this.mejorarCotizacionJson)
        localStorage.setItem('mejorar_cotizacion', JSON.stringify(MejorarCotizacionJson))
        localStorage.setItem('mensualidad_mejorada', response.mensualidad_mejorada)
        this.router.navigate(['/mejorar_cotizacion/pre-aprobado'])
      
     
    } catch (err){

      console.log(err.error.message)
      const noAprobado = err
      localStorage.setItem('noAprobado', JSON.stringify(noAprobado))
      this.router.navigate(['/mejorar_cotizacion/pre-aprobado'])
    }
  
  }
  ConfirmedCode(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  isInvalid() {
    return (
      !this.frmDatosDomicilio.valid ||
      !this.frmDatosEquipo.valid ||
      !this.frmInformacionLaboral.valid ||
      !this.frmBuro.valid
    );
  }
 

}

