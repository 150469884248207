<div class="body-precal">
    <div class="container-form">
      <form [formGroup]="domicilioForm">
        <mat-form-field appearance="outline">
            <mat-label>Código Postal</mat-label>
            <input #input_cp matInput type="text" formControlName="cp" name="cp" (input)="SearchState(input_cp.value)" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Calle</mat-label>
          <input matInput type="text" formControlName="calle" name="calle" />
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Numero exterior</mat-label>
            <input matInput type="text" formControlName="num_ext" name="num_ext" />
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Ciudad</mat-label>
            <input matInput type="text" formControlName="ciudad" name="ciudad" />
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Estado</mat-label>
            <input matInput type="text" formControlName="estado" name="estado" />
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Delegación</mat-label>
            <input matInput type="text" formControlName="delegacion_municipio" name="delegacion_municipio" />
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Colonia o Población</mat-label>
            <mat-select formControlName="colonia" required>
              <mat-option
                *ngFor="let colonias of COLONIAS"
                [value]="colonias"
                >{{ colonias }}</mat-option
              >
            </mat-select>
          </mat-form-field>
         
      </form>
      <div class="container-btn">
        <button
          class="btn-secondary-new"
          matStepperPrevious
        >
          Regresar
        </button>
        <button
          class="btn-new-p"
          color="primary"
          (click)="enviarDatos()"
          matStepperNext
        >
          Continuar
        </button>
      </div>
    </div>
  </div>
  