<div class="container-all">
    <div class="header" style="box-shadow: 0 2px 12px rgb(0 71 128 / 16%)">
      <div class="menu-header">
        <img
          class="logo"
          mat-button
          height="40px"
          width="auto"
          src="https://static.api.leaseforu.com/assets/New-logo-LFU.svg"
          alt="leaseforu"
        />
      </div>
    </div>
    <mat-drawer-container class="example-container">
      <mat-drawer mode="side" position="end" opened>
        <div class="container-open">
          <div class="container-mensualidades">
            <div class="title-men">Tipo de producto</div>
            <div class="text-men">
              {{ mejorarCotizacionJson.cotizacion.tipo_producto }}
            </div>
            <div class="title-men">Precio del equipo médico</div>
            <div class="text-men">
              {{
                mejorarCotizacionJson.cotizacion.monto
                  | currency : "USD" : "symbol-narrow"
              }}
            </div>
            <div class="title-men">Enganche</div>
            <div class="text-men">
              {{
                mejorarCotizacionJson.cotizacion.pago_inicial
                  | currency : "USD" : "symbol-narrow"
              }}
            </div>
            <div class="title-men">Plazo a financiar</div>
            <div class="text-men">
              {{ mejorarCotizacionJson.cotizacion.plazo }} mensualidades
            </div>
            <div class="title-men">Monto a pagar mensual</div>
            <div class="text-men">
              {{
                mejorarCotizacionJson.cotizacion.mensualidad
                  | currency : "USD" : "symbol-narrow"
              }}
            </div>
          </div>
          <div>
            <ng-lottie [options]="options"></ng-lottie>
          </div>
          <div
            class="container-footer"
            *ngIf="
              mejorarCotizacionJson.cotizacion.mensualidad ==
              mejorarCotizacionJson.cotizacion.mensualidad_mejorada
            "
          >
            <div class="title-men">Mensualidad desde</div>
            <div class="text-fija">
              {{
                mejorarCotizacionJson.cotizacion.mensualidad_mejorada
                  | currency : "USD" : "symbol-narrow"
              }}
            </div>
          </div>
  
          <div
            class="container-footer"
            *ngIf="
              mejorarCotizacionJson.cotizacion.mensualidad !=
              mejorarCotizacionJson.cotizacion.mensualidad_mejorada
            "
          >
            <div class="title-men">Mensualidad desde</div>
            <div class="text-fija">
              {{
                mejorarCotizacionJson.cotizacion.mensualidad_mejorada
                  | currency : "USD" : "symbol-narrow"
              }}
              {{ " " }}
              <span>{{
                mejorarCotizacionJson.cotizacion.mensualidad
                  | currency : "USD" : "symbol-narrow"
              }}</span>
            </div>
          </div>
        </div>
      </mat-drawer>
      <mat-drawer-content>
        <div class="container_mejoracotizacion">
          <h1>Mejorar tu cotizacion</h1>
          <div class="container-validator" *ngIf="validator">
            <div class="container-form">
              <div class="container-title">
                Para comenzar, es importante que validemos tu correo electrónico
              </div>
              <div class="container-date">
                <mat-form-field appearance="outline">
                  <mat-label>Correo electrónico</mat-label>
                  <input
                    matInput
                    type="text"
                    [email]="true"
                    id="correo"
                    name="correo"
                    [(ngModel)]="correo"
                  />
                </mat-form-field>
                <div class="container-btn-secondary">
                  <button class="btn-secondary" (click)="SolicitarPin()">
                    Solicitar PIN
                  </button>
                </div>
              </div>
              <div class="container-title">
                Por favor, ingresa el código de validación que te enviamos a tu
                correo electrónico:
              </div>
              <div class="container-date">
                <mat-form-field appearance="outline">
                  <mat-label>Código de validación</mat-label>
                  <input
                    matInput
                    mask="000000"
                    required
                    id="code"
                    name="code"
                    [(ngModel)]="code"
                  />
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Confirma código de validación</mat-label>
                  <input
                    matInput
                    mask="000000"
                    [(ngModel)]="codeConfirm"
                    id="codeConfirm"
                    name="codeConfirm"
                    [pattern]="code"
                  />
                  <mat-error *ngIf="invalidatorCode" class="invalid-feedback">
                    <img src="https://static.api.leaseforu.com/assets/warning.svg" />
                    <div *ngIf="invalidatorCode">Deben coincidir los códigos</div>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="aviso-privacidad">
                Al ingresar tu correo electrónico, estarás aceptando nuestro
                <a>Aviso de Privacidad</a> y el uso de medios electrónicos de
                autenticación, como Número de solicitud (NIP).
              </div>
              <div class="container-btn">
                <button
                  mat-raised-button
                  class="btn-primary-new"
                  color="primary"
                  (click)="VerificarEmail()"
                >
                  Continuar
                </button>
              </div>
            </div>
          </div>
          <div class="container-stepper" *ngIf="!validator">
            <mat-horizontal-stepper #stepper>
              <mat-step [stepControl]="frmDatosGenerales">
                <form [formGroup]="frmDatosGenerales">
                  <ng-template matStepLabel>General</ng-template>
                  <div class="container-form">
                    <div class="container-title">
                      Ingresa tus datos personales. Estos deben coincidir con tus
                      documentos:
                    </div>
                    <div class="container-date">
                      <mat-form-field appearance="outline">
                        <mat-label>Primer nombre</mat-label>
                        <input
                          matInput
                          type="text"
                          formControlName="primer_nombre"
                          required
                        />
                        <mat-error
                          *ngIf="
                            !fg.primer_nombre.valid && fg.primer_nombre.touched
                          "
                          [ngClass]="{
                            'invalid-input':
                              !fg.primer_nombre.valid && fg.primer_nombre.touched
                          }"
                        >
                          <img src="https://static.api.leaseforu.com/assets/warning.svg" /> Ingresa
                          tu nombre</mat-error
                        >
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                        <mat-label>Segundo nombre</mat-label>
                        <input
                          matInput
                          type="text"
                          formControlName="segundo_nombre"
                        />
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                        <mat-label>Apellido Paterno</mat-label>
                        <input
                          matInput
                          type="text"
                          formControlName="apellido_paterno"
                          required
                        />
                      </mat-form-field>
  
                      <mat-form-field appearance="outline">
                        <mat-label>Apellido Materno</mat-label>
                        <input
                          matInput
                          type="text"
                          formControlName="apellido_materno"
                          required
                        />
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                        <mat-label>Fecha de nacimiento</mat-label>
                        <input
                          matInput
                          formControlName="fecha_nacimiento"
                          type="date"
                        />
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                        <mat-label
                          >Sexo</mat-label
                        >
                        <mat-select
                          formControlName="sexo"
                          required
                        >
                          <mat-option value="F"> F </mat-option>
                          <mat-option value="M"> M </mat-option>
                        </mat-select>
                        <mat-error
                          *ngIf="
                            !fg.sexo.valid &&
                            fg.sexo.touched
                          "
                          [ngClass]="{
                            'invalid-input':
                              !fg.sexo.valid &&
                              fg.sexo.touched
                          }"
                        >
                          <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/warning.svg" />
                          Selecciona una opción</mat-error
                        >
                      </mat-form-field>
  
                      <mat-form-field appearance="outline">
                        <mat-label>RFC</mat-label>
  
                        <input
                          matInput
                          type="text"
                          formControlName="rfc"
                          maxlength="13"
                          required
                        />
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                        <mat-label>Correo electrónico</mat-label>
  
                        <input
                          matInput
                          [value]="mejorarCotizacionJson.email"
                          required
                        />
                      </mat-form-field>
                    </div>
                    <div class="container-btn">
                      <button
                        mat-raised-button
                        class="btn-primary-new"
                        color="primary"
                        (click)="Siguiente()"
                        matStepperNext
                      >
                        Continuar
                      </button>
                    </div>
                  </div>
                </form>
              </mat-step>
              <mat-step [stepControl]="frmDatosDomicilio">
                <form [formGroup]="frmDatosDomicilio">
                  <ng-template matStepLabel>Domicilio</ng-template>
                  <div class="container-form">
                    <div class="container-title">
                      Ingresa tus datos de tu domicilio actual
                      <span class="text-span"
                        >(Los usaremos para consultar tu historial
                        crediticio)</span
                      >
                    </div>
                    <div class="container-date">
                      <mat-form-field appearance="outline">
                        <mat-label>Calle</mat-label>
                        <input
                          matInput
                          type="text"
                          formControlName="calle"
                          required
                        />
                        <mat-error
                          *ngIf="!fd.calle.valid && fd.calle.touched"
                          [ngClass]="{
                            'invalid-input': !fd.calle.valid && fd.calle.touched
                          }"
                        >
                          <img src="https://static.api.leaseforu.com/assets/warning.svg" /> Ingresa
                          calle</mat-error
                        >
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                        <mat-label>Número exterior</mat-label>
                        <input
                          matInput
                          type="text"
                          formControlName="num_ext"
                          required
                        />
                        <mat-error
                          *ngIf="!fd.num_ext.valid && fd.num_ext.touched"
                          [ngClass]="{
                            'invalid-input': !fd.num_ext.valid && fd.num_ext.touched
                          }"
                        >
                          <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/warning.svg" /> Ingresa
                          número exterior</mat-error
                        >
                      </mat-form-field>
  
                      <mat-form-field appearance="outline">
                        <mat-label>Código Postal</mat-label>
                        <input
                          matInput
                          #input_cp
                          mask="00000"
                          formControlName="cp"
                          [value]="datosGen.cp"
                          [ngClass]="{
                            'invalid-input': !fd.cp.valid
                          }"
                          (input)="SearchState(input_cp.value)"
                          required
                        />
                        <mat-error
                          *ngIf="!fd.cp.valid && fd.cp.touched"
                          [ngClass]="{
                            'invalid-input': !fd.cp.valid && fd.cp.touched
                          }"
                        >
                          <img src="https://static.api.leaseforu.com/assets/warning.svg" /> Ingresa
                          código postal correcto</mat-error
                        >
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                        <mat-label>Ciudad</mat-label>
                        <input
                          matInput
                          type="text"
                          formControlName="ciudad"
                          required
                        />
                      </mat-form-field>
  
                      <mat-form-field appearance="outline">
                        <mat-label>Estado</mat-label>
                        <input
                          matInput
                          type="text"
                          formControlName="estado_nombre"
                        readonly="true"
                          required
                        />
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                        <mat-label>Delegación o Municipio</mat-label>
                        <input
                          matInput
                          type="text"
                          formControlName="delegacion_municipio"
                          required
                        />
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                        <mat-label>Colonia o Población</mat-label>
                        <!-- <input
                    matInput
                    type="text"
                    formControlName="colonia"
                    required
                  /> -->
                        <mat-select formControlName="colonia" required>
                          <mat-option
                            *ngFor="let colonias of COLONIAS"
                            [value]="colonias"
                            >{{ colonias }}</mat-option
                          >
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="container-btn">
                      <button
                        mat-raised-button
                        class="btn-secondary-new"
                        color="secondary"
                        matStepperPrevious
                      >
                        Regresar
                      </button>
                      <button
                        mat-raised-button
                        class="btn-primary-new"
                        color="primary"
                        matStepperNext
                        (click)="Siguiente()"
                      >
                        Continuar
                      </button>
                    </div>
                  </div>
                </form>
              </mat-step>
  
              <mat-step>
                <form [formGroup]="frmInformacionLaboral">
                  <ng-template matStepLabel>Ocupación</ng-template>
                  <div class="container-form">
                    <div class="container-title">
                      Ingresa tus datos de ocupación:
                    </div>
  
                    <div class="container-date">
                      <mat-form-field appearance="outline">
                        <mat-label>Ocupación</mat-label>
                        <mat-select formControlName="ocupacion" required>
                          <mat-option value="medico"> Médico</mat-option>
                          <mat-option value="otro"> Otro </mat-option>
                        </mat-select>
                        <mat-error
                          *ngIf="!fl.ocupacion.valid && fl.ocupacion.touched"
                          [ngClass]="{
                            'invalid-input':
                              !fl.ocupacion.valid && fl.ocupacion.touched
                          }"
                        >
                          <img src="https://static.api.leaseforu.com/assets/warning.svg" /> Ingresa
                          tu ocupación</mat-error
                        >
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                        <mat-label>Especialidad</mat-label>
                        <input
                          matInput
                          type="text"
                          formControlName="especialidad"
                        />
                        <mat-error
                          *ngIf="
                            !fl.especialidad.valid && fl.especialidad.touched
                          "
                          [ngClass]="{
                            'invalid-input':
                              !fl.especialidad.valid && fl.especialidad.touched
                          }"
                        >
                          <img src="https://static.api.leaseforu.com/assets/warning.svg" /> Ingresa
                          una especialidad</mat-error
                        >
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                        <mat-label>Subespecialidad</mat-label>
                        <input
                          matInput
                          type="text"
                          formControlName="subespecialidad"
                        />
                        <mat-error
                          *ngIf="
                            !fl.subespecialidad.valid &&
                            fl.subespecialidad.touched
                          "
                          [ngClass]="{
                            'invalid-input':
                              !fl.subespecialidad.valid &&
                              fl.subespecialidad.touched
                          }"
                        >
                          <img src="https://static.api.leaseforu.com/assets/warning.svg" /> Ingresa
                          una Subespecialidad</mat-error
                        >
                      </mat-form-field>
  
                      <mat-form-field appearance="outline">
                        <mat-label
                          >¿Certificación de especialidad vigente?</mat-label
                        >
                        <mat-select
                          formControlName="certificacion_vigente"
                          required
                        >
                          <mat-option value="Si"> Si </mat-option>
                          <mat-option value="No"> No </mat-option>
                        </mat-select>
                        <mat-error
                          *ngIf="
                            !fl.certificacion_vigente.valid &&
                            fl.certificacion_vigente.touched
                          "
                          [ngClass]="{
                            'invalid-input':
                              !fl.certificacion_vigente.valid &&
                              fl.certificacion_vigente.touched
                          }"
                        >
                          <img src="https://static.api.leaseforu.com/assets/warning.svg" />
                          Selecciona una opción</mat-error
                        >
                      </mat-form-field>
  
                      <div class="container-title">
                        Ingresa tus ingresos mensuales:
                      </div>
                      <div></div>
                      <mat-form-field appearance="outline">
                        <mat-label>Ingresos Mensuales Comprobables</mat-label>
                        <input
                          mask="separator.2"
                          thousandSeparator=","
                          matInput
                          formControlName="ingresos_mensuales"
                          required
                        />
                        <mat-error
                          *ngIf="
                            !fl.ingresos_mensuales.valid &&
                            fl.ingresos_mensuales.touched
                          "
                          [ngClass]="{
                            'invalid-input':
                              !fl.ingresos_mensuales.valid &&
                              fl.ingresos_mensuales.touched
                          }"
                        >
                          <img src="https://static.api.leaseforu.com/assets/warning.svg" /> Ingresa
                          tus ingresos mensuales</mat-error
                        >
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                        <mat-label>Ingresos mensuales no comprobables</mat-label>
                        <input
                          matInput
                          mask="separator.2"
                          thousandSeparator=","
                          formControlName="otros_ingresos"
                        />
                      </mat-form-field>
                    </div>
                  </div>
                </form>
                <form [formGroup]="frmDatosEquipo">
                  <div class="container-form">
                    <div class="container-title">
                      Ingresa los datos del equipo y/o automóvil en el que estás
                      interesado:
                    </div>
                    <div class="container-date">
                      <mat-form-field appearance="outline">
                        <mat-label>Nombre del equipo</mat-label>
                        <input
                          matInput
                          type="text"
                          required
                          formControlName="nombre_equipo"
                        />
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                        <mat-label>Marca del equipo</mat-label>
                        <input
                          matInput
                          type="text"
                          required
                          formControlName="marca_equipo"
                        />
                      </mat-form-field>
                    </div>
                    <div class="container-btn">
                      <button
                        mat-raised-button
                        class="btn-secondary-new"
                        color="secondary"
                        matStepperPrevious
                      >
                        Regresar
                      </button>
                      <button
                        mat-raised-button
                        class="btn-primary-new"
                        color="primary"
                        matStepperNext
                        (click)="Siguiente()"
                      >
                        Continuar
                      </button>
                    </div>
                  </div>
                </form>
              </mat-step>
              <mat-step>
                <form [formGroup]="frmBuro">
                  <ng-template matStepLabel>Score de Buró</ng-template>
                  <div class="container-form">
                    <div class="container-title">
                      Enviaremos tu número de solicitud (NIP) por mensaje de
                      texto.
                    </div>
                    <div class="container-date">
                      <mat-form-field appearance="outline">
                        <mat-label>Numero de celular a 10 dígitos</mat-label>
                        <input
                          matInput
                          mask="00-0000-0000"
                          required
                          formControlName="phone"
                        />
                      </mat-form-field>
                      <div class="container-btn-secondary">
                        <button
                          class="btn-secondary"
                          (click)="SolicitarPinPhone()"
                        >
                          Solicitar PIN
                        </button>
                      </div>
                    </div>
                    <div class="container-title">
                      Ingresa el número de solicitud (NIP) que te enviamos:
                    </div>
                    <div class="container-date">
                      <mat-form-field appearance="outline">
                        <mat-label>Número de solicitud(NIP)</mat-label>
                        <input
                          matInput
                          mask="0000"
                          required
                          formControlName="phone_code"
                        />
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                        <mat-label>Confirma número de solicitud(NIP)</mat-label>
                        <input
                          matInput
                          mask="0000"
                          required
                          formControlName="phone_code_confirm"
                        />
                        <mat-error
                          *ngIf="fb.phone_code_confirm.errors?.confirmedValidator"
                        >
                          <img src="https://static.api.leaseforu.com/assets/warning.svg"/>
                          <div>Deben coincidir los códigos</div>
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="aviso-privacidad">
                      <input type="checkbox" formControlName="checkAcept" />
                      
                      Autorizo expresamente a <a>LEASE
                      FOR YOU SA DE CV.</a>, para lleve
                      a cabo Investigaciones, sobre mi <a>comportamiento Crediticio </a>
                      en SIC que estime conveniente. Conozco la naturaleza y
                      alcance de la información que se solicitará, del uso que se
                      le dará y que se podrá realizar consultas periódicas de mi
                      historial crediticio, consiento que esta autorización tenga
                      vigencia de 3 años contados a partir de hoy, y en su caso
                      mientras mantengamos relación jurídica. 
                    </div>
                    <div class="container-btn">
                      <button
                        mat-raised-button
                        class="btn-secondary-new"
                        color="secondary"
                        matStepperPrevious
                      >
                        Regresar
                      </button>
                      <button
                        mat-raised-button
                        class="btn-primary-new"
                        color="primary"
                        (click)="Guardar()"
                        [disabled]="isInvalid() || loading"
                      >
                        <mat-progress-spinner
                          *ngIf="loading"
                          mode="indeterminate"
                          diameter="30"
                        ></mat-progress-spinner>
                        <span *ngIf="!loading">Continuar</span>
                      </button>
                    </div>
                  </div>
                </form>
              </mat-step>
            </mat-horizontal-stepper>
          </div>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
  