<div class="container">
    <div class="top">
        <div class="header">
            <img class="logo" mat-button height="60px" width="auto" [routerLink]="['/dashboard']" src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/logo-header.svg" alt="">
            <div>
                <button mat-button routerLink="/changePassword">Cambiar Contraseña
                    <svg style="height: 20px; width: auto;" xmlns="http://www.w3.org/2000/svg" width="31.992" height="31.992" viewBox="0 0 31.992 31.992"><defs><style>.a{fill:#3ca2d6;}</style></defs><path class="a" d="M18,2a16,16,0,1,0,16,16A16.006,16.006,0,0,0,18,2Zm0,1.391A14.6,14.6,0,1,1,3.391,18,14.594,14.594,0,0,1,18,3.391Zm0,4.868a4.164,4.164,0,0,0-4.173,4.173v2.086h-.7A2.1,2.1,0,0,0,11.041,16.6V23.56a2.1,2.1,0,0,0,2.086,2.086h9.737a2.1,2.1,0,0,0,2.086-2.086V16.6a2.1,2.1,0,0,0-2.086-2.086h-.7V12.432A4.164,4.164,0,0,0,18,8.259ZM18,9.65a2.724,2.724,0,0,1,2.782,2.782v2.086H15.214V12.432A2.724,2.724,0,0,1,18,9.65Zm-4.868,6.259H14.4a.7.7,0,0,0,.227,0h6.728a.7.7,0,0,0,.227,0h1.281a.693.693,0,0,1,.7.7V23.56a.693.693,0,0,1-.7.7H13.128a.693.693,0,0,1-.7-.7V16.6A.693.693,0,0,1,13.128,15.909ZM18,18a1.39,1.39,0,0,0-.7,2.594v.883a.7.7,0,1,0,1.391,0v-.884A1.389,1.389,0,0,0,18,18Z" transform="translate(-2 -2)"/></svg>
                </button>
                <button mat-button (click)="logout()">Cerrar Sesión
                    <svg style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="32" height="31.992" viewBox="0 0 32 31.992"><defs><style>.a{fill:#3ca2d6;}</style></defs><path class="a" d="M18,2A16,16,0,1,0,30.9,27.449a.7.7,0,1,0-1.121-.823,14.6,14.6,0,1,1,0-17.259A.7.7,0,1,0,30.9,8.543,15.976,15.976,0,0,0,18,2Zm9.73,9.73a.7.7,0,0,0-.485,1.194L31.617,17.3H16.6a.7.7,0,1,0,0,1.391H31.617l-4.377,4.377a.7.7,0,1,0,.984.984l5.5-5.5a.7.7,0,0,0,0-1.107l-5.5-5.5A.7.7,0,0,0,27.726,11.73Z" transform="translate(-2 -2)"/></svg>
                </button>
            </div>
        </div>
        <div class="title">Confirmar Cotización</div>
        <span>#{{folio}}</span>
    </div>

    <div class="box">
        <div *ngIf="data.n_cotizacion != ''" class="comentarios">
            <span>RESULTADO COTIZACION #{{ data.n_cotizacion }}</span>
            <span style="margin-left: 20px;">{{ data.comentarios }}</span>
        </div>
        <span>REVISA LAS COTIZACIONES Y ELIGE SÓLO UNA</span>
        <div class="lista">
            <app-cotizacion *ngFor="let cotizacion of data.cotizaciones" 
                [Cotizacion]="cotizacion"
                (onClick)="SeleccionarCotizacion(cotizacion)"
            ></app-cotizacion>
        </div>
    </div>

    <app-socials-footer style="width: 100%;"></app-socials-footer>
</div>