<div class="row">
    <div class="col-4">
        <ul class="progress-cotizacion">
			<li class="active" *ngIf="tramite"><span>CLIENTE</span></li>
            <li class="active"><span>EQUIPO</span></li>
            <li><span>COTIZACIÓN</span></li>
            <li><span>RESIDUAL</span></li>
        </ul>
    </div>
</div>

<form [formGroup]="equipoForm">
<div class="row">
    <div class="col-2">
        <div class="input">
            <label>Activo a arrendar</label>
            <mat-select formControlName="activoArrendar">
                <mat-option [value]="'EQUIPO MÉDICO'">EQUIPO MÉDICO</mat-option>
                <mat-option [value]="'VEHÍCULO DE TRANSPORTE'">VEHÍCULO DE TRANSPORTE</mat-option>
                <mat-option [value]="'EQUIPO DE CÓMPUTO'">EQUIPO DE CÓMPUTO</mat-option>
                <mat-option [value]="'OTRO'">OTRO</mat-option>
            </mat-select>
            <!-- <input 
			    [specialCharacters]="['space']"
                type="text" 
                [patterns]="customPattern"
                mask="E{100}"
                formControlName="activoArrendar"
                placeholder="Activo a arrendar"> -->
                <mat-error *ngIf="(submit || calculated) && !f.activoArrendar.value">Campo obligatorio</mat-error>
        </div>

        <div class="input">
            <label>Descripción</label>
            <textarea 
			    [specialCharacters]="['space']"
                formControlName="descripcion" 
                [patterns]="customPattern"
                mask="E{100}"
                style="height: 50px!important;" 
                placeholder="Descripción del activo"
            ></textarea>
            <mat-error *ngIf="(submit || calculated) && !f.descripcion.value">Campo obligatorio</mat-error>
        </div>
    </div>
    <div class="col-2">
        <div class="input">
            <label>Precio Equipo (Sin I.V.A.)</label>
            <input 
                formControlName="precio_equipo_sin_iva" 
                type="text" 
                mask="separator.2" 
                thousandSeparator="," 
                [separatorLimit]="separatorLimit"
                prefix="$ " 
                placeholder="$ 0"
            >
            <mat-error *ngIf="(submit || calculated) && !f.precio_equipo_sin_iva.value">Campo obligatorio</mat-error>
            <mat-error *ngIf="(submit || calculated) && f.precio_equipo_sin_iva.value && f.precio_equipo_sin_iva.invalid">Valor no válido</mat-error>
        </div>
        <div class="input">
            <label>Tipo de cambio</label>
            <input 
                formControlName="tipo_cambio" 
                type="text" 
                mask="separator.2" 
                thousandSeparator="," 
                separatorLimit="100"
                prefix="$ " 
                placeholder="$ 0"
            >
            <mat-error *ngIf="(submit || calculated) && !f.tipo_cambio.value">Campo obligatorio</mat-error>
            <mat-error *ngIf="(submit || calculated) && f.tipo_cambio.value && f.tipo_cambio.invalid">Valor no válido</mat-error>
        </div>
        <div class="input" *ngIf="descuento_ciego">
            <label>Descuento Ciego</label>
            <input 
                formControlName="descuento_ciego" 
                type="text" 
                mask="separator.2" 
                thousandSeparator="," 
                separatorLimit="10"
                suffix=" %" 
                placeholder="0 %"
            >
            <mat-error *ngIf="(submit || calculated) && f.descuento_ciego.invalid">Valor no válido</mat-error>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-4">
        <div class="calcular" [ngClass]="{'calculated': calculated}" (click)="Calcular()">
            <svg xmlns="http://www.w3.org/2000/svg" width="31.41" height="45.036" viewBox="0 0 31.41 45.036"><defs><style>.a{fill:#fff;}</style></defs><g transform="translate(0)"><path class="a" d="M18.083,1A4.383,4.383,0,0,0,14,5.647V41.389a4.383,4.383,0,0,0,4.083,4.647H41.326a4.383,4.383,0,0,0,4.083-4.647V5.647A4.383,4.383,0,0,0,41.326,1Zm0,2.145H41.326a2.35,2.35,0,0,1,2.2,2.5V41.389a2.35,2.35,0,0,1-2.2,2.5H18.083a2.35,2.35,0,0,1-2.2-2.5V5.647A2.35,2.35,0,0,1,18.083,3.145Zm2.2,3.932a1,1,0,0,0-.942,1.072v5a1,1,0,0,0,.942,1.072H39.128a1,1,0,0,0,.942-1.072v-5a1,1,0,0,0-.942-1.072Zm.942,2.145H38.185V12.08H21.224Zm-.942,7.149a1,1,0,0,0-.942,1.072v4.289a1,1,0,0,0,.942,1.072h3.769a1,1,0,0,0,.942-1.072V17.442a1,1,0,0,0-.942-1.072Zm7.538,0a1,1,0,0,0-.942,1.072v4.289A1,1,0,0,0,27.82,22.8h3.769a1,1,0,0,0,.942-1.072V17.442a1,1,0,0,0-.942-1.072Zm7.538,0a1,1,0,0,0-.942,1.072v4.289a1,1,0,0,0,.942,1.072h3.769a1,1,0,0,0,.942-1.072V17.442a1,1,0,0,0-.942-1.072ZM20.282,24.947a1,1,0,0,0-.942,1.072v4.289a1,1,0,0,0,.942,1.072h3.769a1,1,0,0,0,.942-1.072V26.02a1,1,0,0,0-.942-1.072Zm7.538,0a1,1,0,0,0-.942,1.072v4.289a1,1,0,0,0,.942,1.072h3.769a1,1,0,0,0,.942-1.072V26.02a1,1,0,0,0-.942-1.072Zm7.538,0a1,1,0,0,0-.942,1.072V38.887a1,1,0,0,0,.942,1.072h3.769a1,1,0,0,0,.942-1.072V26.02a1,1,0,0,0-.942-1.072ZM20.282,33.526A1,1,0,0,0,19.34,34.6v4.289a1,1,0,0,0,.942,1.072h3.769a1,1,0,0,0,.942-1.072V34.6a1,1,0,0,0-.942-1.072Zm7.538,0a1,1,0,0,0-.942,1.072v4.289a1,1,0,0,0,.942,1.072h3.769a1,1,0,0,0,.942-1.072V34.6a1,1,0,0,0-.942-1.072Z" transform="translate(-14 -1)"/></g></svg>
            <span>Calcular</span>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-4">El cálculo es el siguiente:</div>
</div>

<div class="row">
    <div class="col-2">
        <div class="input">
            <label>Precio Equipo MXN (Sin I.V.A.)</label>
            <input 
                type="text" 
                mask="separator.2" 
                thousandSeparator="," 
                prefix="$ " 
                suffix=".00"
                placeholder="$ 0"
                formControlName="precio_equipo_sin_iva_mxn"
                readonly
            >
        </div>
        <div class="input">
            <label>I.V.A.</label>
            <input 
                type="text" 
                mask="separator.2" 
                thousandSeparator="," 
                prefix="$ " 
                suffix=".00"
                placeholder="$ 0"
                formControlName="iva"
                readonly
            >
        </div>
        <div class="input">
            <label>Tiempo de entrega</label>
            <mat-radio-group formControlName="tiempoEntrega">
                <mat-radio-button labelPosition="before" [value]="0">Inmediato</mat-radio-button>
                <mat-radio-button labelPosition="before" [value]="2">De 31 a 60 días</mat-radio-button>
                <mat-radio-button labelPosition="before" [value]="1">De 10 a 30 días</mat-radio-button>
                <mat-radio-button labelPosition="before" [value]="3">De 61 a 90 días</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="col-2">
        <div class="input">
            <label>Precio Equipo MXN (Con I.V.A.)</label>
            <input 
                type="text" 
                mask="separator.2" 
                thousandSeparator="," 
                prefix="$ " 
                suffix=".00"
                placeholder="$ 0"
                formControlName="precioEquipoIva"
                readonly
            >
        </div>
    </div>
</div>
</form>
<div class="row">
    <div class="col-4">
        <button mat-button (click)="NextPage()" class="btn-next-page" [disabled]="!calculated">
            Siguiente
            <svg style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><defs><style>.abc{fill:#707070;}</style></defs><path class="abc" d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0ZM22,38V18.7L13.448,27.25l-3.394-3.394L24,9.908,37.948,23.856,34.554,27.25,26,18.7V38Z" transform="translate(48) rotate(90)"/></svg>
        </button>
    </div>
</div>