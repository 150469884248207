<div class="precalificar-box">
    <div class="title">Precalificar</div>
    <div class="field">Nombre: <span>{{ObtenerNombreCompleto()}}</span></div>
    <div class="field">RFC: <span>{{contacto.rfc}}</span></div>
    <div class="field">Dirección: <span>{{contacto.direccion.calle}} {{contacto.direccion.numeroExterior}} {{contacto.direccion.numeroInterior}}</span></div>
    <div class="field">Colonia: <span>{{contacto.direccion.colonia}}</span></div>
    <div class="field">Municipio: <span>{{contacto.direccion.municipio}}</span></div>
    <div class="field">Estado: <span>{{contacto.direccion.estado}}</span></div>
    <div class="field">C.P.: <span>{{contacto.direccion.cp}}</span></div>
    <div class="buttons">
        <button class="btn-cancel" (click)="Close()">Cancelar</button>
        <button *ngIf="!loading" class="btn-primary-main" (click)="Precalificar()">Precalificar</button>
        <span *ngIf="loading" style="width: 210px; display: flex; justify-content: center;">
            <mat-spinner diameter="20"></mat-spinner>
        </span>
    </div>
</div>