<div class="content">
    <div class="title">Indicar dirección</div>
    <div class="subtitle">Donde se encontrará el activo</div>
    <div class="input">
        <label for="">Calle</label>
        <input type="text" [(ngModel)]="direccion.calle">
    </div>
    <div class="col-2">
        <div class="input">
            <label for="">Número exterior</label>
            <input type="text" [(ngModel)]="direccion.numExt">
        </div>
        <div class="input">
            <label for="">Número interior</label>
            <input type="text" [(ngModel)]="direccion.numInt">
        </div>
    </div>
    <div class="col-2">
        <div class="input">
            <label for="">Código postal</label>
            <input type="text" (input)="BuscarCP(direccion.cp)" maxlength="5" [(ngModel)]="direccion.cp">
        </div>
        <div class="input">
            <label for="">Estado</label>
            <input type="text" [(ngModel)]="direccion.estado">
        </div>
    </div>
    <div class="col-2">
        <div class="input">
            <label for="">Municipio</label>
            <input type="text" [(ngModel)]="direccion.municipio">
        </div>
        <div class="input">
            <label for="">Ciudad</label>
            <input type="text" [(ngModel)]="direccion.ciudad">
        </div>
    </div>
    <div class="col-2">
        <div class="input">
            <label for="">Colonia</label>
            <mat-select [(ngModel)]="direccion.colonia">
                <mat-option [value]="''">Selecciona una colonia</mat-option>
                <mat-option *ngFor="let colonia of colonias" [value]="colonia">{{colonia}}</mat-option>
            </mat-select>
        </div>
    </div>
    <button class="btn-primary-new" (click)="GuardarDireccionActivo()">Guardar</button>
</div>
