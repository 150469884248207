<div class="container">
    <div class="general" (click)="VerDetalles()">
        <span>{{ tramite.cliente}}</span>
        <span>#{{ tramite.folio}} {{tramite.activo_nombre}}</span>
    </div>
    <div class="procesos">
        <div class="proceso" [ngClass]="{'actual': tramite.procesos[0].status == 0}">
            <span>REGISTRO</span>
            <span>
                {{ tramite.procesos[0].status == 0 ? 'Actual' : 'Duró'}}
                {{tramite.registro >= 0 ? tramite.registro : 0}} días
            </span>
        </div>
        <div class="proceso" [ngClass]="{'actual': tramite.procesos[0].estado == 'Actual'}">
            <span>SOLICITUD</span>
            <span>
                {{ tramite.procesos[0].estado }}
                <!-- {{ tramite.procesos[0].status == 3 ? 'Duró' :  tramite.procesos[0].status > 0 && tramite.procesos[1].status == 0 ? 'Actual' : ''}} -->
                {{tramite.procesos[0].dias >= 0 ? tramite.procesos[0].dias : 0}} días
            </span>
        </div>
        <div class="proceso" [ngClass]="{'actual': tramite.procesos[1].estado == 'Actual'}" (click)="VerExpediente()">
            <span>EXPEDIENTE</span>
            <span>
                {{ tramite.procesos[1].estado }}
                <!-- {{ tramite.procesos[1].status == 3 ? 'Duró' : tramite.procesos[1].status > 0 && tramite.procesos[2].status == 0 ? 'Actual' : ''}} -->
                {{tramite.procesos[1].dias >= 0 ? tramite.procesos[1].dias : 0}} días
            </span>
        </div>
        <div class="proceso" [ngClass]="{'actual': (tramite.procesos[2].estado == 'Actual' && perfil == 3), 'completed': tramite.procesos[2].estado == 'Duró' && perfil == 3}" (click)="Confirmacion()">
            <span>CONFIRMACIÓN</span>
            <span>
                {{ tramite.procesos[2].estado }}
                <!-- {{ tramite.procesos[2].status == 3 ? 'Duró' : tramite.procesos[2].status > 0 && tramite.procesos[3].status == 0 ? 'Actual' : ''}} -->
                {{tramite.procesos[2].dias >= 0 ? tramite.procesos[2].dias : 0}} días
            </span>
        </div>
        <div *ngIf="perfil == 4" class="proceso" [ngClass]="{'actual': tramite.procesos[3].estado == 'Actual', 'completed': tramite.procesos[3].estado == 'Duró'}">
            <span>COMITÉ</span>
            <span>
                {{ tramite.procesos[3].estado }}
                <!-- {{ tramite.procesos[2].status == 3 ? 'Duró' : tramite.procesos[2].status > 0 && tramite.procesos[3].status == 0 ? 'Actual' : ''}} -->
                {{tramite.procesos[3].dias >= 0 ? tramite.procesos[3].dias : 0}} días
            </span>
        </div>
    </div>
    <div class="time" [ngClass]="{'completed': tramite.procesos[2].estado == 'Duró'}">
        <svg style="height: 30px; width:30px; margin:0 30px;" xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46"><defs><style>.a{fill:#60ab63;}</style></defs><path class="a" d="M25,2A23,23,0,1,0,48,25,23.015,23.015,0,0,0,25,2Zm0,2A21,21,0,1,1,4,25,20.985,20.985,0,0,1,25,4Zm12.039,6.99a1,1,0,0,0-.773.332L26.184,22.244a3,3,0,1,0-.684,5.715l3.625,6.527a1,1,0,0,0,1.75-.973l-3.629-6.529a2.991,2.991,0,0,0,.406-3.385L37.734,12.678a1,1,0,0,0-.7-1.688Z" transform="translate(-2 -2)"/></svg>
    </div>
    <div class="dias">
        <span>Días totales</span>
        <span>{{dias}} días</span>
    </div>
</div>