import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ver-cotizacion-creada',
  templateUrl: './ver-cotizacion-creada.component.html',
  styleUrls: ['./ver-cotizacion-creada.component.scss']
})
export class VerCotizacionCreadaComponent implements OnInit {
  @HostListener('window:popstate', ['$event'])
  onpopstate(event) {
    console.log("Back button");
    
    this.router.navigate(['dashboard'])
  }

  url = null
  constructor(
    private router:Router
  ) { }

  ngOnInit(): void {
    this.url = localStorage.getItem('cotizacion')
  }

}
