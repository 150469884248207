import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/_services';

@Component({
  selector: 'app-aval-nueva-solicitud',
  templateUrl: './aval-nueva-solicitud.component.html',
  styleUrls: ['./aval-nueva-solicitud.component.scss']
})
export class AvalNuevaSolicitudComponent implements OnInit {
  @Input() data: any;
  @Output() datosActualizados = new EventEmitter();
  data_aval: any = {};
  frmAval: FormGroup;
  frmAvalMoral: FormGroup;

  id_usuario = null;
  perfil = 0;
  id_cliente = null;
  folio = "";
  tipo_persona = 1;
  tipo_aval = 1;
  loading = false;
  $loading = null;

  Documentos = {
    DocumentosSolicitante: [],
    DocumentosSolicitanteLegal: [],
    DocumentosAval: [],
    DocumentosAvalLegal: [],
  };
  documentos_necesarios = 0;
  faltanDocumentos = false;
  DocumentosValidar = [];
  validandoDocumentos = false;
  cancelDoc = false;
  completado = false;
  solicitud_prellenada = false;
  formData: FormData;
  allowed_type = "application/pdf";


  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.id_cliente = localStorage.getItem('current_id_cliente')
    this.id_usuario = JSON.parse(localStorage.getItem('currentUser')).id
    this.folio = JSON.parse(localStorage.getItem('folio_solicitud'))
    this.data_aval =
      this.tipo_persona == 1
        ? {
            folio: this.folio.toString(),
            id_cliente: this.id_cliente,
            tipo_persona: this.tipo_persona,
            tipo_aval: this.tipo_aval,
            empleado: false,
            nuevo_flujo: true,
          }
        : {
            folio: this.folio,
            tipo_persona: this.tipo_persona,
            tipo_aval: this.tipo_aval,
          };
    this.frmAval = this.formBuilder.group({
      nombres: ['', Validators.required],
      apellido_paterno: ['', Validators.required],
      apellido_materno: ['', Validators.required],
      ocupacion: [''],
      // correo_aval: ['', [Validators.required, this.customEmailValidator]],
      tel: ['', [Validators.required, Validators.maxLength(10),Validators.minLength(10)]],
    })
    this.ObtenerStatus()
    this.frmAval.valueChanges.subscribe(() => {
      if (this.frmAval.valid) {
        this.data = { ...this.data, aval: this.frmAval.value };
        this.datosActualizados.emit(this.data);
      }
    });
    if(this.data.aval){
      this.frmAval.patchValue({
        nombres: this.data.aval.nombres || '',
        apellido_paterno: this.data.aval.apellido_paterno || '',
        apellido_materno: this.data.aval.apellido_materno || '',
        ocupacion: this.data.aval.ocupacion || '',
      });
    }
  }


customEmailValidator(control: FormControl) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!emailRegex.test(control.value)) {
    return { invalidEmail: true, message: 'Por favor, ingresa un correo electrónico válido.' };
  }
  return null;
}
ObtenerStatus() {
  console.log("DATA ENVIADA DOC", this.data_aval)
  this.userService.ObtenerStatusDocuments(this.data_aval).subscribe(
    (res) => {
      this.Documentos = res;
      this.documentos_necesarios =
        this.Documentos.DocumentosSolicitante.length +
        this.Documentos.DocumentosAval.length;
      this.Documentos.DocumentosAval = this.Documentos?.DocumentosAval?.filter(documento => documento.tipo_documento !== 20)

      if (Number(this.perfil) === 4) {
        const result1 = this.Documentos.DocumentosSolicitante.filter(
          (doc: any) => {
            return doc.status === 2;
          }
        );
        const result2 = this.Documentos.DocumentosAval.filter((doc: any) => {
          return doc.status === 2;
        });
        this.cancelDoc = result1.length + result2.length > 0;
      }

      this.faltanDocumentos = false;
      this._hayDocumentosSinCargar(this.Documentos.DocumentosSolicitante);
      this._hayDocumentosSinCargar(this.Documentos.DocumentosAval);
      this.DocumentosCompletos();
    },
    (err) => {
      this._snackBar.open(
        "Ha ocurrido un error, por favor vuelve a intentarlo. ",
        "Ok",
        {
          duration: 10000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: ["warning-snackbar"],
        }
      );
    }
  );
}
private _hayDocumentosSinCargar(documentos) {
  if (!this.faltanDocumentos) {
    for (const documento of documentos) {
      if (documento.tipo_documento != 21 && documento.status == 0) {
        this.faltanDocumentos = true;
        break;
      }
    }
  }
}
DocumentosCompletos() {
  if (!this.userService.alertService.isOnline()) return;
  this.completado = true;
  let status = 0;

  this.Documentos.DocumentosSolicitante.forEach((documento) => {
    
    if (documento.requerido == true && documento.status != 3) {
      this.completado = false;
    }
    this.solicitud_prellenada =
      documento.tipo_documento == 21 && documento.url.length > 0;
  });
  this.Documentos.DocumentosAval.forEach((documento) => {
    if (documento.requerido == true && documento.status != 3) {
      this.completado = false;
    }
  });

  if (this.Documentos.DocumentosSolicitanteLegal)
    this.Documentos.DocumentosSolicitanteLegal.forEach((documento) => {
      if (documento.requerido == true && documento.status != 3) {
        this.completado = false;
      }
      this.solicitud_prellenada =
        documento.tipo_documento == 21 && documento.url.length > 0;
    });
  if (this.Documentos.DocumentosAvalLegal)
    this.Documentos.DocumentosAvalLegal.forEach((documento) => {
      if (documento.requerido == true && documento.status != 3) {
        this.completado = false;
      }
    });

  if (!this.solicitud_prellenada) {
    this.documentos_necesarios -= 1;
  }
}

SubirDocumento({ target: { files } }) {
  
  // if (!this.userService.alertService.isOnline()) return;
  const file = files[0];

  if (file) {
    this.formData.append("document", file);

    this.userService.SubirDocumento(this.formData).subscribe(
      (res) => {
        const $document = document.querySelector("#documentaval");
        this._snackBar.open(
          "Se ha cargado correctamente el documento",
          "Ok",
          {
            duration: 5000,
            horizontalPosition: "center",
            verticalPosition: "top",
            panelClass: ["success-snackbar"],
          }
        );
        // this.userService.changeStatus(this.folio, 2, 1) // ya se realiza desde el servicio
        this.ObtenerStatus();
      },
      (err) => {
        this._snackBar.open(
          "Ha ocurrido un error, por favor vuelve a intentarlo. ",
          "Ok",
          {
            duration: 10000,
            horizontalPosition: "center",
            verticalPosition: "top",
            panelClass: ["warning-snackbar"],
          }
        );
      }
    );
  }
}

SeleccionarDocumento(doc, solicitante) {
  // if (!this.userService.alertService.isOnline()) return;
  // const doc = (solicitante) ? this.Documentos.DocumentosSolicitante[idx] : this.Documentos.DocumentosAval[idx]
  if (doc.status == 3 || doc.status == 1) return;

  this.formData = new FormData();
  this.formData.append("folio", this.folio);
  this.formData.append("id_cliente", this.id_cliente.toString());
  this.formData.append("tipo_documento", doc.tipo_documento);
  this.formData.append("solicitante", solicitante);
  this.formData.append("id_usuario_mod", this.id_cliente.toString() ?? this.id_usuario);


  const $document: any = document.querySelector("#documentaval");
  $document.click();
}

resset = (event) => {
  event.target.value = null;
};

VerDocumento(documento, solicitante) {
  localStorage.setItem("documento", JSON.stringify(documento));

  if (documento.url) {
    const url = `#/dashboard/cliente/${this.folio}/ver_documento`;
    window.open(url, "_blank");
  }
}

}
