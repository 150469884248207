<div class="row">
    <div class="col-4">
        <ul class="progress-cotizacion">
			<li class="active" *ngIf="tramite"><span>CLIENTE</span></li>
            <li class="active"><span>EQUIPO</span></li>
            <li class="active"><span>COTIZACIÓN</span></li>
            <li><span>RESIDUAL</span></li>
        </ul>
    </div>
</div>

<form [formGroup]="cotizacionForm">
<div class="row">
    <div class="col-4" style="display: flex; flex-direction: row;">
        <svg style="height: 15px; width:auto; border-radius: 45px; border:2px solid #3F4A57; margin-right: 5px;" xmlns="http://www.w3.org/2000/svg" width="17.328" height="17.328" viewBox="0 0 17.328 17.328"><defs><style>.info_icon{fill:#fff; stroke: #3F4A57;}</style></defs><path class="info_icon" d="M10.664,2a8.664,8.664,0,1,0,8.664,8.664A8.664,8.664,0,0,0,10.664,2Zm.866,13H9.8V13.263H11.53Zm0-3.466H9.8v-5.2H11.53Z" transform="translate(-2 -2)"/></svg>
        Recuerda que el factor debe ser mayor a {{factorMin}}
    </div>
</div>
<div class="row">
    <div class="col-2">
        <div class="input">
            <label>Factor</label>
            <input mask="separator.3" placeholder="0" formControlName="factor" separatorLimit="100" (input)="fail_factor = false">
            <mat-error *ngIf="(submit || calculated) && (!f.factor.value)">Campo obligatorio</mat-error>
            <mat-error *ngIf="(f.factor.value && ( f.factor.invalid || fail_factor == true))">Verifica tu factor</mat-error>
        </div>
        <div class="input">
            <label>Porcentaje Enganche</label>
            <input type="text" mask="separator.3" suffix=" %" placeholder="0 %" separatorLimit="100" formControlName="enganche" (input)="fail_enganche = false" >
            <mat-error *ngIf="(submit || calculated) && !(f.enganche.value >= 0)">Campo obligatorio</mat-error>
            <mat-error *ngIf="f.enganche.value && (f.enganche.invalid || fail_enganche)">Porcentaje no válido</mat-error>
        </div>

        <div class="input" *ngIf="poliza_mantenimiento">
            <label>Precio póliza mantenimiento anual (Sin I.V.A.)</label>
            <input type="text" mask="separator.2" thousandSeparator="," prefix="$ " formControlName="polizaMantenimientoAnual">
        </div>
    </div>


    <div class="col-2">
        <div class="input">
            <label>Porcentaje Seguro</label>
            <input type="text" mask="separator.1" suffix=" %" placeholder="0 %" separatorLimit="100" formControlName="porcentajeSeguro" (input)="fail_seguro = false" [readonly]="!puede_editar">
            <!-- <mat-error *ngIf="(submit || calculated) && !f.porcentajeSeguro.value">Campo obligatorio</mat-error> -->
            <mat-error *ngIf="f.porcentajeSeguro.value && (f.porcentajeSeguro.invalid || fail_seguro)">Porcentaje no válido</mat-error>
        </div>
        <div class="input">
            <label>Deposito en garantía:</label>
            <mat-select formControlName="deposito_garantia">
                <mat-option 
                    *ngFor="let item of Garantia; let i = index" 
                    [value]="i">{{ i }} {{ i == 1 ? 'mes' : 'meses' }}
                </mat-option>
            </mat-select>
        </div>

        <div class="input" *ngIf="poliza_mantenimiento">
            <label>% Póliza mantenimiento anual</label>
            <input type="text" mask="0" suffix=" %" readonly formControlName="porcentajePolizaMantenimiento">
        </div>
    </div>
</div>

<div class="row">
    <div class="col-4">
        <div class="calcular" [ngClass]="{'calculated': calculated}" (click)="Calcular()">
            <svg xmlns="http://www.w3.org/2000/svg" width="31.41" height="45.036" viewBox="0 0 31.41 45.036"><defs><style>.a{fill:#fff;}</style></defs><g transform="translate(0)"><path class="a" d="M18.083,1A4.383,4.383,0,0,0,14,5.647V41.389a4.383,4.383,0,0,0,4.083,4.647H41.326a4.383,4.383,0,0,0,4.083-4.647V5.647A4.383,4.383,0,0,0,41.326,1Zm0,2.145H41.326a2.35,2.35,0,0,1,2.2,2.5V41.389a2.35,2.35,0,0,1-2.2,2.5H18.083a2.35,2.35,0,0,1-2.2-2.5V5.647A2.35,2.35,0,0,1,18.083,3.145Zm2.2,3.932a1,1,0,0,0-.942,1.072v5a1,1,0,0,0,.942,1.072H39.128a1,1,0,0,0,.942-1.072v-5a1,1,0,0,0-.942-1.072Zm.942,2.145H38.185V12.08H21.224Zm-.942,7.149a1,1,0,0,0-.942,1.072v4.289a1,1,0,0,0,.942,1.072h3.769a1,1,0,0,0,.942-1.072V17.442a1,1,0,0,0-.942-1.072Zm7.538,0a1,1,0,0,0-.942,1.072v4.289A1,1,0,0,0,27.82,22.8h3.769a1,1,0,0,0,.942-1.072V17.442a1,1,0,0,0-.942-1.072Zm7.538,0a1,1,0,0,0-.942,1.072v4.289a1,1,0,0,0,.942,1.072h3.769a1,1,0,0,0,.942-1.072V17.442a1,1,0,0,0-.942-1.072ZM20.282,24.947a1,1,0,0,0-.942,1.072v4.289a1,1,0,0,0,.942,1.072h3.769a1,1,0,0,0,.942-1.072V26.02a1,1,0,0,0-.942-1.072Zm7.538,0a1,1,0,0,0-.942,1.072v4.289a1,1,0,0,0,.942,1.072h3.769a1,1,0,0,0,.942-1.072V26.02a1,1,0,0,0-.942-1.072Zm7.538,0a1,1,0,0,0-.942,1.072V38.887a1,1,0,0,0,.942,1.072h3.769a1,1,0,0,0,.942-1.072V26.02a1,1,0,0,0-.942-1.072ZM20.282,33.526A1,1,0,0,0,19.34,34.6v4.289a1,1,0,0,0,.942,1.072h3.769a1,1,0,0,0,.942-1.072V34.6a1,1,0,0,0-.942-1.072Zm7.538,0a1,1,0,0,0-.942,1.072v4.289a1,1,0,0,0,.942,1.072h3.769a1,1,0,0,0,.942-1.072V34.6a1,1,0,0,0-.942-1.072Z" transform="translate(-14 -1)"/></g></svg>
            <span>Calcular</span>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-4">El cálculo es el siguiente:</div>
</div>
<div class="row">
    <div class="col-2">
        <div class="input">
            <label>Enganche (Con I.V.A.)</label>
            <input type="text" suffix=".00" formControlName="engancheIVA" mask="separator.2" thousandSeparator="," prefix="$ " readonly>
        </div>
        <div class="input">
            <label>Precio Seguro Mensual</label>
            <input type="text" suffix=".00" formControlName="precioSeguroMensualFinanciado" mask="separator.2" thousandSeparator="," prefix="$ " readonly>
        </div>
    </div>
    <div class="col-2">
        <div class="input">
            <label>Precio Seguro Anual (Financiado)</label>
            <input type="text" suffix=".00" formControlName="precioSeguroAnual" mask="separator.2" thousandSeparator="," prefix="$ " readonly>
        </div>

        <div class="input" *ngIf="poliza_mantenimiento">
            <label>Precio póliza mantenimiento mensual (financiado)</label>
            <input type="text" readonly formControlName="polizaMantenimientoMensual" mask="separator.2" thousandSeparator="," prefix="$ ">
        </div>
    </div>
</div>
</form>
<div class="row">
    <div class="col-4">
        <button mat-button (click)="NextPage()" class="btn-next-page" [disabled]="!calculated">
            Siguiente
            <svg style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><defs><style>.abc{fill:#707070;}</style></defs><path class="abc" d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0ZM22,38V18.7L13.448,27.25l-3.394-3.394L24,9.908,37.948,23.856,34.554,27.25,26,18.7V38Z" transform="translate(48) rotate(90)"/></svg>
        </button>
    </div>
</div>