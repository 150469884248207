<form [formGroup]="frmAval">
  <div style="display: flex; flex-direction: column">
    <app-input-validation
      type="text"
      [formGroup]="frmAval"
      [controlName]="'nombres'"
      [errorMessage]=""
      label="Nombre(s)"
      mask=""
    ></app-input-validation>
  </div>

  <div class="container-form">
    <div class="column">
      <app-input-validation
        type="text"
        [errorMessage]=""
        [formGroup]="frmAval"
        [controlName]="'apellido_paterno'"
        label="Apellido Paterno"
        mask=""
      ></app-input-validation>
      <app-input-validation
        type="text"
        [formGroup]="frmAval"
        [controlName]="'apellido_materno'"
        [errorMessage]=""
        label="Apellido Materno"
        mask=""
      ></app-input-validation>
    </div>
    <div class="column" style="margin-left: 15px;">
      <app-input-validation
        type="text"
        [formGroup]="frmAval"
        [controlName]="'ocupacion'"
        [errorMessage]=""
        label="Ocupación"
        mask=""
      ></app-input-validation>
      <app-input-validation
        type="text"
        [formGroup]="frmAval"
        [controlName]="'tel'"
        [errorMessage]=""
        label="Teléfono"
        mask="00-0000-0000"
      ></app-input-validation>
    </div>
  </div>
  <div class="container-docu">
    <input
      type="file"
      id="documentaval"
      (click)="resset($event)"
      accept="application/pdf"
      (change)="SubirDocumento($event)"
      hidden
    />

    <app-documents-aval-new
      *ngFor="let documento of Documentos.DocumentosAval; let i = index"
      [documento]="documento"
      [index]="i"
      [solicitante]="false"
      style="margin: 10px 0"
      (Upload)="SeleccionarDocumento(documento, false)"
      (VerDocumento)="VerDocumento(documento, false)"
    >
    </app-documents-aval-new>
  </div>
</form>
